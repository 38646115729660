import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";

const campaignBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaignBlur.png";
const campaign = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaignManagement.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/campaignManagment.svg";

const CampaignManagement = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/campaign-management" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg}
        container
        sx={{
          backgroundImage: `url('${campaignBlur}')`,
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
        }}
      >
        <Grid
          item
          md={6}
          sx={{
            paddingRight: { md: "70px", sm: "0px", xs: "0px" },
          }}
        >
          <Typography
            className={classes.title}
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            CAMPAIGN MANAGEMENT
          </Typography>
          <Typography
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontFamily: "Stolzl",
            }}
          >
            Streamlines the creation, execution, and monitoring of fundraising
            campaigns, allowing for effective organization and engagement with
            potential donors.
          </Typography>
        </Grid>
        <Grid mt={{ md: 0, sm: 4, xs: 6 }}>
          {themeMode === "dark" ? (
            <img
              src={campaign}
              alt="Campaign Management"
              className={classes.fullWidth}
            />
          ) : (
            <img
              src={light}
              alt="Campaign Management"
              className={classes.fullWidth}
            />
          )}
        </Grid>
      </Grid>
    </Link>
  );
};
export default CampaignManagement;
