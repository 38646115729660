import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import './useStyle.css'

const up = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upBlack.svg"
const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg"


export const ImgCard = ({ imageUrl, title, content, width, height, backgroundColor, borderSize, borderRadius, shadow, textColor, label, labelColor, btnColor }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: borderSize ? `${borderSize}px solid #FFF` : '1px solid #FFF',
          borderRadius: borderRadius ? `${borderRadius}px` : '10px',
          width: width ? width : '80%',
          height: 'fit-content',
          overflow: 'none',
          boxShadow: shadow ? '2px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
        }}
      >
        <div style={{display:"flex",flexDirection:"column",width:"fit-content",height: height ? height : "100%"}}>
            <div >
                <img src={imageUrl} alt="" style={{width:"100%",height:"100%"}}/>
            </div>

            <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
             style={{width:"100%",backgroundColor: backgroundColor ? backgroundColor : "#FFF"}}>
                <p style={{color: textColor ? textColor :"#000", display:"flex",justifyContent:"space-between",fontSize:"20px"}}>
                    {title ? title : "Card Title"}
                    <img src={isHovered?down:up} alt="" style={{margin:"0px 10px 0px 0px"}}/>
                </p>
            </div>
            
            {
                isHovered?
                <div style={{width:"100%",backgroundColor:"#FFF",height:"100%"}}>
                    <p style={{color:"#000",display:"flex",justifyContent:"space-between",fontSize:"14px",margin:"0px 0px 0px 10px"}}>
                        Description of the need and/or donor impact
                    </p>
                </div>
                :""
            }
            

            <button class="proceed" id='proceed-btn' style={{ color: labelColor ? labelColor : '#FFF', backgroundColor:  btnColor ? btnColor : '#A4B200' }}> 
              {label ? label : "Donate"}
            </button>
        </div>
      </div>
    </>
  )
}
