import React from 'react';
import { Modal, Tabs, Tab, Box, Typography } from '@mui/material';

function TabPanel({ children, value, index, ...other  }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function ElementShowModal({ open, setOpen, elementRow }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-title">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography id="modal-title" variant="h6" component="h2">
          Element 
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="modal tabs">
          <Tab label="Appearance" id="tab-1" />
          <Tab label="Documentation" id="tab-2" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <span dangerouslySetInnerHTML={{ __html: elementRow?.elementHtml}} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h4>Your installation code enables your website to use Accleraise Element.</h4>
          <h4>{`By embedded the code below into <head> section of your website's code, you will be able to display the Checkout modal and other elements:`}</h4>
          <p>
          {`<script src="https://js.stripe.com/v3/"></script>
          <script src="https://www.accceleraise.com/checkout-modal.js"></script>`}
          </p>
          <h4>And place this anywhere in the frontend document body to make it an acceleraise element:</h4>
          <p>
          {`<span name="acceleraise-element" id="${elementRow?.uuid}"></span>`}
          </p>
        </TabPanel>
      </Box>
    </Modal>
  );
}