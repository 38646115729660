import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "10px",
    padding:"0px",
    "& .MuiOutlinedInput-input": {
      color: "#A4B200",
      margin: "0px 0px 0px 40px",
      fontSize: "16px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(15px, 70%) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(15px, 70%) scale(1)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "0px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderTop: "0px solid #FFF",
      borderRight:"0px solid #FFF",
      borderLeft:"0px solid #FFF",
      borderBottom: "0px solid #FFF",
    },
  },
  select: {
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
      backgroundColor: "red",
    },
    border:"0px solid red !important",
    borderRadius: "10px",
    width: "50%",
    position:"relative",left:"35px",
    "&:hover":{
      border:"0px solid red !important",
    },
    "&:focus":{
      border:"0px solid red !important",
    },
    "&:active":{
      border:"0px solid red !important",
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      //borderColor: "#ffff",
      border: "0px solid transparent",
      borderWidth: "0.5px",
      borderRadius: "5px",
      "&:hover": {
        border: "0px solid #FFF",
      },
    },
  },
  menuItem: {
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      color: "#689900",
      backgroundColor: "#0D0D0D",
    },
    "&:focus": {
      color: "#689900",
      backgroundColor: "#000",
    },
    "&.Mui-selected": {
      color: "#FFFF",
      backgroundColor: "#689900 !important",
      "&:hover": {
        color: "#689900",
        backgroundColor: "#0D0D0D !important",
      },
    },
  },
  input2: {
    //backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    // "& .MuiOutlinedInput-input": {
    //   color: "#FFFFFF",
    //   "&::placeholder": {
    //     color: "#FFFFFF",
    //   },
    // },
    "& .MuiInputLabel-root": {
      //color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
     "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: "0px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderTop: "0px solid #FFF",
      borderRight:"0px solid #FFF",
      borderLeft:"0px solid #FFF",
      borderBottom: "0px solid #FFF",
    },
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  head700: {
    
    fontWeight: "700",
  },
  bkg: {
    width: "100%",
    height: "150px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    borderRadius: "10px",
  },
  f16w400: {
    fontSize: "16px",
    fontWeight: "400",
  },
  fullWidth: {
    width: "100%",
  },
}));