import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { countriesList } from "../../../AuthPages/SignUp/SalesTeam/Form/countries";
import { supportEmail } from "../../../../store/supportSlice";
import { success } from "../../../../store/alertSlice";

const noble =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/noble.svg";
const pricing =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/pricing.svg";
const feature =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/features.svg";
const ieee =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/IEEE.svg";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  first_name: "",
  email: "",
  message: "",
  emaillll: "",
};

const schema = yup
  .object()
  .shape({
    message: yup.string().required("You must type message"),
    email: yup.string().required("You must type email"),
    first_name: yup.string().required("You must type your name"),
  })
  .required();

const Answer = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const dispatch = useDispatch();

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  return (
    <>
      <Grid
        container
        mb={3}
        sx={{
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#FFF",
          padding: { md: "100px 80px 60px", sm: "40px 80px", xs: "20px 10px" },
          fontFamily: "Stolzl",
        }}
      >
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            padding: "60px 20px",
            display: { md: "", sm: "flex", xs: "flex" },
            flexDirection: "column",
            alignItems: { md: "flex-start", sm: "center" },
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "54px", sm: "64px", xs: "32px" },
              fontWeight: "700",
              color: themeMode === "dark" ? "#FFF" : "#030303",
              fontFamily: "Stolzl",
              textAlign: { md: "left", sm: "center", xs: "center" },
            }}
          >
            Contact Us
          </Typography>
          <Typography
            my={5}
            sx={{
              fontSize: { md: "16px", sm: "18px", xs: "15px" },
              fontWeight: "400",
              color: themeMode === "dark" ? "#FFF" : "#030303",
              width: { md: "85%", sm: "100%" },
              fontFamily: "Stolzl",
              textAlign: { md: "left", sm: "center", xs: "center" },
            }}
          >
            We would love to answer any query and get you connected with
            Acceleraise.
          </Typography>

          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { md: "75%", sm: "75%" },
              height: "fit-content",
            }}
          >
            <Button
              sx={{
                background: themeMode === "dark" ? "#d1000a" : "#8ED003",
                padding: { md: "", sm: "15px", xs: "" },
                borderRadius: "10px",
                display: "flex",
                justifyContent: "left",
                paddingLeft: "20px",
                "&:hover": {
                  background: themeMode === "dark" ? "#1c0002" : "#77b000",
                },
              }}
            >
              <Link to="/features">
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "18px" },
                    fontWeight: "500",
                    color: "#FFF",
                    textTransform: "capitalize",
                    fontFamily: "Stolzl",
                    textAlign: "left",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <img src={feature} alt="" style={{ marginRight: "10px" }} />
                  Get to know about all features.
                </Typography>
              </Link>
            </Button>

            <Button
              sx={{
                background: themeMode === "dark" ? "#d1000a" : "#8ED003",
                padding: { md: "", sm: "15px", xs: "" },
                borderRadius: "10px",
                display: "flex",
                justifyContent: "left",
                paddingLeft: "20px",
                margin: "15px 0px",
                "&:hover": {
                  background: themeMode === "dark" ? "#1c0002" : "#77b000",
                },
              }}
            >
              <Link to="/learn">
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "18px" },
                    fontWeight: "500",
                    color: "#FFF",
                    textTransform: "capitalize",
                    fontFamily: "Stolzl",
                    textAlign: "left",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <img src={noble} alt="" style={{ marginRight: "10px" }} />
                  Collect more for your noble cause.
                </Typography>
              </Link>
            </Button>

            <Button
              sx={{
                background: themeMode === "dark" ? "#d1000a" : "#8ED003",
                padding: { md: "", sm: "15px", xs: "" },
                borderRadius: "10px",
                display: "flex",
                justifyContent: "left",
                paddingLeft: "20px",
                "&:hover": {
                  background: themeMode === "dark" ? "#1c0002" : "#77b000",
                },
              }}
            >
              <Link to="/pricing">
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "18px" },
                    fontWeight: "500",
                    color: "#FFF",
                    textTransform: "capitalize",
                    fontFamily: "Stolzl",
                    textAlign: "left",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <img src={pricing} alt="" style={{ marginRight: "10px" }} />
                  Learn more about pricing plans.
                </Typography>
              </Link>
            </Button>
          </Grid>
        </Grid>

        {/* Form */}

        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            padding: "60px 40px",
            borderRadius: "15px",
            background: themeMode === "dark" ? "#1c0002" : "#e5fcb3",
            boxShadow:
              themeMode === "dark"
                ? "0px 0px 0px red"
                : "0px 8px 5px 0px #00000040",
          }}
        >
          <form
            // className="w-full"
            onSubmit={handleSubmit((_data) => {
              const support_email = {
                first_name: _data.first_name,
                phone_number: _data.phone_number,
                country_code: _data.country_code,
                email: _data.email,
                message: _data.message,
              };
              console.log(
                "🚀 ~ onSubmit={handleSubmit ~ support_email:",
                support_email
              );
              dispatch(supportEmail({ support_email })).then(() =>
                dispatch(success("Your feedback is submitted!"))
              );
            })}
          >
            <Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Stolzl",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                    }}
                  >
                    Email Address
                  </Typography>

                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        variant="outlined"
                        id="outlined-basic"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        sx={{
                          mr: 1,
                          borderRadius: "10px",
                          width: { md: "90%", sm: "90%", xs: "100%" },
                          boxShadow:
                            themeMode === "dark"
                              ? "0px 0px 0px red"
                              : "0px 4px 5px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#FFF",
                          "& .MuiInputLabel-root": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:focus": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "red",
                            },
                            outline: "none",
                          },
                        }}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  mt={{ md: 0, sm: 0, xs: 2 }}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Stolzl",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                    }}
                  >
                    Full Name
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        variant="outlined"
                        id="outlined-basic-2"
                        error={!!errors.first_name}
                        helperText={errors?.first_name?.message}
                        sx={{
                          mr: 1,
                          borderRadius: "10px",
                          width: { md: "90%", sm: "90%", xs: "100%" },
                          boxShadow:
                            themeMode === "dark"
                              ? "0px 0px 0px red"
                              : "0px 4px 5px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#FFF",
                          "& .MuiInputLabel-root": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:focus": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "red",
                            },
                            outline: "none",
                          },
                        }}
                      />
                    )}
                    name="first_name"
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                my={2}
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Stolzl",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                    }}
                  >
                    Country Code
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        name="countryCode"
                        variant="outlined"
                        placeholder="Pakistan (+92)"
                        // value={code}
                        //onChange={(e) => setCode(e.target.value)}
                        sx={{
                          mr: 1,
                          borderRadius: "10px",
                          width: { md: "90%", sm: "90%", xs: "100%" },
                          boxShadow:
                            themeMode === "dark"
                              ? "0px 0px 0px red"
                              : "0px 4px 5px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#FFF",
                          "& .MuiInputLabel-root": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:focus": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "red",
                            },
                            outline: "none",
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                Width: "100%",
                                background:
                                  themeMode === "dark" ? "#000" : "#FFF",
                                scrollbarWidth: "thin",
                                scrollbarColor:
                                  themeMode === "dark"
                                    ? "#8ED003CC #0F0F0F"
                                    : "#8ED003 #E2E2E2",
                              },
                            },
                          },
                        }}
                      >
                        {countriesList.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.dial_code}
                            sx={{
                              fontWeight: "600",
                              color: themeMode === "dark" ? "#FFF" : "#8ED003",
                              backgroundColor:
                                themeMode === "dark" ? "#160602" : "#FFF",

                              "&:hover": {
                                backgroundColor:
                                  themeMode === "dark" ? "#551e23" : "#8ED003",
                                color:
                                  themeMode === "dark" ? "#689900" : "#FFF",
                              },
                              "&:focus": {
                                backgroundColor:
                                  themeMode === "dark" ? "#160609" : "#344d02",
                                color:
                                  themeMode === "dark" ? "#689900" : "#FFF",

                                "&:hover": {
                                  backgroundColor:
                                    themeMode === "dark"
                                      ? "#551e23"
                                      : "#8ED003",
                                  color:
                                    themeMode === "dark" ? "#689900" : "#FFF",
                                },
                              },
                            }}
                          >
                            {item.name} ({item.dial_code})
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    name="country_code"
                    control={control}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  mt={{ md: 0, sm: 0, xs: 2 }}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Stolzl",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                    }}
                  >
                    Phone Number
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        variant="outlined"
                        id="outlined-basic-2"
                        error={!!errors.phone_number}
                        helperText={errors?.phone_number?.message}
                        sx={{
                          mr: 1,
                          borderRadius: "10px",
                          width: { md: "90%", sm: "90%", xs: "100%" },
                          boxShadow:
                            themeMode === "dark"
                              ? "0px 0px 0px red"
                              : "0px 4px 5px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#FFF",
                          "& .MuiInputLabel-root": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:focus": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "red",
                            },
                            outline: "none",
                          },
                        }}
                      />
                    )}
                    name="phone_number"
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Stolzl",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                    }}
                  >
                    Question/Message
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        variant="outlined"
                        id="outlined-basic"
                        multiline
                        minRows={4}
                        sx={{
                          mr: 1,
                          borderRadius: "10px",
                          width: { md: "95%", sm: "95%", xs: "100%" },
                          boxShadow:
                            themeMode === "dark"
                              ? "0px 0px 0px red"
                              : "0px 4px 5px 0px #00000040",
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#FFF",
                          "& .MuiInputLabel-root": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:focus": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "red",
                            },
                            outline: "none",
                          },
                        }}
                      />
                    )}
                    name="message"
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  control={<Checkbox style={{ color: "#8ED003" }} />}
                  label={
                    <Typography
                      sx={{
                        fontSize: { md: "16px", xs: "16px", xs: "13px" },
                        color: themeMode === "dark" ? "#FFF" : "#030303",
                      }}
                    >
                      Agree to our <b>Terms of Services</b> and{" "}
                      <b>Privacy Policy</b>
                    </Typography>
                  }
                  size="small"
                  sx={{ my: 5 }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="submit"
                // disabled={!isValid}
                sx={{
                  background: "#8ED003",
                  borderRadius: "30px",
                  display: "flex",
                  justifyContent: "left",
                  padding: "8px 15px",
                  "&:hover": {
                    background: "#77b000",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#FFF",
                    textTransform: "capitalize",
                    fontFamily: "Stolzl",
                    textAlign: "left",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Contact Us
                </Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          padding: { md: "40px 80px", sm: "40px 80px", xs: "40px 25px" },
          background: themeMode === "dark" ? "#1c0002" : "#e5fcb3",
        }}
      >
        <Grid item md={6} sm={6} xs={12}>
          <Grid
            item
            md={9}
            sm={9}
            xs={12}
            sx={{
              display: { md: "block", sm: "block", xs: "flex" },
              flexDirection: "column",
            }}
          >
            <img src={ieee} alt="" />
            <Typography
              sx={{
                fontSize: { md: "32px", sm: "32px", xs: "24px" },
                fontWeight: "700",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Stolzl",
                textAlign: { md: "left", sm: "left", xs: "center" },
              }}
            >
              Empowering Student Initiatives Through Innovative Fundraising
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Grid item sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              sx={{
                fontSize: "100px",
                fontWeight: "400",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Poppins",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              "
            </Typography>
            <Typography
              mt={5}
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "13px" },
                fontWeight: "400",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Poppins",
              }}
            >
              Acceleraise has revolutionized the way we manage fundraising at
              the IEEE DUET Student Branch. Their intuitive platform made it
              incredibly easy for us to launch campaigns and engage with our
              donors more effectively. Thanks to Acceleraise, we've
              significantly exceeded our fundraising goals, allowing us to
              expand our educational programs and student activities. Their
              commitment to using technology for good is truly making a
              difference in how student organizations operate and succeed in
              their missions.
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              mt={5}
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Poppins",
              }}
            >
              Nabeel Ahmed
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "13px" },
                fontWeight: "500",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Poppins",
              }}
            >
              Vice President
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Answer;
