import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import schedule from '../../../../../../assets/FeatureLightTheme/Reporting/schedule.svg'
import scheduleDark from '../../../../../../assets/FeatureLightTheme/Reporting/schedulrDark.svg'
import selectDark from '../../../../../../assets/FeatureLightTheme/Reporting/selectDark.svg'
import selectLight from '../../../../../../assets/FeatureLightTheme/Reporting/selectLight.svg'

const fields = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/fields.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const calender = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/calender.svg";
const optimized = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/optimized.svg";
const conversion = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/conversion.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_camp3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_camp3.svg";
const light_camp4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_camp4.svg";

const SubSection = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  return(
    <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#FFF",}}>
    <Grid
      className={classes.bkg}
      container
      sx={{
        backgroundImage: themeMode === "dark" ? `url('${bkg}')` : `url('${blur1}')`,
        backgroundImage: themeMode === 'dark'
          ? `url('${bkg}'), url('${bkg2}')`
          : `url('${blur1}'), url('${blur2}')`,
        backgroundSize: themeMode === "dark" ? "140% auto" : "100% auto",
        backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "right bottom,left 0%",

        padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around",
      }}
    >
      <Grid
        item
        md={12}
        sm={11}
        xs={11.5}
        sx={{
          padding: { md: "40px", sm: "30px", xs: "20px" },
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "15px",
          backdropFilter: "blur(22px)",
          background: "transparent",
          boxShadow:themeMode==="dark"?"": "10px 13px 5px 0px #00000040",
          position: "relative",
          color: "#fff",
          //overflow: 'hidden',
          "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            borderRadius: "15px",
            padding: "1px",
            background: themeMode === "dark"
              ? "linear-gradient(145deg, white, transparent)"
              : "linear-gradient(145deg, #00000040, transparent)",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
          },
        }}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          <Grid item md={7.8} sm={12} xs={12}>
            <Typography
              className={classes.title}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#030303",
                textAlign: { md: "left", sm: "center", xs: "center" },
                fontSize: { md: "40px", sm: "35px", xs: "30px" },
              }}
            >
                <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}>Automatic{" "}</span>
                Report Generation & Delivery
            </Typography>
            <Grid item md={8.5}>
              <Typography
                mt={{md:4,sm:4,xs:2}}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  textAlign: { md: "left", sm: "center", xs: "center" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  fontFamily: "Stolzl"
                }}
              >
                  Turn on this schedule report feature to automatically send reports to concern people.
              </Typography>
            </Grid>
     
          </Grid>
          <Grid
            item
            md={5}
            mt={{ md: 0, sm: 5, xs: 5 }}
            sx={{
              display: { md: "flex", sm: "flex", xs: "flex" },
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              transform: { md: "translateX(3%) scale(1.2)", sm: "translateY(0%)" },
            }}
          >
            <img src={themeMode === "dark" ? scheduleDark : schedule} alt="" />
          </Grid>
        </Grid>
      </Grid>




      <Grid
        item
        md={12}
        sm={11}
        xs={11.5}
        mt={{ md: 25, sm: 20, xs: 15 }}
        sx={{
          padding: { md: "40px", sm: "30px", xs: "20px" },
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "15px",
          backdropFilter: "blur(22px)",
          background: "transparent",
          boxShadow:themeMode==="dark"?"": "-10px -13px 5px 0px #00000040",
          position: "relative",
          color: "#fff",
          //overflow: 'hidden',
          "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            borderRadius: "15px",
            padding: "1px",
            background: themeMode === "dark"
              ? "linear-gradient(265deg, white, transparent)"
              : "linear-gradient(325deg, #00000040, transparent)",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
          },
        }}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: {
              md: "row",
              sm: "column-reverse",
              xs: "column-reverse",
            },
            justifyContent: { md: "flex-end", sm: "center", xs: "center" },
            alignItems: "center",
          }}
        >
          <Grid
            item
            md={7}
            sm={12}
            xs={12}
            mt={3}
            sx={{
              display: { md: "flex", sm: "flex", xs: "none" },
              alignItems: "center",
              textAlign: { md: "right", sm: "center", xs: "center" },
              position: "relative",
              //top: { md: "30%", sm: "0%", xs: "0%" },
              //left: { md: "-10%", sm: "10%", xs: "0%" },
              transform: {
                md: "translateY(-10%) translateX(-10%) scale(1.1)",
                sm: "translateY(0%)",
                xs: "translateY(0%)",
              },
            }}
          >
            <img src={themeMode === "dark" ? selectDark : selectLight} alt="" />
          </Grid>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            mt={3}
            sx={{
              display: { md: "none", sm: "none", xs: "flex" },
              alignItems: "center",
              textAlign: { md: "right", sm: "center", xs: "center" },
              position: "relative",
              top: { md: "30%", sm: "0%", xs: "0%" },
              left: { md: "-15%", sm: "10%", xs: "0%" },
              transform: {
                //md: "translateY(20%)",
                sm: "translateY(0%)",
                xs: "translateY(0%)",
              },
            }}
          >
              <img src={themeMode === "dark" ? selectDark : selectLight} alt=""  style={{ width: "100%" }} /> 
          </Grid>
          <Grid item md={6} sm={12} xs={12}
            sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
            <Typography
              className={classes.title}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontSize: { md: "40px", sm: "35px", xs: "30px" },
                textAlign: { md: "right", sm: "center", xs: "center" },
              }}
            >
              <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}>Select{" "}</span>
               among multiple insight options {" "}
              
              
            </Typography>
            <Typography
              mt={4}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#030303",
                textAlign: { md: "right", sm: "center", xs: "center" },
                fontSize: { md: "16px", sm: "14px", xs: "12px" },
                fontFamily: "Stolzl",
                width: {md:"70%",sm:"70",xs:"100%"},
              }}
            >
                Choose your required data fields to be included in your detailed report.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    </Grid>
  )
};
export default SubSection;
