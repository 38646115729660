import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Paper,
  TextField,
  FormControl,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { logout } from "../../../../../../store/authSlice";
import { AuthService } from "../../../../../../services/auth.service";
import DonationCheckout from "../../../../../DonationCheckout/DonationCheckout";
import MainSearchBar from "../../../../../MainLayout/Dashboard/MainSearch";
import ThemeModeSwitch from "../../../../../../utils/themeModeSwitch";
import { success, error as errorMsg } from "../../../../../../store/alertSlice";
import { getUserFromLocalStorage } from "../../../../../../services/localStorageService";
import { useStyles } from "../../../../../MainLayout/useStyles";
import { notifications } from "../../../../SideBarList";
import { ArrowRightAlt } from "@mui/icons-material";
import { setSpinnerLoading } from "../../../../../../store/spinnerLoaderSlice";
import { publishData, defaultPublishData } from "../../../data";
import { useMutation, useQuery } from "@apollo/client";
import DocumentTitle from "../../../../../../DocumentTitle";
import {
  GET_CAMPAIGN,
  PUBLISH_CAMPAIGN,
  UNPUBLISH_CAMPAIGN,
  UPDATE_SELECTED_TEMPLATE,
  UPDATE_CAMPAIGN_TEMPLATE,
  CREATE_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN_WITH_DONATIONS,
  CREATE_ASK_PAGE,
  UPDATE_ASK_PAGE,
  CAMPAIGN_SETTINGS_SUGGESTIONS,
} from "../../Query";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  setAskPage,
  setCampaignState,
} from "../../../../../../store/campaignSlice";
import { adjustNegativeMonths } from "../../../../../../utils/globalFunctions";
import { freq, suggestion } from "../../CampaignSettings/methods";

const undoIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/undo.svg";
const undoIconLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/undoLight.svg";
const redoIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/redo.svg";
const redoIconLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/redoLight.svg";
const upload =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/upload.svg";
const uploadDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/uploadDark.svg";
const plant =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/plant.svg";
const templateOrange =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/orangeBkgTemplate.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/question.svg";
const lightQuestion =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/help.svg";
const logo =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logo.png";
const logoLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";
const verify =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/verify.png";
const dp =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png";
const up =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/up.svg";
const bell =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/bell.png";
const lightBell =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/bell.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const settingsIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/settings.svg";
const logoutIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logout.svg";
const templateBW =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateBkgBW.svg";

const CustomizeTemplate = () => {
  DocumentTitle("Customize Template - Acceleraise");
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid, templateNo } = useParams();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const user = useSelector((state) => state.auth.user);
  const askPage = useSelector((state) => state.campaign.askPage);
  const campaignState = useSelector((state) => state.campaign.campaignState);

  const imgInputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifBoxVisible, setNotifBoxVisible] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formTitle, setFormTitle] = useState(null);
  const [formDesc, setFormDesc] = useState(null);
  const [displayedFrequency, setDisplayedFrequency] = useState([]);
  const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
  const [numberOfSuggestedAmount, setNumberOfSuggestedAmount] = useState(null);
  const [campaignObj, setCampaignObj] = useState(campaignState);
  const [donationForm, setDonationForm] = useState(null);
  const [state, setState, undo, redo] = useHistory({});

  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const { data: campaign } = useQuery(GET_CAMPAIGN_WITH_DONATIONS, {
    variables: {
      uuid: uuid,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      if (result?.campaign?.uuid) {
        dispatch(setAskPage(result.campaign?.askPage));
        dispatch(setCampaignState(result.campaign));
        setCampaignObj(result.campaign);
        setNumberOfSuggestedAmount(
          result.campaign?.defaultSuggestedAmounts?.length
        );
      }
    },
  });

  useEffect(() => {
    if (templateNo && !donationForm) {
      const selectedTemplate = defaultPublishData?.find(
        (template) => Number(template.id) === Number(templateNo)
      );
      setDonationForm(selectedTemplate);
    }
  }, [templateNo]);

  useEffect(() => {
    const matchIndex = freq.findIndex(
      (item) => item.value === campaignObj?.defaultFrequency
    );
    if (matchIndex !== -1) {
      setDisplayedFrequency(freq.slice(0, matchIndex + 1));
    } else {
      setDisplayedFrequency(freq);
    }
  }, [campaignObj?.defaultFrequency]);

  useEffect(() => {
    const matchIndex = suggestion.findIndex(
      (item) => item.show === numberOfSuggestedAmount
    );
    let newSuggestion = suggestion;
    if (matchIndex !== -1) {
      newSuggestion = suggestion.slice(0, matchIndex + 1);
      setDisplayedSuggestions(newSuggestion);
    } else {
      setDisplayedSuggestions(suggestion);
    }
    const defaultSuggestedAmounts = newSuggestion?.map((item) =>
      parseInt(item.name)
    );
    setCampaignObj((prevObj) => ({
      ...prevObj,
      defaultSuggestedAmounts: defaultSuggestedAmounts,
    }));
  }, [numberOfSuggestedAmount]);

  function useHistory(initialState) {
    const [index, setIndex] = useState(0);
    const [history, setHistory] = useState([initialState]);

    const setState = useCallback(
      (newState) => {
        const updatedHistory = history.slice(0, index + 1);
        updatedHistory.push(newState);
        setHistory(updatedHistory);
        setIndex(updatedHistory.length - 1);
      },
      [index, history]
    );

    const undo = useCallback(() => {
      setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }, []);

    const redo = useCallback(() => {
      setIndex((prevIndex) => Math.min(prevIndex + 1, history.length - 1));
    }, [history]);

    const state = history[index];

    return [state, setState, undo, redo];
  }

  const handleButtonClick = (inputRef) => {
    inputRef.current.click();
  };

  const openNotif = (event) => {
    setNotifBoxVisible(event.currentTarget);
  };

  const closeNotif = () => {
    setNotifBoxVisible(null);
  };

  useEffect(() => {
    const isAuthenticated = !!getUserFromLocalStorage();
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    window.location.href = "/sign_in";
  };

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.error("Sign-out error:", error);
      navigate("/");
    }
  };

  const handleImgFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const copyToClipboard = () => {
    const selectedFormUrl = `${process.env.REACT_APP_FRONTEND_URL}/campaign-donation/${templateNo}/${uuid}`;
    navigator.clipboard
      .writeText(selectedFormUrl)
      .then(() => {
        dispatch(success("URL copied to clipboard"));
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const [UpdateAskPage, { loading: askUpdateLoading, error: askUpdateError }] =
    useMutation(UPDATE_ASK_PAGE, {
      onError: (error) => {
        console.error("Mutation Error:", error);
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
      },
    });

  const [
    CampaignSettingSuggestions,
    { loading: suggestionLoading, error: suggestionError },
  ] = useMutation(CAMPAIGN_SETTINGS_SUGGESTIONS, {
    onError: (error) => {
      console.error("Mutation Error:", error);
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
    },
  });

  const handleSave = async (event) => {
    event.preventDefault();

    const variables = {
      uuid: askPage?.uuid,
      title: formTitle || askPage?.title,
      message: formDesc || askPage?.message,
      socialLinks: campaignObj?.askPage?.socialLinks,
      // image: imageUrl,
    };

    dispatch(setSpinnerLoading(true));
    try {
      const result = await UpdateAskPage({ variables });

      if (campaignObj) {
        const variables = {
          uuid: uuid,
          defaultSuggestedAmounts: campaignObj?.defaultSuggestedAmounts,
          defaultFrequency: campaignObj?.defaultFrequency,
        };
        const suggestionsResult = await CampaignSettingSuggestions({
          variables,
        });
        // de-active previous selected other active template.
        let updatedNodes = [];
        campaignObj?.campaignTemplates?.nodes?.forEach(async (item) => {
          if (item.active && item.uuid && item.name !== donationForm?.name) {
            const variables = {
              uuid: item.uuid,
              active: false,
            };
            const resultDeSelectedTemplate = await UpdateCampaignTemplate({
              variables,
            });
            const updatedItem = { ...item, active: false };
            updatedNodes.push(updatedItem);
          } else {
            updatedNodes.push(item);
          }
        });
        campaignObj.campaignTemplates = {
          ...campaignObj.campaignTemplates,
          nodes: updatedNodes,
        };
        setCampaignObj(campaignObj);

        // check if templateObj exist in campaign.
        const templateObj = campaignObj?.campaignTemplates?.nodes?.find(
          (item) => item.name === donationForm?.name && item.uuid
        );
        if (!!templateObj) {
          // if templateObj exist in campaign then update its to active status.
          const variables = {
            uuid: templateObj.uuid,
            name: donationForm?.name,
            template: donationForm?.template,
            active: true,
          };
          if (variables?.uuid && variables?.name && variables?.active) {
            const resultTemplate = await UpdateCampaignTemplate({ variables });
            const templateObj =
              resultTemplate.data.updateCampaignTemplate.campaignTemplate;
            let templateArray = campaignObj.campaignTemplates.nodes
              ? [...campaignObj.campaignTemplates.nodes]
              : [];
            templateArray = [...templateArray, templateObj];
            campaignObj.campaignTemplates = {
              ...campaignObj.campaignTemplates,
              nodes: templateArray,
            };
            setCampaignObj(campaignObj);
          }
        } else {
          // if templateObj doesn't exist in campaign then create with active status.
          const variables = {
            campaignUuid: uuid,
            name: donationForm?.name,
            template: donationForm?.template,
            active: true,
          };
          if (variables?.campaignUuid && variables?.name && variables?.active) {
            const resultTemplate = await CreateCampaignTemplate({ variables });
            const templateObj =
              resultTemplate.data.createCampaignTemplate.campaignTemplate;
            let templateArray = campaignObj.campaignTemplates.nodes
              ? [...campaignObj.campaignTemplates.nodes]
              : [];
            templateArray = [...templateArray, templateObj];
            campaignObj.campaignTemplates = {
              ...campaignObj.campaignTemplates,
              nodes: templateArray,
            };
            setCampaignObj(campaignObj);
          }
        }
        if (
          campaignObj?.defaultSuggestedAmounts ||
          campaignObj?.defaultFrequency
        ) {
          const variables = {
            uuid: uuid,
            defaultSuggestedAmounts: campaignObj?.defaultSuggestedAmounts,
            defaultFrequency: campaignObj?.defaultFrequency,
          };
          const result = await CampaignSettingSuggestions({ variables });
        }
        if (campaignObj?.status === "unpublish") {
          const variables = {
            uuid: uuid,
          };
          const result = await SavePublishCampaign({ variables });
          if (result?.errors?.message) {
            const sentencesArray = JSON.parse(result?.errors?.message);
            setDialogContent(
              sentencesArray ? sentencesArray : result?.errors?.message
            );
            setOpenDialog(true);
          }
        }
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  const [
    UpdateCampaignTemplate,
    {
      loading: updateCampaignTemplateLoading,
      error: updateCampaignTemplateError,
    },
  ] = useMutation(UPDATE_CAMPAIGN_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to update campaign template was unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(
        success("your request to update campaign template was successful!")
      );
    },
  });
  const [
    CreateCampaignTemplate,
    {
      loading: createCampaignTemplateLoading,
      error: createCampaignTemplateError,
    },
  ] = useMutation(CREATE_CAMPAIGN_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to save selected template unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(success("your request to save selected template successful!"));
    },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [
    SaveUnPublishCampaign,
    { loading: unPublishCampaignLoading, error: unPublishCampaignError },
  ] = useMutation(UNPUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to unpublish campaign was unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(success("your request to unpublish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.unpublishCampaign?.campaign?.status,
      }));
    },
  });
  const [
    UpdateSelectedTemplate,
    { loading: updateCampaignLoading, error: updateCampaignError },
  ] = useMutation(UPDATE_SELECTED_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
    },
    onCompleted: (data) => {},
  });

  const [
    SavePublishCampaign,
    { loading: publishCampaignLoading, error: publishCampaignError },
  ] = useMutation(PUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request to publish campaign was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request to publish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.publishCampaign?.campaign?.status,
      }));
    },
  });

  useEffect(() => {
    if (!imageUrl && (templateNo === "0" || templateNo === "3")) {
      setImageUrl(plant);
    } else if (!imageUrl && (templateNo === "1" || templateNo === "5")) {
      setImageUrl(templateOrange);
    } else if (!imageUrl && (templateNo === "2" || templateNo === "4")) {
      setImageUrl(templateBW);
    } else if (imageUrl) {
      setImageUrl(imageUrl);
    }
  }, [imageUrl]);

  const templateNumber = parseInt(templateNo) + 1;

  return (
    <Grid
      container
      sx={{
        padding: { md: "15px", sm: "15px", xs: "5px" },
        backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        overflow: "hidden",
        paddingTop: "0px",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          md={7}
          sm={7}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid sx={{ width: "fit-content" }}>
            <Button
              disableRipple
              href="/dashboard/campaigns"
              sx={{
                padding: { md: "5px", sm: "0px", xs: "0px" },
                margin: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
                transform: { md: "scale(1)", sm: "scale(0.7)" },
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {themeMode === "dark" ? (
                <img src={logo} alt="" />
              ) : (
                <img src={logoLight} alt="" />
              )}
            </Button>
          </Grid>
          <Grid
            item
            md={6}
            sm={7}
            sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
          >
            <MainSearchBar />
          </Grid>
        </Grid>

        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <button
            onClick={() => (window.location.href = "/dashboard/help")}
            className={classes.btn}
            style={{
              color: themeMode === "dark" ? "#FFF" : "#030303",
              padding: "0px 10px",
              "&:hover": {
                transform: "scale(1.1)",
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                border:
                  themeMode === "dark"
                    ? "1px solid transparent"
                    : "1px solid #9CCA3C",
              },
            }}
            component={Link}
            to="/dashboard/help"
          >
            <HelpOutlineIcon
              sx={{
                fontSize: { md: "30px" },
                color: themeMode === "dark" ? "#FFF" : "#030303",
                "&:hover": {
                  color: themeMode === "dark" ? "#94D906" : "#619000",
                },
              }}
            />
          </button>

          <button
            className={classes.btn}
            onClick={openNotif}
            style={{
              color: themeMode === "dark" ? "#FFF" : "#030303",
              padding: "0px 10px",
              "&:hover": {
                transform: "scale(1.1)",
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                border:
                  themeMode === "dark"
                    ? "1px solid transparent"
                    : "1px solid #9CCA3C",
              },
            }}
          >
            <NotificationsNoneIcon
              sx={{
                fontSize: { md: "30px" },
                color: themeMode === "dark" ? "#FFF" : "#030303",
                "&:hover": {
                  color: themeMode === "dark" ? "#94D906" : "#619000",
                },
              }}
            />
          </button>
          <Menu
            anchorEl={notifBoxVisible}
            open={notifBoxVisible}
            onClose={closeNotif}
            PaperComponent={Paper}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              display: "flex",
              justifyContent: "center",

              width: { md: "90% !important", sm: "100% !important" },

              padding: "10px 18px",
              "& .MuiPaper-root": {
                backgroundColor: "#0F0F0FEE",
                border: "1px solid #FFFFFF",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "flex-end",
                borderRadius: "15px",
                padding: "10px 30px",
              },
              "& .MuiList-root": {},
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "700",
                  textAlign: "left",
                }}
              >
                Notifications
              </Typography>
              <Grid
                item
                my={2}
                md={6}
                sm={6}
                xs={9}
                className={classes.overflowx}
                sx={{
                  flexDirection: "row",
                }}
              >
                <button
                  disableRipple
                  item
                  className={classes.btnList}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid transparent",
                    width: "fit-content",
                    "&:hover": {
                      background: "transparent",
                      color: "#FFB800",
                    },
                  }}
                >
                  <Typography
                    className={classes.btnText}
                    sx={{
                      fontSize: { md: "14px", sm: "14px", xs: "14px" },
                    }}
                  >
                    All
                  </Typography>
                </button>
                <button
                  disableRipple
                  item
                  className={classes.btnList}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid transparent",
                    width: "fit-content",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                >
                  <Typography
                    mx={{ md: 0, sm: 0, xs: 1 }}
                    className={classes.btnText}
                    sx={{
                      fontSize: { md: "14px", sm: "14px", xs: "14px" },
                    }}
                  >
                    Read
                  </Typography>
                </button>
              </Grid>
              <Grid
                mt={1}
                sx={{
                  overflowY: "auto",
                  maxHeight: "45vh",
                  overflowY: "auto",
                  maxHeight: "45vh",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    width: 0,
                    background: "transparent",
                  },
                }}
              >
                {notifications?.map((item, index) => (
                  <Grid
                    key={index}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid #FFFF",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item md={9} sm={9} xs={9}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        {item.text}{" "}
                        <span style={{ color: "#FFAA00", fontWeight: "700" }}>
                          {item.campaign}
                        </span>{" "}
                        campaign
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sm={2}
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        sx={{
                          display: !item.read ? "flex" : "none",
                          width: "11px",
                          height: "11px",
                          backgroundColor: "#FFB800",
                          borderRadius: "50%",
                        }}
                      ></Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Menu>
          <Button
            className={classes.btn2}
            disableElevation
            onClick={handleMenuOpen}
            sx={{
              "&:hover": {
                border: "1px solid #94D906",
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid className={classes.row} container>
              <Grid className={classes.dpHolder} item>
                <img src={dp} alt="" className={classes.w80} />
                <Grid
                  className={classes.colCenter}
                  sx={{
                    display: {
                      md: "flex",
                      sm: "flex",
                      xs: "none",
                    },
                  }}
                >
                  <Grid
                    className={classes.profile}
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    {" "}
                    {user && user?.first_name && user?.last_name
                      ? user?.first_name + " " + user?.last_name
                      : ""}
                  </Grid>
                  <Grid
                    className={classes.profileDull}
                    sx={{
                      flexWrap: "nowrap",
                    }}
                  >
                    <Typography
                      sx={{
                        color: themeMode === "dark" ? "#9F9F9F" : "#0F0F0F",
                        textWrap: "nowrap",
                        fontSize: "11px",
                        textTransform: "capitalize",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      Verified Foundation
                      <img
                        src={verify}
                        alt=""
                        style={{
                          margin: "0px 3px",
                          width: "10px",
                          height: "10px",
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item ml={1}>
                {anchorEl ? (
                  <img
                    src={up}
                    alt=""
                    style={{ width: "10px", height: "10px" }}
                  />
                ) : (
                  <img
                    src={down}
                    alt=""
                    style={{ width: "10px", height: "10px" }}
                  />
                )}
              </Grid>
            </Grid>
          </Button>
          <Menu
            className={classes.menu}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperComponent={Paper}
            sx={{
              "& .MuiPaper-root": {
                width: { md: "17%", sm: "30%", xs: "70%" },
                backgroundImage: "none",
                backgroundColor: themeMode === "dark" ? "#0a0a0a" : "#dbd7d7",
              },
            }}
          >
            <MenuItem
              className={classes.menuItem}
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",

                "&:hover": {
                  backgroundColor: "#2222",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  borderBottom: "1px solid #FFF",
                  padding: "10px 15px",
                  justifyContent: "space-between",
                }}
              >
                Mode : {themeMode === "dark" ? "Dark" : "Light"}
                <ThemeModeSwitch />
              </Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={handleMenuClose}
              component={Link}
              to="/dashboard/settings"
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                "&:hover": {
                  backgroundColor: "#2222",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  borderBottom: "1px solid #FFF",
                  padding: "10px 15px",
                }}
              >
                <img
                  src={settingsIcon}
                  alt=""
                  style={{ margin: "0px 15px 0px 0px" }}
                />
                Settings
              </Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                "&:hover": {
                  color: user ? "#FF0000 !important" : "#689900",
                  backgroundColor: "#2222",
                },
              }}
              onClick={() => {
                user ? handleLogout() : handleLogin();
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  padding: "10px 15px",
                }}
              >
                <img
                  src={logoutIcon}
                  alt=""
                  style={{ margin: "0px 15px 0px 0px" }}
                />
                {user ? "Logout" : "Login"}
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        mt={2}
        sx={{
          display: "flex",
          background: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
          borderRadius: "15px",
          height: "fit-content",
          padding: { md: "40px 20px", sm: "30px 20px" },
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          boxShadow:
            themeMode === "dark"
              ? "0px 0px 0px blue"
              : "4px 4px 14px 0px #00000024",
        }}
      >
        <Grid
          item
          md={9}
          sm={12}
          xs={12}
          ml={{ md: 0, sm: 0, xs: 0 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid
            sx={{
              display: { md: "block", sm: "block", xs: "flex" },
              flexDirection: { md: "column", sm: "column", xs: "row" },
            }}
          >
            <Button
              disableRipple
              onClick={() => navigate(-1)}
              sx={{
                "&:hover": {
                  background: "transparent",
                  transform: "rotate(315deg)",
                },
              }}
            >
              <img src={themeMode === "dark" ? back : backLight} alt="" />
            </Button>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              href="/dashboard/campaigns"
              sx={{
                margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                background: "transparent",
                border: "1px solid transparent",
                display: "flex",
                width: "fit-content",
                justifyContent: "flex-start",
                alignContent: "center",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  textWrap: "nowrap",
                }}
              >
                <img
                  src={themeMode === "dark" ? heart : heartDark}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    fontSize: { md: "16px", sm: "16px", xs: "9px" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Campaigns
                </Typography>
              </Grid>
            </Button>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              <ArrowRightAlt />
            </Grid>
            <Button
              onClick={() => navigate(-1)}
              sx={{
                margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                background: "transparent",
                border: "1px solid transparent",
                display: "flex",
                width: "fit-content",
                justifyContent: "flex-start",
                alignContent: "center",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  textWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    fontSize: { md: "16px", sm: "16px", xs: "9px" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  {campaignState?.title ? campaignState.title : "Campaign"}
                </Typography>
              </Grid>
            </Button>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              <ArrowRightAlt />
            </Grid>
            <Button
              onClick={() => navigate(-1)}
              sx={{
                margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                background: "transparent",
                border: "1px solid transparent",
                display: "flex",
                width: "fit-content",
                justifyContent: "flex-start",
                alignContent: "center",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  textWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    fontSize: { md: "16px", sm: "16px", xs: "9px" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Publish Campaign
                </Typography>
              </Grid>
            </Button>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              <ArrowRightAlt />
            </Grid>
            <Button
              href="/dashboard/campaigns"
              sx={{
                margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                background: "transparent",
                border: "1px solid transparent",
                display: "flex",
                width: "fit-content",
                justifyContent: "flex-start",
                alignContent: "center",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  textWrap: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    textTransform: "capitalize",
                    fontSize: { md: "16px", sm: "16px", xs: "9px" },
                    color: "#8ED003",
                  }}
                >
                  Customize Template
                </Typography>
              </Grid>
            </Button>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              ml={1}
              sx={{
                fontWeight: "600",
                fontSize: { md: "40px", sm: "40px", xs: "26px" },
                fontFamily: "Poppins",
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              Template # {templateNumber} for Campaign {campaignState?.title}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          my={1}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            background:
              themeMode === "dark"
                ? "linear-gradient(270deg, #263238 13.56%, rgba(38, 50, 56, 0) 100%)"
                : "linear-gradient(270deg, #c1d19d 13.56%, rgba(38, 50, 56, 0) 100%)",
            borderRadius: "30px",
          }}
        >
          <Button
            onClick={copyToClipboard}
            disableRipple
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              backgroundColor: "transparent",
              border: "1px solid #689900",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontWeight: "600",
              flexWrap: "nowrap",
              margin: "10px 10px 10px 0px",
              width: "fit-content",
              color: "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                color: "#030303",
              },
            }}
          >
            Get Sharing Link
          </Button>
          <Button
            disableRipple
            onClick={handleSave}
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "transparent",
              color: "#FFF",
              background: "#659500",
              border: "1px solid #689900",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontWeight: "600",
              flexWrap: "nowrap",
              margin: "10px 10px 10px 0px",
              width: "fit-content",
              "&:hover": {
                border: "1px solid #689900",
                backgroundColor: "#8ED003",
              },
              "&:hover": {
                background:
                  "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
              },
            }}
          >
            Save & Publish
          </Button>
        </Grid>
        <Grid
          my={4}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            width: { md: "100%" },
            flexDirection: { md: "row", sm: "row", xs: "column" },
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            md={9.75}
            sm={9.25}
            xs={12}
            sx={{
              display: "flex",
              width: "100%",
              background: themeMode === "dark" ? "#131313" : "#ECECEC",
              borderRadius: "15px",
              height: "fit-content",
              padding: { md: "30px 15px", sm: "30px 10px", xs: "30px 10px" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                mb={3}
                sx={{
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  width: "fit-content",
                  justifyContent: { md: "left", sm: "left", xs: "center" },
                }}
              >
                {themeMode === "dark" ? (
                  <img src={logo} alt="" />
                ) : (
                  <img src={logoLight} alt="" />
                )}
              </Grid>
              <Grid mb={3} md={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    width: "100%",
                    fontWeight: "700",
                    fontSize: "18px",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Donation Form
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <DonationCheckout
                embed="true"
                formTitle={formTitle}
                coverImage={imageUrl}
                formDesc={formDesc}
                template={templateNo}
                displayedFrequency={displayedFrequency}
                displayedSuggestions={displayedSuggestions}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2.5}
            xs={12}
            my={{ md: 0, sm: 0, xs: 3 }}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              sx={{
                textAlign: { md: "left", sm: "left", xs: "center" },
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Stolzl",
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              Customization Panel
            </Typography>
            <Grid
              item
              my={4}
              sx={{
                display: "flex",
                background: themeMode === "dark" ? "#131313" : "#ECECEC",
                borderRadius: "15px",
                height: "fit-content",
                padding: "30px 15px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px blue"
                    : "4px 4px 14px 0px #00000024",
              }}
            >
              {/* <Grid
                item
                md={12}
                mb={3}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item md={6}>
                  <Button onClick={undo} sx={{ borderRadius: "30px" }}>
                    <img
                      src={themeMode === "dark" ? undoIcon : undoIconLight}
                      alt=""
                    />
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button onClick={redo} sx={{ borderRadius: "30px" }}>
                    <img
                      src={themeMode === "dark" ? redoIcon : redoIconLight}
                      alt=""
                    />
                  </Button>
                </Grid>
              </Grid> */}
              <Grid my={2}>
                <FormControl
                  sx={{
                    display: templateNo === "4" ? "flex" : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        width: "100%",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        fontSize: {
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                      }}
                    >
                      Set Title
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      multiline
                      minRows={2}
                      mt={1}
                      fullWidth
                      size="small"
                      variant="outlined"
                      id="outlined-basic"
                      placeholder={formTitle || askPage?.title}
                      value={formTitle ? formTitle : ""}
                      onChange={(event) => setFormTitle(event.target.value)}
                      sx={{
                        width: "100% !important",
                        borderRadius: "10px",
                        backgroundColor:
                          themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                        "& .MuiOutlinedInput-input": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          "&::placeholder": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          fontSize: "12px",
                          marginBottom: "15px",
                          transform: "translate(0px, -40px) scale(1.25)",
                          "&.MuiInputLabel-shrink": {
                            transform: "translate(0px, -40px) scale(1.25)",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          borderWidth: "0.5px",
                          borderRadius: "5px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acff00",
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                        "&.Mui-active .MuiOutlinedInput-notchedOutline": {
                          borderColor: "red",
                        },
                        outline: "none",
                      }}
                    ></TextField>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid>
                <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    mr={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        width: "100%",
                        fontSize: {
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                      }}
                    >
                      Set Image
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <input
                      type="file"
                      ref={imgInputRef}
                      onChange={handleImgFileChange}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleButtonClick(imgInputRef)}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      sx={{
                        borderRadius: "15px",
                        width: { md: "180px", sm: "110px", xs: "200px" },
                        height: "90px",
                        "&:hover": {
                          color: "#FFF",
                        },
                        backgroundImage: `url('${imageUrl}')`,
                        opacity:
                          (imageUrl === plant ||
                            imageUrl === templateBW ||
                            imageUrl === templateOrange) &&
                          !isHovered
                            ? "0.5"
                            : "1",
                        backgroundSize: "cover,cover",
                        backgroundPosition: "center center,10% 50%",
                        border:
                          themeMode === "dark"
                            ? "1px solid #FFF"
                            : "1px solid #000",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        "&:hover": {
                          backdropFilter: "blur(50px)",
                          background: "#0D0D0DE5",
                        },
                      }}
                    >
                      {!imageUrl || (imageUrl && isHovered) ? (
                        <Grid>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "17px",
                              color: "#FFF",
                              textWrap: "nowrap",
                              display: "flex",
                            }}
                          >
                            <img
                              src={upload}
                              alt=""
                              style={{
                                marginRight: "10px",
                              }}
                            />
                            Upload
                          </Typography>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Button>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid my={2}>
                <FormControl
                  sx={{
                    display: templateNo === "1" ? "flex" : "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textWrap: "nowrap",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        textAlign: "left",
                        width: "100%",
                        fontSize: {
                          md: "16px",
                          sm: "14px",
                          xs: "12px",
                        },
                      }}
                    >
                      Set Description
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      multiline
                      minRows={5}
                      mt={1}
                      fullWidth
                      size="small"
                      variant="outlined"
                      id="outlined-basic"
                      placeholder={formDesc || askPage?.message}
                      value={formDesc ? formDesc : ""}
                      onChange={(event) => {
                        setFormDesc(event.target.value);
                      }}
                      sx={{
                        width: "100% !important",
                        borderRadius: "10px",
                        backgroundColor:
                          themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                        "& .MuiOutlinedInput-input": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          "&::placeholder": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          fontSize: "12px",
                          marginBottom: "15px",
                          transform: "translate(0px, -40px) scale(1.25)",
                          "&.MuiInputLabel-shrink": {
                            transform: "translate(0px, -40px) scale(1.25)",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            themeMode === "dark" ? "#ffff" : "#0F0F0F",
                          borderWidth: "0.5px",
                          borderRadius: "5px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#acff00",
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                        "&.Mui-active .MuiOutlinedInput-notchedOutline": {
                          borderColor: "red",
                        },
                        outline: "none",
                      }}
                    ></TextField>
                  </Grid>
                </FormControl>
              </Grid>

              <Grid my={1}>
                <Typography
                  sx={{
                    textWrap: "nowrap",
                    textAlign: "left",
                    width: "100%",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: {
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                  }}
                >
                  Suggested Frequency
                </Typography>
              </Grid>

              <Grid item md={12} sm={12} xs={12} my={1}>
                <FormControl className={classes.fullWidth}>
                  <Typography
                    className={classes.inputText}
                    sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                  >
                    Number of suggestions
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    placeholder={campaignObj?.defaultFrequency || ""}
                    value={campaignObj?.defaultFrequency || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        defaultFrequency: event.target.value,
                      }))
                    }
                    className={classes.select}
                    sx={{
                      width: "100% !important",
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: themeMode === "dark" ? "#000" : "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {freq?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.value}
                        className={classes.menuItem}
                        sx={{
                          display: item.show ? "flex" : "none",
                          flexWrap: "wrap",
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        {item.show > 1
                          ? `${item.show} blocks`
                          : `${item.show} block`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <Grid
                  my={2}
                  item
                  md={12}
                  sx={{
                    background: themeMode === "dark" ? "#2C2C2C" : "#FFF",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {displayedFrequency?.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      md={5.5}
                      my={1}
                      sx={{
                        border:
                          themeMode === "dark"
                            ? "2px solid #FFF"
                            : "2px solid #030303",
                        borderRadius: "10px",
                        color: themeMode === "dark" ? "white" : "black",

                        textWrap: "wrap",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        textAlign: "center",
                        marginRight: "2px",
                      }}
                    >
                      {item.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid my={1}>
                <Typography
                  sx={{
                    textWrap: "nowrap",
                    textAlign: "left",
                    width: "100%",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: {
                      md: "16px",
                      sm: "14px",
                      xs: "12px",
                    },
                  }}
                >
                  Suggested Amount
                </Typography>
              </Grid>

              <Grid item md={12} sm={12} xs={12} my={1}>
                <FormControl className={classes.fullWidth}>
                  <Typography
                    className={classes.inputText}
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    }}
                  >
                    Number of suggestions
                  </Typography>

                  <TextField
                    select
                    variant="outlined"
                    placeholder={numberOfSuggestedAmount || ""}
                    value={numberOfSuggestedAmount || ""}
                    onChange={(event) =>
                      setNumberOfSuggestedAmount(event.target.value)
                    }
                    className={classes.select}
                    sx={{
                      width: "100% !important",
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: themeMode === "dark" ? "#000" : "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {suggestion?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.show}
                        className={classes.menuItem}
                        sx={{
                          display: item.show ? "flex" : "none",
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        {item.show > 1
                          ? `${item.show} blocks`
                          : `${item.show} block`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <Grid
                  my={2}
                  md={12}
                  item
                  sx={{
                    background: themeMode === "dark" ? "#2C2C2C" : "#FFF",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "4px 4px",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {displayedSuggestions?.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      my={1}
                      mx={1}
                      md={4.5}
                      sx={{
                        border:
                          themeMode === "dark"
                            ? "2px solid #FFF"
                            : "2px solid #030303",
                        borderRadius: "10px",
                        textWrap: "nowrap",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        textAlign: "center",
                        color: themeMode === "dark" ? "white" : "black",
                        fontSize: "12px",
                        width:
                          item.value === "500"
                            ? "50%"
                            : item.value === "100"
                            ? "50%"
                            : "28%",
                      }}
                    >
                      Rs. {item.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomizeTemplate;
