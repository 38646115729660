import React from 'react'

const ReminderElement = ({
  reminderTxtColor,
  reminderBkgColor,
  reminderBorderSize,
  reminderBorderRadius,
  reminderBorderColor,
  reminderShadow,
  reminderIconColor,
  reminderBtnEnable
}) => {
  return (
    <>
      
      <div style={{
        border: `${reminderBorderSize}px solid ${reminderBorderColor}`,
        borderRadius: `${reminderBorderRadius}px`,
        boxShadow: reminderShadow ? '1px 1px 5px #FFF' : 'none',
        position: 'absolute',
        bottom: reminderBtnEnable ? '2px' : '0',
        display: reminderBtnEnable ? 'flex' : 'none',
        justifyContent: 'center',
        zIndex: 1000,
      }}>
        <div style={{
          width: '79%',
          padding: '8px 15px',
          backgroundColor: `${reminderBkgColor ? reminderBkgColor : '#7CB602'}`,
          borderTopLeftRadius: `${reminderBorderRadius}px`,
          borderBottomLeftRadius: `${reminderBorderRadius}px`,
          border: `${reminderBorderSize}px solid #757575`,
          border: `1px solid ${reminderBorderColor}`,
          display: 'flex',
          alignItems: 'center',
          color: `${reminderTxtColor ? reminderTxtColor : '#FFFF'}`,
          '&:hover': {
            filter: 'brightness(120%)',
          },
        }}>
          <span style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            alignItems: 'center',
            margin: '0px 10px 0px',
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px" fill={`${reminderIconColor}`}>
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
            </svg>
          </span>
          Complete your gift to make a difference
        </div>

        <div style={{
          width: '39%',
          textTransform: 'capitalize',
          borderTopRightRadius: `${reminderBorderRadius}px`,
          borderBottomRightRadius: `${reminderBorderRadius}px`,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <button style={{
            border: `1px solid ${reminderBorderColor}`,
            borderTopRightRadius: `${reminderBorderRadius}px`,
            padding: '15px',
            backgroundColor: `${reminderBkgColor ? reminderBkgColor : '#7CB602'}`,
            color: `${reminderTxtColor ? reminderTxtColor : '#FFFF'}`,
            '&:hover': {
              backgroundColor: `${reminderBkgColor ? reminderBkgColor : '#7CB602'}`,
              filter: 'brightness(120%)',
            },
          }}>
            I'm ready
          </button>
          <button style={{
            border: `${reminderBorderSize}px solid #757575`,
            border: `1px solid ${reminderBorderColor}`,

            borderBottomRightRadius: `${reminderBorderRadius}px`,
            padding: '15px',
            backgroundColor: `${reminderBkgColor ? reminderBkgColor : '#7CB602'}`,
            color: `${reminderTxtColor ? reminderTxtColor : '#FFFF'}`,
            '&:hover': {
              backgroundColor: `${reminderBkgColor ? reminderBkgColor : '#7CB602'}`,
              filter: 'brightness(120%)',
            },
          }}>
            Not Today
          </button>
        </div>
      </div>
    </>
  )
}
export default ReminderElement
