import React, { useState, useEffect  } from "react";
import { Grid, Typography, Button, TextField, Link } from "@mui/material";
import NavBarAuth from "../../Navbar/NavbarAuth.jsx";
import NavBar from "../../Navbar/Navbar.jsx";
import Footer from "../../Footer/Footer.jsx";
import { useDispatch } from "react-redux";
import { loginSuccess, setuserDataProfile } from "../../../store/authSlice.js";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../services/auth.service.js";
import { useStyles } from "./useStyles.jsx";
import { useSelector } from "react-redux";
import DocumentTitle from "../../../DocumentTitle.js";
import { setSpinnerLoading } from "../../../store/spinnerLoaderSlice.js";
import { success, error as errorMsg } from "../../../store/alertSlice.js";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/signInBkg.png";
const lightBkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/signin.svg"

const ForgotPassword = () => {
  DocumentTitle("Forgot Password - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const redirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/sign_in`;
  // const redirectUrl = `https://www.accceleraise.com/sign_in`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setSpinnerLoading(true));
    try {
      const response = await AuthService.recoverPassword(email, redirectUrl);
      dispatch(setSpinnerLoading(false));
      if(response?.status === 200 && response?.data?.success){
        dispatch(success(response?.data?.message));
        setEmail("");
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      setError("Invalid email");
      console.log(error, 'error')
      dispatch(setSpinnerLoading(false));
    }
  };


    const light="light";
  const dark="dark";

  return (
    <>
      {/* <NavBarAuth /> */}
      {
            themeMode==="dark"?
            <NavBar 
            scrollColor="#081a01" 
            btnColor="transparent" 
            getStartedBorder="1px solid #689900" 
            profileBkg="#2e3322"
            profileBorder="1px solid #94D906"
            logoTheme={light}
            scrollingColor={light}
            textColor="#FFF"
            />
        :
        <NavBar 
            scrollColor="#174f01" 
            btnColor="transparent" 
            getStartedBorder="1px solid #689900" 
            profileBkg="#2e3322"
            profileBorder="1px solid #94D906"
            logoTheme={light}
            scrollingColor={light}
            txtColor="#FFF"
        />
        }
      <Grid
        container
        className={classes.bkg}
        sx={{
          // backgroundImage: `url('${bkg}')`,
          backgroundImage: themeMode==="dark"?`url('${bkg}')`:`url('${lightBkg}')`,
          padding: { md: "150px 70px", sm: "150px 70px", xs: "100px 10px" },
        }}
      >
        <Grid
          item
          md={7.5}
          sm={11}
          xs={11}
          className={classes.box}
          sx={{
            padding: { md: "60px 100px", sm: "60px 100px", xs: "60px 30px" },
            background:themeMode==="dark"? "#0D0D0D85":"#FFFFFFB2",
          }}
        >
          <Typography
            className={classes.fw700}
            sx={{
              fontFamily:"Stolzl",
              fontSize: { md: "54px", sm: "54px", xs: "40px" },
              color:themeMode==="dark"? "#FFFF":"#030303",
            }}
          >
            Hello
          </Typography>
          <Typography
            className={classes.fw400}
            sx={{
              fontFamily: "Stolzl",
              fontSize: { md: "28px", sm: "28px", xs: "22px" },
              color:themeMode==="dark"? "#FFFF":"#030303",
            }}
          >
            Enter Your Email
          </Typography>
          <Typography
            className={classes.fw400}
            sx={{
              fontFamily: "Stolzl",
              fontSize: { md: "16px", sm: "16px", xs: "14px" },
              color:themeMode==="dark"? "#FFFF":"#030303",
            }}
          >
            You will receive password recovery instruction at you email address.
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container className={classes.jCenter}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Your Email Address"
                  name="fname"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.input}
                  sx={{
                    mt: { md: 12, sm: 12, xs: 8 },
                      mr: 1,
                      borderRadius: "10px",
                      backgroundColor:themeMode==="dark"? "#0D0D0D":"#FFFF",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode==="dark"? "#FFFFFF":"#242424",
                        "&::placeholder": {
                          color: themeMode==="dark"? "#FFFFFF":"#242424",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color:themeMode==="dark"? "#FFFF":"#030303",
                        marginBottom: "20px",
                        transform: "translate(0px, -30px) scale(1.1)",
                        "&.MuiInputLabel-shrink": {
                          transform: "translate(0px, -30px) scale(1)",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode==="dark"?"#ffff":"#000000",
                        borderWidth: "0.5px",
                        borderRadius: "5px",
                      },
                      "&:focus": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "red",
                        },
                        outline: "none",
                      },
                  }}
                />
              </Grid>
              <Grid className={classes.flexEnd} item md={12}>
                <Typography className={classes.alignRight} 
                  sx={{color:themeMode==="dark"? "#FFFF":"#030303"}}
                >
                  <Button
                    className={classes.linkButton}
                    disableRipple
                    disableElevation
                    href="/sign_in"
                  >
                    Remembered Password?
                  </Button>
                </Typography>
              </Grid>

              <Grid className={classes.centerFull} my={6} item>
                <Button className={classes.signIn} type="submit"
                  sx={{
                    color: "#FFF",
                    background: "#689900",
                    "&:hover": {
                      color:themeMode==="dark"? "#FFF":"#689900",
                      background: "transparent",
                      border: "2px solid #689900",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            {error && (
              <Typography color="error" mt={2} className={classes.textCenter}>
                {error}
              </Typography>
            )}
          </form>
          <Grid item p={1} md={12} className={classes.centerFull}>
            <Typography className={classes.textCenter} href="#"
              sx={{
                fontFamily: "Stolzl",
                color:themeMode==="dark"? "#FFFF":"#030303",
              }}
            >
              Don’t Have an Account?
              <Button
                disableRipple
                disableElevation
                href="/signup"
                className={classes.signUp}
                sx={{
                  fontSize:"15px",
                  color:themeMode==="dark"? "#FFFF":"#030303",
                }}
              >
                {"  "}
                Sign up here
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      {themeMode === "dark" ? (
        <Footer
          bkg={bkg}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      ) : (
        <Footer
          bkg={lightBkg}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      )}
    </>
  );
};
export default ForgotPassword;
