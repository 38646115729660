import { Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import { allClients } from "../../../AuthPages/SignUp/Clients/clientsList";
import { category } from "./data";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const Hero = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 0px 10px",
            sm: "130px 30px 10px",
            xs: "100px 15px",
          },
          filter: themeMode === "dark" ? "" : "brightness(500%)",
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={7.5}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "26px" },
              fontFamily: "Stolzl",
            }}
          >
            Pricing
          </Typography>
          <Typography
            sx={{
              fontSize: { md: "16px", sm: "16px", xs: "12px" },
              fontFamily: "Stolzl",
              fontWeight: themeMode === "dark" ? "600" : "300",
            }}
          >
            Choose a pricing plan that aligns with your organization's needs and
            budget. Our transparent, no-surprise pricing means you can plan your
            fundraising with confidence, with the assurance of no hidden fees,
            no monthly charges, and absolutely no initial setup cost.
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          my={5}
          className={classes.overflowx}
          sx={{ flexDirection: { md: "row", sm: "row", xs: "column" } }}
        >
          {category?.map((item, index) => (
            <Button
              disableRipple
              item
              key={index}
              className={classes.btnList}
              sx={{
                backgroundColor:
                  themeMode === "dark" ? "transparent" : "'#000003B",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px #aa39cc"
                    : "0px 8px 5px 0px #000000",
                padding: { md: "10px 18px", sm: "10px 18px", xs: "10px 1px" },
                width: "fit-content",
                mx: { md: 1, sm: 1, xs: 0 },
                my: { md: 0, sm: 0, xs: 1 },
                "&:hover": {
                  textShadow:
                    themeMode === "dark"
                      ? "1px 1px 10px #FFFF"
                      : "1px 1px 10px #0c000f",
                  backgroundColor:
                    themeMode === "dark" ? "transparent" : "#000000",
                },
              }}
            >
              <Typography
                variant="inherit"
                mx={{ md: 0, sm: 0, xs: 1 }}
                className={classes.btnText}
                sx={{
                  fontWeight: themeMode === "dark" ? "600" : "300",
                  fontSize: { md: "14px", sm: "14px", xs: "10px" },
                  fontFamily: "Stolzl",
                  color: "#FFFFFF !important",
                }}
              >
                {item.name}
              </Typography>
            </Button>
          ))}
        </Grid>

        <Grid className={classes.flexHolder} item md={12} sm={12} xs={12}>
          <Grid
            className={classes.gridHolder}
            item
            md={11}
            sm={12}
            xs={12}
            sx={{
              gridTemplateColumns: {
                md: "repeat(6,1fr)",
                sm: "repeat(3,1fr)",
                xs: "repeat(3,1fr)",
              },
            }}
          >
            {allClients?.map((item, index) => (
              <Grid
                key={index}
                className={classes.imgHolder}
                sx={{ paddingY: { md: "0px", sm: "10px", xs: "10px" } }}
              >
                <img src={item.client} alt="" />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Hero;
