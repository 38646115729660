import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "../useStyles";
import { useSelector } from "react-redux";
import ChartComponent from "../../../Insights/Graph/ChartComponent.jsx";
import dayjs from "dayjs";

const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/down.svg";
const graph =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/graph.svg";

export const GraphAnalysis = ({ donationsByDates }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  if (!donationsByDates || Object.keys(donationsByDates)?.length === 0) {
    const currentDate = new Date();
    donationsByDates = { [dayjs(currentDate)?.format("YYYY-MM-DD")]: 0 };
  }
  return (
    <Grid
      mx={1}
      className={classes.box}
      sx={{
        backdropFilter: {
          md: "blur(11px)",
          sm: "blur(11px)",
          xs: "blur(11px)",
        },
        boxShadow:
          themeMode === "dark"
            ? "0px 0px 0px red"
            : "0px 8px 5px 0px #00000040",
        backgroundColor: themeMode === "dark" ? "#0D0D0DE5" : "#FFF",
        color: themeMode === "dark" ? "#FFF" : "#030303",
        "&::before": {
          content: "''",
          position: "absolute",
          inset: 0,
          borderRadius: "15px",
          padding: "1px",
          background:
            themeMode === "dark"
              ? "linear-gradient(145deg, white, transparent)"
              : "linear-gradient(180deg, white, transparent)",
          WebkitMask:
            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "xor",
          maskComposite: "exclude",
        },
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        {/* <Grid className={classes.tag}>
          <Button className={classes.down}>
            <Typography className={classes.downBtn} mx={1}
              sx={{color:themeMode==="dark"? "#FFFF":"#030303",}}
            >
              This Month{" "}
            </Typography>
            <img src={down} alt="" />
          </Button>
        </Grid> */}
        <Grid mb={3} className={classes.full}>
          <Typography
            className={classes.f14w700}
            sx={{
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Per Day Collection{" "}
          </Typography>
        </Grid>
      </Grid>

      <Grid item sx={{ height: "100%", display: "flex", alignItems: "center" }}>
        {/* <img src={graph} alt="" className={classes.full} sx={{height:"100%"}}/> */}
        <ChartComponent donationsByDates={donationsByDates} chartType={"bar"} />
      </Grid>
    </Grid>
  );
};
