import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import './useStyle.css'

const up = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upBlack.svg"
const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg"


export const Popup = ({ imageUrl, showImg, title, content, labelColor, backgroundColor, borderSize, borderRadius, borderColor, label, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, shadow }) => {

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    //border: "1px solid #FFF",
                    borderRadius: borderRadius ? borderRadius : "15px",
                    border: borderSize || borderColor ? `${borderSize ? borderSize : '1'}px solid ${borderColor ? borderColor : 'transparent'}` : 'none',
                    width: "80%",
                    height: "fit-content",
                    overflow: "none",
                    backgroundColor: backgroundColor ? backgroundColor : "#FFF",
                    boxShadow: shadow ? '2px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", width: "fit-content", height: "100%", backgroundSize: "#FFF", borderRadius: "15px", }}>
                    {showImg && 
                        <div style={{ borderRadius: "15px 15px 0px 0px" }} >
                            <img src={imageUrl} alt="" style={{ width: "100%", height: "100%", borderRadius: "15px 15px 0px 0px" }} />
                        </div>
                    }

                    <div
                        style={{ width: "100%", backgroundColor: "#FFF",marginTop:"10px" }}>
                        <p style={{
                            color: labelColor ? labelColor : "#000",
                            display: "flex",
                            fontSize: "20px",
                            textAlign: "center",
                            justifyContent: "center",
                            border: "0px solid #000",
                            fontWeight: "700",
                        }}>
                            {title ? title : 'Popup Title'}
                        </p>
                    </div>


                    <div style={{ width: "100%", backgroundColor: "#FFF", height: "100%", color: labelColor ? labelColor : "#000" }}>
                        <p style={{ color: "#000", display: "flex", justifyContent: "left", fontSize: "14px", padding: "15px" }}>
                            {content ? content : 'This content is usually a short paragraph explaining why the viewer should make a donation today. Given that popups interrupt site navigation until closed, make sure that it feels important!'}
                        </p>
                    </div>


                    <div style={{ padding: "5px", width: "100%",  borderRadius: "0px 0px 15px 15px", }}>
                        <button class="proceed" id='proceed-btn' style={{ backgroundColor:  btnColor ? btnColor : '#A4B200', borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '10px', border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '1'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : 'none' }}>
                            {label ? label : 'Donate'}
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}
