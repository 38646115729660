import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  MenuItem, InputAdornment,
  FormControlLabel, Checkbox
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { MySwitch } from "../CreateCampaign/MyComponents.jsx";
import { makeStyles } from "@mui/styles";
import { donations, recurring, p2p, report } from "./data";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
//import { GET_CAMPAIGN } from "./Query";

const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const copy = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/copyIcon.svg"
const question = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const profileIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/profileIcon.svg";
const install = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/install.svg";
const bell = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/bellSmall.svg";
const profile = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/profileImg.svg";
const installBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/installBlack.svg";
const bellBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/bellSmallBlack.svg";
const profileBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/profileIconBlack.svg";

const Settings = ({ showPopupCallback }) => {
  const classes = useStyles();
  const [mode, setMode] = useState("Profile");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const logoFileInputRef = useRef(null);
  const [logoFile, setlogoFile] = useState(profile);
  const [imageLogoUrl, setImageLogoUrl] = useState(profile);
  

  const ProfileMode = () => {
    setMode("Profile");
  };
  const NotificationMode = () => {
    setMode("Notification");
  };
  const InstallationMode = () => {
    setMode("Installation");
  };

  const handleButtonClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleLogoFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setlogoFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageLogoUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
          display: "flex",
          flexDirection: "row",
          width: "100%"
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "28px" },
              fontWeight: "600",
              textAlign: { md: "left", sm: "left", xs: "center" },
              textWrap: "nowrap",
              color:themeMode==="dark"? "#FFFF":"#030303"
            }}
          >
            Settings
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "space-between",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            // md={12}
            // sm={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: { md: "fit-content", sm: "fit-content", xs: "fit-content" },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              overflowX: "auto",
              position: "relative"
            }}
          >
            <Button
              onClick={ProfileMode}
              disableRipple
              sx={{
                color: "#FFF",
                background: mode === "Profile" ?
                  "linear-gradient(274.38deg, rgba(97, 143, 0, 0.79) 4.85%, rgba(140, 207, 1, 0.79) 112.34%)"
                  :
                  "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 8px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                },
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "fit-content",
                }}
              >

                <Typography sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" }, display: "flex", alignItems: "center", textWrap: "nowrap", fontWeight: "600"
                }}>
                  <img src={themeMode==="dark"?profileIcon:profileBlack} alt="" style={{ marginRight: "10px" }} />
                  Profile
                </Typography>

              </Grid>
            </Button>

            <Button
              onClick={NotificationMode}
              disableRipple
              sx={{
                color: "#FFF",
                background:
                  mode === "Notification" ?
                    "linear-gradient(274.38deg, rgba(97, 143, 0, 0.79) 4.85%, rgba(140, 207, 1, 0.79) 112.34%)"
                    :
                    "transparent",
                border: "1px solid #94DA00",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                },
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "fit-content",
                }}
              >

                <Typography sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" }, display: "flex", alignItems: "center", textWrap: "nowrap", fontWeight: "600"
                }}>
                  <img src={themeMode==="dark"?bell:bellBlack} alt="" style={{ marginRight: "10px" }} />
                  Notifications
                </Typography>
              </Grid>
            </Button>

            <Button
              onClick={InstallationMode}
              disableRipple
              sx={{
                color: "#FFF",
                background: mode === "Installation" ?
                  "linear-gradient(274.38deg, rgba(97, 143, 0, 0.79) 4.85%, rgba(140, 207, 1, 0.79) 112.34%)"
                  :
                  "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 6px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                },
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "fit-content",
                }}
              >
                <Typography sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" }, display: "flex", alignItems: "center", textWrap: "nowrap", fontWeight: "600"
                }}>
                  <img src={themeMode==="dark"?install:installBlack} alt="" style={{ marginRight: "10px" }} />
                  Installation
                </Typography>
              </Grid>
            </Button>
          </Box>
        </Grid>




        {
          mode === "Profile" ?
            (
              <Grid item md={12} sm={12} xs={12}>
                <Grid sx={{ display: "flex" }}>
                  <Grid item md={3} sm={5.5} xs={mode === "Profile" ? 12 : 6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginTop: "24px",
                        fontWeight: "600",
                        width: "fit-content",
                        color:themeMode==="dark"? "#FFFF":"#030303"
                      }}
                    >
                      First Name
                    </Typography>

                    <Grid>
                      <FormControl
                        sx={{ width: "100%" }}
                      //onSubmit={handleSubmit}
                      >
                        <TextField
                          variant="outlined"
                          sx={{
                            mt: { md: 1, sm: 2, xs: 2 },
                            "& .MuiSelect-icon": {
                              fill: "#689900",
                            },
                            "& .MuiSelect-selectMenu": {
                              backgroundColor: "#160609",
                            },
                            backgroundColor: themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                            borderRadius: "10px",
                            width: "100%",
                            "& .MuiOutlinedInput-input": {
                    color: themeMode==="dark"?"#ffff":"#000",
                    "&::placeholder": {
                      color: themeMode==="dark"?"#ffff":"#000",
                    },
                  },
                            "& .MuiInputLabel-root": {
                              color: "#FFFFFF",
                              fontSize: "16px",
                              marginBottom: "20px",
                              transform: "translate(0px, -100%) scale(0.85)",
                              "&.MuiInputLabel-shrink": {
                                transform: "translate(0px, -100%) scale(0.85)",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: themeMode==="dark"?"#ffff":"#0F0F0F",
                              borderWidth: "0.5px",
                              borderRadius: "5px",
                            },
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#FFF",
                              },
                              outline: "none",
                            },
                          }}
                        >
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>




                  <Grid py={2} sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Typography mx={{ md: 3, sm: 2, xs: 1 }} sx={{ fontSize: "16px", fontWeight: "500" }}></Typography>
                  </Grid>
                  <Grid item md={3} sm={5.5} xs={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginTop: "24px",
                        fontWeight: "600",
                        width: "fit-content",
                        color:themeMode==="dark"? "#FFFF":"#030303"
                      }}
                    >
                      Last Name
                    </Typography>
                    <Grid>
                      <FormControl
                        sx={{ width: "100%" }}
                      //onSubmit={handleSubmit}
                      >
                        <TextField
                          variant="outlined"
                          sx={{
                            mt: { md: 1, sm: 2, xs: 2 },
                            "& .MuiSelect-icon": {
                              fill: "#689900",
                            },
                            "& .MuiSelect-selectMenu": {
                              backgroundColor: "#160609",
                            },
                            backgroundColor: themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                            borderRadius: "10px",
                            width: "100%",
                            "& .MuiOutlinedInput-input": {
                    color: themeMode==="dark"?"#ffff":"#000",
                    "&::placeholder": {
                      color: themeMode==="dark"?"#ffff":"#000",
                    },
                  },
                            "& .MuiInputLabel-root": {
                              color: "#FFFFFF",
                              fontSize: "16px",
                              marginBottom: "20px",
                              transform: "translate(0px, -100%) scale(0.85)",
                              "&.MuiInputLabel-shrink": {
                                transform: "translate(0px, -100%) scale(0.85)",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: themeMode==="dark"?"#ffff":"#0F0F0F",
                              borderWidth: "0.5px",
                              borderRadius: "5px",
                            },
                            "&:hover": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#FFFF",
                              },
                              outline: "none",
                            },
                          }}
                        >
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>



                <Grid my={4} item md={6} sm={4} xs={12}>
                <Typography className={classes.insert} mb={2} sx={{color:themeMode==="dark"? "#FFFF":"#030303"}}>
                    Profile Picture
                </Typography>
                <Grid className={classes.spaceBtwn} item md={6.5}>
                  <input
                    type="file"
                    ref={logoFileInputRef}
                    onChange={handleLogoFileChange}
                    style={{ display: "none" }}
                  />
                  {logoFile && (
                    <Grid
                      className={classes.upload}
                      sx={{
                        backgroundImage: `url(${imageLogoUrl})`,
                        border:themeMode==="dark"?"0.25px solid #0F0F0F":"0.25px solid #FFF",
                        
                      }}
                    ></Grid>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => handleButtonClick(logoFileInputRef)}
                    className={classes.uploadButton}
                  >
                    <Grid sx={{ display: "flex" }}>
                      <Grid className={classes.flexCenter}>
                        {/* <img src={upload} alt="" /> */}
                      </Grid>
                      <Grid mx={1} className={classes.flexCenter}>
                        <Typography className={classes.buttonText}
                          sx={{color:themeMode==="dark"? "#ACFF00":"#8ED003",
                        "&:hover":{
                              color: themeMode==="dark"?"#bbfc32":"#659500",
                            }}}
                        >
                          Change Image
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                  
                </Grid>
              </Grid>



                <Grid item md={10} my={4} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                  <Grid item md={12} sm={6} xs={12}>
                    <Typography sx={{color: themeMode==="dark"?"#ffff":"#000",}}>
                      Email Address
                    </Typography>
                    <Grid my={{ md: 0, sm: 0, xs: 4 }}
                      sx={{ width: { md: "80%", sm: "80%", xs: "100%" } }}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        variant="standard"
                        placeholder="anabimam@gmail.com"
                        InputLabelProps={{
                          //className: classes.label,
                          className: classes.placeholder
                        }}
                        InputProps={{
                          classes: {
                              underline: themeMode==="dark"?classes.underline:classes.underlineLight,
                              input: themeMode==="dark"?classes.input:classes.inputLight,
                          },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Typography
                                variant='body2'
                                color='#6C9D06'
                              >
                                Change Email
                              </Typography>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} sm={6} xs={12}>
                    <Typography sx={{color:themeMode==="dark"? "#FFFF":"#030303",}}>
                      Password
                    </Typography>
                    <Grid my={{ md: 0, sm: 0, xs: 4 }}
                      sx={{ width: { md: "70%", sm: "80%", xs: "100%" } }}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        variant="standard"
                        placeholder="*************"
                        InputLabelProps={{
                          //className: classes.label,
                          className: classes.placeholder
                        }}
                        InputProps={{
                          classes: {
                            underline: themeMode==="dark"?classes.underline:classes.underlineLight,
                            input: themeMode==="dark"?classes.input:classes.inputLight,
                          },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Typography
                                variant='body2'
                                color='#6C9D06'
                              >
                                Change Password
                              </Typography>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

               

                <Grid
                  my={{ md: 5, sm: 5, xs: 0 }}
                  px={6}
                  py={4}
                  sx={{
                    backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                    boxShadow: themeMode==="dark"?"0px 0px 0px red": "0px 8px 5px 0px #00000040",
                    width: "fit-content",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: { md: "row", sm: "row", xs: "column" },

                  }}
                >
                  <Typography mr={{ md: 4, sm: 4, xs: 0 }} sx={{ fontSize: "16px", textAlign: { md: "left", sm: "left", xs: "center" }, display: 'flex', alignItems: "center",color: themeMode === "dark" ? "#FFFF" : "#030303",  }}>
                    Two Factor Authentication

                    <img src={question} alt="" style={{ marginLeft: "10px" }} />

                  </Typography>
                  <Grid my={{ md: 0, sm: 0, xs: 2 }} sx={{ display: "flex", justifyContent: "center" }}>
                    <MySwitch />

                    <Typography ml={2} sx={{ fontSize: "16px",color: themeMode === "dark" ? "#FFFF" : "#030303",  }}>
                      Disabled
                    </Typography>
                  </Grid>
                </Grid>
                

                 <Grid container justifyContent="flex-start" my={6}>

                  <Grid item md={3} sm={4} xs={6}>
                    <Button
                      disableRipple
                      sx={{
                        color: "#FFF",
                        background: "#659500",
                        border: "1px solid #689900",
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                        flexWrap: "nowrap",
                        margin: "10px 10px 10px 0px",
                        width: "fit-content",
                        fontSize: { md: "14px", sm: "14px", xs: "8px" },
                        textWrap: "nowrap",
                        "&:hover": {
                          backgroundColor: "#2e3322",
                        },
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>



              </Grid>
            )
            :
            mode === "Notification" ?
              (
                <Grid item md={12} sm={12} xs={12} mt={5}>
                  <Grid item md={9}>
                    <Typography sx={{color: themeMode === "dark" ? "#FFFF" : "#030303",}}>
                      Notifications are emails to you as a user, notifying you of account activity. Choose the notification emails that are most relevant to you and your responsibilities.
                    </Typography>
                  </Grid>

                  <Grid my={5} item md={9} sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid item md={6} sx={{ display: 'flex', flexDirection: "column" }}>
                      <Typography sx={{ textTransform: "uppercase", fontSize: "16px", fontWeight: "600",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        Donations
                      </Typography>
                      <Grid sx={{ display: 'flex', flexDirection: "column" }}>
                        {
                          donations?.map((item, index) => (
                            <FormControlLabel key={index}
                              control={
                                <Checkbox
                                  className={classes.checkbox}
                                  //checked={agreeToProcess}
                                  //onChange={handleAgreeToProcessChange}
                                  style={{ color: themeMode === "dark" ? "#FFFF" : "#659500", }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { md: "16px", xs: "16px", xs: "12px" },
                                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                                  }}>
                                  {item.text}
                                </Typography>
                              }
                              size="small"
                            />
                          ))
                        }

                      </Grid>
                    </Grid>

                    <Grid item md={6} sx={{ display: 'flex', flexDirection: "column" }}>
                      <Typography sx={{ textTransform: "uppercase", fontSize: "16px", fontWeight: "600",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        RECURRING PLANS
                      </Typography>
                      <Grid sx={{ display: 'flex', flexDirection: "column" }}>
                        {
                          recurring?.map((item, index) => (
                            <FormControlLabel key={index}
                              control={
                                <Checkbox
                                  className={classes.checkbox}
                                  //checked={agreeToProcess}
                                  //onChange={handleAgreeToProcessChange}
                                  style={{ color: "#FFFF",color: themeMode === "dark" ? "#FFFF" : "#659500", }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { md: "16px", xs: "16px", xs: "12px" },
                                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                                  }}>
                                  {item.text}
                                </Typography>
                              }
                              size="small"
                            />
                          ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>


                  <Grid my={5} item md={9} sx={{ display: "flex", flexDirection: "row" }}>

                    <Grid item md={6} sx={{ display: 'flex', flexDirection: "column" }}>
                      <Typography sx={{ textTransform: "uppercase", fontSize: "16px", fontWeight: "600",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        SUMMARY REPORTS
                      </Typography>
                      <Grid sx={{ display: 'flex', flexDirection: "column" }}>
                        {
                          report?.map((item, index) => (
                            <FormControlLabel key={index}
                              control={
                                <Checkbox
                                  className={classes.checkbox}
                                  //checked={agreeToProcess}
                                  //onChange={handleAgreeToProcessChange}
                                  style={{ color: "#FFFF",color: themeMode === "dark" ? "#FFFF" : "#659500", }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { md: "16px", xs: "16px", xs: "12px" },
                                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                                  }}>
                                  {item.text}
                                </Typography>
                              }
                              size="small"
                            />
                          ))
                        }

                      </Grid>
                    </Grid>

                    <Grid item md={6} sx={{ display: 'flex', flexDirection: "column" }}>
                      <Typography sx={{ textTransform: "uppercase", fontSize: "16px", fontWeight: "600",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        P2P FUNDRAISERS
                      </Typography>
                      <Grid sx={{ display: 'flex', flexDirection: "column" }}>
                        {
                          p2p?.map((item, index) => (
                            <FormControlLabel key={index}
                              control={
                                <Checkbox
                                  className={classes.checkbox}
                                  //checked={agreeToProcess}
                                  //onChange={handleAgreeToProcessChange}
                                  style={{ color: "#FFFF",color: themeMode === "dark" ? "#FFFF" : "#659500", }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { md: "16px", xs: "16px", xs: "12px" },
                                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                                  }}>
                                  {item.text}
                                </Typography>
                              }
                              size="small"
                            />
                          ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>

                   <Grid container justifyContent="flex-start" my={6}>

                  <Grid item md={3} sm={4} xs={6}>
                    <Button
                      disableRipple
                      sx={{
                        color: "#FFF",
                        background: "#659500",
                        border: "1px solid #689900",
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        fontWeight: "600",
                        padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                        flexWrap: "nowrap",
                        margin: "10px 10px 10px 0px",
                        width: "fit-content",
                        fontSize: { md: "14px", sm: "14px", xs: "8px" },
                        textWrap: "nowrap",
                        "&:hover": {
                          backgroundColor: "#2e3322",
                        },
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>

                </Grid>
              )
              :
              mode === "Installation" ?
                (
                  <>
                    <Grid item my={5} md={9}>
                      <Typography sx={{ fontSize: "24px", fontWeight: "600",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>Installation</Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: "400",color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        Your installation code enables your website to use Fundraise Up.
                        By embedded the code below into
                        section of your website's code, you will be able to display the Checkout modal, Elements, P2P fundraising components and more on all pages of your site
                      </Typography>
                    </Grid>

                    <Grid container mt={2} md={9} alignItems="flex-start" justifyContent="flex-end" sx={{ position: "relative", zIndex: "1" }}>
                      <Grid item>
                        <Button variant="contained"
                          sx={{ 
                            position: "absolute", 
                            zIndex: "2", 
                            right: "3%", 
                            top: "4%", 
                            color: "#FFF", 
                            borderRadius:"10px",
                            background:themeMode==="dark"? "#252525":"#949494", 
                            padding: "8px 15px",
                            "&:hover" :{
                              background: "#80BD00", 
                            },
                            "&:focus":{
                              background: "#80BD00", 
                            }
                          }}
                        >
                          <Typography sx={{ fontSize: "12px", fontWeight: "400", fontFamily: "Poppins", display: "flex", alignItems: "center", textTransform: "capitalize" }}>
                            <img src={copy} alt="" style={{ marginRight: "10px" }} />
                            Copy
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          multiline
                          minRows={3}
                          value="<!-- Fundraise Up: the new standard for online giving -->
                                <script>(function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
                                .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
                                :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
                                j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
                                t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
                                for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
                                })(window,document,'script','FundraiseUp','AEGJEHBT');</script>
                                <!-- End Fundraise Up -->"
                          placeholder="<!-- Fundraise Up: the new standard for online giving -->
                                <script>(function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
                                .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
                                :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
                                j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
                                t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
                                for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
                                })(window,document,'script','FundraiseUp','AEGJEHBT');</script>
                                <!-- End Fundraise Up -->"
                          mt={1}
                          fullWidth
                          size="small"
                          className={classes.fullInput}
                          id="outlined-basic"
                          sx={{
                            backgroundColor: themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: themeMode==="dark"?"#ffff":"#0F0F0F"
                            },
                            "& .MuiOutlinedInput-input": {
                              color: themeMode==="dark"?"#ffff":"#000",
                              "&::placeholder": {
                                color: themeMode==="dark"?"#ffff":"#000",
                                fontSize: {
                                  md: '16px',
                                  sm: '16px',
                                  xs: '11px'
                                }
                              },
                            },
                            width: '100% !important',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode==="dark"?"#ffff":"#0F0F0F"
                            },
                            '&:focus .MuiOutlinedInput-notchedOutline': {
                              borderColor: themeMode==="dark"?"#ffff":"red"
                            },

                          }}
                        />
                      </Grid>
                    </Grid>

                  </>
                )
                :
                ""
        }













      </Grid>
    </>
  );
};
export default Settings;
