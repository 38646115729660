import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { platform, revenue, discover, nature } from "./items";
import { UPDATE_USER_REQUEST } from "./Query";
import { useStyles } from "./useStyles";
import { useMutation } from "@apollo/client";
import { setSpinnerLoading } from '../../../../store/spinnerLoaderSlice';
import { useDispatch } from 'react-redux';
import { success, error as errorMsg } from '../../../../store/alertSlice';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'

const tick = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/tick.png";

const Question = ({ onClose, userId }) => {
  const classes = useStyles();
  const themeMode = useSelector(state => state.themeMode.mode)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRevenue, setSelectedRevenue] = useState("none");
  const [selectedPlatform, setSelectedPlatform] = useState("none");
  const [selectedDiscover, setSelectedDiscover] = useState("none");
  const [selectedNature, setSelectedNature] = useState("none");

  const [UpdateUserRequest, { loading: Loading, error: Error }] = useMutation(UPDATE_USER_REQUEST, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg('request was unsuccessful!'));
    },
    onCompleted: (data) => {
      
      dispatch(success('The information you provided will help us personalize your experience. Thanks for your time!'));
    },
  });

  const handleSave = async (event) => {
    event.preventDefault();
    if(selectedRevenue || selectedPlatform || selectedDiscover || selectedNature){
      const variables = {
        uuid: userId,
        nonprofitAnnualRevenue: selectedRevenue,
        donationPlatform: selectedPlatform,
        discoverFrom: selectedDiscover,
        workType: selectedNature,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await UpdateUserRequest({ variables });
        dispatch(setSpinnerLoading(false));
        navigate("/");
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
      }
    }
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Grid
        className={classes.blur}
        container
        sx={{
          padding: { md: "5px 25%", sm: "0px 15%", xs: "5px 0%" },
          top: { md: "4%", sm: "0px", xs: "0px" },
          
        }}
      >
        <Grid
          item
          md={10}
          sm={12}
          xs={12}
          className={classes.box}
          sx={{
            transform: {
              md: "scale(0.75)",
              sm: "scale(0.65)",
              xs: "scale(0.75)",
            },
            left: { md: "10%", sm: "5%", xs: "0%" },
            background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
            boxShadow:
              themeMode === 'dark'
                ? '0px 0px 0px red'
                : '0px 8px 5px 0px #00000040',
            color: themeMode === 'dark' ? '#FFFF' : '#030303',
          }}
        >
          <Grid
            item
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
            }}
          >
            <Grid
              className={classes.jCenter}
              sx={{
                transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.7)" },
              }}
            >
              <img src={tick} alt="" />
            </Grid>
            <Grid className={classes.column}>
              <Typography
                className={classes.fw500}
                sx={{
                  fontSize: { md: "25px", sm: "20px", xs: "15px" },
                }}
              >
                Your Information was Sent
              </Typography>
              <Typography
                className={classes.fw400}
                sx={{
                  fontSize: { md: "15px", sm: "12px", xs: "10px" },
                }}
              >
                Before you go, answer few questions to help us personalize your
                experience.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            className={classes.fullRel}
            item
            md={12}
            sx={{
              padding: { md: "30px 0px", sm: "10px 0px", xs: "15px 0px" },
            }}
          >
            <Grid my={{ md: 0, sm: 3, xs: 2 }}>
              <FormControl className={classes.fullRel}>
                <Typography
                  className={classes.alignLeft}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "13px" },
                  }}
                >
                  What is your nonprofit's annual online donation revenue?
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  value={selectedRevenue}
                  onChange={(event) => setSelectedRevenue(event.target.value)}
                  className={classes.select}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        color: themeMode === 'dark' ? '#ffff' : '#000'
                      }
                    },
                    backgroundColor:
                      themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === 'dark' ? '#ffff' : '#000',
                      "&::placeholder": {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === 'dark' ? '#ffff' : '#000',
                    }
                  }}
                  InputLabelProps={{
                    shrink: selectedRevenue !== "",
                    className: classes.placeholderLabel,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          Width: "80%",
                          background:themeMode==="dark"? "#000":"#FFF",
                          scrollbarWidth: 'thin',
                          scrollbarColor:
                            themeMode === 'dark'
                              ? '#8ED003CC #0F0F0F'
                              : '#8ED003 #E2E2E2',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="none" disabled className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                    }}
                  >
                    Select an Option
                  </MenuItem>
                  {revenue?.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      className={classes.menuItem}
                      sx={{
                        color: themeMode==="dark"?"#fff":"#030303",
                        backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                      }}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid my={{ md: 2, sm: 3, xs: 2 }}>
              <FormControl className={classes.fullRel}>
                <Typography
                  className={classes.alignLeft}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "13px" },
                  }}
                >
                  What donation platform do you currently use?
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  value={selectedPlatform}
                  onChange={(event) => setSelectedPlatform(event.target.value)}
                  className={classes.select}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        color: themeMode === 'dark' ? '#ffff' : '#000'
                      }
                    },
                    backgroundColor:
                      themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === 'dark' ? '#ffff' : '#000',
                      "&::placeholder": {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === 'dark' ? '#ffff' : '#000',
                    }
                  }}
                  InputLabelProps={{
                    shrink: selectedPlatform !== "",
                    className: classes.placeholderLabel,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          Width: "80%",
                          background: themeMode === "dark" ? "#000" : "#FFF",
                          scrollbarWidth: 'thin',
                          scrollbarColor:
                            themeMode === 'dark'
                              ? '#8ED003CC #0F0F0F'
                              : '#8ED003 #E2E2E2',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="none" disabled className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                    }}>
                    Select a Platform
                  </MenuItem>
                  {platform?.map((app, index) => (
                    <MenuItem
                      key={index}
                      value={app.name}
                      className={classes.menuItem}
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#030303",
                        backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                      }}
                    >
                      {app.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid my={{ md: 2, sm: 3, xs: 2 }}>
              <FormControl className={classes.fullRel}>
                <Typography
                  className={classes.alignLeft}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "13px" },
                  }}
                >
                  How did you discover Acceleraise?
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  value={selectedDiscover}
                  onChange={(event) => setSelectedDiscover(event.target.value)}
                  className={classes.select}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        color: themeMode === 'dark' ? '#ffff' : '#000'
                      }
                    },
                    backgroundColor:
                      themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === 'dark' ? '#ffff' : '#000',
                      "&::placeholder": {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === 'dark' ? '#ffff' : '#000',
                    }
                  }}
                  InputLabelProps={{
                    shrink: selectedDiscover !== "",
                    className: classes.placeholderLabel,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          Width: "80%",
                          background: themeMode === "dark" ? "#000" : "#FFF",
                          scrollbarWidth: 'thin',
                          scrollbarColor:
                            themeMode === 'dark'
                              ? '#8ED003CC #0F0F0F'
                              : '#8ED003 #E2E2E2',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="none" disabled className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                    }}>
                    Select an Option
                  </MenuItem>
                  {discover?.map((us, index) => (
                    <MenuItem
                      key={index}
                      value={us.name}
                      className={classes.menuItem}
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#030303",
                        backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                      }}
                    >
                      {us.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid my={{ md: 2, sm: 3, xs: 2 }}>
              <FormControl className={classes.fullRel}>
                <Typography
                  className={classes.alignLeft}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "13px" },
                  }}
                >
                  Are you a nonprofit or an agency working with nonprofits?
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  value={selectedNature}
                  onChange={(event) => setSelectedNature(event.target.value)}
                  className={classes.select}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      '&::placeholder': {
                        color: themeMode === 'dark' ? '#ffff' : '#000'
                      }
                    },
                    backgroundColor:
                      themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === 'dark' ? '#ffff' : '#000',
                      "&::placeholder": {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === 'dark' ? '#ffff' : '#000',
                    }
                  }}
                  InputLabelProps={{
                    shrink: selectedNature !== "",
                    className: classes.placeholderLabel,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          Width: "80%",
                          background: themeMode === "dark" ? "#000" : "#FFF",
                          scrollbarWidth: 'thin',
                          scrollbarColor:
                            themeMode === 'dark'
                              ? '#8ED003CC #0F0F0F'
                              : '#8ED003 #E2E2E2',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="none" disabled className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                    }}>
                    Select an Option
                  </MenuItem>
                  {nature?.map((you, index) => (
                    <MenuItem
                      key={index}
                      value={you.type}
                      className={classes.menuItem}
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#030303",
                        backgroundColor: themeMode === "dark" ? "#030303" : "#FFF",
                      }}
                    >
                      {you.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid mt={4} className={classes.centerRow}>
              <Button className={classes.button} sx={{ mr: 2 }} onClick={handleSave}>
                Save
              </Button>

              {/* <Button
                className={classes.button}
                sx={{ ml: 2 }}
                onClick={handleClose}
              >
                Close
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Question;
