export const revenue = [
  {
    id: 1,
    value: "Less than $100,000",
  },
  {
    id: 2,
    value: "$100,000-$500,000",
  },
  {
    id: 3,
    value: "$500,000-$1M",
  },
  {
    id: 4,
    value: " $1M-$10M",
  },
  {
    id: 5,
    value: " More than $10M",
  },
  {
    id: 6,
    value: "I don't know",
  },
];

export const platform = [
  {
    id: 1,
    name: "Classy",
  },
  {
    id: 2,
    name: "Luminate",
  },
  {
    id: 3,
    name: "EveryAction",
  },
  {
    id: 4,
    name: "Engaging Networks",
  },
  {
    id: 5,
    name: "Donorbox",
  },
  {
    id: 6,
    name: "Kindful",
  },
  {
    id: 7,
    name: "Springboard",
  },
  {
    id: 8,
    name: "iRaiser",
  },
  {
    id: 9,
    name: "Custom",
  },
  {
    id: 10,
    name: "Something else",
  },
];

export const discover = [
  {
    id: 1,
    name: "Blog",
  },
  {
    id: 2,
    name: "Confrence",
  },
  {
    id: 3,
    name: "Internet Search (Google,Bing,etc.)",
  },
  {
    id: 4,
    name: "Podcast",
  },
  {
    id: 5,
    name: "Review Website",
  },
  {
    id: 6,
    name: "Social Media",
  },
  {
    id: 7,
    name: "Webinar",
  },
  {
    id: 8,
    name: "Word of mouth",
  },
  {
    id: 9,
    name: "Something else",
  },
];

export const nature = [
  {
    id: 1,
    type: "Nonprofit",
  },
  {
    id: 2,
    type: "Agency / Consultant",
  },
];
