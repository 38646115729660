import { gql } from "@apollo/client";

export const GET_P2P = gql`
  query PeerToPeerFundraises(
    $first: Int
    $after: String
    $sortColumn: PeerToPeerFundraiseSortColumnsEnum
    $sortOrder: SortOrderEnum
    $searchQuery: String
    $startDate: String
    $endDate: String
  ) {
    peerToPeerFundraises(
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      first: $first
      after: $after
      query: $searchQuery
      startDate: $startDate
      endDate: $endDate
    ) {
      nodes {
        uuid
        name
        campaign {
          uuid
          title
          description
          donations {
            nodes {
              amount
              currency
            }
          }
          raised
        }
        user {
          firstName
          lastName
          email
          phoneNumber
        }
        goalAmount
        goalCurrency
        goalDate
        message
        updatedAt
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_P2P_WITH_CAMPAIGN = gql`
  query PeerToPeerFundraises(
    $first: Int
    $after: String
    $sortColumn: PeerToPeerFundraiseSortColumnsEnum
    $sortOrder: SortOrderEnum
    $searchQuery: String
    $startDate: String
    $endDate: String
    $campaignUuid: ID!
  ) {
    peerToPeerFundraises(
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      first: $first
      after: $after
      query: $searchQuery
      startDate: $startDate
      endDate: $endDate
      campaignUuid: $campaignUuid
    ) {
      nodes {
        uuid
        name
        campaign {
          uuid
          title
          description
          donations {
            nodes {
              amount
              currency
            }
          }
          raised
        }
        user {
          firstName
          lastName
          email
          phoneNumber
        }
        goalAmount
        goalCurrency
        goalDate
        message
        updatedAt
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DELETE_P2P_FUNDRAISE = gql`
  mutation DeletePeerToPeerFundraise($uuid: ID!) {
    deletePeerToPeerFundraise(input: { uuid: $uuid }) {
      success
    }
  }
`;

export const GET_CAMPAIGN_LIST = gql`
  query campaignList {
    campaigns {
      nodes {
        uuid
        title
        description
        status
      }
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!) {
    createExport(input: { pageName: $pageName }) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;
