import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
const qr = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/qrBig.png"

const QRCodeImg = ({url, size, margin, setQrBase64}) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        if (canvasRef.current) {
          const canvas = canvasRef.current.querySelector('canvas');
          if (canvas) {
            const base64Image = canvas.toDataURL('image/png');
            setQrBase64(base64Image);
          }
        }
    }, [url, size]);
    return(
        <div ref={canvasRef} style={{display:"flex",justifyContent:"center"}}>
            {!url && <img src={qr} alt="" style={{width:"40%"}}/>}
            {url && 
                <QRCode 
                 value={url}
                 size={256} 
                 bgColor={"#ffffff"} 
                 fgColor={"#000000"} 
                 level={"H"} 
                 includeMargin={true}  
                />
            }
        </div>
    )
}
export default QRCodeImg