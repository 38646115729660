import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    bkg:{
        background: "#000"
    },
    cover:{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "720px",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    column:{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
    },
    blur:{
        padding: "20px 40px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        // backgroundColor: "transparent",
        color: "#fff",
        borderRadius: "15px",
        overflow: "hidden",
        
    },
    fw700:{
        fontWeight: "900",
    },
    flex:{
        display: "flex",
    },
    padW400:{
        padding: "10px 15px 10px 0px",
        fontWeight: "400",
    },
    centerRow:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    btn:{
        color: "#FFF",
        background:
          "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
        borderRadius: "30px",
        textTransform: "capitalize",
        flexWrap: "nowrap",
        fontSize: "16px",
        fontWeight: "600",
        padding: "5px 15px",
        border: "0.5px solid transparent",
        "&:hover": {
          background: "#081a01",
          border: "0.5px solid #689900",
        },
    }
});