import { createSlice } from "@reduxjs/toolkit";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: { screen: 1, askPage: {}, campaignState: {} },
  reducers: {
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setAskPage: (state, action) => {
      state.askPage = action.payload;
    },
    setCampaignState: (state, action) => {
      state.campaignState = action.payload;
    },
  },
});

export const { setScreen, setAskPage, setCampaignState } =
  campaignSlice.actions;
export default campaignSlice.reducer;
