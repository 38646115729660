import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  flex: {
    display: "flex",
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },

  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",

    textWrap: "nowrap",
  },
  fw500: {
    fontWeight: "500",
    textTransform: "capitalize",
    textWrap: "nowrap",
  },

  bottom: {
    display: "flex",
    alignItems: "flex-end",
  },
  neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  fw600: {
    fontWeight: "600",
    textWrap: "nowrap",
    color: "#70FF00",
  },
  fw600w: {
    fontWeight: "600",
    textWrap: "nowrap",
  },

  divider: {
    display: "flex",
    width: "100%",

    padding: "10px",
    flexWrap: "wrap",
  },

  fs12: {
    fontSize: "12px",
    fontWeight: "500",
    textWrap: "nowrap",
    textAlign: "center",
  },
  fs10: {
    fontSize: "11.5px",
    fontWeight: "400",
    textWrap: "wrap",
  },
  fs12w600: {
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "right",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "700",
    textWrap: "nowrap",
  },
  neon: {
    fontWeight: "600",
    color: "#00FF0A",
    textWrap: "nowrap",
    textAlign: "center",
  },

  refund: {
    background: "transparent",

    borderRadius: "30px",
    flexWrap: "nowrap",

    width: "fit-content",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  refundLight: {
    background: "transparent",

    borderRadius: "30px",
    flexWrap: "nowrap",

    width: "fit-content",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: "#2e3322",
      color: "white",
    },
  },
  download: {
    color: "#FFF",
    background: "linear-gradient(90deg, #5D8900 3.35%, #95DC00 96.95%)",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 0px 10px 5px",
    width: "fit-content",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  rowEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fit: {
    height: "fit-content",
    paddingTop: "15px",
  },
  box: {
    padding: "20px",
    border: "2px solid #9CCA3C",
    borderRadius: "15px",
    width: "100%",
  },
  mapped: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  comments: {
    display: "flex",
    backgroundColor: "#2F2E2E",
    borderRadius: "5px",
    padding: "5px",
  },

  tableContainer: {
    zIndex: "0",

    padding: "20px",
    paddingTop: "0px",
    borderRadius: "15px",
    maxHeight: "350px",
    overflowY: "auto",
  },
  fullWhite: {
    width: "100%",
    color: "#fff",
  },

  title: {
    fontWeight: "700",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  row: {
    "&:last-child td, &:last-child th": { borderBottom: 0 },
    "&:hover": {
      borderRadius: "15px",
    },
  },
  cell: {
    width: "10%",
    borderRight: "0px",
    color: "#fff",
  },
  data: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#fff",
    textAlign: "center",
  },
  titleCell: {
    width: "30%",
    borderRight: "0px",
  },
  donorData: {
    textAlign: "center",
  },
  rasiedCell: {
    width: "15%",
    borderRight: "0px",
    textDecoration: "none",
  },
  colorData: { color: "#8ED003", textAlign: "center" },
  dots: {
    "&:hover": { backgroundColor: "transparent" },
  },
  mr1: {
    marginRight: 1,
  },

  paperLight: {
    "& .MuiPaper-root": {
      color: "#FFF",
      backgroundColor: "#111",
      border: "1px solid #FFF",
      borderRadius: "15px",
    },
  },
}));
