import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const blur =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg";
const light =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/light.svg";

const Plan = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          filter: themeMode === "dark" ? "" : "brightness(500%)",
          //color:themeMode==="dark"?"#FFFF":"#000000"
        }}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          className={classes.bkgImg}
          sx={{
            padding: {
              md: "80px 0px",
              sm: "80px 30px ",
              xs: "80px 15px",
            },
          }}
        >
          <Grid className={classes.columnCenter} item md={7.5} sm={10} xs={11}>
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "40px", sm: "36px", xs: "26px" },
                fontFamily: "Stolzl",
              }}
            >
              plans just as per your needs
            </Typography>
            <Typography
              className={classes.stolzl}
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "12px" },
                fontWeight: themeMode === "dark" ? "700" : "500",
                fontFamily: "Stolzl",
              }}
            >
              Select from a variety of tailored plans designed to meet the
              unique demands of your fundraising initiatives. Whether you're a
              grassroots organization or a global powerhouse, our flexible
              pricing ensures you get exactly what you need without compromise.
            </Typography>
          </Grid>
          <Grid
            item
            md={9.5}
            sm={12}
            xs={12}
            my={5}
            py={{ md: 5, sm: 5, xs: 3 }}
            className={classes.spaceBtwn}
            sx={{
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              className={classes.box}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                textAlign: "center",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                background: "#0000003B",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px red"
                    : "0px 8px 5px 0px #00000040",
                borderRadius: "15px",
                overflow: "visible",
                padding: "40px 40px",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background: "linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
              mx={{ md: 1.5, sm: 1.5, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 4 }}
            >
              <Typography
                className={classes.toper}
                sx={{
                  fontWeight: themeMode === "dark" ? "400" : "200",
                  fontFamily: "Stolzl",
                }}
              >
                Self-Managed
              </Typography>
              <Typography
                my={2}
                className={classes.title}
                sx={{
                  fontWeight: themeMode === "dark" ? "700" : "400",
                  fontFamily: "Stolzl",
                  fontWeight: themeMode === "dark" ? "700" : "500",
                }}
              >
                4% Per Transaction
              </Typography>
              <Grid className={classes.p70}>
                <Typography
                  className={classes.text}
                  sx={{
                    fontWeight: themeMode === "dark" ? "400" : "100",
                    fontWeight: themeMode === "dark" ? "400" : "200",
                    fontFamily: "Stolzl",
                  }}
                >
                  Take control with a Self-Managed plan at 4% per transaction,
                  perfect for organizations seeking an agile and cost-effective
                  solution to manage their fundraising activities with autonomy.
                </Typography>
              </Grid>
              <Grid>
                <Button
                  href="/signup"
                  variant="contained"
                  size="medium"
                  disableRipple
                  className={classes.btn}
                  sx={{
                    backgroundColor:
                      themeMode === "dark" ? "#7CB602" : "#55017d",
                    "&:hover": {
                      backgroundColor:
                        themeMode === "dark" ? "#7CB602" : "#55017d",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFF",
                      textTransform: "capitalize",
                      fontFamily: "Stolzl",
                    }}
                  >
                    Request Account
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid
              mx={{ md: 1.5, sm: 1.5, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 4 }}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                textAlign: "center",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                // position: "absolute",
                // top:"0px",
                bottom: "15px",
                background: "#6500E529",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px red"
                    : "0px 8px 5px 0px #00000040",
                borderRadius: "15px",
                overflow: "visible",
                padding: "30px 40px",
                transform: {
                  md: "scale(1.05)",
                  sm: "scale(1.1)",
                  xs: "scale(1)",
                },
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "2px",
                  background: "linear-gradient(180deg, #C4C800, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
            >
              <Typography
                mb={4}
                sx={{
                  fontSize: "14px",
                  fontWeight: themeMode === "dark" ? "400" : "200",
                  color: themeMode === "dark" ? "#A5F202" : "#636e02",
                  fontFamily: "Stolzl",
                }}
              >
                Recommended Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: themeMode === "dark" ? "400" : "200",
                  fontFamily: "Stolzl",
                }}
              >
                Custom
              </Typography>
              <Typography
                my={2}
                sx={{
                  fontSize: "22px",
                  fontWeight: themeMode === "dark" ? "700" : "400",
                  fontFamily: "Stolzl",
                }}
              >
                Contact Sales Team
              </Typography>
              <Grid sx={{ padding: "40px 0px" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: themeMode === "dark" ? "400" : "200",
                    fontFamily: "Stolzl",
                  }}
                >
                  Our Custom plan is recommended for those who need a more
                  personalized approach. Contact our sales team to discuss
                  bespoke solutions crafted to fit the specific requirements and
                  complexities of your fundraising strategy.
                </Typography>
              </Grid>
              <Grid>
                <Button
                  href="/signup"
                  variant="contained"
                  size="medium"
                  disableRipple
                  sx={{
                    borderRadius: "30px",
                    padding: "8px 15px",
                    backgroundColor:
                      themeMode === "dark" ? "#7CB602" : "#160021",
                    "&:hover": {
                      backgroundColor:
                        themeMode === "dark" ? "#7CB602" : "#160021",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFF",
                      textTransform: "capitalize",
                      fontWeight: themeMode === "dark" ? "400" : "200",
                      fontFamily: "Stolzl",
                    }}
                  >
                    Register with us
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid
              mx={{ md: 1.5, sm: 1.5, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 4 }}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                textAlign: "center",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                background: "#0000003B",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px red"
                    : "0px 8px 5px 0px #00000040",
                borderRadius: "15px",
                overflow: "visible",
                padding: "40px 40px",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background: "linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Stolzl",
                }}
              >
                Self-Managed
              </Typography>
              <Typography
                my={2}
                sx={{
                  fontSize: "22px",
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                }}
              >
                6% Per Transaction
              </Typography>
              <Grid sx={{ padding: "70px 0px" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    fontFamily: "Stolzl",
                  }}
                >
                  Opt for a straightforward Self-Managed plan at 6% per
                  transaction, ideal for organizations that prefer a direct,
                  uncomplicated pricing model while still benefiting from our
                  full suite of fundraising tools.
                </Typography>
              </Grid>
              <Grid>
                <Button
                  href="/signup"
                  variant="contained"
                  size="medium"
                  disableRipple
                  sx={{
                    borderRadius: "30px",
                    padding: "8px 15px",
                    backgroundColor:
                      themeMode === "dark" ? "#7CB602" : "#55017d",
                    "&:hover": {
                      backgroundColor:
                        themeMode === "dark" ? "#7CB602" : "#55017d",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFF",
                      textTransform: "capitalize",
                      fontFamily: "Stolzl",
                    }}
                  >
                    Request Account
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Plan;
