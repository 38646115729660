import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  select: {
    fontWeight: "600",
    textAlign: "left",
    fontSize: "18px",
    // color: "#fff",
    // backgroundColor: "#000",
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    
    borderRadius: "10px",
    width: "100%",
    // "& .MuiOutlinedInput-input": {
    //   color: "#FFFFFF",
    //   "&::placeholder": {
    //     color: "#FFFFFF",
    //   },
    // },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
    //  borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },

    
    "&:focus": {
      outline: "none",
    },
  },
  menuItem: {
    fontWeight: "600",
    
    "&:hover": {
      color: "#689900",
    },
    "&:focus": {
      color: "#689900",
    },
  },
  blur: {
    position: "absolute",
    backdropFilter: "blur(22px)",
    height: "100%",
  },
  box: {
    padding: "25px",
    backdropFilter: "blur(22px)",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    
    borderRadius: "15px",
    overflow: "visible",
    height: "fit-content",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: "linear-gradient(145deg, white, transparent)",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  },
  flex: {
    display: "flex",
  },
  jCenter: {
    display: "flex",
    justifyContent: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  fw500: {
    fontWeight: "500",
  },
  fw400: {
    fontWeight: "400",
  },
  fullRel: {
    width: "100%",
    position: "relative",
  },
  alignLeft: {
    textAlign: "left",
  },
  centerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    color: "#FFF",
    width: "30%",
    background: "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px 15px",
    border: "0.5px solid transparent",
    "&:hover": {
      background: "#081a01",
      border: "0.5px solid #689900",
    },
  },
}));
