import * as React from 'react'
import { useState, useEffect } from 'react'
import Toolbar from '@mui/material/Toolbar'
import {
  Grid,
  AppBar,
  Box,
  Button,
  IconButton,
  PaperProps,
  Paper,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import Drawer from '@mui/material/Drawer'
import { NavItems } from './NavItems'
import { useStyles } from './useStyles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { getUserFromLocalStorage } from '../../services/localStorageService'
import { useNavigate } from 'react-router-dom'
import { AuthService } from '../../services/auth.service'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/authSlice'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ThemeModeSwitch from '../../utils/themeModeSwitch'
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';

const optionDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/optionsBlack.svg'
const optionLight = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Navbar/hamBurgLight.png'
const logoLight = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Navbar/logo.svg'
const logoDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg'
const down = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg'
const dp = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png'
const verify = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/verify.png'
const logoutIcon = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logout.svg'
const settingsIcon = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/settings.svg'

const NavBar = (
  scrollColor,
  btnColor,
  getStartedBorder,
  profileBkg,
  profileBkgLight,
  profileBorder,
  logoTheme,
  txtColor,
  scrollingColor
) => {
  const classes = useStyles()
  const [transparent, setTransparent] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const themeMode = useSelector(state => state.themeMode.mode)
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const navigate = useNavigate()
  const currentPath = window.location.pathname;

  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    function handleScroll () {
      const isTransparent = window.scrollY === 0
      setTransparent(!isTransparent)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setIsDrawerOpen(open)
  }

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout()
      dispatch(logout())
      navigate('/')
    } catch (error) {
      console.error('Sign-out error:', error)
      navigate('/')
    }
  }
  const handleLogin = () => {
    window.location.href = '/sign_in'
  }
  return (
    <Box
      className={classes.root}
      sx={
        {
          //     ...theme,
          //     palette: {
          //     ...theme.palette,
          //     mode: 'light', // Change 'light' to whatever mode you want for the AppBar
          // },
        }
      }
    >
      <AppBar
        position='fixed'
        className={classes.appBar}
        sx={{
          backgroundImage:"none",
          backgroundColor: transparent
            ? scrollColor.scrollColor
            : 'transparent',
          paddingRight: { md: '0px', sm: '0px', xs: '0px' },
          paddingLeft: { md: '80px', sm: '50px', xs: '15px' }
        }}
      >
        <Toolbar className={classes.navbar}>
          <Grid
            className={classes.transparent}
            sx={{
              display: {
                md: 'block',
                sm: 'none',
                xs: 'none'
              }
            }}
          >
            <Button disableRipple className={classes.logo} href='/'>
              {
                !transparent && scrollColor.logoTheme==="light"?
              (
                  <img className={classes.img} src={logoLight} alt='' />
              ) 
              : !transparent && scrollColor.logoTheme==="dark"?
              (
                  <img className={classes.img} src={logoDark} alt='' />
              )
              : transparent && scrollColor.scrollingColor==="dark"? 
              (
                  <img className={classes.img} src={logoDark} alt='' />
              )
              : transparent && scrollColor.scrollingColor==="light"? 
              (
                  <img className={classes.img} src={logoLight} alt='' />
              )
              :""
              }
            </Button>
          </Grid>

          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(true)}
            sx={{
              display: {
                sm: 'block',
                md: 'none'
              }
            }}
          >
            <Typography sx={{ color: themeMode === "dark" ? "#FFF" : "#030303", fontSize: "25px" }}>
              <MenuIcon sx={{ height: "45px", width: "45px" }} />
            </Typography> 
            {/* <img src={ !transparent && themeMode==="dark"?optionLight:optionDark} alt='' /> */}
             
          </IconButton>
          <Grid sx={{ width: { md: '70%', sm: '57%', xs: '40%' } }}>
            <Toolbar
              className={classes.itemHolder}
              sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}
            >
              <Grid className={classes.flex}>
                {NavItems?.map(item => (
                  <div className={classes.mr10} key={item.id}>
                    <Button
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className={classes.navBtn}
                      variant='text'
                      href={item.link}
                      sx={{
                        fontFamily: "Stolzl",
                        color:
                          !transparent && scrollColor.logoTheme==="light"?
                            "#FFFF"
                          : !transparent && scrollColor.logoTheme==="dark"?
                          scrollColor.txtColor
                          : transparent && scrollColor.scrollingColor==="dark"? 
                          "#FFFF" 
                          : transparent && scrollColor.scrollingColor==="light"? 
                          "#FFFF" 
                          :""
                      }}
                    >
                      {item.name}
                    </Button>
                  </div>
                ))}
              </Grid>

              <Drawer
                anchor='left'
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Grid
                  role='presentation'
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={classes.presentation}
                  sx={{ 
                    backgroundColor: themeMode==="dark"? "#0b0b0b":"#FFF", 
                  }}
                >
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableElevation
                    className={classes.buttonContainer}
                    href='/home'
                    sx={{ 
                      backgroundColor: themeMode === "dark" ? "#0b0b0b" : "#FFF",
                    }}
                  >
                    <Grid className={classes.mt10}>
                      <Grid md={2} className={classes.home}>
                        <HomeIcon />
                      </Grid>
                      <Grid md={10}>Home</Grid>
                    </Grid>
                  </Button>
                  {NavItems?.map(item => (
                    <Button
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className={classes.buttonContainer}
                      key={item.id}
                      href={item.link}
                    >
                      <Grid>
                        <Grid md={2} className={classes.navLogo}>
                          {item.logo}
                        </Grid>
                        <Grid md={10}>{item.name}</Grid>
                      </Grid>
                    </Button>
                  ))}
                </Grid>
              </Drawer>
            </Toolbar>
          </Grid>
          <Grid className={classes.row}>
            {!user?(<ThemeModeSwitch />):""}
            {
              !isAuthenticated && currentPath === '/sign_in' ? (
                <Grid sx={{display:"flex",alignItems:"center"}}>
                  <Typography className={classes.noAccount} mx={1}
                    sx={{
                      fontSize: { md: "15px", sm: "15px", xs: "12px" },
                      display:{md:"flex",sm:"flex",xs:"none"},alignItems:"center"
                    }}
                  >
                    Don’t Have an Account?
                  </Typography>
                  
                  <Button className={classes.signup}
                    href="/signup"
                    variant="contained"
                    size="medium"
                    sx={{
                      fontSize: { md: "15px", sm: "15px", xs: "12px" },
                      textWrap:"nowrap"
                    }}
                  >
                    Sign up
                  </Button>
                </Grid>
              )
              :
              !isAuthenticated && currentPath === '/signup'? (
            <>
              <Typography className={classes.noAccount} mx={1}
                sx={{
                  display:{md:'flex',sm:"flex",xs:"none"},
                  fontSize: { md: "15px", sm: "15px", xs: "12px" },
                }}
              >
                Already Have an Account?
              </Typography>
              
              <Button 
                href="/sign_in"
                variant="contained"
                size="medium"
                sx={{
                  fontSize: { md: "15px", sm: "15px", xs: "12px" },
                  background: "linear-gradient(273.66deg, #618F00 4.91%, #8ED003 82.75%)",
                  color:"#FFFF",
                  textTransform:"capitalize",
                  borderRadius:"30px",
                  textWrap:"nowrap"

                }}
              >
                Sign in
              </Button>
            </>
          )
          :
            !isAuthenticated && (
              <>
              <Button
                className={classes.login}
                onClick={() => (window.location.href = '/sign_in')}
                sx={{
                  fontSize: { md: '15px', sm: '15px', xs: '12px' },
                  color:
                    //themeMode === 'light' && !transparent ? '#030303' : '#FFFF'
                    !transparent && scrollColor.logoTheme==="light"?
                            "#FFFF"
                          : !transparent && scrollColor.logoTheme==="dark"?
                          scrollColor.txtColor
                          : transparent && scrollColor.scrollingColor==="dark"? 
                          "#FFFF" 
                          : transparent && scrollColor.scrollingColor==="light"? 
                          "#FFFF" 
                          :""
                }}
              >
                Log in
              </Button>
            
            <Button
              className={classes.getStarted}
              href={!isAuthenticated ? '/signup' : '/dashboard'}
              variant='contained'
              size='medium'
              sx={{
                fontSize: { md: '15px', sm: '15px', xs: '12px' },
                backgroundColor: scrollColor.btnColor,
                border: scrollColor.getStartedBorder,
                color:
                  //themeMode === 'light' && !transparent ? '#030303' : '#FFFF',
                  !transparent && scrollColor.logoTheme==="light"?
                            "#FFFF"
                          : !transparent && scrollColor.logoTheme==="dark"?
                          scrollColor.txtColor
                          : transparent && scrollColor.scrollingColor==="dark"? 
                          "#FFFF" 
                          : transparent && scrollColor.scrollingColor==="light"? 
                          "#FFFF" 
                          :"",
                '&:hover': {
                  background:
                    'linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)'
                }
              }}
            >
              Get Started
            </Button>
            </>
            )}
            {user ? (
              <Button
                className={classes.btn2}
                disableElevation
                onClick={handleMenuOpen}
                sx={{
                  '&:hover': {
                    border: scrollColor.profileBorder,
                    backgroundColor:scrollColor.profileBkg

                  }
                }}
              >
                <Grid className={classes.row} container>
                  <Grid className={classes.dpHolder} item>
                    <img src={dp} alt='' className={classes.w80} />
                    {user ? (
                      <Grid
                        className={classes.colCenter}
                        sx={{
                          display: {
                            md: 'flex',
                            sm: 'flex',
                            xs: 'none'
                          }
                        }}
                      >
                        <Grid
                          className={classes.profile}
                          sx={{

                            color:
                                !transparent && scrollColor.logoTheme==="light"?
                                  "#FFFF"
                                : !transparent && scrollColor.logoTheme==="dark"?
                                scrollColor.txtColor
                                : transparent && scrollColor.scrollingColor==="dark"? 
                                "#FFFF" 
                                : transparent && scrollColor.scrollingColor==="light"? 
                                "#FFFF" 
                                :"",
                          }}
                        >
                          {' '}
                          {user && user?.first_name && user?.last_name
                            ? user?.first_name + ' ' + user?.last_name
                            : ''}
                        </Grid>
                        <Grid
                          className={classes.profileDull}
                          sx={{
                            color:
                                  !transparent && scrollColor.logoTheme==="light"?
                                    "#FFFF"
                                  : !transparent && scrollColor.logoTheme==="dark"?
                                  scrollColor.txtColor
                                  : transparent && scrollColor.scrollingColor==="dark"? 
                                  "#FFFF" 
                                  : transparent && scrollColor.scrollingColor==="light"? 
                                  "#FFFF" 
                                  :""
                          }}
                        >
                          <Typography sx={{   color: themeMode === "dark" ? "#9F9F9F" : "#0F0F0F",textWrap:"nowrap",fontSize: "11px",
                            textTransform: "capitalize",
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            display:"flex",justifyContent:"flex-start",flexDirection:"row",alignItems:"center"
                        }}>
                          Verified Foundation
                          <img src={verify} alt='' style={{ margin: "0px 3px",width:"10px",height:"10px" }}/>
                        </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item>
                    <img src={down} alt='' />
                  </Grid>
                </Grid>
              </Button>
            ) : (
              ''
            )
            }

            <Menu
              className={classes.menu}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperComponent={Paper}
              sx={{
                '& .MuiPaper-root': {
                  border: scrollColor.profileBorder,
                  width: { md: '17%', sm: '30%', xs: '70%' },
                  backgroundImage: 'none',
                  backgroundColor:
                    themeMode === 'dark' ? '#0a0a0a' : '#dbd7d7'
                }
              }}
            >
              <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === 'dark' ? '#FFFF' : '#030303',

                    '&:hover': {
                      backgroundColor: '#2222'
                    }
                  }}
                >
                <Typography sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid #FFF", padding: "15px 15px" ,justifyContent:"space-between",fontFamily:"Stolzl"}}>
                    Theme: {" "}{
                      themeMode==="dark"?"Dark":"Light"
                    }
                    <ThemeModeSwitch />
                  </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode==="dark"?"#FFFF":'#030303',
                  "&:hover":{
                  backgroundColor: "#2222",
                }}}
                onClick={() => {
                  handleMenuClose()
                }}
                component={Link}
                to='/dashboard/settings'
              >
                <Typography 
                  sx={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center", 
                    textAlign: "left", 
                    borderBottom: "1px solid #FFF", 
                    padding: "15px 15px", 
                    fontFamily: "Stolzl" ,
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}>
                    {/* <img src={settingsIcon} alt="" style={{margin:"0px 15px 0px 0px"}}/> */}
                    <SettingsIcon style={{ margin: "0px 15px 0px 0px" }} />
                    Settings
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode==="dark"?"#FFFF":'#030303',
                  '&:hover': {
                    color: user ? '#FF0000 !important' : '#689900',
                    backgroundColor: "#2222",
                  }
                }}
                onClick={() => {
                  user ? handleLogout() : handleLogin()
                }}
              >
                <Typography sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "left", padding: "15px 15px", fontFamily: "Stolzl" }}>
                  <img src={logoutIcon} alt="" style={{margin:"0px 15px 0px 0px"}}/>
                  {user && user?.email ? 'Logout' : 'Login'}
                  </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default NavBar
