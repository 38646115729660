import { Grid, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { allClients } from "../../../AuthPages/SignUp/Clients/clientsList";
import { useStyles } from "./useStyles";
import { useStylesLight } from "./useStylesLight";

const whyBlur1 =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/whyBlur1.svg";
const whyBlur2 =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/whyBlur2.svg";
const space =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/space.svg";
const spaceLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/spaceLight.svg";
const logo =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/logo.svg";
const logoLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/logoLight.svg";
const bright =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/bright.svg";
const brightLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/brightLight.svg";
const bar =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/bar.svg";

const WhyUs = () => {
  const classes = useStyles();
  const classesLight = useStylesLight;
  const themeMode = useSelector((state) => state.themeMode.mode);
  if (themeMode === "dark") {
    return (
      <Grid
        container
        sx={{
          backgroundColor: "#130407",
          width: "100%",
          display: "flex",
          padding: { md: "40px 0px", sm: "40px 0px", xs: "40px 0px" },
          justifyContent: "center",
          backgroundImage: `url('${whyBlur1}'), url('${whyBlur2}')`,
          backgroundPosition: {
            md: "left top, 100% 40%",
            sm: "left top, 100% 20%",
            xs: "0% 0%,100% 0%",
          },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          md={11}
          sm={11}
          xs={11}
          sx={{
            backgroundImage: `url('${space}')`,
            backgroundPosition: {
              md: "right bottom",
              sm: "center center",
              xs: "center 10%",
            },
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            borderRadius: "30px",
            padding: { md: "30px 40px", sm: "30px 40px", xs: "20px 20px" },
            height: "90vh",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                }}
              >
                Mission
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  fontFamily: "Stolzl",
                }}
              >
                At Acceleraise, our mission is to revolutionize the fundraising
                landscape by providing cutting-edge technology solutions that
                empower nonprofits to maximize their impact. We are committed to
                making fundraising accessible, efficient, and impactful for
                organizations of all sizes, enabling them to focus on what truly
                matters - their cause.
              </Typography>
            </Grid>
            <Grid mt={16} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                }}
              >
                Vision
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  fontFamily: "Stolzl",
                }}
              >
                We envision a world where every nonprofit, regardless of its
                size, has the tools and technology to connect with supporters
                and secure the funding needed to create positive change. Our
                vision is to be at the forefront of fundraising innovation,
                creating a global community of empowered donors and thriving
                nonprofits.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            padding: { md: "80px 40px", sm: "80px 20px", xs: "80px 10px" },
            backgroundImage: `url('${bright}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: {
              md: "left center",
              sm: "-50% 0%",
              xs: "50% 7%",
            },
          }}
        >
          <Grid
            id="why"
            item
            md={4}
            sm={4}
            xs={12}
            sx={{
              textAlign: "center",
            }}
          >
            <img src={logo} alt="" />
          </Grid>

          <Grid
            item
            md={12}
            sm={8}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              mt={{ md: 0, sm: 0, xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                Why acceleraise?
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  //padding:{md:"0px",sm:"0px",xs:"10px 60px"},
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                "Acceleraise stands out as a beacon of innovation in the
                fundraising sector. Our platform is designed with the latest in
                AI and machine learning technologies, ensuring that your
                organization can reach its potential donors more effectively.
                With user-friendly interfaces, comprehensive analytics, and
                support for modern payment methods, Acceleraise is more than
                just a tool - it's your partner in making a difference
              </Typography>
            </Grid>
            <Grid mt={6}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                our trusted clientele
              </Typography>
            </Grid>
            <Grid
              sx={{
                padding: "20px 0px",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "space-between",
                overflowX: "auto",
              }}
            >
              {allClients?.map((item, index) => (
                <Grid
                  key={index}
                  className={classes.imgHolder}
                  sx={{
                    paddingY: { md: "0px", sm: "10px", xs: "10px" },
                    transform: {
                      md: "scale(1)",
                      sm: "scale(0.8)",
                      xs: "scale(0.6)",
                    },
                  }}
                >
                  <img src={item.client} alt="" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        sx={{
          backgroundColor: "#FFA3A3",
          width: "100%",
          display: "flex",
          padding: { md: "40px 0px", sm: "40px 0px", xs: "40px 0px" },
          justifyContent: "center",
          backgroundImage: `url('${whyBlur1}'), url('${whyBlur2}')`,
          backgroundPosition: {
            md: "left top, 100% 40%",
            sm: "left top, 100% 20%",
            xs: "0% 0%,100% 0%",
          },
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          md={11}
          sm={11}
          xs={11}
          sx={{
            backgroundImage: `url('${spaceLight}')`,
            backgroundPosition: {
              md: "right bottom",
              sm: "center center",
              xs: "center 10%",
            },
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            borderRadius: "30px",
            padding: { md: "30px 40px", sm: "30px 40px", xs: "20px 20px" },
            height: "90vh",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Grid
              sx={{ display: "flex", flexDirection: "column", color: "black" }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                }}
              >
                Mission
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "right",
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  fontFamily: "Stolzl",
                }}
              >
                At Acceleraise, our mission is to revolutionize the fundraising
                landscape by providing cutting-edge technology solutions that
                empower nonprofits to maximize their impact. We are committed to
                making fundraising accessible, efficient, and impactful for
                organizations of all sizes, enabling them to focus on what truly
                matters - their cause.
              </Typography>
            </Grid>
            <Grid
              mt={16}
              sx={{ display: "flex", flexDirection: "column", color: "black" }}
            >
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                }}
              >
                Vision
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  fontFamily: "Stolzl",
                }}
              >
                We envision a world where every nonprofit, regardless of its
                size, has the tools and technology to connect with supporters
                and secure the funding needed to create positive change. Our
                vision is to be at the forefront of fundraising innovation,
                creating a global community of empowered donors and thriving
                nonprofits.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            padding: { md: "80px 40px", sm: "80px 20px", xs: "80px 10px" },
            backgroundImage: `url('${brightLight}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: {
              md: "left center",
              sm: "-50% 0%",
              xs: "50% 7%",
            },
          }}
        >
          <Grid
            id="why"
            item
            md={4}
            sm={4}
            xs={12}
            sx={{
              textAlign: "center",
            }}
          >
            <img src={logoLight} alt="" />
          </Grid>

          <Grid
            item
            md={12}
            sm={8}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              mt={{ md: 0, sm: 0, xs: 4 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                color: "black",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "40px", sm: "32px", xs: "20px" },
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                Why acceleraise?
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  //padding:{md:"0px",sm:"0px",xs:"10px 60px"},
                  fontSize: { md: "16px", sm: "13px", xs: "10px" },
                  fontWeight: "400",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                "Acceleraise stands out as a beacon of innovation in the
                fundraising sector. Our platform is designed with the latest in
                AI and machine learning technologies, ensuring that your
                organization can reach its potential donors more effectively.
                With user-friendly interfaces, comprehensive analytics, and
                support for modern payment methods, Acceleraise is more than
                just a tool - it's your partner in making a difference
              </Typography>
            </Grid>
            <Grid mt={6}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  textTransform: "uppercase",
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  fontFamily: "Stolzl",
                  color: "black",
                }}
              >
                our trusted clientele
              </Typography>
            </Grid>
            <Grid
              sx={{
                padding: "20px 0px",
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "space-between",
                overflowX: "auto",
              }}
            >
              {allClients?.map((item, index) => (
                <Grid
                  key={index}
                  className={classes.imgHolder}
                  sx={{
                    paddingY: { md: "0px", sm: "10px", xs: "10px" },
                    transform: {
                      md: "scale(1)",
                      sm: "scale(0.8)",
                      xs: "scale(0.6)",
                    },
                  }}
                >
                  <img src={item.client} alt="" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
export default WhyUs;
