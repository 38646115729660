import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/DataExport/bkgHero.svg'
import heroLight from '../../../../../../assets/FeatureLightTheme/CampaignManagement/heroLight.svg'
import heroDark from '../../../../../../assets/FeatureLightTheme/CampaignManagement/heroDark.svg'


//const light_export1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_export1.svg";
const main2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/main2.svg";
const main = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/main.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";
const light_camp1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_camp1.svg";
const light_camp2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_camp2.svg";

const HeroSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          zIndex:"3",
          padding: {
            md: "130px 70px 10px",
            sm: "130px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark" ? `url('${bkg}')` : `url('${bkgLight}')`,
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={8.5}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
            }}
          >
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              Create, configure, and launch{" "}
            </span>
            <span
              className={
                themeMode === "dark" ? classes.color : classes.lightColor
              }
              sx={{ fontWeight: "700" }}
            >
              {" "}
              donation pages{" "}
            </span>
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              {" "}
              in minutes{" "}
            </span>
          </Typography>
          <Typography
            className={themeMode === "dark" ? "text-white" : "text-black"}
            sx={{ fontFamily: "Stolzl" }}
          >
            Creating engaging donation pages has never been easier. With
            Campaign Pages, you can create, configure, and launch your donation
            pages quicker and raise more faster.
          </Typography>
        </Grid>
        <Grid
          my={3}
          item
          className={classes.jCenter}
          md={9.7}
          sm={10}
          xs={12}
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
            width: { md: "40%", sm: "auto", xs: "auto" },
          }}
        >
          <Grid
            item
            className={classes.textCenter}
            md={7} sm={12} xs={12} mt={{md:0,sm:4,xs:0}}
            sx={{ 
              minHeight: "20rem",
              transform:{md:"translateY(18%)"},
              display:"flex",alignItems:{xs:"center"}
          }}
          >            
              <img
                src={themeMode === "dark" ? heroDark : heroLight}
                alt=""
              />
              
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default HeroSection;
