import React, { useState, useEffect } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { useSelector } from 'react-redux'

const ProgressBar = ({ collected, remaining }) => {
  const total = collected + remaining;
  const progress= collected/(total)*100;
  const themeMode = useSelector(state => state.themeMode.mode)


  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
      }}
    >
      <LinearProgress
        variant="determinate"
        value={collected <= total ? progress : 100}
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          borderRadius: "15px",
          height: "20px",
          border:"1px solid #8BBC00",     
          
          "& .MuiLinearProgress-bar": {
            
            backgroundColor: "#8BBC00",
            borderRadius:"15px",
            margin:"5px 0px"
          },
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "8px",
        }}
      >
        <Typography variant="body2"
        sx={{
          textAlign:"left",
          color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
        }}>
          ${collected} Collected
        </Typography>
        {remaining !== 0 ?
          <Typography variant="body2" 
            sx={{
              textAlign:"right",
              color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
            }}>
          ${remaining} Remaining
          </Typography>
        : 
          <Typography variant="body2" color="#FFFFFF80" sx={{textAlign:"right"}}>
           No Remaining
          </Typography>
        }
      </div>
    </div>
  );
};

export default ProgressBar;
