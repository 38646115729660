import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux'

export function QRCodeModal({ open, setOpen, url }) {
  const themeMode = useSelector(state => state.themeMode.mode)
  return (
    <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-title">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD', boxShadow: 24, p: 4 }}>
        <Typography id="modal-title" variant="h6" component="h2">
          QR CODE
        </Typography>
        <Typography style={{display:"flex",justifyContent:"center"}}>
            {url && 
                <QRCode 
                 value={url}
                 size={256} 
                 bgColor={"#ffffff"} 
                 fgColor={"#000000"} 
                 level={"H"} 
                 includeMargin={true}  
                />
            }
        </Typography>
      </Box>
    </Modal>
  );
}