import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  bkg: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",

  },
  title: {
    
    fontWeight: "700",
    color: "#FFFF",
    textTransform: "uppercase",
  },
  fullWidth: {
    width: "100%",
  },

  bkg2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "140% auto",
    backgroundPosition: "0% center",
  },
  index1: {
    position: "relative",
    zIndex: 1,
  },
  index2: {
    position: "absolute",
    zIndex: 2,
  },
  eco: {
    position: "relative",
    zIndex: 1,
    right: "0px",
    maxWidth: "100%",
  },
  


  supportCard: {
    background: "transparent",
    //border: themeMode === "dark" ? "2px solid #FFF" :"2px solid #484B6A",
    boxShadow: '0px 0px 0px red',
    padding: '24px',
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    // flexDirection: "column",
    alignItems: "center",
    position: "relative",
    backdropFilter: 'blur(50px)',
    borderRadius: "15px",
    overflow: "visible",
    height: "fit-content",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: 'linear-gradient(160deg, #FFFFFF99, transparent)',
      WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  }
}));
