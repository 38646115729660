import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/DataExport/bkgHero.svg'
import heroLight from '../../../../../../assets/FeatureLightTheme/Support247/hero.svg'

const support = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/support.svg";
const element4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element4.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";
const light_241 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_241.svg";
const light_242 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_242.svg";


const HeroSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 90px 10px",
            sm: "130px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark" ? `url('${bkg}')` : `url('${bkgLight}')`,
        }}
      >
        <div className="md:flex w-full">
          {" "}
          <Grid
            className={classes.columnCenter}
            item
            md={8}
            sm={10}
            xs={12}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "40px", sm: "36px", xs: "34px" },
                fontFamily:"Stolzl",
                textAlign: { md: "left", sm: "left", xs: "center" },
              }}
            >
              {" "}
              <span
                className={themeMode === "dark" ? "text-white" : "text-black"}
              >
                24/7 Cost-Free{" "}
              </span>
              <span
                className={
                  themeMode === "dark" ? "text-[#8ED003]" : "text-[#484B6A]"
                }
                sx={{ fontWeight: "700" }}
              >
                {" "}
                Assistance{" "}
              </span>{" "}
            </Typography>
            <Typography
              sx={{ fontFamily: "Stolzl", textAlign: { md: "left", sm: "left", xs: "center" }, }}
              className={
                themeMode === "dark" ? "text-white pt-4" : "text-black pt-4"
              }
            >
              Bid farewell to onboarding charges and hourly support expenses.
              Access assistance without any financial burden, provided by
              individuals familiar with your needs. Exceptional support is not
              an additional cost but an inherent standard.
            </Typography>
          </Grid>
          <Grid
            my={3}
            item
            className={classes.jCenter}
            md={9.7}
            sm={9}
            xs={12}
            sx={{
              display: "flex", justifyContent: "flex-end",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              width: { md: "40%", sm: "auto", xs: "auto" },
            }}
          >
            <Grid
              item
              className={classes.textCenter}
              md={9}
              // style={{ minHeight: "20rem" }}
              sx={{ minHeight: "20rem", maxHeight: "70rem", textAlign: { md: "flex-end" }, display: "flex", justifyContent: "flex-end", }}
            >

                <img src={themeMode==="dark"?support:heroLight} alt="" />
                 

            </Grid>
          </Grid>{" "}
        </div>
      </Grid>
    </>
  );
};
export default HeroSection;
