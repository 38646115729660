import { Grid, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

import bkgLight from '../../../../../../assets/FeatureLightTheme/DataExport/bkgHero.svg'
import heroLight from '../../../../../../assets/FeatureLightTheme/DonationManagement/hero.svg'
import hero from '../../../../../../assets/FeatureLightTheme/DonationManagement/heroDark.svg'


const donation1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation1.svg";
const donation2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation2.svg";
const donation3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation3.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";
const light_donation1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_donation1.svg";
const light_donation2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_donation2.svg";

const HeroSection = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 70px 10px",
            sm: "100px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark" ? `url('${bkg}')` : `url('${bkgLight}')`,
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={8.5}
          sm={11}
          xs={12}
          sx={{  }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
              width:{md:"80%",sm:"80%",xs:"100%"},
              
            }}
          >
            {" "}
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              Handle and explore the{" "}
            </span>
            <span className={themeMode==="dark"?classes.color:classes.colorLight} sx={{ fontWeight: "700" }}>
              {" "}
              donation insights{" "}
            </span>
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              {" "}
              closely{" "}
            </span>
          </Typography>
          <Typography mt={{md:0,sm:1}}
            className={themeMode === "dark" ? "text-white" : "text-black"}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" },fontFamily:"Stolzl"}}
          >
            View all the necessary data related to donations and configure your
            future campaigns according to the favorable conditions of your
            supporters.
          </Typography>
        </Grid>
        <Grid
          my={3}
          item
          className={classes.jCenter}
          md={9.7}
          sm={10}
          xs={12}
          sx={{
            //flexDirection: { md: "row", sm: "column", xs: "column" },
            width: { md: "40%", sm: "auto", xs: "auto" },
          }}
        >
          <Grid
            item
            className={classes.textCenter}
            md={9.5} sm={9.5} xs={12}
            style={{ minHeight: "15rem",display:"flex",alignItems:"center" }}
          >
              <img src={themeMode==="dark"?hero:heroLight} alt=""  style={{width:"100%"}}/>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default HeroSection;
