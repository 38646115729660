import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import dataLight from '../../../../../../assets/FeatureLightTheme/DonationManagement/dataLight.svg'
import donationLight from '../../../../../../assets/FeatureLightTheme/DonationManagement/3grouped.svg'
import donationDark from '../../../../../../assets/FeatureLightTheme/DonationManagement/donation.svg'

const fields = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/fields.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const calender = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/calender.svg";
const donation4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation4.svg";
const light_donation3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_donation3.svg";
const light_donation4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_donation5.svg";
const donation5 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation5.svg";
const donation6 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation6.svg";
const donation7 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationManagement/donation7.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";

const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E4E5F1" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize: themeMode === "dark" ? "140% auto" : "100% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "right bottom,left 0%",
            padding: { md: "100px 150px", sm: "90px 40px", xs: "70px 20px" },
            //padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background:themeMode==="dark" 
                  ?"linear-gradient(145deg, white, transparent)"
                  :"linear-gradient(145deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
              }}
            >
              <Grid item md={5} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    color:themeMode==="dark"? "#fff":"#030303"
                  }}
                >
                  Generalized <span style={{ color: themeMode === "dark" ? "#6A9D01" :"#484B6A" }}>donation</span>{" "}
                  data
                </Typography>
                <Grid item md={11}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      color: themeMode === "dark" ? "#fff" : "#030303"
                    }}
                  >
                    See what trend your supproters of different campaigns are
                    following. increasing one-time and recurring donations from
                    both your newest and most loyal donors.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={7}
                // mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  transform: { md: "translateY(0%) translateX(20%)", sm: "translateY(0%)" },
                }}
              >
                <img src={themeMode==="dark"?donation4:dataLight} alt="" style={{width:"100%"}}/>
              </Grid>
            </Grid>
          </Grid>


          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            mt={{md:25,sm:20,xs:20}}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "-10px -13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(325deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: {
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
                justifyContent: { md: "flex-end", sm: "center", xs: "center" },
              }}
            >
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "none" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  // top: { md: "30%", sm: "0%", xs: "0%" },
                  // left: { md: "-15%", sm: "10%", xs: "0%" },
                  transform: {
                    md: "translateY(20%) scale(1.2)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={themeMode==="dark"?donationDark:donationLight} alt="" />
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  top: { md: "30%", sm: "0%", xs: "0%" },
                  left: { md: "-15%", sm: "10%", xs: "0%" },
                  transform: {
                    md: "translateY(20%)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={donation5} alt="" style={{ width: "100%" }} />
              </Grid>
              <Grid item md={6.3} sm={12} xs={12} sx={{display:"flex",alignItems:"flex-end",flexDirection:"column"}}>
                <Typography
                  className={classes.title}
                  sx={{
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    color: themeMode === "dark" ? "#fff" : "#030303",
                    width:"90%"
                  }}
                >
                  See Data of each donation in {" "}
                  <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}>details</span>
                </Typography>
                <Typography
                  mt={4}
                  sx={{
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    color: themeMode === "dark" ? "#fff" : "#030303",
                    fontFamily:"Stolzl"

                  }}
                >
                  Raise more with conversion-optimized donation pages Powered by Acceleraise’s standard-setting optimization technology, Campaign Pages excel at increasing one-time and recurring donations from both your newest and most loyal donors.{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === "dark" ? `url('${bkg2}')` : `url('${blur2}')`,
            padding: {
              md: "150px 100px 200px 250px",
              sm: "150px 20px",
              xs: "70px 20px",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: "linear-gradient(205deg, white, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: {
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
                justifyContent: { md: "flex-end", sm: "center", xs: "center" },
              }}
            >
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "none" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  top: { md: "30%", sm: "0%", xs: "0%" },
                  left: { md: "-15%", sm: "10%", xs: "0%" },
                  transform: {
                    md: "translateY(20%)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={donation5} alt="" />
              </Grid>
              <Grid
                item
                md={4}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  top: { md: "30%", sm: "0%", xs: "0%" },
                  left: { md: "-15%", sm: "10%", xs: "0%" },
                  transform: {
                    md: "translateY(20%)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={donation5} alt="" style={{ width: "100%" }} />
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    textAlign: { md: "right", sm: "center", xs: "center" },
                  }}
                >
                  Schedule Files for{" "}
                  <span style={{ color: "#6A9D01" }}>Seamless Delivery</span>
                </Typography>
                <Typography
                  mt={4}
                  sx={{
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  }}
                >
                  Get data from Acceleraise on your schedule. Select from hourly
                  to monthly intervals to automatically receive exported files
                  when you need them.{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    );
  
};
export default SubSection;
