import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { freq, amount } from "./data.jsx";
import { useParams } from "react-router-dom";
import { DonationService } from "../../services/donations-service.js";
import { setSpinnerLoading } from "../../store/spinnerLoaderSlice.js";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../store/alertSlice.js";
import {
  capitalizeFirstLetter,
  currencies,
  currencyToCountryMap,
  getClientData,
} from "../../utils/globalFunctions.js";
import getSymbolFromCurrency from "currency-symbol-map";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const sheild =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/shield.svg";
const sheildBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/sheildBlack.svg";
const sheildYellow =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/secureYellow.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/back.svg";
const backBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/backBlack.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/heart.svg";
const fb =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/fb.svg";
const wa =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/WA.svg";
const mail =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/mail.svg";
const plant =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/plantImg.svg";
const templateOrange =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/orangeBkgTemplate.svg";
const templateBW =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateBkgBW.svg";
const StripeLogo =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DonationFormTemplate/Powered-by-Stripe-Logo.svg";

const StripeCardNumberElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardNumberElement
        options={{
          showIcon: true,
          style: {
            // base: {
            //   backgroundColor: "#0D0D0D",
            //   color: "#FFFFFF",
            //   "::placeholder": {
            //    color: "#FFFFFF",
            //   },
            // },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const StripeCardExpiryElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardExpiryElement
        options={{
          style: {
            base: {
              //color: currentTemplate==="0" || currentTemplate==="5"? "#FFFFFF":"",
              //color: "#FFFFFF",
              //  "::placeholder": {
              //    color: "#FFFFFF",
              //  },
            },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const StripeCardCvcElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardCvcElement
        options={{
          style: {
            // base: {

            //   color: "#FFFFFF",
            //   "::placeholder": {
            //    color: "#FFFFFF",
            //   },
            // },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const StripeCardNumberLightElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardNumberElement
        options={{
          showIcon: true,
          style: {
            base: {
              color: "#FFFFFF",
              "::placeholder": {
                color: "#c9c7c7",
              },
            },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const StripeCardExpiryLightElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardExpiryElement
        options={{
          style: {
            base: {
              color: "#FFFFFF",
              "::placeholder": {
                color: "#c9c7c7",
              },
            },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const StripeCardCvcLightElement = React.forwardRef(({ ...props }, ref) => {
  return (
    <div ref={ref} style={{ width: "85%" }}>
      <CardCvcElement
        options={{
          style: {
            base: {
              color: "#FFFFFF",
              "::placeholder": {
                color: "#c9c7c7",
              },
            },
            invalid: {
              color: "red",
            },
          },
        }}
        {...props}
      />
    </div>
  );
});

const paymentMethodTypes = {
  link: "fast_link",
  applePay: "apple_pay",
  googlePay: "google_pay",
};

const DonationCheckout = ({
  embed,
  formTitle,
  coverImage,
  formDesc,
  template,
  displayedFrequency,
  displayedSuggestions,
}) => {
  const classes = useStyles();
  const { uuid, formSelection } = useParams();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const askPage = useSelector((state) => state.campaign.askPage);

  const [mode, setMode] = useState("secure");
  const [currentTemplate, setCurrentTemplate] = useState(
    formSelection ? formSelection : template
  );
  const [selectedValue, setSelectedValue] = useState("a");
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [newImage, setNewImage] = useState();
  const [paymentObj, setPaymentObj] = useState({
    firstName: "",
    lastName: "",
    campiagnUuid: uuid,
    isRecurring: false,
    frequency: "once",
    cardTitle: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    cvcCode: "",
    amount: 100,
    currency: "USD",
    currencySymbol: "$",
    email: "",
    phone: "",
    stripeCardToken: "",
  });
  const [checkField, setCheckField] = useState({
    firstName: "",
    lastName: "",
    cardTitle: "",
    amount: null,
    currency: "",
    email: "",
  });
  const processCost = 1.25;
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [frequencyDisplayed, setFrequencyDisplayed] = useState(freq);
  const [suggestionsDisplayed, setSuggestionsDisplayed] = useState(amount);

  const isObjectEmpty = (obj) => {
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if(displayedFrequency){
      setFrequencyDisplayed(displayedFrequency)
    }
    if(displayedSuggestions){
      setSuggestionsDisplayed(displayedSuggestions)
    }
  }, [displayedFrequency, displayedSuggestions]);

  useEffect(() => {
    setCheckField({
      firstName: paymentObj?.firstName,
      lastName: paymentObj?.lastName,
      cardTitle: paymentObj?.cardTitle,
      amount: paymentObj?.amount,
      currency: paymentObj?.currency,
      email: paymentObj?.email,
    });
    if (!clientData) {
      getClientData()
        .then((clientData) => {
          setClientData(clientData);
        })
        .catch((error) => {
          console.error("Error getting client data:", error);
        });
    }
  }, [paymentObj]);

  useEffect(() => {
    if (stripe && mode === "donate") {
      const TotalCost = paymentObj?.amount + processCost;
      const paymentRequest = stripe.paymentRequest({
        country: currencyToCountryMap[paymentObj?.currency],
        currency: paymentObj?.currency?.toLowerCase(),
        total: {
          label: "Total Amount",
          amount: Math.round(TotalCost * 100), // Amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      paymentRequest.canMakePayment().then((result) => {
        if (result) {
          const prButton = stripe.elements().create("paymentRequestButton", {
            paymentRequest,
          });
          prButton.mount("#payment-request-button");
        } else {
          console.log("Payment Request API is not available");
        }
      });

      paymentRequest.on("paymentmethod", async (event) => {
        const { paymentMethod } = event;
        dispatch(setSpinnerLoading(true));
        try {
          setPaymentLoading(true);
          const responsePayment = await DonationService.makeDonation(
            paymentMethod?.billing_details?.name,
            null,
            uuid,
            paymentObj?.isRecurring,
            paymentObj?.frequency,
            TotalCost,
            paymentObj?.currency,
            paymentMethod?.billing_details?.email,
            null,
            paymentMethod?.id,
            paymentMethodTypes[paymentMethod?.card?.wallet?.type]
              ? paymentMethodTypes[paymentMethod?.card?.wallet?.type]
              : paymentMethod?.card?.wallet?.type,
            clientData?.browser,
            clientData?.country,
            clientData?.region,
            clientData?.city,
            clientData?.device,
            clientData?.ip_address
          );
          if (responsePayment?.data?.success) {
            dispatch(success("your request was successful!"));
            setMode("thanks");
          }
          setPaymentLoading(false);
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error("Error:", error);
          dispatch(errorMsg(error?.response?.data?.error));
          dispatch(setSpinnerLoading(false));
          setPaymentLoading(false);
        }
      });
    }
  }, [stripe, mode]);

  const fetchCampaignData = async () => {
    try {
      const response = await DonationService.getCampaignDetails(uuid);
      const campaignData = response?.data?.campaign;
      setCampaignDetails(campaignData);
      setPaymentObj((prevPaymentObj) => ({
        ...prevPaymentObj,
        currency: campaignData?.default_checkout_currency ? campaignData?.default_checkout_currency : "USD",
        currencySymbol: campaignData?.default_checkout_currency ? getSymbolFromCurrency(campaignData?.default_checkout_currency) : "$",
      }));
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (uuid && !campaignDetails) {
      fetchCampaignData();
    }
  }, [uuid]);

  const handleInputChange = (field, value) => {
    setPaymentObj((prevPaymentObj) => ({
      ...prevPaymentObj,
      [field]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isObjectEmpty(checkField)) {
      dispatch(errorMsg("Please complete the form."));
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    dispatch(setSpinnerLoading(true));
    try {
      const cardElement = elements?.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        console.error(error.message);
        dispatch(errorMsg("your request was unsuccessful!"));
        dispatch(setSpinnerLoading(false));
        return;
      }
      const TotalCost = paymentObj?.amount + processCost;
      setPaymentLoading(true);
      const responsePayment = await DonationService.makeDonation(
        paymentObj?.firstName,
        paymentObj?.lastName,
        uuid,
        paymentObj?.isRecurring,
        paymentObj?.frequency,
        TotalCost,
        paymentObj?.currency,
        paymentObj?.email,
        token.id,
        null,
        "card",
        clientData?.browser,
        clientData?.country,
        clientData?.region,
        clientData?.city,
        clientData?.device,
        clientData?.ip_address,
        paymentObj?.cardTitle
      );
      if (responsePayment?.data?.success) {
        dispatch(success("your request was successful!"));
        setMode("thanks");
      }
      setPaymentLoading(false);
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(errorMsg(error?.response?.data?.error));
      dispatch(setSpinnerLoading(false));
      setPaymentLoading(false);
    }
  };

  const handleShare = (shareTo) => {
    const url = window.location.href;
    if (shareTo === "facebook") {
      const quote =
        "Join me in supporting a great cause! Every contribution makes a difference. 🌟";
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(quote)}`,
        "_blank"
      );
    } else if (shareTo === "whatsapp") {
      const text =
        "I just donated to a wonderful cause! 💖 Check it out donation link:";
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${text} ${url}`
        )}`,
        "_blank"
      );
    } else if (shareTo === "email") {
      const subject = "Support a Great Cause";
      const body = `
        Hi,
    
        I wanted to share a donation link for a cause that's close to my heart. Every contribution matters!
    
        Donation Link: ${url}
    
        Thanks for your support!
    
        Best regards,
      `;
      window.location.href = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
    }
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (!coverImage && (currentTemplate === "0" || currentTemplate === "3")) {
      setNewImage(plant);
    } else if (
      !coverImage &&
      (currentTemplate === "1" || currentTemplate === "5")
    ) {
      setNewImage(templateOrange);
    } else if (
      !coverImage &&
      (currentTemplate === "2" || currentTemplate === "4")
    ) {
      setNewImage(templateBW);
    } else if (coverImage) {
      setNewImage(coverImage);
    }
  }, [coverImage]);

  return (
    <>
      {currentTemplate === "0" || currentTemplate === "3" ? (
        <Grid
          container
          sx={{
            display: "flex",
            padding: "25px",
            background:
              currentTemplate === "0"
                ? "#030303"
                : currentTemplate === "3"
                ? "#ECECECDD"
                : "",
          }}
        >
          <Grid
            item
            md={embed ? 7 : 8}
            sm={12}
            xs={12}
            mx={1}
            px={{ md: embed ? 6 : 10, sm: embed ? 6 : 10, xs: 2 }}
            py={7}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              background:
                currentTemplate === "0"
                  ? "#0D0D0DE5"
                  : currentTemplate === "3"
                  ? "#ECECECDD"
                  : "",
              boxShadow:
                currentTemplate === "0"
                  ? "0px 0px 0px red"
                  : currentTemplate === "3"
                  ? "0px 8px 5px 0px #00000040"
                  : "",
              color:
                currentTemplate === "0"
                  ? "#FFFF"
                  : currentTemplate === "3"
                  ? "#030303"
                  : "",
              borderRadius: "15px",
              overflow: "visible",
              height: "fit-content",
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background:
                  currentTemplate === "0"
                    ? "linear-gradient(145deg, white, transparent)"
                    : currentTemplate === "3"
                    ? "linear-gradient(180deg, white, transparent)"
                    : "",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  transform: {
                    md: "scale(1)",
                    sm: "scale(1)",
                    xs: "scale(0.7)",
                  },
                  justifyContent: "center",
                }}
              ></Grid>
              <Grid className={classes.column}>
                {mode === "donate" ? (
                  <>
                    <Typography
                      className={classes.head700}
                      sx={{
                        fontSize: { md: "28px", sm: "25px", xs: "15px" },
                      }}
                    >
                      Checkout
                    </Typography>
                    <Grid
                      mt={3}
                      mb={1}
                      className={classes.bkg}
                      sx={{
                        backgroundImage: `url('${newImage}')`,
                      }}
                    ></Grid>
                    <Grid mt={1} mb={5}>
                      <Typography className={classes.f16w400}>
                        Please provide your information so that we can contact
                        you about your donation.
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.head700}
                      sx={{
                        textAlign: { md: "left", sm: "left", xs: "center" },
                        fontSize: { md: "28px", sm: "25px", xs: "20px" },
                      }}
                    >
                      {formTitle
                        ? formTitle
                        : campaignDetails?.ask_page?.title ||
                          "You have the power to create an impact."}
                    </Typography>
                    <Grid my={3}>
                      <img
                        src={newImage}
                        alt=""
                        className={classes.fullWidth}
                      />
                    </Grid>
                    <Grid my={1}>
                      <Typography
                        className={classes.f16w400}
                        sx={{
                          textAlign: { md: "left", sm: "left", xs: "center" },
                        }}
                      >
                        {formDesc
                          ? formDesc
                          : campaignDetails?.ask_page?.message?.replace(/^<p>/, '')?.replace(/<\/p>$/, '') ||
                            "Support our cause and make a difference today by contributing to our donation fund. Your generous donations enable us to continue our mission. Every dollar counts and helps us create a positive impact in the lives of those we serve. Together, we can make a meaningful difference and build a brighter future for our community. Thank you for your support!"}
                      </Typography>
                    </Grid>
                  </>
                )}
                {mode === "donate" && (
                  <>
                    <div id="payment-request-button"></div>
                    <h1>Pay by Card</h1>
                    <FormControl
                      className={classes.spaceBtwn}
                      sx={{
                        width: { md: "100%", sm: "65%", xs: "100%" },
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="outlined-basic"
                        label="First Name"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        value={paymentObj.firstName}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        id="outlined-basic"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        value={paymentObj.lastName}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                    </FormControl>

                    <FormControl
                      className={classes.spaceBtwn}
                      sx={{
                        width: { md: "100%", sm: "65%", xs: "100%" },
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="outlined-basic"
                        label="Name on Card"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        value={paymentObj.cardTitle}
                        onChange={(e) =>
                          handleInputChange("cardTitle", e.target.value)
                        }
                      />
                      <TextField
                        label="Card Number"
                        variant="outlined"
                        id="outlined-basic"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        InputProps={{
                          // inputComponent: StripeCardNumberElement,
                          inputComponent:
                            currentTemplate === "0"
                              ? StripeCardNumberLightElement
                              : currentTemplate === "3"
                              ? StripeCardNumberElement
                              : "",
                        }}
                      />
                    </FormControl>

                    <FormControl
                      className={classes.spaceBtwn}
                      sx={{
                        width: { md: "100%", sm: "65%", xs: "100%" },
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="outlined-basic"
                        label="Card Expiry Date"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        InputProps={{
                          inputComponent:
                            currentTemplate === "0"
                              ? StripeCardExpiryLightElement
                              : currentTemplate === "3"
                              ? StripeCardExpiryElement
                              : "",
                        }}
                      />
                      <TextField
                        label="CVC"
                        variant="outlined"
                        id="outlined-basic"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                              backgroundColor: "transparent !important",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        InputProps={{
                          inputComponent:
                            currentTemplate === "0"
                              ? StripeCardCvcLightElement
                              : currentTemplate === "3"
                              ? StripeCardCvcElement
                              : "",
                        }}
                      />
                    </FormControl>

                    <FormControl
                      className={classes.spaceBtwn}
                      sx={{
                        width: { md: "100%", sm: "65%", xs: "100%" },
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="outlined-basic"
                        label="Email"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        value={paymentObj.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                      />
                      <TextField
                        variant="outlined"
                        id="outlined-basic"
                        label="Phone"
                        className={classes.input2}
                        sx={{
                          //my: { md: 2, sm: 4, xs: 2 },
                          my: 3,
                          mr: 1,
                          width: { md: "45%", sm: "45%", xs: "100%" },
                          backgroundColor:
                            currentTemplate === "0"
                              ? "#0D0D0D"
                              : currentTemplate === "3"
                              ? "#FFFF"
                              : "",
                          border:
                            currentTemplate === "0"
                              ? "1px solid #FFFF"
                              : currentTemplate === "3"
                              ? "1px solid #0D0D0D"
                              : "",
                          "& .MuiOutlinedInput-input": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            "&::placeholder": {
                              color:
                                currentTemplate === "0"
                                  ? "#FFFF"
                                  : currentTemplate === "3"
                                  ? "#030303"
                                  : "",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                          },
                        }}
                        value={paymentObj.phone}
                        onChange={(e) =>
                          handleInputChange("phone", e.target.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={embed ? 4.5 : 3.5}
            sm={12}
            xs={12}
            mx={1}
            px={{ md: 6, sm: 6, xs: 2 }}
            py={3}
            my={{ md: 0, sm: 4, xs: 4 }}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              background:
                currentTemplate === "0"
                  ? "#0D0D0DE5"
                  : currentTemplate === "3"
                  ? "#ECECECDD"
                  : "",
              boxShadow:
                currentTemplate === "0"
                  ? "0px 0px 0px red"
                  : currentTemplate === "3"
                  ? "0px 8px 5px 0px #00000040"
                  : "",
              color:
                currentTemplate === "0"
                  ? "#FFFF"
                  : currentTemplate === "3"
                  ? "#030303"
                  : "",
              borderRadius: "15px",
              overflow: "visible",
              height: "fit-content",
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background:
                  currentTemplate === "0"
                    ? "linear-gradient(145deg, white, transparent)"
                    : currentTemplate === "3"
                    ? "linear-gradient(180deg, white, transparent)"
                    : "",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            {mode === "secure" ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { md: "column", sm: "column", xs: "column" },
                }}
              >
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Grid mr={1} sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        currentTemplate === "0"
                          ? sheild
                          : currentTemplate === "3"
                          ? sheildBlack
                          : ""
                      }
                      alt=""
                    />
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: { md: "22px", sm: "20px", xs: "15px" },
                      fontWeight: "500",
                    }}
                  >
                    Secure Donation
                  </Typography>
                </Grid>

                <Grid my={4}>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      overflowX: { md: "hidden", sm: "hidden", xs: "scroll" },
                    }}
                  >
                    {frequencyDisplayed?.map((item, index) => (
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          mx: 0.5,
                          my: 0.5,
                          width: "20%",
                          border:
                            paymentObj.frequency === item.name.toLowerCase()
                              ? "2px solid #A4B200"
                              : "2px solid #BCBCBC",
                          borderRadius: "5px",
                          padding: "8px 10px",
                          background:
                            currentTemplate === "3" &&
                            paymentObj.frequency === item.name.toLowerCase()
                              ? "#FFF"
                              : currentTemplate === "0"
                              ? "transparent"
                              : "",
                          "&:hover": {
                            background:
                              currentTemplate === "3" &&
                              paymentObj.frequency === item.name.toLowerCase()
                                ? "#f2fa93"
                                : currentTemplate === "0"
                                ? "transparent"
                                : "",
                          },
                        }}
                        value={item.name.toLowerCase()}
                        onClick={() => {
                          handleInputChange(
                            "frequency",
                            item.name.toLowerCase()
                          );
                          item.value.toLowerCase() === "once"
                            ? handleInputChange("isRecurring", false)
                            : handleInputChange("isRecurring", true);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            textTransform: "capitalize",
                            fontSize: embed ? "10.5px" : "9px",
                            fontWeight: "400",
                            textWrap: "nowrap",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Grid>

                <Grid my={2}>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {suggestionsDisplayed?.map((item, index) => (
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          width: "28%",
                          marginTop: "10px",
                          border:
                            paymentObj.amount === item.name
                              ? "2px solid #A4B200"
                              : "2px solid #BCBCBC",
                          borderRadius: "5px",
                          padding: "8px 15px",
                          background:
                            currentTemplate === "3" &&
                            paymentObj.amount === item.name
                              ? "#FFF"
                              : currentTemplate === "0"
                              ? "transparent"
                              : "",
                          "&:hover": {
                            background:
                              currentTemplate === "3" &&
                              paymentObj.amount === item.name
                                ? "#f2fa93"
                                : currentTemplate === "0"
                                ? "transparent"
                                : "",
                          },
                        }}
                        onClick={() => handleInputChange("amount", item.name)}
                      >
                        <Typography
                          sx={{
                            color:
                              currentTemplate === "0"
                                ? "#FFFF"
                                : currentTemplate === "3"
                                ? "#030303"
                                : "",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            fontWeight: "400",
                            textWrap: "nowrap",
                          }}
                        >
                          {`${paymentObj.currencySymbol}`} {item.name}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #FFFF",
                    borderRadius: "10px",
                  }}
                >
                  <TextField
                    label={`${paymentObj.currencySymbol}.`}
                    variant="outlined"
                    type="number"
                    value={paymentObj.amount}
                    onChange={(e) =>
                      handleInputChange("amount", e.target.value)
                    }
                    className={classes.input}
                    sx={{
                      width: "150%",
                      backgroundColor:
                        currentTemplate === "0"
                          ? "#0D0D0D"
                          : currentTemplate === "3"
                          ? "#FFFF"
                          : "",
                      border:
                        currentTemplate === "0"
                          ? "1px solid #FFFF"
                          : currentTemplate === "3"
                          ? "1px solid #0D0D0D"
                          : "",
                      "& .MuiOutlinedInput-input": {
                        width: "140%",
                        "&::placeholder": {
                          color:
                            currentTemplate === "0"
                              ? "#FFFF"
                              : currentTemplate === "3"
                              ? "#030303"
                              : "",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color:
                          currentTemplate === "0"
                            ? "#FFFF"
                            : currentTemplate === "3"
                            ? "#030303"
                            : "",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          //      sx={{ width: "fit-content" }}
                        >
                          <TextField
                            select
                            value={paymentObj?.currency}
                            onChange={(e) => {
                              handleInputChange("currency", e.target.value);
                              const selectedCurrency = currencies.find(
                                (currency) =>
                                  currency.value === e?.target?.value
                              );
                              handleInputChange(
                                "currencySymbol",
                                selectedCurrency.label
                              );
                            }}
                            variant="outlined"
                            sx={{
                              width: "fit-content",
                              paddingLeft: "0px",
                              backgroundColor:
                                currentTemplate === "0"
                                  ? "#0D0D0D"
                                  : currentTemplate === "3"
                                  ? "#FFFF"
                                  : "",
                              "& .MuiOutlinedInput-input": {
                                color:
                                  currentTemplate === "0"
                                    ? "#FFFF"
                                    : currentTemplate === "3"
                                    ? "#030303"
                                    : "",
                                "&::placeholder": {
                                  color:
                                    currentTemplate === "0"
                                      ? "#FFFF"
                                      : currentTemplate === "3"
                                      ? "#030303"
                                      : "",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color:
                                  currentTemplate === "0"
                                    ? "#FFFF"
                                    : currentTemplate === "3"
                                    ? "#030303"
                                    : "",
                              },
                            }}
                            className={classes.select}
                          >
                            {currencies?.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                className={classes.menuItem}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Grid mt={6}>
                  <Button
                    disableRipple
                    onClick={() => {
                      if (paymentObj.amount) {
                        !paymentObj.isRecurring
                          ? setMode("consider")
                          : setMode("donate");
                      }
                    }}
                    sx={{
                      backgroundColor: paymentObj.amount
                        ? "#A4B200"
                        : "#706e6e",
                      color: paymentObj.amount ? "#fff" : "#000",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid transparent",
                      "&:hover": {
                        color:
                          currentTemplate === "0"
                            ? "#FFFF"
                            : currentTemplate === "3"
                            ? "#030303"
                            : "",
                        border: paymentObj.amount
                          ? "2px solid #A4B200"
                          : "2px solid transparent",
                        backgroundColor: paymentObj.amount
                          ? "transparent"
                          : "#706e6e",
                        cursor: paymentObj.amount ? "pointer" : "not-allowed",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Donate Now
                    </Typography>
                  </Button>
                </Grid>

                <Grid
                  mt={1}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "white",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "white",
                          margin: "0px",
                          padding: "0px",
                          minHeight: "4px",
                          maxHeight: "30px",
                        }}
                      >
                        Is my donation &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        secure?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "white" : "white",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        Your donation is handled with the utmost care and
                        confidentiality. If you have any further questions or
                        concerns about donation security, please don't hesitate
                        to reach out to us.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "white",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "white",
                          margin: "0px",
                          padding: "0px",
                          maxHeight: "30px",
                          minHeight: "4px",
                        }}
                      >
                        Can I cancel my recurring donation?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "white" : "white",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        If you encounter any difficulties or have specific
                        questions about canceling your recurring donation,
                        please don't hesitate to contact our support team, and
                        we'll be happy to assist you.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "consider" ? (
              <Grid item>
                <Button
                  disableRipple
                  onClick={() => setMode("secure")}
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderRadius: "0px",
                    borderBottom:
                      currentTemplate === "0"
                        ? "1px solid #FFF"
                        : currentTemplate === "3"
                        ? "1px solid #030303"
                        : "",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "& img": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <img
                    src={
                      currentTemplate === "0"
                        ? back
                        : currentTemplate === "3"
                        ? backBlack
                        : ""
                    }
                    alt=""
                  />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color:
                        currentTemplate === "0"
                          ? "#FFFF"
                          : currentTemplate === "3"
                          ? "#030303"
                          : "",
                      textTransform: "capitalize",
                    }}
                  >
                    Become a monthly supporter
                  </Typography>
                </Button>
                <Grid mt={9}>
                  <Typography>
                    Will you consider becoming one of our valued monthly
                    suporters by converting your{" "}
                    {getSymbolFromCurrency(paymentObj?.currency)}
                    {paymentObj.amount} contribution into monthly donation?
                  </Typography>
                </Grid>
                <Grid my={5}>
                  <Grid my={1} sx={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        handleInputChange(
                          "amount",
                          Number(paymentObj.amount) * 2
                        );
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#E70000",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        height: "50px",
                        "&:hover": {
                          border: "2px solid #4f1616",
                          fontSize: "18px",
                          backgroundColor: "#4f1616",
                          "& img": {
                            transform: "scale(1.1)",
                          },
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          position: "absolute",
                          height: "fit-content",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={heart}
                          alt="Heart"
                          style={{
                            position: "absolute",
                            bottom: "-3px",
                            left: "0%",
                          }}
                        />

                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Donate {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount * 2}/month
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={() => {
                        handleInputChange("amount", paymentObj.amount);
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        //height:"20%",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                          color:
                            currentTemplate === "0"
                              ? "#FFF"
                              : currentTemplate === "3"
                              ? "#030303"
                              : "",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Donate {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}/month
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={7}>
                    <Button
                      onClick={() => setMode("donate")}
                      sx={{
                        backgroundColor: "transparent",
                        color:
                          currentTemplate === "0"
                            ? "#FFF"
                            : currentTemplate === "3"
                            ? "#030303"
                            : "",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px",
                        border:
                          currentTemplate === "0"
                            ? "2px solid #FFFFFF"
                            : currentTemplate === "3"
                            ? "2px solid #030303"
                            : "",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                          color: "#A4B200",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Keep my one-time{" "}
                        {getSymbolFromCurrency(paymentObj?.currency)}
                        {paymentObj.amount} gift
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "donate" ? (
              <Grid item>
                <Button
                  onClick={() =>
                    !paymentObj.isRecurring
                      ? setMode("consider")
                      : setMode("secure")
                  }
                  disableRipple
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderRadius: "0px",
                    borderBottom:
                      currentTemplate === "0"
                        ? "1px solid #FFF"
                        : currentTemplate === "3"
                        ? "1px solid #030303"
                        : "",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    },
                  }}
                >
                  <img
                    src={
                      currentTemplate === "0"
                        ? back
                        : currentTemplate === "3"
                        ? backBlack
                        : ""
                    }
                    alt=""
                  />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color:
                        currentTemplate === "0"
                          ? "#FFFF"
                          : currentTemplate === "3"
                          ? "#030303"
                          : "",
                      textTransform: "capitalize",
                    }}
                  >
                    Payment Information
                  </Typography>
                </Button>

                <Grid my={5} item md={12}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Donation</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      my={2}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Processing Fee</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                      }}
                    >
                      <Grid>
                        <Typography>Currency</Typography>
                      </Grid>
                      <Grid>
                        <Typography>{paymentObj?.currency}</Typography>
                      </Grid>
                    </Grid>
                    {paymentObj.isRecurring && (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}
                      >
                        <Grid>
                          <Typography>Recurring</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {capitalizeFirstLetter(paymentObj?.frequency)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Total</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {Number(paymentObj?.amount) + processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={embed ? setMode("thanks") : handleSubmit}
                      disabled={isPaymentLoading}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color:
                          currentTemplate === "0"
                            ? "#FFFF"
                            : currentTemplate === "3"
                            ? "#030303"
                            : "",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {isPaymentLoading ? "Loading..." : "Donate Now"}
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={StripeLogo}
                      alt="stripe-logo"
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "thanks" ? (
              <Grid item>
                <Grid
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Button
                    disableRipple
                    onClick={() => setMode("secure")}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid #FFF",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <img src={back} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#FFF",
                        textTransform: "capitalize",
                      }}
                    >
                      Thank you
                    </Typography>
                  </Button>
                </Grid>

                <Grid my={5} item md={12}>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#ADFF00",
                    }}
                  >
                    Thank you for the donation of ${" "}
                    {Number(paymentObj?.amount) + processCost}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "#689900",
                    width: "100%",
                    zIndex: "22",
                    borderRadius:"15px"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      textWrap: "wrap",
                      padding: "20px 5px",
                    }}
                  >
                    {formDesc
                      ? formDesc
                      : campaignDetails?.description ||
                        "Share now to help us spread this cause"}
                  </Typography>
                </Grid>
                <Grid my={4}>
                  <Button
                    onClick={() => handleShare("facebook")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={fb} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Facebook
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("whatsapp")}
                    sx={{
                      my: 2,
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={wa} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Watsapp
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("email")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={mail} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Email
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : currentTemplate === "1" ? (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: embed ? "100%" : "100vh",
            minWidth: "100%",
            padding: { md: "35px", sm: "35px", xs: "10px" },
            backgroundImage: `url('${newImage}')`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            borderRadius: "15px",
          }}
        >
          <Grid item md={7} sx={{ alignItems: "flex-end" }}>
            <Typography
              sx={{
                width: "100%",
                textTransform: "uppercase",
                fontSize: { md: "50px", sm: "50px", xs: "35px" },
                fontWeight: "700",
                fontFamily: "Stolzl",
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              {formTitle
                ? formTitle
                : askPage?.title || (
                    <p>
                      We can <br />
                      save the future
                    </p>
                  )}
            </Typography>
            <Typography
              sx={{
                width: "100%",
                textTransform: "uppercase",
                fontSize: { md: "30px", sm: "30px", xs: "20px" },
                fontWeight: "400",
                fontFamily: "Stolzl",
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              {formDesc
                ? formDesc
                : askPage?.message || (
                    <p>
                      We can <br />
                      save the future
                    </p>
                  )}
            </Typography>
          </Grid>

          <Grid
            item
            md={embed ? 4.5 : 4.5}
            sm={12}
            xs={12}
            mx={0}
            px={{ md: 6, sm: 6, xs: 2 }}
            py={3}
            my={{ md: 0, sm: 4, xs: 4 }}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              //background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
              backdropFilter: "blur(50px)",
              //background: "#481B1599",
              background: "transparent",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
              borderRadius: "15px",
              overflow: "visible",
              height: "fit-content",
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background:
                  themeMode === "dark"
                    ? "linear-gradient(145deg, white, transparent)"
                    : "linear-gradient(180deg, white, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            {mode === "secure" ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { md: "column", sm: "column", xs: "column" },
                }}
              >
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "12px", sm: "10px", xs: "15px" },
                      fontWeight: "500",
                      fontFamily: "Stolzl",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Choose Donation Frequency
                  </Typography>
                </Grid>

                <Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      overflowX: { md: "hidden", sm: "hidden", xs: "scroll" },
                    }}
                  >
                    {frequencyDisplayed?.map((item, index) => (
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          mt: 1,
                          width: "20%",
                          border:
                            themeMode === "dark" &&
                            paymentObj.frequency === item.name.toLowerCase()
                              ? "2px solid #A4B200"
                              : themeMode === "light" &&
                                paymentObj.frequency === item.name.toLowerCase()
                              ? "2px solid #A4B200"
                              : themeMode === "dark"
                              ? "2px solid #BCBCBC"
                              : themeMode === "light"
                              ? "2px solid #030303"
                              : "",
                          borderRadius: "5px",
                          padding: "8px 10px",

                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        value={item.name.toLowerCase()}
                        onClick={() => {
                          handleInputChange(
                            "frequency",
                            item.name.toLowerCase()
                          );
                          item.name.toLowerCase() === "once"
                            ? handleInputChange("isRecurring", false)
                            : handleInputChange("isRecurring", true);
                        }}
                      >
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                            textTransform: "capitalize",
                            fontSize: embed ? "10px" : "9px",
                            fontWeight: "400",
                            textWrap: "nowrap",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Grid>

                <Grid mt={4} sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "12px", sm: "10px", xs: "15px" },
                      fontWeight: "500",
                      fontFamily: "Stolzl",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Choose the donation amount
                  </Typography>
                </Grid>

                <Grid>
                  <FormControl sx={{ width: "100%", fontSize: "14px" }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={selectedValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="newCampaign"
                        sx={{
                          textAlign: "left",
                          border:
                            themeMode === "dark" && selectedValue === "a"
                              ? "1px solid #CADC00"
                              : themeMode === "light" && selectedValue === "a"
                              ? "1px solid #A4B200"
                              : themeMode === "dark"
                              ? "1px solid #FFF"
                              : themeMode === "light"
                              ? "1px solid #030303"
                              : "",
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          ml: 0.5,
                        }}
                        control={
                          <Radio
                            {...controlProps("a")}
                            sx={{
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#8ED003",
                              },
                            }}
                          />
                        }
                        label="Rs 1000"
                      />
                      <FormControlLabel
                        value="clone"
                        control={
                          <Radio
                            {...controlProps("b")}
                            sx={{
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#8ED003",
                              },
                            }}
                          />
                        }
                        label="Rs. 500"
                        sx={{
                          mt: { md: 1, sm: 2, xs: 4 },
                          textAlign: "left",
                          border:
                            themeMode === "dark" && selectedValue === "b"
                              ? "1px solid #CADC00"
                              : themeMode === "light" && selectedValue === "b"
                              ? "1px solid #A4B200"
                              : themeMode === "dark"
                              ? "1px solid #FFF"
                              : themeMode === "light"
                              ? "1px solid #030303"
                              : "",
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          ml: 0.5,
                        }}
                      />
                      <FormControlLabel
                        value="clone"
                        control={
                          <Radio
                            {...controlProps("c")}
                            sx={{
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#8ED003",
                              },
                            }}
                          />
                        }
                        label="Rs. 200"
                        sx={{
                          mt: { md: 1, sm: 2, xs: 4 },
                          textAlign: "left",
                          border:
                            themeMode === "dark" && selectedValue === "c"
                              ? "1px solid #CADC00"
                              : themeMode === "light" && selectedValue === "c"
                              ? "1px solid #A4B200"
                              : themeMode === "dark"
                              ? "1px solid #FFF"
                              : themeMode === "light"
                              ? "1px solid #030303"
                              : "",
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          ml: 0.5,
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid mt={6}>
                  <Button
                    disableRipple
                    onClick={() => {
                      if (paymentObj.amount) {
                        !paymentObj.isRecurring
                          ? setMode("consider")
                          : setMode("donate");
                      }
                    }}
                    sx={{
                      backgroundColor: paymentObj.amount
                        ? "#A4B200"
                        : "#706e6e",
                      color: paymentObj.amount ? "#fff" : "#000",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid transparent",
                      "&:hover": {
                        border: paymentObj.amount
                          ? "2px solid #A4B200"
                          : "2px solid transparent",
                        backgroundColor: paymentObj.amount
                          ? "transparent"
                          : "#706e6e",
                        cursor: paymentObj.amount ? "pointer" : "not-allowed",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Donate Now
                    </Typography>
                  </Button>
                </Grid>

                <Grid
                  mt={1}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "white",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "black",
                          margin: "0px",
                          padding: "0px",
                          minHeight: "4px",
                          maxHeight: "30px",
                        }}
                      >
                        Is my donation &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        secure?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "black" : "black",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        Your donation is handled with the utmost care and
                        confidentiality. If you have any further questions or
                        concerns about donation security, please don't hesitate
                        to reach out to us.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "black",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "black",
                          margin: "0px",
                          padding: "0px",
                          maxHeight: "30px",
                          minHeight: "4px",
                        }}
                      >
                        Can I cancel my recurring donation?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "black" : "black",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        If you encounter any difficulties or have specific
                        questions about canceling your recurring donation,
                        please don't hesitate to contact our support team, and
                        we'll be happy to assist you.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "consider" ? (
              <Grid item>
                <Button
                  disableRipple
                  onClick={() => setMode("secure")}
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "1px solid #FFF",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "& img": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <img src={back} alt="" />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color: "#FFF",
                      textTransform: "capitalize",
                    }}
                  >
                    Become a monthly supporter
                  </Typography>
                </Button>
                <Grid mt={9}>
                  <Typography>
                    Will you consider becoming one of our valued monthly
                    suporters by converting your{" "}
                    {getSymbolFromCurrency(paymentObj?.currency)}
                    {paymentObj.amount} contribution into monthly donation?
                  </Typography>
                </Grid>
                <Grid my={5}>
                  <Grid my={1} sx={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        handleInputChange(
                          "amount",
                          Number(paymentObj.amount) * 2
                        );
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#E70000",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        height: "50px",
                        "&:hover": {
                          border: "2px solid #4f1616",
                          fontSize: "18px",
                          backgroundColor: "#4f1616",
                          "& img": {
                            transform: "scale(1.1)",
                          },
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          position: "absolute",
                          height: "fit-content",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={heart}
                          alt="Heart"
                          style={{
                            position: "absolute",
                            bottom: "-3px",
                            left: "0%",
                          }}
                        />

                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Donate {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount * 2}/month
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={() => {
                        handleInputChange("amount", paymentObj.amount);
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        //height:"20%",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Donate {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}/month
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={7}>
                    <Button
                      onClick={() => {
                        setMode("donate")
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #FFFFFF",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                          color: "#A4B200",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Keep my one-time{" "}
                        {getSymbolFromCurrency(paymentObj?.currency)}
                        {paymentObj.amount} gift
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "pay" ? (
              <>
                <div id="payment-request-button"></div>
                <h1>Pay by Card</h1>
                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="First Name"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Name on Card"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.cardTitle}
                    onChange={(e) =>
                      handleInputChange("cardTitle", e.target.value)
                    }
                  />
                  <TextField
                    label="Card Number"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        color: "#FFFF",
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardNumberLightElement,
                    }}
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Card Expiry Date"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardExpiryLightElement,
                    }}
                  />
                  <TextField
                    label="CVC"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardCvcLightElement,
                    }}
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Email"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Phone"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "transparent",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                </FormControl>

                <Grid mt={3}>
                  <Button
                    onClick={embed ? setMode("thanks") : handleSubmit}
                    disabled={isPaymentLoading}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #FFFFFF",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                        color: "#A4B200",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      {isPaymentLoading ? "Loading..." : "Donate Now"}
                    </Typography>
                  </Button>
                </Grid>
              </>
            ) : mode === "donate" ? (
              <Grid item>
                <Button
                  onClick={() =>
                    !paymentObj.isRecurring
                      ? setMode("consider")
                      : setMode("secure")
                  }
                  disableRipple
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "1px solid #FFF",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    },
                  }}
                >
                  <img src={back} alt="" />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color: "#FFF",
                      textTransform: "capitalize",
                    }}
                  >
                    Payment Information
                  </Typography>
                </Button>

                <Grid my={5} item md={12}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Donation</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      my={2}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Processing Fee</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                      }}
                    >
                      <Grid>
                        <Typography>Currency</Typography>
                      </Grid>
                      <Grid>
                        <Typography>{paymentObj?.currency}</Typography>
                      </Grid>
                    </Grid>
                    {paymentObj.isRecurring && (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}
                      >
                        <Grid>
                          <Typography>Recurring</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {capitalizeFirstLetter(paymentObj?.frequency)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Total</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {Number(paymentObj?.amount) + processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={() => setMode("pay")}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Proceed
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={StripeLogo}
                      alt="stripe-logo"
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "thanks" ? (
              <Grid item>
                <Grid
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Button
                    disableRipple
                    onClick={() => setMode("secure")}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid #FFF",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <img src={back} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#FFF",
                        textTransform: "capitalize",
                      }}
                    >
                      Thank you
                    </Typography>
                  </Button>
                </Grid>

                <Grid my={5} item md={12}>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#ADFF00",
                    }}
                  >
                    Thank you for the donation of ${" "}
                    {Number(paymentObj?.amount) + processCost}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "#689900",
                    width: "100%",
                    zIndex: "22",
                    borderRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      textWrap: "wrap",
                      padding: "20px 5px",
                    }}
                  >
                    Share now to help us spread this cause
                  </Typography>
                </Grid>
                <Grid my={4}>
                  <Button
                    onClick={() => handleShare("facebook")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={fb} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Facebook
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("whatsapp")}
                    sx={{
                      my: 2,
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={wa} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Watsapp
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("email")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={mail} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Email
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : currentTemplate === "2" ? (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: embed ? "100%" : "100vh",
            minWidth: "100%",
            padding: { md: "35px", sm: "35px", xs: "5px" },
            backgroundImage: `url('${newImage}')`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            md={embed ? 4.5 : 4}
            sm={12}
            xs={12}
            mx={{ md: 1, sm: 1, xs: 0 }}
            px={{ md: 6, sm: 6, xs: 1 }}
            py={3}
            my={{ md: 0, sm: 4, xs: 4 }}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              background: "#222222",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: "#FFFF",
              borderRadius: "15px",
              overflow: "visible",
              height: "fit-content",
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background:
                  themeMode === "dark"
                    ? "linear-gradient(145deg, white, transparent)"
                    : "linear-gradient(180deg, white, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            {mode === "secure" ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { md: "column", sm: "column", xs: "column" },
                }}
              >
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "22px", sm: "20px", xs: "15px" },
                      fontWeight: "500",
                    }}
                  >
                    Secure Donation
                  </Typography>
                </Grid>

                <Grid my={4}>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {frequencyDisplayed?.map((item, index) => (
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          mx: 0.5,
                          width: "20%",
                          border:
                            paymentObj.frequency === item.name.toLowerCase()
                              ? "2px solid #A4B200"
                              : "2px solid #BCBCBC",
                          borderRadius: "5px",
                          padding: {
                            md: "8px 10px",
                            sm: "8px 10px",
                            xs: "8px 8px",
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        value={item.name.toLowerCase()}
                        onClick={() => {
                          handleInputChange(
                            "frequency",
                            item.name.toLowerCase()
                          );
                          item.name.toLowerCase() === "once"
                            ? handleInputChange("isRecurring", false)
                            : handleInputChange("isRecurring", true);
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#FFFF",
                            textTransform: "capitalize",
                            fontSize: {
                              md: embed ? "10.5px" : "9px",
                              sm: embed ? "10.5px" : "9px",
                              xs: embed ? "8px" : "9px",
                            },
                            fontWeight: "400",
                            textWrap: "nowrap",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Grid>

                <Grid my={2}>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    {suggestionsDisplayed?.map((item, index) => (
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          width: "28%",
                          marginTop: "10px",
                          border:
                            paymentObj.amount === item.name
                              ? "2px solid #A4B200"
                              : "2px solid #BCBCBC",
                          borderRadius: "5px",
                          padding: "8px 15px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => handleInputChange("amount", item.name)}
                      >
                        <Typography
                          sx={{
                            color: "#FFFF",
                            textTransform: "capitalize",
                            fontSize: "12px",
                            fontWeight: "400",
                            textWrap: "nowrap",
                          }}
                        >
                          {`${paymentObj.currencySymbol}`} {item.name}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #FFFF",
                    borderRadius: "10px",
                  }}
                >
                  <TextField
                    label={`${paymentObj.currencySymbol}.`}
                    variant="outlined"
                    type="number"
                    value={paymentObj.amount}
                    onChange={(e) =>
                      handleInputChange("amount", e.target.value)
                    }
                    className={classes.input}
                    sx={{
                      width: "150%",
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        width: "140%",
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          //      sx={{ width: "fit-content" }}
                        >
                          <TextField
                            select
                            value={paymentObj?.currency}
                            onChange={(e) => {
                              handleInputChange("currency", e.target.value);
                              const selectedCurrency = currencies.find(
                                (currency) =>
                                  currency.value === e?.target?.value
                              );
                              handleInputChange(
                                "currencySymbol",
                                selectedCurrency.label
                              );
                            }}
                            variant="outlined"
                            sx={{
                              width: "fit-content",
                              paddingLeft: "0px",
                              backgroundColor: "#0D0D0D",
                              "& .MuiOutlinedInput-input": {
                                color: "#FFFF",
                                "&::placeholder": {
                                  color: "#FFFF",
                                },
                              },
                              "& .MuiInputLabel-root": {
                                color: "#FFFF",
                              },
                            }}
                            className={classes.select}
                          >
                            {currencies?.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                className={classes.menuItem}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Grid mt={6}>
                  <Button
                    disableRipple
                    onClick={() => {
                      if (paymentObj.amount) {
                        !paymentObj.isRecurring
                          ? setMode("consider")
                          : setMode("donate");
                      }
                    }}
                    sx={{
                      backgroundColor: paymentObj.amount
                        ? "#A4B200"
                        : "#706e6e",
                      color: paymentObj.amount ? "#fff" : "#000",
                      width: "100%",
                      borderRadius: "20px",
                      padding: "10px ",
                      border: "2px solid transparent",
                      "&:hover": {
                        border: paymentObj.amount
                          ? "2px solid #A4B200"
                          : "2px solid transparent",
                        backgroundColor: paymentObj.amount
                          ? "transparent"
                          : "#706e6e",
                        cursor: paymentObj.amount ? "pointer" : "not-allowed",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      Donate Now
                    </Typography>
                  </Button>
                </Grid>

                <Grid
                  mt={1}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "white",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "white",
                          margin: "0px",
                          padding: "0px",
                          minHeight: "4px",
                          maxHeight: "30px",
                        }}
                      >
                        Is my donation &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        secure?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "white" : "white",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        Your donation is handled with the utmost care and
                        confidentiality. If you have any further questions or
                        concerns about donation security, please don't hesitate
                        to reach out to us.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item md={6}>
                    <Accordion
                      elevation={0}
                      sx={{
                        backgroundColor: "transparent",
                        color: "white",
                        margin: "0px",
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          color: "white",
                          margin: "0px",
                          padding: "0px",
                          maxHeight: "30px",
                          minHeight: "4px",
                        }}
                      >
                        Can I cancel my recurring donation?
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          color: themeMode === "dark" ? "white" : "white",
                          textAlign: "left",
                          fontSize: "10px",
                          fontWeight: "400",
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        If you encounter any difficulties or have specific
                        questions about canceling your recurring donation,
                        please don't hesitate to contact our support team, and
                        we'll be happy to assist you.
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "consider" ? (
              <Grid item>
                <Button
                  disableRipple
                  onClick={() => setMode("secure")}
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "1px solid #FFF",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "& img": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <img src={back} alt="" />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color: "#FFF",
                      textTransform: "capitalize",
                    }}
                  >
                    Become a monthly supporter
                  </Typography>
                </Button>
                <Grid mt={9}>
                  <Typography>
                    Will you consider becoming one of our valued monthly
                    suporters by converting your{" "}
                    {getSymbolFromCurrency(paymentObj?.currency)}
                    {paymentObj.amount} contribution into monthly donation?
                  </Typography>
                </Grid>
                <Grid my={5}>
                  <Grid my={1} sx={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        handleInputChange(
                          "amount",
                          Number(paymentObj.amount) * 2
                        );
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#E70000",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        height: "50px",
                        "&:hover": {
                          border: "2px solid #4f1616",
                          fontSize: "18px",
                          backgroundColor: "#4f1616",
                          "& img": {
                            transform: "scale(1.1)",
                          },
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          position: "absolute",
                          height: "fit-content",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={heart}
                          alt="Heart"
                          style={{
                            position: "absolute",
                            bottom: "-3px",
                            left: "0%",
                          }}
                        />

                        <Grid sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Donate {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount * 2}/month
                          </Typography>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={() => {
                        handleInputChange("amount", paymentObj.amount);
                        handleInputChange("frequency", "monthly");
                        handleInputChange("isRecurring", true);
                        setMode("donate");
                      }}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        //height:"20%",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Donate {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}/month
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={7}>
                    <Button
                      onClick={() =>
                        setMode("donate")
                      }
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #FFFFFF",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                          color: "#A4B200",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Keep my one-time{" "}
                        {getSymbolFromCurrency(paymentObj?.currency)}
                        {paymentObj.amount} gift
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "pay" ? (
              <>
                <div id="payment-request-button"></div>
                <h1>Pay by Card</h1>
                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="First Name"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Name on Card"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.cardTitle}
                    onChange={(e) =>
                      handleInputChange("cardTitle", e.target.value)
                    }
                  />
                  <TextField
                    label="Card Number"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardNumberLightElement,
                    }}
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Card Expiry Date"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardExpiryLightElement,
                    }}
                  />
                  <TextField
                    label="CVC"
                    variant="outlined"
                    id="outlined-basic"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    InputProps={{
                      inputComponent: StripeCardCvcLightElement,
                    }}
                  />
                </FormControl>

                <FormControl
                  className={classes.spaceBtwn}
                  sx={{
                    width: { md: "100%", sm: "65%", xs: "100%" },
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Email"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    label="Phone"
                    className={classes.input2}
                    sx={{
                      //my: { md: 2, sm: 4, xs: 2 },
                      my: 3,
                      mr: 1,
                      width: { md: "45%", sm: "45%", xs: "100%" },
                      backgroundColor: "#0D0D0D",
                      border: "1px solid #FFFF",
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": {
                          color: "#FFFF",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFF",
                      },
                    }}
                    value={paymentObj.phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                  />
                </FormControl>

                <Grid mt={7}>
                  <Button
                    onClick={embed ? setMode("thanks") : handleSubmit}
                    disabled={isPaymentLoading}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #FFFFFF",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                        color: "#A4B200",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      {isPaymentLoading ? "Loading..." : "Donate Now"}
                    </Typography>
                  </Button>
                </Grid>
              </>
            ) : mode === "donate" ? (
              <Grid item>
                <Button
                  onClick={() =>
                    !paymentObj.isRecurring
                      ? setMode("consider")
                      : setMode("secure")
                  }
                  disableRipple
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "1px solid #FFF",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    },
                  }}
                >
                  <img src={back} alt="" />
                  <Typography
                    mx={3}
                    sx={{
                      textWrap: "nowrap",
                      color: "#FFF",
                      textTransform: "capitalize",
                    }}
                  >
                    Payment Information
                  </Typography>
                </Button>

                <Grid my={5} item md={12}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Donation</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      my={2}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Processing Fee</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 2,
                      }}
                    >
                      <Grid>
                        <Typography>Currency</Typography>
                      </Grid>
                      <Grid>
                        <Typography>{paymentObj?.currency}</Typography>
                      </Grid>
                    </Grid>
                    {paymentObj.isRecurring && (
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}
                      >
                        <Grid>
                          <Typography>Recurring</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {capitalizeFirstLetter(paymentObj?.frequency)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Grid>
                        <Typography>Total</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {Number(paymentObj?.amount) + processCost}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid my={3}>
                    <Button
                      onClick={() => setMode("pay")}
                      sx={{
                        backgroundColor: "#7EBA00",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Proceed
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={StripeLogo}
                      alt="stripe-logo"
                      style={{ width: "100%", height: "50px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : mode === "thanks" ? (
              <Grid item>
                <Grid
                  py={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Button
                    disableRipple
                    onClick={() => setMode("secure")}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid #FFF",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <img src={back} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#FFF",
                        textTransform: "capitalize",
                      }}
                    >
                      Thank you
                    </Typography>
                  </Button>
                </Grid>

                <Grid my={5} item md={12}>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      color: "#ADFF00",
                    }}
                  >
                    Thank you for the donation of ${" "}
                    {Number(paymentObj?.amount) + processCost}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    backgroundColor: "#689900",
                    width: "100%",
                    zIndex: "22",
                    borderRadius: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      textWrap: "wrap",
                      padding: "20px 5px",
                    }}
                  >
                    Share now to help us spread this cause
                  </Typography>
                </Grid>
                <Grid my={4}>
                  <Button
                    onClick={() => handleShare("facebook")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={fb} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Facebook
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("whatsapp")}
                    sx={{
                      my: 2,
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={wa} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Watsapp
                      </Typography>
                    </Grid>
                  </Button>

                  <Button
                    onClick={() => handleShare("email")}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "10px",
                      padding: "10px ",
                      border: "2px solid #689900",
                      zIndex: "10",
                      "&:hover": {
                        border: "2px solid #A4B200",
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        width: "80%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img src={mail} alt="" style={{ marginRighr: "15px" }} />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Share on Email
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={12}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid
              my={2}
              sx={{
                height: "80%",
                display: "flex",
                alignItems: "flex-end",
                background: "#222222",
                padding: "10px",
                flexDirection: "column",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { md: "25px", sm: "25px", xs: "15px" },
                  fontWeight: "700",
                  fontFamily: "Stolzl",
                  textAlign: { md: "center", sm: "center", xs: "center" },
                }}
              >
                {formTitle
                  ? formTitle
                  : askPage?.title || "You have the power to create an impact."}
              </Typography>
              <Typography
                mt={3}
                sx={{
                  width: "100%",
                  fontSize: { md: "15px", sm: "15px", xs: "10px" },
                  fontWeight: "500",
                  fontFamily: "Stolzl",
                  textAlign: { md: "center", sm: "center", xs: "center" },
                }}
              >
                {formDesc
                  ? formDesc
                  : askPage?.message ||
                    "Support our cause and make a difference today by contributing to our donation fund. Your generous donations enable us to continue our mission. Every dollar counts and helps us create a positive impact in the lives of those we serve. Together, we can make a meaningful difference and build a brighter future for our community. Thank you for your support!"}
              </Typography>
            </Grid>

            <Grid
              my={3}
              sx={{
                height: "80%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                background: "#222222",
                padding: "15px 20px",
                borderRadius: "10px",
                fontSize: { md: "40px", sm: "40px", xs: "25px" },
                color: "#ADBC00",
                fontWeight: "700",
                fontFamily: "Stolzl",
              }}
            >
              <Grid sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "25px" },
                    color: "#ADBC00",
                    fontWeight: "700",
                  }}
                >
                  04
                </Grid>
                <Grid
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "8px" },
                    color: "#FFFF",
                    fontWeight: "400",
                  }}
                >
                  Days
                </Grid>
              </Grid>
              :
              <Grid sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "25px" },
                    color: "#ADBC00",
                    fontWeight: "700",
                  }}
                >
                  15
                </Grid>
                <Grid
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "8px" },
                    color: "#FFFF",
                    fontWeight: "400",
                  }}
                >
                  Hours
                </Grid>
              </Grid>
              :
              <Grid sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "25px" },
                    color: "#ADBC00",
                    fontWeight: "700",
                  }}
                >
                  01
                </Grid>
                <Grid
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "8px" },
                    color: "#FFFF",
                    fontWeight: "400",
                  }}
                >
                  Minutes
                </Grid>
              </Grid>
              :
              <Grid sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "25px" },
                    color: "#ADBC00",
                    fontWeight: "700",
                  }}
                >
                  55
                </Grid>
                <Grid
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "8px" },
                    color: "#FFFF",
                    fontWeight: "400",
                  }}
                >
                  Seconds
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : currentTemplate === "4" ? (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            height: embed ? "100%" : "100vh",
            minWidth: "100%",
            padding: { md: "15px", sm: "15px", xs: "10px" },
            background: "#D9D9D9",
            borderRadius: "15px",
          }}
        >
          <Grid item md={5.5} sm={12} xs={12}>
            <Grid
              item
              md={embed ? 12 : 12}
              sm={12}
              xs={12}
              mx={{ md: 1, sm: 0, xs: 0 }}
              px={{ md: 6, sm: 6, xs: 1 }}
              py={3}
              my={{ md: 0, sm: 4, xs: 4 }}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "center",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                background: "transparent",
                color: "#030303",
                borderRadius: "15px",
                overflow: "visible",
                height: "fit-content",
              }}
            >
              {mode === "secure" ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: { md: "column", sm: "column", xs: "column" },
                  }}
                >
                  <Grid sx={{ display: "flex", justifyContent: "left" }}>
                    <Typography
                      sx={{
                        fontSize: { md: "22px", sm: "22px", xs: "15px" },
                        fontWeight: "700",
                      }}
                    >
                      {formTitle
                        ? formTitle
                        : askPage?.title ||
                          "You have the power to create an impact."}{" "}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", justifyContent: "left" }}>
                    <Typography
                      sx={{
                        fontSize: { md: "12px", sm: "15px", xs: "15px" },
                        textAlign: "left",
                        fontWeight: "400",
                      }}
                    >
                      {formDesc
                        ? formDesc
                        : askPage?.message ||
                          "With your help, we can save the future of many needy children. Together we can."}{" "}
                    </Typography>
                  </Grid>

                  <Grid my={4}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Stolzl",
                        textAlign: "left",
                      }}
                    >
                      Select Donation Frequency
                    </Typography>

                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {frequencyDisplayed?.map((item, index) => (
                        <Button
                          key={index}
                          disableElevation
                          disableRipple
                          sx={{
                            mx: 0.5,
                            //width: "20%",
                            borderBottom:
                              paymentObj.frequency === item.name.toLowerCase()
                                ? "2px solid #DC9300"
                                : "2px solid #000000",
                            borderRadius: "0px",
                            padding: {
                              md: "8px 20px",
                              sm: "8px 10px",
                              xs: "8px 8px",
                            },
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          value={item.name.toLowerCase()}
                          onClick={() => {
                            handleInputChange(
                              "frequency",
                              item.name.toLowerCase()
                            );
                            item.name.toLowerCase() === "once"
                              ? handleInputChange("isRecurring", false)
                              : handleInputChange("isRecurring", true);
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              color:
                                paymentObj.frequency === item.name.toLowerCase()
                                  ? "#DC9300"
                                  : "#030303",
                              fontSize: {
                                md: embed ? "14px" : "15px",
                                sm: embed ? "14px" : "9px",
                                xs: embed ? "12px" : "9px",
                              },
                              fontWeight: "700",
                              textWrap: "nowrap",
                              fontFamily: "Stolzl",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Button>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid my={2}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Stolzl",
                        textAlign: "left",
                      }}
                    >
                      Select Donation Amount
                    </Typography>
                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {suggestionsDisplayed?.map((item, index) => (
                        <Button
                          key={index}
                          disableElevation
                          disableRipple
                          sx={{
                            width: "28%",
                            marginTop: "10px",
                            borderBottom:
                              paymentObj.amount === item.name
                                ? "2px solid #DC9300"
                                : "2px solid #000000",
                            borderRadius: "0px",
                            padding: "8px 15px",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          onClick={() => handleInputChange("amount", item.name)}
                        >
                          <Typography
                            sx={{
                              color: "#FFFF",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: "600",
                              textWrap: "nowrap",
                              color:
                                paymentObj.amount === item.name
                                  ? "#DC9300"
                                  : "#030303",
                              fontSize: {
                                md: embed ? "18px" : "15px",
                                sm: embed ? "14px" : "9px",
                                xs: embed ? "12px" : "9px",
                              },
                            }}
                          >
                            {`${paymentObj.currencySymbol}`} {item.name}
                          </Typography>
                        </Button>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid my={6}>
                    <Button
                      disableRipple
                      onClick={() => {
                        if (paymentObj.amount) {
                          !paymentObj.isRecurring
                            ? setMode("consider")
                            : setMode("donate");
                        }
                      }}
                      sx={{
                        backgroundColor: paymentObj.amount
                          ? "#FFAD08"
                          : "#706e6e",
                        color: paymentObj.amount ? "#000" : "#000",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid transparent",
                        "&:hover": {
                          border: paymentObj.amount
                            ? "2px solid #FFAD08"
                            : "2px solid transparent",
                          backgroundColor: paymentObj.amount
                            ? "transparent"
                            : "#706e6e",
                          cursor: paymentObj.amount ? "pointer" : "not-allowed",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "16px", sm: "16px", xs: "13px" },
                          fontWeight: "700",
                          textTransform: "capitalize",
                          fontFamily: "Stolzl",
                        }}
                      >
                        Proceed to Checkout
                        <ArrowRightIcon />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ) : mode === "consider" ? (
                <Grid item>
                  <Button
                    disableRipple
                    onClick={() => setMode("secure")}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "2px solid #000",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& img": {
                        transform: "scale(1.1)",
                      },
                    }}
                  >
                    <img src={backBlack} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#000",
                        textTransform: "capitalize",
                        fontFamily: "Stolzl",
                        fontWeight: "600",
                      }}
                    >
                      Become a monthly supporter
                    </Typography>
                  </Button>
                  <Grid mt={9}>
                    <Typography sx={{ fontFamily: "Stolzl" }}>
                      Will you consider becoming one of our valued monthly
                      suporters by converting your{" "}
                      {getSymbolFromCurrency(paymentObj?.currency)}
                      {paymentObj.amount} contribution into monthly donation?
                    </Typography>
                  </Grid>
                  <Grid my={5}>
                    <Grid my={1} sx={{ position: "relative" }}>
                      <Button
                        onClick={() => {
                          handleInputChange(
                            "amount",
                            Number(paymentObj.amount) * 2
                          );
                          handleInputChange("frequency", "monthly");
                          handleInputChange("isRecurring", true);
                          setMode("donate");
                        }}
                        sx={{
                          backgroundColor: "#E70000",
                          color: "#fff",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "10px ",
                          border: "2px solid transparent",
                          zIndex: "10",
                          height: "50px",
                          "&:hover": {
                            border: "2px solid #4f1616",
                            fontSize: "18px",
                            backgroundColor: "#4f1616",
                            "& img": {
                              transform: "scale(1.1)",
                            },
                          },
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            position: "absolute",
                            height: "fit-content",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={heart}
                            alt="Heart"
                            style={{
                              position: "absolute",
                              bottom: "-5px",
                              left: "-5%",
                              transform: "rotate(330deg) scale(1.2)",
                            }}
                          />

                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                fontFamily: "Stolzl",
                              }}
                            >
                              Donate{" "}
                              {getSymbolFromCurrency(paymentObj?.currency)}
                              {paymentObj.amount * 2}/month
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid my={3}>
                      <Button
                        onClick={() => {
                          handleInputChange("amount", paymentObj.amount);
                          handleInputChange("frequency", "monthly");
                          handleInputChange("isRecurring", true);
                          setMode("donate");
                        }}
                        sx={{
                          backgroundColor: "#fa690f",
                          color: "#fff",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "10px ",
                          border: "2px solid transparent",
                          zIndex: "10",
                          //height:"20%",
                          "&:hover": {
                            border: "2px solid #8a3601",
                            backgroundColor: "#8a3601",
                          },
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Donate {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount}/month
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid mt={7}>
                      <Button
                        onClick={() =>
                          setMode("donate")
                        }
                        sx={{
                          backgroundColor: "#FFAD08",
                          color: "#fff",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "10px ",
                          border: "2px solid #000",
                          zIndex: "10",
                          "&:hover": {
                            border: "2px solid #000",
                            backgroundColor: "#fcc85d",
                            color: "#A4B200",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            textTransform: "capitalize",
                            color: "#000",
                            fontFamily: "Stolzl",
                          }}
                        >
                          Keep my one-time{" "}
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount} gift
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : mode === "pay" ? (
                <>
                  <div id="payment-request-button"></div>
                  <h1>Pay by Card</h1>
                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="First Name"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      value={paymentObj.firstName}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                    />
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      value={paymentObj.lastName}
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Name on Card"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      value={paymentObj.cardTitle}
                      onChange={(e) =>
                        handleInputChange("cardTitle", e.target.value)
                      }
                    />
                    <TextField
                      label="Card Number"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardNumberElement,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Card Expiry Date"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardExpiryElement,
                      }}
                    />
                    <TextField
                      label="CVC"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardCvcElement,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Email"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      value={paymentObj.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Phone"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #0D0D0D",
                        "& .MuiOutlinedInput-input": {
                          color: "#030303",
                          "&::placeholder": {
                            color: "#0D0D0D",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#0D0D0D",
                        },
                      }}
                      value={paymentObj.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                    />
                  </FormControl>

                  <Grid mt={3}>
                    <Button
                      onClick={embed ? setMode("thanks") : handleSubmit}
                      disabled={isPaymentLoading}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#0D0D0D",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #0D0D0D",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                          color: "#A4B200",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        {isPaymentLoading ? "Loading..." : "Donate Now"}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              ) : mode === "donate" ? (
                <Grid item>
                  <Button
                    onClick={() =>
                      !paymentObj.isRecurring
                        ? setMode("consider")
                        : setMode("secure")
                    }
                    disableRipple
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "2px solid #000",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        "& img": {
                          transform: "scale(1.1)",
                        },
                      },
                    }}
                  >
                    <img src={backBlack} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#000",
                        textTransform: "capitalize",
                        fontFamily: "Stolzl",
                        fontWeight: "600",
                      }}
                    >
                      Payment Information
                    </Typography>
                  </Button>

                  <Grid my={5} item md={12}>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Donation
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        my={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Processing Fee
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {processCost}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Currency
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            {paymentObj?.currency}
                          </Typography>
                        </Grid>
                      </Grid>
                      {paymentObj.isRecurring && (
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 2,
                          }}
                        >
                          <Grid>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                fontFamily: "Stolzl",
                              }}
                            >
                              Recurring
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                fontFamily: "Stolzl",
                              }}
                            >
                              {capitalizeFirstLetter(paymentObj?.frequency)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Total
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {Number(paymentObj?.amount) + processCost}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid my={3}>
                      <Button
                        onClick={() => setMode("pay")}
                        sx={{
                          backgroundColor: "#FFAD08",
                          color: "#000",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "10px ",
                          border: "2px solid transparent",
                          zIndex: "10",
                          "&:hover": {
                            border: "2px solid #FFAD08",
                            backgroundColor: "#f5c25b",
                          },
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Proceed
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={StripeLogo}
                        alt="stripe-logo"
                        style={{ width: "100%", height: "50px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : mode === "thanks" ? (
                <Grid item>
                  <Grid
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Button
                      disableRipple
                      onClick={() => setMode("secure")}
                      py={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        borderBottom: "1px solid #FFF",
                        borderRadius: "0px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        "& img": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <img src={back} alt="" />
                      <Typography
                        mx={3}
                        sx={{
                          textWrap: "nowrap",
                          color: "#FFF",
                          textTransform: "capitalize",
                        }}
                      >
                        Thank you
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid my={5} item md={12}>
                    <Typography
                      sx={{
                        fontSize: "28px",
                        fontWeight: "700",
                        color: "#ADFF00",
                      }}
                    >
                      Thank you for the donation of ${" "}
                      {Number(paymentObj?.amount) + processCost}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      backgroundColor: "#689900",
                      width: "100%",
                      zIndex: "22",
                      borderRadius: "15px"
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        textWrap: "wrap",
                        padding: "20px 5px",
                      }}
                    >
                      Share now to help us spread this cause
                    </Typography>
                  </Grid>
                  <Grid my={4}>
                    <Button
                      onClick={() => handleShare("facebook")}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img src={fb} alt="" style={{ marginRighr: "15px" }} />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Facebook
                        </Typography>
                      </Grid>
                    </Button>

                    <Button
                      onClick={() => handleShare("whatsapp")}
                      sx={{
                        my: 2,
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img src={wa} alt="" style={{ marginRighr: "15px" }} />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Watsapp
                        </Typography>
                      </Grid>
                    </Button>

                    <Button
                      onClick={() => handleShare("email")}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          src={mail}
                          alt=""
                          style={{ marginRighr: "15px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Email
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={6.5}
            sx={{
              backgroundImage: `url('${newImage}')`,
              backgroundSize: "cover",
              backgroundPosition: "top left",
              width: "100%",
              height: "75vh",
              borderRadius: "10px",
            }}
          ></Grid>
        </Grid>
      ) : currentTemplate === "5" ? (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column-reverse" },
            alignItems: "center",
            justifyContent: { md: "space-between", sm: "center" },
            height: embed ? "fit-content" : "100vh",
            minWidth: "100%",
            borderRadius: "15px",
            backgroundImage: `url('${newImage}')`,
            backgroundSize: "cover",
            backgroundPosition: { md: "center center", sm: "top center" },
          }}
        >
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            sx={{
              width: "100%",
              height: embed ? "85vh" : "100vh",
              padding: { md: "15px 25px", sm: "15px", xs: "10px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: "15px",
              alignItems: { md: "flex-start", sm: "center", xs: "center" },
            }}
          >
            <Grid
              item
              md={6.5}
              sm={12}
              xs={12}
              mb={3}
              mt={{ md: 0, sm: 0, xs: 3 }}
              sx={{
                display: "flex",
                justifyContent: {
                  md: "flex-start",
                  sm: "center",
                  xs: "center",
                },
                height: "fit-content",
                padding: "0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "22px", sm: "20px", xs: "15px" },
                  fontWeight: "600",
                  color: "#FA9A0F",
                  fontFamily: "Stolzl",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  textWrap: "nowrap",
                  alignItems: "center",
                  width: "100%",
                  textAlign: "center",
                  height: "fit-content",
                }}
              >
                <img
                  src={sheildYellow}
                  alt=""
                  style={{ marginRight: "10px" }}
                />
                Secure Donation
              </Typography>
            </Grid>

            <Grid
              item
              md={embed ? 6.5 : 6.5}
              sm={9}
              xs={12}
              mx={0}
              px={{ md: 6, sm: 6, xs: 2 }}
              py={3}
              my={{ md: 0, sm: 4, xs: 4 }}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "center",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                //background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
                backdropFilter: "blur(50px)",
                //background: "#481B1599",
                background: "#FA9A0F",
                boxShadow:
                  themeMode === "dark"
                    ? "0px 0px 0px red"
                    : "0px 8px 5px 0px #00000040",
                color: "#FFFF",
                borderRadius: "15px",
                overflow: "visible",
                height: "fit-content",
              }}
            >
              {mode === "secure" ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: { md: "column", sm: "column", xs: "column" },
                  }}
                >
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      sx={{
                        fontSize: { md: "12px", sm: "10px", xs: "15px" },
                        fontWeight: "500",
                        fontFamily: "Stolzl",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Choose Donation Frequency
                    </Typography>
                  </Grid>

                  <Grid>
                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        overflowX: { md: "hidden", sm: "hidden", xs: "scroll" },
                      }}
                    >
                      {frequencyDisplayed?.map((item, index) => (
                        <Button
                          key={index}
                          disableElevation
                          disableRipple
                          sx={{
                            mt: 1,
                            width: "20%",
                            border:
                              paymentObj.frequency === item.name.toLowerCase()
                                ? "2px solid #EBFF00"
                                : "1px solid #FFFF",
                            borderRadius: "0px",
                            padding: "8px 10px",

                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          value={item.name.toLowerCase()}
                          onClick={() => {
                            handleInputChange(
                              "frequency",
                              item.name.toLowerCase()
                            );
                            item.name.toLowerCase() === "once"
                              ? handleInputChange("isRecurring", false)
                              : handleInputChange("isRecurring", true);
                          }}
                        >
                          <Typography
                            sx={{
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                              textTransform: "capitalize",
                              fontSize: embed ? "10px" : "9px",
                              fontWeight: "400",
                              textWrap: "nowrap",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Button>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid
                    mt={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      sx={{
                        fontSize: { md: "12px", sm: "10px", xs: "15px" },
                        fontWeight: "500",
                        fontFamily: "Stolzl",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Choose the donation amount
                    </Typography>
                  </Grid>

                  <Grid>
                    <FormControl sx={{ width: "100%", fontSize: "14px" }}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedValue}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="newCampaign"
                          sx={{
                            textAlign: "left",
                            border:
                              selectedValue === "a"
                                ? "2px solid #EBFF00"
                                : "1px solid #FFFF",
                            padding: "4px",
                            borderRadius: "5px",
                            width: "100%",
                            ml: 0.5,
                          }}
                          control={
                            <Radio
                              {...controlProps("a")}
                              sx={{
                                color: "#FFFF",
                                "&.Mui-checked": {
                                  color: "#EBFF00",
                                },
                              }}
                            />
                          }
                          label="Rs 1000"
                        />
                        <FormControlLabel
                          value="clone"
                          control={
                            <Radio
                              {...controlProps("b")}
                              sx={{
                                color: "#FFFF",
                                "&.Mui-checked": {
                                  color: "#EBFF00",
                                },
                              }}
                            />
                          }
                          label="Rs. 500"
                          sx={{
                            mt: { md: 1, sm: 2, xs: 4 },
                            textAlign: "left",
                            textAlign: "left",
                            border:
                              selectedValue === "b"
                                ? "2px solid #EBFF00"
                                : "1px solid #FFFF",
                            padding: "4px",
                            borderRadius: "5px",
                            width: "100%",
                            ml: 0.5,
                          }}
                        />
                        <FormControlLabel
                          value="clone"
                          control={
                            <Radio
                              {...controlProps("c")}
                              sx={{
                                color: "#FFFF",
                                "&.Mui-checked": {
                                  color: "#EBFF00",
                                },
                              }}
                            />
                          }
                          label="Rs. 200"
                          sx={{
                            mt: { md: 1, sm: 2, xs: 4 },
                            textAlign: "left",
                            border:
                              selectedValue === "c"
                                ? "2px solid #EBFF00"
                                : "1px solid #FFFF",
                            padding: "4px",
                            borderRadius: "5px",
                            width: "100%",
                            ml: 0.5,
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    mt={3}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      disableRipple
                      onClick={() => {
                        if (paymentObj.amount) {
                          !paymentObj.isRecurring
                            ? setMode("consider")
                            : setMode("donate");
                        }
                      }}
                      sx={{
                        backgroundColor: paymentObj.amount ? "#FFF" : "#706e6e",
                        color: paymentObj.amount ? "#FA9A0F" : "#000",
                        width: "50%",
                        borderRadius: "30px",
                        padding: "8px 25px",
                        border: "2px solid transparent",
                        "&:hover": {
                          border: paymentObj.amount
                            ? "2px solid #EBFF00"
                            : "2px solid transparent",
                          backgroundColor: paymentObj.amount
                            ? "#EBFF00"
                            : "transparent",
                          color: "#FFF",
                          cursor: paymentObj.amount ? "pointer" : "not-allowed",
                          textShadow: "1px 1px 10px #000",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          textWrap: "nowrap",
                          fontFamily: "Stolzl",
                        }}
                      >
                        Checkout
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ) : mode === "consider" ? (
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    disableRipple
                    onClick={() => setMode("secure")}
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderRadius: "0px",
                      borderBottom: "1px solid #FFF",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "& img": {
                        transform: {
                          md: "scale(1.1)",
                          sm: "scale(1.1)",
                          xs: "scale(0.9)",
                        },
                      },
                    }}
                  >
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#FFF",
                        textTransform: "capitalize",
                        fontSize: { md: "16px", sm: "16px", xs: "12px" },
                        display: "flex",
                        flexWrap: "nowrap",
                        textWrap: "nowrap",
                      }}
                    >
                      <img src={back} alt="" style={{ marginRight: "10px" }} />
                      Become a monthly supporter
                    </Typography>
                  </Button>
                  <Grid mt={3}>
                    <Typography>
                      Will you consider becoming one of our valued monthly
                      suporters by converting your{" "}
                      {getSymbolFromCurrency(paymentObj?.currency)}
                      {paymentObj.amount} contribution into monthly donation?
                    </Typography>
                  </Grid>
                  <Grid my={5}>
                    <Grid my={1} sx={{ position: "relative" }}>
                      <Button
                        onClick={() => {
                          handleInputChange(
                            "amount",
                            Number(paymentObj.amount) * 2
                          );
                          handleInputChange("frequency", "monthly");
                          handleInputChange("isRecurring", true);
                          setMode("donate");
                        }}
                        sx={{
                          backgroundColor: "#E70000",
                          color: "#fff",
                          width: "100%",
                          borderRadius: "30px",
                          padding: "10px ",
                          border: "2px solid transparent",
                          zIndex: "10",
                          height: "50px",
                          "&:hover": {
                            border: "2px solid #4f1616",
                            fontSize: "18px",
                            backgroundColor: "#4f1616",
                            "& img": {
                              transform: "scale(1.1)",
                            },
                          },
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            position: "absolute",
                            height: "fit-content",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={heart}
                            alt="Heart"
                            style={{
                              position: "absolute",
                              bottom: "-3px",
                              left: "0%",
                            }}
                          />

                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              Donate{" "}
                              {getSymbolFromCurrency(paymentObj?.currency)}
                              {paymentObj.amount * 2}/month
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid my={3}>
                      <Button
                        onClick={() => {
                          handleInputChange("amount", paymentObj.amount);
                          handleInputChange("frequency", "monthly");
                          handleInputChange("isRecurring", true);
                          setMode("donate");
                        }}
                        sx={{
                          backgroundColor: "#7EBA00",
                          color: "#fff",
                          width: "100%",
                          borderRadius: "30px",
                          padding: "10px ",
                          border: "2px solid transparent",
                          zIndex: "10",
                          //height:"20%",
                          "&:hover": {
                            border: "2px solid #A4B200",
                            backgroundColor: "#bef54c",
                            color: "#A4B200",
                          },
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            Donate {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount}/month
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid mt={3}>
                      <Button
                        onClick={() =>
                          setMode("donate")
                        }
                        sx={{
                          backgroundColor: "#FFF",
                          color: "#FA9A0F",
                          width: "100%",
                          borderRadius: "30px",
                          padding: "10px ",
                          border: "2px solid #FFFFFF",
                          zIndex: "10",
                          "&:hover": {
                            border: "2px solid #EBFF00",
                            backgroundColor: "transparent",
                            color: "#FFF",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Keep my one-time{" "}
                          {getSymbolFromCurrency(paymentObj?.currency)}
                          {paymentObj.amount} gift
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : mode === "pay" ? (
                <>
                  <div id="payment-request-button"></div>
                  <h1>Pay by Card</h1>
                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="First Name"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      value={paymentObj.firstName}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                    />
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      value={paymentObj.lastName}
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Name on Card"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      value={paymentObj.cardTitle}
                      onChange={(e) =>
                        handleInputChange("cardTitle", e.target.value)
                      }
                    />
                    <TextField
                      label="Card Number"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardNumberLightElement,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Card Expiry Date"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardExpiryLightElement,
                      }}
                    />
                    <TextField
                      label="CVC"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      InputProps={{
                        inputComponent: StripeCardCvcLightElement,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "65%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Email"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      value={paymentObj.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Phone"
                      className={classes.input2}
                      sx={{
                        //my: { md: 2, sm: 4, xs: 2 },
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                        backgroundColor: "transparent",
                        border: "1px solid #FFFF",
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": {
                            color: "#FFFF",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#FFFF",
                        },
                      }}
                      value={paymentObj.phone}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                    />
                  </FormControl>

                  <Grid mt={3}>
                    <Button
                      onClick={embed ? setMode("thanks") : handleSubmit}
                      disabled={isPaymentLoading}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #FFFFFF",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #FA9A0F",
                          backgroundColor: "#FFF",
                          color: "#FA9A0F",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        {isPaymentLoading ? "Loading..." : "Donate Now"}
                      </Typography>
                    </Button>
                  </Grid>
                </>
              ) : mode === "donate" ? (
                <Grid item>
                  <Button
                    onClick={() =>
                      !paymentObj.isRecurring
                        ? setMode("consider")
                        : setMode("secure")
                    }
                    disableRipple
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      borderBottom: "1px solid #FFF",
                      "&:hover": {
                        backgroundColor: "transparent",
                        "& img": {
                          transform: "scale(1.1)",
                        },
                      },
                    }}
                  >
                    <img src={back} alt="" />
                    <Typography
                      mx={3}
                      sx={{
                        textWrap: "nowrap",
                        color: "#FFF",
                        textTransform: "capitalize",
                      }}
                    >
                      Payment Information
                    </Typography>
                  </Button>

                  <Grid my={5} item md={12}>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography>Donation</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {paymentObj.amount}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        my={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography>Processing Fee</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {processCost}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 2,
                        }}
                      >
                        <Grid>
                          <Typography>Currency</Typography>
                        </Grid>
                        <Grid>
                          <Typography>{paymentObj?.currency}</Typography>
                        </Grid>
                      </Grid>
                      {paymentObj.isRecurring && (
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: 2,
                          }}
                        >
                          <Grid>
                            <Typography>Recurring</Typography>
                          </Grid>
                          <Grid>
                            <Typography>
                              {capitalizeFirstLetter(paymentObj?.frequency)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid>
                          <Typography>Total</Typography>
                        </Grid>
                        <Grid>
                          <Typography>
                            {getSymbolFromCurrency(paymentObj?.currency)}
                            {Number(paymentObj?.amount) + processCost}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid my={3}>
                      <Button
                        onClick={() => setMode("pay")}
                        sx={{
                          backgroundColor: "#FFFF",
                          color: "#FA9A0F",
                          width: "75%",
                          borderRadius: "30px",
                          padding: "10px 15px",
                          border: "2px solid transparent",
                          zIndex: "10",
                          "&:hover": {
                            border: "2px solid #EBFF00",
                            backgroundColor: "transparent",
                            color: "#FFF",
                          },
                        }}
                      >
                        <Grid>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              fontFamily: "Stolzl",
                            }}
                          >
                            Proceed
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={StripeLogo}
                        alt="stripe-logo"
                        style={{ width: "100%", height: "50px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : mode === "thanks" ? (
                <Grid item>
                  <Grid
                    py={1}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Button
                      disableRipple
                      onClick={() => setMode("secure")}
                      py={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        borderBottom: "1px solid #FFF",
                        borderRadius: "0px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        "& img": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <img src={back} alt="" />
                      <Typography
                        mx={3}
                        sx={{
                          textWrap: "nowrap",
                          color: "#FFF",
                          textTransform: "capitalize",
                        }}
                      >
                        Thank you
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid my={5} item md={12}>
                    <Typography
                      sx={{
                        fontSize: "28px",
                        fontWeight: "700",
                        color: "#ADFF00",
                      }}
                    >
                      Thank you for the donation of ${" "}
                      {Number(paymentObj?.amount) + processCost}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      backgroundColor: "#689900",
                      width: "100%",
                      zIndex: "22",
                                      borderRadius: "15px"
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        textWrap: "wrap",
                        padding: "20px 5px",
                      }}
                    >
                      Share now to help us spread this cause
                    </Typography>
                  </Grid>
                  <Grid my={4}>
                    <Button
                      onClick={() => handleShare("facebook")}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img src={fb} alt="" style={{ marginRighr: "15px" }} />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Facebook
                        </Typography>
                      </Grid>
                    </Button>

                    <Button
                      onClick={() => handleShare("whatsapp")}
                      sx={{
                        my: 2,
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img src={wa} alt="" style={{ marginRighr: "15px" }} />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Watsapp
                        </Typography>
                      </Grid>
                    </Button>

                    <Button
                      onClick={() => handleShare("email")}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        width: "100%",
                        borderRadius: "10px",
                        padding: "10px ",
                        border: "2px solid #689900",
                        zIndex: "10",
                        "&:hover": {
                          border: "2px solid #A4B200",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          width: "80%",
                          justifyContent: "space-around",
                        }}
                      >
                        <img
                          src={mail}
                          alt=""
                          style={{ marginRighr: "15px" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Share on Email
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: "#FA9A0F",
              height: "100vh",
              padding: "15px 20px 15px 25px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              borderRadius: {
                md: "0px 15px 15px 0px",
                sm: "0px 0px 15px 15px",
                xs: "15px 15px 0px 0px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "42px", sm: "36px", xs: "30px" },
                fontWeight: "600",
                textAlign: { md: "right", sm: "center", xs: "center" },
                width: "100%",
              }}
            >
              {formTitle
                ? formTitle
                : askPage?.title || (
                    <p>
                      Transform <br />{" "}
                      <b
                        style={{
                          fontSize: { md: "50px", sm: "40px", xs: "35px" },
                          fontWeight: "900",
                          fontStyle: "italic",
                        }}
                      >
                        Lives
                      </b>
                    </p>
                  )}
            </Typography>

            <Typography
              sx={{
                fontSize: { md: "42px", sm: "36px", xs: "30px" },
                fontWeight: "600",
                textAlign: { md: "right", sm: "center", xs: "center" },
                width: "100%",
              }}
            >
              Donate
              <br />{" "}
              <b
                style={{
                  fontSize: { md: "50px", sm: "40px", xs: "35px" },
                  fontWeight: "900",
                  fontStyle: "italic",
                }}
              >
                Now !
              </b>
            </Typography>

            <Typography
              my={4}
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                textAlign: { md: "right", sm: "center", xs: "center" },
                width: { md: "70%", sm: "100%", xs: "100%" },
              }}
            >
              {formDesc
                ? formDesc
                : askPage?.message ||
                  "Support our cause and make a difference in the lives of those in need by contributing through our secure donation form"}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};
export default DonationCheckout;
