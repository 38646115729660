import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import visitorLight from '../../../../../../assets/FeatureLightTheme/ElementManagement/visitorLight.svg'
import panelLight from '../../../../../../assets/FeatureLightTheme/ElementManagement/panelLight.svg'

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const element1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element1.svg";
const element2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element2.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_element3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_element3.svg";
const light_element4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_element4.svg";

const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E4E5F1" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize: themeMode === "dark" ? "140% auto" : "140% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "left 0%,right bottom",
            padding: { md: "100px 130px", sm: "90px 40px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "-10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(145deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                justifyContent: "start",
              }}
            >
              <Grid item md={9} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "38px", sm: "35px", xs: "30px" },
                    color: themeMode === "dark" ? "#fff" : "#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  Convert website visitors to{" "}
                  <span className={themeMode === "dark" ? classes.color : classes.colorLight}
                    sx={{ fontWeight: "700", fontFamily: "Stolzl" }}>
                    donors</span> with Elements
                </Typography>
                <Grid item md={10.5}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      fontFamily: "Stolzl"
                    }}
                  >
                    Convert website visitors to donors with Elements.<br /> Elements
                    are UI components specially designed for digital
                    fundraising. Use any combination of Elements on any page of
                    your website to create interactive experiences that engage
                    visitors and convert them to donors.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                mt={{md:0,sm:5,xs:5}}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  //top: { md: "20%", sm: "0%", xs: "0%" },
                   transform: { md: "translateY(-10%) translateX(20%) scale(1.2)", sm: "translateY(0%)" },
                   
                }}
              >
                <img src={themeMode==="dark"?element1:visitorLight} alt="" />
              </Grid>
            </Grid>
          </Grid>




          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            mt={{ md: 25, sm: 20, xs: 20 }}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "10px -13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(125deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column-reverse" },
                justifyContent: "start",
              }}
            >
              <Grid
                item
                md={4}
                mt={{ md: 0, sm: 5, xs: 5 }}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  //top: { md: "20%", sm: "0%", xs: "0%" },
                  transform: { md: "translateX(-15%) scale(1.2)", sm: "translateX(0%)" },
                }}
              >
                <img src={themeMode==="dark"?element2:panelLight} alt="" />
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    fontSize: { md: "38px", sm: "35px", xs: "30px" },
                    color: themeMode === "dark" ? "#fff" : "#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  Build with{" "}
                  <span className={themeMode === "dark" ? classes.color : classes.colorLight}>20+ customizable</span> UI
                  components
                </Typography>
                <Grid item md={12}
                  sx={{display:"flex",justifyContent:"flex-end"}}
                >
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "right", sm: "center", xs: "center" },
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },fontFamily:"Stolzl"
                    }}
                  >
                    Elements gives you access to a library of
                    fundraising-specific UI components that you create and
                    manage directly within your Fundraise Up account. It’s easy
                    to add Elements to your website and you can make real-time
                    changes without touching a line of code.
                  </Typography>
                </Grid>
              </Grid>
            
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  
};
export default SubSection;
