import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'

const DonateButtonElement = ({ borderRadius, borderSize, buttonSize, labelColor, shadow, btnLabel, backgroundColor, iconColor, borderColor, themeMode }) => {
  return (
    <>
       {/* <Button
        sx={{
          //backgroundColor:`${buttonColor}`,
          //borderColor:{borderColor},
          //borderColor: 'red',
          //borderWidth: { borderSize },
          textTransform: 'capitalize',
          borderRadius: `${borderRadius}px`,
          backgroundColor: "#7CB602",
          padding: '8px 15px',
          boxShadow: shadow === true ? "2px 2px 5px #FFF" : "",
          border: `${borderSize}px solid #FFF`,
          "&:hover": {
            backgroundColor: "#7CB602"
          }
        }}
      >
        <Typography
          sx={{
            color: { labelColor },
            fontSize: `${buttonSize}px`,
            color: "#FFFF",
            display: "flex", flexDirection:"row",
          }}>
          <span style={{
            display: "flex", flexDirection: "row", width: "fit-content",
            alignItems: "center", margin: "0px 5px 0px"
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={`${buttonSize}px`} height={`${buttonSize}px`} fill="red">
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
          </span>

          {btnLabel}
        </Typography>
      </Button>  */}


      
      <button 
        style={{
          borderRadius: `${borderRadius}px`,
          border: `${borderSize}px solid ${borderColor}`,
          backgroundColor: backgroundColor ? backgroundColor : '#7CB602',
          boxShadow: themeMode==="dark" && shadow ? '2px 2px 5px #FFF' :
                     themeMode === "light" && shadow ? '2px 2px 5px #000'
                     :'none',
          display:"flex",
          alignItems:"center",
          //height:"55px",
          height:"fit-content",
          textTransform: 'capitalize',
          padding: '0px 15px',
          "&:hover": {
            filter:'brightness(50%)'
        }
      }} id='proceed-btn'>
        <p style={{
          color: labelColor,
          fontSize: `${buttonSize}px`,
          display: 'flex',
          flexDirection: 'row'
        }}>
          <span style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            alignItems: 'center',
            margin: '0px 5px 0px'
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" 
                 viewBox="0 0 24 24" 
                 width={`${buttonSize}px`} 
                 height={`${buttonSize}px`} 
                 fill={iconColor}
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>
          </span>

          {btnLabel ? btnLabel : 'Donate'}
        </p>
        </button>
      
    </>
  )
}
export default DonateButtonElement
