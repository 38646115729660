import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { steps } from "../../data";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const cross = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg";
const crossBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/crossBlack.svg";

const CampaignPop = ({ onClose }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        container
        className={classes.blurBkg}
        sx={{
          padding: { md: "7% 15%", sm: "7% 5%", xs: "7% 10%" },
          justifyContent: { md: "center", sm: "flex-start" },
          alignItems: { md: "center", sm: "flex-start" },
        }}
      >
        <Grid className={classes.closeButtonHolder}>
          <Button onClick={handleClose} className={classes.closeButton}>
            <img src={themeMode=="dark"?cross:crossBlack} alt="" />
          </Button>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className={classes.holder}>
          <Typography
            variant="inherit"
            sx={{ fontSize: { md: "32px", sm: "30px", xs: "26px" },color:themeMode==="dark"? "#FFFF":"#030303", }}
          >
            3 Easy Steps to{" "}
            <span style={{}} className={classes.neon}>
              Raise Donation
            </span>
          </Typography>
          <Grid
            item
            className={classes.mapped}
            sx={{
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            {steps?.map((item, index) => (
              <Grid
                container
                key={index}
                mt={{ md: 0, sm: 3, xs: 2 }}
                className={classes.mapItem}
                sx={{
                  flexDirection: { md: "column", sm: "column", xs: "row" },
                }}
              >
                <Grid
                  className={classes.imgHolder}
                  sx={{
                    maxWidth: { md: "300px", sm: "200px", xs: "120px" },
                  }}
                >
                  <img src={item.img} alt="" style={{ width: "100%" }} />
                </Grid>
                <Grid
                  item
                  className={classes.imgHolder}
                  sx={{
                    padding: {
                      md: "0px 10%",
                      sm: "0% 5% 0% 5% ",
                      xs: "0px 0%",
                    },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { md: "18px", sm: "16px", xs: "12px" },color:themeMode==="dark"? "#FFFF":"#030303", }}
                  >
                    <span sx={{ fontWeight: "900" }}>{item.id}#</span>{" "}
                    {item.text}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default CampaignPop;
