import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/bkgLight.svg'
import hero from '../../../../../../assets/FeatureLightTheme/SupporterManagement/heroSupp.svg'

const supportermgmt = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/supportermgmt.svg";
const element4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element4.svg";
const light_supporter1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_supporter1.svg";
const light_supporter2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_supporter2.svg";
const light_camp2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_camp2.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";

const HeroSection = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 120px 10px",
            sm: "130px 60px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark"
              ? `url('${bkg}')`
              : `url('${bkgLight}')`,
        }}
      >
        <div className="md:flex w-full">
          
          <Grid
            className={classes.columnCenter}
            item
            md={7}
            sm={10}
            xs={12}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" }}}
          >
            <Typography className={"sub-feature-title"}
              sx={{
                textAlign: {md:"left",sm:"left",xs:"center"},
              }}
            >
              <span className={themeMode === "dark" ? "text-white" : "text-black"}>
                Donor Portal{" "}
              </span>
            </Typography>
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "46px", sm: "36px", xs: "32px" },
                textAlign: {md:"left",sm:"left",xs:"center"},
                fontFamily:"Stolzl" 
              }}
            >
              <span className={themeMode === "dark" ? "text-white" : "text-black"}>
                {" "}
                Let donors{" "}
              </span>
              <span className={themeMode === "dark" ? classes.color : classes.colorLight} sx={{ fontWeight: "700", fontFamily: "Stolzl" }}>
                {" "}
                manage{" "}
              </span>{" "}
              <span
                style={{ fontFamily: "Stolzl" }}
                className={themeMode === "dark" ? "text-white" : "text-black"}
              >
                {" "}
                their online giving{" "}
              </span>
            </Typography>
            
            <Grid sx={{width:{md:"80%",sm:"80%",xs:"100%"},display:"flex",flexWrap:"wrap",justifyContent:{md:"flex-start",sm:"flex-start",xs:"center"}}}>
              
              <Button
                variant="outlined"
                sx={{
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "11px" },
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4 md:mr-4"
              >
                Maintain Donor Trust
              </Button>
              <Button
                variant="outlined"
                sx={{
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "11px" },
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4 md:mr-4"
              >
                Increase Recurring Donations{" "}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "11px" },
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4"
              >
                Reduce Donor Support Hours{" "}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "11px" },
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4"
              >
                Engage Donors with P2P Fundraising{" "}
              </Button>
            </Grid>
          </Grid>
          <Grid
            // my={3}
            item
            //className={classes.jCenter}
            md={7}
            sm={10}
            xs={12}
            sx={{
              display:"flex",justifyContent:"flex-end",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              width: { md: "40%", sm: "auto", xs: "auto" },
            }}
          >
            <Grid
              item
              //className={classes.textCenter}
              md={10}
              sx={{ minHeight: "20rem", maxHeight:"70rem", textAlign: { md: "flex-end" }, display: "flex", justifyContent: "flex-end", }}
            >
              
                <img
                  src={themeMode === "dark" ? supportermgmt : hero}
                  alt=""
                />
              
            </Grid>
            
          </Grid>
        </div>
      </Grid>
    </>
  );
};
export default HeroSection;
