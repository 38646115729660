import { createSlice } from '@reduxjs/toolkit';

const storedTheme = localStorage.getItem('theme');

const themeModeSlice = createSlice({
  name: 'themeMode',
  initialState: {mode: storedTheme},
  reducers: {
    setThemeMode: (state, action) => {
        state.mode = action.payload;
    },
  },
});

export const { setThemeMode } = themeModeSlice.actions;
export default themeModeSlice.reducer;