import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  body: {
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  bodyFtr: {
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "brightness(180%)",
  },
  fs40: {
    fontSize: "40px",
    fontWeight: "700",
  },
  button: {
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px 15px",
    border: "0.5px solid transparent",
    
  },
  btnFtr: {
    backgroundColor: "#018C94",
    color: "#000",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px 15px",
    border: "0.5px solid transparent",
    "&:hover": {
      background: "#01131a",
      color: "#FFF",
      border: "0.5px solid #018C94",
      fontWeight: "500",
    },
  },
  btnSignup:{
    color: "#FFF",
              background:
                "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "600",
              padding: "5px 15px",
              border: "0.5px solid transparent",
              "&:hover": {
                background: "#1a0101",
                border: "0.5px solid #689900",
                fontWeight: "500",
              },
  },
  flex: {
    display: "flex",
  },
  link: {
    textDecoration: "none",
    listStyleType: "none",
    color: "#fff",
    lineHeight: "2",
    fontSize: "16px",
  },
  head: {
    textDecoration: "none",
    color: "#fff",
    lineHeight: "2.75",
    fontSize: "16px",
    fontWeight: "800",
    listStyleType: "none",
    marginRight: "30px",
  },
  list: {
    listStyleType: "none",
  },
  row: {
    padding: "15px 30px",
    textAlign: "right",
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    height: "30px",
    width: "2px",
    orientation: "vertical",
    mx: 2,
  },
  fitRow: {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
  },
  my15: {
    margin: "0px 10px",
  },
});
