import React, { useState, useEffect } from "react";
import { LinearProgress, Typography } from "@mui/material";
import getSymbolFromCurrency from 'currency-symbol-map';

const GoalMeter = ({ collected, remaining, themeMode, labelColor, progressBarColor, backgroundColor, borderSize, borderRadius, goalCurrency }) => {
  const total = collected + remaining;
  const progress= collected/(total)*100;


  return (
    <div
      style={{
        // width: "100%",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "flex-end",
        width: "200px",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "3px",
        }}
      >
        {remaining > 0 ?
          <p
          style={{
            textAlign:"left",
            fontSize:"20px",
          }}>
            <span style={{fontWeight:"800",color: labelColor ? labelColor : '#FFFFE0'}}> {getSymbolFromCurrency(goalCurrency || 'USD')}{collected || 0}</span><span style={{color: labelColor ? labelColor : '#FFFFE0'}}> of {getSymbolFromCurrency(goalCurrency || 'USD')}{total || 0} goal</span>
          </p>
        : 
          <p variant="body2" color="#FFFFFF80" sx={{textAlign:"right"}}>
           No Remaining
          </p>
        }
      </div>
      {/* <LinearProgress
        variant="determinate"
        value={collected <= total ? progress : 100}
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          borderRadius: "15px",
          height: "20px",
          border:"1px solid #689900",     
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#689900",
            borderRadius:"15px",
            margin:"0px 0px"
          },
        }}
      /> */}
      <div style={{ width: '100%', height: '20px', borderRadius: borderRadius ? borderRadius : '15px', backgroundColor: '#e0e0e0', overflow: 'hidden', border: `${borderSize}px solid #689900` }}>
          <div id="progressBarFill" style={{ height: '100%', backgroundColor: progressBarColor ? progressBarColor : '#689900', borderRadius: '15px', width: collected <= total ? `${progress}%` : '100%' }}></div>
      </div>
    </div>
  );
};

export default GoalMeter;
