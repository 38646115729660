const campaign = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const donations = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/Donations.png";
const elements = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/elements.png";
const p2p = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/p2p.png";
const recurring = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/recurring.png";
const supporters = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/supporters.png";
const exportss = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/exports.png";
const reports = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/reports.png";
const insights = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/insights.png";
const payment = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/payment.svg";
const light_insight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/insight.svg";
const light_campaign = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const light_donation = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/donation.svg";
const light_p2p = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/p2p.svg";
const light_recurring = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/recurring.svg";
const light_supporter = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/supporter.svg";
const light_export = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/export.svg";
const light_element = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/element.svg";
const light_report = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/report.svg";
const light_payment = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/payment.svg";


export const options = [
    {
        id: 1,
        name: "Insights",
        img: insights,
        light: light_insight,
        link: "/dashboard/insights"
    },
    {
        id: 2,
        name: "Campaigns",
        img: campaign,
        light: light_campaign,
        link: "/dashboard/campaigns",
        tabs: [
            {
                id: 0,
                name: "P2P",
                img: p2p,
                light: light_p2p,
                link: "/dashboard/p2p"
            },
        ]

    },
    {
        id: 3,
        name: "Donations",
        img: donations,
        link: "/dashboard/donations",
        light: light_donation,
        tabs: [
            {
                id: 0,
                name: "Recurring",
                img: recurring,
                light: light_recurring,
                link: "/dashboard/recurring"
            },
            {
                id: 1,
                name: "Supporters",
                img: supporters,
                light: light_supporter,
                link: "/dashboard/supporters"
            },
        ]
    },
    {
        id: 4,
        name: "Elements",
        img: elements,
        light: light_element,
        link: "/dashboard/elements"
    },
    {
        id: 5,
        name: "Payments",
        img: payment,
        light: light_payment,
        link: "/dashboard/payments"
    },
    {
        id: 6,
        name: "Reports",
        img: reports,
        light: light_report,
        link: "/dashboard/reports"
    },

]


export const notifications = [
    {
        id: 0,
        text: "Imad ud Din donated $30 for the ",
        campaign: "Save Cheetas",
        read: false
    },
    {
        id: 1,
        text: "Syed Anab donated $50 for the Save Water, ",
        campaign: "Save Planet",
        read: true
    },
    {
        id: 2,
        text: "Saad Haider donated $10 for the Save Water,",
        campaign: " Save Planet",
        read: true
    },
    {
        id: 3,
        text: "Nabeel Ahmed donated $150 for the ",
        campaign: "Save Cheetas",
        read: true
    },
    {
        id: 4,
        text: "Syed Hamza donated $200 for the ",
        campaign: "Save Trees",
        read: false
    },
    {
        id: 0,
        text: "Imad ud Din donated $30 for the ",
        campaign: "Save Cheetas",
        read: false
    },
    {
        id: 1,
        text: "Syed Anab donated $50 for the Save Water, ",
        campaign: "Save Planet",
        read: true
    },
    {
        id: 2,
        text: "Saad Haider donated $10 for the Save Water,",
        campaign: " Save Planet",
        read: true
    },
    {
        id: 3,
        text: "Nabeel Ahmed donated $150 for the ",
        campaign: "Save Cheetas",
        read: true
    },
    {
        id: 4,
        text: "Syed Hamza donated $200 for the ",
        campaign: "Save Trees",
        read: false
    },
]

