import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  fw600: {
    fontWeight: "600",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
  },
  flexStart: {
    display: "flex",
    flexWrap:"wrap"
  },
  dullButton: {
    color: "#FFF",
    background: "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
    border: "1px solid transparent",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  transButton: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    
    
  },
  noWrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
  noWrapCenter: {
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
    fontWeight: "500",
    width: "100%",
  },
  centerText: {
    textAlign: "center",
    alignItems: "center",
  },
  clrButton: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #8ED003",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 0px",
    width: "fit-content",
  },
  spaceBtwn: {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    flexDirection: "space-between",
  },
  tableContainer: {
    zIndex: "0",
    padding: "20px",
    paddingTop: "0px",
    borderRadius: "15px",
    maxHeight: "350px", 
    overflowY: "auto",
  },
  table: {
    width: "100%",
  },
  white: {
    textWrap: "nowrap",
  },
  neon: {
    color: "#8ED003",
    textWrap: "nowrap",
  },
  tableHeadText: {
    fontWeight: "700",
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  row: {
    "&:last-child td, &:last-child th": {
      borderBottom: 0,
    },
    "&:hover": {
      borderRadius: "15px",
    },
  },
  tableTextWhite: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "30px",
    
    width: "fit-content",
    textWrap: "nowrap",
  },
  paper: {
    "& .MuiPaper-root": {
      color: "#FFF",
      backgroundColor: "#222",
      border: "1px solid #689900",
    },
  },
  paperLight: {
    "& .MuiPaper-root": {
      border: "1px solid #FFF",
      borderRadius:"15px"
    },
  },
  dots:{
    "&:hover":{backgroundColor:"transparent"}
  },
  menuItem:{    
  
  fontWeight: "600",
  "&:hover": {
    color: "#689900",
  },
  "&:focus": {
    color: "#689900",
  },
  }
}));


