import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { searchArticles } from "../../../../store/helpSlice";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
  },
}));

const SearchArticles = ({ placeholderText }) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  const handleSearch = async () => {
    try {
      // Replace the axios request with your actual search logic
      console.log(`Searching for: ${query}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid sx={{ width: { xs: "100%" } }}>
      <TextField
        style={{
          borderRadius: "50px",
          border: "1px solid #689900",
          padding: "0px 30px",
          backgroundColor: "transparent",
        }}
        variant="standard"
        fullWidth
        placeholder={placeholderText}
        onChange={(e) => dispatch(searchArticles(e.target.value))}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton onClick={handleSearch}>
              <SearchIcon
                sx={{
                  color: "#9CCA3C",
                  borderRadius: "50px",
                  "&:hover": {
                    color: "#689900",
                  },
                }}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          style: {
            color: "#FFF",
          },
        }}
        className={classes.searchInput}
      />
    </Grid>
  );
};

export default SearchArticles;
