import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import SearchBar from "../SearchBar";
import { tableElement, elementRows } from "../data";
import { useMutation, useQuery } from "@apollo/client";
import { useStyles } from "./useStyles";
import {
  GET_CAMPAIGN_LIST,
  GET_ELEMENTS,
  CREATE_ELEMENT,
  DELETE_ELEMENT,
  UPDATE_ELEMENT,
  CREATE_EXPORT,
} from "./Query";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateElement from "./CreateElement/CreateElement";
import { allElements } from "./CreateElement/elements";
import { ElementShowModal } from "./ElementShowModal";
import { useSelector } from "react-redux";
import { ConfirmationDialog } from "../../../../utils/confirmationDialog";
import { downloadFile } from "../../../../utils/globalFunctions.js";
import dayjs from "dayjs";
import DocumentTitle from "../../../../DocumentTitle";

const editIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/edit.svg";
const editDarkIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/editBlack.svg";
const deleteIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/delete.svg";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";

const Elements = ({
  newElementPopup,
  setNewElementPopup,
  onClose,
  setSelectedElement,
  refetchElementRows,
  setRefetchElementRows,
}) => {
  DocumentTitle("Elements - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [openElementShowModal, setElementShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [mode, setMode] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);

  const [sortElement, setSortElement] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [filterElement, setFilterElement] = useState({
    filterBy: null,
  });
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [elementRow, setElementRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [refetchRows, setRefetchRows] = useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const handleCreatePopup = () => {
    setNewElementPopup(true);
  };

  const [CreateExport, { loading: exportLoading, error: exportError }] =
    useMutation(CREATE_EXPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("export request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("export request was successful!"));
      },
    });

  const updateElementRowState = (result) => {
    setElementRows(result?.elements?.nodes);
    const pageInfo = result?.elements?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const [DeleteElement, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_ELEMENT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("the delete was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("element deleted successfully!"));
      },
    });

  const {
    data: elementData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_ELEMENTS, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      sortColumn: sortElement?.name,
      sortOrder: sortElement?.order,
      filterBy: filterElement?.filterBy,
    },
    onCompleted: (result) => {
      updateElementRowState(result);
    },
  });

  useEffect(() => {
    if (filterElement.filterBy || refetchElementRows) {
      refetch({
        variables: {
          filterBy: filterElement?.filterBy,
        },
      }).then((result) => {
        updateElementRowState(result?.data);
      });
      setRefetchElementRows(false);
    }
  }, [filterElement.filterBy, refetchElementRows]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setElementRows([...elementRow, ...result?.data?.elements?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor: result?.data?.elements?.pageInfo?.startCursor,
          endCursor: result?.data?.elements?.pageInfo?.endCursor,
          hasNextPage: result?.data?.elements?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleDelete = async () => {
    if (selectedRow?.uuid) {
      const variables = {
        uuid: selectedRow?.uuid,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await DeleteElement({ variables });
        dispatch(setSpinnerLoading(false));
        setRefetchElementRows(true);
        setConfirmationDialog(false);
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
        setConfirmationDialog(false);
      }
    }
  };

  const handleExport = async () => {
    const variables = {
      pageName: "Element",
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateExport({ variables });
      if (result?.data?.createExport?.export?.success) {
        downloadFile(result?.data?.createExport?.export?.fileUrl);
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.container}
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.fw600}
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Elements
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid
            className={classes.centerAlign}
            sx={{
              justifyContent: {
                md: "flex-start",
                sm: "flex-start",
                xs: "space-between",
              },
              width: { md: "fit-content", sm: "100%", xs: "100%" },
              flexDirection: { md: "row", sm: "row", xs: "column" },
              flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
            }}
          >
            <Box
              className={classes.flexStart}
              item
              xs={10}
              sx={{
                width: {
                  md: "fit-content",
                  sm: "fit-content",
                  xs: "fit-content",
                },
                padding: { md: "0px", sm: "0px", xs: "0px 0px" },
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "nowrap" },
                justifyContent: {
                  md: "flex-start",
                  sm: "flex-start",
                  xs: "center",
                },
                flexDirection: { md: "row", sm: "row", xs: "row" },
              }}
            >
              <Button
                disableRipple
                className={classes.clrButton}
                onClick={() => {
                  setSelectedElement(null);
                  handleCreatePopup();
                }}
                sx={{
                  padding: {
                    md: "10px 15px",
                    sm: "10px 15px",
                    xs: "8px 15px",
                  },
                  margin: "10px 10px 10px 0px",
                }}
              >
                <Grid
                  className={classes.noWrapCenter}
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "13px" },
                  }}
                >
                  <Typography
                    variant="inherit"
                    sx={{ display: { md: "flex", sm: "flex", xs: "flex" } }}
                  >
                    + New Element
                  </Typography>
                </Grid>
              </Button>
              <Button
                mx={1}
                className={classes.transButton}
                disableRipple
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElFilter(e.currentTarget);
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "11px" },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Type
                    <img src={down} alt="" style={{ marginLeft: "10px" }} />
                  </Typography>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                open={Boolean(anchorElFilter)}
                onClose={(e) => {
                  setAnchorElFilter(null);
                }}
                sx={{
                  "& .MuiPaper-root": {
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                    border: "1px solid #689900",
                    borderRadius: "10px",
                  },
                }}
              >
                <Paper
                  style={{
                    maxHeight: 350,
                    maxWidth: 250,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor:
                      themeMode === "dark"
                        ? "#8ED003CC #0F0F0F"
                        : "#8ED003 #E2E2E2",
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      setAnchorElFilter(null);
                      setFilterElement({ filterBy: null });
                    }}
                    selected={filterElement?.filterBy === null}
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                  >
                    All
                  </MenuItem>
                  {allElements?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(e) => {
                        setAnchorElFilter(null);
                        setFilterElement({ filterBy: item.type });
                      }}
                      selected={filterElement?.filterBy === item.type}
                      className={classes.menuItem}
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                        fontWeight: "600",
                        "&:hover": {
                          color: "#689900",
                        },
                        "&:focus": {
                          color: "#689900",
                        },
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Paper>
              </Menu>

              <Button
                className={classes.transButton}
                disableRipple
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElSort(e.currentTarget);
                }}
              >
                <Grid container sx={{ display: "flex", flexWrap: "nowrap" }}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "11px" },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Sort
                    <img src={down} alt="" style={{ marginLeft: "10px" }} />
                  </Typography>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElSort}
                open={Boolean(anchorElSort)}
                onClose={(e) => {
                  setAnchorElSort(null);
                }}
                sx={{
                  "& .MuiPaper-root": {
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                    border: "1px solid #689900",
                    borderRadius: "10px",
                  },
                }}
              >
                <Paper
                  style={{
                    maxHeight: 350,
                    maxWidth: 250,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor:
                      themeMode === "dark"
                        ? "#8ED003CC #0F0F0F"
                        : "#8ED003 #E2E2E2",
                  }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "NAME",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "NAME" &&
                      sortElement?.order === "ASCENDING"
                    }
                  >
                    Name A to Z
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "NAME",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "NAME" &&
                      sortElement?.order === "DESCENDING"
                    }
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                  >
                    Name Z to A
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "AMOUNT",
                        order: "ASCENDING",
                      });
                    }}
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    selected={
                      sortElement?.name === "AMOUNT" &&
                      sortElement?.order === "ASCENDING"
                    }
                  >
                    Amount Low To High
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "AMOUNT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "AMOUNT" &&
                      sortElement?.order === "DESCENDING"
                    }
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                  >
                    Amount High To Low
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "CREATED_AT",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "CREATED_AT" &&
                      sortElement?.order === "ASCENDING"
                    }
                  >
                    Created First
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "CREATED_AT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "CREATED_AT" &&
                      sortElement?.order === "DESCENDING"
                    }
                  >
                    Created Last
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "UPDATED_AT",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "UPDATED_AT" &&
                      sortElement?.order === "ASCENDING"
                    }
                  >
                    Updated First
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortElement({
                        name: "UPDATED_AT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortElement?.name === "UPDATED_AT" &&
                      sortElement?.order === "DESCENDING"
                    }
                  >
                    Updated Last
                  </MenuItem>
                </Paper>
              </Menu>
            </Box>
            <Box item xs={1} my={{ xs: 1 }}>
              <SearchBar
                specific="Search Element"
                handleSearch={handleSearch}
              />
            </Box>
          </Grid>

          <Grid
            item
            md={2.2}
            sm={3.2}
            xs={12}
            className={classes.noWrap}
            sx={{
              justifyContent: {
                md: "flex-end",
                sm: "flex-end",
                xs: "flex-end",
              },
            }}
          >
            <Button
              disableRipple
              className={classes.exportBtn}
              onClick={() => {
                handleExport();
              }}
              sx={{
                //            display:{md:"flex",sm:"none",xs:"none"},
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 15px",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid container className={classes.nowrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },
                    width: "100%",
                  }}
                >
                  Export List
                  <img
                    src={themeMode === "dark" ? download : downloadLight}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12} my={3}>
          <TableContainer
            className={classes.tableContainer}
            id="table-container"
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
            }}
          >
            <Table
              className={classes.table}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ position: "sticky" }}>
                <TableRow
                  className={classes.white}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  {tableElement?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{

                        fontWeight: "700",
                        backgroundColor: themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0",  // Apply bottom left radius to the first item
                        borderBottomRightRadius: index === tableElement.length - 1 ? "15px" : "0",  // Apply bottom right radius to the last item
                        color: index === tableElement.length - 1 && themeMode === "dark" ? "#FFFF" : "#030303",
                        borderRight: index === tableElement.length - 1 ? "0px" : undefined,
                      }}
                    >
                      <Typography
                        className={classes.tableHeadText}
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {elementRow?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      align="center"
                      sx={{ color: "#fff" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        No Records
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  elementRow?.map((row, index) => (
                    <TableRow
                      className={classes.row}
                      key={index}
                      sx={{
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "12.5%",
                          ...(index === tableElement.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.tableTextWhite}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.uuid
                            ? row.uuid.slice(0, row.uuid.indexOf("-"))
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        md={2}
                        sx={{
                          width: "15%",
                          ...(index === tableElement.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            fontSize: { md: "16px", sm: "13px", xs: "12px" },
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "20%",
                          ...(index === tableElement.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            fontSize: { md: "16px", sm: "13px", xs: "12px" },
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.elementType}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "12.5%",
                          ...(index === tableElement.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography className={classes.tableTextWhite}>
                          {row.campaign
                            ? row.campaign?.title?.length > 18
                              ? `${row.campaign.title?.substring(0, 18)}...`
                              : row?.campaign?.title
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "30%",
                          ...(index === tableElement.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            fontSize: { md: "16px", sm: "13px", xs: "12px" },
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {dayjs(row.createdAt)?.format("YYYY-MM-DD")}
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "2%",
                          ...(index === tableElement.length - 1 && {}),
                        }}
                        onClick={() => {
                          setSelectedRow(row);
                          setElementShowModal(true);
                        }}
                      >
                        <IconButton
                          sx={{ "&:hover": { backgroundColor: "transparent" } }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "2%",
                          ...(index === tableElement.length - 1 && {}),
                        }}
                        onClick={() => {
                          setSelectedElement(row);
                          handleCreatePopup();
                        }}
                      >
                        <Button
                          sx={{ "&:hover": { backgroundColor: "transparent" } }}
                        >
                          {themeMode === "dark" ? (
                            <img src={editIcon} alt="" />
                          ) : (
                            <img src={editDarkIcon} alt="" />
                          )}
                        </Button>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "2%",
                          ...(index === tableElement.length - 1 && {}),
                        }}
                        onClick={() => {
                          setSelectedRow(row);
                          setConfirmationDialog(true);
                        }}
                      >
                        <Button
                          sx={{ "&:hover": { backgroundColor: "transparent" } }}
                        >
                          <img src={deleteIcon} alt="" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* <P2PPopup open={openPopup} setOpen={setOpenPopup} p2pData={mode === "EDIT" ? selectedRow : null} mode={mode} setRefetchElementRows={setRefetchElementRows}/> */}
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setConfirmationDialog}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this?"}
        handleConfirmation={handleDelete}
      />
      <ElementShowModal
        open={openElementShowModal}
        setOpen={setElementShowModal}
        elementRow={selectedRow}
      />
    </>
  );
};
export default Elements;
