import { Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./useStyles";
import SearchArticles from "./SearchArticles";
import { useSelector } from "react-redux";

const Help = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 0px 20px",
            sm: "130px 30px 20px",
            xs: "100px 15px 30px",
          },
          filter: themeMode === "dark" ? "" : "brightness(500%)",
        }}
      >
        <Grid
          id="help"
          className={classes.columnCenter}
          item
          md={12}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontWeight: themeMode === "dark" ? "700" : "300",
              fontSize: { md: "22px", sm: "16px", xs: "12px" },
              fontFamily: "Stolzl",
            }}
          >
            Help Centre{" "}
          </Typography>
          <Typography
            className={classes.upper700}
            sx={{
              //fontWeight:"900",
              fontWeight: themeMode === "dark" ? "700" : "300",
              fontSize: { md: "56px", sm: "36px", xs: "26px" },
              fontFamily: "Stolzl",
            }}
          >
            How can we help?
          </Typography>
        </Grid>
        <Grid
          item
          my={5}
          className={classes.jCenter}
          sx={{ width: { md: "35%", sm: "50%", xs: "100%" } }}
        >
          <SearchArticles placeholderText="Search FAQs" />
        </Grid>
      </Grid>
    </>
  );
};
export default Help;
