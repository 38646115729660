import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Input,
} from "@mui/material";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GroupIcon from "@mui/icons-material/Group";
import { OverviewList } from "../overviewList";
import {
  MySwitch,
  MyTextField,
  donationType,
  CustomColorPicker,
} from "../MyComponents";
import { useStyles } from "./useStyles";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_EMAIL_SETTING,
  UPDATE_EMAIL_SETTING,
  CREATE_EMAIL_BODY,
  UPDATE_EMAIL_BODY,
  GET_CAMPAIGN,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import EmailPreviewDialog from "./EmailPreviewScreens/EmailPreviewDialog";
import { ArrowRightAlt } from "@mui/icons-material";

const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const backLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const back = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const heart = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const heartDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const question = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const upload = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/upload.svg";
const uploadDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/uploadDark.svg";

const CampaignEmail = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#000");
  const [mode, setMode] = useState("Customize");
  const [isHovered, setIsHovered] = useState(false);
  const [type, setType] = useState(1);
  const [emailSettingObj, setEmailSettingObj] = useState({
    campaignUuid: uuid,
    uuid: null,
    senderEmail: "",
    senderName: "",
    body: "",
    textColor: "#000",
    headerTextColor: "#000",
    footerTextColor: "#000",
    socialLinks: [],
    title: "",
  });
  const [emailBodyObj, setEmailBodyObj] = useState({
    campaignUuid: uuid,
    uuid: null,
    emailSubject: "",
    emailTitle: "",
    emailFor: "",
    active: false,
    emailText: "",
    campaignTitle: "",
  });

  const { data: campaignData, refetch } = useQuery(GET_CAMPAIGN, {
    variables: { uuid },
    onCompleted: (result) => {
      if (result?.campaign) {
        setEmailSettingObj((prevObj) => ({
          ...prevObj,
          uuid: result?.campaign?.emailSetting?.uuid,
          senderEmail: result?.campaign?.emailSetting?.senderEmail,
          senderName: result?.campaign?.emailSetting?.senderName,
          body: result?.campaign?.emailSetting?.body,
          textColor: result?.campaign?.emailSetting?.textColor,
          headerTextColor: result?.campaign?.emailSetting?.headerTextColor,
          footerTextColor: result?.campaign?.emailSetting?.footerTextColor,
          socialLinks: result?.campaign?.emailSetting?.socialLinks,
          campaignTitle: result?.campaign?.title,
        }));
      }
      if (result?.campaign?.emailBodies?.nodes?.length) {
        const emailSettingBodyNodes = result?.campaign?.emailBodies?.nodes;
        if (type === 1) {
          const emailSettingBody = emailSettingBodyNodes?.find(
            (emailBody) => emailBody.emailFor === "one_time_receipt"
          );
          setEmailBodyObj((prevObj) => ({
            ...prevObj,
            uuid: emailSettingBody?.uuid,
            emailSubject: emailSettingBody?.emailSubject || "One-time receipt",
            emailTitle: emailSettingBody?.emailTitle,
            emailFor: emailSettingBody?.emailFor,
            active: emailSettingBody?.active,
            emailText: emailSettingBody?.emailText,
          }));
        }
      }
    },
  });
  const [
    CreateEmailSetting,
    { loading: emailSettingLoading, error: emailSettingError },
  ] = useMutation(CREATE_EMAIL_SETTING, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const [
    UpdateEmailSetting,
    { loading: emailSettingUpdateLoading, error: emailSettingUpdateError },
  ] = useMutation(UPDATE_EMAIL_SETTING, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request was successful!"));
    },
  });
  const [
    CreateEmailBody,
    { loading: emailBodyLoading, error: emailBodyError },
  ] = useMutation(CREATE_EMAIL_BODY, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request was successful!"));
    },
  });
  const [
    UpdateEmailBody,
    { loading: emailBodyUpdateLoading, error: emailBodyUpdateError },
  ] = useMutation(UPDATE_EMAIL_BODY, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request was successful!"));
    },
  });

  const changeType = (item) => {
    setType(item.id);
    refetch({
      variables: {
        uuid: uuid,
      },
    });
    const emailSettingBodyNodes = campaignData?.campaign?.emailBodies?.nodes;
    const emailSettingBody = emailSettingBodyNodes?.find(
      (emailBody) => emailBody.emailFor === item?.value
    );
    setEmailBodyObj((prevObj) => ({
      ...prevObj,
      emailSubject: emailSettingBody?.emailSubject || item.name,
      emailFor: item.value,
      uuid: emailSettingBody?.uuid,
      emailTitle: emailSettingBody?.emailTitle,
      active: emailSettingBody?.active,
      emailText: emailSettingBody?.emailText,
    }));
  };
  const CustomizeMode = () => {
    setMode("Customize");
  };

  const BodyMode = () => {
    setMode("Body");
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSave = async (event) => {
    event.preventDefault();
    if (mode === "Customize") {
      if (!emailSettingObj?.uuid) {
        const variables = { campaignUuid: uuid, ...emailSettingObj };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreateEmailSetting({ variables });
          setEmailSettingObj((prevObj) => ({
            ...prevObj,
            uuid: result?.data?.createEmailSetting?.emailSetting?.uuid,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (emailSettingObj?.uuid) {
        const variables = { uuid: emailSettingObj.uuid, ...emailSettingObj };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdateEmailSetting({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    } else if (mode === "Body") {
      if (!emailBodyObj?.uuid) {
        const variables = { campaignUuid: uuid, ...emailBodyObj };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreateEmailBody({ variables });
          setEmailBodyObj((prevObj) => ({
            ...prevObj,
            uuid: result?.data?.createEmailBody?.emailBody?.uuid,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (emailBodyObj?.uuid) {
        const variables = { uuid: emailBodyObj.uuid, ...emailBodyObj };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdateEmailBody({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    }
  };

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                display: { md: "block", sm: "block", xs: "flex" },
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>
            </Grid>

            <Grid className={classes.fullWidth}>
              <Button
                className={classes.transButton}
                href="/dashboard/campaigns"
                sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
              >
                <Grid className={classes.flexCenter}>
                  <img
                    src={themeMode === "dark" ? heart : heartDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    className={classes.fw500}
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    Campaigns
                  </Typography>
                </Grid>
              </Button>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "40px", sm: "40px", xs: "26px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                }}
              >
                {emailSettingObj?.campaignTitle?.length > 15
                  ? `${emailSettingObj?.campaignTitle.substring(0, 15)}...`
                  : emailSettingObj?.campaignTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll   */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList?.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${emailSettingObj?.campaignTitle}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 2
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 2
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 2
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 2 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid
                  key={`arrow-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: { md: "scale(1.3)", sm: "scale(1)" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${emailSettingObj?.campaignTitle}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 2
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 2
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 2
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 2 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>

        <Grid
          className={classes.fullWidth}
          sx={{
            justifyContent: { md: "left", sm: "left", xs: "center" },
          }}
        >
          <Button
            className={classes.modeButton}
            disableRipple
            onClick={CustomizeMode}
            sx={{
              background:
                mode === "Customize" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 10px",
              },
              color:
                themeMode === "light" && mode !== "Customize"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "14px" } }}
              >
                Customize
              </Typography>
            </Grid>
          </Button>

          <Button
            className={classes.modeButton}
            onClick={BodyMode}
            disableRipple
            sx={{
              background:
                mode === "Body" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Body" ? "#030303" : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "14px" } }}
              >
                Set Email Body
              </Typography>
            </Grid>
          </Button>
        </Grid>

        {mode === "Customize" ? (
          <Grid
            my={3}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Typography className={classes.fs16}>
              Customize the automated emails sent to supporters of this
              campaign. Any changes made here will override the account-level
              settings for all emails linked to this campaign.
            </Typography>

            <Grid item md={4} sm={6.5} my={3}>
              <Typography>Sender Email</Typography>
              <FormControl className={classes.fullWidth}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  value={emailSettingObj?.senderEmail || ""}
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      senderEmail: event.target.value,
                    }))
                  }
                  className={classes.input}
                  sx={{
                    mr: 1,
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiInputLabel-root": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item md={4} sm={6.5} my={3}>
              <Typography>Sender Name</Typography>
              <FormControl className={classes.fullWidth}>
                <TextField
                  label=""
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  value={emailSettingObj?.senderName || ""}
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      senderName: event.target.value,
                    }))
                  }
                  className={classes.input}
                  sx={{
                    mr: 1,
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiInputLabel-root": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid my={2}>
              <Typography className={classes.f18w500} mb={2}>
                Logo
              </Typography>
              <Grid className={classes.spaceBtwn} item md={3}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <Button
                  className={classes.uploadButton}
                  variant="contained"
                  onClick={handleButtonClick}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  sx={{
                    background: themeMode === "dark" ? "#141414" : "#ECECEC",
                    border:
                      themeMode === "dark"
                        ? "1px solid transparent"
                        : "1px solid #000",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                      color: "#FFF",
                    },
                  }}
                >
                  <Grid className={classes.fullWidth}>
                    <Typography
                      className={classes.buttonText}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          themeMode === "dark"
                            ? upload
                            : themeMode === "light" && isHovered
                            ? upload
                            : uploadDark
                        }
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      Upload
                    </Typography>
                  </Grid>
                </Button>
                {selectedFile && (
                  <Grid
                    className={classes.upload}
                    sx={{
                      backgroundImage: `url(${imageUrl})`,
                      filter: "grayscale(1)",
                    }}
                  ></Grid>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              my={3}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "row", xs: "column" },
              }}
            >
              <Grid item my={2} md={2} sm={3} xs={7} mr={{ md: 2, sm: 2 }}>
                <Typography
                  className={classes.buttonText}
                  sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                >
                  Text Color
                </Typography>

                <Grid
                  className={classes.colorBox}
                  sx={{
                    border:
                      themeMode === "dark"
                        ? "0.5px solid #FFFFFF"
                        : "0.5px solid #000",
                  }}
                >
                  <CustomColorPicker
                    name="color"
                    value={emailSettingObj?.textColor || "#000"}
                    onChange={(color) => {
                      setEmailSettingObj({
                        ...emailSettingObj,
                        textColor: color,
                      });
                    }}
                  />
                  <Typography mx={2}>{emailSettingObj?.textColor}</Typography>
                </Grid>
              </Grid>

              <Grid item my={2} md={2} sm={3} xs={7} mr={{ md: 2, sm: 2 }}>
                <Typography
                  className={classes.buttonText}
                  sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                >
                  Header Text Color
                </Typography>

                <Grid
                  className={classes.colorBox}
                  sx={{
                    border:
                      themeMode === "dark"
                        ? "0.5px solid #FFFFFF"
                        : "0.5px solid #000",
                  }}
                >
                  <CustomColorPicker
                    name="color"
                    value={emailSettingObj?.headerTextColor || "#000"}
                    onChange={(color) => {
                      setEmailSettingObj({
                        ...emailSettingObj,
                        headerTextColor: color,
                      });
                    }}
                  />
                  <Typography mx={2}>
                    {emailSettingObj?.headerTextColor}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item my={2} md={2} sm={3} xs={7} mr={{ md: 2, sm: 2 }}>
                <Typography
                  className={classes.buttonText}
                  sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                >
                  Footer Text Color
                </Typography>

                <Grid
                  className={classes.colorBox}
                  sx={{
                    border:
                      themeMode === "dark"
                        ? "0.5px solid #FFFFFF"
                        : "0.5px solid #000",
                  }}
                >
                  <CustomColorPicker
                    name="color"
                    value={emailSettingObj?.footerTextColor || "#000"}
                    onChange={(color) => {
                      setEmailSettingObj({
                        ...emailSettingObj,
                        footerTextColor: color,
                      });
                    }}
                  />
                  <Typography mx={2}>
                    {emailSettingObj?.footerTextColor}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className={classes.spaceBtwn}
              sx={{ flexDirection: { md: "row", sm: "row", xs: "column" } }}
            >
              <Grid item md={7.5}>
                <Typography className={classes.buttonText}>
                  Social Links
                </Typography>
                <TextField
                  variant="outlined"
                  value={
                    emailSettingObj?.socialLinks
                      ? emailSettingObj?.socialLinks[0]
                      : ""
                  }
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      socialLinks: [
                        event.target.value,
                        ...prevObj.socialLinks?.slice(1),
                      ],
                    }))
                  }
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: { md: 3, sm: 3, xs: 0 },
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <FacebookRoundedIcon
                        className={classes.icon}
                        sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                      />
                    ),
                  }}
                />

                <TextField
                  variant="outlined"
                  value={
                    emailSettingObj?.socialLinks
                      ? emailSettingObj?.socialLinks[1]
                      : ""
                  }
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      socialLinks: [
                        prevObj.socialLinks[0],
                        event.target.value,
                        ...(prevObj.socialLinks
                          ? prevObj.socialLinks.slice(2)
                          : []),
                      ],
                    }))
                  }
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: { md: 3, sm: 3, xs: 0 },
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <LinkedInIcon
                        className={classes.icon}
                        sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                      />
                    ),
                  }}
                />

                <TextField
                  variant="outlined"
                  value={
                    emailSettingObj?.socialLinks
                      ? emailSettingObj?.socialLinks[2]
                      : ""
                  }
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      socialLinks: [
                        prevObj.socialLinks[0],
                        prevObj.socialLinks[1],
                        event.target.value,
                        ...(prevObj.socialLinks.slice(3) || []),
                      ],
                    }))
                  }
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: { md: 3, sm: 3, xs: 0 },
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <TwitterIcon
                        className={classes.icon}
                        sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                      />
                    ),
                  }}
                />

                <TextField
                  variant="outlined"
                  value={
                    emailSettingObj?.socialLinks
                      ? emailSettingObj?.socialLinks[3]
                      : ""
                  }
                  onChange={(event) =>
                    setEmailSettingObj((prevObj) => ({
                      ...prevObj,
                      socialLinks: [
                        prevObj.socialLinks[0],
                        prevObj.socialLinks[1],
                        prevObj.socialLinks[2],
                        event.target.value,
                      ],
                    }))
                  }
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: { md: 3, sm: 3, xs: 0 },
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <GroupIcon
                        className={classes.icon}
                        sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid md={4.5} className={classes.p20}>
                <Button
                  className={classes.add}
                  sx={{
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D66" : "#0F0F0F",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    border:
                      themeMode === "dark"
                        ? "1px solid 0D0D0D66"
                        : "1px solid #0F0F0F",
                    "&:hover": {
                      color: themeMode === "light" ? "#030303" : "#FFF",
                      border:
                        themeMode === "dark"
                          ? "1px solid #0F0F0F"
                          : "1px solid 0D0D0D66",
                    },
                  }}
                >
                  +
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : mode === "Body" ? (
          <Grid
            my={5}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Typography className={classes.fs16}>
              Set email’s subject and body from here for all the conditions
            </Typography>

            <Grid
              my={1}
              item
              md={12}
              sx={{
                borderBottom:
                  themeMode === "dark"
                    ? "0.5px solid #FFF"
                    : "0.5px solid #030303",
              }}
            >
              {donationType?.map((item, index) => (
                <Button
                  key={index}
                  disableRipple
                  onClick={(event) => changeType(item)}
                  className={classes.options}
                  sx={{
                    mr: "20px",
                    borderBottom:
                      type === item.id
                        ? "4px solid #97DF00"
                        : "0px solid transparent",
                  }}
                >
                  <Typography
                    className={classes.optionText}
                    sx={{
                      fontSize: { md: "18px", sm: "18px", xs: "12px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Button>
              ))}
            </Grid>

            <Grid className={classes.column} container my={3}>
              <Grid item md={10} my={4}>
                <Typography className={classes.fs16}>
                  This is sent when a one-time donation succeeds. If PDF
                  receipts are enabled, an official PDF receipt is attached. It
                  also includes a button to review their donation in Donor
                  Portal.
                </Typography>
              </Grid>
              <Grid className={classes.rowCenter} item md={12} my={2}>
                <MySwitch
                  checked={emailBodyObj?.active || ""}
                  onChange={(event) =>
                    setEmailBodyObj((prevObj) => ({
                      ...prevObj,
                      active: event.target.checked,
                    }))
                  }
                />
                <Typography className={classes.fs16} mx={3}>
                  Enable One-time receipt email
                </Typography>
              </Grid>
            </Grid>

            <Grid
              className={classes.spaceBtwn}
              item
              md={12}
              my={{ md: 3, sm: 2, xs: 3 }}
              sx={{
                flexDirection: { md: "row", sm: "row", xs: "column" },
              }}
            >
              <FormControl
                className={classes.spaceBtwn}
                sx={{
                  width: { md: "60%", sm: "65%", xs: "100%" },
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                }}
              >
                <Grid item md={6}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                    }}
                  >
                    Email Subject
                  </Typography>
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    value={emailBodyObj?.emailSubject || ""}
                    onChange={(event) =>
                      setEmailBodyObj((prevObj) => ({
                        ...prevObj,
                        emailSubject: event.target.value,
                      }))
                    }
                    className={classes.input}
                    sx={{
                      mr: 1,
                      width: { md: "90%", sm: "90%", xs: "100%" },
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                    }}
                  >
                    Email Title
                  </Typography>
                  <TextField
                    variant="outlined"
                    id="outlined-basic"
                    value={emailBodyObj?.emailTitle || ""}
                    onChange={(event) =>
                      setEmailBodyObj((prevObj) => ({
                        ...prevObj,
                        emailTitle: event.target.value,
                      }))
                    }
                    className={classes.input}
                    sx={{
                      mr: 1,
                      width: { md: "90%", sm: "90%", xs: "100%" },
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                  />
                </Grid>
              </FormControl>

              <Grid md={4} sm={4}>
                <Typography className={classes.f18w500} mb={2}>
                  Insert Image Here
                </Typography>
                <Grid className={classes.spaceBtwn} item md={8}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    mx={1}
                    className={classes.uploadButton}
                    variant="contained"
                    onClick={handleButtonClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    sx={{
                      background: themeMode === "dark" ? "#141414" : "#ECECEC",
                      border:
                        themeMode === "dark"
                          ? "1px solid transparent"
                          : "1px solid #000",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      "&:hover": {
                        color: "#FFF",
                      },
                    }}
                  >
                    <Grid className={classes.fullWidth}>
                      <Typography
                        className={classes.buttonText}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            themeMode === "dark"
                              ? upload
                              : themeMode === "light" && isHovered
                              ? upload
                              : uploadDark
                          }
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                        Upload
                      </Typography>
                    </Grid>
                  </Button>
                  {/* {selectedFile && <Grid className={classes.upload}></Grid>} */}
                  {selectedFile && (
                    <Grid
                      className={classes.upload}
                      sx={{
                        backgroundImage: `url(${imageUrl})`,
                        filter: "grayscale(0)",
                      }}
                    ></Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={7}>
              <Typography className={classes.f18w500} mb={2}>
                Email Text
              </Typography>
              <FormControl className={classes.fullWidth}>
                <MyTextField
                  textMsg={emailBodyObj?.emailText || "Write message here"}
                  handleTextChange={(value) =>
                    setEmailBodyObj((prevObj) => ({
                      ...prevObj,
                      emailText: value,
                    }))
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid container mt={6} justifyContent="flex-start">
          <Grid item md={2} sm={2.5} xs={6}>
            <EmailPreviewDialog type={type} mode={mode} />
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              onClick={handleSave}
              loading={false}
              loadingPosition="start"
              variant="outlined"
              className={classes.save}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
              }}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default CampaignEmail;
