import { makeStyles } from "@mui/styles";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/learnLightbg.svg";
const ramdanLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramdanLight.svg";

export const useStylesLight = makeStyles((theme) => ({
  bkg: {
    backgroundImage: `url('${bkg}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  upper700: {
    textTransform: "uppercase",
    fontWeight: "700",
    color: "black",
  },
  color: {
    color: "#F6BE25",
  },
  jCenter: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  full: {
    width: "100%",
  },
  overflowx: {
    display: "flex",
    justifyContent: "space-between",
    overflowX: "auto",
    scrollbarColor: "#8ED003CC #0F0F0F",
    scrollbarWidth: "thin",
  },
  btnList: {
    border: "1px solid #5C5C5C",
    borderRadius: "30px",
    width: "100%",
    "&:hover": {
      border: "1px solid #79B200",
      color: "black",
    },
  },
  btnText: {
    fontWeight: "600",
    color: "black",
    textTransform: "capitalize",
    textWrap: "nowrap",
    width: "fit-content",
  },
  ramdanLight: {
    backgroundImage: `url('${ramdanLight}')`,
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    borderRadius: "30px",
    width: "100%",
    height: "70vh",
  },
  captionBox: {
    backgroundColor: "#000000CC",
    width: "100%",
    borderRadius: "0 0 30px 30px",
  },
  captionHead: {
    textTransform: "uppercase",
    fontWeight: "700",
    width: "100%",
  },
  caption2: {
    textTransform: "uppercase",
    fontWeight: "500",
    width: "100%",
  },
}));
