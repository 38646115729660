import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from "@mui/material";
import SearchBar from "../SearchBar";
import { tableExports, exportRow } from "../data";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
//import { GET_CAMPAIGN } from "./Query";

const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const downWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const question = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const download = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";


const Exports = ({ showPopupCallback }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [searchQuery, setSearchQuery] = useState(null);
  const [campaigns, setCampaigns] = useState([]);

  //   const { data: campaignInfo } = useQuery(GET_CAMPAIGN);

  //   useEffect(() => {
  //     if (campaignInfo) {
  //       const campaignData = campaignInfo.campaigns.nodes;
  //       setCampaigns(campaignData);
  //     }
  //   }, [campaignInfo]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              fontWeight: "600",
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Exported Material
            {/* <img src={question} alt=""/> */}
          </Typography>
        </Grid>

        <Grid
          item
          // md={6}
          // sm={5}
          // xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "space-between",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            xs={10}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: { md: "fit-content", sm: "fit-content", xs: "fit-content" },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
            }}
          >
            <Button
              disableRipple
              sx={{
                color: "#FFF",
                background: "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                },
              }}
            >
              <Grid container sx={{ display: "flex", flexWrap: "nowrap" }}>
                <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", fontSize: { md: "12px", sm: "12px", xs: "10px" }, display: "flex", alignItems: "center", textWrap: "nowrap" }}>
                  This Month
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
            <Button
              disableRipple
              sx={{
                color: "#FFF",
                background: "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                },
              }}
            >
              <Grid container sx={{ display: "flex", flexWrap: "nowrap" }}>
                <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", fontSize: { md: "12px", sm: "12px", xs: "10px" }, display: "flex", alignItems: "center", textWrap: "nowrap" }}>
                  Filters
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
          </Box>
          <Box item xs={1} my={{ xs: 2 }}>
            <SearchBar specific="Search File" handleSearch={handleSearch} />
          </Box>
        </Grid>













        <Grid item md={12} sm={12} xs={12} my={3}>
          <TableContainer
            sx={{
              zIndex: "0",
              backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
              boxShadow: themeMode === 'dark' ? "0px 0px 0px blue" : "4px 4px 14px 0px #00000024",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <Table
              sx={{
                width: "100%",
                color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ color: "#fff" }}>
                  {tableExports?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        color: "#000",
                        border: "0px",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                          fontWeight: "700",
                          whiteSpace: "nowrap",
                          width: "fit-content"
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {exportRow?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { borderBottom: 0 },
                      borderRadius: "15px",
                      textDecoration: 'none', zIndex: "10",
                      "&:hover": {
                        backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7"
                      },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      md={2}
                      sx={{
                        //borderRight: "0.5px solid #F1F1F1",
                        width: "15%",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          fontSize: { md: "16px", sm: "13px", xs: "12px" },
                          textWrap: "none"
                        }}
                      >
                        {row.fileName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        //borderRight: "0.5px solid #F1F1F1",
                        width: "15%",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                        }),
                      }}
                    >
                      <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", textWrap: "none" }}>
                        {row.dataType}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        //borderRight: "0.5px solid #F1F1F1",
                        width: "10%",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }),
                      }}
                    >
                      <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", }}>
                        {row.fileType}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        //borderRight: "0.5px solid #F1F1F1",
                        width: "10%",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }),
                      }}
                    >
                      <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", textWrap: "nowrap", }}>
                        {row.date}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        //borderRight: "0.5px solid #F1F1F1",
                        width: "10%",
                        ...(index === tableExports.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }),
                      }}
                    >
                      <Button>
                        <img src={themeMode==="dark"?download:downloadLight} alt="" />
                      </Button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default Exports;
