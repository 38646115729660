import { makeStyles } from '@mui/styles'

const blur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg"
const bkg = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/bkg.svg'
const ramadan = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramadan.svg'

export const useStyles = makeStyles(theme => ({
  bkg: {
    backgroundImage: `url('${bkg}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display:"flex",
    flexDirection:"column"
  },
  columnCenter: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  upper700: {
    textTransform: 'uppercase',
    fontFamily:"Stolzl",
    fontWeight: '700'
  },
  color: {
    color: '#F6BE25'
  },
  jCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  full: {
    width: '100%'
  },
  
  btnList: {
    border: '1px solid #5C5C5C',
    borderRadius: '30px',
    width: '100%',
    '&:hover': {
      textShadow: '1px 1px 10px #FFFF',
      border: '1px solid #FFFFF'
    }
  },
  btnText: {
    fontWeight: '600',
    color: '#FFFF',
    textTransform: 'capitalize',
    textWrap: 'nowrap',
    width: 'fit-content'
  },
  ramadan: {
    backgroundImage: `url('${ramadan}')`,
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderRadius: '30px',
    width: '100%',
    height: '70vh'
  },
  captionBox: {
    backgroundColor: '#000000CC',
    width: '100%',
    borderRadius: '0 0 30px 30px'
  },
  captionHead: {
    textTransform: 'uppercase',
    fontWeight: '700',
    width: '100%'
  },
  caption2: {
    textTransform: 'uppercase',
    fontWeight: '500',
    width: '100%'
  },
  client: {
    backgroundColor: "#160609",
    color: "#fff",
    padding: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexHolder: {
    display: "flex",
    justifyContent: "center",
    margin:"30px 0px",
  },
  gridHolder: {
    display: "grid",
    justifyItems: "center",
    padding:"10px 0px"
  },
  imgHolder: {
    display: "flex",
    alignItems: "center",
  },
  menuItem:{
    backgroundColor: "transparent",
    width: "100% !important",
    textAlign: "center",
    padding: "0px 20px",
    justifyContent: "center",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    "& .MuiPaper-root": {
      //backgroundColor: "#0F0F0F",
      border: "0.5px solid #FFF",
      color: "#FFF",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "flex-end",
      borderRadius: "15px",
    },
    "& .MuiList-root": {
      width: "100%",
    },
  },
  profileDull: {
    padding: "0px 5px",
  },
  profile: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    fontWeight: "600"

  },
  colCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  w80: {
    width: "80%",
  },

  all: {
    width: "100%",
    height: "100%",
  },
  logo: {
    
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))
