import { makeStyles } from "@mui/styles";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg"

export const useStyles = makeStyles((theme) => ({
  bkg: {
    // backgroundImage: `url('${bkg}')`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // backgroundPosition: "center",
    backgroundColor:"#130407",
    width: "100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  upper700: {
    textTransform: "uppercase",
    fontWeight: "700",
  },
  color: {
    color: "#F6BE25",
  },
  jCenter: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  full: {
    width: "100%",
  },
}));
