const fb = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/fb.svg";
const gmail = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/gmail.svg";
const linkedIn = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/linkedIn.svg";
const group = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/group.svg";

export const footLinks = [
  {
    id: 1,
    name: "Gmail",
    link: "",
    img: gmail,
  },
  {
    id: 2,
    name: "Facebook",
    link: "",
    img: fb,
  },
  {
    id: 3,
    name: "LinkedIn",
    link: "",
    img: linkedIn,
  },
  {
    id: 4,
    name: "Fb Group",
    link: "",
    img: group,
  },
];

export const product = [
  {
    id: 1,
    name: "Features",
    link: "/features",
  },
  {
    id: 2,
    name: "Pricing",
    link: "/pricing",
  },
];

export const resources = [
  {
    id: 1,
    name: "Help Center",
    link: "/help-center",
  },
  {
    id: 2,
    name: "Contact Us",
    link: "/contact-us",
  },
];

export const community = [
  {
    id: 1,
    name: "Blog",
    link: "/learn",
  },
  {
    id: 2,
    name: "Career",
    link: "/career",
  },

];

export const company = [
  {
    id: 1,
    name: "Why Acceleraise",
    link: "/company",
  },
  {
    id: 2,
    name: "Customer Stories",
    link: "/testimonial",
  },

];
