import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import spinnerLoaderSlice from "./spinnerLoaderSlice";
import alertSlice from "./alertSlice";
import themeModeSlice from "./themeModeSlice";
import supportSlice from "./supportSlice";
import campaignSlice from "./campaignSlice";
import helpSlice from "./helpSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    spinnerLoader: spinnerLoaderSlice,
    alert: alertSlice,
    themeMode: themeModeSlice,
    support: supportSlice,
    campaign: campaignSlice,
    help: helpSlice,
  },
});

export default store;
