import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/mobile.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/mobile.svg"

const Mobile = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid className={classes.bkg}
      sx={{
        background: "#000",
      }}
      >
        <Grid
          container
          className={classes.cover}
          sx={{
            backgroundImage:themeMode==="dark"? `url('${bkg}')`:`url('${light}')`,
           backgroundSize: {
              md:themeMode==="dark"?"90% auto": "cover",
              sm:themeMode==="dark"?"90% auto": "150% auto",
              xs:themeMode==="dark"? "300% auto":"360% auto"
            },
          }}
        >
          <Grid className={classes.column} item md={9} sm={10} xs={10}>
            <Grid
              className={classes.blur}
              sx={{
                backdropFilter: {
                  md: "blur(11px)",
                  sm: "blur(11px)",
                  xs: "blur(11px)",
                },
                backgroundColor:themeMode==="dark"? "transparent":"#ECECEC66",
                boxShadow: themeMode==="dark"?"0px 0px 0px red": "0px 8px 5px 0px #00000040",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background:themeMode==="dark"? "linear-gradient(145deg, white, transparent)":"linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
            >
              
                <Typography
                  className={classes.fw700}
                  sx={{
                    width:"100% !important",
                    textAlign: { md: "left", sm: "left" },
                    fontSize: { md: "48px", sm: "40px", xs: "30px" },
                    color:themeMode==="dark"? "#FFFF":"#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  MOBILE ACCESSIBILITY
                </Typography>
              
              <Grid
                className={classes.flex}
                sx={{
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  fontSize: { md: "16px", sm: "16px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                }}
              >
                <Typography className={classes.padW400} variant="inherit"
                  sx={{color:themeMode==="dark"? "#FFFF":"#030303",fontFamily:"Stolzl"}}>
                  This feature in Acceleraise ensures that users can access the platform's functionalities seamlessly on mobile devices. This design philosophy prioritizes convenience, enabling users to manage and participate in fundraising activities from anywhere, at any time. By embracing mobile accessibility, Acceleraise caters to the modern user's need for flexibility and constant connectivity, making it easier for organizations and individuals to reach their fundraising goals without being tied to a desktop environment.
                </Typography>
                
                <Typography className={classes.padW400} variant="inherit">                  
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.centerRow} my={5} item>
              <Button className={classes.btn} href="/features">
                Learn More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Mobile;
