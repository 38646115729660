import { gql } from "@apollo/client";

export const CREATE_EMAIL_SETTING = gql`
  mutation CreateEmailSetting(
    $campaignUuid: ID!
    $senderEmail: String
    $senderName: String
    $textColor: String
    $headerTextColor: String
    $footerTextColor: String
    $socialLinks: [String!]
  ) {
    createEmailSetting(
      input: {
        campaignUuid: $campaignUuid
        senderEmail: $senderEmail
        senderName: $senderName
        textColor: $textColor
        headerTextColor: $headerTextColor
        footerTextColor: $footerTextColor
        socialLinks: $socialLinks
      }
    ) {
      emailSetting {
        uuid
        senderEmail
        senderName
        textColor
        headerTextColor
        footerTextColor
        campaign {
          uuid
        }
        socialLinks
      }
    }
  }
`;
export const UPDATE_EMAIL_SETTING = gql`
  mutation UpdateEmailSetting(
    $uuid: ID!
    $senderEmail: String
    $senderName: String
    $textColor: String
    $headerTextColor: String
    $footerTextColor: String
    $socialLinks: [String!]
  ) {
    updateEmailSetting(
      input: {
        uuid: $uuid
        senderEmail: $senderEmail
        senderName: $senderName
        textColor: $textColor
        headerTextColor: $headerTextColor
        footerTextColor: $footerTextColor
        socialLinks: $socialLinks
      }
    ) {
      emailSetting {
        uuid
        senderEmail
        senderName
        textColor
        headerTextColor
        footerTextColor
        campaign {
          uuid
        }
        socialLinks
      }
    }
  }
`;

export const CREATE_EMAIL_BODY = gql`
  mutation CreateEmailBody(
    $campaignUuid: ID!
    $emailSubject: String
    $emailTitle: String
    $emailFor: String
    $emailText: String
    $active: Boolean
  ) {
    createEmailBody(
      input: {
        campaignUuid: $campaignUuid
        emailSubject: $emailSubject
        emailTitle: $emailTitle
        emailFor: $emailFor
        emailText: $emailText
        active: $active
      }
    ) {
      emailBody {
        uuid
        campaign {
          uuid
        }
        emailSubject
        emailTitle
        emailFor
        emailText
        active
      }
    }
  }
`;
export const UPDATE_EMAIL_BODY = gql`
  mutation UpdateEmailBody(
    $uuid: ID!
    $emailSubject: String
    $emailTitle: String
    $emailFor: String
    $emailText: String
    $active: Boolean
  ) {
    updateEmailBody(
      input: {
        uuid: $uuid
        emailSubject: $emailSubject
        emailTitle: $emailTitle
        emailFor: $emailFor
        emailText: $emailText
        active: $active
      }
    ) {
      emailBody {
        uuid
        campaign {
          uuid
        }
        emailSubject
        emailTitle
        emailFor
        emailText
        active
      }
    }
  }
`;

export const CREATE_PEER_TO_PEER = gql`
  mutation CreatePeerToPeer(
    $campaignUuid: ID!
    $destinationUrl: String
    $message: String
  ) {
    createPeerToPeer(
      input: {
        campaignUuid: $campaignUuid
        destinationUrl: $destinationUrl
        message: $message
      }
    ) {
      peerToPeer {
        uuid
        destinationUrl
        message
        campaign {
          uuid
        }
      }
    }
  }
`;

export const UPDATE_PEER_TO_PEER = gql`
  mutation UpdatePeerToPeer(
    $uuid: ID!
    $destinationUrl: String
    $message: String
  ) {
    updatePeerToPeer(
      input: { uuid: $uuid, destinationUrl: $destinationUrl, message: $message }
    ) {
      peerToPeer {
        uuid
        destinationUrl
        message
        campaign {
          uuid
        }
      }
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation UpdateGoal(
    $uuid: ID!
    $goalDate: String
    $title: String
    $message: String
  ) {
    updateGoal(
      input: {
        uuid: $uuid
        goalDate: $goalDate
        title: $title
        message: $message
      }
    ) {
      goal {
        uuid
        title
        message
        goalDate
        campaign {
          uuid
        }
      }
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation CreateGoal(
    $campaignUuid: ID!
    $goalDate: String!
    $title: String!
    $message: String!
  ) {
    createGoal(
      input: {
        campaignUuid: $campaignUuid
        goalDate: $goalDate
        title: $title
        message: $message
      }
    ) {
      goal {
        uuid
        title
        message
        goalDate
        campaign {
          uuid
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $campaignUrl: String!
    $title: String
    $defaultPaymentMethod: String!
    $defaultCheckoutCurrency: String!
    $minimumOneTimeDonation: Float!
    $minimumRecurringDonation: Float!
    $transactionCost: String!
  ) {
    updateCampaign(
      input: {
        uuid: $uuid
        campaignUrl: $campaignUrl
        title: $title
        defaultPaymentMethod: $defaultPaymentMethod
        defaultCheckoutCurrency: $defaultCheckoutCurrency
        minimumOneTimeDonation: $minimumOneTimeDonation
        minimumRecurringDonation: $minimumRecurringDonation
        transactionCost: $transactionCost
      }
    ) {
      campaign {
        uuid
        campaignUrl
        title
        defaultPaymentMethod
        minimumOneTimeDonation
        minimumRecurringDonation
        transactionCost
      }
    }
  }
`;

export const CAMPAIGN_SETTINGS_GENERAL = gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $campaignUrl: String
    $title: String
    $description: String
    $endDate: String
  ) {
    updateCampaign(
      input: {
        uuid: $uuid
        campaignUrl: $campaignUrl
        title: $title
        description: $description
        endDate: $endDate
      }
    ) {
      campaign {
        uuid
        campaignUrl
        title
        description
        endDate
      }
    }
  }
`;

export const CAMPAIGN_SETTINGS_PAYMENTS = gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $defaultPaymentMethod: String
    $defaultCheckoutCurrency: String
    $detectLocation: Boolean
    $minimumOneTimeDonation: Float
    $minimumRecurringDonation: Float
    $transactionCost: String
  ) {
    updateCampaign(
      input: {
        uuid: $uuid
        defaultPaymentMethod: $defaultPaymentMethod
        defaultCheckoutCurrency: $defaultCheckoutCurrency
        detectLocation: $detectLocation
        minimumOneTimeDonation: $minimumOneTimeDonation
        minimumRecurringDonation: $minimumRecurringDonation
        transactionCost: $transactionCost
      }
    ) {
      campaign {
        uuid
        defaultPaymentMethod
        defaultCheckoutCurrency
        detectLocation
        minimumOneTimeDonation
        minimumRecurringDonation
        transactionCost
      }
    }
  }
`;

export const CAMPAIGN_SETTINGS_SUPPORTER = gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $askForEmail: Boolean
    $askForPhoneNumber: Boolean
    $allowAnonymously: Boolean
    $acceptTermsCondition: Boolean
  ) {
    updateCampaign(
      input: {
        uuid: $uuid
        askForEmail: $askForEmail
        askForPhoneNumber: $askForPhoneNumber
        allowAnonymously: $allowAnonymously
        acceptTermsCondition: $acceptTermsCondition
      }
    ) {
      campaign {
        uuid
        askForEmail
        askForPhoneNumber
        allowAnonymously
        acceptTermsCondition
      }
    }
  }
`;

export const CAMPAIGN_SETTINGS_SUGGESTIONS = gql`
  mutation UpdateCampaign(
    $uuid: ID!
    $defaultSuggestedAmounts: [Int!]
    $defaultFrequency: String!
  ) {
    updateCampaign(
      input: {
        uuid: $uuid
        defaultFrequency: $defaultFrequency
        defaultSuggestedAmounts: $defaultSuggestedAmounts
      }
    ) {
      campaign {
        uuid
        defaultFrequency
        defaultSuggestedAmounts
      }
    }
  }
`;

export const UPDATE_SELECTED_TEMPLATE = gql`
  mutation UpdateSelectedTemplate($uuid: ID!, $template: String) {
    updateCampaign(input: { uuid: $uuid, template: $template }) {
      campaign {
        uuid
        template
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_TEMPLATE = gql`
  mutation UpdateCampaignTemplate(
    $uuid: ID!
    $name: String
    $template: String
    $active: Boolean
  ) {
    updateCampaignTemplate(
      input: { uuid: $uuid, name: $name, template: $template, active: $active }
    ) {
      campaignTemplate {
        uuid
        name
        template
        active
      }
    }
  }
`;
export const CREATE_CAMPAIGN_TEMPLATE = gql`
  mutation CreateCampaignTemplate(
    $campaignUuid: ID!
    $name: String
    $template: String
    $active: Boolean
  ) {
    createCampaignTemplate(
      input: {
        campaignUuid: $campaignUuid
        name: $name
        template: $template
        active: $active
      }
    ) {
      campaignTemplate {
        uuid
        name
        template
        active
      }
    }
  }
`;

export const GET_CAMPAIGN_WITH_DONATIONS = gql`
  query GetCampaign($uuid: ID!, $startDate: String!, $endDate: String!) {
    campaign(uuid: $uuid) {
      uuid
      title
      description
      campaignUrl
      defaultPaymentMethod
      defaultCheckoutCurrency
      detectLocation
      minimumOneTimeDonation
      minimumRecurringDonation
      transactionCost
      status
      endDate
      askForEmail
      askForPhoneNumber
      allowAnonymously
      acceptTermsCondition
      defaultFrequency
      defaultSuggestedAmounts
      raised
      recurringPlans
      totalP2pFundraiser
      p2pCollection
      peerToPeer {
        uuid
        destinationUrl
        message
      }
      goal {
        uuid
        goalDate
        title
        message
        amount
        currency
        goalDate
      }
      askPage {
        uuid
        title
        message
        socialLinks
      }
      thankYouPage {
        uuid
        message
        enableScreen
        typeOfScreen
      }
      emailSetting {
        uuid
        senderEmail
        senderName
        textColor
        headerTextColor
        footerTextColor
        socialLinks
      }
      emailBodies {
        nodes {
          uuid
          emailSubject
          emailTitle
          emailFor
          emailText
          active
        }
      }
      donationsParDateCount(startDate: $startDate, endDate: $endDate)
      archivedAt
      template
      campaignTemplates {
        nodes {
          uuid
          name
          template
          active
        }
      }
      createdAt
      raised
      endDate
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query GetCampaign($uuid: ID!) {
    campaign(uuid: $uuid) {
      uuid
      title
      description
      campaignUrl
      defaultPaymentMethod
      defaultCheckoutCurrency
      defaultSuggestedAmounts
      defaultFrequency
      detectLocation
      minimumOneTimeDonation
      minimumRecurringDonation
      transactionCost
      status
      endDate
      askForEmail
      askForPhoneNumber
      allowAnonymously
      acceptTermsCondition
      peerToPeer {
        uuid
        destinationUrl
        message
      }
      goal {
        uuid
        goalDate
        title
        message
        amount
        currency
        goalDate
      }
      askPage {
        uuid
        title
        message
        socialLinks
      }
      thankYouPage {
        uuid
        message
        enableScreen
        typeOfScreen
      }
      emailSetting {
        uuid
        senderEmail
        senderName
        textColor
        headerTextColor
        footerTextColor
        socialLinks
      }
      emailBodies {
        nodes {
          uuid
          emailSubject
          emailTitle
          emailFor
          emailText
          active
        }
      }
      archivedAt
      template
      campaignTemplates {
        nodes {
          uuid
          name
          template
          active
        }
      }
      createdAt
      raised
      endDate
    }
  }
`;

export const CREATE_ASK_PAGE = gql`
  mutation CreateAskPage(
    $campaignUuid: ID!
    $title: String!
    $message: String!
    $socialLinks: [String!]
  ) {
    createAskPage(
      input: {
        campaignUuid: $campaignUuid
        title: $title
        message: $message
        socialLinks: $socialLinks
      }
    ) {
      askPage {
        uuid
        title
        message
        socialLinks
        campaign {
          uuid
        }
      }
    }
  }
`;

export const UPDATE_ASK_PAGE = gql`
  mutation UpdateAskPage(
    $uuid: ID!
    $title: String
    $message: String
    $socialLinks: [String!]
    $image: Upload
  ) {
    updateAskPage(
      input: {
        uuid: $uuid
        title: $title
        message: $message
        socialLinks: $socialLinks
        image: $image
      }
    ) {
      askPage {
        uuid
        title
        message
        socialLinks
        image {
          url
        }
        logo {
          url
        }
        campaign {
          uuid
        }
      }
    }
  }
`;

export const CREATE_THANK_YOU_PAGE = gql`
  mutation CreateThankYouPage(
    $campaignUuid: ID!
    $message: String
    $enableScreen: Boolean
    $typeOfScreen: String
  ) {
    createThankYouPage(
      input: {
        campaignUuid: $campaignUuid
        message: $message
        enableScreen: $enableScreen
        typeOfScreen: $typeOfScreen
      }
    ) {
      thankYouPage {
        uuid
        message
        enableScreen
        typeOfScreen
        campaign {
          uuid
        }
      }
    }
  }
`;

export const UPDATE_THANK_YOU_PAGE = gql`
  mutation UpdateThankYouPage(
    $uuid: ID!
    $message: String
    $enableScreen: Boolean
    $typeOfScreen: String
  ) {
    updateThankYouPage(
      input: {
        uuid: $uuid
        message: $message
        enableScreen: $enableScreen
        typeOfScreen: $typeOfScreen
      }
    ) {
      thankYouPage {
        uuid
        message
        enableScreen
        typeOfScreen
        campaign {
          uuid
        }
      }
    }
  }
`;
export const UPDATE_CAMPAIGN_ARCHIVE = gql`
  mutation ArchiveCampaign($uuid: ID!, $isArchive: Boolean!) {
    archiveCampaign(input: { uuid: $uuid, isArchive: $isArchive }) {
      campaign {
        uuid
        archivedAt
      }
    }
  }
`;

export const ADD_CAMPAIGN_CLONE = gql`
  mutation CloneCampaign($uuid: ID!) {
    cloneCampaign(input: { uuid: $uuid }) {
      campaign {
        uuid
      }
    }
  }
`;

export const PUBLISH_CAMPAIGN = gql`
  mutation PublishCampaign($uuid: ID!) {
    publishCampaign(input: { uuid: $uuid }) {
      campaign {
        uuid
        status
      }
    }
  }
`;
export const UNPUBLISH_CAMPAIGN = gql`
  mutation UnPublishCampaign($uuid: ID!) {
    unpublishCampaign(input: { uuid: $uuid }) {
      campaign {
        uuid
        status
      }
    }
  }
`;
