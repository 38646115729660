import { Grid, Typography, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
// import data from "./data";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";


const blur =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg";
const light =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/light.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/down.svg";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  name: "",
  email: "",
  message: "",
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required("You must provide email"),
  })
  .required();

const Articles = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.help.data);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [displayCount, setDisplayCount] = useState(data.length);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, touchedFields } = formState;

  const handleSeeMoreClick = () => {
    setDisplayCount(data.length);
  };
  const handleSeeLessClick = () => {
    setDisplayCount(4);
  };

  return (
    <>
      <Grid className={classes.bkg} container id="faq"
      sx={{
        filter: themeMode === "dark" ? "" : "brightness(500%)",
      }}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundImage: `url('${light}'),url('${blur}')`,
            backgroundSize: "cover,cover",
            backgroundPosition: "center center,10% 50%",
            padding: {
              md: "80px 80px",
              sm: "80px 30px ",
              xs: "80px 15px",
            },
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: "center",
            justifyContent: "flex-start",
      
          }}
        >
          <Grid item md={7} sm={7} xs={12}>
            <Typography
              sx={{
                fontSize: { md: "30px", sm: "30px", xs: "20px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                fontWeight: themeMode === "dark" ? '600' : "200",
                fontFamily: "Stolzl",
              }}
            >
              Frequently Asked Questions
            </Typography>
            {data?.slice(0, displayCount)?.map((item, index) => (
              <ul className={classes.listItemStyle} key={index}>
                <li className={classes.listItemContentStyle}>
                  <span className={classes.bulletStyle}></span>
                  <Typography
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      fontWeight: themeMode === "dark" ? '600' : "200",
                      fontFamily: "Stolzl",
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    mt={{ md: 0, sm: 1, xs: 1 }}
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      fontWeight: themeMode === "dark" ? '400' : "200",
                      fontFamily: "Stolzl",
                    }}
                  >
                    {item.text}
                  </Typography>
                </li>
              </ul>
            ))}
            <Grid
              sx={{
                display: "flex",
                justifyContent: {
                  md: "flex-start",
                  sm: "flex-start",
                  xs: "center",
                },
              }}
            >
              {displayCount === 4 ? (
                <Button
                  disableRipple
                  sx={{ "&:hover": { backgroundColor: "transparent" } }}
                  onClick={handleSeeMoreClick}
                >
                  <Grid sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      mr={1}
                      sx={{
                        fontSize: "18px",
                        fontWeight: themeMode === "dark" ? '700' : "200",
                        color: "#FFFF",
                        textTransform: "capitalize",
                        fontFamily: "Stolzl",
                      }}
                    >
                      See More
                    </Typography>
                    <img src={down} alt="" />
                  </Grid>
                </Button>
              ) : (
                <Button
                  disableRipple
                  sx={{ "&:hover": { backgroundColor: "transparent" } }}
                  onClick={handleSeeLessClick}
                >
                  <Grid sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      mr={1}
                      sx={{
                        fontSize: "18px",
                        fontWeight: themeMode === "dark" ? '700' : "200",
                        color: "#FFFF",
                        textTransform: "capitalize",
                        fontFamily: "Stolzl",
                      }}
                    >
                      See Less
                    </Typography>
                    <img
                      src={down}
                      alt=""
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </Grid>
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            md={5}
            sm={5}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
            }}
          >
            <Grid
              my={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
              }}
            >
              <Typography
                sx={{
                  textAlign: { md: "right", sm: "right", xs: "center" },
                  width: { md: "60%", sm: "60%", xs: "80%" },
                  fontSize: "14px",
                  fontWeight: themeMode === "dark" ? '400' : "100",
                  fontFamily: "Stolzl",
                }}
              >
                Unable to find solution in articles? Try contacting our
                technical team here.
              </Typography>
              <Grid
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(90deg, #669600 0%, #89CA00 133.68%)",
                    borderRadius: "30px",
                    padding: "8px 15px",
                    width: "fit-content",
                    filter: themeMode === "dark" ? "" : "brightness(45%)",
                  }}
                  onClick={() =>
                    window.open(
                      "mailto:email@example.com?subject=Help Centre&body="
                    )
                  }
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color:themeMode==="dark"? "#FFFF":"#030303",
                      textTransform: "capitalize",
                      textWrap: "nowrap",
                    }}
                  >
                    Email Support
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid my={{ md: 16, sm: 16, xs: 5 }} sx={{ width: "75%" }}>
              <Typography
                sx={{
                  fontSize: { md: "18px", sm: "15px", xs: "15px" },
                  fontWeight: themeMode === "dark" ? '600' : "200",
                  textAlign: "right",
                  fontFamily: "Stolzl",
                }}
              >
                We’d love to hear back from you.
              </Typography>
              <form
                // className="w-full"
                onSubmit={handleSubmit((_data) => console.info(_data))}
              >
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    alignItems: {
                      md: "flex-end",
                      sm: "flex-end",
                      xs: "center",
                    },
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        mt={3}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Name"
                        variant="outlined"
                        id="outlined-basic"
                        // value={elementName}
                        // onChange={handleElementName}
                        sx={{
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        mt={3}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Email *"
                        variant="outlined"
                        id="outlined-basic"
                        required
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        // value={elementName}
                        // onChange={handleElementName}
                        sx={{
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        multiline
                        minRows={5}
                        mt={3}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Write message here"
                        variant="outlined"
                        id="outlined-basic"
                        name="CampaignName"
                        // value={elementName}
                        // onChange={handleElementName}
                        sx={{
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="message"
                    control={control}
                  />
                </Grid>
                <Grid
                  mt={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    sx={{
                      background:
                        "linear-gradient(90deg, #669600 0%, #89CA00 133.68%)",
                      borderRadius: "30px",
                      padding: "8px 25px",
                      width: "fit-content",
                      filter: themeMode === "dark" ? "" : "brightness(45%)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        textTransform: "capitalize",
                        textWrap: "nowrap",
                      }}
                    >
                      Send
                    </Typography>
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Articles;
