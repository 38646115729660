import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'

const cross = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg'
const crossDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/cross.svg'

const SocialProof = ({
    accentColor,
    textColor,
    backgroundColor,
    borderSize,
    borderRadius,
    borderColor,
    shadow,
    label,
    labelColor,
    btnColor,
    btnBorderSize,
    btnBorderRadius,
    btnBorderColor,
    enable_emoji,
    enable_location,
    themeMode
}) => {

    return (
        <>
            <div style={{
                border: themeMode==="dark" ? `${borderSize ? borderSize : '1px'} solid ${borderColor ? borderColor : '#FFF'}` : `${borderSize ? borderSize : '1px'} solid ${borderColor ? borderColor : '#000'}`,
                borderRadius: borderRadius  ? `${borderRadius}px` : '10px', 
                backgroundColor: backgroundColor,
                boxShadow: shadow ? '1px 1px 5px #FFF' : 'none',
                //bottom: socialBtnEnable ? '2px' : '0',
                position: 'absolute',
                bottom: '0',
                display: "flex",
                justifyContent: 'center',
                zIndex: 1000,
                width:"100%",
            }}>
                <div style={{
                    width: '75%',
                    padding: '8px 15px',
                    // backgroundColor: `${backgroundColor}`,
                    // borderTopLeftRadius: `${socialBorderRadius}px`,
                    // borderBottomLeftRadius: `${socialBorderRadius}px`,
                    // border: `${socialBorderSize}px solid #757575`,
                    // border: `1px solid ${socialBorderColor}`,
                    // display: 'flex',
                    alignItems: 'center',
                    justifyContent:"left",
                    //color: `${socialTxtColor}`,
                    '&:hover': {
                        filter: 'brightness(120%)',
                    },
                }}>
                    <p style={{ color: accentColor, fontWeigt: "600", textAlign: "left" }}>
                        Hiroki.S donated $100 {enable_emoji && <span>👍</span>}
                    </p>
                    <p style={{fontSize:"12px",textAlign:"left", color: textColor ? textColor : "#FFF"}}>
                        to Where it is needed most
                    </p>
                    { enable_location &&
                        <p style={{ fontSize: "12px", textAlign: "left", fontWeigt: "200",color: textColor ? textColor : "grey" }}>
                            New York, United States
                        </p>
                    }
                </div>

                <div style={{
                    width: '39%',
                    textTransform: 'capitalize',
                    // borderTopRightRadius: `${socialBorderRadius}px`,
                    // borderBottomRightRadius: `${socialBorderRadius}px`,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems:"center"
                }}>
                    <button 
                        style={{
                            border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '1'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : '1px solid transparent' ,
                            borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '20px',
                            padding: '8px 15px',
                            backgroundColor: btnColor ? btnColor : 'transparent', 
                            color: labelColor ? labelColor : '#FFF',  
                            height:"fit-content",
                            '&:hover': {
                                backgroundColor: "#689900",
                            },
                        }}
                        id="proceed-btn"
                    >
                        {label ? label : 'Donate'}
                    </button>
                    <button 
                        style={{
                            marginLeft:"10px",
                            '&:hover': {
                                backgroundColor: "transparent",
                            },
                        }}
                        id="closed-btn"
                    >
                        <img src={themeMode === 'dark' ? cross : crossDark} alt='' />
                    </button>
                </div>
            </div>
        </>
    )
}
export default SocialProof
