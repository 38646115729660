import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import { useStyles } from "./useStyles";
import { AuthService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserFromLocalStorage } from "../../services/localStorageService";
import { options, notifications } from "./SideBarList";
import { logout } from "../../store/authSlice";
import Dashboard from "./Dashboard/Dashboard";
import MainSearchBar from "../MainLayout/Dashboard/MainSearch";
import CreateCampaign from "./Create Campaign/CreateCampaign";
import CampaignPop from "./Dashboard/Campaign/CampaignPop/CampaignPop";
import CreateElement from "./Dashboard/Elements/CreateElement/CreateElement";
import ThemeModeSwitch from "../../utils/themeModeSwitch";
import AddWithdrawalMethod from "./Dashboard/Payment/AddWithdrawalMethod/AddWithdrawalMethod";
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const up = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/up.svg";
const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const upWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upWhite.svg";
const upBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upBlack.svg";
const downBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg";
const downWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/downWhite.svg";
const logo = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logo.png";
const logoLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";
const bell = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/bell.png";
const question = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/question.svg";
const settingsIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/settings.svg";
const logoutIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logout.svg";
const dp = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png";
const verify = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/verify.png";
// const optionDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/optionsBlack.svg";
// const option = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Navbar/hamBurgLight.png";
const lightQuestion = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/help.svg";
const lightBell = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/bell.svg";

const MainLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  const [showTab, setShowTab] = useState(null);

  const [selectedOption, setSelectedOption] = useState(1);
  const [onClose, setOnClose] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newCampaign, setNewCampaign] = useState(false);
  const [newElementPopup, setNewElementPopup] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [refetchElementRows, setRefetchElementRows] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [popup, setPopup] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notif, setNotif] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const themeMode = useSelector((state) => state.themeMode.mode);
  useEffect(() => {
    const isAuthenticated = !!getUserFromLocalStorage();
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (popup) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [popup]);

  useEffect(() => {
    if (withdrawPopup) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [withdrawPopup]);

  const showPopup = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };
  const showWithdraw = () => {
    setWithdrawPopup(true);
  };
  const closeWithdraw = () => {
    setWithdrawPopup(false);
  };

  useEffect(() => {
    if (newCampaign) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [newCampaign]);

  useEffect(() => {
    if (newElementPopup) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [newElementPopup]);

  const showDialog = () => {
    setNewCampaign(true);
  };

  const closeDialog = () => {
    setNewCampaign(false);
  };

  const showElementDialog = () => {
    setNewElementPopup(true);
  };

  const closeElementDialog = () => {
    setNewElementPopup(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  const handleLogin = () => {
    window.location.href = "/sign_in";
  };

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.error("Sign-out error:", error);
      navigate("/");
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [notifBoxVisible, setNotifBoxVisible] = useState(false);

  const openNotif = (event) => {
    setNotifBoxVisible(event.currentTarget);
  };

  const closeNotif = () => {
    setNotifBoxVisible(null);
  };

  const handleTabs = (index) => {
    showTab === index ? setShowTab(null) : setShowTab(index);
  };

  return (
    <>
      {newCampaign ? (
        <CreateCampaign
          onClose={closeDialog}
          setSelectedOption={(n) => {
            setSelectedOption(n);
          }}
        />
      ) : (
        ""
      )}
      {newElementPopup ? (
        <CreateElement
          onClose={closeElementDialog}
          selectedElement={selectedElement}
          setRefetchElementRows={setRefetchElementRows}
        />
      ) : (
        ""
      )}
      {popup ? <CampaignPop onClose={closePopup} /> : ""}
      {withdrawPopup ? <AddWithdrawalMethod onClose={closeWithdraw} /> : ""}

      <Grid
        container
        className={classes.bkg}
        sx={{
          padding: { md: "15px", sm: "15px", xs: "5px" },
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
        }}
      >
        <Grid
          className={classes.column}
          item
          md={2.4}
          sm={3}
          xs={3.5}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            zIndex: "10",
          }}
        >
          <Grid
            className={classes.p10col}
            sx={{
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px red"
                  : "8px 8px 14px 0px #00000024",
              zIndex: "5",
            }}
          >
            <Button
              disableRipple
              className={classes.logo}
              href="/dashboard/insights"
              sx={{
                padding: { md: "25px", sm: "0px", xs: "0px" },
              }}
            >
              {themeMode === "dark" ? (
                <img src={logo} alt="" />
              ) : (
                <img src={logoLight} alt="" />
              )}
            </Button>
            <Button
              className={classes.create}
              onClick={showDialog}
              sx={{
                fontSize: { md: "16px", sm: "12px", xs: "9px" },
                margin: {
                  md: "10px 10px 10px 0px",
                  sm: "10px",
                  xs: "10px 5px",
                },
                width: { md: "100%", sm: "75%", xs: "95%" },
              }}
            >
              + New Campaign
            </Button>

            <Grid className={classes.scroll}>
              {options?.map((item, index) => (
                <Grid key={index}>
                  <Button
                    className={classes.options}
                    component={Link}
                    to={item.link}
                    //onClick={() => setSelectedOption(item.id)}
                    onClick={() => {
                      setSelectedOption(
                        selectedOption === item.id ? null : item.id
                      );
                      handleTabs(index);
                    }}
                    // onMouseEnter={() => {setShowTab(index)}}
                    // onMouseLeave={() => {setShowTab(null)}}
                    sx={{
                      border:
                        currentPath === item.link ||
                        (item.tabs &&
                          item.tabs.some((tab) => currentPath === tab.link))
                          ? "1.5px solid #DCE000"
                          : "1.5px solid transparent",

                      margin: { md: "10px", sm: "10px", xs: "10px 5px" },
                      width: { md: "95%", sm: "75%", xs: "95%" },
                      justifyContent:
                        currentPath === item.link ? "center" : "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      className={classes.centerAlign}
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          fontSize: { md: "16px", sm: "12px", xs: "9px" },
                          textTransform: "capitalize",
                          fontWeight: "600",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {themeMode === "dark" ? (
                          <img src={item.img} alt="" className={classes.mr20} />
                        ) : (
                          <img
                            src={item.light}
                            alt=""
                            className={classes.mr20}
                          />
                        )}
                        {item.name}
                      </Typography>
                      {item.tabs ? (
                        <img
                          src={
                            themeMode === "dark" && showTab === index
                              ? upWhite
                              : themeMode === "dark" && showTab !== index
                              ? downWhite
                              : themeMode === "light" && showTab === index
                              ? upBlack
                              : themeMode === "light" && showTab !== index
                              ? downBlack
                              : ""
                          }
                          alt=""
                          style={{ marginLeft: "15px" }}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Button>
                  {
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {item.tabs?.map((childItem, childIndex) => (
                        <Grid
                          key={childIndex}
                          sx={{
                            display: showTab === index ? "flex" : "none",
                            justifyContent: "center",
                            borderBottom:
                              themeMode === "dark" &&
                              childIndex !== item.tabs?.length - 1
                                ? "1px solid #FFF"
                                : themeMode === "light" &&
                                  childIndex !== item.tabs?.length - 1
                                ? "1px solid #0F0F0F"
                                : "none",
                            width: "85%",
                          }}
                        >
                          <Button
                            // className={classes.options}
                            component={Link}
                            to={childItem.link}
                            //onClick={() => setSelectedOption(item.id)}

                            onClick={() =>
                              setSelectedOption(
                                selectedOption === childItem.id
                                  ? null
                                  : childItem.id
                              )
                            }
                            sx={{
                              borderRadius: "0px",
                              borderBottom:
                                currentPath === childItem.link
                                  ? "3px solid #ADFF00"
                                  : "3px solid transparent",

                              margin: {
                                md: "10px 0px 0px 0px",
                                sm: "10px",
                                xs: "10px 5px",
                              },
                              width: { md: "70%", sm: "75%", xs: "95%" },
                              justifyContent:
                                currentPath === childItem.link
                                  ? "center"
                                  : "flex-start",
                              justifyContent: "center",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                          >
                            <Grid
                              className={classes.centerAlign}
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    md: "16px",
                                    sm: "12px",
                                    xs: "9px",
                                  },
                                  textTransform: "capitalize",
                                  fontWeight: "600",
                                  color:
                                    themeMode === "dark" ? "#FFFF" : "#030303",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {themeMode === "dark" ? (
                                  <img
                                    src={childItem.img}
                                    alt=""
                                    className={classes.mr20}
                                  />
                                ) : (
                                  <img
                                    src={childItem.light}
                                    alt=""
                                    className={classes.mr20}
                                  />
                                )}
                                {childItem.name}
                              </Typography>
                            </Grid>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  }
                </Grid>
              ))}
            </Grid>
            
          </Grid>
        </Grid>

        <Grid
          className={classes.p0flex}
          item
          container
          md={9.5}
          sm={12}
          xs={12}
        >
          {/* =======================================NavBar======================================== */}
          <Grid
            className={classes.fit}
            container
            sx={{
              flexDirection: { md: "row", sm: "row", xs: "row" },
              justifyContent: { md: "space-between", sm: "space-evenly" },
              alignItems: { md: "center", sm: "center", xs: "center" },
            }}
          >
            {/* ===================================Filter=============================================== */}
            <Grid item md={5} sm={5} xs={4} className={classes.justifyStart}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                className={classes.alignCenter}
                sx={{
                  display: {
                    md: "none",
                    sm: "flex",
                  },
                  
               
                }}
              >
                <Typography sx={{ color: themeMode === "dark" ? "#FFF" : "#030303", fontSize:"25px"}}>
                  <MenuIcon sx={{ height: "45px", width: "45px" }} />
                </Typography>
                {/* <img src={themeMode === "dark" ? option : optionDark} alt="" /> */}
              </IconButton>

              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Grid
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={classes.presentation}
                  sx={{
                    backgroundColor: themeMode === "dark" ? "#0b0b0b" : "#FFF",
                  }}
                >
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableElevation
                    className={classes.buttonContainer}
                    href="/dashboard/insights"
                    sx={{
                      backgroundColor: themeMode === "dark" ? "#0b0b0b" : "#FFF",
                    }}
                  >
                    <Grid className={classes.mt10}>
                      <Grid md={2} className={classes.home}>
                        {themeMode === "dark" ? (
                          <img src={logo} alt="" />
                        ) : (
                          <img src={logoLight} alt="" />
                        )}
                      </Grid>
                    </Grid>
                  </Button>
                  <Button
                    className={classes.create}
                    onClick={showDialog}
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "16px" },
                      margin: { md: "10px", sm: "10px", xs: "10px 5px" },
                      width: { md: "75%", sm: "85%", xs: "85%" },
                    }}
                  >
                    + New Campaign
                  </Button>
                  
                  <Grid className={classes.scroll}>
                    {options?.map((item, index) => (
                      <Grid key={index}>
                        <Button
                          className={classes.options}
                          component={Link}
                          to={item.link}
                          onClick={() => {
                            setSelectedOption(
                              selectedOption === item.id ? null : item.id
                            );
                            handleTabs(index);
                          }}
                          sx={{
                            border:
                              currentPath === item.link ||
                              (item.tabs &&
                                item.tabs.some(
                                  (tab) => currentPath === tab.link
                                ))
                                ? "1.5px solid #DCE000"
                                : "1.5px solid transparent",

                            margin: { md: "10px", sm: "10px", xs: "10px 5px" },
                            width: { md: "95%", sm: "85%", xs: "95%" },
                            justifyContent:
                              currentPath === item.link
                                ? "center"
                                : "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid
                            className={classes.centerAlign}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <Typography
                              sx={{
                                fontSize: { md: "16px", sm: "14px", xs: "14px" },
                                textTransform: "capitalize",
                                fontWeight: "600",
                                color:
                                  themeMode === "dark" ? "#FFFF" : "#030303",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {themeMode === "dark" ? (
                                <img
                                  src={item.img}
                                  alt=""
                                  className={classes.mr20}
                                />
                              ) : (
                                <img
                                  src={item.light}
                                  alt=""
                                  className={classes.mr20}
                                />
                              )}
                              {item.name}
                            </Typography>
                            {item.tabs ? (
                              <img
                                src={
                                  themeMode === "dark" && showTab === index
                                    ? upWhite
                                    : themeMode === "dark" && showTab !== index
                                    ? downWhite
                                    : themeMode === "light" && showTab === index
                                    ? upBlack
                                    : themeMode === "light" && showTab !== index
                                    ? downBlack
                                    : ""
                                }
                                alt=""
                                style={{ marginLeft: "15px" }}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Button>
                        {
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {item.tabs?.map((childItem, childIndex) => (
                              <Grid
                                key={childIndex}
                                sx={{
                                  display: showTab === index ? "flex" : "none",
                                  justifyContent: "center",
                                  borderBottom:
                                    themeMode === "dark" &&
                                    childIndex !== item.tabs?.length - 1
                                      ? "1px solid #FFF"
                                      : themeMode === "light" &&
                                        childIndex !== item.tabs?.length - 1
                                      ? "1px solid #0F0F0F"
                                      : "none",
                                  width: "85%",
                                }}
                              >
                                <Button
                                  // className={classes.options}
                                  component={Link}
                                  to={childItem.link}
                                  //onClick={() => setSelectedOption(item.id)}

                                  onClick={() =>
                                    setSelectedOption(
                                      selectedOption === childItem.id
                                        ? null
                                        : childItem.id
                                    )
                                  }
                                  sx={{
                                    borderRadius: "0px",
                                    borderBottom:
                                      currentPath === childItem.link
                                        ? "3px solid #ADFF00"
                                        : "3px solid transparent",

                                    margin: {
                                      md: "10px 0px 0px 0px",
                                      sm: "10px",
                                      xs: "10px 5px",
                                    },
                                    width: { md: "70%", sm: "75%", xs: "95%" },
                                    justifyContent:
                                      currentPath === childItem.link
                                        ? "center"
                                        : "flex-start",
                                    justifyContent: "center",
                                    "&:hover": {
                                      background: "transparent",
                                    },
                                  }}
                                >
                                  <Grid
                                    className={classes.centerAlign}
                                    sx={{ justifyContent: "space-between" }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: {
                                          md: "16px",
                                          sm: "12px",
                                          xs: "9px",
                                        },
                                        textTransform: "capitalize",
                                        fontWeight: "600",
                                        color:
                                          themeMode === "dark"
                                            ? "#FFFF"
                                            : "#030303",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {themeMode === "dark" ? (
                                        <img
                                          src={childItem.img}
                                          alt=""
                                          className={classes.mr20}
                                        />
                                      ) : (
                                        <img
                                          src={childItem.light}
                                          alt=""
                                          className={classes.mr20}
                                        />
                                      )}
                                      {childItem.name}
                                    </Typography>
                                  </Grid>
                                </Button>
                              </Grid>
                            ))}
                          </Grid>
                        }
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Drawer>
              <Grid
                sx={{
                  display: {
                    md: "flex",
                    sm: "flex",
                    xs: "none",
                  },
                }}
              >
                <Grid sx={{ md: "flex", sm: "flex", xs: "flex" }}>
                  <MainSearchBar />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={7} sm={7} xs={8} className={classes.flexEnd}>
              <button
                className={classes.btn}
                style={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  padding: "0px 10px",
                  "&:hover": {
                    transform: "scale(1.1)",
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    border:
                      themeMode === "dark"
                        ? "1px solid transparent"
                        : "1px solid #9CCA3C",
                  },
                }}
                onClick={() => window.location.href = '/dashboard/help'}
              >
                <HelpOutlineIcon 
                  sx={{
                    fontSize: { md: "30px" },
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    "&:hover": {
                      color: themeMode === "dark" ? "#94D906" :"#619000"
                    }
                  }} />
                
               
              </button>
              
              <button
                className={classes.btn}
                onClick={openNotif}
                style={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  padding: "0px 10px",
                  "&:hover":{
                    transform:"scale(1.1)",
                    backgroundColor:themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    border:
                      themeMode === "dark"
                        ? "1px solid transparent"
                        : "1px solid #9CCA3C",
                  },
                }}
              >
                <NotificationsNoneIcon 
                  sx={{
                    fontSize:{md:"30px"},
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    "&:hover":{
                      color: themeMode === "dark" ? "#94D906" : "#619000"
                    }
                  }}/>
                
              </button>

              <Menu
                anchorEl={notifBoxVisible}
                open={notifBoxVisible}
                onClose={closeNotif}
                PaperComponent={Paper}
                anchorOrigin={{
                  vertical: "bottom", 
                  horizontal: "center", 
                }}
                transformOrigin={{
                  vertical: "top", 
                  horizontal: "center", 
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: { md: "90% !important", sm: "100% !important" },
                  padding: "10px 18px",
                  "& .MuiPaper-root": {
                    backgroundColor: themeMode === "dark" ? "#0d0d0dED" :"#ECECEC",
                    boxShadow:
                      themeMode === 'dark'
                        ? '0px 0px 0px red'
                        : '0px 8px 5px 0px #00000040',
                    border: "1px solid #FFFFFF",
                    color: themeMode==="dark"? "#FFF":"#030303",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "flex-end",
                    borderRadius: "15px",
                    padding: "10px 30px",
                  },
                }}
              >
                <Grid sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    Notifications
                  </Typography>
                  <Grid
                    item
                    my={2}
                    md={6}
                    sm={6}
                    xs={9}
                    className={classes.overflowx}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <button
                      disableRipple
                      item
                      className={classes.btnList}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid transparent",
                        width: "fit-content",
                        "&:hover": {
                          background: "transparent",
                          color: "#FFB800",
                        },
                      }}
                    >
                      <Typography
                        className={classes.btnText}
                        sx={{
                          fontSize: { md: "14px", sm: "14px", xs: "14px" },
                        }}
                      >
                        All
                      </Typography>
                    </button>
                    <button
                      disableRipple
                      item
                      className={classes.btnList}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid transparent",
                        width: "fit-content",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                    >
                      <Typography
                        mx={{ md: 0, sm: 0, xs: 1 }}
                        className={classes.btnText}
                        sx={{
                          fontSize: { md: "14px", sm: "14px", xs: "14px" },
                        }}
                      >
                        Read
                      </Typography>
                    </button>
                  </Grid>
                  <Grid
                    mt={1}
                    sx={{
                      overflowY: "auto",
                      maxHeight: "45vh",
                      overflowY: "auto",
                      maxHeight: "45vh",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        width: 0,
                        background: "transparent",
                      },
                    }}
                  >
                    {notifications?.map((item, index) => (
                      <Grid
                        key={index}
                        py={1}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          borderBottom: themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item md={9} sm={9} xs={9}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              textAlign: "left",
                            }}
                          >
                            {item.text}{" "}
                            <span
                              style={{ color: themeMode === "dark" ? "#FFAA00" :"#bf8000", fontWeight: "700" }}
                            >
                              {item.campaign}
                            </span>{" "}
                            campaign
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              display: !item.read ? "flex" : "none",
                              width: "11px",
                              height: "11px",
                              backgroundColor: themeMode === "dark" ? "#FFAA00" : "#bf8000",
                              borderRadius: "50%",
                            }}
                          ></Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Menu>

              <Button
                className={classes.btn2}
                disableElevation
                onClick={handleMenuOpen}
                sx={{
                  "&:hover": {
                    border: "1px solid #94D906",
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid className={classes.row} container>
                  <Grid className={classes.dpHolder} item sx={{width:{md:"55px",sm:"55px",xs:"45px"},height:{md:"55px",sm:"55px",xs:"45px"}}}>
                    <img src={dp} alt=""  />
                    <Grid
                      className={classes.colCenter}
                      sx={{
                        display: {
                          md: "flex",
                          sm: "flex",
                          xs: "none",
                        },
                      }}
                    >
                      <Grid
                        className={classes.profile}
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                        }}
                      >
                        {" "}
                        {user && user?.first_name && user?.last_name
                          ? user?.first_name + " " + user?.last_name
                          : ""}
                      </Grid>
                      <Grid
                        className={classes.profileDull}
                        sx={{
                          flexWrap:"nowrap"
                        }}
                      >
                        <Typography sx={{   color: themeMode === "dark" ? "#9F9F9F" : "#0F0F0F",textWrap:"nowrap",fontSize: "11px",
                            textTransform: "capitalize",
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            display:"flex",justifyContent:"flex-start",flexDirection:"row",alignItems:"center"
                        }}>
                            Verified Foundation
                            <img
                              src={verify}
                              alt=""
                              style={{ margin: "0px 3px",width:"10px",height:"10px" }}
                            />
                        </Typography>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item ml={1}>
                    {anchorEl ? (
                      <img
                        src={up}
                        alt=""
                        style={{ width: "10px", height: "10px" }}
                      />
                    ) : (
                      <img
                        src={down}
                        alt=""
                        style={{ width: "10px", height: "10px" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Button>
              <Menu
                className={classes.menu}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperComponent={Paper}
                sx={{
                  "& .MuiPaper-root": {
                    width: { md: "17%", sm: "30%", xs: "70%" },
                    backgroundImage: "none",
                    backgroundColor:
                      themeMode === "dark" ? "#0a0a0a" : "#dbd7d7",
                  },
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                      backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",
                      
                    },
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      borderBottom: "1px solid #FFF",
                      padding: "15px 15px",
                      justifyContent: "space-between",
                    }}
                  >
                    Theme: {themeMode === "dark" ? "Dark" : "Light"}
                    <ThemeModeSwitch />
                  </Typography>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleMenuClose}
                  component={Link}
                  to="/home"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                      backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",
                      color: themeMode === "dark" ? "#689900" : "#8ED003",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      borderBottom: "1px solid #FFF",
                      padding: "15px 15px",
                    }}
                  >
                    <HomeIcon style={{ margin: "0px 15px 0px 0px" }} />
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleMenuClose}
                  component={Link}
                  to="/dashboard/settings"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                      backgroundColor: themeMode === "dark" ? "#2222" : "#FFF" ,
                      color: themeMode === "dark" ? "#689900" :"#8ED003",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      borderBottom: "1px solid #FFF",
                      padding: "15px 15px",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    <SettingsIcon style={{ margin: "0px 15px 0px 0px" }} />
                    Settings
                  </Typography>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                      color: user ? "#FF0000 !important" : "#689900",
                      backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",
                    
                    },
                  }}
                  onClick={() => {
                    user ? handleLogout() : handleLogin();
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                      padding: "15px 15px",
                    }}
                  >
                    <img
                      src={logoutIcon}
                      alt=""
                      style={{ margin: "0px 15px 0px 0px" }}
                    />
                    {user ? "Logout" : "Login"}
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>

            <Grid
              px={1}
              my={2}
              className={classes.fullWidth}
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
              }}
            >
              <MainSearchBar />
            </Grid>
          </Grid>

          <Grid className={classes.all}>
            <Dashboard
              selectedOption={selectedOption}
              showPopupCallback={showPopup}
              newElementPopup={newElementPopup}
              withdrawPopup={withdrawPopup}
              setWithdrawPopup={setWithdrawPopup}
              setSelectedOption={(n) => {
                setSelectedOption(n);
              }}
              setNewElementPopup={setNewElementPopup}
              setSelectedElement={setSelectedElement}
              refetchElementRows={refetchElementRows}
              setRefetchElementRows={setRefetchElementRows}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default MainLayout;
