


export const getUserFromLocalStorage = () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      return user;
    } catch (error) {
      console.error('Error parsing user from local storage:', error);
      return null;
    }
};