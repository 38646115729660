import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  flex:{
    display:"flex",
    fontFamily:"Stolzl"
  },
  bkg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "700px",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  leftCol: {
    display: "flex",
    justifyContent: "left",
    flexDirection: "column",
    alignItems: "left",
    paddingLeft: "15px",
  },
  borderBtm: {
    borderBottom: "7px solid #7AB402",
  },
  btn: {
    color: "#FFF",
    background: "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
    borderRadius: "30px",
    flexWrap: "nowrap",
    textTransform: "capitalize",
    fontWeight: "600",
    padding: "5px 15px",
    border: "0.5px solid transparent",
    "&:hover": {
      background: "#081a01",
      border: "0.5px solid #689900",
    },
  },
  fw700:{
    fontWeight:'700',
    flexWrap:"nowrap",
    fontFamily:"Stolzl"
  }
}));
