import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  LinearProgress,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import ChartComponent from "./ChartComponent";
import Paper from "@mui/material/Paper";
import { useStyles } from "./useStyles";
import { adjustNegativeMonths } from "../../../../../utils/globalFunctions.js";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const date =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";

const Graph = ({ donationsByDates, dateRange, setDateRange }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  if (!donationsByDates || Object.keys(donationsByDates)?.length === 0) {
    const currentDate = new Date();
    donationsByDates = { [dayjs(currentDate)?.format("YYYY-MM-DD")]: 0 };
  }

  return (
    <Grid
      sx={{
        backgroundColor: themeMode === "dark" ? "#899499" : "#ECECEC",
        boxShadow:
          themeMode === "dark"
            ? "0px 0px 0px blue"
            : "4px 4px 14px 0px #00000024",
        borderRadius: "15px",
        height: "fit-content",
        padding: "25px",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
          flexDirection: { md: "row", sm: "row", xs: "row" },
        }}
      >
        <Grid item md={3} sm={3} sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: { md: "16px", sm: "16px", xs: "20px" },
              fontWeight: "600",
              textAlign: { xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
            }}
          >
            Donation <span style={{ color: "#8ED003" }}> in USD</span>
          </Typography>
        </Grid>
      </Grid>

      <ChartComponent donationsByDates={donationsByDates} chartType={"line"} />
    </Grid>
  );
};
export default Graph;
