import { Link } from "react-router-dom";
import { Grid, Typography, Divider, Button } from "@mui/material";
import { community, company, resources, product, footLinks } from "./links";
import { useStyles } from "./useStyles";

const logo =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Navbar/logo.png";

const Footer = ({
  bkg,
  talkBtn,
  btnText,
  btnHover,
  btnBorder,
  textHover,
  brightness,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      className={classes.body}
      sx={{
        backgroundImage: `url('${bkg}')`,
        justifyContent: { md: "start", sm: "center", xs: "center" },
        padding: {
          md: "40px 70px 60px",
          sm: "40px 70px 60px",
          xs: "40px 10px 60px",
        },
        alignItems: { md: "start", sm: "center", xs: "center" },
        filter: brightness,
      }}
    >
      <Grid item lg={6} md={4} sm={12} xs={12}>
        <Grid sx={{ textAlign: { md: "left", sm: "center", xs: "center" } }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: { md: "left", sm: "center", xs: "center" },
            }}
          >
            <img src={logo} alt="" />
          </Grid>
          <Typography
            my={5}
            className={classes.fs40}
            sx={{ fontFamily: "Stolzl" }}
          >
            Raise Your <br />
            Donation Now
          </Typography>
          <Button
            href="/signup"
            className={classes.button}
            sx={{
              background: talkBtn,
              color: btnText,
              "&:hover": {
                background: btnHover,
                border: btnBorder,
                color: textHover,
              },
            }}
          >
            Register with us
          </Button>
        </Grid>
      </Grid>

      <Grid item lg={6} md={8}>
        <Grid
          container
          md={12}
          columnGap={4}
          className={classes.flex}
          sx={{ justifyContent: { md: "left", sm: "left", xs: "center" } }}
        >
          <Grid sx={{ my: 3 }}>
            <Typography className={classes.head}>Product</Typography>
            {product?.map((user) => (
              <li key={user.id} className={classes.list}>
                <Link className={classes.link} to={user.link}>
                  {user.name}
                </Link>
              </li>
            ))}
          </Grid>
          <Grid sx={{ my: 3 }}>
            <Typography className={classes.head}>Resources</Typography>
            {resources?.map((user) => (
              <li key={user.id} className={classes.list}>
                <Link className={classes.link} to={user.link}>
                  {user.name}
                </Link>
              </li>
            ))}
          </Grid>
          <Grid sx={{ my: 3 }}>
            <Typography className={classes.head}>Community</Typography>
            {community?.map((user) => (
              <li key={user.id} className={classes.list}>
                <Link className={classes.link} to={user.link}>
                  {user.name}
                </Link>
              </li>
            ))}
          </Grid>
          <Grid sx={{ my: 3 }}>
            <Typography className={classes.head}>Company</Typography>
            {company?.map((user) => (
              <li key={user.id} className={classes.list}>
                <Link className={classes.link} to={user.link}>
                  {user.name}
                </Link>
              </li>
            ))}
          </Grid>
          <Grid sx={{display:{md:"none",sm:"none",xs:"flex"}}}>
            <Grid>
              <Link to="/terms-and-condition">
                <Typography>Terms & Conditions</Typography>
              </Link>
            </Grid>
            <Divider className={classes.divider} color="#FFF" sx={{ mx: 2, }} />
            <Grid>
              <Link to="/privacy-policy">
                <Typography>Privacy Policy</Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid
            className={classes.row}
            md={12}
            sm={12}
            sx={{
              justifyContent: {
                md: "right",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Grid sx={{display:{md:"flex",sm:"flex",xs:"none"}}}>
              <Link to="/terms-and-condition">
                <Typography>Terms and Conditions</Typography>
              </Link>
            </Grid>
            <Divider className={classes.divider} color="#FFF" sx={{ mx: 2, display: { md: "flex", sm: "flex", xs: "none" } }} />
            <Grid sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}>
              <Link to="/privacy-policy">
                <Typography>Privacy Policy</Typography>
              </Link>
            </Grid>
            <Divider className={classes.divider} color="#FFF" sx={{ mx: 2, display: { md: "flex", sm: "flex", xs: "none" } }} />
            
            <Grid className={classes.fitRow}>
              {footLinks?.map((user) => (
                <Link to={user.link} key={user.id}>
                  <img
                    src={user.img}
                    alt={user.name}
                    className={classes.my15}
                  />
                </Link>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Footer;
