const p2p = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/p2p.svg";
const checkout = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/checkout.svg";
const settings = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/settings.svg";
const email = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/email.svg";
const publish = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/publish.svg";
const overview = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/overview.svg";
const p2pDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/p2pDark.svg";
const checkoutDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/checkoutDark.svg";
const settingsDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/settingsDark.svg";
const emailDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/emailDark.svg";
const publishDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/publishDark.svg";
const overviewDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/overviewDark.svg";

export const OverviewList = [
    {
        id:0,
        img:overview,
        text:"Overview",
        link: "/dashboard/campaign-overview", 
        light:overviewDark,
        option:13,
    },
    {
        id:1,
        img:p2p,
        light:p2pDark,
        text:"P2P",
        link: "/dashboard/peer-to-peer", 
        option:12,
    },
    {
        id:2,
        img:email,
        light:emailDark,
        text:"Emails",
        link: "/dashboard/campaign-email",
        option:14
    },
        {
        id:3,
        img:checkout,
        light:checkoutDark,
        text:"Checkout Modal",
        link: "/dashboard/checkout-modal", 
        option:15
    },
    {
        id:4,
        img:settings,
        light:settingsDark,
        text:"Settings",
        link: "/dashboard/campaign-settings", 
        option:16
    },
    {
        id:5,
        img:publish,
        light:publishDark,
        text:"Publish Campaign",
        link: "/dashboard/publish-campaign", 
    }

];
