import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { pagesInfo, GENERAL_SEARCH } from "./dataSearch.jsx";
import { MenuItem } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
  },
  highlighted: {
    backgroundColor: "#3f3f17",
  },
  optionsContainer: {
    position: "absolute",
    maxHeight: "300px", 
    overflowY: "auto", 
    backgroundColor: "#0F0F0F",
    zIndex: "999",
    borderRadius: "10px"
  },
  optionsItem: {
    paddingBottom: "10px",
    minWidth: '300px',
  },
}));

const MainSearchBar = () => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const themeMode = useSelector(state => state.themeMode.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { data: searchData, loading, fetchMore, refetch } = useQuery(GENERAL_SEARCH, {
    variables: {
      searchQuery: query,
    },
    onCompleted: (result) => {
      if(result){
        handleSearchData(result.generalSearch)
      }
    },
  });

  const handleSearchData = (searchDataList) => {
    if(searchDataList){
      if (!query || query.trim() === "") {
        setOptions([]);
        return;
      }
      const filterPageList = pagesInfo?.filter(page => searchDataList?.find(option => page.title.toLowerCase() === option.modelName.toLowerCase()))
      ?.map(page => {
        const matchingOption = searchDataList?.find(option => page.title.toLowerCase() === option.modelName.toLowerCase());
        const matchedLines = [];
        Object.values(matchingOption?.attributes).forEach(value => {
            if (typeof value === 'string' && value.toLowerCase().includes(query?.toLowerCase())) {
                matchedLines.push(value);
            }
        });
    
        const concatenatedLines = matchedLines?.join('\n');
    
        return {
          ...page,
          uuid: matchingOption ? (matchingOption.modelName !== "Campaign" ? matchingOption.campaignUuid : matchingOption.attributes?.uuid) : null,
          content: concatenatedLines
        };
      });
      setOptions(filterPageList);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
  };

  const getMatchingText = (text) => {
    if (!text || query.length < 1) return null;
  
    const regex = new RegExp(query, "gi");
    const match = regex.exec(text);
  
    if (!match) return null;
  
    const index = match.index;
    const start = Math.max(0, index - 30); // Start 30 characters before the match
    const end = index + query.length + 30; // End 30 characters after the match
  
    const beforeMatch = <span style={{ fontSize: 'smaller' }}>{text.substring(start, index)}</span>;
    const matchedText = <span className={classes.highlighted}>{match}</span>;
    const afterMatch = <span style={{ fontSize: 'smaller' }}>{text.substring(index + query.length, end)}</span>;

    return (
      <>
          {beforeMatch}
          {matchedText}
          {afterMatch}
      </>
    );
  };

  return (
    <Grid sx={{ width: { xs: "100%" } } }>
      <TextField
        style={{
          borderRadius: "50px",
          border: "2px solid #689900",
          padding: "0px 10px 0px 30px",
          backgroundColor: "transparent",
          backgroundColor: themeMode==="dark"?"#2e3322":"#FFFF" 
        }}
        variant="standard"
        fullWidth
        placeholder="Search Here"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton onClick={handleSearch}>
              <SearchIcon
                sx={{
                  color: "#9CCA3C",
                  borderRadius: "50px",
                  "&:hover": {
                    color: "#689900",
                  },
                }}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          style: {
            color:themeMode==="dark"? "#FFF":"#689900",
            "&:hover":{
              color:themeMode==="dark"? "#FFF":"#689900",
            },
          }
        }}
        className={classes.searchInput}
        value={query}
        onChange={handleSearch}
        onClick={handleOpenMenu}
      />
      {anchorEl && (
        <MenuList className={classes.optionsContainer}
          ref={menuRef}
          autoFocusItem={Boolean(anchorEl)}
        >
          {options?.map(option => (
            <div key={option.id} className={classes.optionsItem}>
              <MenuItem component={Link} to={option.type === "specific" ? `${option.link}/${option.uuid}` : option.link}>
                {option.title}
              </MenuItem >
              <MenuItem component={Link} to={option.type === "specific" ? `${option.link}/${option.uuid}` : option.link}>
                {getMatchingText(option.content)}
              </MenuItem>
            </div>
          ))}
        </MenuList>
      )}
    </Grid>
  );
};

export default MainSearchBar;
