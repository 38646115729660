import { makeStyles } from '@mui/styles'

const blur = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg'
const light = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/light.svg'
const bkg = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/planbkg.svg'
const ramadan = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramadan.svg'

export const useStyles = makeStyles(theme => ({
  bkg: {
    backgroundImage: `url('${bkg}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  bkgImg: {
    backgroundImage: `url('${light}'),url('${blur}')`,
    backgroundSize: 'cover,cover',
    backgroundPosition: 'center center,10% 50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  columnCenter: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  upper700: {
    textTransform: 'uppercase',
    fontWeight: '700', fontFamily: "Stolzl"
  },
  stolzl: {
    fontFamily: "Stolzl"
  },
  color: {
    color: '#F6BE25'
  },
  jCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  full: {
    width: '100%'
  },
  overflowx: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto'
  },
  btnList: {
    border: '1px solid #5C5C5C',
    borderRadius: '30px',
    width: '100%',
    '&:hover': {
      border: '1px solid #FFFFF'
    }
  },
  btnText: {
    fontWeight: '600',
    textTransform: 'capitalize',
    textWrap: 'nowrap',
    width: 'fit-content'
  },
  ramadan: {
    backgroundImage: `url('${ramadan}')`,
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderRadius: '30px',
    width: '100%',
    height: '70vh'
  },
  captionBox: {
    backgroundColor: '#000000CC',
    width: '100%',
    borderRadius: '0 0 30px 30px'
  },
  captionHead: {
    textTransform: 'uppercase',
    fontWeight: '700',
    width: '100%'
  },
  caption2: {
    textTransform: 'uppercase',
    fontWeight: '500',
    width: '100%'
  },
  client: {
    backgroundColor: "#160609",
    padding: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexHolder: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  gridHolder: {
    display: "grid",
    justifyItems: "center",
    padding: "10px 0px"
  },
  imgHolder: {
    display: "flex",
    alignItems: "center",
  },
  spaceBtwn: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  box: {
    
  },
  toper: {
    fontSize: '14px', fontFamily: "Stolzl"
  },
  title: {
    fontSize: '22px',  fontFamily: "Stolzl"
  },
  p70: {
    padding: '70px 0px'
  },
  text: {
    fontSize: '12px', fontFamily: "Stolzl"
  },
  btn: {
    
    borderRadius: '30px',
    padding: '8px 15px',
    
  }
}))
