import React from "react";
import { Button, Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { SupportCard } from "./SupportCard";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'

import dollarLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/dollarLight.svg'
import dateLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/dateLight.svg'
import historyLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/historyLight.svg'
import paymentLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/paymentLight.svg'
import pauseLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/pauseLight.svg'
import pauseDark from '../../../../../../assets/FeatureLightTheme/SupporterManagement/pauseDark.svg'
import recieptLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/receiptsLight.svg'

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const dollar = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/dollar.svg";
const date = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/date.svg";
const payment = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/payment.svg";
const pause = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/pause.svg";
const receipts = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/receipts.svg";
const history = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/history.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const dollar_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/dollar_black.svg";
const calendar_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/calendar_black.svg";
const payment_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/payment_black.svg";
const pause_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/pause_black.svg";
const receipt_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/receipt_black.svg";
const history_black = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/history_black.svg";

const SubSection = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#FFF" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize:themeMode==="dark"? "140% auto":"100% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%":"left 0%,right bottom",
            padding: { md: "100px 130px", sm: "90px 40px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid mb={5}>
            <Typography sx={{ fontFamily: "Stolzl", fontSize: "50px", fontWeight: "700", textTransform: "uppercase", color: themeMode === "dark" ? "#8ED003" :"#484B6A"}}>Benefits</Typography>
          </Grid>
          <SupportCard
            icon={themeMode==="dark"?dollar:dollarLight}
            title={"Adjust donation amount"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode === "dark" ? date:dateLight}
            title={"Change Donation Date"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode === "dark" ? payment:paymentLight}
            title={"Edit Payment Methods"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode === "dark" ? pauseDark:pauseLight}
            title={"pause Plan"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode === "dark" ? receipts:recieptLight}
            title={"product TRAINing"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode === "dark" ? history:historyLight}
            title={"Donation History"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
        </Grid>
      </Grid>
    );
  
};
export default SubSection;
