import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import currencyLight from '../../../../../../assets/FeatureLightTheme/PaymentEco/currencyLight.svg'
import mapLight from '../../../../../../assets/FeatureLightTheme/PaymentEco/mapLightGreen.svg'
import mapDark from '../../../../../../assets/FeatureLightTheme/PaymentEco/mapDark.svg'

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const payment4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/PaymentEcosystem/payment4.svg";
const payment5 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/PaymentEcosystem/payment5.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_payment5 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment5.svg";
const light_payment6 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment6.svg";


const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E3E4F0" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize: themeMode === "dark" ? "140% auto" : "100% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "right bottom,left 0%",
            padding: { md: "100px 150px", sm: "90px 40px", xs: "70px 20px" },
            //padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(145deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                justifyContent: "start",
              }}
            >
              <Grid item md={8} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    color: themeMode === "dark" ? "#fff" : "#030303"
                  }}
                >
                  130+ currencies accepted
                </Typography>
                <Grid item md={8}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      fontFamily:"Stolzl"
                    }}
                  >
                    We accept all the world’s currencies — plus provide
                    suggested amounts adjusted to purchasing power versus a
                    straight exchange rate.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={5} mt={{ md: 0, sm: 3, xs: 3 }}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  top: { md: "20%", sm: "0%", xs: "0%" },
                  transform: { md: "translateY(0%)", sm: "translateY(0%)" },
                }}
              >
                <img src={themeMode==="dark"?payment4:currencyLight} alt="" />
              </Grid>
            </Grid>
          </Grid>


          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            mt={{ md: 25, sm: 20, xs: 20 }}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "-10px -13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(325deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                justifyContent: "start",
              }}
            >
              <Grid item md={7} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    color: themeMode === "dark" ? "#fff" : "#030303"
                  }}
                >
                  Automatic regional currency suggestions{" "}
                </Typography>
                <Grid item md={8}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      color: themeMode === "dark" ? "#fff" : "#030303",
                      fontFamily: "Stolzl"
                    }}
                  >
                    Experience personalized giving with automatic currency
                    suggestions based on your location. Easily customize your
                    preferred currency for a seamless and flexible donation
                    process.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={6}
                mt={{ md: 0, sm: 3,xs:3 }}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  top: { md: "20%", sm: "0%", xs: "0%" },
                  transform: { md: "translateX(10%) scale(1.2)", sm: "translateX(0%)" },
                }}
              >
                {/* <img src={themeMode==="dark"?payment5:mapLight} alt="" /> */}
                <img src={themeMode === "light" ? mapDark : mapLight} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        
      </Grid>
    );
  
};
export default SubSection;
