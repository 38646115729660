import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/Recurring/bkg.svg'

const main2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/main2.svg";
const main = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/main.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";
const light_recurr1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_recurr1.svg";

const HeroSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 70px 10px",
            sm: "130px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark" ? `url('${bkg}')` : `url('${bkgLight}')`,
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={9}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
              fontFamily: "Stolzl",
            }}
          >
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              {" "}
              Convert Supporters into
            </span>
            <span
              className={
                themeMode === "dark" ? classes.color : classes.colorLight
              }
              sx={{ fontWeight: "700" }}
            >
              {" "}
              FREQUENT Contributors{" "}
            </span>
          </Typography>
          <Typography
            sx={{ fontFamily: "Stolzl", }}
            className={
              themeMode === "dark"
                ? "text-white mb-16 mt-8 md:w-1/2"
                : "text-black mb-16 mt-8 md:w-1/2"
            }
          >
            Utilize Fundraise Up to access all the essentials for expanding
            enduring support and boosting recurring revenue.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default HeroSection;
