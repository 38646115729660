import { Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

const gifts = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/gifts.png";

export const PostDonationUpsell = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div className="flex flex-col h-full">
      <div>
        <Typography className="pre-don-text py-4 text-center">
          Thank You{" "}
        </Typography>
        <hr />
        <div className="flex justify-center text-center py-6">
          <img src={gifts} alt="gift" />
          <Typography className="w-1/3 pre-don-text2 py-4 text-center">
            Thank you for donation of $60
          </Typography>
        </div>
      </div>
      <div className="px-8">
        <div
          className="px-8 py-4"
          style={{ backgroundColor: "#A4B200", borderRadius: "10px" }}
        >
          <Typography className="pre-don-text2 pb-4 text-center text-white">
            Monthly donations ensure Continuous support of our mission. Would
            you like to become a monthly supporter starting Apr 25?{" "}
          </Typography>
          <Typography className="pre-don-text2 pb-1 text-center text-white">
            <span className="text-xl"> $12</span>/ month <br /> or Another
            amount
          </Typography>

          <div className="mt-4">
            <Button
              variant="outlined"
              className="w-full py-3"
              sx={{ border: "1px solid #FFFFFF", borderRadius: "10px" }}
            >
              <span className={"pre-donation-btn text-white"}>
                Yes, I’ll give monthly{" "}
              </span>
            </Button>
          </div>
          <div className="mt-5">
            <Button
              variant="outlined"
              className="w-full py-3"
              sx={{ border: "1px solid #FFFFFF", borderRadius: "10px" }}
            >
              <span className={"pre-donation-btn text-white"}>
                Not, at this time
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
