import "./useStyle.css"

export const GroupBtn = ({groupBtn, title, labelColor, btnSize, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, shadow}) =>{
    return(
      <div style={{ position: 'absolute' }}>
        <div style={{ fontWeight: 600 }}>
          {title ? title : 'Support Us'}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxWidth: "500px", 
          margin: '0 auto',
        }}>
          {groupBtn?.map((item, index) => (
            <div key={index}>
              <button
                class="moneyBtn"
                id="proceed-btn"
                style={{ 
                  color: labelColor ? labelColor : '#FFF', 
                  backgroundColor:  btnColor ? btnColor : '#A4B200', 
                  borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '10px', 
                  border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '2'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : '2px solid transparent' ,
                  fontSize: `${btnSize}px`,
                  width: "20vh",
                  height: "fit-content", 
                  padding: "15px 20px",
                  margin: "10px",
                  fontWeight: "700",
                }}
                value = {item.pay}
              >
                ${item.pay}
              </button>
            </div>
          ))}
        </div>
      </div>
    )
}