import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import GroupsIcon from "@mui/icons-material/Groups";

const Email = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Email.png";
const funds = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/funds.png";
const TwitterX = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/twitter.png";
const Facebook = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/fb.png";
const LinkedIn = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/linked.png";
const Mail = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/emails.png";
const Instagram = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Instagram.png";
const YouTube = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/YouTube.png";
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/card.png";


export const FundraisersStarted = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between w-full items-center">
          <Typography className="email-preview-title">
            Fundraiser started preview
          </Typography>
          <Button
            style={{
              border:
                themeMode === "dark"
                  ? "2px solid rgba(255, 255, 255, 1)"
                  : "2px solid rgba(0, 0, 0, 1)",
              borderRadius: "17px",
              color: themeMode === "dark" ? "white" : "black",
            }}
            className="email-preview-title"
          >
            <img src={Email} alt="email" />
            <Typography className="email-preview-title ml-2">
              Send test email
            </Typography>
          </Button>
        </div>
        <div>
          <div
            className={
              themeMode === "dark"
                ? "mt-8 email-preview-box p-6"
                : "mt-8 email-preview-box-light p-6"
            }
          >
            {" "}
            <Typography className="email-preview-subtitle">
              {`From: Imam Connect Welfare Grant <noreply@acceleraise.com> `}
            </Typography>
            <Typography className="email-preview-subtitle mt-4">
              {`Reply-To: Imam Connect Welfare Grant <admin@imamconnect.com>`}
            </Typography>
            <Typography className="email-preview-subtitle mt-4">
              {`Subject: Thankyou  for your help!`}
            </Typography>
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-center">
            <img src={funds} alt="fund" className="w-full" />
          </div>
          <div className="flex justify-center">
            <div className="lg:w-2/3">
              <Typography className="mt-8 text-center email-preview-title2">
                You've Started Fundraising! 👋{" "}
              </Typography>
              <Typography className="mt-8 email-preview-subtitle">
                Thank you for joining our efforts by starting a fundraiser
                today!{" "}
              </Typography>
              <Typography className="my-8 email-preview-subtitle">
                Your fundraiser is now available to be shared, and anyone
                visiting it via direct link or social media will be shown your
                goal and personal message as part of making their donation.
              </Typography>
              <div className="lg:flex justify-between lg:pace-x-4">
                <div
                  className="border-1 p-4"
                  style={{
                    border:
                      themeMode === "dark"
                        ? "1px solid #FFFFFF"
                        : "1px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  <Typography className="funds-text1">GOAL</Typography>
                  <Typography className="funds-text2 py-4">
                    £3,000,000.00
                  </Typography>
                  <Typography className="funds-text3">
                    £2,500,000.00 raised{" "}
                  </Typography>
                </div>
                <div
                  className="border-1 p-4"
                  style={{
                    border:
                      themeMode === "dark"
                        ? "1px solid #FFFFFF"
                        : "1px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  <Typography className="funds-text1">END DATE</Typography>
                  <Typography className="funds-text2 py-4">
                    Apr 2, 2024{" "}
                  </Typography>
                  <Typography className="funds-text3">15 days left</Typography>
                </div>{" "}
              </div>
              <div
                className="mt-8 p-8"
                style={{ backgroundColor: "#689BFF", borderRadius: "25px" }}
              >
                <Typography className="text-white funds-text1 text-center">
                  SHARE THIS LINK WITH YOUR COMMUNITY
                </Typography>

                <div
                  className="p-4 my-4"
                  style={{ backgroundColor: "white", borderRadius: "20px" }}
                >
                  <Typography className="text-black text-center">
                    http://hrmeigns.acceleraise.com/-/NXQWYBLG{" "}
                  </Typography>
                </div>
              </div>
              <div
                className="py-6 mt-4"
                style={{
                  borderTop: "1px solid #000000",
                  borderBottom: "1px solid #000000",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#46A8F4",
                    color: "white",
                    borderRadius: "18px",
                    opacity: 1,
                  }}
                  className="p-4 w-full"
                >
                  <Typography className="email-btn-txt">
                    {" "}
                    Open my fundraiser{" "}
                  </Typography>
                </Button>{" "}
                <div className="flex space-x-12 mt-4">
                  <div
                    className="p-5 px-10"
                    style={{ backgroundColor: "#FBAFFD", borderRadius: "50%" }}
                  ></div>{" "}
                  <div>
                    <Typography className="funds-text1 ">
                      Principal Infrastructure Planner{" "}
                    </Typography>
                    <Typography className="funds-text3 ">
                      <GroupsIcon /> 0 members
                    </Typography>
                  </div>{" "}
                </div>
              </div>
              <div className="mt-8">
                <Typography className=" funds-text1 text-center">
                  MY TEAM'S PROGRESS{" "}
                </Typography>
                <div className="flex justify-between mt-4">
                  <Typography className=" funds-text3 text-center w-full flex justify-start">
                    15 days left{" "}
                  </Typography>
                  <Typography className=" funds-text3 text-center flex justify-end w-full">
                    £3,000,000.00 goal{" "}
                  </Typography>
                </div>
                <div
                  className="p-2 px-8"
                  style={{ backgroundColor: "#689BFF", borderRadius: "27px" }}
                ></div>{" "}
                <Typography className=" funds-text1 text-center pt-2">
                  INVITE NEW MEMBERS USING THIS LINK{" "}
                </Typography>
                <div
                  className="p-4 my-4 mt-16"
                  style={{
                    border:
                      themeMode === "dark"
                        ? "1px solid white"
                        : "1px solid #000000",
                    borderRadius: "20px",
                  }}
                >
                  <Typography className="text-black text-center ">
                    http://hrmeigns.acceleraise.com/-/NXQWYBLG{" "}
                  </Typography>
                </div>
                <div className="flex space-x-4 justify-center">
                  <div
                    style={{ backgroundColor: "white", borderRadius: "50%" }}
                    className="p-4"
                  >
                    <img src={TwitterX} alt="twitter" />
                  </div>
                  <div
                    style={{ backgroundColor: "white", borderRadius: "50%" }}
                    className="p-4"
                  >
                    <img src={Facebook} alt="twitter" />
                  </div>
                  <div
                    style={{ backgroundColor: "white", borderRadius: "50%" }}
                    className="p-4"
                  >
                    <img src={LinkedIn} alt="twitter" />
                  </div>
                  <div
                    style={{ backgroundColor: "white", borderRadius: "50%" }}
                    className="p-4"
                  >
                    <img src={Mail} alt="twitter" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex justify-center mt-12 "
          style={{
            borderTop:
              themeMode === "dark" ? "2px solid #FFFFFF" : "1px solid black",
          }}
        >
          <Typography className="email-preview-subtitle text-center mt-4">
            Imam Connect Welfare Grant is a registered CIC in the UK.{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
};
