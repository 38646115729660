export const data = [
  {
    id: 1,
    name: "Status",
    value: "Successful",
  },
  {
    id: 2,
    name: "Donor",
    value: "JayTar",
  },
  {
    id: 3,
    name: "Campaign",
    value: "Help Gaza",
  },
  {
    id: 4,
    name: "Donation Date",
    value: "11 Dec 2023",
  },
  {
    id: 5,
    name: "Frequency",
    value: "One-Time",
  },
  {
    id: 6,
    name: "Payment Method",
    value: "Credit/Debit Card",
  },
];
export const fees = [
  {
    id: 1,
    name: "Donation Amount",
    value: "Rs. 5000",
  },
  {
    id: 2,
    name: "Platform Fee",
    value: "Rs. 280",
  },
  {
    id: 3,
    name: "Payment Processing Fee",
    value: "Rs. 210",
  },
  {
    id: 4,
    name: "Payout Amount",
    value: "$ 15.96",
  },
  {
    id: 5,
    name: "Payment Processor",
    value: "Stripe",
  },
  {
    id: 6,
    name: "Fee Covered",
    value: "Covered",
  },
  {
    id: 7,
    name: "Donation Amount",
    value: "Rs. 5000",
  },
];

export const insight = [
  {
    id: 1,
    name: "IP Address",
    value: "73.72.11.19",
  },
  {
    id: 2,
    name: "IP Geolocation",
    value: "USA",
  },
  {
    id: 3,
    name: "Browser",
    value: "Safari",
  },
  {
    id: 4,
    name: "Device",
    value: "Mobile",
  },
  {
    id: 5,
    name: "Suggested Cover Fees",
    value: "Used",
  },
  {
    id: 6,
    name: "Suggested Amount",
    value: "Not Used",
  },
  
];
export const donor = [
  {
    id: 1,
    name: "Name",
    value: "JayTar",
  },
  {
    id: 2,
    name: "Email",
    value: "jaytar@gmail.com",
  },
  {
    id: 3,
    name: "Phone",
    value: "0312 1234567",
  },
  {
    id: 4,
    name: "Mailing Address",
    value: "87th St.,\n BridgeView, USA",
  },
];

export const comments = [];

export const emails = [
  {
    id: 1,
    sent: "Feb 12, 2021, 12:25 PM",
    subject: "Thank you for the support. We are getting closer to our goal.",
  },
];
