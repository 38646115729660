import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { ArrowRightAlt } from "@mui/icons-material";
import { OverviewList } from "../overviewList";
import "react-quill/dist/quill.snow.css";
import Quill from "react-quill";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PEER_TO_PEER,
  UPDATE_PEER_TO_PEER,
  CREATE_GOAL,
  UPDATE_GOAL,
  GET_CAMPAIGN,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useStyles } from "./useStyles";
import P2PPreviewDialog from "./Preview/P2PPreviewDialog";

const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";

const MyTextField = ({ textMsg, handleTextChange }) => {
  const [text, setText] = useState(textMsg);
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  };
  useEffect(() => {
    if (textMsg) {
      setText(textMsg);
    }
  }, [textMsg]);

  return (
    <Grid>
      <Quill
        value={text}
        placeholder={text}
        onChange={(value) => {
          handleTextChange(value);
          setText(value);
        }}
        modules={modules}
        formats={formats}
        theme="snow"
        sx={{ backgroundColor: "#0D0D0D", borderRadius: "10px" }}
      />
    </Grid>
  );
};

const PeerToPeer = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [mode, setMode] = useState("General");
  const [type, setType] = useState(1);
  const [peerObj, setPeerObj] = useState({
    campaignUuid: uuid,
    uuid: null,
    destinationUrl: "",
    message: "",
    campaignTitle: "",
  });
  const [goalObj, setGoalObj] = useState({
    campaignUuid: uuid,
    uuid: null,
    goalDate: "",
    campaignTitle: "",
    message: "",
  });
  const [selectedGoalDuration, setSelectedGoalDuration] = useState(null);
  const { data: campaignData } = useQuery(GET_CAMPAIGN, {
    variables: { uuid },
    onCompleted: (result) => {
      if (result?.campaign) {
        setPeerObj((prevObj) => ({
          ...prevObj,
          uuid: result?.campaign?.peerToPeer?.uuid,
          destinationUrl: result?.campaign?.peerToPeer?.destinationUrl,
          message: result?.campaign?.peerToPeer?.message,
          campaignTitle: result?.campaign?.title,
        }));
      }
      if (result?.campaign?.goal?.uuid) {
        setGoalObj((prevObj) => ({
          ...prevObj,
          uuid: result.campaign.goal.uuid,
          goalDate: result.campaign.goal.goalDate,
          campaignTitle: result.campaign.goal.title,
          message: result.campaign.goal.message,
        }));
        let selected_Goal_Duration = null;
        switch (result.campaign.goal.goalDate) {
          case "1 Month":
            selected_Goal_Duration = 1;
            break;
          case "3 Months":
            selected_Goal_Duration = 2;
            break;
          case "6 Months":
            selected_Goal_Duration = 3;
            break;
          default:
            selected_Goal_Duration = null;
            break;
        }
        setSelectedGoalDuration(selected_Goal_Duration);
      }
    },
  });
  const [CreatePeerToPeer, { loading: peerLoading, error: peerError }] =
    useMutation(CREATE_PEER_TO_PEER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("your request was successful!"));
      },
    });
  const [
    UpdatePeerToPeer,
    { loading: updatePeerLoading, error: updatePeerError },
  ] = useMutation(UPDATE_PEER_TO_PEER, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const [CreateGoal, { loading: goalLoading, error: goalError }] = useMutation(
    CREATE_GOAL,
    {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("your request was successful!"));
      },
    }
  );
  const [UpdateGoal, { loading: updateGoalLoading, error: updateGoalError }] =
    useMutation(UPDATE_GOAL, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("your request was successful!"));
      },
    });
  const handleSave = async (event) => {
    event.preventDefault();
    if (mode === "General") {
      if (!peerObj?.uuid) {
        const variables = {
          campaignUuid: uuid,
          destinationUrl: peerObj?.destinationUrl,
          message: peerObj?.message,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreatePeerToPeer({ variables });
          setPeerObj((prevObj) => ({
            ...prevObj,
            uuid: result?.data?.createPeerToPeer?.peerToPeer?.uuid,
            destinationUrl:
              result?.data?.createPeerToPeer?.peerToPeer?.destinationUrl,
            message: result?.data?.createPeerToPeer?.peerToPeer?.message,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (peerObj?.uuid) {
        const variables = {
          uuid: peerObj?.uuid,
          destinationUrl: peerObj?.destinationUrl,
          message: peerObj?.message,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdatePeerToPeer({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    } else if (mode === "Goals") {
      if (!goalObj.uuid) {
        const variables = {
          campaignUuid: uuid,
          goalDate: goalObj?.goalDate,
          title: goalObj?.title,
          message: goalObj?.message,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreateGoal({ variables });
          setGoalObj((prevObj) => ({
            ...prevObj,
            uuid: result?.data?.createGoal?.goal?.uuid,
            goalDate: result?.data?.createGoal?.goal?.goalDate,
            title: result?.data?.createGoal?.goal?.title,
            message: result?.data?.createGoal?.goal?.message,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (goalObj.uuid) {
        const variables = {
          uuid: goalObj?.uuid,
          goalDate: goalObj?.goalDate,
          title: goalObj?.title,
          message: goalObj?.message,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdateGoal({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    }
  };
  const GoalMode = () => {
    setMode("Goals");
  };
  const GeneralMode = () => {
    setMode("General");
  };

  const handleDurationChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGoalDuration(selectedValue);
    let goalDate = "";
    switch (selectedValue) {
      case 1:
        goalDate = "1 Month";
        break;
      case 2:
        goalDate = "3 Months";
        break;
      case 3:
        goalDate = "6 Months";
        break;
      default:
        goalDate = "";
        break;
    }
    setGoalObj((prevObj) => ({
      ...prevObj,
      goalDate: goalDate,
    }));
  };
  console.log("data", campaignData);
  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>
              <Grid className={classes.fullWidth}>
                <Button
                  className={classes.transButton}
                  href="/dashboard/campaigns"
                  sx={{
                    margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                  }}
                >
                  <Grid className={classes.flexCenter}>
                    <img
                      src={themeMode === "dark" ? heart : heartDark}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "9px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      Campaigns
                    </Typography>
                  </Grid>
                </Button>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  fontSize: { md: "40px", sm: "40px", xs: "26px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                }}
              >
                {peerObj?.campaignTitle?.length > 15
                  ? `${peerObj.campaignTitle?.substring(0, 15)}...`
                  : peerObj?.campaignTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll   */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList?.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${peerObj?.campaignTitle}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 1
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 1
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                {/* <Grid 
                    sx={{
                      display:{md:"flex",sm:"flex",xs:"flex"},
                      flexDirection:"row",
                      width:{md:"100%",sm:"fit-content",xs:"100%"},
                      padding:"0px",
                      transform:{md:"scale(1)",sm:"scale(0.9)"}
                    }}> */}

                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 1
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 1 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
                {/* </Grid> */}
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid key={`arrow-${index}`} 
                  sx={{ 
                    display: "flex", 
                    alignItems: "center", 
                    transform: {md:"scale(1.3)",sm:"scale(1)"} ,
                    color:themeMode==="dark"? "#FFFF":"#030303"
                  }}>
                    <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${peerObj?.campaignTitle}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 1
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 1
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 1
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 1 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>

        <Grid
          className={classes.fullWidth}
          sx={{
            justifyContent: { md: "left", sm: "left", xs: "center" },
          }}
        >
          <Button
            className={classes.modeButton}
            disableRipple
            onClick={GeneralMode}
            sx={{
              background:
                mode === "General" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 10px",
              },
              color:
                themeMode === "light" && mode !== "General"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "14px" } }}
              >
                General
              </Typography>
            </Grid>
          </Button>
          <Button
            className={classes.modeButton}
            onClick={GoalMode}
            disableRipple
            sx={{
              background:
                mode === "Goals" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Goals" ? "#030303" : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "14px" } }}
              >
                Goals
              </Typography>
            </Grid>
          </Button>
        </Grid>

        <Grid className={classes.rowStart} item md={12}></Grid>

        {mode === "General" ? (
          <Grid
            my={3}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Typography
              className={classes.fs16}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Manage the options that should be configured at the campaign level
              to engage donors with P2P fundraising.{" "}
            </Typography>
            <Typography className={classes.url} mt={4}>
              Donor Destination URL
              {/* <img src={question} alt="" /> */}
            </Typography>
            <Typography className={classes.fs16}>
              Landing page on your website where P2P Fundraiser links start the
              donation checkout.{" "}
            </Typography>
            <Grid item md={5} sm={9}>
              <FormControl className={classes.fullWidth}>
                <TextField
                  className={classes.input}
                  required
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  value={peerObj?.destinationUrl || ""}
                  onChange={(event) =>
                    setPeerObj({
                      ...peerObj,
                      destinationUrl: event.target.value,
                    })
                  }
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Typography className={classes.url} mt={4}>
              Default Personal Message
              {/* <img src={question} alt="" /> */}
            </Typography>
            <Typography className={classes.fs16}>
              Prefilled based message for anyone starting a fundraiser. It can
              be changer while they're starting their fundraiser, or afterwards.{" "}
            </Typography>
            <Grid item md={7} py={2}>
              <FormControl className={classes.fullWidth}>
                <MyTextField
                  textMsg={peerObj?.message || "Write message here"}
                  handleTextChange={(value) =>
                    setPeerObj((prevObj) => ({ ...prevObj, message: value }))
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : mode === "Goals" ? (
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Typography className={classes.fs16}>
              Set information to be displayed on a P2P Fundraiser's page in
              Donor Portal when their goal is reached.
            </Typography>

            <Typography className={classes.url} mt={4}>
              Default goal dates
              {/* <img src={question} alt="" /> */}
            </Typography>
            <Grid item md={3} sm={4} xs={12}>
              <FormControl className={classes.fullWidth}>
                <TextField
                  className={classes.select}
                  select
                  variant="outlined"
                  value={selectedGoalDuration || ""}
                  onChange={handleDurationChange}
                  sx={{
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          Width: "100%",
                          background: themeMode === "dark" ? "#000" : "#ffff",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    value={1}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      backgroundColor: themeMode === "dark" ? "#000" : "#ffff",
                    }}
                  >
                    1 Month
                  </MenuItem>

                  <MenuItem
                    className={classes.menuItem}
                    value={2}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      backgroundColor: themeMode === "dark" ? "#000" : "#ffff",
                    }}
                  >
                    3 Months
                  </MenuItem>

                  <MenuItem
                    className={classes.menuItem}
                    value={3}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      backgroundColor: themeMode === "dark" ? "#000" : "#ffff",
                    }}
                  >
                    6 Months
                  </MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Typography className={classes.url} mt={4}>
              Goal reached title
              {/* <img src={question} alt="" /> */}
            </Typography>
            <Grid item md={5} sm={9} xs={12}>
              <FormControl className={classes.fullWidth}>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  placeholder="Write title here"
                  value={goalObj?.title || ""}
                  onChange={(event) =>
                    setGoalObj({
                      ...goalObj,
                      title: event.target.value,
                    })
                  }
                  className={classes.input}
                  sx={{
                    mb: { md: 4, sm: 2, xs: 2 },
                    mr: 1,
                  }}
                />
              </FormControl>
            </Grid>
            <Typography className={classes.url} mt={4}>
              Default Personal Message
              {/* <img src={question} alt="" /> */}
            </Typography>
            <Typography className={classes.fs16}>
              Prefilled based message for anyone starting a fundraiser. It can
              be changer while they're starting their fundraiser, or afterwards.{" "}
            </Typography>
            <Grid item md={7} py={2}>
              <FormControl className={classes.fullWidth}>
                <MyTextField
                  textMsg={goalObj?.message || "Write message here"}
                  handleTextChange={(value) =>
                    setGoalObj((prevObj) => ({ ...prevObj, message: value }))
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          container
          justifyContent="flex-start"
          my={{ md: 0, sm: 0, xs: 3 }}
        >
          <Grid item>
            {/* <LoadingButton
              loading={false}
              loadingPosition="start"
              startIcon={<EyeIcon />}
              variant="outlined"
              className={classes.preview}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                color: themeMode === 'dark' ? '#FFFF' : '#030303',
                "&:hover": {
                  backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7',
                }
              }}
            >
              Preview
            </LoadingButton> */}
            <P2PPreviewDialog type={type} mode={mode} />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={false}
              loadingPosition="start"
              variant="outlined"
              className={classes.save}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
              }}
              onClick={handleSave}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default PeerToPeer;
