import { gql } from "@apollo/client";

export const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($isDefault: Boolean!, $accountDetail: JSON!) {
    createPaymentMethod(
      input: { isDefault: $isDefault, accountDetail: $accountDetail }
    ) {
      paymentMethod {
        uuid
        isDefault
        accountDetail
      }
    }
  }
`;

export const CREATE_WITHDRAWL_REQUEST = gql`
  mutation CreateWithdrawalRequest($paymentMethodUuid: ID!, $amount: Float!) {
    createWithdrawalRequest(
      input: { paymentMethodUuid: $paymentMethodUuid, amount: $amount }
    ) {
      withdrawalRequest {
        uuid
        amount
        status
        paymentMethod {
          uuid
          accountDetail
        }
        user {
          uuid
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query paymentMethods {
    paymentMethods {
      nodes {
        uuid
        isDefault
        accountDetail
        archivedAt
      }
    }
  }
`;
