import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },
  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    
    fontWeight: "500",
    textTransform: "capitalize",
  },
  neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  fw600: {
    fontWeight: "600",
    textWrap: "wrap",
  },
  modeButton: {
    
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
  },
  fs18: {
    fontWeight: "600",
    width: "100%",
  },
  justifyCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  quick: {
    fontSize: "20px",
    fontWeight: "600",
  },
  links: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "500",
    color: "#8ED003",
    textTransform: "capitalize",
    display: "block",
    cursor:"pointer",
  },
  fs16: {
    fontSize: "16px",
    fontWeight: "400",
  },
  italics:{
      fontSize: "16px",
      fontWeight: "400",
      fontStyle:"italic"
  },
  bold: {
    fontSize: "16px",
    fontWeight: "600",
  },
  neonBold: {
    color:"'#659500",
    fontSize: "16px",
    fontWeight: "600",
  },
  rowWrap: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  img: {
    borderRadius: "15px",
    
    "&:hover": { backgroundColor: "transparent" },
    "&:focus": { backgroundColor: "transparent" },
  },

  f28w600: {
    fontSize: "28px",
    fontWeight: "600",
  },

  f18w500: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },

  p20: {
    padding: "20px 0px",
  },

  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  f18w500: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
  },

  preview: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  save: {
    color: "#FFF",
    background: "#659500",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      border: "1px solid #689900",
      backgroundColor: "#8ED003",
    },
  },

  animatedGrid: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '0px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius:"15px",
    flexDirection:"column",
    transition: 'height 0.5s ease-in-out, background-color 0.5s ease-in-out',
  },
  slideDown: {
    height: '100%',
    backgroundColor: 'red',
  },
}));
