import { useMutation, gql } from "@apollo/client"

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $title: String!
  ) {
    createCampaign(
      input: { title: $title }
    ) {
      campaign {
        uuid
        title
      }
    }
  }
`
