import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useStyles } from "./useStyles";
import { freq, amount } from "../data.jsx";
import { currencies } from '../../../utils/globalFunctions.js'; 

const fb = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/fb.svg";
const sheild = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/shield.svg";
const back = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/back.svg";
const heart = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/heart.svg";
const wa = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/WA.svg";
const mail = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/mail.svg";
const plant = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/plantImg.svg";

const Embedded = () => {
  const classes = useStyles();
  const [frequency, setFrequency] = useState("");
  const [donation, setDonation] = useState("");
  const [currency, setCurrency] = useState([]);
  const [money, setMoney] = useState("");
  const [mode, setMode] = useState("secure");

  const SecureMode = () => {
    setMode("secure");
  };
  const ConsiderMode = () => {
    setMode("consider");
  };
  const DonateMode = () => {
    setMode("donate");
  };
  const ThankMode = () => {
    setMode("thanks");
  };
  const Recur50 = () => {
    handleFrequency(1);
    handleDonation("$", "50");
    setMode("donate");
  };
  const Recur25 = () => {
    setFrequency(1);
    setDonation("50", "USD");
    setMode("donate");
  };

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.label);
  };

  const handleChangeMoney = (event) => {
    setMoney(event.target.value);
  };

  const handleFrequency = (f) => {
    setFrequency(f);
  };

  const handleDonation = (f, m) => {
    setDonation(f);
    setMoney(m);
  };

  const processCost = 1.25;
  const TotalCost = money + processCost;

  return (
    <>
      <Grid container className={classes.p25}>
        <Grid
          item
          md={8}
          sm={8}
          xs={12}
          py={7}
          mx={{ md: 1, sm: 1, xs: 0 }}
          px={{ md: 10, sm: 6, xs: 2 }}
          className={classes.box}
        >
          <Grid
            item
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
            }}
          >
            <Grid className={classes.center}
              sx={{
                transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.7)" },
              }}
            ></Grid>
            <Grid className={classes.column}>
              {mode === "donate" ? (
                <>
                  <Typography className={classes.head700}
                    sx={{
                      fontSize: { md: "28px", sm: "25px", xs: "15px" },
                    }}
                  >
                    Checkout
                  </Typography>
                  <Grid
                    mt={3}
                    mb={1}
                    className={classes.bkg}
                  ></Grid>
                  <Grid mt={1} mb={5}>
                    <Typography className={classes.f16w400}>
                      Please provide your information so that we can contact you
                      about your donation.
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Typography className={classes.head700}
                    sx={{
                      fontSize: { md: "28px", sm: "25px", xs: "15px" },
                    }}
                  >
                    You have the power to create an impact.
                  </Typography>
                  <Grid my={3}>
                    <img src={plant} alt="" className={classes.fullWidth}/>
                  </Grid>
                  <Grid my={1}>
                    <Typography className={classes.f16w400}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, Lorem Ipsum is
                      simply dummy text of the printing a
                    </Typography>
                  </Grid>
                </>
              )}

              {mode === "donate" ? (
                <>
                  <FormControl
                    className={classes.spaceBtwn} 
                    
                    sx={{
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Name on Card"
                      className={classes.input2}
                      sx={{
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                    <TextField
                      label="Card Number"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        my: 3,
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "100%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Card Expiry Date"
                      className={classes.input2}
                      sx={{
                        my: { md: 3, sm: 4, xs: 2 },
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                    <TextField
                      label="Security Code"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        my: { md: 3, sm: 4, xs: 4 },
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                  </FormControl>

                  <FormControl
                    className={classes.spaceBtwn}
                    sx={{
                      width: { md: "100%", sm: "100%", xs: "100%" },
                      flexDirection: { md: "row", sm: "row", xs: "column" },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="outlined-basic"
                      label="Email"
                      className={classes.input2}
                      sx={{
                        my: { md: 3, sm: 4, xs: 2 },
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      id="outlined-basic"
                      className={classes.input2}
                      sx={{
                        my: { md: 3, sm: 4, xs: 4 },
                        mr: 1,
                        width: { md: "45%", sm: "45%", xs: "100%" },
                      }}
                    />
                  </FormControl>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={3.5}
          sm={3.5}
          xs={12}
          px={{ md: 6, sm: 3, xs: 4 }}
          mx={{ md: 1, sm: 0, xs: 0 }}
          my={{ md: 0, sm: 0, xs: 1 }}
          py={3}
          className={classes.box2}
        >
          {mode === "secure" ? (
            <Grid className={classes.fit}
              item
              sx={{
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
              }}
            >
              <Grid className={classes.center}>
                <Grid mr={1} className={classes.alignCenter}>
                  <img src={sheild} alt="" />
                </Grid>
                <Typography className={classes.fw500}
                  sx={{
                    fontSize: { md: "22px", sm: "18px", xs: "15px" },
                  }}
                >
                  Secure Donation
                </Typography>
              </Grid>

              <Grid my={{ md: 4, sm: 2, xs: 2 }}>
                <Grid className={classes.fullSpace}
                  sx={{
                    flexWrap: { md: "nowrap", sm: "wrap", xs: "wrap" },
                  }}
                >
                  {freq.map((item, index) => (
                    <Grid item md={3} sm={6} xs={6}>
                      <Button
                        key={index}
                        disableElevation
                        disableRipple
                        className={classes.option}
                        sx={{
                          my: { md: 0, sm: 1, xs: 1 },
                          mx: 0.5,
                          border:
                            frequency === item.id
                              ? "2px solid #A4B200"
                              : "2px solid #BCBCBC",
                        }}
                        onClick={() => handleFrequency(item.id)}>
                        <Typography className={classes.btnText}>
                          {item.name}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid my={2}>
                <Grid className={classes.btnHolder}>
                  {amount.map((item, index) => (
                    <Grid item md={4} sm={6} xs={4}>
                      <Button className={classes.moneyBtn}
                        key={index}
                        disableElevation
                        disableRipple
                        sx={{
                          border:
                            donation === item.id
                            ? "2px solid #A4B200"
                            : "2px solid #BCBCBC",
                        }}
                        onClick={() => handleDonation(item.id, item.name)}
                      >
                        <Typography className={classes.btnText2}>
                          Rs. {item.name}
                        </Typography>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div className={classes.alignCenter}>
                <TextField
                  label="Rs."
                  variant="outlined"
                  value={money}
                  onChange={handleChangeMoney}
                  className={classes.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ width: "fit-content" }}
                      >
                        <TextField
                          select
                          value={currency}
                          onChange={handleChangeCurrency}
                          variant="outlined"
                          className={classes.select}
                        >
                          {currencies?.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              className={classes.menuItem}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <Grid mt={{ md: 6, sm: 3, xs: 3 }}>
                <Button className={classes.proceed}
                  disableRipple
                  onClick={() => {
                    if (money && frequency) ConsiderMode();
                  }}
                  sx={{
                    backgroundColor: money && frequency 
                        ? "#A4B200" 
                        : "#706e6e",
                    color: money && frequency 
                        ? "#fff" 
                        : "#000",
                    "&:hover": {
                      border:
                        money && frequency
                          ? "2px solid #A4B200"
                          : "2px solid transparent",
                      backgroundColor:
                        money && frequency 
                          ? "transparent" 
                          : "#706e6e",
                      cursor: money && frequency 
                          ? "pointer" 
                          : "not-allowed",
                    },
                  }}
                >
                  <Typography className={classes.f16w500}>
                    Proceed
                  </Typography>
                </Button>
              </Grid>

              <Grid className={classes.spaceBtwn} mt={1}>
                <Grid item md={4}>
                  <Typography className={classes.f10w400l}>
                    Is my donation secure?
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography className={classes.f10w400r}>
                    Can I cancel my recurring donation?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : mode === "consider" ? (
            <Grid className={classes.flexFull}
              item 
              sx={{   
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
              }}
            >
              <Button
                disableRipple
                onClick={SecureMode}
                className={classes.backBtn}
                py={1}
              >
                <img src={back} alt="" />
                <Typography
                  mx={1} className={classes.backText}
                  sx={{   
                    fontSize: { md: "18px", sm: "9px", xs: "13px" },
                  }}
                >
                  Become a monthly supporter
                </Typography>
              </Button>
              <Grid item mt={{ md: 9, sm: 5, xs: 6 }} className={classes.fullWidth}>
                <Typography sx={{ textAlign: { md: "left", sm: "center", xs: "left" } }}>
                  
                  Will you consider becoming one of our valued monthly suporters
                  by converting your $100 contribution into monthly donation?

                </Typography>
              </Grid>
              <Grid my={5} className={classes.fullWidth}>
                <Grid my={1} className={classes.relFull}>
                  <Button
                    onClick={Recur50}
                    className={classes.recur50}>
                    <Grid className={classes.red}>
                      <img
                        src={heart}
                        alt="Heart"
                        className={classes.heart}
                      />

                      <Grid className={classes.alignCenter}>
                        <Typography className={classes.cap500}
                          sx={{
                            fontSize: { md: "16px", sm: "12px", xs: "16px" },
                          }}
                        >
                          Donate $50/month
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid my={3}>
                  <Button
                    //onClick={Thankyou}
                    className={classes.green}
                  >
                    <Grid>
                      <Typography className={classes.don25}
                        sx={{
                          fontSize: { md: "16px", sm: "12px", xs: "16px" },
                        }}
                      >
                        Donate $25/month
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
                <Grid mt={{ md: 7, sm: 4, xs: 7 }}>
                  <Button className={classes.donate}
                    onClick={DonateMode}>
                    <Typography className={classes.don25}
                      sx={{
                        fontSize: { md: "16px", sm: "10px", xs: "12px" },
                      }}
                    >
                      Keep my one-time $100 gift
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : mode === "donate" ? (
            <Grid className={classes.flexFit}
              item
              sx={{
                flexDirection: { md: "column", sm: "column", xs: "column" },
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
              }}
            >
              <Button className={classes.consider}
                onClick={ConsiderMode}
                disableRipple
                py={1}
              >
                <img src={back} alt="" />
                <Typography
                  mx={3}
                  className={classes.backText}
                  sx={{
                    fontSize: { md: "18px", sm: "9px" },
                  }}
                >
                  Personal Information
                </Typography>
              </Button>

              <Grid my={5} item md={12}>
                <Grid className={classes.flexFull}>
                  <Grid className={classes.spaceBtwn}>
                    <Grid>
                      <Typography>Donation</Typography>
                    </Grid>
                    <Grid>
                      <Typography>${money}</Typography>
                    </Grid>
                  </Grid>
                  <Grid className={classes.spaceBtwn} my={2}>
                    <Grid>
                      <Typography>Processing Fee</Typography>
                    </Grid>
                    <Grid>
                      <Typography>$1.25</Typography>
                    </Grid>
                  </Grid>
                  <Grid className={classes.spaceBtwn}>
                    <Grid>
                      <Typography>Total</Typography>
                    </Grid>
                    <Grid>
                      <Typography>$ {TotalCost}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid my={3}>
                  <Button className={classes.green}
                    onClick={ThankMode}
                  >
                    <Grid>
                      <Typography className={classes.f16w500}>
                        Donate Now
                      </Typography>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : mode === "thanks" ? (
            <Grid item>
              <Grid className={classes.thankTitle}
                py={1}
              >
                <Typography className={classes.backText}>
                  Thank You
                </Typography>
              </Grid>

              <Grid my={5} item md={12}>
                <Typography className={classes.thank700}>
                  Thank you for the donation of $ {TotalCost}
                </Typography>
              </Grid>
              <Grid className={classes.grnBox}>
                <Typography className={classes.share}>
                  Share now to help us spread this cause
                </Typography>
              </Grid>
              <Grid my={4}>
                <Button className={classes.shareBtn}>
                  <Grid className={classes.inBtn}>
                    <img src={fb} alt=""  />
                    <Typography className={classes.cap500}
                      sx={{
                        fontSize: { md: "16px", sm: "12px", xs: "16px" },
                      }}
                    >
                      Share on Facebook
                    </Typography>
                  </Grid>
                </Button>

                <Button className={classes.shareBtn}
                  sx={{
                    my: 2,
                  }}
                >
                  <Grid className={classes.inBtn}>
                    <img src={wa} alt="" />
                    <Typography className={classes.cap500}
                      sx={{
                        fontSize: { md: "16px", sm: "12px", xs: "16px" },
                      }}
                    >
                      Share on Watsapp
                    </Typography>
                  </Grid>
                </Button>

                <Button className={classes.shareBtn}>
                  <Grid className={classes.inBtn}>
                    <img src={mail} alt="" />
                    <Typography className={classes.cap500}
                      sx={{
                        fontSize: { md: "16px", sm: "12px", xs: "16px" },
                      }}
                    >
                      Share on Email
                    </Typography>
                  </Grid>
                </Button>
                <Grid my={3}>
                  <Button onClick={SecureMode}>
                    <Typography className={classes.close}>
                      Close
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Embedded;
