import { gql } from "@apollo/client";

export const GET_ALL_DONATIONS = gql`
  query Donations($first: Int, $after: String, $filterBy: String, $sortColumn: DonationSortColumnsEnum, $sortOrder: SortOrderEnum, $searchQuery: String, $startDate: String, $endDate: String){ 
    donations(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, filterBy: $filterBy, searchQuery: $searchQuery, startDate: $startDate, endDate: $endDate) 
    { 
      nodes 
      { 
        uuid 
        amount
        currency 
        stripeChargeId 
        feeCover
        campaign 
        { 
          uuid 
          title 
        } 
        donor 
        { 
          uuid 
          email 
          firstName
          lastName
        } 
        createdAt
        updatedAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!){
    createExport(input:{pageName: $pageName}) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;