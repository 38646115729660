import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useMutation, gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { setSpinnerLoading } from '../../../store/spinnerLoaderSlice'
import { useDispatch } from 'react-redux'
import { success, error as errorMsg } from '../../../store/alertSlice'
import { useSelector } from 'react-redux'
import { CREATE_CAMPAIGN } from './Query'

const cross = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg'
const crossDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/crossBlack.svg'

const CreateCampaign = ({ onClose, setSelectedOption }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  //  const classes = useStyles()
  const themeMode = useSelector(state => state.themeMode.mode)
  const handleClose = () => {
    onClose()
  }

  const handleCreate = async () => {
    const variables = {
      title: title,
      description: 'This is default description of campaign',
      price: '100.0'
    }
    dispatch(setSpinnerLoading(true))
    try {
      const result = await createCampaign({ variables })
      dispatch(setSpinnerLoading(false))
      setSelectedOption(12)
      const campaignUuid = result?.data?.createCampaign?.campaign?.uuid
      const campaignTitle = result?.data?.createCampaign?.campaign?.title
      navigate(`/dashboard/campaign-overview/${campaignUuid}/${campaignTitle}`)
      onClose()
    } catch (error) {
      console.error(error)
      dispatch(setSpinnerLoading(false))
    }
  }

  const [selectedValue, setSelectedValue] = useState('a')
  const [title, setTitle] = useState('')

  const [createCampaign, { loading, error }] = useMutation(CREATE_CAMPAIGN, {
    onError: error => {
      console.error('Mutation Error:', error)
      dispatch(errorMsg('your request was unsuccessful!'))
    },
    onCompleted: data => {
      console.log('Mutation Completed:', data)
      dispatch(success('your request was successful!'))
    }
  })

  const handleChange = event => {
    setSelectedValue(event.target.value)
  }

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item }
  })

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: '8% 27%', sm: '35px 15%', xs: '5px 2%' },
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          backdropFilter: 'blur(11px)',
          height: '100%',
          zIndex: '99',
          width: '100%'
        }}
      >
        <Grid
          item
          md={10}
          sm={12}
          xs={12}
          sx={{
            transform: 'scale(0.85)',
            padding: '35px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
            boxShadow:
              themeMode === 'dark'
                ? '0px 0px 0px red'
                : '0px 8px 5px 0px #00000040',
            color: themeMode === 'dark' ? '#FFFF' : '#030303',
            borderRadius: '15px',
            overflow: 'hidden',
            height: 'fit-content',

            '&::before': {
              content: "''",
              position: 'absolute',
              inset: 0,
              borderRadius: '15px',
              padding: '1px',
              background:
                themeMode === 'dark'
                  ? 'linear-gradient(145deg, white, transparent)'
                  : 'linear-gradient(180deg, white, transparent)',
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude'
            }
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: { md: 'row', sm: 'row', xs: 'row' },
              justifyContent: 'space-between',
              alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
              width: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '16px', sm: '16px', xs: '15px' },
                fontWeight: '600',
                textAlign: 'left',
                width: '100%',
                whiteSpace: 'nowrap'
              }}
            >
              Create a new campaign
            </Typography>
            <Button
              disableElevation
              disableRipple
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              onClick={handleClose}
            >
              <img src={themeMode === 'dark' ? cross : crossDark} alt='' />
            </Button>
          </Grid>
          <Grid
            item
            mt={5}
            md={12}
            sx={{
              width: '100%',
              position: 'relative'
            }}
          >
            <FormControl sx={{ width: '100%', fontSize: '14px' }}>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value='newCampaign'
                  sx={{ textAlign: 'left' }}
                  control={
                    <Radio
                      {...controlProps('e')}
                      sx={{
                        color: themeMode === 'dark' ? '#FFFF' : '#030303',
                        '&.Mui-checked': {
                          color: themeMode === 'dark' ? '#D5DA00' : '#8ED003'
                        }
                      }}
                    />
                  }
                  label='New campaign with default settings '
                />
                <FormControlLabel
                  value='clone'
                  control={
                    <Radio
                      {...controlProps('f')}
                      sx={{
                        color: themeMode === 'dark' ? '#FFFF' : '#030303',
                        '&.Mui-checked': {
                          color: themeMode === 'dark' ? '#D5DA00' : '#8ED003'
                        }
                      }}
                    />
                  }
                  label='Clone an existing campaign'
                  sx={{ mt: { md: 1, sm: 2, xs: 4 }, textAlign: 'left' }}
                />
              </RadioGroup>

              <Grid mt={{ md: 6, sm: 6, xs: 6 }} item md={12}>
                <FormControl sx={{ display: 'flex', width: '100%' }}>
                  <Typography
                    sx={{
                      texAlign: 'left',
                      width: '100%',
                      fontSize: '18px',
                      display: 'flex'
                    }}
                  >
                    Campaign Name
                  </Typography>
                  <TextField
                    fullWidth
                    size='large'
                    variant='outlined'
                    id='outlined-basic'
                    //label=""
                    name='CampaignName'
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    sx={{
                      //  mt: { md: 0, sm: 10, xs: 10 },
                      //              mt: { md: 4, sm: 2, xs: 2 },
                      mr: 1,
                      borderRadius: '10px',
                      backgroundColor:
                        themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',

                      '& .MuiOutlinedInput-input': {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                        '&::placeholder': {
                          color: themeMode === 'dark' ? '#ffff' : '#000'
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: themeMode === 'dark' ? '#ffff' : '#000',
                        fontSize: '16px',
                        marginBottom: '20px',
                        transform: 'translate(0px, -40px) scale(1.25)',
                        '&.MuiInputLabel-shrink': {
                          transform: 'translate(0px, -40px) scale(1.25)'
                        }
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                        borderWidth: '0.5px',
                        borderRadius: '5px'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        color: themeMode === 'dark' ? '#ffff' : '#000'
                      },
                      '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red'
                      },
                      outline: 'none'
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  mt: 4
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    color: themeMode === 'dark' ? '#ffff' : '#000',
                    width: { md: '30%', sm: '30%', xs: '50%' },
                    background: 'transparent',
                    border: '1px solid #fff',
                    border:
                      themeMode === 'dark'
                        ? '1px solid #fff'
                        : '1px solid #000',
                    borderRadius: '30px',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: '600',
                    padding: '5px 15px',
                    mr: 2,
                    '&:hover': {
                      backgroundColor:
                        themeMode === 'dark' ? '#2e3322' : '#e3fca7',
                      border: '1px solid #081a01'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreate}
                  sx={{
                    color: '#FFF',
                    width: { md: '30%', sm: '30%', xs: '50%' },
                    background:
                      'linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)',
                    borderRadius: '30px',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: '600',
                    padding: '5px 15px',
                    ml: 2,
                    '&:hover': {
                      background:
                        'linear-gradient(180.33deg, #618F00 4.78%, #8ED003 154.24%)'
                    }
                  }}
                >
                  Create
                </Button>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default CreateCampaign
