export const category = [
    {
        id:0,
        name:"Recent",
    },
    {
        id:1,
        name:"Fundraising",
    },
    {
        id:2,
        name:"All Articles",
    },
    {
        id:3,
        name:"Trending",
    },
    {
        id:4,
        name:"Tips & Tricks",
    },
    

]