import { Typography, Button } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export const MoneyBasedDonation = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [selectedDuration, setSelectedDuration] = useState(1);
  const [selectedAmount, setSelectedAmount] = useState("1000");
  const [currency, setCurrency] = useState("pkr");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const durationArray = [
    {
      id: 1,
      value: "Give Once",
    },
    {
      id: 2,
      value: "Monthly",
    },
    {
      id: 3,
      value: "Yearly",
    },
  ];

  const amountArray = [
    {
      id: 1,
      value: "1000",
    },
    {
      id: 2,
      value: "500",
    },
    {
      id: 3,
      value: "200",
    },
  ];
  const amountArray2 = [
    {
      id: 4,
      value: "100",
    },
    {
      id: 5,
      value: "60",
    },
    {
      id: 6,
      value: "20",
    },
  ];
  return (
    <div className="px-8">
      <Typography className="preview-settings-content-text2 py-4 text-center">
        Secure Donation{" "}
      </Typography>
      <div>
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center md:space-x-4">
          {durationArray?.map((duration) => (
            <Button
              id={duration.id}
              onClick={() => setSelectedDuration(duration.id)}
              // size="small"
              className={
                selectedDuration == duration.id
                  ? "selected-duration-button px-4 w-full"
                  : "duration-button px-4 w-full"
              }
              sx={
                themeMode === "dark" ? { color: "white" } : { color: "black" }
              }
            >
              {duration.value}{" "}
            </Button>
          ))}
        </div>
        <div className="flex flex-wrap md:flex-nowrap  justify-between items-center mt-6 md:space-x-4">
          {amountArray?.map((amount) => (
            <Button
              id={amount.id}
              onClick={() => setSelectedAmount(amount.value)}
              className={
                selectedAmount == amount.value
                  ? "selected-duration-button px-4 w-full"
                  : "duration-button px-4 w-full"
              }
              sx={
                themeMode === "dark" ? { color: "white" } : { color: "black" }
              }
            >
              Rs {amount.value}{" "}
            </Button>
          ))}
        </div>
        <div className="flex flex-wrap md:flex-nowrap  justify-between items-center mt-4 mb-6 md:space-x-4">
          {amountArray2?.map((amount) => (
            <Button
              id={amount.id}
              onClick={() => setSelectedAmount(amount.value)}
              className={
                selectedAmount == amount.value
                  ? "selected-duration-button px-4 w-full"
                  : "duration-button px-4 w-full"
              }
              sx={
                themeMode === "dark" ? { color: "white" } : { color: "black" }
              }
            >
              Rs {amount.value}{" "}
            </Button>
          ))}
        </div>
      </div>
      <div
        className={
          themeMode === "dark"
            ? "my-4 amount-selected-box px-4"
            : "my-4 amount-selected-box-light text-black px-4"
        }
      >
        <div className="flex justify-between items-center w-full">
          <div>
            <Typography>
              Rs{" "}
              <span
                className={
                  themeMode === "dark"
                    ? "amount-selected-box-text"
                    : "amount-selected-box-text"
                }
              >
                {" "}
                {selectedAmount}
              </span>
            </Typography>
          </div>
          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={currency}
                onChange={handleChange}
                label="currency"
                className="amount-selected-box-currency text-right pt-2"
                disableUnderline
              >
                <MenuItem
                  className="amount-selected-box-currency"
                  value={"pkr"}
                >
                  PKR
                </MenuItem>
                <MenuItem className="amount-selected-box-currency" value={"$"}>
                  US Dollar
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="mt-8 pt-8">
        <Button
          variant="contained"
          className="w-full"
          sx={{ backgroundColor: "#A4B200" }}
        >
          <span
            className={
              themeMode === "dark"
                ? "donate-now-button text-white"
                : "donate-now-button text-black"
            }
          >
            Donate Now
          </span>
        </Button>
      </div>
      <div className="mt-2 w-full md:flex justify-between">
        <Button variant="text">
          <Typography
            className="donate-links"
            sx={themeMode === "dark" ? { color: "white" } : { color: "black" }}
          >
            Is my donation secure?{" "}
          </Typography>
        </Button>
        <Button variant="text">
          <Typography
            className="donate-links"
            sx={themeMode === "dark" ? { color: "white" } : { color: "black" }}
          >
            Can I cancel my recurring donation?{" "}
          </Typography>
        </Button>
      </div>
    </div>
  );
};
