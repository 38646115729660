import React, { useEffect, useState, useRef } from "react";
import {Grid,Typography} from '@mui/material'
import { CustomColorPicker } from '../../../../CreateCampaign/MyComponents'
import { useStyles } from "../../useStyles";
import { useSelector } from "react-redux";
const ColorBox = ({label,value,onChange}) => {
    const classes=useStyles();
    const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      <Grid
        item
        md={4}
        sm={4}
        xs={12}
        mr={2}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          my={{ md: 0, sm: 0, xs: 1 }}
          sx={{
            textWrap: 'nowrap',
            textAlign: 'right',
            width: '100%'
          }}
        >
          
          {label}
        </Typography>
      </Grid>
      <Grid
        md={4}
        sm={5}
        xs={7}
        className={classes.colorBox}
        item
        my={1}
        sx={{
          display: 'flex',
          textAlign: 'center',
          border:
            themeMode === 'dark' ? '0.5px solid #FFFFFF' : '0.5px solid #656565'
        }}
      >
        <CustomColorPicker
          name='color'
          value={value}
          onChange={onChange}
        />
        <Typography mx={2}>
          {value || '#FFFF'}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default ColorBox
