import { gql } from "@apollo/client";

export const CREATE_USER_REQUEST = gql`
  mutation CreateUserRequest(
    $email: String!
    $firstName: String!
    $lastName: String!
    $organization: String!
    $organizationCountry: String!
    $jobTitle: String!
    $phoneNumber: String!
  ) {
    createUserRequest(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        organization: $organization
        organizationCountry: $organizationCountry
        jobTitle: $jobTitle
        phoneNumber: $phoneNumber
      }
    ) {
      userRequest {
        uuid
        email
        firstName
        lastName
        organization
        organizationCountry
        jobTitle
        phoneNumber
      }
    }
  }
`;
