import Footer from "../../Footer/Footer";
import NavBarAuth from "../../Navbar/NavbarAuth";
import NavBar from "../../Navbar/Navbar";
import Client from "./Clients/Clients";
import Question from "./Question/Question";
import SalesTeam from "./SalesTeam/SalesTeam";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./styles.css";
import { useSelector } from "react-redux";
import DocumentTitle from "../../../DocumentTitle.js";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footer.png";
const bklight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/bgLights.svg";
const footerlight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footerlight.svg";

const SignUp = () => {
  DocumentTitle("Signup - Acceleraise");
  const [ques, setQues] = useState(false);
  const [userId, setUserId] = useState(null);
  const themeMode = useSelector((state) => state.themeMode.mode);

  useEffect(() => {
    if (ques) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [ques]);

  const showQuestion = () => {
    setQues(true);
  };

  const closeQuestion = () => {
    setQues(false);
  };
  const light = "light";
  const dark = "dark";
  return (
    <Grid
      sx={
        themeMode === "light"
          ? {
              backgroundImage: `url('${bklight}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              backgroundColor: "#7CB702",
            }
          : {}
      }
    >
      {themeMode === "dark" ? (
        <NavBar
          scrollColor="#000"
          btnColor="transparent"
          getStartedBorder="1px solid #689900"
          profileBkg="#2e3322"
          profileBorder="1px solid #94D906"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      ) : (
        <NavBar
          scrollColor="#7CB702"
          btnColor="#018C94"
          getStartedBorder="1px solid transparent"
          profileBkg="#7CB702"
          profileBorder="1px solid #018C94"
          logoTheme={light}
          scrollingColor={light}
          txtColor="#FFF"
        />
      )}
      <SalesTeam showQuestion={showQuestion} setUserId={setUserId} />
      {ques ? <Question onClose={closeQuestion} userId={userId} /> : ""}
      <Client />

      {themeMode === "dark" ? (
        <Footer
          bkg={bkg}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      ) : (
        <Footer
          bkg={footerlight}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      )}
    </Grid>
  );
};
export default SignUp;
