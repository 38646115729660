import { Typography, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";

const stars = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/stars.png";
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/card.png";
const checked = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/checked.png";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required("You must enter email"),
  })
  .required();

export const PersonalInfo = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, touchedFields } = formState;

  return (
    <div className="flex flex-col h-full">
      <div className="px-8">
        <Typography className="pre-don-text py-4 text-center">
          Personal Info{" "}
        </Typography>
        <hr />
        <Typography className="thank-you-text2 py-4 text-center">
          Please provide me your details to continue with your donation{" "}
        </Typography>
      </div>
      <div className="px-8">
        <form
          onSubmit={handleSubmit((_data) => {
            console.log("🚀 ~ _data:", _data);
          })}
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                mt={3}
                fullWidth
                size="small"
                placeholder="First Name"
                variant="outlined"
                id="outlined-basic"
                name="firstName"
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                className="mt-8"
              />
            )}
            name="firstName"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                mt={3}
                fullWidth
                size="small"
                placeholder="Last Name"
                variant="outlined"
                id="outlined-basic"
                name="lastName"
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                className="mt-8"
              />
            )}
            name="lastName"
            control={control}
          />

          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                mt={3}
                fullWidth
                size="small"
                placeholder="Email Address"
                variant="outlined"
                id="outlined-basic"
                name="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                type="email"
                className="mt-8"
              />
            )}
            name="email"
            control={control}
          />

          <Button
            variant="contained"
            className="w-full py-3 mt-16"
            sx={{ backgroundColor: "#A4B200", borderRadius: "10px" }}
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-white flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <span className="mx-2">Donate $60/Week </span>
            </span>
          </Button>
        </form>
      </div>
    </div>
  );
};
