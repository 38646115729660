const visa = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/visa.svg";

export const donationHistory=[
    {
        id:1,
        date:"1 Jun, 2024",
        amount:"$13.5 USD",
        methodImg:visa,
        method:". . . . 4254"
    },
    {
        id:2,
        date:"21 Sept, 2024",
        amount:"$25.5 USD",
        methodImg:visa,
        method:". . . . 4204",
    },
    {
        id:3,
        date:"15 Dec, 2024",
        amount:"$35 USD",
        methodImg:visa,
        method:". . . . 9254"
    }
];
export const recurring = [
    {
        id:1,
        amount:"$13.5 USD",
        next:"1 June, 2024",
        methodImg:visa,
        method:". . . . 4254"

    },
    
];

export const campaign = [
  {
    name: "Saving Animals",
    time: "30 days remaining",
    collected: "500",
    remaining: "600",
  },
];
export const messages=[
    {
        id:1,
        msg:"Donation Successful",
    },
    {
        id:2,
        msg:"Message 2",
    },
    {
        id:3,
        msg:"Message 3",
    }

]
