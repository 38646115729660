import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { payment } from '../../data'
import { useSelector } from 'react-redux'

const PaymentGateway = () => {
  const themeMode = useSelector(state => state.themeMode.mode);
  const [seeP2P, setSeeP2P] = useState(false);
  const handleSeeP2P = () => {
    setSeeP2P(!seeP2P);
  };
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
          boxShadow:
            themeMode === 'dark'
              ? '0px 0px 0px blue'
              : '4px 4px 14px 0px #00000024',
          borderRadius: '15px',
          height: 'fit-content',
          padding: '25px'
        }}
      >
        <Grid
          mb={2}
          sx={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F'
            }}
          >
            Top Payment Gateways
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: "auto",
            maxHeight: {
              md: seeP2P ? "" : `calc(100vh - 465px)`,
              sm: seeP2P ? "" : `calc(100vh - 255px)`,
              xs: seeP2P ? "" : `calc(100vh - 180px)`,
          },
          }}
        >
          {payment?.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: '5px 10px' }}>
              <Grid
                container
                sx={{
                  border: '1px solid #9CCA3C',
                  borderRadius: '15px',
                  padding: '10px 15px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor:
                      themeMode === 'dark' ? '#2e3322' : '#e3fca7'
                  }
                }}
              >
                <Grid
                  item
                  md={8}
                  sm={8}
                  xs={8}
                  sx={{ display: 'flex', alignItem: 'center' }}
                >
                  <img
                    src={themeMode==="dark"?item.img:item.light}
                    alt={item.way}
                    style={{ width: 'fit-content' }}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
export default PaymentGateway
