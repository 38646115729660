export const jobs=[
  {
    id: 1,
    name: "Design",
    job:[
      {
        id:0,
        post:"Junior UI/UX designer",
        time:"Full-Time",
        nature:"Remote",
        salary:"$20/hour",
        exp:"0-1 yrs"
      },
      {
        id: 1,
        post: "Mid-Level UI/UX designer",
        time: "Part-Time",
        nature: "Remote",
        salary: "$70/hour",
        exp: "2-3 yrs"
      }
    ]
  },
  {
    id: 2,
    name: "Development",
    job: [
      {
        id: 0,
        post: "Junior Backend Developer",
        time: "Full-Time",
        nature: "Remote",
        salary: "$100/hour",
        exp: "0-2 yrs"
      },
      {
        id: 1,
        post: "Assosciate Frontend Developer",
        time: "Full-Time",
        nature: "Remote",
        salary: "$150/hour",
        exp: "3-6 yrs"
      }
    ]
  },
  {
    id: 3,
    name: "Finance",
    job: [
      {
        id: 0,
        post: "Senior Accountant",
        time: "Full-Time",
        nature: "On-Site"
      },
     
    ]
  },
  {
    id: 4,
    name: "Marketing",
    job: [
      
    ]
  },
  {
    id: 5,
    name: "Video Editting",
    job: [
      {
        id: 0,
        post: "Senior VFX Designer",
        time: "Part-Time",
        nature: "Remote"
      },
     
    ]
  },
]
export const category = [
  {
    id: 0,
    name: "View All",
  },
  {
    id: 1,
    name: "Design",
  },
  {
    id: 2,
    name: "Development",
  },
  {
    id: 3,
    name: "Finance",
  },
  {
    id: 4,
    name: "Marketing",
  },
  {
    id: 5,
    name: "Video Editting",
  },


]