import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { recurring } from "../../data";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
const RecDonation = ({recurringDonations}) => {
  const themeMode = useSelector(state => state.themeMode.mode)
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
          boxShadow:themeMode === 'dark' ?"0px 0px 0px blue":"4px 4px 14px 0px #00000024",
          borderRadius: "15px",
          height: "fit-content",
          padding: { md: "25px", sm: "25px", xs: "20px" },
        }}
        component={Link}
        to="/dashboard/recurring" 
      >
        <Grid
          mb={2}
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "700",color:themeMode==="dark"? "#FFFF":"#0F0F0F", }}>
            Recurring Donations
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            //    overflowY: "auto",
          }}
        >
        
          <Grid
            item
            md={12}
            sx={{ padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" } }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" },
                borderBottom: "1px solid #3A3A3A",
              }}
            >
              <Grid sx={{ display: "flex", alignItem: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "500",
                    color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                  }}
                >
                  Amount
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "700",
                    color: "#8ED003",
                  }}
                >
                  ${recurringDonations?.total_amount?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sx={{ padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" } }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" },
                borderBottom: "1px solid #3A3A3A",
              }}
            >
              <Grid sx={{ display: "flex", alignItem: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "500",
                    color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                  }}
                >
                  Count
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "700",
                    color: "#8ED003",
                  }}
                >
                  {recurringDonations?.total_count}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sx={{ padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" } }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" },
                borderBottom: "1px solid #3A3A3A",
              }}
            >
              <Grid sx={{ display: "flex", alignItem: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "500",
                    color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                  }}
                >
                  Average
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "700",
                    color: "#8ED003",
                  }}
                >
                  ${recurringDonations?.total_average?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sx={{ padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" } }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: { md: "5px 10px", sm: "5px 10px", xs: "5px" },
                borderBottom: "none"
              }}
            >
              <Grid sx={{ display: "flex", alignItem: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "500",
                    color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                  }}
                >
                  Median
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "14px" },
                    fontWeight: "700",
                    color: "#8ED003",
                  }}
                >
                  ${recurringDonations?.total_amount / recurringDonations?.total_count ? (recurringDonations?.total_amount / recurringDonations?.total_count)?.toFixed(2) : 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};
export default RecDonation;
