import { gql } from "@apollo/client";

export const GET_ALL_DONORS = gql`
  query Donors($first: Int, $after: String, $sortColumn: DonorSortColumnsEnum, $sortOrder: SortOrderEnum, $searchQuery: String, $startDate: String, $endDate: String){ 
    donors(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, searchQuery: $searchQuery, startDate: $startDate, endDate: $endDate) 
    { nodes 
      { 
        uuid 
        firstName 
        lastName
        email
        donations 
        { 
          nodes 
          { 
            uuid 
            amount 
          } 
        }
        totalDonations
        firstDonationDate
        createdAt
        lastDonationDate
        updatedAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!){
    createExport(input:{pageName: $pageName}) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;