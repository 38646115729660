import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@mui/material";

import { countriesList } from "./countries";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { CREATE_USER_REQUEST } from "./Query";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import { useStylesLight } from "./useStylesLight";

const Form = ({ showQuestion, setUserId }) => {
  const classes = useStyles();
  const classesLight = useStylesLight();

  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [organization, setOrganization] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [agreeToProcess, setAgreeToProcess] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [users, setUsers] = useState([]);
  const [mobile, setMobile] = useState([]);
  const [placeholder, setPlaceholder] = useState("Select a country");
  const themeMode = useSelector((state) => state.themeMode.mode);

  const [createUser, { loading, error }] = useMutation(CREATE_USER_REQUEST, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg(error?.message));
    },
    onCompleted: (data) => {
      // console.log("Mutation Completed:", data);
      dispatch(
        success(
          "You will receive instructions in an email once the admin has approved your request!"
        )
      );
    },
  });

  useEffect(() => {
    setMobile(code.dial_code + phone);
  }, [code, phone]);

  const isSubmitDisabled = !agreeToProcess;

  const handleAgreeToProcessChange = () => {
    setAgreeToProcess((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitDisabled) {
      return;
    }
    const formData = new FormData(event.target);
    const variables = {
      email: formData.get("email"),
      firstName: formData.get("fname"),
      lastName: formData.get("lname"),
      organization: formData.get("organization"),
      organizationCountry: formData.get("location"),
      jobTitle: formData.get("title"),
      phoneNumber: formData.get("phone"),
    };
    if (!variables.email) {
      return;
    } else {
      dispatch(setSpinnerLoading(true));
      try {
        const response = await createUser({
          variables: variables,
        });
        dispatch(setSpinnerLoading(false));
        if (response?.data?.createUserRequest?.userRequest) {
          setUserId(response?.data?.createUserRequest?.userRequest?.uuid);
          showQuestion();
        }
      } catch (error) {
        console.error("Error:", error);
        dispatch(setSpinnerLoading(false));
      }
    }
  };

  const handleCreateUser = async () => {
    const userData = {
      email: email,
      firstName: fname,
      lastName: lname,
      organization: organization,
      organizationCountry: location,
      jobTitle: title,
      phoneNumber: mobile,
    };

    if (!email) {
      setEmailError(true);
    } else {
      if (editIndex !== null) {
        setUsers((prevUsers) => {
          return prevUsers?.map((users, index) =>
            index === editIndex
              ? {
                  ...users,
                  email: "test@test.com",
                  firstName: "test",
                  lastName: "user",
                  organization: "organization",
                  organizationCountry: "Pakistan",
                  jobTitle: "manager",
                  phoneNumber: "03001234567",
                }
              : users
          );
        });
        setEditIndex(null);
      } else {
        dispatch(setSpinnerLoading(true));
        try {
          const response = await createUser({
            variables: userData,
          });

          setUsers((prevUsers) => [
            ...prevUsers,
            {
              email: "test@test.com",
              firstName: "test",
              lastName: "user",
              organization: "organization",
              organizationCountry: "Pakistan",
              jobTitle: "manager",
              phoneNumber: "03001234567",
            },
          ]);
          showQuestion();
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error("Error:", error);
          dispatch(setSpinnerLoading(false));
        }
        setEmail("");
        setEmailError(false);
      }
    }
  };

  // if (themeMode === "dark") {
  return (
    <>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        sx={{
          paddingLeft: { md: "30px", sm: "30px", xs: "0px" },
          marginBottom: { md: "80px", sm: "0px", xs: "0px" },
        }}
      >
        <Grid
          className={themeMode === "dark" ? classes.line : classesLight.line}
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            bottom: { md: "20px", sm: "0px", xs: "0px" },
            backgroundSize: {
              md: "auto 98%",
              sm: "auto 100%",
              xs: "auto 100%",
            },
            padding: { md: "70px 60px", sm: "70px 60px", xs: "70px 20px" },
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Grid sx={{ mt: { md: 1, sm: 2, xs: 2 } }}>
                <Typography
                  className={
                    themeMode === "dark"
                      ? classes.inputText
                      : classesLight.inputText
                  }
                >
                  Email Address
                </Typography>
                <TextField
                  required
                  fullWidth
                  className={
                    themeMode === "dark"
                      ? classes.fullInput
                      : classesLight.fullInput
                  }
                  variant="outlined"
                  type="email"
                  id="outlined-basic"
                  //label="Email Address"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid
                className={classes.rowSpace}
                sx={{ mt: { md: 6, sm: 6, xs: 6 } }}
              >
                <Grid item md={5.9} sm={5.9} xs={5.9}>
                  <Typography
                    className={
                      themeMode === "dark"
                        ? classes.inputText
                        : classesLight.inputText
                    }
                  >
                    First Name
                  </Typography>
                  <TextField
                    required
                    variant="outlined"
                    className={
                      themeMode === "dark"
                        ? classes.fullInput
                        : classesLight.fullInput
                    }
                    id="outlined-basic"
                    //label="First Name"
                    name="fname"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </Grid>
                <Grid item md={5.9} sm={5.9} xs={5.9}>
                  <Typography
                    className={
                      themeMode === "dark"
                        ? classes.inputText
                        : classesLight.inputText
                    }
                  >
                    Last Name
                  </Typography>
                  <TextField
                    required
                    variant="outlined"
                    className={
                      themeMode === "dark"
                        ? classes.fullInput
                        : classesLight.fullInput
                    }
                    id="outlined-basic"
                    //label="Last Name"
                    name="lname"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: { md: 6, sm: 6, xs: 6 } }}>
                <Typography
                  className={
                    themeMode === "dark"
                      ? classes.inputText
                      : classesLight.inputText
                  }
                >
                  Organization
                </Typography>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  className={
                    themeMode === "dark"
                      ? classes.fullInput
                      : classesLight.fullInput
                  }
                  id="outlined-basic"
                  //label="Organization"
                  name="organization"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </Grid>
              <Grid sx={{ mt: { md: 6, sm: 6, xs: 6 } }}>
                <Typography
                  className={
                    themeMode === "dark"
                      ? classes.inputText
                      : classesLight.inputText
                  }
                >
                  Organization Based Location
                </Typography>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  className={
                    themeMode === "dark"
                      ? classes.fullInput
                      : classesLight.fullInput
                  }
                  id="outlined-basic"
                  //label="Organization Based Location"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </Grid>
              <Grid sx={{ mt: { md: 6, sm: 6, xs: 6 } }}>
                <Typography
                  className={
                    themeMode === "dark"
                      ? classes.inputText
                      : classesLight.inputText
                  }
                >
                  Job Title
                </Typography>
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  //className={classes.fullInput}
                  className={
                    themeMode === "dark"
                      ? classes.fullInput
                      : classesLight.fullInput
                  }
                  id="outlined-basic"
                  //label="Job Title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>

              <Grid
                className={classes.rowSpace}
                sx={{ mt: { md: 6, sm: 6, xs: 6 }, mb: { md: 2 } }}
              >
                <Grid item md={5.9} sm={5.9} xs={5.9}>
                  <Typography
                    className={
                      themeMode === "dark"
                        ? classes.inputText
                        : classesLight.inputText
                    }
                  >
                    Country Code
                  </Typography>
                  <TextField
                    required
                    select
                    //name="countryCode"
                    variant="outlined"
                    value={code}
                    //placeholder={placeholder}
                    onChange={(e) => setCode(e.target.value)}
                    className={
                      themeMode === "dark"
                        ? classes.select
                        : classesLight.select
                    }
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: themeMode === "dark" ? "#000" : "#FFF",
                            scrollbarWidth: "thin",
                            scrollbarColor:
                              themeMode === "dark"
                                ? "#8ED003CC #0F0F0F"
                                : "#8ED003 #E2E2E2",
                          },
                        },
                      },
                    }}
                  >
                    {countriesList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        className={
                          themeMode === "dark"
                            ? classes.menuItem
                            : classesLight.menuItem
                        }
                        onMouseEnter={() =>
                          setPlaceholder(`${item.name} (${item.dial_code})`)
                        }
                        onMouseLeave={() => setPlaceholder("Select a country")}
                      >
                        {item.name} ({item.dial_code})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={5.9} sm={5.9} xs={5.9}>
                  <Typography
                    className={
                      themeMode === "dark"
                        ? classes.inputText
                        : classesLight.inputText
                    }
                  >
                    Phone
                  </Typography>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    className={
                      themeMode === "dark"
                        ? classes.fullInput
                        : classesLight.fullInput
                    }
                    id="outlined-basic"
                    //label="Phone"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.justifyLeft}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={agreeToProcess}
                      onChange={handleAgreeToProcessChange}
                      style={{
                        color: themeMode === "dark" ? "#689900" : "#FFF",
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { md: "16px", xs: "16px", xs: "12px" },
                        color: themeMode === "dark" ? "#FFF" : "#030303",
                      }}
                    >
                      Agree to our Terms of Services and Privacy Policy
                    </Typography>
                  }
                  size="small"
                  sx={{ my: 5 }}
                />
              </Grid>
              <Grid
                className={classes.flex}
                sx={{
                  justifyContent: { md: "left", sm: "left", xs: "center" },
                }}
              >
                {themeMode === "dark" ? (
                  <Button
                    className={classes.signup}
                    type="submit"
                    sx={{
                      color: isSubmitDisabled ? "#8a8888" : "#FFF",
                      border: isSubmitDisabled
                        ? "1px solid #8a8888"
                        : "1px solid #689900",
                      cursor: isSubmitDisabled ? "not-allowed" : "pointer",

                      "&:hover": {
                        background: isSubmitDisabled
                          ? "transparent"
                          : "#689900",
                        border: isSubmitDisabled
                          ? "1px solid #689900"
                          : "2px solid #689900",
                      },
                    }}
                  >
                    Register with us{" "}
                  </Button>
                ) : (
                  <Button
                    className={classesLight.signup}
                    type="submit"
                    sx={{
                      color: "#FFF",
                      border: "1px solid #FFF",
                      cursor: isSubmitDisabled ? "not-allowed" : "pointer",

                      "&:hover": {
                        background: isSubmitDisabled
                          ? "transparent"
                          : "#ADFF00",
                        border: isSubmitDisabled
                          ? "1px solid #689900"
                          : "2px solid #ADFF00",
                      },
                    }}
                  >
                    Register with us{" "}
                  </Button>
                )}
              </Grid>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
export default Form;
