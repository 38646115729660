import { makeStyles } from "@mui/styles";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/signUpLight.svg";
const ellipseFull = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/ellipseFullLight.svg";
const ellipseHalf = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/ellipseHalfLight.svg";

export const useStylesLight = makeStyles((theme) => ({
  bkg: {
    backgroundImage: `url('${bkg}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  ellipse: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "flex-start",
    borderRadius: "15px",
    backdropFilter: "blur(22px)",
    backgroundColor: "#8ED003",
    boxShadow: "10px 13px 5px 0px #00000040",
    backgroundImage: `url('${ellipseFull}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom left",
  },
  ellipse2: {
    backgroundImage: `url('${ellipseHalf}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  fw700: {
    fontWeight: "700",
    color: "black",
  },
  fw400: {
    fontWeight: "400",
    color: "black",
  },
  right: {
    justifyContent: "right",
  },
}));
