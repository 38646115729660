import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/bkg.png";
const graph = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/graph.png";
const lightGraph = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/ttt.svg";
const today = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/today.png";
const lightToday = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/today.svg";
const campaign = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaign.png";
const total = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/total.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/total.svg";
const lightActive = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/active.svg";
const dynamic = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/dynamic.svg"

const Dynamic = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 70px 10px",
            sm: "130px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage: themeMode==="dark"?`url('${bkg}')`:`url('${dynamic}')`,
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={9}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography sx={{fontFamily:"Stolzl"}}>Leveraging advanced tools to significantly enhance the effectiveness of your fundraising efforts.</Typography>
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
              fontFamily:"Stolzl"
            }}
          >
            Accelerate Your Impact with OUR{" "}
            <span className={classes.color}
              style={{color:themeMode==="dark"? "#018C94":"#002C2F",}}
            > Dynamic Features</span>
          </Typography>
          <Typography sx={{fontFamily:"Stolzl"}}>
             By utilizing a suite of comprehensive features designed for campaign management, donation processing, and supporter engagement, organizations can amplify their reach and impact. 
          </Typography>
        </Grid>
        <Grid
          my={3}
          item
          className={classes.jCenter}
          md={9.7}
          sm={10}
          xs={11}
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
            width: { md: "40%", sm: "auto", xs: "auto" },
          }}
        >
          <Grid
            item
            md={3}
            container
            className={classes.alignCenter}
            sx={{
              flexDirection: { md: "column", sm: "row", xs: "row" },
              justifyContent: {
                md: "flex-start",
                sm: "space-around",
                xs: "center",
              },
            }}
          >
            <Grid className={classes.textCenter}>
              
              {
                themeMode==="dark"?
                <img src={total} alt="" className={classes.full} />
                :
                <img src={light} alt="" className={classes.full} />
              }
            </Grid>
            <Grid className={classes.textCenter}>
              
              {
                themeMode==="dark"?
                <img src={today} alt="" className={classes.full} />
                :
                <img src={lightToday} alt="" className={classes.full} />
              }
            </Grid>
          </Grid>
          <Grid item className={classes.textCenter} md={6}>
            {
                themeMode==="dark"?
                <img src={graph} alt="" className={classes.full} />
                :
                <img src={lightGraph} alt="" className={classes.full} />
              }
          </Grid>
          <Grid item className={classes.textCenter} md={3}>
            {
                themeMode==="dark"?
                <img src={campaign} alt="" className={classes.full} />
                :
                <img src={lightActive} alt="" className={classes.full} />
              }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Dynamic;
