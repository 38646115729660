import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  body: {
    // backgroundColor: "#1C1C1C",
    color:"#fff",
    width: "100%",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "'Montserrat', sans-serif",
  },
}));
