import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useStylesLight } from "./useStylesLight";
import { useSelector } from "react-redux";

const blur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/blur.svg";
const man  = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/man.svg";
const boy  = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Company/boy.svg";

const About = () => {
  const classes = useStyles();
  const classesLight = useStylesLight();
  const themeMode = useSelector((state) => state.themeMode.mode);
  if (themeMode === "dark") {
    return (
      <>
        <Grid
          className={classes.bkg}
          container
          sx={{
            padding: {
              md: "130px 70px",
              sm: "130px 20px ",
              xs: "100px 15px ",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              className={classes.columnCenter}
              item
              md={8}
              sm={9}
              xs={12}
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "14px" },
                paddingLeft: { md: "0px", sm: "25px", xs: "0px" },
                paddingRight: { md: "25px", sm: "25px", xs: "0px" },
              }}
            >
              <Typography
                className={classes.upper700}
                sx={{
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                ABOUT Acceleraise
              </Typography>
              <Typography
                my={2}
                className={classes.upper700}
                sx={{
                  fontSize: { md: "40px", sm: "36px", xs: "34px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                WHO WE ARE?
              </Typography>
              <Typography
                my={1}
                sx={{
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                  fontSize: {
                    md: "16px",
                    sm: "16px",
                    xs: "13px",
                    fontFamily: "Stolzl",
                  },
                }}
              >
                Acceleraise is a collective of dreamers, developers, and
                do-gooders dedicated to transforming the way the world thinks
                about fundraising. Founded on the belief that technology can and
                should serve a greater purpose, we've set out to create a
                platform that not only simplifies the donation process but also
                amplifies the impact of every contribution.
              </Typography>
              <Typography
                my={1}
                sx={{
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                  fontSize: {
                    md: "16px",
                    sm: "16px",
                    xs: "13px",
                    fontFamily: "Stolzl",
                  },
                }}
              >
                Our journey began with a simple observation: despite the
                critical role of nonprofits in addressing the world’s most
                pressing issues, many were struggling to connect with potential
                donors and secure the funds needed to carry out their missions.
                In an era where technology has the power to connect people
                across the globe, we saw an opportunity to bridge this gap.
                Thus, Acceleraise was born.
              </Typography>
            </Grid>

            <Grid
              my={3}
              item
              className={classes.jCenter}
              md={4}
              sm={3}
              xs={12}
              sx={{
                flexDirection: { md: "row", sm: "row", xs: "row" },
                width: { md: "40%", sm: "auto", xs: "auto" },
                justifyContent: {
                  md: "space-around",
                  sm: "space-around",
                  xs: "center",
                },
              }}
            >
              <Grid mx={1} sx={{ position: "relative", bottom: "10%" }}>
                <img src={man} alt="" style={{ width: "100%" }} />
              </Grid>
              <Grid mx={1} sx={{ position: "relative", top: "10%" }}>
                <img src={boy} alt="" style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          className={classesLight.bkg}
          container
          sx={{
            padding: {
              md: "130px 70px",
              sm: "130px 20px ",
              xs: "100px 15px ",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              className={classesLight.columnCenter}
              item
              md={8}
              sm={9}
              xs={12}
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "14px" },
                paddingLeft: { md: "0px", sm: "25px", xs: "0px" },
                paddingRight: { md: "25px", sm: "25px", xs: "0px" },
                color: "black",
              }}
            >
              <Typography
                className={classesLight.upper700}
                sx={{
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  fontFamily: "Stolzl",
                  color: "#B70000",
                }}
              >
                ABOUT Acceleraise
              </Typography>
              <Typography
                my={2}
                className={classesLight.upper700}
                sx={{
                  fontSize: { md: "40px", sm: "36px", xs: "34px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  fontFamily: "Stolzl",
                }}
              >
                WHO WE ARE?
              </Typography>
              <Typography
                my={1}
                sx={{
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                  fontSize: {
                    md: "16px",
                    sm: "16px",
                    xs: "13px",
                    fontFamily: "Stolzl",
                  },
                }}
              >
                Acceleraise is a collective of dreamers, developers, and
                do-gooders dedicated to transforming the way the world thinks
                about fundraising. Founded on the belief that technology can and
                should serve a greater purpose, we've set out to create a
                platform that not only simplifies the donation process but also
                amplifies the impact of every contribution.
              </Typography>
              <Typography
                my={1}
                sx={{
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                  fontSize: {
                    md: "16px",
                    sm: "16px",
                    xs: "13px",
                    fontFamily: "Stolzl",
                  },
                }}
              >
                Our journey began with a simple observation: despite the
                critical role of nonprofits in addressing the world’s most
                pressing issues, many were struggling to connect with potential
                donors and secure the funds needed to carry out their missions.
                In an era where technology has the power to connect people
                across the globe, we saw an opportunity to bridge this gap.
                Thus, Acceleraise was born.
              </Typography>
            </Grid>

            <Grid
              my={3}
              item
              className={classesLight.jCenter}
              md={4}
              sm={3}
              xs={12}
              sx={{
                flexDirection: { md: "row", sm: "row", xs: "row" },
                width: { md: "40%", sm: "auto", xs: "auto" },
                justifyContent: {
                  md: "space-around",
                  sm: "space-around",
                  xs: "center",
                },
              }}
            >
              <Grid mx={1} sx={{ position: "relative", bottom: "10%" }}>
                <img src={man} alt="" style={{ width: "100%" }} />
              </Grid>
              <Grid mx={1} sx={{ position: "relative", top: "10%" }}>
                <img src={boy} alt="" style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
};
export default About;
