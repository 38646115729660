import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Modal,
} from "@mui/material";
import { useStyles } from "./useStyles";
import { currencies } from "../../../../../../utils/globalFunctions";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_P2P_MEMBER, UPDATE_P2P_MEMBER } from "./Query";
import { setSpinnerLoading } from "../../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../../../store/alertSlice";
import { useSelector } from 'react-redux'

const cross = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg";

export function AddDonor({ open, setOpen, memberData, mode, setRefetchRows, p2pUuid }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const themeMode = useSelector(state => state.themeMode.mode)
    const [memberObj, setMemberObj] = useState({
        peerToPeerFundraiseUuid: p2pUuid,
        uuid: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: ""
    })

    useEffect(() => {
        if(mode === "EDIT" && memberData){
            setMemberObj({
                uuid: memberData?.uuid,
                firstName: memberData?.donor?.firstName,
                lastName: memberData?.donor?.lastName,
                email: memberData?.donor?.email,
                phoneNumber: memberData?.donor?.phoneNumber,
                role: memberData?.donor?.role,
            })
        }
        else if(mode === "ADD"){
            setMemberObj({
                peerToPeerFundraiseUuid: p2pUuid,
                uuid: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            })
        }
    }, [memberData, mode, ]);

    const [CreatePeerToPeerMember, { loading: createMemberLoading, error: createMemberError }] =
    useMutation(CREATE_P2P_MEMBER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("p2p member created successfully!"));
      },
    });

    const [UpdatePeerToPeerMember, { loading: updateMemberLoading, error: updateMemberError }] =
    useMutation(UPDATE_P2P_MEMBER, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("p2p member updated successfully!"));
      },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const variables = memberObj;
       
        console.log(variables)
        dispatch(setSpinnerLoading(true));
        try { 
            if(mode === "ADD"){
                const variables = {
                    peerToPeerFundraiseUuid: p2pUuid,
                    firstName: memberObj?.firstName,
                    lastName: memberObj?.lastName,
                    email: memberObj?.email,
                    phoneNumber: memberObj?.phoneNumber,
                }
                const result = await CreatePeerToPeerMember({ variables });
                setMemberObj((prevObj) => ({
                  ...prevObj,
                  firstName: result?.data?.createMember?.member?.firstName,
                  lastName: result?.data?.createMember?.member?.lastName,
                  email: result?.data?.createMember?.member?.email,
                  phoneNumber: result?.data?.createMember?.member?.phoneNumber
                }));
            }
            if(mode === "EDIT"){
                const variables = {
                    uuid: memberObj?.uuid,
                    role: memberObj?.role,
                }
                const result = await UpdatePeerToPeerMember({ variables });
                setMemberObj((prevObj) => ({
                  ...prevObj,
                  firstName: result?.data?.updateMember?.member?.firstName,
                  lastName: result?.data?.updateMember?.member?.lastName,
                  email: result?.data?.updateMember?.member?.email,
                  phoneNumber: result?.data?.updateMember?.member?.phoneNumber
                }));
            }
            dispatch(setSpinnerLoading(false));
            setOpen(false);
            setRefetchRows(true);
        } 
        catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
    };

    
    if (!open) return null;
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid
                    container
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                        padding: { md: "0% 30%", sm: "0px 15%", xs: "0px 0%" },
                        display:"flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backdropFilter: "blur(11px)",
                        width: "100%",
                        height: "100vh",
                        //bottom:mode==="EDIT"?"100px":"",
                    }}
                >
                    <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                            transform: {md:"scale(0.75)",sm:"scale(0.7)",xs:"scale(0.7)"},
                            padding: {md:"35px 70px",sm:"35px 70px",xs:"35px 25px"},
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            position: "relative",
                            bottom:{md:mode==="EDIT"?"11%":"0px",sm:mode==="EDIT"?"20%":"50px",xs:mode==="EDIT"?"20%":"70px"},
                            background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
                            boxShadow:
                              themeMode === 'dark'
                                ? '0px 0px 0px red'
                                : '0px 8px 5px 0px #00000040',
                            color: themeMode === 'dark' ? '#FFFF' : '#030303',
                            borderRadius: "15px",
                            overflow: "hidden",
                            height: "fit-content",
                            "&::before": {
                                content: "''",
                                position: "absolute",
                                inset: 0,
                                borderRadius: "15px",
                                padding: "1px",
                                background:
                                    themeMode === 'dark'
                                      ? 'linear-gradient(145deg, white, transparent)'
                                      : 'linear-gradient(180deg, white, transparent)',
                                WebkitMask:
                                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                WebkitMaskComposite: "xor",
                                maskComposite: "exclude",
                            },
                        }}
                    >

                        <Grid
                            sx={{
                                display: "flex",
                                flexDirection: { md: "row", sm: "row", xs: "row" },
                                justifyContent: "space-between",
                                alignItems: { md: "center", sm: "center", xs: "flex-end" },
                                width: "100%",
                                borderBottom:themeMode==="dark"?"1px solid #FFFF":"1px solid #030303",
                                padding:"20px 0px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { md: "20px", sm: "20px", xs: "16px" },
                                    fontWeight: "600",
                                    textAlign: "center",
                                    width: "100%",
                                    whiteSpace: {md:"nowrap",sm:"nowrap",xs:"wrap"},
                                }}
                            >
                                {mode === "ADD" ? "Add a new member to this fundraiser" : "Update Peer To Peer Member"}
                            </Typography>
                           
                        </Grid>
                        <Grid
                            item
                            mt={5}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            <form 
                                onSubmit={handleSubmit}
                            >
                                <FormControl
                                    sx={{ width: "100%", fontSize: "14px" }}
                                >
                                    <Grid item md={12} sm={12} xs={12} >
                                        <Typography my={2}
                                            sx={{ width: "100%", textAlign: "left", fontSize: "20px" }}>
                                            Email
                                        </Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            size="large"
                                            className={classes.fullInput}
                                            variant="outlined"
                                            id="outlined-basic"
                                            name="email"
                                            value={memberObj?.email}
                                            onChange={(event) => setMemberObj((prevObj) => ({
                                                ...prevObj,
                                                email: event.target.value
                                            }))}
                                            disabled={mode === "EDIT"}
                                            sx={{
                                                backgroundColor:
                                                    themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                    '&::placeholder': {
                                                        color: themeMode === 'dark' ? '#ffff' : '#000'
                                                    }
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                  color: themeMode === 'dark' ? '#ffff' : '#000'
                                                },
                      
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} >
                                        <Typography my={2}
                                            sx={{ width: "100%", textAlign: "left", fontSize: "20px" }}>
                                            First Name
                                        </Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            size="large"
                                            className={classes.fullInput}
                                            variant="outlined"
                                            id="outlined-basic"
                                            name="firstname"
                                            value={memberObj?.firstName}
                                            onChange={(event) => setMemberObj((prevObj) => ({
                                                ...prevObj,
                                                firstName: event.target.value
                                            }))}
                                            disabled={mode === "EDIT"}
                                            sx={{
                                                backgroundColor:
                                                    themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                    '&::placeholder': {
                                                        color: themeMode === 'dark' ? '#ffff' : '#000'
                                                    }
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                  color: themeMode === 'dark' ? '#ffff' : '#000'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} >
                                        <Typography my={2}
                                            sx={{ width: "100%", textAlign: "left", fontSize: "20px" }}>
                                            Last Name
                                        </Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            size="large"
                                            className={classes.fullInput}
                                            variant="outlined"
                                            id="outlined-basic"
                                            name="lastName"
                                            value={memberObj?.lastName}
                                            onChange={(event) => setMemberObj((prevObj) => ({
                                                ...prevObj,
                                                lastName: event.target.value
                                            }))}
                                            disabled={mode === "EDIT"}
                                            sx={{
                                                backgroundColor:
                                                    themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                    '&::placeholder': {
                                                        color: themeMode === 'dark' ? '#ffff' : '#000'
                                                    }
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                  color: themeMode === 'dark' ? '#ffff' : '#000'
                                                },
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item md={12} sm={12} xs={12} >
                                        <Typography my={2}
                                            sx={{ width: "100%", textAlign: "left", fontSize: "20px" }}>
                                            Phone Number
                                        </Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            size="large"
                                            className={classes.fullInput}
                                            variant="outlined"
                                            id="outlined-basic"
                                            name="phoneNumber"
                                            value={memberObj?.phoneNumber}
                                            onChange={(event) => setMemberObj((prevObj) => ({
                                                ...prevObj,
                                                phoneNumber: event.target.value
                                            }))}
                                            disabled={mode === "EDIT"}
                                        />
                                    </Grid> */}
                                    {mode !== "ADD" && 
                                        (<Grid item md={12} sm={12} xs={12} >
                                            <Typography my={2}
                                                sx={{ width: "100%", textAlign: "left", fontSize: "20px" }}>
                                                Role
                                            </Typography>
                                            <TextField
                                                select
                                                required
                                                fullWidth
                                                size="large"
                                                variant="outlined"
                                                id="outlined-basic"
                                                value={memberObj?.role} 
                                                onChange={(event) => setMemberObj((prevObj) => ({
                                                    ...prevObj,
                                                    role: event.target.value
                                                }))}
                                                className={classes.select}
                                                sx={{
                                                    backgroundColor:
                                                    themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                    '&::placeholder': {
                                                        color: themeMode === 'dark' ? '#ffff' : '#000'
                                                    }
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: themeMode === 'dark' ? '#ffff' : '#000',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                  color: themeMode === 'dark' ? '#ffff' : '#000'
                                                },}}
                                                SelectProps={{
                                                  MenuProps: {
                                                    PaperProps: {
                                                      style: {
                                                        maxHeight: 150,
                                                        Width: '100%',
                                                        background: themeMode === "dark" ? "#000" : "#ffff",
                                                      }
                                                    }
                                                  }
                                                }}
                                            >
                                                <MenuItem value={'member'} className={classes.menuItem}
                                                 sx={{
                                                  color: themeMode === "dark" ? "#fff" : "#000",
                                                  backgroundColor: themeMode === "dark" ? "#000" : "#ffff",
                                                }}>
                                                    <Typography sx={{ fontSize: '14px' }}>Member</Typography> 
                                                </MenuItem>
                                                <MenuItem value={'administrator'} className={classes.menuItem}>
                                                    <Typography sx={{ fontSize: '14px' }}>Administrator</Typography> 
                                                </MenuItem>
                                            </TextField>
                                        </Grid>)
                                    }
                                    <Grid my={6} sx={{display:"flex",flexDirection:"row",justifyContent:"space-evenly"}}>
                                        <Button mr={1} disableRipple sx={{backgorundColor:"transparent", border:themeMode==="dark"?"1px solid #FFFF":"1px solid #030303",borderRadius:"30px",padding:{md:"12px 25px",sm:"12px 25px",xs:"12px 15px"}}}
                                            onClick={() => setOpen(false)}
                                        >
                                            <Typography sx={{color: themeMode === 'dark' ? '#FFFF' : '#030303',textTransform:"capitalize",}}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                        <Button ml={1} sx={{background: "linear-gradient(274.32deg, #618F00 4.86%, #8ED003 168.46%)",borderRadius:"30px",padding:{md:"12px 25px",sm:"12px 25px",xs:"12px 15px"}}} type="submit">
                                            <Typography sx={{color:"#FFFF",textTransform:"capitalize",}}>
                                                {mode === "ADD" ? "Add Member" : "Update Member"}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </FormControl>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}
