import { gql } from "@apollo/client";


export const CREATE_P2P_MEMBER = gql`
    mutation createMember($peerToPeerFundraiseUuid: ID!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String){
        createMember(input:{peerToPeerFundraiseUuid: $peerToPeerFundraiseUuid, firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber}){
        member {
            donor {
                firstName
                lastName
                email
                phoneNumber
            }
            peerToPeerFundraise {
                user {
                    uuid
                    firstName
                    lastName
                    email
                    organization
                    jobTitle
                }
            }
        }
        }
    }
`;

export const UPDATE_P2P_MEMBER = gql`
    mutation UpdateMember($uuid: ID!, $role: String!){
        updateMember(input:{uuid: $uuid, role: $role}){
        member {
            donor {
                firstName
                lastName
                email
                phoneNumber
            }
            peerToPeerFundraise {
                user {
                    uuid
                    firstName
                    lastName
                    email
                    organization
                    jobTitle
                }
            }
        }
        }
    }
`;