import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchBar from "../SearchBar";
import { tableSupporter, supporterRow } from "../data";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import { GET_ALL_DONORS, CREATE_EXPORT } from "./Query";
import dayjs from "dayjs";
import { useStyles } from "./useStyles";
import {
  adjustNegativeMonths,
  downloadFile,
} from "../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePickerComp } from "../DatePickerComp.jsx";
import DocumentTitle from "../../../../DocumentTitle.js";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { GET_STATS } from "../Campaign/Query.jsx";

const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const downWhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const downBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const newicon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/newIconn.svg";
const date =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";

const Supporter = () => {
  DocumentTitle("Supporters - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [searchQuery, setSearchQuery] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [sortDonors, setSortDonors] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [donorRows, setDonorRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [pageStats, setPageStats] = useState([]);

  const { data: pagesStats } = useQuery(GET_STATS, {
    onCompleted: (result) => {
      if (result?.pageStats) {
        setPageStats(result?.pageStats?.supporterPage);
      }
    },
  });

  const [CreateExport, { loading: exportLoading, error: exportError }] =
    useMutation(CREATE_EXPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("export request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("export request was successful!"));
      },
    });

  const updateDonorRowState = (result) => {
    setDonorRows(result?.donors?.nodes);
    const pageInfo = result?.donors?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const {
    data: DonorData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_ALL_DONORS, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      sortColumn: sortDonors?.name,
      sortOrder: sortDonors?.order,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      updateDonorRowState(result);
    },
  });

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setDonorRows([...donorRows, ...result?.data?.donors?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor: result?.data?.donors?.pageInfo?.startCursor,
          endCursor: result?.data?.donors?.pageInfo?.endCursor,
          hasNextPage: result?.data?.donors?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleExport = async () => {
    const variables = {
      pageName: "Donor",
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateExport({ variables });
      if (result?.data?.createExport?.export?.success) {
        downloadFile(result?.data?.createExport?.export?.fileUrl);
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.container}
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.fw600}
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Supporters
          </Typography>
        </Grid>
        <Grid
          className={classes.centerAlign}
          item
          // md={6}
          // sm={5}
          // xs={12}
          sx={{
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "space-between",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            className={classes.flexStart}
            item
            xs={10}
            sx={{
              width: {
                md: "fit-content",
                sm: "fit-content",
                xs: "fit-content",
              },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              flexWrap: { md: "wrap", sm: "wrap", xs: "nowrap" },
              justifyContent: {
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
            }}
          >
            <Button
              className={classes.transButton}
              disableRipple
              sx={{
                padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElDateFilter(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "9.5px" },
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{
                      color: "#9CCA3C",
                      marginRight: { md: "10px", sm: "10px", xs: "5px" },
                    }}
                  />
                  {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                  {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElDateFilter}
              open={Boolean(anchorElDateFilter)}
              onClose={(e) => {
                setAnchorElDateFilter(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  background: themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                  width: "fit-content",
                  border: "2px solid #ABFC00",
                  borderRadius: "40px",
                },
              }}
            >
              <MenuItem>
                <Grid container spacing={2} alignItems="center">
                  {/* <Grid item xs={6} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dayjs(dateRange?.startDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            startDate: date,
                          }))
                        }
                        maxDate={dateRange?.endDate ? dayjs(dateRange?.endDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <span>-</span>
                  </Grid>
                  <Grid item xs={5} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dayjs(dateRange?.endDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            endDate: date,
                          }))
                        }
                        minDate={dateRange?.startDate ? dayjs(dateRange?.startDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                  <Grid item md={2} xs={12}>
                    <DatePickerComp
                      startDate={dateRange?.startDate}
                      endDate={dateRange?.endDate}
                      displayDate={setDateRange}
                    />{" "}
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>

            <Button
              className={classes.transButton}
              disableRipple
              sx={{
                display: { md: "flex", sm: "flex", xs: "flex" },
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElSort(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "11px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Sort
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElSort}
              open={Boolean(anchorElSort)}
              onClose={(e) => {
                setAnchorElSort(null);
              }}
              PaperComponent={Paper}
              sx={{
                "& .MuiPaper-root": {
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                  border: "1px solid #689900",
                  borderRadius: "10px",
                },
              }}
            >
              <Paper
                style={{
                  maxHeight: 350,
                  maxWidth: 250,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor:
                    themeMode === "dark"
                      ? "#8ED003CC #0F0F0F"
                      : "#8ED003 #E2E2E2",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "FIRST_NAME",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "FIRST_NAME" &&
                    sortDonors?.order === "ASCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Alphabetical Name
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "FIRST_NAME",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "FIRST_NAME" &&
                    sortDonors?.order === "DESCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Reverse Alphabetical Name
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "LAST_NAME",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "LAST_NAME" &&
                    sortDonors?.order === "ASCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Alphabetical Last Name
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "LAST_NAME",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "LAST_NAME" &&
                    sortDonors?.order === "DESCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Reverse Alphabetical Last Name
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "EMAIL",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "EMAIL" &&
                    sortDonors?.order === "ASCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Alphabetical Email
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "EMAIL",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "EMAIL" &&
                    sortDonors?.order === "DESCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Reverse Alphabetical Email
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "CREATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "CREATED_AT" &&
                    sortDonors?.order === "ASCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Created First
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "CREATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "CREATED_AT" &&
                    sortDonors?.order === "DESCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Recently Created
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "UPDATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "UPDATED_AT" &&
                    sortDonors?.order === "ASCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Oldest Updated
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonors({
                      name: "UPDATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonors?.name === "UPDATED_AT" &&
                    sortDonors?.order === "DESCENDING"
                  }
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Recently Updated
                </MenuItem>
              </Paper>
            </Menu>
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
          >
            <SearchBar specific="Search Donor" handleSearch={handleSearch} />
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{
              width: "100%",
              justifyContent: "center",
              display: { md: "none", sm: "none", xs: "flex" },
            }}
          >
            <SearchBar specific="Search Donor" handleSearch={handleSearch} />
          </Box>
        </Grid>

        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            md={7}
            sm={9}
            xs={12}
            my={{ md: 3, sm: 3, xs: 1 }}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
              alignItems: "center",
            }}
          >
            <Grid
              mr={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              className={classes.box}
              item
              container
              sx={{
                width: { md: "100%", sm: "100%", xs: "100%" },
                padding: { md: "15px 25px", sm: "15px 15px", xs: "10px 15px" },
                justifyContent: {
                  md: "space-between",
                  sm: "space-between",
                  xs: "center",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  className={classes.fs12}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Best Supporter
                </Typography>
                <Typography
                  className={classes.fs28}
                  my={1}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  {pageStats?.best_supporter}
                </Typography>
                <Typography
                  className={classes.neonBold}
                  sx={{ textAlign: { md: "left", sm: "left", xs: "center" } }}
                >
                  has donated ${pageStats?.best_supporter_donation}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              ml={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              className={classes.box}
              item
              container
              sx={{
                width: { md: "100%", sm: "100%", xs: "100%" },
                padding: { md: "15px 25px", sm: "15px 15px", xs: "10px 15px" },
                justifyContent: {
                  md: "space-between",
                  sm: "space-between",
                  xs: "center",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  className={classes.fs12}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Top Supported Campaign
                </Typography>
                <Typography
                  className={classes.fs28}
                  my={1}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Save Water
                </Typography>
                <Typography
                  className={classes.neonBold}
                  sx={{ textAlign: { md: "left", sm: "left", xs: "center" } }}
                >
                  has {pageStats?.top_supported_campaign} supporters
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={5}
            sm={3}
            xs={12}
            my={3}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disableRipple
              className={classes.transButton}
              onClick={() => {
                handleExport();
              }}
              sx={{
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 10px",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid container className={classes.nowrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",

                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: "16px",

                    width: "100%",
                  }}
                >
                  Export List
                  <img
                    src={themeMode === "dark" ? download : downloadLight}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TableContainer
            className={classes.tableContainer}
            id="table-container"
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
            }}
          >
            <Table
              className={classes.table}
              aria-label="simple table"
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F" }}
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.white}>
                  {tableSupporter?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        backgroundColor:
                          themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0", // Apply bottom left radius to the first item
                        borderBottomRightRadius:
                          index === tableSupporter.length - 1 ? "15px" : "0", // Apply bottom right radius to the last item
                        color:
                          index === tableSupporter.length - 1 &&
                          themeMode === "dark"
                            ? "#FFFF"
                            : "#030303",
                        borderRight:
                          index === tableSupporter.length - 1
                            ? "0px"
                            : undefined,
                      }}
                    >
                      <Typography
                        className={classes.tableHeadText}
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {donorRows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        No Records
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  donorRows?.map((row, index) => (
                    <TableRow
                      className={classes.row}
                      key={row.uuid}
                      component={Link}
                      to={`/dashboard/donor/${row.uuid}`}
                      sx={{
                        textDecoration: "none",
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "20%",
                          ...(index === tableSupporter.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.tableTextWhite}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row?.firstName}&nbsp;{row?.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        md={2}
                        sx={{
                          width: "20%",
                          ...(index === tableSupporter.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.email}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tableSupporter.length - 1 && {
                            borderRight: "0px",
                          }),
                        }}
                      >
                        <Typography className={classes.neon}>
                          {row.totalDonations}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "13%",
                          ...(index === tableSupporter.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {dayjs(row.firstDonationDate)?.format("YYYY-MM-DD")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tableSupporter.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          className={classes.white}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {dayjs(row.lastDonationDate)?.format("YYYY-MM-DD")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default Supporter;
