import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import RevenueGraph from "./RevenueGraph";
import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

const revenueDataStatic = [
  [8890, "2024-01-17"],
  [20000, "2024-01-18"],
  [2835, "2024-01-19"],
  [2500, "2024-01-20"],
  [100, "2024-01-21"],
  [1550, "2024-01-22"],
  [2380, "2024-01-23"],
  [101.25, "2024-01-24"],
  [2265.65, "2024-01-25"],
  [40, "2024-01-29"],
  [101.25, "2024-02-19"],
  [101.25, "2024-02-21"],
  [708.75, "2024-02-23"],
  [906.25, "2024-04-09"],
  [101.25, "2024-02-27"],
  [2206.25, "2024-03-05"],
  [101.25, "2024-03-06"],
  [405, "2024-03-07"],
  [15, "2024-03-28"],
  [1093.75, "2024-04-02"],
  [101.25, "2024-04-04"],
  [101.25, "2024-04-05"],
  [106.25, "2024-04-06"],
  [101.25, "2024-05-14"],
  [201.25, "2024-05-15"],
  [101.25, "2024-05-23"],
  [405, "2024-06-04"],
  [1215, "2024-06-05"],
  [101.25, "2024-06-06"],
  [101.25, "2024-06-12"],
];
const valueFormatter = (value) => `${value}$`;

const chartSetting = {
  yAxis: [{}],
  series: [{ dataKey: "x", valueFormatter }],
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

const graph =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/graph.svg";

export const GraphAnalysis = ({ revenueData }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  const transformedData = revenueDataStatic?.map((item) => ({
    x: item[0],
    y: item[1],
  }));
  return (
    <Grid
      container
      sx={{
        backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
        boxShadow:
          themeMode === "dark"
            ? "0px 0px 0px blue"
            : "4px 4px 14px 0px #00000024",
        borderRadius: "15px",
        height: "fit-content",
        padding: "18px 25px",
        height: "100%",
      }}
    >
      <Grid
        mb={2}
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
          }}
        >
          Total Revenue
        </Typography>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <BarChart
          dataset={transformedData}
          sx={{
            color: "#3f51b5",
          }}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "y",
            },
          ]}
          {...chartSetting}
        />
      </Grid>
    </Grid>
  );
};
