import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./component/HomePage/HomePage";
import { useStyles } from "./useStyles";
import SignIn from "./component/AuthPages/SignIn/SignIn";
import ForgotPassword from "./component/AuthPages/ForgotPassword/ForgotPassword";
import SignUp from "./component/AuthPages/SignUp/SignUp";
import DonationCheckout from "./component/DonationCheckout/DonationCheckout";
import MainLayout from "./component/MainLayout/MainLayout";
import Peer2Peer from "./component/MainLayout/Dashboard/CreateCampaign/PeerToPeer/PeerToPeer";
import "./index.css";
import { useDispatch } from "react-redux";
import { setuserDataProfile } from "./store/authSlice";
import ProtectedRoute from "./utils/protectedRoutes";
import NonProtectedRoute from "./utils/nonProtectedRoutes";
import { getUserFromLocalStorage } from "./services/localStorageService";
import Embedded from "./component/DonationCheckout/EmbedDonation/Embed";
import DonationCheckoutTest from "./component/DonationCheckout/Test/DonationCheckoutTest";
import DonorProfile from "./component/DonationCheckout/DonorProfile/DonorProfile";
import CustomizeTemplate from "./component/MainLayout/Dashboard/CreateCampaign/PublishCampaign/CustomizeTemplate/CustomizeTemplate.jsx";

import Feature from "./component/WebsitePages/Feature/Feature";
import Learn from "./component/WebsitePages/Learn/Learn";
import Company from "./component/WebsitePages/Company/Company";
import Pricing from "./component/WebsitePages/Pricing/Pricing";
import Contact from "./component/WebsitePages/Contact/Contact";


import CampaignManagement from "./component/WebsitePages/Feature/Attributes/CampaignManagement/CampaignManagement";
import DonationManagement from "./component/WebsitePages/Feature/Attributes/DonationManagement/DonationManagement";
import PaymentFeature from "./component/WebsitePages/Feature/Attributes/PaymentFeature/PaymentFeature";
import ReportingFeature from "./component/WebsitePages/Feature/Attributes/ReportingFeature/ReportingFeature";
import ElementFeature from "./component/WebsitePages/Feature/Attributes/ElementFeature/ElementFeature";
import RecurringFeature from "./component/WebsitePages/Feature/Attributes/RecurringFeature/RecurringFeature";
import CrowdsourceFeature from "./component/WebsitePages/Feature/Attributes/CrowdsourceFeature/CrowdsourceFeature";
import SupportFeature from "./component/WebsitePages/Feature/Attributes/24-7SupportFeature/SupportFeature";
import SupporterManagementFeature from "./component/WebsitePages/Feature/Attributes/SupporterManagementFeature/SupporterManagementFeature";
import DataExport from "./component/WebsitePages/Feature/Attributes/DataExport1/DataExport";

import PrivacyPolicy from "./component/WebsitePages/Security/PrivacyPolicy.jsx";
import TermsAndCondition from "./component/WebsitePages/Security/TermsAndCondition.jsx";
import PrivacyPolicyWrapper from "./component/WebsitePages/Security/PrivacyPolicyWrapper.jsx";
import ContactUs from "./component/WebsitePages/ContactUs/ContactUs";
import Career from "./component/WebsitePages/Career/Career";

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const themeMode = useSelector((state) => state.themeMode.mode);
  const fetchUser = () => {
    try {
      const user = getUserFromLocalStorage();
      dispatch(setuserDataProfile(user));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <Grid
      className={classes.body}
      sx={{
        scrollbarWidth: "thin",
        backgroundColor: themeMode === "dark" ? "#1C1C1C" : "",
        scrollbarColor:
          themeMode === "dark" ? "#1C1C1C #0F0F0F" : "#9CCA3C #ECECEC",
      }}
    >
      <Routes>
        <Route path="/" index element={<HomePage />} />
        <Route path="/features" index element={<Feature />} />
        {/* <Route path="/data-export" index element={<DataExport />} /> */}
        <Route path="/data-export" index element={<DataExport />} />
        <Route path="/campaign-management" index element={<CampaignManagement />} />
        <Route path="/donation-management" index element={<DonationManagement />} />
        <Route path="/payment-ecosystem" index element={<PaymentFeature />} />
        <Route path="/reporting-management" index element={<ReportingFeature />} />
        <Route path="/elements-management" index element={<ElementFeature />} />
        <Route path="/recurring-management" index element={<RecurringFeature />} />
        <Route path="/p2p-crowdsource" index element={<CrowdsourceFeature />} />
        <Route path="/support" index element={<SupportFeature />} />
        <Route path="/supporters-management" index element={<SupporterManagementFeature />} />    
        <Route path="/learn" index element={<Learn />} />
        <Route path="/company" index element={<Company />} />
        <Route path="/pricing" index element={<Pricing />} />
        <Route path="/help-center" index element={<Contact />} />
        <Route path="/career" index element={<Career />} />
        <Route
          path="/terms-and-condition"
          index
          element={<TermsAndCondition />}
        />
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/contact-us" index element={<ContactUs />} />

        <Route path="/embed-screen" element={<Embedded />} />
        <Route
          path="/campaign-donation/:formSelection/:uuid"
          index
          element={<DonationCheckout />}
        />
        <Route
          path="/customize-template/:uuid/:templateNo"
          element={<CustomizeTemplate />}
        />
        <Route path="/donor-profile" index element={<DonorProfile />} />
        <Route path="/signup" index element={<SignUp />} />

        <Route element={<NonProtectedRoute />}>
          <Route element={<SignIn />} path="/sign_in/*" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />} path="/dashboard/*" />
        </Route>
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Grid>
  );
}

export default App;
