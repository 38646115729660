import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  LinearProgress,
} from "@mui/material";
import { supporters } from "../../data";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
const TopSupporters = ({ topDonors }) => {
  const [seeSupporters, setSeeSupporters] = useState(false);
  const themeMode = useSelector(state => state.themeMode.mode)
  const handleSupporters = () => {
    setSeeSupporters(!seeSupporters);
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
        boxShadow:themeMode === 'dark' ?"0px 0px 0px blue":"4px 4px 14px 0px #00000024",
        borderRadius: "15px",
        height: "fit-content",
        padding: "25px",
      }}
    >
      <Grid
        mb={2}
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "700",color:themeMode==="dark"? "#FFFF":"#0F0F0F", }}>
          Top Supporters
        </Typography>
        <Button
          onClick={handleSupporters}
          component={Link}
          to="/dashboard/supporters" 
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
            textTransform: "capitalize",
            borderRadius: "25px",
            border:"1px solid transparent",
            "&:hover": {
              backgroundColor: themeMode==="dark"?"#2e3322":"#e3fca7",
              border:themeMode==="dark"?"1px solid transparent":"1px solid #9CCA3C"    
            },
          }}
        >
          See all
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          //maxHeight: {md:seeSupporters ? "" : `calc(100vh-180px )`,sm:seeSupporters ? "" : `calc(100vh - 255px)`,xs:seeSupporters ? "" : `calc(100vh - 180px)`}
          maxHeight: {
            md: seeSupporters ? "" : `calc(72vh )`,
            sm: seeSupporters ? "" : `calc(25vh)`,
            xs: seeSupporters ? "" : `calc(70vh)`,
          },
        }}
      >
        <div style={{ width: '100%', height: '400px', overflow: 'auto' }}>
          {topDonors?.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: "5px 10px" }}>
              <Grid
                sx={{
                  border: "1px solid #9CCA3C",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  justifyContent: "space-between",
                  "&:hover": {
                     backgroundColor: themeMode==="dark"?"#2e3322":"#e3fca7",
                    border:themeMode==="dark"?"1px solid transparent":"1px solid #9CCA3C"    
                  },
                }}
              >
                <Grid>
                  <Typography
                    sx={{
                      textWrap: "none",
                      fontSize: "16px",
                      fontWeight: "400",
                      color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                    }}
                  >
                    {item.first_name}&nbsp;{item.last_name}
                  </Typography>
                  <Typography
                    sx={{
                      textWrap: "none",
                      fontSize: "12px",
                      fontWeight: "400",
                      color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
                    }}
                  >
                    from {supporters[Math.floor(Math.random() * 6)]?.location}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ fontSize: "18px", fontWeight: "700",color:themeMode==="dark"? "#FFFF":"#0F0F0F", }}>
                    ${item.total_donation_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};
export default TopSupporters;
