import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../SearchBar";
import { tableP2P, p2pRow } from "../data";
import { useMutation, useQuery } from "@apollo/client";
import { useStyles } from "./useStyles";
import {
  GET_P2P,
  DELETE_P2P_FUNDRAISE,
  GET_CAMPAIGN_LIST,
  CREATE_EXPORT,
  GET_P2P_WITH_CAMPAIGN,
} from "./Query";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import { P2PPopup } from "./P2P-Popup/P2P-Popup";
import { ConfirmationDialog } from "../../../../utils/confirmationDialog";
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import {
  adjustNegativeMonths,
  downloadFile,
} from "../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePickerComp } from "../DatePickerComp.jsx";
import DocumentTitle from "../../../../DocumentTitle.js";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const downWhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const downBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg";
const upWhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upWhite.svg";
const upBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/upBlack.svg";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const dots =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots.svg";
const dotswhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dotswhite.svg";
const dotsgreen =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots green.svg";
const date =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";

const P2P = () => {
  DocumentTitle("P2P - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [openPopup, setOpenPopup] = useState(false);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [mode, setMode] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [anchorElFilterCampaign, setAnchorElFilterCampaign] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [hoveredRows, setHoveredRows] = useState({});
  const [selectedCampaignUuid, setSelectedCampaignUuid] = useState(null);
  const [sortP2P, setSortP2P] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [p2pRows, setP2PRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [refetchRows, setRefetchRows] = useState(false);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [CreateExport, { loading: exportLoading, error: exportError }] =
    useMutation(CREATE_EXPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("export request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("export request was successful!"));
      },
    });

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const updateP2PRowState = (result) => {
    setP2PRows(result?.peerToPeerFundraises?.nodes);
    const pageInfo = result?.peerToPeerFundraises?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const [
    DeletePeerToPeerFundraise,
    { loading: peerDeleteLoading, error: peerDeleteError },
  ] = useMutation(DELETE_P2P_FUNDRAISE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("the delete was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("p2p deleted successfully!"));
    },
  });

  const {
    data: p2pData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_P2P_WITH_CAMPAIGN, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      sortColumn: sortP2P?.name,
      sortOrder: sortP2P?.order,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      campaignUuid: selectedCampaignUuid ? selectedCampaignUuid : "",
    },
    onCompleted: (result) => {
      updateP2PRowState(result);
    },
  });

  useEffect(() => {
    // if (refetchRows) {
    //   refetch().then((result) => {
    //     updateP2PRowState(result?.data);
    //   });
    //   setRefetchRows(false);
    // }
    refetch({
      variables: selectedCampaignUuid
        ? {
            campaignUuid: selectedCampaignUuid,
          }
        : {},
    }).then((result) => {
      updateP2PRowState(result?.data);
    });
  }, [selectedCampaignUuid, refetch]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setP2PRows([...p2pRows, ...result?.data?.peerToPeerFundraises?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor:
            result?.data?.peerToPeerFundraises?.pageInfo?.startCursor,
          endCursor: result?.data?.peerToPeerFundraises?.pageInfo?.endCursor,
          hasNextPage:
            result?.data?.peerToPeerFundraises?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleDelete = async () => {
    if (selectedRow?.uuid) {
      const variables = {
        uuid: selectedRow?.uuid,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await DeletePeerToPeerFundraise({ variables });
        dispatch(setSpinnerLoading(false));
        setRefetchRows(true);
        setConfirmationDialog(false);
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
        setConfirmationDialog(false);
      }
    }
  };

  const handleHover = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: false,
    }));
  };

  const handleExport = async () => {
    const variables = {
      pageName: "PeerToPeerFundraise",
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateExport({ variables });
      if (result?.data?.createExport?.export?.success) {
        downloadFile(result?.data?.createExport?.export?.fileUrl);
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.fw600}
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            P2P Fundraisers
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          className={classes.centerAlign}
          sx={{
            justifyContent: {
              md: "space-between",
              sm: "space-between",
              xs: "flex-start",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Grid
            className={classes.centerAlign}
            sx={{
              justifyContent: {
                md: "space-between",
                sm: "space-between",
                xs: "flex-start",
              },
              flexDirection: { md: "column", sm: "column", xs: "row" },
              flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
            }}
          >
            <Box
              className={classes.flexStart}
              item
              xs={10}
              sx={{
                justifyContent: {
                  md: "flex-start",
                  sm: "space-between",
                  xs: "space-between",
                },
                width: { md: "100%", sm: "100%", xs: "fit-content" },
                padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              }}
            >
              <Button
                className={classes.dullButton}
                disableRipple
                onClick={() => {
                  setMode("ADD");
                  setOpenPopup(true);
                }}
                sx={{
                  padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 8px" },
                }}
              >
                <Grid
                  className={classes.noWrapCenter}
                  item
                  md={12}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "13px" },
                  }}
                >
                  + New P2P Fundraiser
                </Grid>
              </Button>

              <Button
                className={classes.transButton}
                disableRipple
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 15px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElFilterCampaign(e.currentTarget);
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "13px" },
                      display: "flex",
                      alignItems: "center",
                      textWrap: "nowrap",
                    }}
                  >
                    Filter
                    <img src={down} alt="" style={{ marginLeft: "10px" }} />
                  </Typography>
                  <Grid className={classes.centerText} item md={4}></Grid>
                </Grid>
              </Button>

              <Button
                disableRipple
                className={classes.clrButton}
                sx={{
                  display: { md: "none", sm: "flex", xs: "none" },
                  padding: {
                    md: "10px 30px",
                    sm: "10px 15px",
                    xs: "8px 10px",
                  },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      textWrap: "nowrap",
                      fontSize: "16px",
                      width: "100%",
                    }}
                    onClick={() => {
                      handleExport();
                    }}
                  >
                    Export List
                    <img
                      src={themeMode === "dark" ? download : downloadLight}
                      alt=""
                      style={{ marginLeft: "10px" }}
                    />
                  </Typography>
                </Grid>
              </Button>

              <Box
                item
                xs={1}
                my={{ xs: 2 }}
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <SearchBar specific="Search P2P" handleSearch={handleSearch} />
              </Box>
            </Box>

            <Box sx={{ display: "flex", width: "100%" }}>
              <Button
                className={classes.transButton}
                disableRipple
                sx={{
                  display: { md: "flex", sm: "flex", xs: "none" },
                  padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 6px" },
                  flexWrap: "nowrap",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElDateFilter(e.currentTarget);
                }}
              >
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "11px", xs: "10px" },
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{
                      color: "#9CCA3C",
                      marginRight: { md: "10px", sm: "10px", xs: "5px" },
                    }}
                  />
                  {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                  {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                </Typography>
              </Button>
              <Menu
                anchorEl={anchorElDateFilter}
                open={Boolean(anchorElDateFilter)}
                onClose={(e) => {
                  setAnchorElDateFilter(null);
                }}
                sx={{
                  "& .MuiPaper-root": {
                    background:
                      themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                    width: "fit-content",
                    border: "2px solid #ABFC00",
                    borderRadius: "40px",
                  },
                }}
              >
                <MenuItem>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item md={2} xs={12}>
                      <DatePickerComp
                        startDate={dateRange?.startDate}
                        endDate={dateRange?.endDate}
                        displayDate={setDateRange}
                      />{" "}
                    </Grid>
                  </Grid>
                </MenuItem>
              </Menu>

              <Button
                className={classes.transButton}
                disableRipple
                sx={{
                  display: { md: "flex", sm: "flex", xs: "none" },
                  padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 6px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElFilterCampaign(e.currentTarget);
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      display: "flex",
                      alignItems: "center",
                      textWrap: "nowrap",
                    }}
                  >
                    Filter by campaign
                    <img src={down} alt="" style={{ marginLeft: "5px" }} />
                  </Typography>
                  <Grid className={classes.centerText} item md={4}></Grid>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElFilterCampaign}
                open={Boolean(anchorElFilterCampaign)}
                onClose={(e) => {
                  setAnchorElFilterCampaign(null);
                }}
                sx={{
                  "& .MuiPaper-root": {
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                    border: "1px solid #689900",
                    borderRadius: "10px",
                  },
                }}
              >
                <Paper
                  style={{
                    maxHeight: 350,
                    maxWidth: 250,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor:
                      themeMode === "dark"
                        ? "#8ED003CC #0F0F0F"
                        : "#8ED003 #E2E2E2",
                  }}
                >
                  <MenuItem
                    value={null}
                    onClick={() => {
                      setSelectedCampaignUuid(null);
                      setAnchorElFilterCampaign(null);
                    }}
                    selected={selectedCampaignUuid === null}
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                      fontWeight: "600",
                      "&:hover": {
                        color: "#689900",
                      },
                      "&:focus": {
                        color: "#689900",
                      },
                    }}
                  >
                    All Campaign
                  </MenuItem>
                  {campaignList?.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      onClick={() => {
                        setSelectedCampaignUuid(option.uuid);
                        setAnchorElFilterCampaign(null);
                      }}
                      selected={option.uuid === selectedCampaignUuid}
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                        fontWeight: "600",
                        "&:hover": {
                          color: "#689900",
                        },
                        "&:focus": {
                          color: "#689900",
                        },
                      }}
                    >
                      {option.title?.length > 25
                        ? `${option.title.substring(0, 25)}...`
                        : option.title}
                    </MenuItem>
                  ))}
                </Paper>
              </Menu>
              <Button
                className={classes.transButton}
                disableRipple
                sx={{
                  display: { md: "flex", sm: "flex", xs: "none" },
                  padding: { md: "10px 20px", sm: "10px 15px", xs: "8px 6px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElSort(e.currentTarget);
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      display: "flex",
                      flexDirection: "row",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    }}
                  >
                    Sort by
                    <img src={down} alt="" style={{ marginLeft: "10px" }} />
                  </Typography>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElSort}
                open={Boolean(anchorElSort)}
                onClose={(e) => {
                  setAnchorElSort(null);
                }}
                PaperComponent={Paper}
                sx={{
                  "& .MuiPaper-root": {
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                    border: "1px solid #689900",
                    borderRadius: "10px",
                  },
                }}
              >
                <Paper
                  style={{
                    maxHeight: 350,
                    maxWidth: 250,
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor:
                      themeMode === "dark"
                        ? "#8ED003CC #0F0F0F"
                        : "#8ED003 #E2E2E2",
                  }}
                >
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "NAME",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "NAME" && sortP2P?.order === "ASCENDING"
                    }
                  >
                    Name A to Z
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "NAME",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "NAME" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Name Z to A
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "MESSAGE",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "MESSAGE" &&
                      sortP2P?.order === "ASCENDING"
                    }
                  >
                    Message A to Z
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "MESSAGE",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "MESSAGE" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Message Z to A
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "GOAL_AMOUNT",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "GOAL_AMOUNT" &&
                      sortP2P?.order === "ASCENDING"
                    }
                  >
                    Goal Amount Low to High
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "GOAL_AMOUNT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "GOAL_AMOUNT" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Goal Amount High to Low
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "UPDATED_AT",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "UPDATED_AT" &&
                      sortP2P?.order === "ASCENDING"
                    }
                  >
                    Oldest Updated
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "UPDATED_AT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "UPDATED_AT" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Recently Updated
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "CREATED_AT",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "CREATED_AT" &&
                      sortP2P?.order === "ASCENDING"
                    }
                  >
                    Oldest Created
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "CREATED_AT",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "CREATED_AT" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Recently Created
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "GOAL_DATE",
                        order: "ASCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "GOAL_DATE" &&
                      sortP2P?.order === "ASCENDING"
                    }
                  >
                    Earliest Goal Date
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                    className={classes.menuItem}
                    onClick={(e) => {
                      setAnchorElSort(null);
                      setSortP2P({
                        name: "GOAL_DATE",
                        order: "DESCENDING",
                      });
                    }}
                    selected={
                      sortP2P?.name === "GOAL_DATE" &&
                      sortP2P?.order === "DESCENDING"
                    }
                  >
                    Latest Goal Date
                  </MenuItem>
                </Paper>
              </Menu>

              <Box
                item
                xs={1}
                my={{ xs: 2 }}
                sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
              >
                <SearchBar specific="Search P2P" handleSearch={handleSearch} />
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            md={2}
            sm={3}
            xs={12}
            className={classes.noWrap}
            sx={{
              display: {
                md: "flex !important",
                sm: "none !important",
                xs: "flex !important",
              },
              justifyContent: {
                md: "flex-end",
                sm: "flex-end",
                xs: "space-between",
              },
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            <Button
              className={classes.transButton}
              disableRipple
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 15px",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElSort(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: { md: "12px", sm: "12px", xs: "10px" },
                    display: "flex",
                    flexDirection: "row",
                    textWrap: "nowrap",
                    color: themeMode === "dark" ? "#ffff" : "#000",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                  }}
                >
                  Sort by
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>

            <Button
              disableRipple
              className={classes.clrButton}
              sx={{
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 10px",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid container className={classes.nowrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",

                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: "16px",

                    width: "100%",
                  }}
                  onClick={() => {
                    handleExport();
                  }}
                >
                  Export List
                  <img
                    src={themeMode === "dark" ? download : downloadLight}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12} my={3}>
          <TableContainer
            className={classes.tableContainer}
            id="table-container"
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
            }}
          >
            <Table
              className={classes.table}
              aria-label="simple table"
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F" }}
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.white}>
                  {tableP2P?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        backgroundColor:
                          themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0", // Apply bottom left radius to the first item
                        borderBottomRightRadius:
                          index === tableP2P.length - 1 ? "15px" : "0", // Apply bottom right radius to the last item
                        color:
                          index === tableP2P.length - 1 && themeMode === "dark"
                            ? "#FFFF"
                            : "#030303",
                        borderRight:
                          index === tableP2P.length - 1 ? "0px" : undefined,
                      }}
                    >
                      <Typography
                        className={classes.tableHeadText}
                        sx={{
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {p2pRows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      sx={{ color: "#fff" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        No Records
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  p2pRows?.map((row, index) => (
                    <TableRow
                      className={classes.row}
                      key={index}
                      sx={{
                        textDecoration: "none",
                        zIndex: "10",
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "25%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            className={classes.tableTextWhite}
                            sx={{
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                            }}
                          >
                            {row.campaign.title?.length > 18
                              ? `${row.campaign.title?.substring(0, 18)}...`
                              : row.campaign.title}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        md={2}
                        sx={{
                          width: "12%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography
                            className={classes.white}
                            sx={{
                              fontSize: { md: "16px", sm: "13px", xs: "12px" },
                              color: themeMode === "dark" ? "#FFFF" : "#030303",
                            }}
                          >
                            {row.user.firstName}&nbsp;{row.user.lastName}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography className={classes.neon}>
                            {`${getSymbolFromCurrency(row.goalCurrency)} ${
                              row.goalAmount
                            }  ${row.goalCurrency}`}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                            color: "#fff",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography className={classes.neon}>
                            $ {row?.campaign?.raised?.toFixed(2)}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                            color: "#fff",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography className={classes.neon}>
                            {dayjs(row.createdAt)?.format("YYYY-MM-DD")}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tableP2P.length - 1 && {
                            borderRight: "0px",
                            color: "#fff",
                          }),
                        }}
                      >
                        <Link
                          to={`/dashboard/p2p/${row.uuid}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <Typography className={classes.neon}>
                            0 UFV/$10
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === tableP2P.length - 1 && {}),
                        }}
                        onClick={(e) => {
                          if (!anchorEl) {
                            setSelectedRow(row);
                          }
                        }}
                      >
                        <Grid>
                          <Button
                            disableRipple
                            className={classes.dots}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <img
                              src={
                                hoveredRows[index] && themeMode === "dark"
                                  ? dotswhite
                                  : hoveredRows[index] && themeMode === "light"
                                  ? dotsgreen
                                  : dots
                              }
                              alt=""
                              onMouseOver={() => handleHover(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                              style={{ borderRadius: "50%" }}
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => {
                              setAnchorEl(null);
                            }}
                            PaperComponent={Paper}
                            className={classes.paperLight}
                            sx={{
                              "& .MuiPaper-root": {
                                color: themeMode === "dark" ? "#FFF" : "#111",
                                backgroundColor:
                                  themeMode === "dark" ? "#111" : "#FFF",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setMode("EDIT");
                                setOpenPopup(true);
                              }}
                            >
                              <EditIcon fontSize="small" />
                              <Typography ml={1}>Edit</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setConfirmationDialog(true);
                              }}
                            >
                              <DeleteIcon
                                className={classes.mr1}
                                fontSize="small"
                              />
                              <Typography ml={1}>Delete</Typography>
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <P2PPopup
        open={openPopup}
        setOpen={setOpenPopup}
        p2pData={mode === "EDIT" ? selectedRow : null}
        mode={mode}
        setRefetchRows={setRefetchRows}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setConfirmationDialog}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this?"}
        handleConfirmation={handleDelete}
      />
    </>
  );
};
export default P2P;
