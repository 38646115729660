import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  fullInput: {
    mt: { md: 4, sm: 2, xs: 2 },
    mr: 1,
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",

    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",

      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "13px",
      marginBottom: "20px",
      transform: "translate(0px, -40px) scale(1.25)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -40px) scale(1.25)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#acff00",
    },
    "&.Mui-active .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "white", // Set border color to white for disabled state
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input": {
      color: "white", // Set text color to white for disabled state
    },

    outline: "none",
  },
  fullInputLight: {
    mt: { md: 4, sm: 2, xs: 2 },
    mr: 1,
    backgroundColor: "lightgray",
    borderRadius: "10px",

    "& .MuiOutlinedInput-input": {
      color: "black",

      "&::placeholder": {
        color: "black",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "13px",
      marginBottom: "20px",
      transform: "translate(0px, -40px) scale(1.25)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -40px) scale(1.25)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#acff00",
    },
    "&.Mui-active .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "white", // Set border color to white for disabled state
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-input": {
      color: "white", // Set text color to white for disabled state
    },

    outline: "none",
  },
  select: {
    mt: { md: 4, sm: 2, xs: 2 },
    mr: 1,
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",

    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",

      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "13px",
      marginBottom: "20px",
      transform: "translate(0px, -40px) scale(1.25)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -40px) scale(1.25)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#acff00",
    },
    "&.Mui-active .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },

    "& .Mui-disabled": {
      "-webkit-text-fill-color": "inherit",
      color: "white",
      "& .MuiSelect-icon": {
        color: "white",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },

    outline: "none",
  },
  selectLight: {
    mt: { md: 4, sm: 2, xs: 2 },
    mr: 1,
    backgroundColor: "lightgray",
    borderRadius: "10px",

    "& .MuiOutlinedInput-input": {
      color: "black",

      "&::placeholder": {
        color: "black",
      },
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "13px",
      marginBottom: "20px",
      transform: "translate(0px, -40px) scale(1.25)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -40px) scale(1.25)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#acff00",
    },
    "&.Mui-active .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },

    "& .Mui-disabled": {
      "-webkit-text-fill-color": "inherit",
      color: "white",
      "& .MuiSelect-icon": {
        color: "white",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
    },

    outline: "none",
  },
  menuItem: {
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      color: "#689900",
      backgroundColor: "#0D0D0D",
    },
    "&:focus": {
      color: "#689900",
      backgroundColor: "#000",
    },
    "&.Mui-selected": {
      color: "#689900",
      backgroundColor: "#000",
      "&:hover": {
        color: "#689900",
        backgroundColor: "#0D0D0D",
      },
    },
  },
}));
