import { makeStyles } from "@mui/styles";

const line = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/line.png";

export const useStylesLight = makeStyles((theme) => ({
  fullInput: {
    mr: 1,
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      color: "black",
      "&::placeholder": {
        color: "black",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#030303",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      outline: "none",
    },
  },
  halfInput: {
    backgroundColor: "white",
    borderRadius: "10px",
    width: "49%",
    "& .MuiOutlinedInput-input": {
      color: "black",
      "&::placeholder": {
        color: "black",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  line: {
    overflow: "visible",
    backgroundImage: `url('${line}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    position: "relative",
  },
  rowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  select: {
    backgroundColor: "white",
    borderRadius: "10px",
    width: "100%",
    textAlign: "left",
    "& .MuiOutlinedInput-input": {
      color: "black",
      "&::placeholder": {
        color: "black",
      },
    },
    "& .MuiInputLabel-root": {
      color: "black",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#030303",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
    },
  },
  menuItem: {
    fontWeight: "600",
    color: "#8ED003",
    backgroundColor: "#FFF",

    "&:hover": {
      backgroundColor: "#8ED003",
      color: "#FFF",
    },
    "&:focus": {
      backgroundColor: "#344d02",
      color: "#FFF",

      "&:hover": {
        backgroundColor: "#8ED003",
        color: "#FFF",
      },
    },
  },
  justifyLeft: {
    display: "flex",
    justifyContent: "left",
  },
  checkbox: {
    color: "#689900",
  },
  flex: {
    display: "flex",
  },
  signup: {
    background: "transparent",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px 15px",
  },
  inputText: {
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "capitalize",
    width: "100%",
    textAlign: "left",
    color: "#030303"
  },
}));
