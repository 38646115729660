// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
  },
  reducers: {
    setuserDataProfile: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          user: action.payload,
          isAuthenticated: true,
        };
      } else {
        return {
          ...state,
          user: null,
          isAuthenticated: false,
        };
      }
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setuserDataProfile, loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
