import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  fw600: {
    fontWeight: "600",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
  },
  flexStart: {
    display: "flex",
    flexWrap: "wrap",
  },
  transButton: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    
  },
  noWrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
  noWrapCenter: {
    display: "flex",
    alignItems: "center",
    textWrap: "nowrap",
    fontWeight: "500",
    width: "100%",
  },
  centerText: {
    textAlign: "center",
    alignItems: "center",
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  box: {
    border: "2px solid #9CCA3C",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    
  },
  fs12: {
    fontSize: "12px",
    fontWeight: "500",
  },
  fs28: {
    fontSize: "28px",
    fontWeight: "700",
    flexWrap: "nowrap",
  },
  fs14: {
    fontSize: "14px",
    fontWeight: "500",
    textWrap: "nowrap",
  },
  spaceEven: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  neonBold: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#00FF0A",
    whiteSpace: "nowrap",
  },
  fs10: {
    color: "#FFFFFF80",
    textAlign: "right",
    fontSize: "10px",
  },
  tableContainer: {
    zIndex: "0",
    padding: "20px",
    paddingTop: "0px",
    borderRadius: "15px",
    maxHeight: "350px", 
    overflowY: "auto",
  },
  table: {
    width: "100%",
    color: "#fff",
  },
  white: {
    color: "#fff",
    textWrap: "nowrap",
  },
  neon: {
    color: "#8ED003",
    textWrap: "nowrap",
  },
  tableHeadText: {
    fontSize: "14px",
    fontWeight: "700",
    whiteSpace: "nowrap",
  },
  row: {
    "&:last-child td, &:last-child th": {
      borderBottom: 0,
    },
  },
  tableTextWhite: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "30px",
    width: "fit-content",
    textWrap: "nowrap",
  },
  menuItem:{
    fontWeight: "600",
  "&:hover": {
    color: "#689900",
  },
  "&:focus": {
    color: "#689900",
  },
},
  clrButton: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #8ED003",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 0px",
    width: "fit-content",
  },  

}));
