import React, { useState } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowRightAlt } from "@mui/icons-material";
import { data, fees, insight, donor, comments, emails } from "./data.jsx";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_DONATION, REFUND_DONATION, GET_EXCHANGE_RATE } from "./Query";
import { useStyles } from "./useStyles";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../../../../utils/globalFunctions.js";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import getSymbolFromCurrency from "currency-symbol-map";

const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const gift =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/donations.svg";
const giftBlack =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/donationsBlack.svg";

const Reciept = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const [donationData, setDonationData] = useState(null);
  const processCost = 1.25;
  const [exchangeCurrency, setExchangeCurrency] = useState(null);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const { data: exchangeCurrencyData, loading: exchangeCurrencyLoading } =
    useQuery(GET_EXCHANGE_RATE, {
      variables: {
        amount: exchangeCurrency?.amount,
        fromCurrency: exchangeCurrency?.fromCurrency,
        toCurrency: exchangeCurrency?.toCurrency,
      },
    });

  const [
    RefundDonation,
    { loading: refundDonationLoading, error: refundDonationError },
  ] = useMutation(REFUND_DONATION, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("refund donation request was unsuccessful!"));
    },
    onCompleted: (data) => {
      if (data?.refundDonation?.donation) {
        setDonationData(data?.refundDonation);
      }
      dispatch(success("refund donation request was successful!"));
    },
  });

  const {
    data: DonationData,
    loading,
    refetch,
  } = useQuery(GET_DONATION, {
    variables: {
      uuid: uuid,
    },
    onCompleted: (result) => {
      if (result?.donation) {
        setDonationData(result);
        if (result?.donation?.currency !== "USD") {
          setExchangeCurrency({
            amount: result?.donation?.amount,
            fromCurrency: result?.donation?.currency,
            toCurrency: "USD",
          });
        }
      }
    },
  });

  const handleRefund = async (event) => {
    event.preventDefault();
    const variables = {
      uuid: uuid,
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await RefundDonation({ variables });
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          mb={3}
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>

              <Grid className={classes.fullWidth}>
                <Button
                  className={classes.transButton}
                  href="/dashboard/donations"
                  sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
                >
                  <Grid
                    className={classes.flexCenter}
                    sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                  >
                    <img
                      src={themeMode === "dark" ? gift : giftBlack}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "14px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      Donations
                    </Typography>
                  </Grid>
                </Button>
                <Grid
                  className={classes.flexCenter}
                  mx={{ md: 1, sm: 1, xs: 0 }}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  <ArrowRightAlt />
                </Grid>
                <Button
                  className={classes.transButton}
                  sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
                >
                  <Grid className={classes.flexCenter}>
                    <Typography
                      className={classes.neonButton}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "14px" },
                      }}
                    >
                      #{uuid ? uuid.slice(0, uuid.indexOf("-")) : ""}
                    </Typography>
                  </Grid>
                </Button>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Grid className={classes.container}>
                <Typography
                  className={classes.fw600}
                  sx={{
                    fontSize: { md: "40px", sm: "40px", xs: "40px" },
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                  }}
                >
                  {donationData?.donation?.amount}&nbsp;
                  {donationData?.donation?.currency}
                </Typography>
                {donationData?.donation?.currency !== "USD" &&
                  exchangeCurrencyData?.exchangeRate?.currency && (
                    <Grid mx={3} className={classes.bottom}>
                      <Typography
                        className={classes.fw500}
                        sx={{
                          fontSize: { md: "20px", sm: "20px", xs: "20px" },
                          textAlign: { md: "left", sm: "left", xs: "center" },
                        }}
                      >
                        ≈{" "}
                        {`${getSymbolFromCurrency(
                          exchangeCurrencyData?.exchangeRate?.currency
                        )} ${exchangeCurrencyData?.exchangeRate?.amount?.toFixed(
                          2
                        )} ${exchangeCurrencyData?.exchangeRate?.currency}`}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item my={3} md={6} sm={3.7} xs={12} className={classes.rowEnd}>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.refund}
                onClick={handleRefund}
                sx={{
                  padding: { md: "10px 25px", sm: "10px 15px", xs: "8px 10px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  margin: {
                    md: "10px 10px 10px 5px",
                    sm: "10px 10px 10px 5px",
                    xs: "10px 0px 10px 5px",
                  },
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  border:
                    themeMode === "dark"
                      ? "1px solid #FFFFFF"
                      : "1px solid #030303",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    border:
                      themeMode === "dark"
                        ? "1px solid #FFFFFF"
                        : "1px solid #030303",
                  },
                }}
              >
                Refund Donation
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.download}
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  margin: {
                    md: "10px 10px 10px 5px",
                    sm: "10px 10px 10px 5px",
                    xs: "10px 0px 10px 5px",
                  },
                }}
              >
                Download Receipt
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          className={classes.divider}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
          }}
        >
          {/* {data.map((item, index) => (
            <Grid
              item
              md={2}
              sm={2}
              className={classes.flex}
              sx={{
                flexDirection: { md: "column", sm: "column", xs: "row" },
                justifyContent: { md: "left", sm: "left", xs: "space-between" },
              }}
            >
              <Grid item md={12} sm={12} xs={6}>
                <Typography className={classes.fs12}>{item.name}</Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={6}>
                <Typography
                  className={classes.neon}
                  sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
                >
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          ))} */}
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Status</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  color: "#00FF0A",
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {donationData?.donation?.status
                  ? capitalizeFirstLetter(donationData?.donation?.status)
                  : "Successful"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Donor</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {donationData?.donation?.donor?.firstName}&nbsp;
                {donationData?.donation?.donor?.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Campaign</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {donationData?.donation?.campaign?.title?.substring(0, 8) +
                  "..."}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Donation Date</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {dayjs(donationData?.donation?.createdAt)?.format("YYYY-MM-DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Frequency</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {donationData?.donation?.recurringDonation
                  ? capitalizeFirstLetter(
                      donationData?.donation?.recurringDonation?.frequency
                    )
                  : "One-Time"}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Payment Method</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{
                  fontSize: { md: "18px", sm: "13px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "right" },
                  color: themeMode === "dark" ? "#70FF00" : "#8ED003",
                }}
              >
                {capitalizeFirstLetter(
                  donationData?.donation?.stripeCharge?.payment_method
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Box
          item
          className={classes.fit}
          sx={{
            width: { md: "100%", sm: "100%", xs: "100%" },
            height: "fit-content",
            paddingTop: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            md={4.5}
            sm={4.5}
            xs={12}
            mt={{ md: 4, sm: 4, xs: 3 }}
            mr={{ md: 3, sm: 2 }}
            className={classes.box}
            sx={{
              padding: { md: "15px 35px", sm: "15px 25px", xs: "15px 20px" },
              background: themeMode === "dark" ? "#0000003B" : "#ECECECDD",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Typography mb={2} className={classes.heading}>
              Insight
            </Typography>

            {/* {insight.length > 0 ? (
              insight.map((item, index) => (
                <Grid key={index} mb={1} className={classes.mapped}>
                  <Grid>
                    <Typography className={classes.fs12}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography className={classes.fs12w600}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography>No Data Available</Typography>
            )} */}
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>IP Address</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.ipAddress
                    ? donationData?.donation?.ipAddress
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>IP Geolocation</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.city
                    ? `${donationData?.donation?.city},`
                    : ""}{" "}
                  {donationData?.donation?.region
                    ? `${donationData?.donation?.region},`
                    : ""}{" "}
                  {donationData?.donation?.country
                    ? donationData?.donation?.country
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>Browser</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.browser
                    ? donationData?.donation?.browser
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>Device</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.device
                    ? donationData?.donation?.device
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>
                  Suggested Cover Fees
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>Used</Typography>
              </Grid>
            </Grid>
            <Grid mb={1} className={classes.mapped}>
              <Grid>
                <Typography className={classes.fs12}>
                  Suggested Amount
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>Not Used</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            item
            md={4.5}
            sm={4.5}
            xs={12}
            className={classes.box}
            ml={{ md: 3, sm: 2 }}
            mt={{ md: 4, sm: 4, xs: 3 }}
            sx={{
              padding: { md: "15px 35px", sm: "15px 25px", xs: "15px 20px" },
              background: themeMode === "dark" ? "#0000003B" : "#ECECECDD",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Typography mb={2} className={classes.heading}>
              Payment & Fees
            </Typography>

            {/* {fees.length > 0 ? (
              fees.map((item, index) => (
                <Grid className={classes.mapped} key={index} mb={1}>
                  <Grid>
                    <Typography className={classes.fs12}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography className={classes.fs12w600}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography>No Data Available</Typography>
            )} */}
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>
                  Donation Amount
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.amount
                    ? `${getSymbolFromCurrency(
                        donationData?.donation?.currency
                      )}${Number(donationData?.donation?.amount) - processCost}`
                    : "No amount yet"}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>
                  Donation Currency
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.currency
                    ? donationData?.donation?.currency
                    : "Unknown"}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>
                  Payment Processing Fee
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.currency && processCost
                    ? `${getSymbolFromCurrency(
                        donationData?.donation?.currency
                      )}${processCost}`
                    : "undefined"}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>
                  Payment Processor
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.stripeChargeId
                    ? "Stripe"
                    : "Undefined"}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>Fee Covered</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {donationData?.donation?.feeCover ? "Covered" : "Not-Covered"}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.mapped} mb={1}>
              <Grid>
                <Typography className={classes.fs12}>
                  Total Donation Amount
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  {`${getSymbolFromCurrency(donationData?.donation?.currency)}${
                    donationData?.donation?.amount
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          item
          my={{ md: 2, sm: 1.5, xs: 0 }}
          className={classes.fit}
          sx={{
            width: { md: "100%", sm: "100%", xs: "100%" },
            height: "fit-content",
            paddingTop: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            md={4}
            mr={{ md: 3, sm: 2 }}
            my={{ xs: 1 }}
            className={classes.box}
            sx={{
              padding: { md: "15px 35px", sm: "15px 25px", xs: "15px 20px" },
              background: themeMode === "dark" ? "#0000003B" : "#ECECECDD",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Typography mb={2} className={classes.heading}>
              Donor Details
            </Typography>

            <Grid
              mb={1}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography className={classes.fs12}>Name</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  <div>
                    {donationData?.donation?.donor?.firstName}&nbsp;
                    {donationData?.donation?.donor?.lastName}
                  </div>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              mb={1}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography className={classes.fs12}>Email</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  <div>{donationData?.donation?.donor?.email}</div>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              mb={1}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography className={classes.fs12}>Phone</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.fs12w600}>
                  <div>
                    {donationData?.donation?.phoneNumber
                      ? donationData?.donation?.phoneNumber
                      : "Not provided"}
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            item
            md={4}
            ml={{ md: 3, sm: 2 }}
            my={{ xs: 1 }}
            className={classes.box}
            sx={{
              padding: { md: "15px 35px", sm: "15px 25px", xs: "15px 20px" },
              background: themeMode === "dark" ? "#0000003B" : "#ECECECDD",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Typography mb={2} className={classes.heading}>
              Emails
            </Typography>

            {emails.map((item, index) => (
              <Grid
                key={index}
                mb={1}
                className={classes.mapped}
                sx={{
                  flexDirection: "column",
                }}
              >
                <Grid className={classes.flex}>
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: "12px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Sent
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: "12px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Subject
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.comments}
                  mt={1}
                  sx={{
                    backgroundColor:
                      themeMode === "dark" ? "#2F2E2E" : "#E2E2E2",
                  }}
                >
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography
                      className={classes.fs10}
                      sx={{ textWrap: "nowrap" }}
                    >
                      {item.sent}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <Typography className={classes.fs10}>
                      {item.subject}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>

        <Box
          mt={{ xs: 1 }}
          item
          className={classes.fit}
          sx={{
            width: { md: "100%", am: "100%", xs: "100%" },
          }}
        >
          <Box
            item
            md={12}
            className={classes.box}
            sx={{
              alignItems: "flex-start !important",
              padding: { md: "15px 35px", sm: "15px 25px", xs: "15px 20px" },
              background: themeMode === "dark" ? "#0000003B" : "#ECECECDD",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            <Typography mb={2} className={classes.heading}>
              Comments
            </Typography>

            {comments.length > 0 ? (
              comments?.map((item, index) => (
                <Grid key={index} mb={1} className={classes.mapped}>
                  <Grid>
                    <Typography className={classes.fs12}>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography className={classes.fs12w600}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography>Not Available</Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default Reciept;
