
import React from "react";
import { Chart, AxisOptions } from "react-charts";
import { Grid } from "@mui/material";
import { graphData } from "../data";
type DataAds = { value: number, end_time: string };

const transformDataAds = (dataAds: DataAds[]): DataAds[] => {
  return dataAds.map((item: DataAds) => ({
    end_time: new Date(item.end_time),
    value: item.value,
  }));
};

const ChartComponent = ({valueByDates}) => {
  const graph_Data = Object.entries(valueByDates)?.map(([date, value]) => ({
    value,
    end_time: new Date(date)?.toISOString(),
  }));
  const transformedData = transformDataAds(graph_Data);
  const series = [
    {
      label: "Donation",
      data: transformedData,
      color: "#9CCA3C",
    },
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<DataAds> => ({
      getValue: (datum) => datum.end_time,
      type: "time",
      position: "bottom",
      tickInterval: [10, "day"],
      style: {
        strokeStyle: "red",
      },
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DataAds>[] => [
      {
        getValue: (datum) => datum.value,
        type: "linear",
        position: "left",
        style: {
          strokeStyle: "yellow", // Change color of secondary axis
        },
      },
    ],
    []
  );

  return (
    <Grid
      item
      md={12}
      sx={{
         width: "100%",
         height: "250px",
        marginY: "0px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <Chart
        options={{
          data: series,
          primaryAxis,
          secondaryAxes,
          getSeriesStyle: () => ({
            strokeStyle: "green", // Change color of the line on the graph
            color:  "green"
          }),
          getDatumStyle: () => ({
            r: 6, // Change the size of the legend marker
            fillStyle: "orange", // Change color of the legend marker
          }),
        }}
      />
    </Grid>
  );
};

export default ChartComponent;
