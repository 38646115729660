import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

import bkgLight from '../../../../../../assets/FeatureLightTheme/P2P/bkg.svg'
import p2pLight from '../../../../../../assets/FeatureLightTheme/P2P/p2pLight.svg'
import chart from '../../../../../../assets/FeatureLightTheme/P2P/chart.svg'
import solutions from '../../../../../../assets/FeatureLightTheme/P2P/solutions.svg'
import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import blur3 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'

const fields = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/fields.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const p2p3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/p2p3.svg";
const p2p4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/p2p4.svg";
const p2p2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/p2p2.svg";
const light_crowd3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_crowd3.svg";
const light_crowd4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_crowd4.svg";
const light_crowd5 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_crowd5.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";

const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
 
    return (
      <Grid sx={{ 
        backgroundColor: themeMode === "dark" ? "#130407" : "#E4E5F1",
        zIndex:"5",
        display:"flex",
        flexDirection:"column", 
      }}>
        <Grid className={classes.bkg}
          sx={{
            backgroundImage: themeMode === "dark" ? `url('${bkg}'), url('${bkg2}')` : `url('${bkgLight}')`,
            // backgroundImage: themeMode === 'dark'
            //   ? `url('${bkg}'), url('${bkg2}')`
            //   : `url('${blur1}'), url('${blur2}'), url('${blur3}')`,
            backgroundSize: { md: themeMode === "dark" ? "140% auto" : "140% auto", sm: themeMode === "dark" ? "140% auto" : "240% auto", xs: themeMode === "dark" ? "140% auto" : "340% auto" },
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "right bottom,left 0%",

//          padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
          className={classes.columnCenter}
          item
          md={12}
          sm={10}
          xs={11}
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            padding: { md: "80px 125px", sm: "80px 20px", xs: "70px 20px" },
          }}
        >
          <Typography
            // className={classes.upper700}
            className="md:w-3/4 uppercase font-semibold "
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
              color: themeMode === "dark" ? "#F6BE25" : "#030303",
              fontFamily:"Stolzl"
            }}
          >
            <span>Implement a seamless peer-to-peer fundraising solution.</span>
            </Typography>
              <Typography className="  mt-8 md:w-1/2 " 
              sx={{
                textAlign: { md: "left", sm: "center", xs: "center" },
                color: themeMode === "dark" ? "#FFF" : "#030303",
                fontFamily: "Stolzl"
              }}>
              This approach empowers your supporters to become active participants
              in your fundraising efforts. By providing them with the tools to
              create their own fundraising pages, they can reach out to their
              networks, expanding your cause's visibility and impact.
            </Typography>
            <Grid
              my={3}
              item
              className={classes.jCenter}
              md={12}
              sm={10}
              xs={11}
              sx={{
                flexDirection: { md: "row", sm: "column", xs: "column" },
                // width: { md: "40%", sm: "auto", xs: "auto" },
              }}
            >
              <Grid item className={classes.textCenter} md={7}>
                <img src={themeMode==="dark"?p2p2:p2pLight} alt="" className={classes.full} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
          className={classes.bkg}
          container
          sx={{
            padding: { md: "80px 150px", sm: "80px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            // alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(145deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
              }}
            >
              <Grid item md={9} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    color:themeMode==="dark"?"#FFF":"#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  Website-Integrated{" "}
                  <span style={{color: themeMode === "dark" ? "#8ED003" :"#484B6A" }}>P2P Fundraising</span>{" "}
                  Solutions
                </Typography>
                <Grid item md={10}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      color:themeMode==="dark"?"#FFF":"#030303",
                      fontFamily:"Stolzl"
                    }}
                  >
                    Integrate peer-to-peer fundraising directly into your
                    website to offer a cohesive and branded experience for
                    supporters. This integration simplifies the donation
                    process, encouraging more engagement and contributions
                    directly through your site.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={5}
                 mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  //top: { md: "20%", sm: "0%", xs: "0%" },
                  transform: { md: "translateX(20%) scale(1.2)", sm: "translateX(0%)" },
                }}
              >
                <img src={themeMode==="dark"?p2p3:solutions} alt="" />
              </Grid>
            </Grid>
          </Grid>
          </Grid>

          <Grid
          className={classes.bkg}
          container
          sx={{
            // padding: {
            //   md: "150px 100px 200px 100px",
            //   sm: "150px 20px",
            //   xs: "70px 20px",
            // },
            padding: { md: "80px 150px", sm: "80px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: "-10px -13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(145deg, white, transparent)"
                  : "linear-gradient(325deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: {
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
                justifyContent: { md: "flex-end", sm: "center", xs: "center" },
              }}
            >
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  // top: { md: "-10%", sm: "0%", xs: "0%" },
                  // left: { md: "-14%", sm: "10%", xs: "0%" },
                   transform: { md: "translateX(-20%) scale(1.1)", sm: "translateX(0%)" },

                  // transform: {
                  //   md: "translateY(20%)",
                  //   sm: "translateY(0%)",
                  //   xs: "translateY(0%)",
                  // },
                }}
              >
                <img src={themeMode==="dark"?p2p4:chart} alt="" />
              </Grid>
              {/* <Grid
              item
              md={4}
              sm={12}
              xs={12}
              mt={3}
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
                alignItems: "center",
                textAlign: { md: "right", sm: "center", xs: "center" },
                position: "relative",
                top: { md: "10%", sm: "0%", xs: "0%" },
                left: { md: "-15%", sm: "10%", xs: "0%" },
                // transform: {
                //   md: "translateY(20%)",
                //   sm: "translateY(0%)",
                //   xs: "translateY(0%)",
                // },
                transform: { md: "translateX(20%)", sm: "translateX(0%)" },
              }}
            >
              <img src={p2p3} alt="" style={{ width: "100%" }} />
            </Grid> */}
              <Grid item md={7} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    color:themeMode==="dark"?"#FFF":"#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  Gain <span style={{ color: themeMode === "dark" ? "#8ED003" :"#484B6A" }}>donors</span> and grow
                  revenue from engaged supporters
                </Typography>
                <Typography
                  mt={4}
                  sx={{
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    color:themeMode==="dark"?"#FFF":"#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  By engaging your supporters through personalized and
                  interactive fundraising campaigns, you can significantly
                  increase your donor base and revenue. Empowering individuals
                  to contribute to your cause not only fosters a deeper
                  connection but also drives financial growth.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
 
};
export default SubSection;
