import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/DataExport/bkgHero.svg'
import heroDark from '../../../../../../assets/FeatureLightTheme/PaymentEco/heroDark.svg'
import heroLight from '../../../../../../assets/FeatureLightTheme/PaymentEco/heroLight.svg'

const light_payment1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment1.svg";
const light_payment2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment2.svg";
const light_payment3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment3.svg";
const light_payment4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_payment4.svg";
const payment1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/PaymentEcosystem/payment1.svg";
const payment2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/PaymentEcosystem/payment2.svg";
const payment3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/PaymentEcosystem/payment3.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";


const HeroSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 80px 120px",
            sm: "130px 60px 72px",
            xs: "100px 30px 10px",
          },
          
          backgroundImage:
            themeMode === "dark" 
              ? `url('${bkg}')` 
              : `url('${bkgLight}')`,
        }}
      >
        <div className="md:flex w-full">
          {" "}
          <Grid
            className={classes.columnCenter}
            item
            md={7.5}
            sm={10}
            xs={12}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Typography className={"sub-feature-title"}
              sx={{
                textAlign: { md: "left", sm: "left", xs: "center" },
              }}
            >
              <span className={themeMode === "dark" ? "text-white" : "text-black"}>
              Payment Ecosystem{" "}
              </span>
            </Typography>
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "46px", sm: "36px", xs: "32px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                fontFamily: "Stolzl" ,
                width:{md:"87%",sm:"87%",xs:"100%"},
                
              }}
            >
              <span className={themeMode === "dark" ? classes.color : classes.colorLight} sx={{ fontWeight: "700", fontFamily: "Stolzl" }}>
                {" "}
                Numerous ways
              </span>{" "}
              <span className={themeMode === "dark" ? "text-white" : "text-black"}>
                {" "}
                to give for all donors
              </span>
            </Typography>
            <Typography
              className={themeMode === "dark" ? "text-white pt-4" : "text-black pt-4"}
              sx={{
                fontFamily: "Stolzl", 
                width: { md: "85%", sm: "85%", xs: "100%" }, 
                textAlign: { md: "left", sm: "left", xs: "center" },
                }}
            >
              Accept donations from cards, digital wallets, bank transfers, and
              non-cash payment methods.
            </Typography>
          </Grid>
          <Grid
            // my={3}
            item
            //className={classes.jCenter}
            md={6.5}
            sm={10}
            xs={12}
            sx={{
              display: "flex", justifyContent: "flex-end",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              width: { md: "40%", sm: "auto", xs: "auto" },
            }}
          >
            <Grid
              item
              //className={classes.textCenter}
              md={12}
              sx={{ minHeight: "20rem", maxHeight: "170rem", textAlign: { md: "flex-end" }, display: "flex", justifyContent: "flex-end", }}
            >
              <img
                src={themeMode === "dark" ? heroDark : heroLight}
                alt=""
                style={{width:"100%"}}
              />

            </Grid>

          </Grid>
        </div>
      </Grid>
    </>
  );
};
export default HeroSection;
