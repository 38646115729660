import { createTheme } from '@mui/material/styles';



const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  // },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
    },
  },
  container: {
    xs: 12, 
    sm: 12, 
    md: 12, 
  },
  typography:{
    fontFamily: "'Stolzl', sans-serif",
    xs: 12, 
    sm: 12, 
    md: 12, 
  }
});

export default theme;
