import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    center: {
        display: "flex",
        alignItems: "center",
        width:"100%"
    },
    flexStart: {
        display: "flex",
        justifyContent: "flex-start",
    },
    filterBtn: {
        color: "#FFF",
        background: "transparent",
        border: "1px solid #689900",
        borderRadius: "30px",
        textTransform: "capitalize",
        fontWeight: "600",
        flexWrap: "nowrap",
        margin: "10px 10px 10px 0px",
        width: "fit-content",
        
    },
    noWrap: {
        display: "flex",
        flexWrap: "nowrap",
    },
    centerNoWrap: {
        alignItems: "center",
        display: "flex",
        textWrap: "nowrap",
    },
    centerAlign: {
        textAlign: "center",
        alignItems: "center",
    },
    paper: {
        "& .MuiPaper-root": {
          color: "#FFF",
          backgroundColor: "#222",
          border: "1px solid #689900",
        },
    },
    paperLight: {
        "& .MuiPaper-root": {
            color: "#FFF",
            backgroundColor: "#111",
            border: "1px solid #FFF",
            borderRadius:"15px",
        },
    },
});