import React, { useState } from "react";
import { Grid, Typography, Button, Box, Menu, MenuItem } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { OverviewList } from "../overviewList";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./useStyles";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CAMPAIGN,
  GET_CAMPAIGN_WITH_DONATIONS,
  UPDATE_CAMPAIGN_ARCHIVE,
  PUBLISH_CAMPAIGN,
  UNPUBLISH_CAMPAIGN,
  ADD_CAMPAIGN_CLONE,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import PaymentGateway from "../../Insights/Payment Gateway/PaymentGateway";
import { GraphAnalysis } from "./Display/GraphAnalysis";
import { ChartAnalysis } from "./Display/ChartAnalysis";
import { Gateway } from "./Display/Gateway";
import { ProgressBar } from "./Display/ProgressBar";
import { Country } from "./Display/Country";
import dayjs from "dayjs";
import { AlertDialog } from "../../../../../utils/alertDialog";
import { adjustNegativeMonths } from "../../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePickerComp } from "../../DatePickerComp.jsx";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { setAskPage } from "../../../../../store/campaignSlice.js";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const heartLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/down.svg";
const graph =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/graph.svg";
const dateIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";

const CampaignOverView = ({ setSelectedOption }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });
  const [campaignObj, setCampaignObj] = useState({
    uuid: uuid,
    archivedAt: null,
    status: null,
  });
  const { data: campaign } = useQuery(GET_CAMPAIGN_WITH_DONATIONS, {
    variables: {
      uuid: uuid,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      if (result?.campaign?.uuid) {
        dispatch(setAskPage(result.campaign?.askPage));
        setCampaignObj((prevObj) => ({
          ...prevObj,
          uuid: uuid,
          archivedAt: result.campaign.archivedAt,
          status: result.campaign.status,
          title: result.campaign.title,
        }));
      }
    },
  });
  const [
    SavePublishCampaign,
    { loading: publishCampaignLoading, error: publishCampaignError },
  ] = useMutation(PUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request to publish campaign was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request to publish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.publishCampaign?.campaign?.status,
      }));
    },
  });
  const [
    SaveUnPublishCampaign,
    { loading: unPublishCampaignLoading, error: unPublishCampaignError },
  ] = useMutation(UNPUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to unpublish campaign was unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(success("your request to unpublish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.unpublishCampaign?.campaign?.status,
      }));
    },
  });
  const [
    UpdateCampaignArchive,
    {
      loading: updateCampaignArchiveLoading,
      error: updateCampaignArchiveError,
    },
  ] = useMutation(UPDATE_CAMPAIGN_ARCHIVE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("Change campaign status request was unsuccessful!"));
    },
    onCompleted: (data) => {
      setCampaignObj((prevObj) => ({
        ...prevObj,
        archivedAt: data?.archiveCampaign?.campaign?.archivedAt,
      }));
      dispatch(success("Change campaign status request was successful!"));
    },
  });
  const handleClickArchive = async () => {
    const variables = {
      uuid: uuid,
      isArchive: campaignObj.archivedAt ? false : true,
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await UpdateCampaignArchive({ variables });
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  const [
    AddCampaignClone,
    { loading: AddCampaignCloneLoading, error: AddCampaignCloneError },
  ] = useMutation(ADD_CAMPAIGN_CLONE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("Campaign clone request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("Campaign clone request was successful!"));
    },
  });

  const handleClickClone = async () => {
    const variables = {
      uuid: uuid,
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await AddCampaignClone({ variables });
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  const toggleCampaignStatus = async () => {
    const variables = {
      uuid: uuid,
    };
    dispatch(setSpinnerLoading(true));
    try {
      if (campaignObj?.status === "unpublish") {
        const result = await SavePublishCampaign({ variables });
        if (result?.errors?.message) {
          const sentencesArray = JSON.parse(result?.errors?.message);
          setDialogContent(
            sentencesArray ? sentencesArray : result?.errors?.message
          );
          setOpenDialog(true);
        }
      } else {
        const result = await SaveUnPublishCampaign({ variables });
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        className={classes.column}
        container
        sx={{
          padding: { md: "40px", sm: "40px 20px", xs: "20px" },
        }}
      >
        <Grid className={classes.spaceBtwn} item>
          <Grid className={classes.column} item md={8} sm={7.7} xs={3}>
            <Grid>
              <Button
                className={classes.rotate}
                onClick={() => navigate(-1)}
                disableRipple
              >
                {themeMode === "dark" ? (
                  <img src={back} alt="" />
                ) : (
                  <img src={backLight} alt="" />
                )}
              </Button>
            </Grid>
            <Grid className={classes.fitContent}>
              <Link to={"/dashboard/campaigns"}>
                <Button
                  className={classes.transButton}
                  // href="/dashboard/campaigns"
                  sx={{
                    margin: { md: "0px", sm: "10px 0px", xs: "10px 5px" },
                    width: { md: "fit-content", sm: "100%", xs: "100%" },
                  }}
                >
                  <Grid className={classes.flexCenter} mr={1} ml={0}>
                    <img
                      src={themeMode === "dark" ? heart : heartDark}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "12px", xs: "12px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      Campaigns
                    </Typography>
                  </Grid>
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            sm={4.3}
            xs={9}
            sx={{ textAlign: { md: "end", sm: "end", xs: "right" } }}
          >
            <Typography
              className={classes.sideText}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Campaign Actions{" "}
            </Typography>
            <Grid className={classes.flexEnd} item>
              <Button
                className={classes.borderButton}
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 8px" },
                  margin: "10px 5px 10px 0px",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid className={classes.flexCenter} container>
                  <Typography
                    className={classes.fw400}
                    sx={{
                      fontSize: { md: "14px", sm: "12px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                    onClick={(e) => {
                      handleClickClone();
                    }}
                  >
                    Clone
                  </Typography>
                </Grid>
              </Button>
              <Button
                className={classes.borderButton}
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 8px" },
                  margin: "10px 5px 10px 0px",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  handleClickArchive();
                }}
              >
                <Grid className={classes.flexCenter} container>
                  <Typography
                    className={classes.fw400}
                    sx={{
                      fontSize: { md: "14px", sm: "12px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {campaignObj.archivedAt ? "Non-Archived" : "Archived"}
                  </Typography>
                </Grid>
              </Button>
              <Button
                className={classes.borderButton}
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                  margin: "10px 0px ",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  toggleCampaignStatus();
                }}
              >
                <Grid className={classes.flexCenter} container>
                  <Typography
                    className={classes.fw400}
                    sx={{
                      fontSize: { md: "14px", sm: "12px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {campaignObj?.status && campaignObj?.status === "publish"
                      ? "Unpublish"
                      : "Publish"}
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={9} sm={12} xs={12}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Typography
              className={classes.fw600}
              sx={{
                fontSize: { md: "40px", sm: "40px", xs: "26px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              {campaignObj?.title?.length > 15
                ? `${campaignObj.title.substring(0, 15)}...`
                : campaignObj.title}
            </Typography>
            <Grid
              mx={2}
              py={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Grid
                sx={{
                  width: "17px",
                  height: "17px",
                  backgroundColor: "#CDDF00",
                  borderRadius: "50%",
                }}
              ></Grid>
              <Typography
                mx={1}
                sx={{
                  fontSize: { md: "14px", sm: "14px", xs: "9px" },
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                {campaignObj?.status && campaignObj?.status === "publish"
                  ? "Published"
                  : "Not-Published"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll   */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList?.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${campaignObj.title}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 0
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 0
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 0
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 0 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
                {/* </Grid> */}
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid
                  key={`arrow-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: { md: "scale(1.3)", sm: "scale(1)" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${campaignObj.title}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 0
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 0
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 0
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 0 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        className={classes.divider}
        item
        md={12}
        sm={12}
        sx={{
          flexDirection: { md: "row", sm: "row", xs: "column" },
        }}
      >
        <Grid
          item
          md={3}
          sm={3}
          className={classes.fullWidth}
          sx={{
            flexDirection: { md: "column", sm: "column", xs: "row" },
            justifyContent: { md: "left", sm: "left", xs: "space-between" },
            paddingLeft: "40px",
          }}
        >
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.fs12}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Campaign Created
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.neon600}
              sx={{
                fontSize: { md: "18px", sm: "13px", xs: "12px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#00FF0A" : "#018a07",
              }}
            >
              {dayjs(campaign?.campaign?.createdAt)?.format("YYYY-MM-DD")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          md={2.4}
          sm={2}
          className={classes.flex}
          sx={{
            flexDirection: { md: "column", sm: "column", xs: "row" },
            justifyContent: { md: "left", sm: "left", xs: "space-between" },
          }}
        >
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.fs12}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Total Raised
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.neon600}
              sx={{
                fontSize: { md: "18px", sm: "13px", xs: "12px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#00FF0A" : "#018a07",
              }}
            >
              ${campaign?.campaign?.raised}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          md={2.4}
          sm={2}
          className={classes.flex}
          sx={{
            flexDirection: { md: "column", sm: "column", xs: "row" },
            justifyContent: { md: "left", sm: "left", xs: "space-between" },
          }}
        >
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.fs12}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              P2P Collection
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.neon600}
              sx={{
                fontSize: { md: "18px", sm: "13px", xs: "12px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#00FF0A" : "#018a07",
              }}
            >
              ${campaign?.campaign?.p2pCollection}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          md={2}
          sm={2}
          className={classes.flex}
          sx={{
            flexDirection: { md: "column", sm: "column", xs: "row" },
            justifyContent: { md: "left", sm: "left", xs: "space-between" },
          }}
        >
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.fs12}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Recurring Plans
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.neon600}
              sx={{
                fontSize: { md: "18px", sm: "13px", xs: "12px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#00FF0A" : "#018a07",
              }}
            >
              {campaign?.campaign?.recurringPlans}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          md={2}
          sm={2}
          className={classes.flex}
          sx={{
            flexDirection: { md: "column", sm: "column", xs: "row" },
            justifyContent: { md: "left", sm: "left", xs: "space-between" },
          }}
        >
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.fs12}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              P2P Fundraisers
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={6}>
            <Typography
              className={classes.neon600}
              sx={{
                fontSize: { md: "18px", sm: "13px", xs: "12px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#00FF0A" : "#018a07",
              }}
            >
              {campaign?.campaign?.totalP2pFundraiser}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.center}
        sx={{
          justifyContent: {
            md: "flex-start",
            sm: "flex-start",
            xs: "space-between",
          },
          flexDirection: { md: "row", sm: "row", xs: "row" },
          flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          paddingLeft: "40px",
        }}
      >
        <Box
          item
          xs={10}
          className={classes.flexStart}
          sx={{
            width: { md: "fit-content", sm: "100%", xs: "100%" },
            width: { md: "fit-content", sm: "fit-content", xs: "fit-content" },
            padding: { md: "0px", sm: "0px", xs: "0px 0px" },
            flexWrap: "wrap",
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "center",
            },
          }}
        >
          <Button
            className={classes.filterBtn}
            disableRipple
            sx={{
              padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
            onClick={(e) => {
              setAnchorElDateFilter(e.currentTarget);
            }}
          >
            <Grid container className={classes.noWrap}>
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "12px", sm: "12px", xs: "10px" },
                  display: "flex",
                  alignItems: "center",
                  textWrap: "nowrap",
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    color: "#9CCA3C",
                    marginRight: { md: "10px", sm: "10px", xs: "5px" },
                  }}
                />
                {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
              </Typography>
            </Grid>
          </Button>
          <Menu
            anchorEl={anchorElDateFilter}
            open={Boolean(anchorElDateFilter)}
            onClose={(e) => {
              setAnchorElDateFilter(null);
            }}
            sx={{
              "& .MuiPaper-root": {
                background: themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                width: "fit-content",
                border: "2px solid #ABFC00",
                borderRadius: "40px",
              },
            }}
          >
            <MenuItem>
              <Grid container spacing={2} alignItems="center">
                <Grid item md={2} xs={12}>
                  <DatePickerComp
                    startDate={dateRange?.startDate}
                    endDate={dateRange?.endDate}
                    displayDate={setDateRange}
                  />{" "}
                </Grid>
              </Grid>
            </MenuItem>
          </Menu>
        </Box>
      </Grid>

      <Grid
        my={2}
        item
        className={classes.spaceBtwn}
        sx={{
          flexDirection: { md: "row", sm: "row", xs: "column" },
          flexWrap: { md: "nowrap", sm: "wrap", xs: "wrap" },
          padding: { md: "0px 40px", sm: "40px 20px", xs: "20px" },
        }}
      >
        <Grid item md={4} sm={6} xs={12} my={1}>
          <GraphAnalysis
            donationsByDates={campaign?.campaign?.donationsParDateCount?.data}
            title={"Per Day Collection"}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} my={1}>
          <ChartAnalysis
            donationsByDates={campaign?.campaign?.donationsParDateCount?.data}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} my={1}>
          <Gateway />
        </Grid>
      </Grid>

      <Grid
        my={2}
        item
        className={classes.spaceBtwn}
        sx={{
          flexDirection: { md: "column", sm: "column", xs: "column" },
          flexWrap: { md: "nowrap", sm: "wrap", xs: "wrap" },
          padding: { md: "10px 40px", sm: "40px 20px", xs: "20px" },
        }}
      >
        <Grid item md={12} sm={12} xs={12} my={1}>
          <Country />
        </Grid>
        <Grid item md={12} sm={12} xs={12} my={1}>
          <ProgressBar />
        </Grid>
      </Grid>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Please complete all campaign settings"}
        content={dialogContent}
      />
    </>
  );
};
export default CampaignOverView;
