import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import Form from "./Form/Form";
import { useSelector } from "react-redux";
import { useStylesLight } from "./useStylesLight";

const arrow =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/arrow.png";

const SalesTeam = ({ showQuestion, setUserId }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  const classesLight = useStylesLight();

  if (themeMode === "dark") {
    return (
      <>
        <Grid
          className={classes.bkg}
          container
          sx={{
            padding: {
              md: "150px 50px 100px",
              sm: "150px 50px 100px",
              xs: "100px 20px 100px",
            },
          }}
        >
          <Grid
            item
            md={11.5}
            sm={11.5}
            xs={12}
            className={classes.ellipse}
            sx={{
              flexDirection: {
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              },
              height: { md: "700px", sm: "fit-content", xs: "fit-content" },
              justifyContent: {
                md: "space-between",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Form showQuestion={showQuestion} setUserId={setUserId} />
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              className={classes.ellipse2}
              sx={{
                padding: {
                  md: "60px 70px 0px",
                  sm: "60px 70px 0px",
                  xs: "60px 20px 0px",
                },
              }}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                className={classes.column}
                sx={{
                  textAlign: { md: "right", sm: "center", xs: "center" },
                }}
              >
                <Typography
                  className={classes.fw700}
                  sx={{
                    fontSize: { md: "52px", sm: "50px", xs: "36px" },
                  }}
                >
                  CONTACT OUR <br />
                  SALES TEAM
                </Typography>
                <Typography
                  className={classes.fw400}
                  mt={7}
                  mb={{ md: 6, sm: 6, xs: 3 }}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  Connect directly with our dedicated sales team by filling out
                  this contact form.
                </Typography>
                <Typography
                  className={classes.fw400}
                  my={2}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  Provide your details, and our team will reach out to you
                  promptly to discuss your needs, answer any questions, and
                  explore how our solutions can best support your goals.
                </Typography>
                <Typography
                  className={classes.fw400}
                  my={2}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  For technical issues and general inquiries,
                  <br />
                  <a
                    href="/help-center"
                    style={{
                      textDecoration: "none",
                      color: "#FFFF",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    visit Help Center
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classes.right}
            item
            md={6}
            py={6}
            sx={{
              display: { md: "flex", sm: "none", xs: "none" },
            }}
          >
            <img src={arrow} alt="" />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          className={classesLight.bkg}
          container
          sx={{
            padding: {
              md: "150px 50px 100px",
              sm: "150px 50px 100px",
              xs: "100px 20px 100px",
            },
          }}
        >
          <Grid
            item
            md={11.5}
            sm={11.5}
            xs={12}
            className={classesLight.ellipse}
            sx={{
              flexDirection: {
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              },
              height: { md: "700px", sm: "fit-content", xs: "fit-content" },
              justifyContent: {
                md: "space-between",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Form showQuestion={showQuestion} setUserId={setUserId} />
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              className={classesLight.ellipse2}
              sx={{
                padding: {
                  md: "60px 70px 0px",
                  sm: "60px 70px 0px",
                  xs: "60px 20px 0px",
                },
              }}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                className={classesLight.column}
                sx={{
                  textAlign: { md: "right", sm: "center", xs: "center" },
                }}
              >
                <Typography
                  className={classesLight.fw700}
                  sx={{
                    fontSize: { md: "52px", sm: "50px", xs: "36px" },
                  }}
                >
                  CONTACT OUR <br />
                  SALES TEAM
                </Typography>
                <Typography
                  className={classesLight.fw400}
                  mt={7}
                  mb={{ md: 6, sm: 6, xs: 3 }}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  Connect directly with our dedicated sales team by filling out
                  this contact form.
                </Typography>
                <Typography
                  className={classesLight.fw400}
                  my={2}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  Provide your details, and our team will reach out to you
                  promptly to discuss your needs, answer any questions, and
                  explore how our solutions can best support your goals.
                </Typography>
                <Typography
                  className={classesLight.fw400}
                  my={2}
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "12px" },
                  }}
                >
                  For technical issues and general inquiries,
                  <br />
                  <a
                    href="/contact"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    visit Help Center
                  </a>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            className={classesLight.right}
            item
            md={6}
            py={6}
            sx={{
              display: { md: "flex", sm: "none", xs: "none" },
            }}
          >
            <img src={arrow} alt="" />
          </Grid>
        </Grid>
      </>
    );
  }
};
export default SalesTeam;
