import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: { message: null, type: null, title: null },
  reducers: {
    success: (state, action) => {
      state.type = 'success';
      state.title = 'Successful';
      state.message = action.payload;
    },
    error: (state, action) => {
      state.type = 'error';
      state.title = 'UnSuccessful';
      state.message = action.payload;
    },
    info: (state, action) => {
      state.type = 'info';
      state.title = 'Info';
      state.message = action.payload;
    },
    warning: (state, action) => {
      state.type = 'warning';
      state.title = 'Warning';
      state.message = action.payload;
    },
    clear: (state) => {
      state.type = null;
      state.title = null;
      state.message = null;
    },
  },
});

export const { success, error, info, warning, clear } = alertSlice.actions;
export default alertSlice.reducer;