import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
    logo: {
        display: "flex",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "transparent",
        },
    },
    flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "5px",
  },
  btn: {
    borderRadius: "50px",
  },
  btn2: {
    paddingRight: " 15px",
    borderRadius: "30px",
    border: "1px solid transparent",
  },
  allFit: {
    width: "fit-content",
    height: "fit-content",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dpHolder: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
  w80: {
    width: "80%",
  },
  colCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  profile: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    
  },
  profileDull: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    color: "#9F9F9F",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    "& .MuiPaper-root": {
      border: "1px solid #689900",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "flex-end",
      borderRadius: "15px",
    },
    "& .MuiList-root": {
      width: "100%",
    },
  },
  menuItem: {
    backgroundColor: "transparent",
    width: "100% !important",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      color: "#689900",
    },
  },
  closeButtonHolder: {
    display: "flex", width: "100%", justifyContent: "flex-end"
  },
  closeButton: {
    "&:hover": {
      transform: "scale(1.2)",
      backgroundColor: "transparent"
    }
  },
  input: {
    borderRadius: "15px",
    
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px",
      borderRadius: "10px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8ED003",
      },
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
}))