import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "600",
    textAlign: "center",
    margin: " 0px 0px",
    textDecoration: "none",
    padding: "20px",
    width: "100%",
    color: "#74aa01",
    
    "&:hover": {
      backgroundColor: "#366510",
      color: "#fff",
    },
  },
  presentation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  home: {
    marginX: "10px",
  },
  bkg: {
    display:"flex",
    justifyContent:"space-between",
    flexDirection:"row",
    minHeight:"100vh",
    height: "100%",
    overflow: "hidden",
    paddingTop: "0px",
  },
  column: {
    flexDirection: "column",
  },
  center: {
    display: "flex",
    textAlign: "center",
  },
  p10col: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    height:"97vh",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "20px",
    position:"fixed"
  },
  create: {
    color: "#FFF",
    background: "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
    borderRadius: "30px",
    textTransform: "capitalize",
    whiteSpace: "noWrap",
    fontWeight: "600",
    padding: "8px 15px",
    
    "&:hover": {
      background:
        "linear-gradient(135deg,  #081a01 -10%, #618F00 50%,#081a01  118%)",
    },
  },
  scroll: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
    scrollbarWidth: "thin",
    overflowX:"hidden"
  },
  options: {
    background: "transparent",
    borderRadius: "30px",
    padding: "12px 25px",
    display: "flex",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  options2: {
    color: "#FFF",
    background: "transparent",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    padding: "5px 15px",
    display: "flex",
    fontSize: "16px",
    margin: "10px",
    width: "75%",
    alignContent: "center",
    "&:hover": {
      background: "#689900",
    },
  },
  box: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px",
    width: "fit-content",
    
  },
  mr20: {
    marginRight: "20px",
  },
  flex: {
    display: "flex",
  },
  logout: {
    color: "#FF0000",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: "500",
    padding: "8px 25px",
    margin: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      border: "1px solid #FF0000",
    },
  },
  p0flex: {
    padding: "0px",
    display: "flex",
  },
  fit: {
    display: "flex",
    width: "100%",
    height: "fit-content",
  },
  justifyStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px 0px 0px 20px",
  },
  alignCenter: {
    alignItems: "center",
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
  },
  mt10: {
    margin: "10px 0px 0px",
  },
  jLeft: {
    justifyContent: "left",
  },
  nowrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
  centerNowrap: {
    display: "flex",
    alignItems: "center",
    
  },
  centerFit: {
    textAlign: "center",
    alignItems: "center",
    width: "100%",
  },
  center: {
    textAlign: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "5px",
  },
  btn: {
    borderRadius: "50px",
    
  },
  btn2: {
    paddingRight: " 15px",
    borderRadius: "30px",
    border: "1px solid transparent",
    // "&:hover": {
    //   border: "1px solid #94D906",
    //   backgroundColor: "#2e3322",
    // },
  },
  allFit: {
    width: "fit-content",
    height: "fit-content",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dpHolder: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
  w80: {
    width: "80%",
  },
  colCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  profile: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    fontWeight:"600"
    
  },
  profileDull: {
    
    padding: "0px 5px",
  },
  fullWidth: {
    width: "100%",
  },
  all: {
    width: "100%",
    height: "100%",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    "& .MuiPaper-root": {
      //backgroundColor: "#0F0F0F",
      border: "0.5px solid #FFF",
      color: "#FFF",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "flex-end",
      borderRadius: "15px",
    },
    "& .MuiList-root": {
      width: "100%",
    },
  },
  menuItem: {
    backgroundColor: "transparent",
    width: "100% !important",
    textAlign: "center",
    padding:"0px 20px",
    justifyContent: "center",
    
  },

  
  overflowx: {
    display: 'flex',
    justifyContent: 'space-between',
    width:"100%"
  },
  btnList: {
    //borderRadius: '30px',
    '&:hover': {
      textShadow: '1px 1px 10px #FFFF',
      color:'#FFB800',
      //border: '1px solid #FFFFF'
      background:"transprent"
    }
  },
  btnText: {
    fontWeight: '600',
    
    textTransform: 'capitalize',
    textWrap: 'nowrap',
    width: 'fit-content'
  },
}));
