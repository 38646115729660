import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";


const campaignBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaignBlur.png";
const reporting = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/reporting.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/report.svg";

const ReportingManagement = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/reporting-management" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
          backgroundImage: `url('${campaignBlur}')`,
        }}
      >
        <Grid
          item
          md={6}
          sx={{
            paddingRight: { md: "70px", sm: "0px", xs: "0px" },
          }}
        >
          <Typography
            className={classes.title}
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            reporting MANAGEMENT
          </Typography>
          <Typography
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontFamily: "Stolzl",
            }}
          >
            Provides detailed analytics and reporting tools for tracking
            campaign performance, financials, and donor trends, aiding strategic
            decision-making.
          </Typography>
        </Grid>
        <Grid mt={{ md: 0, sm: 4, xs: 6 }}>
          {themeMode === "dark" ? (
            <img
              src={reporting}
              alt="Reporting Management"
              className={classes.fullWidth}
            />
          ) : (
            <img
              src={light}
              alt="Reporting Management"
              className={classes.fullWidth}
            />
          )}
        </Grid>
      </Grid>
    </Link>
  );
};
export default ReportingManagement;
