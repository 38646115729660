import React, { useState } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import ChartComponent from "./ChartComponent";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const date = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";

const Graph = ({valueByDates}) => {
    const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      sx={{
        backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
        boxShadow:
          themeMode === 'dark'
            ? '0px 0px 0px blue'
            : '4px 4px 14px 0px #00000024',
        borderRadius: "15px",
        height: "100%",
        padding: "25px 25px 50px",
        border:"1px solid #FFF",
      }}
    >
      <Grid container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
          flexDirection:{md:"row",sm:"column",xs:"row"}
        }}
      >
        <Grid item md={3} sm={12} mr={1}>
          <Typography sx={{ 
            fontSize: {md:"12px",sm:"12px",xs:"12px"},
            fontWeight:"700",
            textTransform:"capitalize",
            textWrap:"nowrap",
            color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F',
            textAlign:{xs:"center"} 
          }}>
            Number Of Donors
          </Typography>
        </Grid>

        <Grid item md={5} sm={12} my={{md:0,sm:3,xs:3}} ml={{md:1,sm:0}}
          sx={{ display: "flex",justifyContent:"right",alignItems:"center",width:"100%" }}>
          <CalendarMonthIcon sx={{ color: "#9CCA3C", marginRight: { md: "10px", sm: "10px", xs: "5px" } }} />
          <Typography mr={1}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              whiteSpace: "nowrap",
              padding: "0px 0px 0px 3%",
              color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F',
            }}
          >
            Last 2 months
          </Typography>
            <img src={down} alt="" />
        </Grid>
      </Grid>
      <Grid sx={{display:"flex",width:"100%",height:"100%"}}>
        {valueByDates && Object.keys(valueByDates)?.length !== 0 && <ChartComponent valueByDates={valueByDates}/>}
      </Grid>
    </Grid>
  );
};
export default Graph;
