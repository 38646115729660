import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { mostUsed } from "../../data";
import { useSelector } from "react-redux";

const MostUsedGateway = ({ paymentCurrencies }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const sortedEntries = Object.entries(paymentCurrencies).sort(
    (a, b) => a[1] - b[1]
  );

  const sortedObj = Object.fromEntries(sortedEntries);
  const resultArray = Object.entries(sortedObj).map(([currency, ranking]) => ({
    ranking: String(ranking),
    currency,
  }));
  console.log("🚀 ~ resultArray ~ resultArray:", resultArray);
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
          boxShadow:
            themeMode === "dark"
              ? "0px 0px 0px blue"
              : "4px 4px 14px 0px #00000024",
          borderRadius: "15px",
          height: "fit-content",
          padding: "18px 25px",
          height: "100%",
        }}
      >
        
        <Grid
          mb={2}
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
            }}
          >
            Most Used Currency
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            //  overflowY: "auto",
          }}
        >
          {resultArray?.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: "5px 10px" }}>
              <Button
                disableRipple
                container
                sx={{
                  width: "100%",
                  border: "1px solid #9CCA3C",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    # {item.ranking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={1}
                  sx={{ display: "flex", alignItem: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "22px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      textTransform: "capitalize",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    {item.currency === "EUR" && "€"}
                    {item.currency === "USD" && "$"}
                    {item.currency === "usd" && "$"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={8}
                  sm={8}
                  xs={8}
                  sx={{ display: "flex", alignItem: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "22px",
                      width: "100%",
                      textAlign: "left",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      textTransform: "capitalize",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    {item.currency}
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
export default MostUsedGateway;
