import { Grid, Typography, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import __ from "lodash";
// import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_PAYMENT_METHOD } from "./Query";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useDispatch } from "react-redux";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  first_name: "",
  id_type: "cnic",
  agree: false,
};

/**
 * Form Validation Schema
 */
const schema = yup
  .object()
  .shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    agree: yup.boolean().oneOf([true], "You must check"),
  })
  .required();

const AccountHolderBankInfo = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const user = useSelector((state) => state.auth.user);

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const [
    CreatePaymentMethod,
    { loading: CreatePaymentMethodLoading, error: CreatePaymentMethodError },
  ] = useMutation(CREATE_PAYMENT_METHOD, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("Create Payment Method request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("Create Payment Method request was successful!"));
    },
  });

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <div className="md:flex justify-between w-full my-8 md:space-x-8">
          <div className="bank-card w-full px-5 py-4 text-black">
            <Typography className="bank-titles mb-4">Bank </Typography>
            <Typography className="bank-heading uppercase">
              HABIB METRO BANK{" "}
            </Typography>
            <Typography className="bank-subheading uppercase md:w-1/2 pt-2">
              SPENCER’S BUILDING, FLOOR GF, I.I. CHUNDRIGAR ROAD, KARACHI,
              74200, PAKISTAN{" "}
            </Typography>
            <Typography className="bank-info pt-4">
              Not your bank or branch?
            </Typography>
          </div>

          <div className="w-full text-black mt-4 md:mt-0">
            <div className="flex justify-between md:py-4">
              <div className="w-full">
                <Typography
                  className="bank-titles mb-4"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#000000",
                  }}
                >
                  Account Currency{" "}
                </Typography>
                <Typography
                  className="bank-currency mb-4"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#000000",
                  }}
                >
                  PKR{" "}
                </Typography>
                <Typography
                  className="bank-subheading"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#000000",
                  }}
                >
                  last exchange rate 268 USD/PKR as of March 14
                </Typography>
              </div>
              <div className="w-full">
                <Typography
                  className="bank-titles mb-4"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#000000",
                  }}
                >
                  Withdrawal Fee{" "}
                </Typography>
                <Typography
                  className="bank-currency mb-4"
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#000000",
                  }}
                >
                  $0.99{" "}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <div>
        <Typography
          className="sub-heading-withdrawl"
          sx={{
            color: themeMode === "dark" ? "#FFFF" : "#000000",
            fontSize: { md: "24px", sm: "24px", xs: "18px" },
          }}
        >
          Account Holder Bank Info{" "}
        </Typography>

        <form
          className="w-full"
          onSubmit={handleSubmit((_data) => {
            const variables = {
              uuid: user.uuid,
              isDefault: true,
              accountDetail: _data,
            };
            try {
              const result = CreatePaymentMethod({ variables });
            } catch (error) {
              console.error(error);
            }
          })}
        >
          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "24px",
                  marginBottom: "8px",
                }}
              >
                IBAN Number{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.iban_number}
                    helperText={errors?.iban_number?.message}
                    // required
                    fullWidth
                    size="small"
                    className="md:mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="iban_number"
                control={control}
              />
            </div>{" "}
            <div className="w-full">
              <div className="flex justify-between space-x-8">
                <div>
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    Post Code{" "}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errors.postal_code}
                        helperText={errors?.postal_code?.message}
                        // required
                        fullWidth
                        size="small"
                        className="mb-4"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#689900",
                            },
                          },
                        }}
                      />
                    )}
                    name="postal_code"
                    control={control}
                  />
                </div>

                <div>
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    Branch Name{" "}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errors.branch_name}
                        helperText={errors?.branch_name?.message}
                        // required
                        fullWidth
                        size="small"
                        className="mb-4"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#689900",
                            },
                          },
                        }}
                      />
                    )}
                    name="branch_name"
                    control={control}
                  />
                </div>
              </div>
            </div>{" "}
          </div>

          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Bank Account Type{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.account_type}
                    helperText={errors?.account_type?.message}
                    // required
                    fullWidth
                    size="small"
                    className="md:mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="account_type"
                control={control}
              />
            </div>{" "}
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Branch Address{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.branch_address}
                    helperText={errors?.branch_address?.message}
                    // required
                    fullWidth
                    size="small"
                    className="mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="branch_address"
                control={control}
              />
            </div>{" "}
          </div>

          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                First Name{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.first_name}
                    helperText={errors?.first_name?.message}
                    required
                    fullWidth
                    size="small"
                    className="md:mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="first_name"
                control={control}
              />
            </div>{" "}
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Last Name{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.last_name}
                    helperText={errors?.last_name?.message}
                    required
                    fullWidth
                    size="small"
                    className="mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="last_name"
                control={control}
              />
            </div>{" "}
          </div>

          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "24px",
                  marginBottom: "8px",
                }}
              >
                Date of Birth{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.date_of_birth}
                    helperText={errors?.date_of_birth?.message}
                    // required
                    fullWidth
                    size="small"
                    className="md:mb-4"
                    type="date"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="date_of_birth"
                control={control}
              />
            </div>{" "}
            <div className="w-full">
              <div className="flex justify-between space-x-8">
                <div className="w-full">
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    ID Type{" "}
                  </Typography>

                  <Controller
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.id_type}
                        // required
                        fullWidth
                        size="small"
                      >
                        <Select {...field} variant="outlined" fullWidth>
                          <MenuItem value="cnic">CNIC</MenuItem>
                          <MenuItem value="card">Card</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors?.id_type?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                    name="id_type"
                    control={control}
                  />
                </div>

                <div className="w-full">
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    ID Number{" "}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errors.id_number}
                        helperText={errors?.id_number?.message}
                        // required
                        fullWidth
                        size="small"
                        className="mb-4"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#689900",
                            },
                          },
                        }}
                      />
                    )}
                    name="id_number"
                    control={control}
                  />
                </div>
              </div>
            </div>{" "}
          </div>

          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Name on Account{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.account_name}
                    helperText={errors?.account_name?.message}
                    // required
                    fullWidth
                    size="small"
                    className="md:mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="account_name"
                control={control}
              />
            </div>{" "}
            <div className="w-full">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Address{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    // required
                    fullWidth
                    size="small"
                    className="mb-4"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#689900",
                        },
                      },
                    }}
                  />
                )}
                name="address"
                control={control}
              />
            </div>{" "}
          </div>

          <div className="md:flex justify-between items-start md:space-x-8">
            <div className="w-full">
              <div className="flex justify-between space-x-8">
                <div>
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    State/Province{" "}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errors.state}
                        helperText={errors?.state?.message}
                        // required
                        fullWidth
                        size="small"
                        className="mb-4"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#689900",
                            },
                          },
                        }}
                      />
                    )}
                    name="state"
                    control={control}
                  />
                </div>

                <div>
                  <Typography
                    className="sub-sub-heading-withdrawl"
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#000000",
                      fontSize: { md: "18px", sm: "18px", xs: "15px" },
                      marginTop: "24px",
                      marginBottom: "8px",
                    }}
                  >
                    Country{" "}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errors.country}
                        helperText={errors?.country?.message}
                        // required
                        fullWidth
                        size="small"
                        className="mb-4"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#689900",
                            },
                          },
                        }}
                      />
                    )}
                    name="country"
                    control={control}
                  />
                </div>
              </div>
            </div>{" "}
            <div className="w-full text-black">
              <Typography
                className="sub-sub-heading-withdrawl"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#000000",
                  fontSize: { md: "18px", sm: "18px", xs: "15px" },
                  marginTop: "24px",
                  marginBottom: "8px",
                }}
              >
                Phone Number{" "}
              </Typography>
              <Controller
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    placeholder="Enter phone number"

                    // value={value}
                    // onChange={setValue}
                  />
                )}
                name="phone_number"
                control={control}
              />
            </div>{" "}
          </div>

          <div className="">
            <Controller
              name="agree"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <FormControl error={!!errors.agree} required>
                  <FormControlLabel
                    label="I attest that I am the owner and have full authorization to this bank account."
                    className="agree-text "
                    control={
                      <Checkbox
                        checked={value}
                        onBlur={onBlur}
                        onChange={(ev) => onChange(ev.target.checked)}
                        inputRef={ref}
                        required
                        style={{ 
                          color:"#FFF",
                          "&:checked":{
                            color: '#689900' 
                          }
                        }}
                      />
                    }
                  />
                  <FormHelperText>{errors?.agree?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </div>

          <div className="flex space-x-4 pt-4">
            <Button
              className="rounded-full border-[#689900]  text-black px-4 capitalize"
              variant="outlined"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid}
            >
              Back
            </Button>
            <Button
              className="rounded-full bg-[#689900]  text-white px-4 capitalize"
              variant="contained"
              type="submit"
              disabled={_.isEmpty(dirtyFields) || !isValid}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </Grid>
  );
};
export default AccountHolderBankInfo;
