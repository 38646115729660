import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useStyles } from "./useStyles";
import SearchBarHelp from "./SearchBarHelp";
// import data from "./data";
import { useSelector } from "react-redux";
import Articles from "../../../WebsitePages/Contact/Articles/Articles";
import MessageBox from "./MessageBox";

const bkg =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Help/bkg.svg";
const fragments =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Help/fragments.svg";

const HelpPage = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const data = useSelector((state) => state.help.data);
  return (
    <>
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundImage: `url('${bkg}')`,
            backgroundSize: "cover,cover",
            backgroundPosition: "center center,10% 50%",
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            borderRadius: "20px",
          }}
        >
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{ padding: { md: "40px", sm: "40px", xs: "20px" } }}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  fontSize: { md: "40px", sm: "40px", xs: "32px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                }}
              >
                How can we help?
              </Typography>
            </Grid>

            <Grid
              item
              md={12}
              sm={12}
              className={classes.centerAlign}
              sx={{
                justifyContent: {
                  md: "space-between",
                  sm: "space-between",
                  xs: "flex-start",
                },
                flexDirection: { md: "row", sm: "row", xs: "row" },
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
              }}
            >
              <Box
                className={classes.flexStart}
                item
                my={3}
                md={6}
                xs={10}
                sx={{
                  width: { md: "100%", sm: "100%", xs: "fit-content" },
                  padding: { md: "0px", sm: "0px", xs: "0px 0px" },
                }}
              >
                <SearchBarHelp specific="Search in Acceleraise Help" />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{
              textAlign: "center",
              transform: {
                md: "scale(1)",
                sm: "scale(0.85)",
                xs: "scale(0.7)",
              },
            }}
          >
            <img src={fragments} alt="" />
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          <Grid
            item
            md={7}
            sm={12}
            xs={11}
            sx={{ padding: { md: "40px", sm: "40px", xs: "20px" } }}
          >
            <Typography
              mb={4}
              sx={{
                fontSize: { md: "30px", sm: "30px", xs: "20px" },
                fontWeight: "600",
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
              }}
            >
              Browse common questions
            </Typography>
            {data?.map((item, index) => (
              <ul className={classes.listItemStyle} key={index}>
                <li className={classes.listItemContentStyle}>
                  <span className={classes.bulletStyle}></span>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: { md: "14px", sm: "14px", xs: "12px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {item.heading}
                  </Typography>
                  <Typography
                    mt={{ md: 0, sm: 1, xs: 1 }}
                    sx={{
                      fontWeight: "400",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {item.text}
                  </Typography>
                </li>
              </ul>
            ))}
          </Grid>

          <Grid
            item
            md={5}
            sm={12}
            xs={11}
            sx={{
              padding: { md: "60px 40px", sm: "60px 15px", xs: "20px" },
              display: { lg: "flex", md: "flex-col" },
              justifyContent: {
                md: "space-around",
                sm: "center",
                xs: "center",
              },
              alignItems: { lg: "flex-start", md: "center" },
              flexDirection: "row",
            }}
          >
            <Button
              className="mr-4"
              mx={1}
              my={1}
              sx={{
                background:
                  "linear-gradient(90deg, #618F00 0%, #ABFD00 133.68%)",
                margin: { md: "0px", sm: "5px 10px", xs: "5px" },
                border: "2px solid transparent",
                borderRadius: "30px",
                padding: "10px 18px",
                "&:hover": {
                  background: "transparent",
                  border: "2px solid #8ED003",
                },
              }}
              onClick={() =>
                window.open(
                  "mailto:email@example.com?subject=Help Centre&body="
                )
              }
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  fontSize: { md: "14px", sm: "14px", xs: "12px" },
                }}
              >
                Email Support
              </Typography>
            </Button>

            <div>
              {/* <Button
                mx={1}
                my={1}
                sx={{
                  margin: { md: "0px", sm: "5px 10px", xs: "5px" },
                  background: "transparent",
                  border: "2px solid #8ED003",
                  borderRadius: "30px",
                  padding: "10px 18px",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #618F00 0%, #ABFD00 133.68%)",
                    width: "fit-content",
                  },
                }}
                className="mx-8"
              > */}
              <Typography
                className="text-center mt-2 font-semibold"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  fontSize: { md: "16px", sm: "16px", xs: "12px" },
                }}
              >
                Provide Feedback
              </Typography>
              {/* </Button> */}
              <MessageBox />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default HelpPage;
