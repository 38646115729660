const stripe = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/stripe.svg"
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/Card.svg"
const gpay = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/Gpay.svg"
const gpayLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/GPay_light.svg"
const payoneer = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/payoneer.svg"
const payoneerLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/payoneer_light.svg"
const paypal = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/paypal.svg"
const cardWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/card.svg";
const localBank = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/withdraw/localbank.svg";
const paypalIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/withdraw/paypal.svg";
const stripeIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/withdraw/stripe.svg";

export const methods=[
    {
        id:0,
        name:"Paypal",
        img:paypal,
        light:paypal,
        status:true
    },
    {
        id:1,
        name:"Debit Card",
        img:card,
        status:true,
        light:cardWhite
    },{
        id:3,
        name:"Stripe",
        img:stripe,
        status:false,
        light:stripe
    },{
        id:4,
        name:"Google Pay",
        img:gpay,
        status:false,
        light:gpayLight,
    },{
        id:5,
        name:"Payoneer",
        img:payoneer,
        status:false,
        light:payoneerLight
    },
]

export const withdrawMethod=[
      {
        id:0,
        name:"Direct to Local Bank",
        details:"Deposit to your local bank account in Local Currency",
        img:localBank,
        link:"/dashboard/payments/setup-local-bank"
    },
    {
        id:1,
        name:"Paypal",
        details:'$2 deduction per withdrawal',
        img:paypalIcon,
    },
    {
        id:2,
        name:"Stripe",
        details:"$2 deduction per withdrawal",
        img:stripeIcon,
    },
]