const donate = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/button.svg'
const link = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/link.svg'
const sticky = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/sticky.svg'
const form = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/form.svg'
const reminder = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/reminder.svg'
const simpleForm = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/simpleForm.svg'
const imgCard = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/imgCard.svg'
const goalMeter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/goalMeter.svg'
const statCounter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/statCounter.svg'
const impactSlider = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/impactSlider.svg'
const levels = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/levels.svg'
const buttonGroup = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/buttonGroup.svg'
const msgBar = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/msgBar.svg'
const topSupporter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/supporter.svg'
const locations = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/locations.svg'
const socialProof = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/socialProof.svg'
const qr = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/QR.svg'
const recentDonation = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/recentDonation.svg'
const map = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/map.svg'
const p2pBtn = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/p2pBtn.svg'
const p2pLink = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/p2pLink.svg'
const topFundraiser = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/topFundraiser.svg'
const popup = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/popup.svg'
const dark_donate = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/donateBtn.svg'
const dark_link = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/link.svg'
const dark_sticky = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/sticky.svg'
const dark_form = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/form.svg'
const dark_reminder = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/reminder.svg'
const dark_simpleForm = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/simple.svg'
const dark_imgCard = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/imgCard.svg'
const dark_goalMeter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/goalMeter.svg'
const dark_statCounter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/statCounter.svg'
const dark_impactSlider = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/impact.svg'
const dark_levels = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/levels.svg'
const dark_buttonGroup = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/groups.svg'
const dark_msgBar = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/msgBar.svg'
const dark_topSupporter = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/supporter.svg'
const dark_locations = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/location.svg'
const dark_socialProof = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/socialProof.svg'
const dark_qr = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/QR.svg'
const dark_recentDonation = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/recent.svg'
const dark_map = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/map.svg'
const dark_p2pBtn = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/p2pButton.svg'
const dark_p2pLink = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/p2pLink.svg'
const dark_topFundraiser = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/topFundraiser.svg'
const dark_popup = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/elements/popup.svg'


export const category = [
  {
    id: 0,
    name: 'All Elements',
    type: null
  },
  {
    id: 1,
    name: 'Buttons',
    type: 'button'
  },
  {
    id: 2,
    name: 'Data',
    type: 'data'
  },
  {
    id: 3,
    name: 'Forms',
    type: 'form'
  },
  {
    id: 4,
    name: 'Links',
    type: 'link'
  },
  {
    id: 5,
    name: 'Lists',
    type: 'list'
  },
  {
    id: 6,
    name: 'Overlays',
    type: 'overlay'
  }
]

export const allElements = [
  {
    id: 0,
    name: 'Donate Button',
    type: "donate_button",
    detail: 'Display an animated donation button anywhere on your website.',
    enable:false,
    img: donate,
    light:dark_donate,
    category: 'button',
    display:true,
    tabs: [
      {
        id: 0,
        name: 'Behaviour'
      },
      {
        id: 1,
        name: 'Appearance'
      },
      {
        id: 2,
        name: 'Custom Fields'
      }
    ]
  },

  {
    id: 1,
    name: 'Donate Link',
    type: "donate_link",
    detail:'Get a link that sends visitors to your website and automatically opens donation checkout.',
    enable:false,
    img: link,
    light:dark_link,
    category: 'link',
    display:false,
    tabs: [
      {
        id: 0,
        name: 'Behaviour'
      },
      {
        id: 1,
        name: 'Custom Fields'
      }
    ]
  },

  {
    id: 2,
    name: 'Sticky Button',
    type: "sticky_button",
    detail:
      'Get a link that sends visitors to your website and automatically opens donation checkout.',
    enable: true,
    img: sticky,
    light:dark_sticky,
    category: 'button',
    display:true,
    tabs: [
      {
        id: 0,
        name: 'Behaviour'
      },
      {
        id: 1,
        name: 'Appearance'
      },
      {
        id: 2,
        name: 'Visibility'
      },
      {
        id: 3,
        name: 'Custom Fields'
      }
    ]
  },

  {
    id: 3,
    name: 'Reminder',
    type: "reminder",
    detail:
      'Show a pop-up reminder to donate when a supporter closes checkout.',
    img: reminder,
    light:dark_reminder,
    enable: true,
    category: 'overlay',
    display:"true",
    tabs: [
      {
        id: 0,
        name: 'Appearance'
      },
      {
        id: 1,
        name:"URL Control"
      },
    ]
  },

  {
    id: 4,
    name: 'Donation Form',
    type: "donation_form",
    detail: 'Display a customizable donation form anywhere on your website.',
    img: form,
    light:dark_form,
    enable: false,
    category: 'form',
    display:"true",
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Form",
      },
      {
        id:2,
        name:"Controls",
      },
      {
        id:3,
        name:"Button",
      },
      {
        id:4,
        name:"Custom Fields",
      },
    ]
  },

  {
    id: 5,
    name: 'Simple Form',
    type: "donation_form",
    detail: 'Display a simple one-line donation form anywhere on your website.',
    img: simpleForm,
    light:dark_simpleForm,
    enable: false,
    category: 'form',
    display:"true",
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Form",
      },
      {
        id:2,
        name:"Amount",
      },
      {
        id:3,
        name:"Button",
      },
      {
        id:4,
        name:"Custom Fields",
      },
    ]

  },

  {
    id: 6,
    name: 'Image Card',
    type: "image_card",
    detail:
      'Display an interactive card that features an image, text, and button.',
    img: imgCard,
    light: dark_imgCard,
    enable: false,
    category: 'button',
    display:"true",
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Card",
      },
      {
        id:2,
        name:"Button",
      },
      {
        id:3,
        name:"Custom Fields",
      },
    ]

  },

  {
    id: 7,
    name: 'Goal Meter',
    type: "goal_meter",
    detail: 'Display an animated progress bar of your campaign’s performance.',
    category: 'data',
    enable: false,
    img: goalMeter,
    light: dark_goalMeter,
    display:"true",
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Appearance",
      },
    ]
  },

  {
    id: 8,
    name: 'Stat Counter',
    type: "start_counter",
    detail: 'Display an animated statistic of your campaign’s performance.',
    category: 'data',
    enable: false,
    img: statCounter,
    light: dark_statCounter,
    display:"true",
  },

  {
    id: 9,
    name: 'Impact Slider',
    type: "impact_slider",
    detail:
      'Engage supporters with a customizable interactive slider that shows their donation’s impact.',
    category: 'form',
    img: impactSlider,
    light: dark_impactSlider,
    enable: false,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Form",
      },

      {
        id: 2,
        name: "Button",
      },
      {
        id: 3,
        name: "Label",
      },
      {
        id: 4,
        name: "Custom Fields",
      },
    ]
  },

  {
    id: 10,
    name: 'Donation Levels',
    type: "donation_levels",
    detail:
      'Display one or more cards with customizable text, donation amounts, and buttons.',
    category: 'form',
    img: levels,
    light: dark_levels,
    display:"true",
    enable: false,
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Panel",
      },
      
      {
        id:2,
        name:"Button",
      },
    ]
  },

  {
    id: 11,
    name: 'Button Group',
    type: "button_group",
    detail: 'Display a set of buttons with customizable donation amounts.',
    category: 'button',
    img: buttonGroup,
    light: dark_buttonGroup,
    enable: false,
    display:"true",
    tabs:[
      {
        id:0,
        name:"Behaviour",
      },
      {
        id:1,
        name:"Title",
      },
      
      {
        id:2,
        name:"Buttons",
      },
      {
        id:2,
        name:"Custom Fields",
      },
    ]
  },

  {
    id: 12,
    name: 'Message Bar',
    type: "message_bar",
    detail:
      'Display a full-width message and button at the top or bottom of your website.',
    category: 'overlay',
    img: msgBar,
    light: dark_msgBar,
    enable: true,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Panel",
      },
      {
        id: 2,
        name: "Button",
      },
      {
        id: 2,
        name: "Visibility",
      },
    ]
  },

  {
    id: 13,
    name: 'Top Supporters',
    type: "top_supporters",
    detail:
      'Shows a list of your supporters ordered by how much they’ve personally donated.',
    category: 'list',
    enable: false,
    img: topSupporter,
    light: dark_topSupporter,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "List",
      },
    ]
  },

  {
    id: 14,
    name: 'Top Locations',
    type: "top_locations",
    detail: 'Show a ranking of top cities/regions/countries by amount donated.',
    category: 'list',
    img: locations,
    light: dark_locations,
    display: "true",
    enable: false,
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "List",
      },
    ]
  },

  {
    id: 15,
    name: 'Social Proof',
    type: "social_proof",
    detail: 'Display a real-time notification of recent donations.',
    category: 'overlay',
    img: socialProof,
    light: dark_socialProof,
    display: "true",
    enable: true,
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Panel",
      },
      {
        id: 2,
        name: "Button",
      },
      {
        id: 3,
        name: "URL Control",
      },
    ]
  },
  {
    id: 16,
    name: 'QR Code',
    img:qr,
    light:dark_qr,
    type: "qr_code",
    detail:
      'Generate a scannable code that when scanned, directs supporters to a campaign’s checkout.',
    category: 'link',
    enable: false,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Appearance",
      },
      {
        id: 2,
        name: "Custom Fields",
      },
    ]
  },
  {
    id: 17,
    name: 'Recent Donations',
    type: "recent_donations",
    detail: 'Show a list of your most recent donations.',
    category: 'list',
    img:recentDonation,
    light:dark_recentDonation,
    enable: false,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "List",
      },
    ]
  },
  {
    id: 18,
    name: 'Donor Map',
    type: "donor_map",
    detail: 'Show a map of your most recent donations.',
    category: 'data',
    img:map,
    light:dark_map,
    enable: false,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Map",
      },
      {
        id: 2,
        name: "Appearance",
      },
    ]
  },
  {
    id: 19,
    name: 'P2P Button',
    type: "p2p_button",
    detail:
      'Display a button that allows supporters to start P2P fundraisers on your behalf.',
    category: 'button',
    img:p2pBtn,
    light:dark_p2pBtn,
    enable: false,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Appearance",
      },
      {
        id: 2,
        name: "Custom Fields",
      },
      {
        id: 3,
        name: "Questions",
      },
    ]
  },
  {
    id: 20,
    name: 'P2P Link',
    type: "p2p_link",
    detail:
      'Get a link that sends supporters to start P2P fundraisers on your behalf.',
    img: link,
    light:dark_link,
    enable: false,
    category: 'link',
    display: "false",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Custom Fields",
      },
      {
        id: 2,
        name: "Question",
      },
    ]
  },
  {
    id: 21,
    name: 'Top Fundraisers',
    type: "top_fundraisers",
    detail:
      'Show a list of your P2P fundraisers ordered by how much they’ve raised',
    category: 'list',
    img:topFundraiser,
    light:dark_topFundraiser,
    enable: false,
    display: "false",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Appearance",
      },
    ]
  },
  {
    id: 22,
    name: 'Popup',
    type: "popup",
    detail:
      'Display a call-to-action popup with a custom image, text, and donation button',
    category: 'overlay',
    img:popup,
    light:dark_popup,
    enable: true,
    display: "true",
    tabs: [
      {
        id: 0,
        name: "Behaviour",
      },
      {
        id: 1,
        name: "Form",
      },
      {
        id: 2,
        name: "Button",
      },
      {
        id: 3,
        name: "Visibility",
      },
      {
        id: 4,
        name: "Custom Fields",
      },
    ]
  }
]

export const tabs = [
  {
    id: 0,
    name: 'Behaviour'
  },
  {
    id: 1,
    name: 'Appearance'
  },
  {
    id: 2,
    name: 'Custom Fields'
  }
]

export const freq = [
  {
    id: 1,
    name: "Give Once",
    value: "once"
  },
  {
    id: 2,
    name: "Monthly",
    value: "monthly"
  },
  {
    id: 3,
    name: "Quarterly",
    value: "quarterly"
  },
  {
    id: 4,
    name: "Yearly",
    value: "yearly"
  },
];


export const counterType=[
  {
    id:0,
    name:"Goal Amount"
  },
  {
    id:1,
    name:"Amount Raised"
  },
  {
    id:3,
    name:"Number of supporters"
  },
  {
    id:4,
    name:"Number of donations"
  },
  {
    id:5,
    name:"Number of recurrings"
  },
  {
    id:6,
    name:"Monthly value of recurrings"
  },

]