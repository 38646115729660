import { Outlet, Navigate, Route, Routes } from "react-router-dom";
import { Grid, Typography, Button, TextField } from "@mui/material";
import Insights from "./Insights/Insights";
import Campaign from "./Campaign/Campaign";
import Donation from "./Donations/Donation";
import P2P from "./P2P/P2P";
import Recurring from "./Recurring/Recurring";
import Supporter from "./Supporter/Supporter";

import PeerToPeer from "./CreateCampaign/PeerToPeer/PeerToPeer";
import CampaignEmail from "./CreateCampaign/CampaignEmail/CampaignEmail";
import CampaignOverview from "./CreateCampaign/CampaignOverview/CampaignOverview";
import CheckoutModal from "./CreateCampaign/CheckoutModal/CheckoutModal";
import CampaignSettings from "./CreateCampaign/CampaignSettings/CampaignSettings";
import PublishCampaign from "./CreateCampaign/PublishCampaign/PublishCampaign";

import Exports from "./Exports/Exports";
import CreateNewReport from "./Reports/CreateNewReport";
import PastReport from "./Reports/PastReport";

import Reciept from "./Donations/Reciept/Reciept";
import Elements from "./Elements/Elements";
import HelpPage from "./Help/Help";
import RecurringDonor from "./Recurring/RecurringDonor/RecurringDonor";
import P2PDonor from "./P2P/P2PDonor/P2PDonor";
import Settings from "./Settings/Settings";
import { useSelector } from "react-redux";
import Payment from "./Payment/Payment";
import WithdrawDonation from "./Payment/WithdrawDonation/WithdrawDonation";
import DirectToLocalBank from "./Payment/Setup-Withdrawl/DirectToLocalBank";

const Dashboard = ({
  selectedOption,
  showPopupCallback,
  withdrawPopup,
  setWithdrawPopup,
  setSelectedOption,
  newElementPopup,
  setNewElementPopup,
  onClose,
  setSelectedElement,
  refetchElementRows,
  setRefetchElementRows,
}) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      sx={{
        //backgroundColor: '#1C1C1C',
        backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
        borderRadius: "20px",
        width: "100%",
        height: "fit-content",
        overflow: "hidden",
      }}
      //className={`theme: ${themeMode}`}
    >
      <Outlet />
      <Routes>
        <Route path="insights" element={<Insights />} />
        <Route
          path="campaigns"
          element={<Campaign showPopupCallback={showPopupCallback} />}
        />
        <Route path="donations" element={<Donation />} />
        <Route
          path="elements"
          element={
            <Elements
              newElementPopup={newElementPopup}
              setNewElementPopup={setNewElementPopup}
              onClose={onClose}
              setSelectedElement={setSelectedElement}
              refetchElementRows={refetchElementRows}
              setRefetchElementRows={setRefetchElementRows}
            />
          }
        />
        <Route path="p2p" element={<P2P />} />
        <Route path="recurring" element={<Recurring />} />
        <Route path="supporters" element={<Supporter />} />
        <Route path="exports" element={<Exports />} />
        <Route path="settings" element={<Settings />} />
        <Route path="create-report" element={<CreateNewReport />} />
        <Route path="reports" element={<PastReport />} />
        <Route path="help" element={<HelpPage />} />
        <Route path="withdraw-donation" element={<WithdrawDonation />} />

        <Route
          path="payments"
          element={
            <Payment
              withdrawPopup={withdrawPopup}
              setWithdrawPopup={setWithdrawPopup}
            />
          }
        />

        <Route
          path="payments"
          element={
            <Payment
              withdrawPopup={withdrawPopup}
              setWithdrawPopup={setWithdrawPopup}
            />
          }
        />
        <Route
          path="payments/setup-local-bank"
          element={
            <DirectToLocalBank
              withdrawPopup={withdrawPopup}
              setWithdrawPopup={setWithdrawPopup}
            />
          }
        />

        <Route
          path="campaign-overview/:uuid/:title"
          element={<CampaignOverview setSelectedOption={setSelectedOption} />}
        />
        <Route
          path="peer-to-peer/:uuid/:title"
          element={
            <PeerToPeer
              setSelectedOption={setSelectedOption}
              showPopupCallback={showPopupCallback}
            />
          }
        />
        <Route
          path="campaign-email/:uuid/:title"
          element={
            <CampaignEmail
              setSelectedOption={setSelectedOption}
              showPopupCallback={showPopupCallback}
            />
          }
        />
        <Route
          path="campaign-settings/:uuid/:title"
          element={
            <CampaignSettings
              setSelectedOption={setSelectedOption}
              showPopupCallback={showPopupCallback}
            />
          }
        />
        <Route
          path="checkout-modal/:uuid/:title"
          element={
            <CheckoutModal
              setSelectedOption={setSelectedOption}
              showPopupCallback={showPopupCallback}
            />
          }
        />
        <Route
          path="publish-campaign/:uuid/:title"
          element={
            <PublishCampaign
              setSelectedOption={setSelectedOption}
              showPopupCallback={showPopupCallback}
            />
          }
        />

        <Route path="donation/:uuid" element={<Reciept />} />
        <Route path="donor/:uuid" element={<RecurringDonor />} />
        <Route path="p2p/:uuid" element={<P2PDonor />} />

        <Route path="*" element={<Navigate to="insights" />} />
      </Routes>
    </Grid>
  );
};
export default Dashboard;
