import { makeStyles } from "@mui/styles";

const line = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/line.png";

export const useStyles = makeStyles((theme) => ({
  fullInput: {
    //mt: { md: 2, sm: 2, xs: 2 },
    mr: 1,
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",

    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      outline: "none",
    },
  },
  halfInput: {
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    width: "49%",
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  line: {
    overflow: "visible",
    backgroundImage: `url('${line}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    position: "relative",
  },
  rowSpace: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  select: {
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    width: "100%",
    textAlign:"left",
    // "& .MuiOutlinedInput-input": {
    //   color: "#FFFFFF",
    //   "&::placeholder": {
    //     color: "#FFFF !important",
    //   },
    // },
     "& .MuiInputLabel-root": {
       color: "#FFFFF0",
       fontSize: "16px",
       margin: "15px",
       transform: "translate(0px, 0%) scale(1)",
       "&.MuiInputLabel-shrink": {
         transform: "translate(0px, 0%) scale(1)",
       },
     },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
    },
  },
  menuItem: {
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#160602",
    "&:hover": {
      backgroundColor: "#551e23",
      color: "#689900",
    },
    "&:focus": {
      backgroundColor: "#160609",
      color: "#689900",

      "&:hover": {
        backgroundColor: "#551e23",
        color: "#689900",
      },
    },
  },
  justifyLeft: {
    display: "flex",
    justifyContent: "left",
  },
  checkbox:{
    color: "#689900",
  },
  flex:{
    display: "flex", 
  },
  signup:{
    background: "transparent",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "5px 15px",
  },
  input:{
    borderRadius: "10px",
    width: "100%",
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  inputText: {
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "capitalize",
    width:"100%",
    textAlign:"left",
    color:"#FFF"
  },

}));
