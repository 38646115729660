import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/SupporterManagement/bkgLight.svg'
import hero from '../../../../../../assets/FeatureLightTheme/ElementManagement/hero.svg'
import heroDark from '../../../../../../assets/FeatureLightTheme/ElementManagement/heroDark.svg'

const element3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element3.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";
const element4 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element4.svg";
const light_element1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_element1.svg";
const light_element2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_element2.svg";


const HeroSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 120px 92px",
            sm: "130px 60px 72px",
            xs: "100px 30px 72px",
          },
          backgroundImage:
            themeMode === "dark"
              ? `url('${bkg}')`
              : `url('${bkgLight}')`,
        }}
      >
        <div className="md:flex w-full">
          
          <Grid
            className={classes.columnCenter}
            item
            md={6.5}
            sm={10}
            xs={12}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "46px", sm: "36px", xs: "32px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                fontFamily: "Stolzl" ,
              }}
            >
              <span
                className={themeMode === "dark" ? "text-white" : "text-black"}
              >
                {" "}
                The UI kit for {" "}
              </span>
              <span className={themeMode === "dark" ? classes.color : classes.colorLight} 
                sx={{ fontWeight: "700", fontFamily: "Stolzl"}}>
                
                digital fundraising
              </span>{" "}
            </Typography>
            <Typography
              className={themeMode === "dark" ? "text-white pt-4" : "text-black pt-4"}
              sx={{ fontFamily: "Stolzl", width: {md:"90%",sm:"90%",xs:"100%"}, textAlign: { md: "left", sm: "left", xs: "center" }, }}
            >
              Update your nonprofit’s website with pre-built buttons, sliders,
              overlays, and other components that engage website visitors and
              convert them to donors.
            </Typography>

            <Grid 
              sx={{ 
                width: { md: "80%", sm: "100%", xs: "100%" }, 
                display: "flex", 
                flexDirection:"row",
                justifyContent: { md: "flex-start", sm: "flex-start", xs: "space-between" } 
              }}>
              <Button
                variant="outlined"
                sx={{
                  width:"fit-content",
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "9px" },
                  textTransform: "capitalize",
                  textWrap:"nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4 md:mr-4"
              >
                Highly Customizable
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "fit-content",
                  background: themeMode === "dark" ? "#130407" : "",
                  borderRadius: "30px",
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  border: themeMode === "dark" ? "1px solid #5C5C5C" : "1px solid #030303",
                  fontSize: { md: "14px", sm: "12px", xs: "9px" },
                  textTransform: "capitalize",
                  textWrap: "nowrap",
                  "&:hover": {
                    background: themeMode === "dark" ? "#78AA0522" : "#FFF",
                    border: themeMode === "dark" ? "1px solid #6A9D01" : "1px solid #030303"
                  }
                }}
                className="mt-4 md:mr-4"
              >
                No Developer Required{" "}
              </Button>
            </Grid>

          </Grid>
          
          
          
          <Grid
            //my={3}
            item
            //className={classes.jCenter}
            md={7}
            sm={10}
            xs={12}
            sx={{
              width: { md: "40%", sm: "auto", xs: "auto" },
              display: "flex", justifyContent:"flex-end",
              alignItems:"center"
            }}
          >
            <Grid
              item
              //className={classes.textCenter}
              md={10}
              sx={{
                minHeight: "20rem", display: "flex", justifyContent: "flex-end",
                alignItems: "center" }}
            >
              <img src={themeMode==="dark"?heroDark:hero} alt=""  />
            </Grid>
          </Grid>{" "}
        </div>
      </Grid>
    </>
  );
};
export default HeroSection;
