import Footer from "../Footer/Footer";
import NavBar from "../Navbar/Navbar";
import AboutUs from "./AboutUs/AboutUs";
import Client from "./Clients/Clients";
import Dashboard from "./Dashboard/Dashboard";
import Donation from "./Donation/Donation";
import Global from "./Global/Global";
import Hero from "./Hero/Hero";
import Mobile from "./Mobile/Mobile";
import { useSelector } from "react-redux";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/footer.png";
const lightBkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/footerLight.svg";

const HomePage=()=>{
    const themeMode = useSelector((state) => state.themeMode.mode);
    const light="light";
    const dark="dark";
    return(
        <>
        {
            themeMode==="dark"?
            <NavBar 
                scrollColor="#081a01" 
                btnColor="transparent" 
                getStartedBorder="1px solid #689900" 
                profileBkg="#2e3322"
                profileBorder="1px solid #94D906"
                logoTheme={light}
                scrollingColor={light}
                txtColor='#FFFF'
                
            />
        :
        <NavBar 
            scrollColor="#174f01" 
            btnColor="transparent" 
            getStartedBorder="1px solid #689900" 
            profileBkg='#7d8765'
            profileBorder="1px solid #94D906"
            logoTheme={dark}
            scrollingColor={light}
            txtColor='#030303'
        />
        }
        
        <Hero />
        <Client />
        <AboutUs />
        <Dashboard />
        <Mobile />
        <Donation />
        <Global />
        {
            themeMode==="dark"?
                <Footer 
                    bkg={bkg} 
                    btnText="#FFFFFF" 
                    talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)" 
                    btnHover="#081a01" 
                    btnBorder= "0.5px solid #689900" 
                    textHover="#FFFFFF" 
                    brightness="brightness(100%)"/>
        :
                <Footer 
                    bkg={lightBkg} 
                    btnText="#FFFFFF" 
                    talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)" 
                    btnHover="#081a01" 
                    btnBorder= "0.5px solid #689900" 
                    textHover="#FFFFFF" 
                    brightness="brightness(100%)"/>
                }
        </>
        
    )
}
export default HomePage;