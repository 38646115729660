export const freq = [
  {
    id: 1,
    name: "Give Once",
    value: "once"
  },
  {
    id: 2,
    name: "Monthly",
    value: "monthly"
  },
  {
    id: 3,
    name: "Quarterly",
    value: "quarterly"
  },
  {
    id: 4,
    name: "Yearly",
    value: "yearly"
  },
];

export const amount = [
  {
    id: 1,
    name: "1000",
    value: 1000
  },
  {
    id: 2,
    name: "500",
    value: 500
  },
  {
    id: 3,
    name: "200",
    value: 200
  },
  {
    id: 4,
    name: "100",
    value: 100
  },
  {
    id: 5,
    name: "50",
    value: 50
  },
  {
    id: 6,
    name: "20",
    value: 20
  },
];

