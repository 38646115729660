import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = "https://api.accceleraise.com/api/v1";

export const supportEmail = createAsyncThunk(
  "support/supportEmail",
  async (params, { dispatch, getState }) => {
    try {
      const data = await axios.post(`${API_URL}/support_emails`, params);

      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);
const supportSlice = createSlice({
  name: "support",
  initialState: { mail: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(supportEmail.fulfilled, (state, action) => {
      state.mail = action.payload;
    });
  },
});

export const {} = supportSlice.actions;
export default supportSlice.reducer;
