import { gql } from "@apollo/client";


export const GET_P2P_FUNDRAISE = gql`
  query PeerToPeerFundraise($uuid: ID!, $first: Int, $after: String, $sortColumn: MemberSortColumnsEnum, $sortOrder: SortOrderEnum){
    peerToPeerFundraise(uuid: $uuid) {
        uuid
        active
        name
        campaign {
          uuid
          title
          description
          raised
          donations {
            nodes {
              amount
              currency
            }
          }
          recurringDonations {
            nodes {
              amount
              currency
            }
          }
          campaignTemplates {
            nodes {
              uuid
              name
              template
              active
            }
          }
        }
        recurringDonationCount
        supporterCount
        donorPerDay
        updatedAt
        createdAt
        goalDate
        goalAmount
        goalCurrency
        message
        user {
          uuid
          firstName
          lastName
          email
          organization
          jobTitle
        }
        members(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after){
          nodes {
            uuid
            donor {
              uuid
              firstName
              lastName
              email
              phoneNumber
            }
            peerToPeerFundraise {
              user {
                uuid
                firstName
                lastName
                email
                organization
                jobTitle
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
    }
  }
`;

export const DELETE_P2P_MEMBER = gql`
  mutation DeletePeerToPeerFundraise($uuid: ID!){
    deleteMember(input:{uuid: $uuid}){
      success
    }
  }
`;