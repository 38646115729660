import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Input,
  Checkbox,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { OverviewList } from "../overviewList";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { ArrowRightAlt } from "@mui/icons-material";
import { MySwitch, MyTextField, donationType } from "../MyComponents";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CAMPAIGN_SETTINGS_GENERAL,
  CAMPAIGN_SETTINGS_PAYMENTS,
  CAMPAIGN_SETTINGS_SUPPORTER,
  GET_CAMPAIGN,
  CAMPAIGN_SETTINGS_SUGGESTIONS,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useStyles } from "./useStyles";
import PreviewDialog from "../../Campaign/PreviewScreens/PreviewDialog";
import { methods, suggestion, freq } from "./methods";

const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";

const CampaignSettings = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoFileInputRef = useRef(null);
  const mainImgFileInputRef = useRef(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [logoFile, setlogoFile] = useState(null);
  const [mainImgFile, setMainImgFile] = useState(null);

  const [imageLogoUrl, setImageLogoUrl] = useState(null);
  const [imageMainUrl, setImageMainUrl] = useState(null);

  const [selectedColor, setSelectedColor] = useState("#000");
  const [mode, setMode] = useState("General");
  const [type, setType] = useState(1);
  const [isEndDateChecked, setIsEndDateChecked] = useState(false);
  const [campaignObj, setCampaignObj] = useState({
    uuid: uuid,
    title: null,
    description: null,
    campaignUrl: null,
    endDate: null,
    defaultPaymentMethod: null,
    defaultCheckoutCurrency: null,
    detectLocation: null,
    minimumOneTimeDonation: null,
    minimumRecurringDonation: null,
    transactionCost: null,
    askForEmail: null,
    askForPhoneNumber: null,
    allowAnonymously: null,
    acceptTermsCondition: null,
    defaultFrequency: null,
    defaultSuggestedAmounts: null,
  });
  const [displayedFrequency, setDisplayedFrequency] = useState([]);
  const [displayedSuggestions, setDisplayedSuggestions] = useState([]);
  const [numberOfSuggestedAmount, setNumberOfSuggestedAmount] = useState(null);

  const [
    CampaignSettingGeneral,
    { loading: generalLoading, error: generalError },
  ] = useMutation(CAMPAIGN_SETTINGS_GENERAL, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const [
    CampaignSettingPayments,
    { loading: paymentLoading, error: paymentError },
  ] = useMutation(CAMPAIGN_SETTINGS_PAYMENTS, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const [
    CampaignSettingSupporter,
    { loading: supporterLoading, error: supporterError },
  ] = useMutation(CAMPAIGN_SETTINGS_SUPPORTER, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });

  const [
    CampaignSettingSuggestions,
    { loading: suggestionLoading, error: suggestionError },
  ] = useMutation(CAMPAIGN_SETTINGS_SUGGESTIONS, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });

  const { data: campaign } = useQuery(GET_CAMPAIGN, {
    variables: { uuid },
    onCompleted: (result) => {
      if (result?.campaign) {
        setCampaignObj((prevObj) => ({
          ...prevObj,
          uuid: uuid,
          title: result?.campaign?.title,
          description: result?.campaign?.description,
          campaignUrl: result?.campaign?.campaignUrl,
          endDate: result?.campaign?.endDate?.split("T")[0],
          defaultPaymentMethod: result?.campaign?.defaultPaymentMethod,
          defaultCheckoutCurrency: result?.campaign?.defaultCheckoutCurrency,
          detectLocation: result?.campaign?.detectLocation,
          minimumOneTimeDonation: result?.campaign?.minimumOneTimeDonation,
          minimumRecurringDonation: result?.campaign?.minimumRecurringDonation,
          transactionCost: result?.campaign?.transactionCost,
          askForEmail: result?.campaign?.askForEmail,
          askForPhoneNumber: result?.campaign?.askForPhoneNumber,
          allowAnonymously: result?.campaign?.allowAnonymously,
          acceptTermsCondition: result?.campaign?.acceptTermsCondition,
          defaultSuggestedAmounts: result?.campaign?.defaultSuggestedAmounts,
          defaultFrequency: result?.campaign?.defaultFrequency,
        }));
        if (result?.campaign?.endDate) {
          setIsEndDateChecked(true);
        }
        setNumberOfSuggestedAmount(result?.campaign?.defaultSuggestedAmounts?.length)
      }
    },
  });
  const changeType = (index) => {
    setType(index);
  };
  const GeneralMode = () => {
    setMode("General");
  };

  const PaymentsMode = () => {
    setMode("Payments");
  };

  const SupportersMode = () => {
    setMode("Supporters");
  };

  const SuggestionsMode = () => {
    setMode("Suggestions");
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (mode === "General") {
      const variables = {
        uuid: uuid,
        title: campaignObj?.title,
        description: campaignObj?.description,
        campaignUrl: campaignObj?.campaignUrl,
        endDate: campaignObj?.endDate,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await CampaignSettingGeneral({ variables });
        dispatch(setSpinnerLoading(false));
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
      }
    } else if (mode === "Payments") {
      const variables = {
        uuid: uuid,
        defaultPaymentMethod: campaignObj?.defaultPaymentMethod,
        defaultCheckoutCurrency: campaignObj?.defaultCheckoutCurrency,
        detectLocation: campaignObj?.detectLocation,
        minimumOneTimeDonation: parseFloat(campaignObj?.minimumOneTimeDonation),
        minimumRecurringDonation: parseFloat(
          campaignObj?.minimumRecurringDonation
        ),
        transactionCost: campaignObj?.transactionCost,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await CampaignSettingPayments({ variables });
        dispatch(setSpinnerLoading(false));
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
      }
    } else if (mode === "Supporters") {
      const variables = {
        uuid: uuid,
        askForEmail: campaignObj?.askForEmail,
        askForPhoneNumber: campaignObj?.askForPhoneNumber,
        allowAnonymously: campaignObj?.allowAnonymously,
        acceptTermsCondition: campaignObj?.acceptTermsCondition,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await CampaignSettingSupporter({ variables });
        dispatch(setSpinnerLoading(false));
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
      }
    } else if (mode === "Suggestions") {
      const variables = {
        uuid: uuid,
        defaultSuggestedAmounts: campaignObj?.defaultSuggestedAmounts,
        defaultFrequency: campaignObj?.defaultFrequency,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await CampaignSettingSuggestions({ variables });
        dispatch(setSpinnerLoading(false));
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
      }
    }
  };


  useEffect(() => {
    const matchIndex = suggestion.findIndex(
      (item) => item.show === numberOfSuggestedAmount
    );
    let newSuggestion = suggestion;
    if (matchIndex !== -1) {
      newSuggestion = suggestion.slice(0, matchIndex + 1);
      setDisplayedSuggestions(newSuggestion);
    } else {
      setDisplayedSuggestions(suggestion);
    }
    const defaultSuggestedAmounts = newSuggestion?.map(item => parseInt(item.name));
    setCampaignObj((prevObj) => ({
      ...prevObj,
      defaultSuggestedAmounts: defaultSuggestedAmounts,
    }))
 
  }, [numberOfSuggestedAmount]);

  useEffect(() => {
    const matchIndex = freq.findIndex(
      (item) => item.value === campaignObj.defaultFrequency
    );
    if (matchIndex !== -1) {
      setDisplayedFrequency(freq.slice(0, matchIndex + 1));
    } else {
      setDisplayedFrequency(freq);
    }
  }, [campaignObj.defaultFrequency]);

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                display: { md: "block", sm: "block", xs: "flex" },
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>
            </Grid>

            <Grid className={classes.fullWidth}>
              <Button
                className={classes.transButton}
                href="/dashboard/campaigns"
                sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
              >
                <Grid className={classes.flexCenter}>
                  <img
                    src={themeMode === "dark" ? heart : heartDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    className={classes.fw500}
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    Campaigns
                  </Typography>
                </Grid>
              </Button>
              {/* <Grid className={classes.flexCenter} mx={{ md: 1, sm: 1, xs: 0 }}>
                <ArrowRightAlt />
              </Grid>
              <Button
                className={classes.neonButton}
                href={`/dashboard/publish-campaign/${uuid}`}
                sx={{
                  fontSize: { md: "16px", sm: "16px", xs: "12px" },
                  margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                }}
              >
                <Grid className={classes.flexCenter}>Publish Campaign</Grid>
              </Button> */}
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "40px", sm: "40px", xs: "26px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                }}
              >
                {campaignObj?.title?.length > 15
                  ? `${campaignObj.title.substring(0, 15)}...`
                  : campaignObj.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll   */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${campaignObj?.title}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 4
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 4
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                {/* <Grid 
                    sx={{
                      display:{md:"flex",sm:"flex",xs:"flex"},
                      flexDirection:"row",
                      width:{md:"100%",sm:"fit-content",xs:"100%"},
                      padding:"0px",
                      transform:{md:"scale(1)",sm:"scale(0.9)"}
                    }}> */}

                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 4
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 4 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
                {/* </Grid> */}
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid
                  key={`arrow-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: { md: "scale(1.3)", sm: "scale(1)" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${campaignObj?.title}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 4
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 4
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 4
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 4 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>

        <Grid
          className={classes.fullWidth}
          sx={{
            justifyContent: { md: "left", sm: "left", xs: "center" },
          }}
        >
          <Button
            className={classes.modeButton}
            disableRipple
            onClick={GeneralMode}
            sx={{
              background:
                mode === "General" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 10px",
              },
              color:
                themeMode === "light" && mode !== "General"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                General
              </Typography>
            </Grid>
          </Button>

          <Button
            className={classes.modeButton}
            onClick={PaymentsMode}
            disableRipple
            sx={{
              background:
                mode === "Payments" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Payments"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                Payments
              </Typography>
            </Grid>
          </Button>

          <Button
            className={classes.modeButton}
            onClick={SupportersMode}
            disableRipple
            sx={{
              background:
                mode === "Supporters" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Supporters"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                Supporter
              </Typography>
            </Grid>
          </Button>

          <Button
            className={classes.modeButton}
            onClick={SuggestionsMode}
            disableRipple
            sx={{
              background:
                mode === "Suggestions" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Suggestions"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                Suggestions
              </Typography>
            </Grid>
          </Button>
        </Grid>

        {mode === "General" ? (
          <Grid
            item
            md={9}
            sm={9}
            xs={12}
            my={5}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Typography className={classes.fs16}>
              Improve your campaign’s visual appeal and performance by tailoring
              its name, URL and end date to suit the cause.
            </Typography>

            <Grid item md={12} my={3}>
              <FormControl className={classes.fullWidth}>
                <Grid item md={8} sm={9} xs={12}>
                  <Typography className={classes.inputText}>
                    Campaign Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="outlined-basic"
                    value={campaignObj?.title || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        title: event.target.value,
                      }))
                    }
                    className={classes.input}
                    sx={{
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                  />
                </Grid>

                {/* <Grid item md={8} sm={9} xs={12} my={2}>
                  <Typography className={classes.inputText}>
                    Campaign Description
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="outlined-basic"
                    value={campaignObj?.description || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        description: event.target.value,
                      }))
                    }
                    className={classes.input}
                    sx={{
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                  />
                </Grid> */}

                <Grid item md={8} sm={10} xs={12} my={4}>
                  <Typography className={classes.inputText}>
                    Campaign URL
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="outlined-basic"
                    value={campaignObj?.campaignUrl || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        campaignUrl: event.target.value,
                      }))
                    }
                    className={classes.input}
                    sx={{
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                  />
                </Grid>
              </FormControl>
              <Typography className={classes.inputText}>
                Campaign End Date
              </Typography>
              <Typography className={classes.f16w400}>
                Select a specific end date for this campaign. On that date, the
                campaign will be disabled and moved to your archive.
              </Typography>
            </Grid>

            <Grid my={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="large"
                    checked={isEndDateChecked}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      setIsEndDateChecked(isChecked);
                      if (!isChecked) {
                        setCampaignObj((prevObj) => ({
                          ...prevObj,
                          endDate: null,
                        }));
                      }
                    }}
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&.Mui-checked": {
                        color: themeMode === "dark" ? "#D5DA00" : "#659500",
                      },
                    }}
                  />
                }
                label="Set the end date for this campaign"
              />
            </Grid>
            {isEndDateChecked && (
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ display: "flex", alignItems: "center" }}>
                  End Date
                </Typography>
                <TextField
                  variant="outlined"
                  id="endDate"
                  type="date"
                  value={campaignObj?.endDate}
                  onChange={(event) =>
                    setCampaignObj((prevObj) => ({
                      ...prevObj,
                      endDate: event.target.value,
                    }))
                  }
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                  }}
                  sx={{
                    width: "30%",
                    marginLeft: "15px",
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    color: themeMode === "dark" ? "#ffff" : "#000",
                    "& .MuiInputLabel-root": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        ) : mode === "Payments" ? (
          <>
            <Grid
              className={classes.divider}
              item
              pb={3}
              mt={5}
              md={10}
              sm={12}
              xs={12}
              sx={{
                color: themeMode === "dark" ? "#ffff" : "#000",
                borderBottom:
                  themeMode === "dark"
                    ? "0.5px solid #FFF"
                    : "0.5px solid #000",
              }}
            >
              <Grid my={1}>
                <Typography className={classes.fs16}>
                  From this page, set the payments parameters for donors.
                </Typography>
                <Grid item md={3.5} sm={5} xs={12} my={3}>
                  <FormControl className={classes.fullWidth}>
                    <Typography className={classes.inputText}>
                      Default Payment Frequency
                    </Typography>

                    <TextField
                      select
                      variant="outlined"
                      value={campaignObj?.defaultPaymentMethod || ""}
                      onChange={(event) =>
                        setCampaignObj((prevObj) => ({
                          ...prevObj,
                          defaultPaymentMethod: event.target.value,
                        }))
                      }
                      className={classes.select}
                      sx={{
                        backgroundColor:
                          themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                        "& .MuiOutlinedInput-input": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          "&::placeholder": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            themeMode === "dark" ? "#ffff" : "#0F0F0F",
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              Width: "100%",
                              background:
                                themeMode === "dark" ? "#000" : "#ffff",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value={"Monthly-based"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        Monthly-based
                      </MenuItem>
                      <MenuItem
                        value={"3 Months"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        3 Months
                      </MenuItem>

                      <MenuItem
                        value={"6 Months"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        6 Months
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>

                <Typography className={classes.italics}>
                  This includes cards, wallets, bank debits and any other
                  currency-based payment methods.
                </Typography>

                <Grid item md={3.5} sm={5} xs={12} my={3}>
                  <FormControl className={classes.fullWidth}>
                    <Typography className={classes.inputText}>
                      Default Checkout Currency
                    </Typography>

                    <TextField
                      select
                      variant="outlined"
                      value={campaignObj?.defaultCheckoutCurrency || ""}
                      onChange={(event) =>
                        setCampaignObj((prevObj) => ({
                          ...prevObj,
                          defaultCheckoutCurrency: event.target.value,
                        }))
                      }
                      className={classes.select}
                      sx={{
                        backgroundColor:
                          themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                        "& .MuiOutlinedInput-input": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          "&::placeholder": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            themeMode === "dark" ? "#ffff" : "#0F0F0F",
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              Width: "100%",
                              background:
                                themeMode === "dark" ? "#000" : "#ffff",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value={"USD"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        US-Dollar
                      </MenuItem>

                      <MenuItem
                        value={"GBP"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        UK Pounds
                      </MenuItem>

                      <MenuItem
                        value={"PKR"}
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        PK Rupees
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid my={3} item md={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.check}
                        size="large"
                        checked={campaignObj?.detectLocation}
                        onChange={(event) =>
                          setCampaignObj((prevObj) => ({
                            ...prevObj,
                            detectLocation: event.target.checked,
                          }))
                        }
                        sx={{
                          color: themeMode === "dark" ? "#ffff" : "#000",
                          "&.Mui-checked": {
                            color: themeMode === "dark" ? "#D5DA00" : "#659500",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: { md: "16px", sm: "15px", xs: "13px" },
                        }}
                      >
                        Automatically detect each donor's default currency using
                        their geolocation.
                      </Typography>
                    }
                  />
                </Grid>

                <Grid className={classes.spaceBtwn} item md={12} my={3}>
                  <Grid item md={8} sm={10} xs={12}>
                    <FormControl
                      className={classes.spaceBtwn}
                      sx={{
                        flexDirection: { md: "row", sm: "row", xs: "column" },
                      }}
                    >
                      <Grid my={1}>
                        <Typography className={classes.inputText}>
                          Minimum One-time Donation
                        </Typography>
                        <TextField
                          variant="outlined"
                          id="outlined-basic"
                          value={campaignObj?.minimumOneTimeDonation || ""}
                          onChange={(event) =>
                            setCampaignObj((prevObj) => ({
                              ...prevObj,
                              minimumOneTimeDonation: event.target.value,
                            }))
                          }
                          className={classes.input}
                          sx={{
                            backgroundColor:
                              themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                            "& .MuiOutlinedInput-input": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&::placeholder": {
                                color: themeMode === "dark" ? "#ffff" : "#000",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                themeMode === "dark" ? "#ffff" : "#0F0F0F",
                            },
                          }}
                        />
                      </Grid>
                      <Grid my={1}>
                        <Typography className={classes.inputText}>
                          Minimum Recurring donation
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="outlined-basic"
                          value={campaignObj?.minimumRecurringDonation || ""}
                          onChange={(event) =>
                            setCampaignObj((prevObj) => ({
                              ...prevObj,
                              minimumRecurringDonation: event.target.value,
                            }))
                          }
                          className={classes.input}
                          sx={{
                            backgroundColor:
                              themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                            "& .MuiOutlinedInput-input": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&::placeholder": {
                                color: themeMode === "dark" ? "#ffff" : "#000",
                              },
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                themeMode === "dark" ? "#ffff" : "#0F0F0F",
                            },
                          }}
                        />
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              md={10}
              my={1}
              sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
            >
              <Typography className={classes.f18w700} my={2}>
                Transaction Cost
              </Typography>
              <Typography className={classes.f16w400}>
                Give your donors the opportunity to cover the transaction costs
                for their donation. If Adaptive cost coverage is enabled, 92% of
                donors using our system agree to cover the transaction costs.
              </Typography>

              <Grid my={2} item md={12}>
                <FormControl className={classes.fullWidth}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue="redirect"
                    value={campaignObj?.transactionCost || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        transactionCost: event.target.value,
                      }))
                    }
                    className={classes.container}
                  >
                    <Grid className={classes.column} item md={5}>
                      <FormControlLabel
                        value="adaptive"
                        control={
                          <Radio
                            sx={{
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#659500",
                              },
                            }}
                          />
                        }
                        label="Adaptive Cost Coverage"
                      />
                      <FormControlLabel
                        value="required_that_donors"
                        selected
                        control={
                          <Radio
                            sx={{
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#659500",
                              },
                            }}
                          />
                        }
                        label="Required that donors cover cost"
                        sx={{ mt: { md: 1, sm: 2, xs: 4 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControlLabel
                        value="show_the_option"
                        selected
                        control={
                          <Radio
                            sx={{
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#659500",
                              },
                            }}
                          />
                        }
                        label="Show the option to cover cost"
                        sx={{ mt: { md: 1, sm: 2, xs: 4 } }}
                      />
                      <FormControlLabel
                        value="show_and_per_selected"
                        selected
                        control={
                          <Radio
                            sx={{
                              color: themeMode === "dark" ? "#ffff" : "#000",
                              "&.Mui-checked": {
                                color:
                                  themeMode === "dark" ? "#D5DA00" : "#659500",
                              },
                            }}
                          />
                        }
                        label="Show and pre-select the option to cover cost"
                        sx={{ mt: { md: 1, sm: 2, xs: 4 } }}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              md={10.5}
              my={1}
              sx={{
                color: themeMode === "dark" ? "#ffff" : "#000",
                borderTop: "1px solid #FFF",
              }}
            >
              <Typography className={classes.f18w700} my={2}>
                Payment Methods
              </Typography>
              <Typography className={classes.f16w400}>
                By selecting below given payment methods, your supporters will
                have these methods options in checkout form of this campaign.
              </Typography>

              <Grid
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                {methods?.map((item, index) => (
                  <Grid my={2} item md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={classes.check}
                          size="large"
                          //checked={campaignObj?.askForEmail}
                          // onChange={(event) =>
                          //   setCampaignObj((prevObj) => ({
                          //     ...prevObj,
                          //     askForEmail: event.target.checked,
                          //   }))
                          // }
                          sx={{
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&.Mui-checked": {
                              color:
                                themeMode === "dark" ? "#D5DA00" : "#659500",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography className={classes.fs16}>
                          {item.name}
                        </Typography>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        ) : mode === "Supporters" ? (
          <>
            <Grid
              my={5}
              item
              md={11}
              sm={12}
              xs={12}
              sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
            >
              <Typography>
                Choose what information you request from donors or what actions
                you make required for donors. While enabling these settings will
                help you collect more data, making all fields required for all
                donations could negatively affect conversion.
              </Typography>
            </Grid>
            <Grid
              my={3}
              item
              md={12}
              sm={12}
              sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    size="large"
                    checked={campaignObj?.askForEmail}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        askForEmail: event.target.checked,
                      }))
                    }
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&.Mui-checked": {
                        color: themeMode === "dark" ? "#D5DA00" : "#659500",
                      },
                    }}
                  />
                }
                label={
                  <Typography className={classes.fs16}>
                    Ask for email address
                  </Typography>
                }
              />
              <Grid
                pl={{ md: 5.2, sm: 5.2, xs: 0 }}
                pr={{ md: 5.2, sm: 5.2, xs: 0 }}
                sx={{ width: { md: "85%", sm: "85%", xs: "100%" } }}
              >
                <Typography
                  sx={{ fontSize: { md: "16px", sm: "15px", xs: "13px" } }}
                >
                  Request a mailing address from your donors after they’ve
                  donated.
                </Typography>
              </Grid>
            </Grid>

            <Grid my={3} item md={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    size="large"
                    checked={campaignObj?.askForPhoneNumber}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        askForPhoneNumber: event.target.checked,
                      }))
                    }
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&.Mui-checked": {
                        color: themeMode === "dark" ? "#D5DA00" : "#659500",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    className={classes.fs16}
                    sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                  >
                    Ask for phone number
                  </Typography>
                }
              />
              <Grid
                pl={{ md: 5.2, sm: 5.2, xs: 0 }}
                pr={{ md: 5.2, sm: 5.2, xs: 0 }}
                sx={{ width: { md: "85%", sm: "85%", xs: "100%" } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  Request a phone number from your donors before they donate. We
                  recommend keeping this field optional if possible.
                </Typography>
              </Grid>
            </Grid>

            <Grid my={3} item md={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    size="large"
                    checked={campaignObj?.allowAnonymously}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        allowAnonymously: event.target.checked,
                      }))
                    }
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&.Mui-checked": {
                        color: themeMode === "dark" ? "#D5DA00" : "#659500",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    className={classes.fs16}
                    sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                  >
                    Allow to donate anonymously
                  </Typography>
                }
              />
              <Grid
                pl={{ md: 5.2, sm: 5.2, xs: 0 }}
                pr={{ md: 5.2, sm: 5.2, xs: 0 }}
                sx={{ width: { md: "85%", sm: "85%", xs: "100%" } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  This gives your donors the option to keep their donation
                  anonymous. Donor information will still be available to you in
                  the Dashboard, but it won't be used in reports, publications
                  etc.
                </Typography>
              </Grid>
            </Grid>

            <Grid my={3} item md={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.check}
                    size="large"
                    checked={campaignObj?.acceptTermsCondition}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        acceptTermsCondition: event.target.checked,
                      }))
                    }
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&.Mui-checked": {
                        color: themeMode === "dark" ? "#D5DA00" : "#659500",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    className={classes.fs16}
                    sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                  >
                    Prompt donors to accept your terms & conditions.
                  </Typography>
                }
              />
              <Grid
                pl={{ md: 5.2, sm: 5.2, xs: 0 }}
                pr={{ md: 5.2, sm: 5.2, xs: 0 }}
                sx={{ width: { md: "85%", sm: "85%", xs: "100%" } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  Use this section to provide links to your privacy policy
                  and/or terms & conditions. When enabled, your donors will have
                  to check the box in order to complete the donation.
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : mode === "Suggestions" ? (
          <>
            <Grid
              item
              md={11}
              sx={{ color: themeMode === "dark" ? "#FFF" : "#030303" }}
            >
              <Grid my={2}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  Choose what suggested options you want to show in checkout
                  forms to your supporters. The suggested amount currency is the
                  one you set in payment section of campaign settings.
                </Typography>
              </Grid>

              <Grid my={2}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  Suggested Amount
                </Typography>
              </Grid>

              <Grid item md={5.5} sm={6} xs={12} my={3}>
                <FormControl className={classes.fullWidth}>
                  <Typography
                    className={classes.inputText}
                    sx={{
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    }}
                  >
                    Number of suggestions
                  </Typography>

                  <TextField
                    select
                    variant="outlined"
                    // placeholder={campaignObj?.defaultSuggestedAmounts || ""}
                    value={numberOfSuggestedAmount || ""}
                    onChange={(event) =>
                      setNumberOfSuggestedAmount(event.target.value)
                    }
                    className={classes.select}
                    sx={{
                      width: "65% !important",
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: themeMode === "dark" ? "#000" : "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {suggestion?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.show}
                        className={classes.menuItem}
                        sx={{
                          display: item.show ? "flex" : "none",
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        {item.show > 1 ? `${item.show} blocks` : `${item.show} block`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <Grid
                  my={2}
                  item
                  sx={{
                    background: themeMode === "dark" ? "#2C2C2C" : "#FFF",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    padding: "15px 10px",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {displayedSuggestions?.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      my={1}
                      mx={1}
                      sx={{
                        border:
                          themeMode === "dark"
                            ? "2px solid #FFF"
                            : "2px solid #030303",
                        borderRadius: "10px",
                        textWrap: "nowrap",
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        textAlign: "center",
                        width:
                          item.value === "500"
                            ? "50%"
                            : item.value === "100"
                            ? "50%"
                            : "28%",
                      }}
                    >
                      Rs. {item.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid my={2}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: themeMode === "dark" ? "#ffff" : "#000",
                  }}
                >
                  Suggested Frequency
                </Typography>
              </Grid>

              <Grid item md={7} sm={5} xs={12} my={3}>
                <FormControl className={classes.fullWidth}>
                  <Typography
                    className={classes.inputText}
                    sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
                  >
                    Number of suggestions
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    placeholder={campaignObj?.defaultFrequency || ""}
                    value={campaignObj?.defaultFrequency || ""}
                    onChange={(event) =>
                      setCampaignObj((prevObj) => ({
                        ...prevObj,
                        defaultFrequency: event.target.value,
                      }))
                    }
                    className={classes.select}
                    sx={{
                      width: "50% !important",
                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: themeMode === "dark" ? "#000" : "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {freq?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.value}
                        className={classes.menuItem}
                        sx={{
                          display: item.show ? "flex" : "none",
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",
                        }}
                      >
                        {item.show > 1 ? `${item.show} blocks` : `${item.show} block`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <Grid
                  my={2}
                  item
                  md={12}
                  sx={{
                    background: themeMode === "dark" ? "#2C2C2C" : "#FFF",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {displayedFrequency?.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      md={2.8}
                      my={1}
                      sx={{
                        border:
                          themeMode === "dark"
                            ? "2px solid #FFF"
                            : "2px solid #030303",
                        borderRadius: "10px",
                        textWrap: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid container mt={4} justifyContent="flex-start">
          <Grid item>
            <PreviewDialog />
            {/* <LoadingButton
              loading={false}
              loadingPosition="start"
              startIcon={<EyeIcon />}
              variant="outlined"
              className={classes.preview}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              Preview
            </LoadingButton> */}
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={handleSave}
              loading={false}
              loadingPosition="start"
              variant="outlined"
              className={classes.save}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
              }}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default CampaignSettings;
