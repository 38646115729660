import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import PlaceIcon from '@mui/icons-material/Place';
import location from "../../../../assets/Career/location.svg";
import clock from "../../../../assets/Career/time.svg";
import right from "../../../../assets/Career/viewMore.svg";

export const ViewJob = ({ category, post, nature, time, isExpanded, onToggle, salary, expirience }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div style={{ position: "relative", marginBottom: "20px" }}>
      <Grid
        className={`${themeMode === 'dark' ? classes.supportCard : ''}`}
        sx={{
          background: "#7d7a7a5E",
          // background:"red",
          padding: '25px',
          borderRadius: "15px",
          boxShadow: '0px 8px 5px 0px #00000040',
          border: themeMode === "dark" ? "" : "2px solid #484B6A",
          color: themeMode === 'dark' ? '#FFFF' : '#030303',
          position: "relative",
          zIndex: 1,
          filter: themeMode === "dark" ? "" : "brightness(50%)",
        }}
      >
        <Grid item md={12} sm={12} xs={12} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, justifyContent: "space-between" }}>
          <Grid item md={10.5} sm={10} xs={12} sx={{ display: { md: "flex", sm: "flex", xs: "flex" }, flexDirection: { md: "column", sm: "column", xs: "column" }, justifyContent: "flex-start" }}>
            <Typography
              sx={{ color: themeMode === "dark" ? "#FFF" : "#000", fontSize: { md: "12px", sm: "12px", xs: "12px" }, fontWeight: "500", fontFamily: "Poppins", textAlign: "left" }}
            >
              {category}
            </Typography>
            <Typography my={1}
              sx={{ fontSize: "18px", fontWeight: "600", textAlign: "left" }}
            >
              {post}
            </Typography>
            <Grid sx={{ display: "flex", flexDirection: "row" }}>
              <Grid sx={{ padding: "10px 10px 10px 0px" }}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "600", textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center" }}
                >

                  <PlaceIcon style={{ marginRight: "10px" }} />
                  {nature}
                </Typography>
              </Grid>
              <Grid sx={{ padding: "10px 10px 10px 0px" }}>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "600", textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center" }}
                >
                  <QueryBuilderIcon style={{ marginRight: "10px" }} />
                  {/* <img src={clock} alt="" style={{ marginRight: "10px" }} /> */}
                  {time}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1.5} sm={2} xs={12}
            sx={{ display: { md: "flex", sm: "flex", xs: "flex" }, justifyContent: {md:"center",sm:"center",xs:"flex-end"}, alignItems: "center" }}>
            <Button onClick={onToggle} disableRipple
            sx={{
              "&:hover": { background: "transparent" },
              color: themeMode === "dark" ? "#FFF" : "#000",
              textTransform: "capitalize", textWrap: "nowrap",
              display: "flex", alignItems: "center",
            }}>
              <Typography>
                View Job
              </Typography>
              {
                isExpanded ?
                  <ArrowDropDownIcon sx={{ color: themeMode === "dark" ? "#618F00" : "#000", marginLeft: "10px", transform: "scale(1.3)" }} />
                  :
                  <ArrowRightIcon sx={{
                    color: themeMode === "dark" ? "#618F00" : "#000", marginLeft: "10px", transform: "scale(1.3)" }}/>
                  }
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          position: "relative",
          width: "100%",
          height: isExpanded ? "fit-content" : "0",
          filter: themeMode === "dark" ? "" : "brightness(25%)",
          background: themeMode === 'dark' ? '#2c0c3b5E' : '#c998f5',
          bottom: "10px",
          borderRadius: '0 0 15px 15px',
          transition: "height 0.3s ease-in-out",
          overflow: "hidden",
          zIndex: 0,
          "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            borderRadius: "15px",
            padding: "1.5px",
            background: themeMode === "dark" ? "linear-gradient(0deg, #9CCA3C, transparent)" : "linear-gradient(0deg, #FFF, transparent)",
            WebkitMask: "linear-gradient(#FFAD08 0 0) content-box, linear-gradient(#ffad08 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",

          },
        }}>
        <Grid sx={{ display: isExpanded ? "flex" : "none", justifyContent: "center", flexDirection: "column", padding: "35px 45px 15px", color: themeMode === "dark" ? "#FFF" : "#000" }}>
          <Typography sx={{fontWeight:"900"}}>Requirements</Typography>
          <ul style={{ listStyleType: "circle",padding: "15px" }}>
            <li>
              <Typography variant="body1" sx={{  }}>
                Salary: {salary}
              </Typography>
            </li>
            <li>
              <Typography variant="body1" sx={{  }}>
                Experience: {expirience}
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};
