import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import './useStyle.css'

export const SimpleForm = ({ label, labelColor, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, shadow }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <span class='input-symbol'>
          <input type='text' class='input2' id="amountInput" />
        </span>
        <button class="proceed"
          style={{ 
            color: labelColor ? labelColor : '#FFF', 
            backgroundColor:  btnColor ? btnColor : '#A4B200', 
            borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '10px', 
            border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '2'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : '2px solid transparent' 
          }}
          id='proceed-btn'
        >
          {label ? label : 'Donate'}
        </button>
      </div>
    </>
  )
}
