import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
  },
}));

const SearchBar = ({ specific, handleSearch }) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [expanded, setExpanded] = useState(window.innerWidth >= 600);
  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  // const handleExpand = () => {
  //   setExpanded(true);
  // };

  // const handleDocumentClick = (event) => {
  //   if (
  //     window.innerWidth < 600 && // Disable for xs screens
  //     searchContainerRef.current &&
  //     !searchContainerRef.current.contains(event.target)
  //   ) {
  //     setExpanded(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleDocumentClick);

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //   };
  // }, [expanded]);

  useEffect(() => {
    if (inputRef.current) {
      const placeholderLength = specific ? specific.length : "Search Here".length;
      inputRef.current.style.width = `${(placeholderLength * 8)+10}px`; // Adjust multiplier as needed
    }
  }, [specific]);

  return (
    <Grid
      sx={{
        borderRadius: "50px",
        "&:hover": {
          // backgroundColor: !expanded  && themeMode === "dark" ? "#2e3322" :
          // !expanded ? "#FFFF":"transparent",
          backgroundColor: "transparent" 
        },
      }}
    >
      <Box
        ref={searchContainerRef}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius:"50px",
          border: expanded ? "1.5px solid #689900" : "none",
          padding: expanded ? "0px 30px" : "0px",
          backgroundColor: "#1C1C1C",
          backgroundColor: "transparent" ,
          width: {
            md: expanded || !specific ? "100%" : "auto",
            sm: expanded || !specific ? "100%" : "auto",
            xs: "100%", 
          },
        }}
        // onClick={handleExpand}
        className={classes.searchInput}
      >
        {expanded && (
          <input
            ref={inputRef}
            type="text"
            placeholder={specific ? specific : "Search Here"}
            style={{
              flex: 1,
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              color:themeMode==="dark"? "#FFFF":"#030303",
              width: "auto",
            }}
            value={query}
            onChange={(e) => {setQuery(e?.target?.value); handleSearch(e?.target?.value)}}
          />
        )}
        <IconButton onClick={(e) => handleSearch(query)}>
          <SearchIcon
            sx={{
              color: "#9CCA3C",
              borderRadius: "50%",
              "&:hover": {
                color: "#689900",
              },
            }}
          />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default SearchBar;
