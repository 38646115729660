const P2PButton =({content, label, btnSize, labelColor, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, shadow})=>{
    console.log(btnSize)
    return(
        <>
        <button 
            style={{
                color: labelColor ? labelColor : '#FFF',  
                backgroundColor: btnColor ? btnColor : 'transparent', 
                fontSize: `${btnSize}px`,
                border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '1'}px solid ${btnBorderColor ? btnBorderColor : '#659500'}` : '1px solid #659500',
                borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '5px',
                padding:"8px 15px"
            }}
            id='proceed-btn'
        >
            {label ? label :  'Start to raise funds'}
        </button>
        </>
    )
}
export default P2PButton