import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  // label: {
  //   color: "white !important",
  // },
  // input: {
  //   color: "#94DA00",
  // },
  // underline: {
  //   "&:before": {
  //     borderBottomColor: "#FFFFFF",
  //   },
  //   "&:hover:not($disabled):before": {
  //     borderBottomColor: "#6EA006",
  //   },
  //   "&:after": {
  //     borderBottomColor: "#94DA00",
  //   },
  // },

  label: {
    color: "#FFFF !important",
  },
  labelLight:{
    color: "#0F0F0F !important",
  },
  placeholder: {
    color: "#C3C3C3 !important"
  },
  input: {
    color: "#C3C3C3",
  },
  inputLight:{
    color:"#0F0F0F"
  },
  underline: {
    "&:before": {
      borderBottomColor: "#FFFFFF",
    },
    "&:hover:not($disabled):before": {
      borderBottomColor: "#6EA006",
    },
    "&:after": {
      borderBottomColor: "#94DA00",
    },
  },
  underlineLight: {
    "&:before": {
      borderBottomColor: "#0F0F0F",
    },
    "&:hover:not($disabled):before": {
      borderBottomColor: "#6EA006",
    },
    "&:after": {
      borderBottomColor: "#94DA00",
    },
  },
  disabled: {},


  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  fw600: {
    fontWeight: "600",
    textWrap: "nowrap",
  },
  centerRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  fullStart: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px",
  },
  modeButton: {
    color: "#FFF",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  disabled: {},
  fitNoWrap: {
    display: "flex",
    flexWrap: "nowrap",
    width: "fit-content",
  },
  fitNoWrapCenter: {
    alignItems: "center",
    display: "flex",
    textWrap: "nowrap",
    width: "fit-content",
  },

  fullInput: {
    //backgroundColor: '#0D0D0D',
    borderRadius: '10px',
    width: '50%',
    
    '& .MuiOutlinedInput-input': {
      //color: '#FFFFFF',
      padding:"30px 20px",
      // '&::placeholder': {
      //   color: '#FFFFFF'
      // }
    },
    '& .MuiInputLabel-root': {
      color: '#FFFFFF',
      fontSize: '12px',
      marginBottom: '15px',
      transform: 'translate(0px, -40px) scale(1.25)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0px, -40px) scale(1.25)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      //borderColor: '#ffff',
      borderWidth: '0.5px',
      borderRadius: '15px'
    },
    
    '&.Mui-active .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },

    outline: 'none'
  },
  insert: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center"
  },
  uploadButton: {
    background: "transparent",
    borderRadius: "26px",
    padding: "10px 15px",
    boxShadow:"none",
    height:"fit-content",
    "&:hover": {
      background: "transparent",
      boxShadow:"none",
    },
  },
  upload: {
    display: "flex",
    
    padding: "10px 15px",
    borderRadius: "50%",
    width: "74px",
    height:"74px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:hover": {
      filter: "grayscale(0)",
    },
  },
  buttonText: {
    fontSize: "16px",
    fontWeight: "500",
    
    fontFamily:"Poppins",
    textTransform: "capitalize",
    
  },
  
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
}));
