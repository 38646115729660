import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { adjustNegativeMonths } from "../../../../../utils/globalFunctions.js";
import { GET_CAMPAIGN_LIST } from "../../Insights/Query";
import { methods } from "../methods";
import __ from "lodash";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CREATE_WITHDRAWL_REQUEST,
  GET_PAYMENT_METHODS,
} from "../Setup-Withdrawl/Query.jsx";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";

const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const exclaim = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/exclaim.svg";
const back = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  amount: "",
};

const schema = yup
  .object()
  .shape({
    amount: yup.string().required(),
  })
  .required();

const WithdrawDonation = () => {
  const [withdrawlMethod, setWithdrawlMethod] = useState(false);
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [anchorElCampaign, setAnchorElCampaign] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [selectedCampaignUuid, setSelectedCampaignUuid] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);

  const { handleSubmit, control, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [
    CreateWithdrawlRequest,
    {
      loading: CreateWithdrawlRequestLoading,
      error: CreateWithdrawlRequestError,
    },
  ] = useMutation(CREATE_WITHDRAWL_REQUEST, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("Create Withdrawl request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("Create Withdrawl request was successful!"));
    },
  });

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const { data: payment } = useQuery(GET_PAYMENT_METHODS, {
    onCompleted: (result) => {
      if (result.paymentMethods.nodes.length) {
        setPaymentMethods(result.paymentMethods.nodes);
      }
      setWithdrawlMethod(true);
    },
  });

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: { md: "40px", sm: "40px", xs: "20px" },
      }}
    >
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Grid sx={{ display: "flex", width: "fit-content" }}>
          <Button
            href="/dashboard/payments"
            disableRipple
            sx={{
              "&:hover": {
                background: "transparent",
                transform: "rotate(315deg)",
              },
            }}
          >
            <img src={themeMode === "dark" ? back : backLight} alt="" />
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "30px", xs: "26px" },
            fontWeight: "800",
            textAlign: { md: "left", sm: "left", xs: "center" },
            color: themeMode === "dark" ? "#FFFF" : "#030303",
            textWrap: "nowrap",
          }}
        >
          Withdraw Donation
        </Typography>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          height: "fit-content",
          justifyContent: "space-between",
          flexWrap: "wrap",
          justifyContent: { md: "left", sm: "left", xs: "center" },
        }}
      >
        <Button
          disableRipple
          sx={{
            padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
            color: "#FFF",
            background: "transparent",
            border: "1px solid #689900",
            borderRadius: "30px",
            textTransform: "capitalize",
            fontWeight: "600",
            flexWrap: "nowrap",
            margin: "10px 10px 10px 0px",
            width: "fit-content",
            "&:hover": {
              backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
            },
          }}
          onClick={(e) => {
            setAnchorElCampaign(e.currentTarget);
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                fontSize: { md: "14px", sm: "12px", xs: "10px" },
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              All Campaign
              <img src={down} alt="" style={{ marginLeft: "10px" }} />
            </Typography>
          </Grid>
        </Button>
        <Menu
          anchorEl={anchorElCampaign}
          open={Boolean(anchorElCampaign)}
          onClose={(e) => {
            setAnchorElCampaign(null);
          }}
        >
          {campaignList?.map((option) => (
            <MenuItem
              key={option.uuid}
              value={option.uuid}
              onClick={() => {
                setSelectedCampaignUuid(option.uuid);
                setAnchorElCampaign(null);
              }}
              selected={option.uuid === selectedCampaignUuid}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        my={2}
        sx={{
          position: "relative",
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#FFFFFF",
          borderRadius: "15px",
          padding: "20px",
        }}
      >
        <Typography
          sx={{
            color: themeMode === "dark" ? "#FFFF" : "#030303",
            fontSize: "16px",
            fontWeight: "900",
            fontFamily: "Poppins",
            textAlign: { md: "left", sm: "left", xs: "center" },
          }}
        >
          Available Balance
        </Typography>
        <Typography
          sx={{
            color: themeMode === "dark" ? "#689900" : "#00F00A",
            fontSize: "30px",
            fontWeight: "600",
            textAlign: { md: "left", sm: "left", xs: "center" },
          }}
        >
          $10000
        </Typography>
        <Grid
          item
          md={12}
          my={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {withdrawlMethod ? (
            <Grid
              item
              md={11}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "20px 30px",
                background: themeMode === "dark" ? "#1c1c1c" : "#F9F9F9",
                borderRadius: "15px",
              }}
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Please enter the amount you want to withdraw
              </Typography>
              <form
                className="w-full"
                onSubmit={handleSubmit((_data) => {
                  const variables = {
                    paymentMethodUuid: paymentMethods?.[0]?.uuid,
                    amount: parseFloat(_data.amount),
                  };
                  try {
                    const result = CreateWithdrawlRequest({ variables });
                  } catch (error) {
                    console.error(error);
                  }
                })}
              >
                <div className="md:flex justify-between items-start md:space-x-8">
                  <div className="w-full">
                    <Typography
                      className="sub-sub-heading-withdrawl"
                      sx={{
                        color: themeMode === "dark" ? "#FFFF" : "#000000",
                        fontSize: { md: "18px", sm: "18px", xs: "15px" },
                        marginTop: "24px",
                        marginBottom: "8px",
                      }}
                    >
                      Amount{" "}
                    </Typography>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          error={!!errors.amount}
                          helperText={errors?.amount?.message}
                          // required
                          fullWidth
                          size="small"
                          type="number"
                          className="md:mb-4 md:w-1/2"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#689900",
                              },
                            },
                          }}
                        />
                      )}
                      name="amount"
                      control={control}
                    />
                  </div>{" "}
                </div>
                <Grid
                  my={4}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  <Button
                    type="submit"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    sx={{
                      background:
                        "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                      border: "2px solid transparent",
                      borderRadius: "30px",
                      padding: "10px 30px",
                      height: "fit-content",
                      "&:hover": {
                        background:
                          "linear-gradient(183.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFF",
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "10px" },
                        fontWeight: "600",
                        textWrap: "nowrap",
                      }}
                    >
                      Withdraw Amount
                    </Typography>
                  </Button>
                </Grid>
              </form>
            </Grid>
          ) : (
            <Grid
              item
              md={11}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "20px 30px",
                background: themeMode === "dark" ? "#1c1c1c" : "#F9F9F9",
                borderRadius: "15px",
              }}
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                To withdraw earnings, first you need to set up a withdrawal
                method.
              </Typography>
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                It may take up to 3 days to activate your withdrawal method.
              </Typography>
              <Grid
                my={4}
                sx={{
                  display: "flex",
                  justifyContent: { md: "left", sm: "left", xs: "center" },
                }}
              >
                <Button
                  href="/dashboard/withdraw-donation"
                  sx={{
                    background:
                      "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                    border: "2px solid transparent",
                    borderRadius: "30px",
                    padding: "10px 30px",
                    height: "fit-content",
                    "&:hover": {
                      background:
                        "linear-gradient(183.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFF",
                      textTransform: "capitalize",
                      fontSize: { md: "14px", sm: "12px", xs: "10px" },
                      fontWeight: "600",
                      textWrap: "nowrap",
                    }}
                  >
                    Add a method
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          justifyContent: "space-between",
          height: "fit-content",
        }}
      >
        <Grid
          item
          md={5.9}
          sm={5.9}
          xs={12}
          mt={2}
          sx={{
            position: "relative",
            backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#FFFFFF",
            borderRadius: "15px",
            padding: "20px",
            height: "fit-content",
          }}
        >
          <Typography
            sx={{
              color: themeMode === "dark" ? "#FFFF" : "#030303",
              fontSize: "16px",
              fontWeight: "900",
              fontFamily: "Poppins",
            }}
          >
            Withdrawal Methods
          </Typography>
          <Typography
            my={2}
            sx={{
              color: themeMode === "dark" ? "#242424" : "#0000001F",
              fontSize: { md: "40px", sm: "26px", xs: "18px" },
              textWrap: "nowrap",
              fontWeight: "700",
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            No data available
          </Typography>

          <Typography
            sx={{
              color: themeMode === "dark" ? "#FFFF" : "#000000",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            You haven’t set up any method yet.
          </Typography>
        </Grid>

        <Grid
          item
          md={5.9}
          sm={5.9}
          xs={12}
          my={2}
          sx={{
            position: "relative",
            backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#FFFFFF",
            borderRadius: "15px",
            padding: "20px",
            height: "fit-content",
          }}
        >
          <Typography
            sx={{
              color: themeMode === "dark" ? "#FFFF" : "#030303",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Last Withdrawal
          </Typography>
          <Typography
            my={2}
            sx={{
              color: themeMode === "dark" ? "#242424" : "#0000001F",
              fontSize: { md: "40px", sm: "26px", xs: "18px" },
              textWrap: "nowrap",
              fontWeight: "700",
              textAlign: "center",
              fontFamily: "Poppins",
            }}
          >
            No data available
          </Typography>
          <Typography
            sx={{
              color: themeMode === "dark" ? "#FFFF" : "#000000",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            You haven’t made any withdrawals yet.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default WithdrawDonation;
