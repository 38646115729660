import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import SearchBar from "../SearchBar";
import { tableDonation, donationRow } from "../data";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_DONATIONS, CREATE_EXPORT } from "./Query";
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import {
  adjustNegativeMonths,
  downloadFile,
} from "../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useStyles } from "../Campaign/useStyles.jsx";
import { DatePickerComp } from "../DatePickerComp.jsx";
import DocumentTitle from "../../../../DocumentTitle.js";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { GET_STATS } from "../Campaign/Query.jsx";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downwhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const date =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";

const Donation = () => {
  DocumentTitle("Donations - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filterDonations, setFilterDonations] = useState({
    filterBy: null,
  });
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [sortDonations, setSortDonations] = useState({
    name: "AMOUNT",
    order: "DESCENDING",
  });
  const [donationRows, setDonationRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [pageStats, setPageStats] = useState([]);

  const { data: pagesStats } = useQuery(GET_STATS, {
    onCompleted: (result) => {
      if (result?.pageStats) {
        setPageStats(result?.pageStats?.donationPage);
      }
    },
  });

  const [CreateExport, { loading: exportLoading, error: exportError }] =
    useMutation(CREATE_EXPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("export request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("export request was successful!"));
      },
    });

  const updateDonationsRowState = (result) => {
    setDonationRows(result?.donations?.nodes);
    const pageInfo = result?.donations?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const {
    data: DonationData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_ALL_DONATIONS, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      filterBy: filterDonations?.filterBy,
      sortColumn: sortDonations?.name,
      sortOrder: sortDonations?.order,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      updateDonationsRowState(result);
    },
  });

  useEffect(() => {
    refetch({
      variables: {
        filterBy: filterDonations?.filterBy,
      },
    }).then((result) => {
      updateDonationsRowState(result?.data);
    });
  }, [filterDonations.filterBy, refetch]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setDonationRows([...donationRows, ...result?.data?.donations?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor: result?.data?.donations?.pageInfo?.startCursor,
          endCursor: result?.data?.donations?.pageInfo?.endCursor,
          hasNextPage: result?.data?.donations?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleExport = async () => {
    const variables = {
      pageName: "Donation",
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateExport({ variables });
      if (result?.data?.createExport?.export?.success) {
        downloadFile(result?.data?.createExport?.export?.fileUrl);
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              fontWeight: "600",
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Donations
          </Typography>
        </Grid>

        <Grid
          item
          className={classes.center}
          sx={{
            justifyContent: {
              md: "space-between",
              sm: "flex-start",
              xs: "space-between",
            },
            alignItems: { md: "center", sm: "center", xs: "center" },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            xs={10}
            className={classes.flexStart}
            sx={{
              width: {
                md: "fit-content",
                sm: "fit-content",
                xs: "fit-content",
              },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              flexWrap: "wrap",
              justifyContent: {
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
            }}
          >
            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElDateFilter(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{
                      color: "#9CCA3C",
                      marginRight: { md: "10px", sm: "10px", xs: "5px" },
                    }}
                  />
                  {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                  {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElDateFilter}
              open={Boolean(anchorElDateFilter)}
              onClose={(e) => {
                setAnchorElDateFilter(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  background: themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                  width: "fit-content",
                  border: "2px solid #ABFC00",
                  borderRadius: "40px",
                },
              }}
            >
              <MenuItem>
                <Grid container spacing={2} alignItems="center">
                  {/* <Grid item xs={6} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dayjs(dateRange?.startDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            startDate: date,
                          }))
                        }
                        maxDate={dateRange?.endDate ? dayjs(dateRange?.endDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <span>-</span>
                  </Grid>
                  <Grid item xs={5} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dayjs(dateRange?.endDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            endDate: date,
                          }))
                        }
                        minDate={dateRange?.startDate ? dayjs(dateRange?.startDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                  <Grid item md={2} xs={12}>
                    <DatePickerComp
                      startDate={dateRange?.startDate}
                      endDate={dateRange?.endDate}
                      displayDate={setDateRange}
                    />{" "}
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>
            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElFilter(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Filters
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElFilter}
              open={Boolean(anchorElFilter)}
              onClose={(e) => {
                setAnchorElFilter(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                  border: "1px solid #689900",
                  borderRadius: "10px",
                },
              }}
            >
              <Paper
                style={{
                  maxHeight: 350,
                  maxWidth: 250,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor:
                    themeMode === "dark"
                      ? "#8ED003CC #0F0F0F"
                      : "#8ED003 #E2E2E2",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterDonations({ filterBy: null });
                  }}
                  selected={filterDonations?.filterBy === null}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterDonations({ filterBy: "one_time" });
                  }}
                  selected={filterDonations?.filterBy === "one_time"}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Once
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterDonations({ filterBy: "recurring" });
                  }}
                  selected={filterDonations?.filterBy === "recurring"}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Recurring
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterDonations({ filterBy: "covered" });
                  }}
                  selected={filterDonations?.filterBy === "covered"}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Covered
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterDonations({ filterBy: "uncovered" });
                  }}
                  selected={filterDonations?.filterBy === "uncovered"}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Uncovered
                </MenuItem>
              </Paper>
            </Menu>
            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElSort(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Sort
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElSort}
              open={Boolean(anchorElSort)}
              onClose={(e) => {
                setAnchorElSort(null);
              }}
              PaperComponent={Paper}
              sx={{
                "& .MuiPaper-root": {
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                  border: "1px solid #689900",
                  borderRadius: "10px",
                },
              }}
            >
              <Paper
                style={{
                  maxHeight: 350,
                  maxWidth: 250,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor:
                    themeMode === "dark"
                      ? "#8ED003CC #0F0F0F"
                      : "#8ED003 #E2E2E2",
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "AMOUNT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "AMOUNT" &&
                    sortDonations?.order === "ASCENDING"
                  }
                >
                  Amount Low to High
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "AMOUNT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "AMOUNT" &&
                    sortDonations?.order === "DESCENDING"
                  }
                >
                  Amount High to Low
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "CREATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "CREATED_AT" &&
                    sortDonations?.order === "ASCENDING"
                  }
                >
                  Oldest Created
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "CREATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "CREATED_AT" &&
                    sortDonations?.order === "DESCENDING"
                  }
                >
                  Recently Created
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "UPDATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "UPDATED_AT" &&
                    sortDonations?.order === "ASCENDING"
                  }
                >
                  Oldest Updated
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortDonations({
                      name: "UPDATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortDonations?.name === "UPDATED_AT" &&
                    sortDonations?.order === "DESCENDING"
                  }
                >
                  Recently Updated
                </MenuItem>
              </Paper>
            </Menu>
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
          >
            <SearchBar
              specific="Search Donation by Supporters/Campaigns"
              handleSearch={handleSearch}
            />
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{ display: { md: "none", sm: "flex", xs: "none" } }}
          >
            <SearchBar
              specific="Search Donation "
              handleSearch={handleSearch}
            />
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{
              display: { md: "none", sm: "none", xs: "flex" },
              width: "100%",
              justifyContent: "center",
            }}
          >
            <SearchBar specific="Search Donation" handleSearch={handleSearch} />
          </Box>
        </Grid>

        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            md={10}
            sm={9}
            xs={12}
            my={{ md: 3, sm: 3, xs: 1 }}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
              alignItems: "center",
            }}
          >
            <Grid
              mr={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              item
              sx={{
                width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                padding: { md: "25px", sm: "25px 15px", xs: "10px 15px" },
                border: "2px solid #9CCA3C",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item sx={{ width: "fit-content" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Collection for this month
                </Typography>

                <Grid
                  mt={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mr={1}
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    $ {pageStats?.this_month_collection}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      flexWrap: "nowrap",
                      color: "#70B500",
                      whiteSpace: "nowrap",
                    }}
                  >
                    donation in USD
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              ml={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              item
              sx={{
                border: "2px solid #9CCA3C",
                borderRadius: "15px",
                padding: { md: "25px", sm: "25px 15px", xs: "10px 15px" },
                width: "45%",
                width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",

                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item sx={{ width: "fit-content" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Trending campaign this month
                </Typography>

                <Grid
                  mt={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    mr={1}
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {pageStats?.trending_campaign}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      flexWrap: "nowrap",
                      color: "#70B500",
                      whiteSpace: "nowrap",
                    }}
                  >
                    collected ${pageStats?.trending_campaign_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={2}
            sm={3}
            xs={12}
            my={3}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disableRipple
              className={classes.clrButton}
              onClick={() => {
                handleExport();
              }}
              sx={{
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 10px",
                },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid container className={classes.nowrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",

                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: "16px",

                    width: "100%",
                  }}
                >
                  Export List
                  <img
                    src={themeMode === "dark" ? download : downloadLight}
                    alt=""
                    style={{ marginLeft: "10px" }}
                  />
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TableContainer
            id="table-container"
            sx={{
              zIndex: "0",
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              padding: "20px",
              paddingTop: "0px",
              borderRadius: "15px",
              maxHeight: "350px",
              overflowY: "auto",
            }}
          >
            {/* <Button
              disableRipple
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              onClick={(e) => {
                setAnchorElSort(e.currentTarget);
              }}
            >
              <Typography
                mr={1}
                sx={{
                  color: themeMode === "dark" ? "#A19A9A" : "#8D8D8D",
                  textTransform: "capitalize",
                  fontSize: "12px",
                }}
              >
                Sort By
                <img
                  src={themeMode === "dark" ? downgrey : downgreyLight}
                  alt=""
                  style={{ margin: "0px 0px 0px 10px" }}
                />
              </Typography>
            </Button>
            <Menu
              anchorEl={anchorElSort}
              open={Boolean(anchorElSort)}
              onClose={(e) => {
                setAnchorElSort(null);
              }}
              PaperComponent={Paper}
              sx={{
                "& .MuiPaper-root": {
                  color: "#FFF",
                  backgroundColor: "#222",
                  border: "1px solid #689900",
                },
              }}
            >
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "AMOUNT",
                    order: "ASCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "AMOUNT" &&
                  sortDonations?.order === "ASCENDING"
                }
              >
                Amount Low to High
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "AMOUNT",
                    order: "DESCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "AMOUNT" &&
                  sortDonations?.order === "DESCENDING"
                }
              >
                Amount High to Low
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "CREATED_AT",
                    order: "ASCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "CREATED_AT" &&
                  sortDonations?.order === "ASCENDING"
                }
              >
                Oldest Created
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "CREATED_AT",
                    order: "DESCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "CREATED_AT" &&
                  sortDonations?.order === "DESCENDING"
                }
              >
                Recently Created
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "UPDATED_AT",
                    order: "ASCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "UPDATED_AT" &&
                  sortDonations?.order === "ASCENDING"
                }
              >
                Oldest Updated
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setAnchorElSort(null);
                  setSortDonations({
                    name: "UPDATED_AT",
                    order: "DESCENDING",
                  });
                }}
                selected={
                  sortDonations?.name === "UPDATED_AT" &&
                  sortDonations?.order === "DESCENDING"
                }
              >
                Recently Updated
              </MenuItem>
            </Menu> */}
            <Table
              sx={{
                width: "100%",
                color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
              }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  {tableDonation?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        backgroundColor:
                          themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0", // Apply bottom left radius to the first item
                        borderBottomRightRadius:
                          index === tableDonation.length - 1 ? "15px" : "0", // Apply bottom right radius to the last item
                        color:
                          index === tableDonation.length - 1 &&
                          themeMode === "dark"
                            ? "#FFFF"
                            : "#030303",
                        borderRight:
                          index === tableDonation.length - 1
                            ? "0px"
                            : undefined,
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          fontSize: "14px",
                          fontWeight: "700",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {donationRows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                    >
                      <Typography variant="h6">No Records</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  donationRows?.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      component={Link}
                      to={`/dashboard/donation/${row.uuid}`}
                      sx={{
                        "&:last-child td, &:last-child th": { borderBottom: 0 },
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                        textDecoration: "none",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "12%",
                          ...(index === tableDonation.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        {/* <img src={row.img} alt="" style={{width:"17px",height:"25px",marginRight:"20px"}}/> */}
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "30px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {dayjs(row.createdAt)?.format("YYYY-MM-DD")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        md={2}
                        sx={{
                          width: "10%",
                          ...(index === tableDonation.length - 1 && {
                            borderRight: "0px",
                            color: "#8ED003",
                          }),
                        }}
                      >
                        <Typography
                          sx={{ color: "#8ED003", textWrap: "nowrap" }}
                        >
                          {`${getSymbolFromCurrency(row.currency)} ${
                            row.amount
                          } ${row.currency}`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tableDonation.length - 1 && {
                            borderRight: "0px",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            color:
                              row.feeCovered == "Covered"
                                ? "#8ED003"
                                : "#FF0000",
                          }}
                        >
                          {row.feeCover ? "Covered" : "Uncovered"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tableDonation.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.donor?.firstName}&nbsp;{row.donor?.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tableDonation.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {/* {row.campaign?.title} */}
                          {row.campaign?.title?.length > 18
                            ? `${row.campaign?.title?.substring(0, 18)}...`
                            : row.campaign?.title}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default Donation;
