import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { searchArticles } from "../../../../store/helpSlice";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
  },
}));

const SearchBarHelp = ({ specific, handleSearch }) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  //const [expanded, setExpanded] = useState(window.innerWidth <= 600);
  const searchContainerRef = useRef(null);
  const dispatch = useDispatch();

  // const handleExpand = () => {
  //   setExpanded(true);
  // };

  // const handleDocumentClick = (event) => {
  //   if (
  //     window.innerWidth > 600 && // Disable for xs screens
  //     searchContainerRef.current &&
  //     !searchContainerRef.current.contains(event.target)
  //   ) {
  //     setExpanded(true);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleDocumentClick);

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //   };
  // }, [expanded]);

  return (
    <Grid
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box
        ref={searchContainerRef}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: "50px",
          border: "1.5px solid #689900",
          padding: "0px 30px",
          backgroundColor: "#1C1C1C",
          width: {
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
        }}
        className={classes.searchInput}
      >
        <input
          type="text"
          placeholder={specific ? specific : "Search Here"}
          style={{
            flex: 1,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            color: "#FFF",
          }}
          // value={query}
          // onChange={(e) => setQuery(e?.target?.value)}
          onChange={(e) => dispatch(searchArticles(e.target.value))}
        />

        <IconButton onClick={(e) => handleSearch(query)}>
          <SearchIcon
            sx={{
              color: "#9CCA3C",
              borderRadius: "50%",
              "&:hover": {
                color: "#689900",
              },
            }}
          />
        </IconButton>
      </Box>
    </Grid>
  );
};

export default SearchBarHelp;
