import React from "react";
import { Grid } from "@mui/material";
import { SupportersManagement } from "./List/SupportersManagement";
import CampaignManagement from "./List/CampaignManagement";
import DonationManagement from "./List/DonationManagement";
import PaymentEcosystem from "./List/PaymentEcosystem";
import ReportingManagement from "./List/ReportingManagement";
import ExportsManagement from "./List/ExportsManagement";
import P2PCrowdsource from "./List/P2P";
import ElementsManagement from "./List/ElementsManagement";
import RecurringDonations from "./List/RecurringDonation";
import Support24_7 from "./List/Support24_7";
import { useSelector } from "react-redux";

const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/ftrList.svg"

const Trait = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid 
      sx={{
        backgroundColor: themeMode==="dark"?"#000a0b":"",
        backgroundImage: themeMode==="light"?`url('${light}')`:"",
      }}
    >
      <CampaignManagement />
      <SupportersManagement />
      <DonationManagement />
      <PaymentEcosystem />
      <ReportingManagement />
      <ElementsManagement />
      <ExportsManagement />
      <RecurringDonations />
      <P2PCrowdsource />
      <Support24_7 />
    </Grid>
  );
};
export default Trait;
