import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  flex: {
    display: "flex",
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },

  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    
    fontWeight: "500",
    textTransform: "capitalize",
    textWrap: "nowrap",
  },

  bottom: {
    display: "flex",
    alignItems: "flex-end",
  },
  neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  fw600: {
    fontWeight: "600",
    textWrap: "nowrap",
    
  },

  divider: {
    display: "flex",
    width: "100%",
    
    padding: "10px",
    flexWrap: "wrap",
  },

  fs12: {
    fontSize: "12px",
    fontWeight: "500",
    textWrap: "nowrap",
  },
  fs10: {
    fontSize: "11.5px",
    fontWeight: "400",
    textWrap: "wrap",
    width:"100%",textAlign:"left"

  },
  fs12w600: {
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "right",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "700",
    textWrap: "nowrap",
    textAlign:"left",
    width:"100%"
  },
  neon: {
    fontWeight: "600",
    
    textWrap: "nowrap",
  },

  refund: {
    background: "transparent",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    width: "fit-content",
    textWrap: "nowrap",
  },
  download: {
    color: "#FFF",
    background: "linear-gradient(90deg, #5D8900 3.35%, #95DC00 96.95%)",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 0px 10px 5px",
    width: "fit-content",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: "#2e3322",
      border: "1px solid #689900",
    },
  },
  rowEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fit: {
    
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    borderRadius: "15px",
    overflow: "hidden",
    height: "100%",
    "&::before": {
        content: "''",
        position: "absolute",
        inset: 0,
        borderRadius: "15px",
        padding: "1px",
        background: "linear-gradient(125deg, white, transparent)",
        WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
    },
  },
  mapped: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  comments: {
    display: "flex",
    
    borderRadius: "5px",
    padding: "5px",
  },
}));
