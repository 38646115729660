import { addDays } from "date-fns";
import { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
//import "./dateStyle.css"

export const DatePickerComp = ({ startDate, endDate, displayDate }) => {
  const [isMobile, setIsMobile] = useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // Set the threshold according to your preference
    };

    handleResize(); // Call it initially to set the state
    window.addEventListener("resize", handleResize); // Listen for resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the listener on unmount
    };
  }, []);

  const [state, setState] = useState([
    {
      //   startDate: new Date(),
      //   endDate: addDays(new Date(), 7),
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  return (
    <DateRangePicker
      onChange={(item) => {
        setState([item.selection]);
        displayDate((prevObj) => ({
          ...prevObj,
          startDate: item.selection?.startDate,
          endDate: item.selection?.endDate,
        }));

        // displayDate(item.selection?.startDate);
      }}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction={isMobile ? "vertical" : "horizontal"}
      inputRanges={[]}
      rangeColors={["#6EA006"]}
    />
  );
};

// style = {{
//   backgroundColor: themeMode === "dark" ? "#030303" : '#FFF',
//     rdrStaticRangeLabel: {
//     backgroundColor: "transparent",
//       color: themeMode === "dark" ? "#FFF" : "#0303003"
//   },
//   rdrDateDisplay: {
//     rdrDateInputrdrDayNumber: {
//       span: {
//         color: themeMode === "dark" ? "red" : "#0303003"
//       }
//     }
//   },
//   rdrDateDisplayWrapper: {
//     backgroundColor: themeMode === "dark" ? "#030303" : '#FFF',
//         },
//   rdrCalendarWrapper: {
//     rdrMonth: {
//       backgroundColor: "transparent",
//           }
//   }
// }}
