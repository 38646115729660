import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { useSelector } from "react-redux";
import "./useStyle.css"

const crossDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/cross.svg'
const cross = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg'

const MesasgeBar = ({ title, content, textColor, backgroundColor, btnPositionVert, show_close_button, shadow, label, labelColor, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, themeMode })=>{

    return(
        <div 
            style={{
                position:"absolute",
                //bottom:"0px",
                top:"0px",
                width:"100%",
                padding:"10px",
                background: backgroundColor ? backgroundColor : themeMode==="dark"?"#000":"#FFF",
                borderRadius:"10px",
                // boxShadow: themeMode === "dark" && shadow ? '2px 2px 5px #FFF' :
                //             themeMode === "light" && shadow ? '2px 2px 10px #000'
                //         : 'none',
            }}>
            <div style={{display:"flex",flexDirection: "row",justifyContent:"space-between"}}>
                <p style={{fontWeight:"800", color: textColor ? textColor : '#FFF' }}>
                    {title ? title : 'Make a difference today!'}
                </p>
                {show_close_button && 
                    <button id="closed-btn" >
                        <img src={themeMode === "dark" ? cross : crossDark} alt='' />
                    </button>
                }
            </div>
            <div style={{textAlign:"left",marginTop:"15px",fontSize:"14px",width:"85%", color: textColor ? textColor : '#FFF'}}>
                <p>
                    {content ? content : "Your contribution today can improve the lives of so many. Please join us in making a difference."}
                </p>
            </div>
            <div>
                <button class="proceed" 
                    id="proceed-btn" 
                    style={{ 
                        color: labelColor ? labelColor : '#FFF', 
                        backgroundColor:  btnColor ? btnColor : '#A4B200', 
                        borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '10px', 
                        border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '2'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : '2px solid transparent' 
                    }}
                >
                    {label ? label : 'Donate'}
                </button>
            </div>
        </div>

    )
}
export default MesasgeBar;