import axios from 'axios';

const API_URL = "https://api.accceleraise.com";



const jwtInterceptor = axios.create();

jwtInterceptor.interceptors.request.use(
    request => {
        const isApiUrl = request.url.startsWith(API_URL);
        // Get the JWT from local storage
        const user = JSON.parse(localStorage.getItem('user'));
        const jwt = user['access-token'];

        if (jwt && isApiUrl) {
            // Add the JWT to the Authorization header
            //request.headers.Authorization = `Bearer ${jwt}`;
            request.headers['Access-Token'] = user['access-token'];
            request.headers['Client'] = user['client'];
            request.headers['Uid'] = user['uid'];
        }

        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

export default jwtInterceptor;