import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { SupportCard } from "./SupportCard";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import support from '../../../../../../assets/FeatureLightTheme/Support247/support.svg'
import cone from '../../../../../../assets/FeatureLightTheme/Support247/cones.svg'


const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const element1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element1.svg";
const element2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/element2.svg";
const support24_7 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/support24_7.svg";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/support24_7.svg";
const product_training = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/product_training.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_242 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_242.svg";
const light243 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light243.svg";

const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);

 
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E3E4F0" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize: themeMode === "dark" ? "140% auto" : "100% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "left 50%,60% bottom",
            padding: { md: "100px 130px", sm: "90px 40px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <SupportCard
            icon={themeMode==="dark"?support24_7:support}
            title={"24/7 Support"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
          <SupportCard
            icon={themeMode==="dark"?product_training:cone}
            title={"product TRAINing"}
            subtitle={
              "Bid farewell to onboarding charges and hourly support expenses. Access assistance without any financial burden, provided by individuals familiar with your needs. Exceptional support is not an additional cost but an inherent standard."
            }
          />
         
        </Grid>
      </Grid>
    );
 
};
export default SubSection;
