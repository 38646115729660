const og = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/og.png";

export const allClients = [
  { id: 1, client: og},
  { id: 2, client: og },
  { id: 3, client: og },
  { id: 4, client: og },
  { id: 5, client: og },
  { id: 6, client: og },
];
