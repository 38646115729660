

export function capitalizeFirstLetter(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function capitalizeString(str) {
    return str?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');
}

export const currencies = [
    { id: 1, value: "AED", label: "د.إ" }, // United Arab Emirates Dirham
    { id: 2, value: "AFN", label: "؋" },   // Afghan Afghani
    { id: 3, value: "ALL", label: "L" },    // Albanian Lek
    { id: 4, value: "AMD", label: "֏" },   // Armenian Dram
    { id: 5, value: "ANG", label: "ƒ" },   // Netherlands Antillean Guilder
    { id: 6, value: "AOA", label: "Kz" },  // Angolan Kwanza
    { id: 7, value: "ARS", label: "$" },   // Argentine Peso
    { id: 8, value: "AUD", label: "$" },   // Australian Dollar
    { id: 9, value: "AWG", label: "ƒ" },   // Aruban Florin
    { id: 10, value: "AZN", label: "₼" },  // Azerbaijani Manat
    { id: 11, value: "BAM", label: "КМ" }, // Bosnia-Herzegovina Convertible Mark
    { id: 12, value: "BBD", label: "$" },  // Barbadian Dollar
    { id: 13, value: "BDT", label: "৳" }, // Bangladeshi Taka
    { id: 14, value: "BGN", label: "лв" }, // Bulgarian Lev
    { id: 15, value: "BHD", label: ".د.ب" }, // Bahraini Dinar
    { id: 16, value: "BIF", label: "FBu" }, // Burundian Franc
    { id: 17, value: "BMD", label: "$" }, // Bermudian Dollar
    { id: 18, value: "BND", label: "$" }, // Brunei Dollar
    { id: 19, value: "BOB", label: "Bs" }, // Bolivian Boliviano
    { id: 20, value: "BRL", label: "R$" }, // Brazilian Real
    { id: 21, value: "BSD", label: "$" }, // Bahamian Dollar
    { id: 22, value: "BTN", label: "Nu." }, // Bhutanese Ngultrum
    { id: 23, value: "BWP", label: "P" }, // Botswana Pula
    { id: 24, value: "BYN", label: "Br" }, // Belarusian Ruble
    { id: 25, value: "BZD", label: "BZ$" }, // Belize Dollar
    { id: 26, value: "CAD", label: "$" }, // Canadian Dollar
    { id: 27, value: "CDF", label: "FC" }, // Congolese Franc
    { id: 28, value: "CHF", label: "CHF" }, // Swiss Franc
    { id: 29, value: "CLP", label: "$" }, // Chilean Peso
    { id: 30, value: "CNY", label: "¥" }, // Chinese Yuan
    { id: 31, value: "COP", label: "$" }, // Colombian Peso
    { id: 32, value: "CRC", label: "₡" }, // Costa Rican Colón
    { id: 33, value: "CUP", label: "$" }, // Cuban Peso
    { id: 34, value: "CVE", label: "$" }, // Cape Verdean Escudo
    { id: 35, value: "CZK", label: "Kč" }, // Czech Koruna
    { id: 36, value: "DJF", label: "Fdj" }, // Djiboutian Franc
    { id: 37, value: "DKK", label: "kr" }, // Danish Krone
    { id: 38, value: "DOP", label: "$" }, // Dominican Peso
    { id: 39, value: "DZD", label: "دج" }, // Algerian Dinar
    { id: 40, value: "EGP", label: "£" }, // Egyptian Pound
    { id: 41, value: "ERN", label: "Nfk" }, // Eritrean Nakfa
    { id: 42, value: "ETB", label: "Br" }, // Ethiopian Birr
    { id: 43, value: "EUR", label: "€" }, // Euro
    { id: 44, value: "FJD", label: "$" }, // Fijian Dollar
    { id: 45, value: "FKP", label: "£" }, // Falkland Islands Pound
    { id: 46, value: "FOK", label: "£" }, // Guernsey Pound
    { id: 47, value: "GBP", label: "£" }, // British Pound Sterling
    { id: 48, value: "GEL", label: "ლ" }, // Georgian Lari
    { id: 49, value: "GGP", label: "£" }, // Guernsey Pound
    { id: 50, value: "GHS", label: "₵" }, // Ghanaian Cedi
    { id: 51, value: "GIP", label: "£" }, // Gibraltar Pound
    { id: 52, value: "GMD", label: "D" }, // Gambian Dalasi
    { id: 53, value: "GNF", label: "FG" }, // Guinean Franc
    { id: 54, value: "GTQ", label: "Q" }, // Guatemalan Quetzal
    { id: 55, value: "GYD", label: "$" }, // Guyanaese Dollar
    { id: 56, value: "HKD", label: "$" }, // Hong Kong Dollar
    { id: 57, value: "HNL", label: "L" }, // Honduran Lempira
    { id: 58, value: "HRK", label: "kn" }, // Croatian Kuna
    { id: 59, value: "HTG", label: "G" }, // Haitian Gourde
    { id: 60, value: "HUF", label: "Ft" }, // Hungarian Forint
    { id: 61, value: "IDR", label: "Rp" }, // Indonesian Rupiah
    { id: 62, value: "ILS", label: "₪" }, // Israeli New Sheqel
    { id: 63, value: "IMP", label: "£" }, // Isle of Man Pound
    { id: 64, value: "INR", label: "₹" }, // Indian Rupee
    { id: 65, value: "IQD", label: "ع.د" }, // Iraqi Dinar
    { id: 66, value: "IRR", label: "﷼" }, // Iranian Rial
    { id: 67, value: "ISK", label: "kr" }, // Icelandic Króna
    { id: 68, value: "JEP", label: "£" }, // Jersey Pound
    { id: 69, value: "JMD", label: "J$" }, // Jamaican Dollar
    { id: 70, value: "JOD", label: "د.ا" }, // Jordanian Dinar
    { id: 71, value: "JPY", label: "¥" }, // Japanese Yen
    { id: 72, value: "KES", label: "KSh" }, // Kenyan Shilling
    { id: 73, value: "KGS", label: "лв" }, // Kyrgyzstani Som
    { id: 74, value: "KHR", label: "៛" }, // Cambodian Riel
    { id: 75, value: "KID", label: "$" }, // Kiribati Dollar
    { id: 76, value: "KMF", label: "CF" }, // Comorian Franc
    { id: 77, value: "KPW", label: "₩" }, // North Korean Won
    { id: 78, value: "KRW", label: "₩" }, // South Korean Won
    { id: 79, value: "KWD", label: "د.ك" }, // Kuwaiti Dinar
    { id: 80, value: "KYD", label: "$" }, // Cayman Islands Dollar
    { id: 81, value: "KZT", label: "₸" }, // Kazakhstani Tenge
    { id: 82, value: "LAK", label: "₭" }, // Laotian Kip
    { id: 83, value: "LBP", label: "ل.ل" }, // Lebanese Pound
    { id: 84, value: "LKR", label: "රු" }, // Sri Lankan Rupee
    { id: 85, value: "LRD", label: "$" }, // Liberian Dollar
    { id: 86, value: "LSL", label: "L" }, // Lesotho Loti
    { id: 87, value: "LYD", label: "ل.د" }, // Libyan Dinar
    { id: 88, value: "MAD", label: "د.م." }, // Moroccan Dirham
    { id: 89, value: "MDL", label: "L" }, // Moldovan Leu
    { id: 90, value: "MGA", label: "Ar" }, // Malagasy Ariary
    { id: 91, value: "MKD", label: "ден" }, // Macedonian Denar
    { id: 92, value: "MMK", label: "K" }, // Myanma Kyat
    { id: 93, value: "MNT", label: "₮" }, // Mongolian Tugrik
    { id: 94, value: "MOP", label: "MOP$" }, // Macanese Pataca
    { id: 95, value: "MRU", label: "UM" }, // Mauritanian Ouguiya
    { id: 96, value: "MUR", label: "₨" }, // Mauritian Rupee
    { id: 97, value: "MVR", label: "ރ." }, // Maldivian Rufiyaa
    { id: 98, value: "MWK", label: "MK" }, // Malawian Kwacha
    { id: 99, value: "MXN", label: "$" }, // Mexican Peso
    { id: 100, value: "MYR", label: "RM" }, // Malaysian Ringgit
    { id: 101, value: "MZN", label: "MT" }, // Mozambican Metical
    { id: 102, value: "NAD", label: "$" }, // Namibian Dollar
    { id: 103, value: "NGN", label: "₦" }, // Nigerian Naira
    { id: 104, value: "NIO", label: "C$" }, // Nicaraguan Córdoba
    { id: 105, value: "NOK", label: "kr" }, // Norwegian Krone
    { id: 106, value: "NPR", label: "₨" }, // Nepalese Rupee
    { id: 107, value: "NZD", label: "$" }, // New Zealand Dollar
    { id: 108, value: "OMR", label: "ر.ع." }, // Omani Rial
    { id: 109, value: "PAB", label: "B/." }, // Panamanian Balboa
    { id: 110, value: "PEN", label: "S/" }, // Peruvian Nuevo Sol
    { id: 111, value: "PGK", label: "K" }, // Papua New Guinean Kina
    { id: 112, value: "PHP", label: "₱" }, // Philippine Peso
    { id: 113, value: "PKR", label: "₨" }, // Pakistani Rupee
    { id: 114, value: "PLN", label: "zł" }, // Polish Zloty
    { id: 115, value: "PYG", label: "₲" }, // Paraguayan Guarani
    { id: 116, value: "QAR", label: "ر.ق" }, // Qatari Rial
    { id: 117, value: "RON", label: "lei" }, // Romanian Leu
    { id: 118, value: "RSD", label: "дин." }, // Serbian Dinar
    { id: 119, value: "RUB", label: "₽" }, // Russian Ruble
    { id: 120, value: "RWF", label: "FR" }, // Rwandan Franc
    { id: 121, value: "SAR", label: "ر.س" }, // Saudi Riyal
    { id: 122, value: "SBD", label: "$" }, // Solomon Islands Dollar
    { id: 123, value: "SCR", label: "₨" }, // Seychellois Rupee
    { id: 124, value: "SDG", label: "ج.س." }, // Sudanese Pound
    { id: 125, value: "SEK", label: "kr" }, // Swedish Krona
    { id: 126, value: "SGD", label: "$" }, // Singapore Dollar
    { id: 127, value: "SHP", label: "£" }, // Saint Helena Pound
    { id: 128, value: "SLL", label: "Le" }, // Sierra Leonean Leone
    { id: 129, value: "SOS", label: "Sh" }, // Somali Shilling
    { id: 130, value: "SRD", label: "$" }, // Surinamese Dollar
    { id: 131, value: "SSP", label: "£" }, // South Sudanese Pound
    { id: 132, value: "STN", label: "Db" }, // São Tomé and Príncipe Dobra
    { id: 133, value: "SYP", label: "£" }, // Syrian Pound
    { id: 134, value: "SZL", label: "L" }, // Swazi Lilangeni
    { id: 135, value: "THB", label: "฿" }, // Thai Baht
    { id: 136, value: "TJS", label: "ЅМ" }, // Tajikistani Somoni
    { id: 137, value: "TMT", label: "m" }, // Turkmenistani Manat
    { id: 138, value: "TND", label: "د.ت" }, // Tunisian Dinar
    { id: 139, value: "TOP", label: "T$" }, // Tongan Pa'anga
    { id: 140, value: "TRY", label: "₺" }, // Turkish Lira
    { id: 141, value: "TTD", label: "TT$" }, // Trinidad and Tobago Dollar
    { id: 142, value: "TVD", label: "$" }, // Tuvaluan Dollar
    { id: 143, value: "TWD", label: "NT$" }, // New Taiwan Dollar
    { id: 144, value: "TZS", label: "TSh" }, // Tanzanian Shilling
    { id: 145, value: "UAH", label: "₴" }, // Ukrainian Hryvnia
    { id: 146, value: "UGX", label: "USh" }, // Ugandan Shilling
    { id: 147, value: "USD", label: "$" }, // United States Dollar
    { id: 148, value: "UYU", label: "$U" }, // Uruguayan Peso
    { id: 149, value: "UZS", label: "so'm" }, // Uzbekistan Som
    { id: 150, value: "VES", label: "Bs" }, // Venezuelan Bolívar
    { id: 151, value: "VND", label: "₫" }, // Vietnamese Dong
    { id: 152, value: "VUV", label: "VT" }, // Vanuatu Vatu
    { id: 153, value: "WST", label: "WS$" }, // Samoan Tala
    { id: 154, value: "XAF", label: "FCFA" }, // Central African CFA Franc
    { id: 155, value: "XCD", label: "$" }, // East Caribbean Dollar
    { id: 156, value: "XDR", label: "SDR" }, // Special Drawing Rights
    { id: 157, value: "XOF", label: "CFA" }, // West African CFA Franc
    { id: 158, value: "XPF", label: "₣" }, // CFP Franc
    { id: 159, value: "YER", label: "﷼" }, // Yemeni Rial
    { id: 160, value: "ZAR", label: "R" }, // South African Rand
    { id: 161, value: "ZMW", label: "ZK" }, // Zambian Kwacha
    { id: 162, value: "ZWL", label: "Z$" }, // Zimbabwean Dollar
];

export const currencyToCountryMap = {
    AED: 'AE', // United Arab Emirates
    AFN: 'AF', // Afghanistan
    ALL: 'AL', // Albania
    AMD: 'AM', // Armenia
    ANG: 'CW', // Curaçao (part of the Kingdom of the Netherlands)
    AOA: 'AO', // Angola
    ARS: 'AR', // Argentina
    AUD: 'AU', // Australia
    AWG: 'AW', // Aruba (part of the Kingdom of the Netherlands)
    AZN: 'AZ', // Azerbaijan
    BAM: 'BA', // Bosnia and Herzegovina
    BBD: 'BB', // Barbados
    BDT: 'BD', // Bangladesh
    BGN: 'BG', // Bulgaria
    BHD: 'BH', // Bahrain
    BIF: 'BI', // Burundi
    BMD: 'BM', // Bermuda (British Overseas Territory)
    BND: 'BN', // Brunei
    BOB: 'BO', // Bolivia
    BRL: 'BR', // Brazil
    BSD: 'BS', // Bahamas
    BTN: 'BT', // Bhutan
    BWP: 'BW', // Botswana
    BYN: 'BY', // Belarus
    BZD: 'BZ', // Belize
    CAD: 'CA', // Canada
    CDF: 'CD', // Democratic Republic of the Congo
    CHF: 'CH', // Switzerland
    CLP: 'CL', // Chile
    CNY: 'CN', // China
    COP: 'CO', // Colombia
    CRC: 'CR', // Costa Rica
    CUP: 'CU', // Cuba
    CVE: 'CV', // Cape Verde
    CZK: 'CZ', // Czech Republic
    DJF: 'DJ', // Djibouti
    DKK: 'DK', // Denmark
    DOP: 'DO', // Dominican Republic
    DZD: 'DZ', // Algeria
    EGP: 'EG', // Egypt
    ERN: 'ER', // Eritrea
    ETB: 'ET', // Ethiopia
    EUR: 'EU', // European Union
    FJD: 'FJ', // Fiji
    FKP: 'FK', // Falkland Islands (British Overseas Territory)
    FOK: 'FO', // Faroe Islands (part of the Kingdom of Denmark)
    GBP: 'GB', // United Kingdom
    GEL: 'GE', // Georgia
    GGP: 'GG', // Guernsey (British Crown Dependency)
    GHS: 'GH', // Ghana
    GIP: 'GI', // Gibraltar (British Overseas Territory)
    GMD: 'GM', // Gambia
    GNF: 'GN', // Guinea
    GTQ: 'GT', // Guatemala
    GYD: 'GY', // Guyana
    HKD: 'HK', // Hong Kong
    HNL: 'HN', // Honduras
    HRK: 'HR', // Croatia
    HTG: 'HT', // Haiti
    HUF: 'HU', // Hungary
    IDR: 'ID', // Indonesia
    ILS: 'IL', // Israel
    IMP: 'IM', // Isle of Man (British Crown Dependency)
    INR: 'IN', // India
    IQD: 'IQ', // Iraq
    IRR: 'IR', // Iran
    ISK: 'IS', // Iceland
    JEP: 'JE', // Jersey (British Crown Dependency)
    JMD: 'JM', // Jamaica
    JOD: 'JO', // Jordan
    JPY: 'JP', // Japan
    KES: 'KE', // Kenya
    KGS: 'KG', // Kyrgyzstan
    KHR: 'KH', // Cambodia
    KID: 'KI', // Kiribati
    KMF: 'KM', // Comoros
    KRW: 'KR', // South Korea
    KWD: 'KW', // Kuwait
    KYD: 'KY', // Cayman Islands (British Overseas Territory)
    KZT: 'KZ', // Kazakhstan
    LAK: 'LA', // Laos
    LBP: 'LB', // Lebanon
    LKR: 'LK', // Sri Lanka
    LRD: 'LR', // Liberia
    LSL: 'LS', // Lesotho
    LYD: 'LY', // Libya
    MAD: 'MA', // Morocco
    MDL: 'MD', // Moldova
    MGA: 'MG', // Madagascar
    MKD: 'MK', // North Macedonia
    MMK: 'MM', // Myanmar
    MNT: 'MN', // Mongolia
    MOP: 'MO', // Macau
    MRU: 'MR', // Mauritania
    MUR: 'MU', // Mauritius
    MVR: 'MV', // Maldives
    MWK: 'MW', // Malawi
    MXN: 'MX', // Mexico
    MYR: 'MY', // Malaysia
    MZN: 'MZ', // Mozambique
    NAD: 'NA', // Namibia
    NGN: 'NG', // Nigeria
    NIO: 'NI', // Nicaragua
    NOK: 'NO', // Norway
    NPR: 'NP', // Nepal
    NZD: 'NZ', // New Zealand
    OMR: 'OM', // Oman
    PAB: 'PA', // Panama
    PEN: 'PE', // Peru
    PGK: 'PG', // Papua New Guinea
    PHP: 'PH', // Philippines
    PKR: 'PK', // Pakistan
    PLN: 'PL', // Poland
    PYG: 'PY', // Paraguay
    QAR: 'QA', // Qatar
    RON: 'RO', // Romania
    RSD: 'RS', // Serbia
    RUB: 'RU', // Russia
    RWF: 'RW', // Rwanda
    SAR: 'SA', // Saudi Arabia
    SBD: 'SB', // Solomon Islands
    SCR: 'SC', // Seychelles
    SDG: 'SD', // Sudan
    SEK: 'SE', // Sweden
    SGD: 'SG', // Singapore
    SHP: 'SH', // Saint Helena (British Overseas Territory)
    SLL: 'SL', // Sierra Leone
    SOS: 'SO', // Somalia
    SPL: 'XSS', // Seborga (self-proclaimed micronation)
    SRD: 'SR', // Suriname
    SSP: 'SS', // South Sudan
    STN: 'ST', // São Tomé and Príncipe
    SVC: 'SV', // El Salvador
    SYP: 'SY', // Syria
    SZL: 'SZ', // Eswatini
    THB: 'TH', // Thailand
    TJS: 'TJ', // Tajikistan
    TMT: 'TM', // Turkmenistan
    TND: 'TN', // Tunisia
    TOP: 'TO', // Tonga
    TRY: 'TR', // Turkey
    TTD: 'TT', // Trinidad and Tobago
    TWD: 'TW', // Taiwan
    TZS: 'TZ', // Tanzania
    UAH: 'UA', // Ukraine
    UGX: 'UG', // Uganda
    USD: 'US', // United States
    UYU: 'UY', // Uruguay
    UZS: 'UZ', // Uzbekistan
    VES: 'VE', // Venezuela
    VND: 'VN', // Vietnam
    VUV: 'VU', // Vanuatu
    WST: 'WS', // Samoa
    XAF: 'GA', // Central African Republic
    XCD: 'LC', // Saint Lucia
    XOF: 'BF', // Burkina Faso
    XPF: 'PF', // French Polynesia
    YER: 'YE', // Yemen
    ZAR: 'ZA', // South Africa
    ZMW: 'ZM', // Zambia
    ZWL: 'ZW', // Zimbabwe
};

export const campaignList = [
    { id: 1, value: "Save Trees, Save Earth", label: "Save Trees, Save Earth" },
    { id: 1, value: "Saving Animal Campaign", label: "Saving Animal Campaign" },
    { id: 1, value: "IEEE Beach Cleaning", label: "IEEE Beach Cleaning" },
]

export function adjustNegativeMonths(date) {
    while (date?.getMonth() < 0) {
      date.setFullYear(date.getFullYear() - 1);
      date.setMonth(date.getMonth() + 12);
    }
    return date;
}

function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let browserName;
    let browserVersion;
    // Detect Chrome
    if (userAgent.indexOf('Chrome') !== -1) {
        const chromeIndex = userAgent.indexOf('Chrome');
        browserName = 'Chrome';
        browserVersion = userAgent.substring(chromeIndex + 7).split(' ')[0];
    }
    // Detect Firefox
    else if (userAgent.indexOf('Firefox') !== -1) {
        const firefoxIndex = userAgent.indexOf('Firefox');
        browserName = 'Firefox';
        browserVersion = userAgent.substring(firefoxIndex + 8).split(' ')[0];
    }
    // Detect Safari
    else if (userAgent.indexOf('Safari') !== -1) {
        const safariIndex = userAgent.indexOf('Version');
        browserName = 'Safari';
        browserVersion = userAgent.substring(safariIndex + 8).split(' ')[0];
    }
    // Detect Edge
    else if (userAgent.indexOf('Edg') !== -1) {
        const edgeIndex = userAgent.indexOf('Edg');
        browserName = 'Edge';
        browserVersion = userAgent.substring(edgeIndex + 4).split(' ')[0];
    }
    // Detect Internet Explorer
    else if (userAgent.indexOf('Trident') !== -1) {
        const msieIndex = userAgent.indexOf('MSIE');
        browserName = 'Internet Explorer';
        browserVersion = userAgent.substring(msieIndex + 5).split(';')[0];
    }
    // Other browsers
    else {
        browserName = 'Unknown';
        browserVersion = 'Unknown';
    }
    return `${browserName} ${browserVersion}`;
}
function getDeviceType() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (screenWidth < 768) {
        return 'Mobile';
    } else if (screenWidth < 1024) {
        return 'Tablet';
    } else {
        return 'Desktop/Laptop';
    }
}
export function getClientData() {
    return new Promise((resolve, reject) => {
        fetch('https://get.geojs.io/v1/ip/geo.json')
        .then(response => response.json())
        .then(data => {
            const { ip, city, region, country } = data;
            const browser = getBrowserInfo();
            const device = getDeviceType();
            const clientData = {
                ip_address: ip,
                city: city,
                region: region,
                country: country,
                browser: browser,
                device: device
            };
            resolve(clientData);
        })
        .catch(error => {
            console.error('Error fetching location:', error);
            reject(error);
        });
    });
}

export const downloadFile = (fileUrl) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};