import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },
  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    fontWeight: "500",
    textTransform: "capitalize",
  },
  neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  fw600: {
    fontWeight: "600",
    textWrap: "wrap",
  },
  modeButton: {
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    
  },
  fs18: {
    fontWeight: "600",
    width: "100%",
  },
  justifyCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  quick: {
    fontSize: "20px",
    fontWeight: "600",
  },
  links: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "500",
    color: "#8ED003",
    textTransform: "capitalize",
    display: "block",
    cursor:"pointer",
  },
  fs16: {
    fontSize: "16px",
  },
  input: {
    
    borderRadius: "10px",
    width: "100%",
    "& .MuiInputLabel-root": {
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  select: {
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
    },
    
    borderRadius: "10px",
    width: "100%",
    
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
    
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  menuItem: {
    fontWeight: "600",
    
    "&:hover": {
      color: "#689900",
    },
    "&:focus": {
      color: "#689900",
    },
  },
  italics: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "italic",
    lineHeight: "21px",
  },
  f18w500: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  f18w700: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "24px",
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  uploadButton: {
    background: "#141414",
    borderRadius: "26px",
    padding: "10px 15px",
    "&:hover": {
      background: "#659500",
    },
  },

  inputText: {
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  upload: {
    display: "flex",
    border: "0.25px solid #FFF",
    padding: "10px 15px",
    borderRadius: "26px",
    width: "40%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:hover": {
      filter: "grayscale(0)",
    },
  },
  colorBox: {
    background:
      "linear-gradient(0deg, rgba(13, 13, 13, 0.4), rgba(13, 13, 13, 0.9)) linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    width: "fit-content",
    borderRadius: "5px",
    padding: "5px 10px",
    display: "flex",
    alignItems: "center",
    border: "0.5px solid #FFFFFF",
  },
  color: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
  },
  icon: {
    color: "#FFF",
    borderRadius: "50%",
    marginRight: 15,
    transform: "scale(1.5)",
  },
  p20: {
    padding: "20px 0px",
  },
  add: {
    display: "flex",
    color: "#FFFF",
    width: "25%",
    fontSize: "28px",
    backgroundColor: "#0D0D0D66",
    borderRadius: "22px",
    padding: "0px 10px",
  },
  
  options: {
    padding: "10px 0px 10px 0px",
    my: 1,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  optionText: {
    fontWeight: "600",
    color: "#FFFF",
    textTransform: "capitalize",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  f16w400: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
  },
  preview: {
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover":{
      border: "1px solid #689900",
    }
  },
  save: {
    color: "#FFF",
    background: "#659500",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#8ED003",
      border: "1px solid #689900",
    },
  },
}));
