import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/store/store";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import theme from "./Theme/theme.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material";
import Spinner from "./utils/spinnerLoader";
import AlertMessage from "./utils/alertMessage";
import { useSelector } from "react-redux";
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import graphqlAuthHeader from '../src/services/graphql-auth-header';
import { getUserFromLocalStorage } from './services/localStorageService';
import { onError } from '@apollo/client/link/error';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { createTheme } from '@mui/material/styles';

let stripePromise;
const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if(stripe_public_key){
  try {
    stripePromise = loadStripe(stripe_public_key);
  } catch (error) {
    console.error('Failed to load Stripe:', error);
  }
}
const httpLink = createHttpLink({
  uri: "https://api.accceleraise.com/api/v1/graphql",
});
const responseTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { response: { headers } } = operation.getContext();
    let userData = getUserFromLocalStorage();
    if (headers && headers.get('Access-Token') && headers.get('Client') && headers.get('Uid') && userData) {
      userData['access-token'] = headers.get('Access-Token');
      userData['client'] = headers.get('Client');
      userData['uid'] = headers.get('Uid');
      localStorage.setItem("user", JSON.stringify(userData));
    }
    return response;
  });
});
const link = ApolloLink.from([responseTokenLink, httpLink])
const logoutLink = onError(({ networkError }) => {
  if (networkError?.statusCode === 401) {
    localStorage.removeItem("user");
    window.location.href = '/'
  };
})
const client = new ApolloClient({
  link: logoutLink.concat(graphqlAuthHeader.concat(link)),
  cache: new InMemoryCache(),
});

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(

//   <React.StrictMode>
//     <Provider store={store}>
//       <ApolloProvider client={client}>
//         <BrowserRouter>
//           <StyledEngineProvider injectFirst>
//             <ThemeProvider theme={theme}>
//               <CssBaseline />
//               <AlertMessage />
//               <Spinner/>
//               <Elements stripe={stripePromise}>
//                 <App />
//               </Elements>
//             </ThemeProvider>
//           </StyledEngineProvider>
//         </BrowserRouter>
//       </ApolloProvider>
//     </Provider>
//   </React.StrictMode>
// );

// serviceWorkerRegistration.unregister();
// reportWebVitals();

// const createAppTheme = () => {
//   const themeMode = useSelector((state) => state.themeMode.mode);

//   return createTheme({
//     palette: {
//       mode: themeMode ? 'dark' : 'light',
//     },
//   });
// };

const AppThemeWrapper = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const theme = createTheme({
    palette: {
      mode: themeMode ==="dark"? 'dark' : 'light',
    },
    
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertMessage />
      <Spinner />
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <AppThemeWrapper />
          </StyledEngineProvider>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();