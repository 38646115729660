import { useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";

export const SupportCard = ({ icon, title, subtitle }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid 
      className={`${themeMode === 'dark' ? classes.supportCard : ''} mb-8`}
      sx={{ 
        background: "#D9D9D95E",
        padding: '24px',
        borderRadius: "15px",
        boxShadow:'0px 8px 5px 0px #00000040',
        border: themeMode === "dark" ? "" : "2px solid #484B6A",
        color: themeMode === 'dark' ? '#FFFF' : '#030303',
      }}>
      {/* <Grid className="flex items-center space-x-8"> */}
      <Grid sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, }}>

        <Grid item md={1.5} sm={2} 
          sx={{display:{md:"flex",sm:"flex",xs:"none"},justifyContent:"center",alignItems:"center"}}>
            <img src={icon} alt="icon" />
        </Grid>

        <Grid item md={10.5} sm={10} sx={{ display: { md: "flex", sm: "flex", xs: "none" }, flexDirection: { md: "column", sm: "column", xs: "column" }, }}>
          
          <Typography className="support-card-title"
              sx={{ color: themeMode === "dark" ? "#FFF" :"#484B6A",fontSize:{md:"36px",sm:"36px",xs:"30px"},lineHeight: "43.2px"}}
            >
              {" "}
              {title}
            
          </Typography>
          <Typography className="support-card-subtitle mt-4"
          >
              {subtitle}
            
          </Typography>
        </Grid>



        <Grid item md={10.5} sm={10} sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: { md: "column", sm: "column", xs: "column" }, }}>
          <Grid sx={{display:"flex",justifyContent:"space-between"}}>
            <Grid item xs={3.5}
              sx={{ display: { xs:"flex" }, justifyContent: "center", alignItems: "center" }}>
              <img src={icon} alt="icon" />
            </Grid>
            <Grid item xs={7.5}>
              <Typography className="support-card-title"
                sx={{ color: themeMode === "dark" ? "#FFF" : "#484B6A", fontSize: "25px",lineHeight: "33.2px" }}
              >
                {" "}
                {title}

              </Typography>
            </Grid>
          </Grid>
          <Typography className="support-card-subtitle mt-4"
          >
            {subtitle}

          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
