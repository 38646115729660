import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import optimize from '../../../../../../assets/FeatureLightTheme/Recurring/optimizeLight.svg'
import control from '../../../../../../assets/FeatureLightTheme/Recurring/control.svg'


const fields = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/fields.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const recurring1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/recurring1.svg";
const recurring2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/recurring2.svg";
const light_right_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_right_shadow.svg";
const light_left_shadow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_left_shadow.svg";
const light_recurr2 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_recurr2.svg";
const light_recurr3 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_recurr3.svg";

const SubSection = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
    return (
      <Grid sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E3E4F0" }}>
        <Grid
          className={classes.bkg}
          container
          sx={{
            backgroundImage: themeMode === 'dark'
              ? `url('${bkg}'), url('${bkg2}')`
              : `url('${blur1}'), url('${blur2}')`,
            backgroundSize: themeMode === "dark" ? "140% auto" : "140% auto",
            backgroundPosition: themeMode === "dark" ? "0% 0%, 100% 100%" : "right bottom,left 0%",
            padding: { md: "100px 150px", sm: "90px 40px", xs: "70px 20px" },
            //padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
          }}
        >
          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: themeMode === "dark" ? "" :"10px 13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(85deg, white, transparent)"
                  : "linear-gradient(145deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column", xs: "column" },
                alignItems:"center",
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    color: themeMode === "dark" ? "#fff" : "#030303"
                  }}
                >
                  <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A"}}>optimize</span> for
                  recurring giving
                </Typography>
                <Grid item md={12}>
                  <Typography
                    mt={4}
                    sx={{
                      textAlign: { md: "left", sm: "center", xs: "center" },
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      fontFamily:"Stolzl",
                      color: themeMode === "dark" ? "#fff" : "#030303"
                    }}
                  >
                    Set up your donations once, and they will be automatically processed on your schedule, ensuring consistent support for your chosen cause. With secure payment options and personalized tracking, you can see the difference your generosity makes over time.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={5}
                mt={{ md: 0, sm: 3, xs: 3 }}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  top: { md: "20%", sm: "0%", xs: "0%" },
                  transform: { md: "translate(20%,10%) scale(1.2)", sm: "translateY(0%)" },
                }}
              >
                <img src={themeMode==="dark"?recurring1:optimize} alt="" />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            sm={11}
            xs={11.5}
            mt={{ md: 25, sm: 20, xs: 20 }}
            sx={{
              padding: { md: "40px", sm: "30px", xs: "20px" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "15px",
              backdropFilter: "blur(22px)",
              background: "transparent",
              boxShadow: themeMode==="dark"?"":"-10px -13px 5px 0px #00000040",
              position: "relative",
              color: "#fff",
              //overflow: 'hidden',
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: themeMode === "dark"
                  ? "linear-gradient(265deg, white, transparent)"
                  : "linear-gradient(325deg, #00000040, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: {
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                },
                justifyContent: { md: "flex-end", sm: "center", xs: "center" },
              }}
            >
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "flex", sm: "flex", xs: "flex" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  top: { md: "-10%", sm: "0%", xs: "0%" },
                  left: { md: "-4%", sm: "10%", xs: "0%" },
                  transform: {
                    md: "translateX(-10%) scale(1.3)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={themeMode === "dark" ? recurring2 : control} alt="" />
              </Grid>
              {/* <Grid
                item
                md={8}
                sm={12}
                xs={12}
                mt={3}
                sx={{
                  display: { md: "none", sm: "none", xs: "flex" },
                  alignItems: "center",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  position: "relative",
                  transform: {
                    md: "translateX(20%) scale(1.4)",
                    sm: "translateY(0%)",
                    xs: "translateY(0%)",
                  },
                }}
              >
                <img src={themeMode==="dark"?recurring2:control} alt="" style={{ width: "100%" }} />
              </Grid> */}
              <Grid item md={10} sm={12} xs={12}>
                <Typography
                  className={classes.title}
                  sx={{
                    fontSize: { md: "40px", sm: "35px", xs: "30px" },
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    color: themeMode === "dark" ? "#fff" : "#030303"
                  }}
                >
                  Controlled recurring donations with{" "}
                  <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}>donor portal</span>
                </Typography>
                <Typography
                  mt={4}
                  sx={{
                    textAlign: { md: "right", sm: "center", xs: "center" },
                    fontFamily: "Stolzl",
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    color: themeMode === "dark" ? "#fff" : "#030303"
                  }}
                >
                  Streamline your fundraising toolkit and enhance donor interactions with P2P Fundraisers – the singular peer-to-peer fundraising tool seamlessly integrated into your current website.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    );
};
export default SubSection;
