import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'

const TopSupporter = ({limitedSupporters, title, textColor, labelColor, backgroundColor, borderSize, borderRadius, borderColor, shadow, enableAmount, enableLocation, themeMode}) => {

    return (
        <>
            <div 
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius  ? `${borderRadius}px` : '0px', 
                    border: borderSize || borderColor ? `${borderSize ? borderSize : '0'}px solid ${borderColor ? borderColor : 'transparent'}` : 'none' , 
                }}
            >
                <div style={{ fontSize: '28px', fontWeight: '600', padding: '8px', color: textColor ? textColor : '#FFF' }}>
                    {title ? title : 'Top Support'}
                </div>

                {limitedSupporters?.map((item, index) => (
                    <div index={index} style={{
                        width:"100%"
                        // borderRadius:"10px",
                        // border:
                        //     themeMode === 'dark'
                        //         ? '1px solid #FFF'
                        //         : '1px solid #000',

                    }}>
                        <div style={{ width: "100%" }}>

                            <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
                                <div style={{ padding: "15px", color: textColor ? textColor : '#FFF' }}>
                                    {item.id}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{textAlign:"left",fontWeight:"700", color: textColor ? textColor : '#FFF'}}>
                                        {item.name}{" "}
                                        {enableAmount && 
                                            <>
                                                donated{" "}
                                                <span style={{ color:"#689900",fontWeight:"600",marginRight:"10px"}}>${item.value}</span>
                                            </> 
                                        }
                                    </div>
                                    {enableLocation && <div style={{color: labelColor ? labelColor : '#FFF'}}>{item.city}</div>}
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default TopSupporter




export const supporter = [
    {
        id: 0,name:"Ann Bowman", value: "4,231.45", city: "New York, United States"
    },
    {
        id: 1, name: "Jane Ried", value: "3,922.23", city: "Boston, United States"
    },
    {
        id: 2, name: "Mark Bowman", value: "2,241.5", city: "New York, United States"
    },
    {
        id: 3, name: "Robert", value: "10,060.5", city: "Detroit, United States"
    },
    {
        id: 4, name: "Khaled", value: "8,631.45", city: "Karachi, Pakistan"
    },
    {
        id: 5, name: "A Kumar", value: "1,991.05", city: "Delhi, India"
    },
    {
        id: 6, name: "Stephanie Brown", value: "239.5", city: "Berlin, Germany"
    },
    {
        id: 7, name: "Dan White", value: "4,999.45", city: "Paris, France"
    },
    {
        id: 8, name: "Mike Grayson", value: "4,200.45", city: "Glasgow, Scotland"
    },
    {
        id: 9, name: "Andrio E", value: "8,735.45", city: "Madrid, Spain"
    }

]