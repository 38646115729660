import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles((theme) => ({
  bkg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  upper700: {
    textTransform: "uppercase",
    fontWeight: "700",
  },
  color: {
    
  },
  jCenter: {
    display: "flex",
    justifyContent: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  full: {
    width: "100%",
  },
}));
