import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { OverviewList } from "../overviewList";
import LoadingButton from "@mui/lab/LoadingButton";
import { ArrowRightAlt } from "@mui/icons-material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { MySwitch, MyTextField } from "../MyComponents";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_ASK_PAGE,
  UPDATE_ASK_PAGE,
  CREATE_THANK_YOU_PAGE,
  UPDATE_THANK_YOU_PAGE,
  GET_CAMPAIGN,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useStyles } from "./useStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ThankYou } from "../../Campaign/PreviewScreens/Screens/ThankYou";

const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const upload =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/upload.svg";
const uploadDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/uploadDark.svg";

const CheckoutModal = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoFileInputRef = useRef(null);
  const mainImgFileInputRef = useRef(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [logoFile, setlogoFile] = useState(null);
  const [mainImgFile, setMainImgFile] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [imageLogoUrl, setImageLogoUrl] = useState(null);
  const [imageMainUrl, setImageMainUrl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedColor, setSelectedColor] = useState("#000");
  const [mode, setMode] = useState("Ask");
  const [type, setType] = useState(1);
  const [checkoutObj, setCheckoutObj] = useState({
    campaignUuid: uuid,
    campaignTitle: "",
    askPageUuid: null,
    thankYouPageUuid: null,
    title: null,
    askPageMessage: null,
    thankYouPageMessage: null,
    socialLinks: [],
    enableScreen: null,
    typeOfScreen: null,
  });
  const [links, setLinks] = useState([""]);
  const { data: campaignData } = useQuery(GET_CAMPAIGN, {
    variables: { uuid },
    onCompleted: (result) => {
      if (result?.campaign) {
        setCheckoutObj((prevObj) => ({
          ...prevObj,
          askPageUuid: result?.campaign?.askPage?.uuid,
          title: result?.campaign?.askPage?.title,
          askPageMessage: result?.campaign?.askPage?.message,
          socialLinks: result?.campaign?.askPage?.socialLinks,
          campaignTitle: result?.campaign?.title,
        }));
        setLinks(result.campaign.askPage.socialLinks);
      }
      if (result?.campaign) {
        setCheckoutObj((prevObj) => ({
          ...prevObj,
          thankYouPageUuid: result?.campaign?.thankYouPage?.uuid,
          thankYouPageMessage: result?.campaign?.thankYouPage?.message,
          enableScreen: result?.campaign?.thankYouPage?.enableScreen,
          typeOfScreen: result?.campaign?.thankYouPage?.typeOfScreen,
          campaignTitle: result?.campaign?.title,
        }));
      }
    },
  });
  const [CreateAskPage, { loading: askLoading, error: askError }] = useMutation(
    CREATE_ASK_PAGE,
    {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("your request was successful!"));
      },
    }
  );
  const [UpdateAskPage, { loading: askUpdateLoading, error: askUpdateError }] =
    useMutation(UPDATE_ASK_PAGE, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("your request was unsuccessful!"));
      },
      onCompleted: (data) => {
        console.log("Mutation Completed:", data);
        dispatch(success("your request was successful!"));
      },
    });
  const [
    CreateThankYouPage,
    { loading: thankPageLoading, error: thankPageError },
  ] = useMutation(CREATE_THANK_YOU_PAGE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const [
    UpdateThankYouPage,
    { loading: thankPageUpdateLoading, error: thankPageUpdateError },
  ] = useMutation(UPDATE_THANK_YOU_PAGE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      dispatch(success("your request was successful!"));
    },
  });
  const handleAddLink = () => {
    if (links.length < 3) {
      setLinks((prevLinks) => [...prevLinks, ""]);
    }
  };
  const handleLinkChange = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = value;
    setLinks(newLinks);
    setCheckoutObj((prevObj) => ({
      ...prevObj,
      socialLinks: newLinks,
    }));
  };

  const changeType = (index) => {
    setType(index);
  };
  const AskMode = () => {
    setMode("Ask");
  };

  const Thankyou = () => {
    setMode("Thankyou");
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleButtonClick = (inputRef) => {
    inputRef.current.click();
  };

  const handleLogoFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setlogoFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageLogoUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMainFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMainImgFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageMainUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (mode === "Ask") {
      if (!checkoutObj?.askPageUuid) {
        const variables = {
          campaignUuid: uuid,
          title: checkoutObj?.title,
          message: checkoutObj?.askPageMessage,
          socialLinks: checkoutObj?.socialLinks,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreateAskPage({ variables });
          setCheckoutObj((prevObj) => ({
            ...prevObj,
            askPageUuid: result?.data?.createAskPage?.askPage?.uuid,
            title: result?.data?.createAskPage?.askPage?.title,
            askPageMessage: result?.data?.createAskPage?.askPage?.message,
            socialLinks: result?.data?.createAskPage?.askPage?.socialLinks,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (checkoutObj?.askPageUuid) {
        const variables = {
          uuid: checkoutObj?.askPageUuid,
          title: checkoutObj?.title,
          message: checkoutObj?.askPageMessage,
          socialLinks: checkoutObj?.socialLinks,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdateAskPage({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    } else if (mode === "Thankyou") {
      if (!checkoutObj?.thankYouPageUuid) {
        const variables = {
          campaignUuid: uuid,
          message: checkoutObj?.thankYouPageMessage,
          enableScreen: checkoutObj?.enableScreen,
          typeOfScreen: checkoutObj?.typeOfScreen,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await CreateThankYouPage({ variables });
          setCheckoutObj((prevObj) => ({
            ...prevObj,
            thankYouPageUuid:
              result?.data?.createThankYouPage?.thankYouPage?.uuid,
            thankYouPageMessage:
              result?.data?.createThankYouPage?.thankYouPage?.message,
            enableScreen:
              result?.data?.createThankYouPage?.thankYouPage?.enableScreen,
            typeOfScreen:
              result?.data?.createThankYouPage?.thankYouPage?.typeOfScreen,
          }));
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      } else if (checkoutObj?.thankYouPageUuid) {
        const variables = {
          uuid: checkoutObj?.thankYouPageUuid,
          message: checkoutObj?.thankYouPageMessage,
          enableScreen: checkoutObj?.enableScreen,
          typeOfScreen: checkoutObj?.typeOfScreen,
        };
        dispatch(setSpinnerLoading(true));
        try {
          const result = await UpdateThankYouPage({ variables });
          dispatch(setSpinnerLoading(false));
        } catch (error) {
          console.error(error);
          dispatch(setSpinnerLoading(false));
        }
      }
    }
  };

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid sx={{}}>
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>

              <Grid className={classes.fullWidth}>
                <Button
                  className={classes.transButton}
                  href="/dashboard/campaigns"
                  sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
                >
                  <Grid className={classes.flexCenter}>
                    <img
                      src={themeMode === "dark" ? heart : heartDark}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "9px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      Campaigns
                    </Typography>
                  </Grid>
                </Button>
                {/* <Grid
                  className={classes.flexCenter}
                  mx={{ md: 1, sm: 1, xs: 0 }}
                >
                  <ArrowRightAlt />
                </Grid>
                <Button
                  className={classes.neonButton}
                  href={`/dashboard/campaign-settings/${uuid}`}
                  sx={{
                    fontSize: { md: "16px", sm: "16px", xs: "12px" },
                    margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" },
                  }}
                >
                  <Grid className={classes.flexCenter}>Checkout Modal</Grid>
                </Button> */}
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontSize: { md: "40px", sm: "40px", xs: "26px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                }}
              >
                {checkoutObj?.campaignTitle?.length > 15
                  ? `${checkoutObj.campaignTitle.substring(0, 15)}...`
                  : checkoutObj.campaignTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll   */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList?.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${checkoutObj?.campaignTitle}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 3
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 3
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                {/* <Grid 
                    sx={{
                      display:{md:"flex",sm:"flex",xs:"flex"},
                      flexDirection:"row",
                      width:{md:"100%",sm:"fit-content",xs:"100%"},
                      padding:"0px",
                      transform:{md:"scale(1)",sm:"scale(0.9)"}
                    }}> */}

                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 3
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 3 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
                {/* </Grid> */}
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid key={`arrow-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: { md: "scale(1.3)", sm: "scale(1)" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303"
                  }}>
                  <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${checkoutObj?.campaignTitle}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 3
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 3
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 3
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 3 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>

        <Grid
          className={classes.fullWidth}
          sx={{
            justifyContent: { md: "left", sm: "left", xs: "center" },
          }}
        >
          <Button
            className={classes.modeButton}
            disableRipple
            onClick={AskMode}
            sx={{
              background: mode === "Ask" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 10px",
              },
              color:
                themeMode === "light" && mode !== "Ask" ? "#030303" : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{
                  fontSize: { md: "18px", sm: "18px", xs: "14px" },
                }}
              >
                Ask Page
              </Typography>
            </Grid>
          </Button>
          <Button
            className={classes.modeButton}
            onClick={Thankyou}
            disableRipple
            sx={{
              background:
                mode === "Thankyou" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Thankyou"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "14px" } }}
              >
                Thank You Screen
              </Typography>
            </Grid>
          </Button>
        </Grid>

        {mode === "Ask" ? (
          <Grid item md={12} sm={12} xs={12} mt={5}>
            <Typography
              className={classes.fs16}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              Enhance your inquiry with a personalized message. Share your
              narrative in the moment and sustain the emotional bond.
            </Typography>

            <Grid
              item
              md={9}
              className={classes.flexStart}
              sx={{ flexDirection: { md: "row", sm: "row", xs: "column" } }}
            >
              <Grid my={2} item md={6} sm={4} xs={12}>
                <Typography
                  className={classes.insert}
                  mb={2}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  Insert Logo Here
                </Typography>
                <Grid className={classes.spaceBtwn} item md={7}>
                  <input
                    type="file"
                    ref={logoFileInputRef}
                    onChange={handleLogoFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    className={classes.uploadButton}
                    variant="contained"
                    onClick={() => handleButtonClick(logoFileInputRef)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    sx={{
                      background: themeMode === "dark" ? "#141414" : "#ECECEC",
                      border:
                        themeMode === "dark"
                          ? "1px solid transparent"
                          : "1px solid #000",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      "&:hover": {
                        color: "#FFF",
                      },
                    }}
                  >
                    <Grid className={classes.fullWidth}>
                      <Typography
                        className={classes.buttonText}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            themeMode === "dark"
                              ? upload
                              : themeMode === "light" && isHovered
                              ? upload
                              : uploadDark
                          }
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                        Upload
                      </Typography>
                    </Grid>
                  </Button>
                  {logoFile && (
                    <Grid
                      className={classes.upload}
                      sx={{
                        backgroundImage: `url(${imageLogoUrl})`,
                        filter: "grayscale(1)",
                      }}
                    ></Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                my={2}
                item
                md={6}
                sm={5}
                xs={12}
                ml={{ md: 0, sm: 2, xs: 0 }}
              >
                <Typography
                  className={classes.insert}
                  mb={2}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  Insert Main Image Here
                </Typography>
                <Grid
                  item
                  md={7}
                  className={classes.spaceBtwn}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    type="file"
                    ref={mainImgFileInputRef}
                    onChange={handleMainFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    className={classes.uploadButton}
                    variant="contained"
                    onClick={() => handleButtonClick(mainImgFileInputRef)}
                    onMouseEnter={() => setIsHovered2(true)}
                    onMouseLeave={() => setIsHovered2(false)}
                    sx={{
                      background: themeMode === "dark" ? "#141414" : "#ECECEC",
                      border:
                        themeMode === "dark"
                          ? "1px solid transparent"
                          : "1px solid #000",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      "&:hover": {
                        color: "#FFF",
                      },
                    }}
                  >
                    <Grid className={classes.fullWidth}>
                      <Typography
                        className={classes.buttonText}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            themeMode === "dark"
                              ? upload
                              : themeMode === "light" && isHovered2
                              ? upload
                              : uploadDark
                          }
                          alt=""
                          style={{ marginRight: "10px" }}
                        />
                        Upload
                      </Typography>
                    </Grid>
                  </Button>
                  {mainImgFile && (
                    <Grid
                      className={classes.upload}
                      sx={{
                        backgroundImage: `url(${imageMainUrl})`,
                        filter: "grayscale(1)",
                      }}
                    ></Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={6}
              sm={9}
              my={3}
              sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
            >
              <FormControl className={classes.fullWidth}>
                <Typography className={classes.insert} mb={1}>
                  Ask Page Title
                </Typography>
                <Typography mb={2}>
                  Tell your donors your main campaign motto.
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  value={checkoutObj?.title || ""}
                  onChange={(event) =>
                    setCheckoutObj((prevObj) => ({
                      ...prevObj,
                      title: event.target.value,
                    }))
                  }
                  className={classes.input}
                  sx={{
                    mt: { md: 0, sm: 2, xs: 2 },
                    mr: 1,
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              </FormControl>

              <Typography className={classes.insert} mt={5} mb={1}>
                Tell your story, why giving to you is important.{" "}
              </Typography>
              <FormControl className={classes.fullWidth}>
                <MyTextField
                  textMsg={checkoutObj?.askPageMessage || "Write message here"}
                  handleTextChange={(value) =>
                    setCheckoutObj((prevObj) => ({
                      ...prevObj,
                      askPageMessage: value,
                    }))
                  }
                />
              </FormControl>
            </Grid>

            <Grid
              mt={6}
              item
              md={9.5}
              sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
            >
              <Typography className={classes.insert} mt={5} mb={1}>
                Links
              </Typography>
              <Typography className={classes.f16w400}>
                Add up to 3 custom links. These can be a URL, an email address
                or a phone number. URLs must begin with http or https and email
                addresses must be preceded by "mailto:". Phone numbers should
                begin with a + and the international country code, and should
                not contain dashes.
              </Typography>
            </Grid>

            <Grid my={3}>
              {links?.map((link, index) => (
                <TextField
                  key={index}
                  fullWidth
                  variant="outlined"
                  label={`Link ${index + 1}`}
                  value={link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                  className={classes.input}
                  sx={{
                    mt: 5,
                    backgroundColor:
                      themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                    "& .MuiInputLabel-root": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                    },
                    "& .MuiOutlinedInput-input": {
                      color: themeMode === "dark" ? "#ffff" : "#000",
                      "&::placeholder": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                    },
                  }}
                />
              ))}
              {links?.length < 3 && (
                <Button
                  variant="contained"
                  onClick={handleAddLink}
                  className={classes.uploadButton}
                  sx={{
                    mt: 3,
                    background: themeMode === "dark" ? "#141414" : "#ECECEC",
                    border:
                      themeMode === "dark"
                        ? "1px solid transparent"
                        : "1px solid #000",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    padding: "10px 30px",
                    "&:hover": {
                      color: "#FFF",
                    },
                  }}
                >
                  <Typography className={classes.buttonText}>
                    + Add Link
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        ) : mode === "Thankyou" ? (
          <Grid
            my={5}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ color: themeMode === "dark" ? "#ffff" : "#000" }}
          >
            <Grid className={classes.container} item md={12} my={2}>
              <MySwitch
                checked={checkoutObj?.enableScreen || ""}
                onChange={(event) =>
                  setCheckoutObj((prevObj) => ({
                    ...prevObj,
                    enableScreen: event.target.checked,
                  }))
                }
              />
              <Typography className={classes.fs16} mx={3}>
                Enable Thank You Screen
              </Typography>
            </Grid>

            <Grid my={6}>
              <Typography className={classes.fs16}>
                Shape the post-donation experience for your Checkout modal.
              </Typography>

              <Typography className={classes.fs16}>
                Choose what to show donors after they donate:
              </Typography>

              <Grid my={2}>
                <FormControl className={classes.fullWidth}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue="specific_url"
                    value={checkoutObj?.typeOfScreen || ""}
                    onChange={(event) =>
                      setCheckoutObj((prevObj) => ({
                        ...prevObj,
                        typeOfScreen: event.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="specific_url"
                      control={
                        <Radio
                          className={classes.radio}
                          sx={{
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&.Mui-checked": {
                              color:
                                themeMode === "dark" ? "#D5DA00" : "#659500",
                            },
                          }}
                        />
                      }
                      label="Redirect donor to specific URL"
                    />
                    <FormControlLabel
                      value="Selected_screen"
                      selected
                      control={
                        <Radio
                          className={classes.radio}
                          sx={{
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&.Mui-checked": {
                              color:
                                themeMode === "dark" ? "#D5DA00" : "#659500",
                            },
                          }}
                        />
                      }
                      label="Show donor the selected ”Thank you” screen."
                      sx={{ mt: { md: 1, sm: 2, xs: 4 } }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item md={7} py={2} my={2}>
              <Typography className={classes.insert} mb={2}>
                Message on Thank you screen.
              </Typography>
              <FormControl className={classes.fullWidth}>
                <MyTextField
                  textMsg={
                    checkoutObj?.thankYouPageMessage || "Write message here"
                  }
                  handleTextChange={(value) =>
                    setCheckoutObj((prevObj) => ({
                      ...prevObj,
                      thankYouPageMessage: value,
                    }))
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid container mt={6} justifyContent="flex-start">
          <Grid item>
            <LoadingButton
              loading={false}
              loadingPosition="start"
              startIcon={<EyeIcon />}
              variant="outlined"
              className={classes.preview}
              onClick={handleClickOpen}
              sx={{
                color: themeMode === "dark" ? "#ffff" : "#000",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                display: mode === "Thankyou" ? "flex" : "none",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              Preview
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={handleSave}
              loading={false}
              loadingPosition="start"
              variant="outlined"
              className={classes.save}
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
              }}
            >
              Save Changes
            </LoadingButton>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <ThankYou />
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
export default CheckoutModal;
