import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  ImageList,
  ImageListItem,
  ListSubheader,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { category } from "../Stories/data";
import { useStyles } from "./useStyles";
import { useStylesLight } from "./useStylesLight";
import { useSelector } from "react-redux";

const ramadan = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramadan.svg";
const ramdanLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramdanLight.svg";
const blog = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/blog.svg";

const Gallery = () => {
  const classes = useStyles();
  const classesLight = useStylesLight();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [selected, setSelected] = useState(0);
  const handleBtnSelect = (value) => {
    setSelected(value);
  };

  if (themeMode === "dark") {
    return (
      <>
        <Grid
          className={classes.bkg}
          container
          sx={{
            padding: {
              md: "80px 0px",
              sm: "40px 0px ",
              xs: "100px 0px",
            },
          }}
        >
          <Grid
            className={classes.columnCenter}
            item
            md={11}
            sm={10}
            xs={11}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Grid
              id="blog"
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Grid item md={5.7} sm={5.7} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        fontWeight: "700",
                        width: "100%",
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 02
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        fontWeight: "500",
                        width: "100%",
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={6} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: { md: "40vh", sm: "40vh", xs: "50vh" },
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 03
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={6} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 04
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                md={5.7}
                sm={5.7}
                xs={12}
                my={2}
                sx={{
                  position: { md: "relative", sm: "relative", xs: "static" },
                  bottom: "65px",
                }}
              >
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: { md: "60vh", sm: "60vh", xs: "50vh" },
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      padding: "20px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 05
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={10}>
              <Grid
                sx={{
                  backgroundImage: `url('${ramadan}')`,
                  backgroundRepeat: "no-repeat",
                  //backgroundPosition: "80% center",
                  backgroundPosition: {md:'center center',sm:"center center",xs:"center 10%"},
                  backgroundSize: "100% ",
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  borderRadius: '30px',
                  width: '100%',
                  height: '60vh'
                }}
              >
                <Grid
                  item
                  md={12}
                  sx={{
                    backgroundColor: '#000000CC',
                    width: '100%',
                    borderRadius: '0 0 30px 30px', 
                     padding: {md:'20px 50px',sm:"20px 50px",xs:"15px 10px"}
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '700',
                      width: '100%',
                      fontSize: {md:'40px',sm:"34px",xs:"18px"},
                      textAlign: {md:'left',sm:"left",xs:"center"}
                 
                    }}
                  >
                    Empowering ramadan generosity
                  </Typography>
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: '500',
                      width: '100%',
                      fontSize: {md:'20px',sm:"17px",xs:"10px"},
                      textAlign: {md:'left',sm:"left",xs:"center"}
                    }}
                  >
                    Consideration for non-profit fundraising
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          <Grid item md={11} sm={10} xs={11} sx={{ padding: "15px" }}>
            <Grid
              sx={{
                backgroundImage: `url('${ramadan}')`,
                backgroundPosition: {
                  md: "center center",
                  sm: "center center",
                  xs: "40% 10%",
                },
                backgroundSize: "cover",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                borderRadius: "30px",
                width: "100%",
                height: "60vh",
              }}
            >
              <Grid
                item
                md={12}
                sx={{
                  backgroundColor: "#000000CC",
                  width: "100%",
                  borderRadius: "0 0 30px 30px",
                  padding: {
                    md: "20px 50px",
                    sm: "20px 50px",
                    xs: "15px 10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    width: "100%",
                    fontSize: { md: "40px", sm: "34px", xs: "18px" },
                    textAlign: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  Empowering ramadan generosity
                </Typography>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    width: "100%",
                    fontSize: { md: "20px", sm: "17px", xs: "10px" },
                    textAlign: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  Consideration for non-profit fundraising
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          className={classesLight.bkg}
          container
          sx={{
            padding: {
              md: "80px 0px",
              sm: "40px 0px ",
              xs: "100px 0px",
            },
          }}
        >
          <Grid
            className={classesLight.columnCenter}
            item
            md={11}
            sm={10}
            xs={11}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Grid
              id="blog"
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Grid item md={5.7} sm={5.7} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        fontWeight: "700",
                        width: "100%",
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 02
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        fontWeight: "500",
                        width: "100%",
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={6} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: { md: "40vh", sm: "40vh", xs: "50vh" },
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      padding: "20px",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 03
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={6} sm={6} xs={12} my={2}>
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: "50vh",
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      borderRadius: "0 0 30px 30px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 04
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                md={5.7}
                sm={5.7}
                xs={12}
                my={2}
                sx={{
                  position: { md: "relative", sm: "relative", xs: "static" },
                  bottom: "65px",
                }}
              >
                <Grid
                  sx={{
                    backgroundImage: `url('${blog}')`,
                    backgroundPosition: {
                      md: "center center",
                      sm: "center center",
                      xs: "center 10%",
                    },
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    borderRadius: "30px",
                    width: "100%",
                    height: { md: "60vh", sm: "60vh", xs: "50vh" },
                  }}
                >
                  <Grid
                    item
                    md={12}
                    sx={{
                      backgroundColor: "#000000CC",
                      width: "100%",
                      padding: "20px",
                      padding: {
                        md: "20px 50px",
                        sm: "20px 50px",
                        xs: "15px 10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        width: "100%",
                        fontSize: { md: "40px", sm: "34px", xs: "18px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Blog # 05
                    </Typography>
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        width: "100%",
                        fontSize: { md: "20px", sm: "17px", xs: "10px" },
                        textAlign: { md: "left", sm: "left", xs: "center" },
                      }}
                    >
                      Enter your sub heading here
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={11} sm={10} xs={11} sx={{ padding: "15px" }}>
            <Grid
              sx={{
                backgroundImage: `url('${ramdanLight}')`,
                backgroundPosition: {
                  md: "center center",
                  sm: "center center",
                  xs: "40% 10%",
                },
                backgroundSize: "cover",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                borderRadius: "30px",
                width: "100%",
                height: "60vh",
              }}
            >
              {/* <Grid
                item
                md={12}
                sx={{
                  backgroundColor: "#000000CC",
                  width: "100%",
                  borderRadius: "0 0 30px 30px",
                  padding: {
                    md: "20px 50px",
                    sm: "20px 50px",
                    xs: "15px 10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    width: "100%",
                    fontSize: { md: "40px", sm: "34px", xs: "18px" },
                    textAlign: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  Empowering ramadan generosity
                </Typography>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "500",
                    width: "100%",
                    fontSize: { md: "20px", sm: "17px", xs: "10px" },
                    textAlign: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  Consideration for non-profit fundraising
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
};
export default Gallery;
