import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from "react-redux";

const marquee = `
  @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  }
`;

const AnimatedGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#2C0439',
    padding: '15px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    '& .marquee-content': {
        display: 'flex',
        width: '200%', // Ensure it's twice the width of the container
        animation: 'marquee 10s linear infinite',
    },
    '& .marquee-item': {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 2rem',
    },
    [`${marquee}`]: {}, // Include the keyframes animation
}));

const items = [
    { id: 1, text: "We’re Hiring !" },
    { id: 2, text: "Join Our Team" },
];

const repeat = new Array(10).fill(null);



const Bulletin = () => {
    const themeMode = useSelector((state) => state.themeMode.mode);
    return (
        <AnimatedGrid item md={12} sm={12} sx={{ filter: themeMode === "dark" ? "" : "brightness(75%)",    filter: themeMode === "dark" ? "" : "brightness(500%)", }}>
            {repeat.map((_, repeatIndex) => (
                <div key={repeatIndex} className="marquee-content" style={{ width: "fit-content" }}>
                        {items.map((item, index) => (
                            <div key={`first-${index}`} className="marquee-item">
                                <Typography sx={{ color: themeMode === "dark" ? "#DBFF00" : "#566301", }}>
                                    {item.text}
                                </Typography>
                            </div>
                        ))}
                    </div>
                ))
            }
           
        </AnimatedGrid>
    );
};

export default Bulletin;
