import { gql } from "@apollo/client";


export const GET_CAMPAIGN_LIST = gql`
    query campaignList {
        campaigns{
            nodes {
                uuid
                title
                description
                status
            }
        }
    }
`;

export const CREATE_P2P_FUNDRAISE = gql`
    mutation CreatePeerToPeerFundraise($campaignUuid: ID!, $name: String!, $goalAmount: Float!, $goalCurrency: String!, $goalDate: String!, $message: String!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String ){
        createPeerToPeerFundraise(input:{campaignUuid: $campaignUuid, name: $name, goalAmount: $goalAmount, goalCurrency: $goalCurrency, goalDate: $goalDate, message: $message, firstName: $firstName, lastName: $lastName, email: $email, phoneNumber: $phoneNumber}){
            peerToPeerFundraise {
                name
                campaign {
                    uuid
                    title
                    description
                }
                user {
                    firstName
                    lastName
                    email
                    phoneNumber
                }
                goalDate
                goalAmount
                goalCurrency
                message
                createdAt
            }
        }
    }
`;

export const UPDATE_P2P_FUNDRAISE = gql`
    mutation UpdatePeerToPeerFundraise($uuid: ID!, $name: String!, $goalAmount: Float!, $goalCurrency: String!, $goalDate: String!, $message: String!){
        updatePeerToPeerFundraise(input:{uuid: $uuid, name: $name, goalAmount: $goalAmount, goalCurrency: $goalCurrency, goalDate: $goalDate, message: $message}){
            peerToPeerFundraise {
                name
                campaign {
                    uuid
                    title
                    description
                }
                user {
                    firstName
                    lastName
                    email
                    phoneNumber
                }
                goalDate
                goalAmount
                goalCurrency
                message
                createdAt
            }
        }
    }
`;