// import { Grid, Typography, Button } from "@mui/material";
 import { useStyles } from "../useStyles";
// import { payment } from "../../../data";
// import { useSelector } from "react-redux";

// const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/down.svg";

// export const Gateway = () => {
//   const classes = useStyles();
//   const themeMode = useSelector((state) => state.themeMode.mode);
//   return (
//     <Grid mx={{ md: 1, sm: 0, xs: 0 }} className={classes.box} container
//       sx={{
//         backdropFilter: {
//           md: "blur(11px)",
//           sm: "blur(11px)",
//           xs: "blur(11px)",
//         },
//         boxShadow: themeMode === "dark" ? "0px 0px 0px red" : "0px 8px 5px 0px #00000040",
//         backgroundColor: themeMode === "dark" ? "#0D0D0DE5" : "#FFF",
//         color: themeMode === "dark" ? "#FFF" : "#030303",
//         "&::before": {
//           content: "''",
//           position: "absolute",
//           inset: 0,
//           borderRadius: "15px",
//           padding: "1px",
//           background: themeMode === "dark" ? "linear-gradient(145deg, white, transparent)" :
//             "linear-gradient(180deg, white, transparent)",
//           WebkitMask:
//             "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
//           WebkitMaskComposite: "xor",
//           maskComposite: "exclude",
//         },
//       }}
//     >
//       <Grid className={classes.tag}>
//         <Button className={classes.down} sx={{}}>
//           <Typography className={classes.downBtn} mx={1}>
//             This Month{" "}
//           </Typography>
//           <img src={down} alt="" />
//         </Button>
//       </Grid>

//       <Grid className={classes.gateway} container>
//         <Grid mb={3} className={classes.spaceBtwn} p={1}>
//           <Typography className={classes.f16w700}>
//             Top Payment Gateways
//           </Typography>
//         </Grid>
//         <Grid className={classes.column}>
//           {payment.map((item, index) => (
//             <Grid key={index} item md={12} className={classes.p5}>
//               <Grid container className={classes.colorBox2}>
//                 <Grid item md={8} sm={8} xs={8} className={classes.fitContent}>
//                   <Typography mr={1}> #{item.id} </Typography>
//                   <img src={item.img} alt={item.way} className={classes.fit} />
//                 </Grid>
//                 <Grid item md={4} sm={4} xs={4} className={classes.rightCenter}>
//                   <Typography className={classes.f16w700}>
//                     ${item.value}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };



import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { payment } from '../../../data'
import { useSelector } from 'react-redux'

export const Gateway = () => {
  const classes = useStyles();
  const themeMode = useSelector(state => state.themeMode.mode);
  const [seeP2P, setSeeP2P] = useState(false);
  const handleSeeP2P = () => {
    setSeeP2P(!seeP2P);
  };
  return (
    <>
      <Grid mx={{ md: 1, sm: 0, xs: 0 }} className={classes.box} container
        sx={{
                backdropFilter: {
                  md: "blur(11px)",
                  sm: "blur(11px)",
                  xs: "blur(11px)",
                },
                boxShadow: themeMode === "dark" ? "0px 0px 0px red" : "0px 8px 5px 0px #00000040",
                backgroundColor: themeMode === "dark" ? "#0D0D0DE5" : "#FFFF",
                color: themeMode === "dark" ? "#FFF" : "#030303",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background: themeMode === "dark" ? "linear-gradient(145deg, white, transparent)" :
                    "linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
      >
        <Grid
          mb={2}
          sx={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '700',
              color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F'
            }}
          >
            Top Payment Gateways
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: "auto",
            maxHeight: {
              md: seeP2P ? "" : `calc(100vh - 465px)`,
              sm: seeP2P ? "" : `calc(100vh - 255px)`,
              xs: seeP2P ? "" : `calc(100vh - 180px)`,
            },
          }}
        >
          {payment?.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: '5px 10px' }}>
              <Grid
                container
                sx={{
                  border: '1px solid #9CCA3C',
                  borderRadius: '15px',
                  padding: '10px 15px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor:
                      themeMode === 'dark' ? '#2e3322' : '#e3fca7'
                  }
                }}
              >
                <Grid
                  item
                  md={8}
                  sm={8}
                  xs={8}
                  sx={{ display: 'flex', alignItem: 'center' }}
                >
                  <img
                    src={themeMode === "dark" ? item.img : item.light}
                    alt={item.way}
                    style={{ width: 'fit-content' }}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '700',
                      color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

