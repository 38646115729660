import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const exportss = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/exports.png";
const campaignBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaignBlur.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/export.svg";

const ExportsManagement = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/data-export" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg}
        container
        sx={{
          backgroundImage: `url('${campaignBlur}')`,
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
        }}
      >
        <Grid
          item
          md={6}
          sx={{
            paddingRight: { md: "70px", sm: "0px", xs: "0px" },
          }}
        >
          <Typography
            className={classes.title}
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            <Link to="/data-export" className={classes.link}>
              exports MANAGEMENT
            </Link>
          </Typography>
          <Typography
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontFamily: "Stolzl",
            }}
          >
            <Link to="/data-export" className={classes.link}>
              Enables easy export of data and analytics for external use or
              in-depth analysis, facilitating transparency and accountability.
            </Link>
          </Typography>
        </Grid>
        <Grid mt={{ md: 0, sm: 4, xs: 6 }}>
          <Link to="/data-export" className={classes.link}>
            {themeMode === "dark" ? (
              <img
                src={exportss}
                alt="Export Management"
                className={classes.fullWidth}
              />
            ) : (
              <img
                src={light}
                alt="Export Management"
                className={classes.fullWidth}
              />
            )}
          </Link>
        </Grid>
      </Grid>
    </Link>
  );
};
export default ExportsManagement;
