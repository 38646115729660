import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";

const supportBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/support blur.png";
const pay = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/pay.png";
const payment1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/payment1.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/payment.svg";
const pay1 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/pay1.svg";

const PaymentEcosystem = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/payment-ecosystem" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg2}
        container
        sx={{
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
          backgroundImage: `url('${supportBlur}')`,
          flexDirection: {
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          },
        }}
      >
        <Grid
          className={classes.index1}
          item
          md={6}
          mt={{ md: 0, sm: 4, xs: 6 }}
        >
          <Grid
            className={classes.index2}
            sx={{
              transform: {
                md: "translateX(20%)",
                sm: "translateX(10%)",
                xs: "translateX(0%) scale(0.85)",
              },
            }}
          >
            {themeMode === "dark" ? (
              <img src={pay} alt="payment Ecosystem" />
            ) : (
              <img src={light} alt="payment Ecosystem" />
            )}
          </Grid>
          <Grid
            className={classes.index1}
            sx={{
              zIndex: 1,
              transform: {
                md: "translateX(0%)",
                sm: "translateX(-30%)",
                xs: "translateX(-20%) scale(0.85)",
              },
            }}
          >
            {themeMode === "dark" ? (
              <img
                src={payment1}
                alt="payment Ecosystem"
                className={classes.eco}
              />
            ) : (
              <img src={pay1} alt="payment Ecosystem" className={classes.eco} />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            paddingLeft: { md: "70px", sm: "0px", xs: "0px" },
            textAlign: { md: "right", sm: "center", xs: "center" },
          }}
        >
          <Typography
            className={classes.title}
            sx={{
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            PAYment ecosystem MANAGEMENT
          </Typography>
          <Typography sx={{ fontFamily: "Stolzl" }}>
            Offers a comprehensive solution for managing multiple payment
            gateways and methods, ensuring secure and flexible donation
            transactions.
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};
export default PaymentEcosystem;
