import React, { useState, useEffect } from 'react'
import getSymbolFromCurrency from "currency-symbol-map";
import { makeStyles } from '@mui/styles'

const StatCounter = ({ amount, goalCurrency })=>{
  const [value,setValue]=useState(0)
  
   useEffect(() => {
    const interval = setInterval(() => {
      if (value < amount) {
        setValue((prevValue) => prevValue + 1);
      }
      else if(value > amount){
        setValue((prevValue) => prevValue - 1);
      }
    }, 0.0000001); 

    return () => clearInterval(interval);
  }, [value, amount]);

    return(
    <>
        <p style={{fontSize:"45px",fontWeight:"600",fontFamily:"Raleway"}}>
          {`${getSymbolFromCurrency(goalCurrency ? goalCurrency : 'USD')}`}<span id="statCounterValue">{value}</span>
        </p>
    </>
    )
}

export default StatCounter
