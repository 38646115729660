import React from "react";
import { Grid, Typography } from "@mui/material";

import {useStyles} from "./useStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";

const campaignBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/campaignBlur.png";
const p2p = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/p2p.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/p2p.svg"

const P2PCrowdsource = () => {
  const classes=useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/p2p-crowdsource" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
          backgroundImage: `url('${campaignBlur}')`,
        }}
      >
        <Grid
          item
          md={6}
          sx={{ paddingRight: { md: "70px", sm: "0px", xs: "0px" } }}
        >
          <Typography
            className={classes.title}
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            p2p crowdsource
          </Typography>
          <Typography
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontFamily: "Stolzl",
            }}
          >
            Leverages the power of peer-to-peer fundraising, enabling supporters
            to create personal fundraising pages linked to your cause.
          </Typography>
        </Grid>
        <Grid mt={{ md: 0, sm: 4, xs: 6 }}>
          {themeMode === "dark" ? (
            <img src={p2p} alt="P2P" className={classes.fullWidth} />
          ) : (
            <img src={light} alt="P2P" className={classes.fullWidth} />
          )}
        </Grid>
      </Grid>
    </Link>
  );
};
export default P2PCrowdsource;
