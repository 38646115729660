import React, { useState, useEffect } from 'react'

const TopCountries = ({ limitedCountries, title, textColor, labelColor, backgroundColor, borderSize, borderRadius, borderColor, shadow, themeMode }) => {

    return (
        <>
            <div 
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius  ? `${borderRadius}px` : '0px', 
                    border: borderSize || borderColor ? `${borderSize ? borderSize : '0'}px solid ${borderColor ? borderColor : 'transparent'}` : 'none' , 
                }}
            >
                <div style={{ fontSize: '28px', fontWeight: '600', padding: '8px', color: textColor ? textColor : '#FFF' }}>
                    {title ? title : 'Top Countries'}
                </div>
                {limitedCountries?.map((item, index) => (
                    <div index={index} style={{
                        width:"100%"
                    }}>
                        <div style={{ width: "100%" }}>

                            <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
                                <div style={{ padding: "15px", color: textColor ? textColor : '#FFF' }}>
                                    {item.id+1}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{textAlign:"left",fontWeight:"700"}}>
                                        <div style={{fontSize:"18px", color: textColor ? textColor : '#FFF'}}>
                                            {item.country}{" "}
                                        </div>
                                        <div style={{color: labelColor ? labelColor : '#FFF'}}>
                                            raised{" "}
                                            <span style={{ color: "#689900", fontWeight: "600", marginRight: "10px" }}>
                                                ${item.price}
                                            </span> 
                                        </div>
                                        
                                    </div>
                                    <div>{item.city}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default TopCountries




export const topCountry = [
    {
        id: 0,country:"United States",price:"100,000.00"
    },
    {
        id: 1, country: "Canada", price: "90,000.00"
    },
    {
        id: 2, country: "United Arab Emirates", price: "80,000.00"
    },
    {
        id: 3, country: "Germany", price: "50,000.00"
    },
    {
        id: 4, country: "Pakistan", price: "35,000.00"
    },
]