import axios from "axios";
import authHeader from "./auth-header";
import jwtInterceptor from "./auth-interceptor";

const API_URL = "https://api.accceleraise.com";

const register = async (
  email,
  firstName,
  lastName,
  organization,
  organizationCountry,
  jobTitle,
  phoneNumber,
  password,
  passwordConfirmation
) => {
  try {
    const response = await axios.post(API_URL + '/auth/sign_up', {
      email,
      first_name: firstName,
      last_name: lastName,
      organization,
      organization_country: organizationCountry,
      job_title: jobTitle,
      phone_number: phoneNumber,
      password,
      password_confirmation: passwordConfirmation,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (currentPassword, passwordConfirmation, password) => {
  try {
    const response = await jwtInterceptor.put(API_URL + '/auth/password', {
      current_password: currentPassword,
      password_confirmation: passwordConfirmation,
      password,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const resetForgotPassword = async (resetPasswordToken, passwordConfirmation, password) => {
  try {
    const response = await jwtInterceptor.put(API_URL + '/auth/password', {
      token: resetPasswordToken,
      password_confirmation: passwordConfirmation,
      password,
    });
    let userData = response?.data?.data;
    userData['access-token'] = response.headers['access-token'];
    userData['client'] = response.headers['client'];
    userData['uid'] = response.headers['uid'];
    localStorage.setItem("user", JSON.stringify(userData));
    return response;
  } catch (error) {
    throw error;
  }
};

const recoverPassword = async (email, redirectUrl) => {
  try {
    const response = await axios.post(`${API_URL}/auth/password`, null, {
      params: {
        email,
        redirect_url: redirectUrl,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteRegistration = async () => {
  try {
    const response = await jwtInterceptor.delete(API_URL + '/auth');
    localStorage.removeItem("user");
    return response;
  } catch (error) {
    localStorage.removeItem("user");
    throw error; 
  }
};

const login = async (email, password) => {
  try {
    const response = await axios.post(API_URL + '/auth/sign_in', {
      email,
      password,
    });
    let userData = response?.data?.data;
    userData['access-token'] = response.headers['access-token'];
    userData['client'] = response.headers['client'];
    userData['uid'] = response.headers['uid'];
    localStorage.setItem("user", JSON.stringify(userData));
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

const login_by_token = async (token) => {
  try {
    const response = await axios.post(API_URL + `/api/v1/passwords/${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const change_password_by_token = async (token, password, password_confirmation) => {
  try {
    const response = await axios.post(API_URL + `/api/v1/passwords/${token}`, {
      password,
      password_confirmation,
    });
    if(response?.status === 200){
      let userData = response?.data?.user;
      userData['access-token'] = response.headers['access-token'];
      userData['client'] = response.headers['client'];
      userData['uid'] = response.headers['uid'];
      localStorage.setItem("user", JSON.stringify(userData));
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const logout = async () => {
  try {
    const response = await jwtInterceptor.delete(API_URL + '/auth/sign_out');
    localStorage.removeItem("user");
    return response;
  } catch (error) {
    localStorage.removeItem("user");
    throw error;
  }
};

export const AuthService = {
  register,
  resetPassword,
  resetForgotPassword,
  recoverPassword,
  deleteRegistration,
  login,
  login_by_token,
  change_password_by_token,
  logout,
};
