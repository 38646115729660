import './useStyle.css'

const DonationLevel = ({ backgroundColor, textColor, accentTextColor, btnSize, borderColor, shadow, labelColor, btnColor, btnRadius, btnBorderSize, btnBorderRadius, btnBorderColor, themeMode }) => {
  return (
    <>
      <div style={{ width: '100%', border: `1px solid ${borderColor ? borderColor : '#FFF'}`,borderRadius: btnBorderRadius ? `${btnBorderRadius}px` : '10px', boxShadow: themeMode==="dark" && shadow ? '2px 2px 5px #FFF' : themeMode === "light" && shadow ? '2px 2px 5px #000' :'none', padding:"35px",margin:"20px 0px" }}>
        <p style={{fontWeight:"800"}}>Supporter</p>
        <h1 style={{color: textColor ? textColor : "#A4B200",fontSize:"55px"}}>$25</h1>
        <p style={{color: textColor ? textColor : "#FFF"}}>Make a difference today</p>
        <button class='proceed' id='proceed-btn' style={{color: labelColor ? labelColor : "#FFF", border: `${btnBorderSize}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}`, borderRadius: btnBorderRadius ? `${btnBorderRadius}px` : '10px', backgroundColor: btnColor ? btnColor : '#A4B200', fontSize: `${btnSize}px`}}>
          Donate
        </button>
      </div>
    </>
  )
}
export default DonationLevel
