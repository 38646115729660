import { createSlice } from '@reduxjs/toolkit';

const spinnerLoaderSlice = createSlice({
  name: 'spinnerLoader',
  initialState: {Loading: false},
  reducers: {
    setSpinnerLoading: (state, action) => {
        state.Loading = action.payload;
    },
  },
});

export const { setSpinnerLoading } = spinnerLoaderSlice.actions;
export default spinnerLoaderSlice.reducer;