import { Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useStyles } from "./useStyles";
// import SearchArticles from "./SearchArticles";
import { useSelector } from "react-redux";
import Bulletin from "./Bulletin";

const Hero = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
//        container
        sx={{
          padding: {
            md: "130px 0px 20px",
            sm: "130px 0px 20px",
            xs: "100px 0px 30px",
          },

          filter: themeMode === "dark" ? "" : "brightness(500%)",
        }}
      >
        <Grid
          id="help"
          className={classes.columnCenter}
          item
          md={12}
          sm={10}
          xs={11}
          mb={6}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" },position:"relative" }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontWeight: themeMode === "dark" ? "700" : "300",
              fontSize: { md: "22px", sm: "16px", xs: "16px" },
              fontFamily: "Stolzl",
            }}
          >
            Careers{" "}
          </Typography>
          <Typography
            className={classes.upper700}
            sx={{
              //fontWeight:"900",
              fontWeight: themeMode === "dark" ? "700" : "300",
              fontSize: { md: "56px", sm: "36px", xs: "30px" },
              fontFamily: "Stolzl",
            }}
          >
            Work With Us
          </Typography>
          <Typography my={3} mb={5}
            sx={{
              //fontWeight:"900",
              fontWeight: "400",
              fontSize: { md: "22px", sm: "16px", xs: "16px" },
              fontFamily: "Stolzl",
              filter: themeMode === "dark" ? "" : "brightness(50%)",
            }}
          >
            Interested in joining our team? See our open jobs below
          </Typography>
        </Grid>

        {/* <Grid mb={0}  sx={{
            width:"100%",
          //  transform:{md:"translateY(40%)",sm:"translateY:(40%)"},
            position:"absolute",bottom:"0px"
            }}>
          <Bulletin />
        </Grid> */}
      </Grid>
    </>
  );
};
export default Hero;
