import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import SearchBar from "../SearchBar";
import { tableCampaign, campaignRow } from "../data";
import { useMutation, useQuery } from "@apollo/client";
import CampaignPop from "./CampaignPop/CampaignPop";
import {
  GET_CAMPAIGN,
  UPDATE_CAMPAIGN_ARCHIVE,
  CREATE_EXPORT,
  GET_STATS,
} from "./Query";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import Paper from "@mui/material/Paper";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
import {
  adjustNegativeMonths,
  downloadFile,
} from "../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePickerComp } from "../DatePickerComp.jsx";
import DocumentTitle from "../../../../DocumentTitle.js";
import PreviewDialog from "./PreviewScreens/PreviewDialog.jsx";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const dots =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots.svg";
const dotswhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dotswhite.svg";
const dotsgreen =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots green.svg";
const dateIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";

const Campaign = ({ showPopupCallback }) => {
  DocumentTitle("Campaigns - Acceleraise");

  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [searchQuery, setSearchQuery] = useState(null);
  const [filterCampaign, setFilterCampaign] = useState({
    archived: false,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [sortCampaign, setSortCampaign] = useState({
    name: "TITLE",
    order: "ASCENDING",
  });
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [pageStats, setPageStats] = useState([]);

  const { data: pagesStats } = useQuery(GET_STATS, {
    onCompleted: (result) => {
      if (result?.pageStats) {
        setPageStats(result?.pageStats?.campaignPage);
      }
    },
  });

  const [campaignRows, setCampaignRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [hoveredRows, setHoveredRows] = useState({});

  const handleHover = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: false,
    }));
  };

  const [CreateExport, { loading: exportLoading, error: exportError }] =
    useMutation(CREATE_EXPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("export request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("export request was successful!"));
      },
    });

  const [
    UpdateCampaignArchive,
    {
      loading: updateCampaignArchiveLoading,
      error: updateCampaignArchiveError,
    },
  ] = useMutation(UPDATE_CAMPAIGN_ARCHIVE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      setSelectedRow(null);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      console.log("Mutation Completed:", data);
      const newCampaignRows = campaignRows.filter(
        (row) => row.uuid !== data?.archiveCampaign?.campaign?.uuid
      );
      setCampaignRows(newCampaignRows);
      setSelectedRow(null);
      dispatch(success("your request was successful!"));
    },
  });
  const updateCampaignRowState = (result) => {
    setCampaignRows(result?.campaigns?.nodes);
    const pageInfo = result?.campaigns?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };
  const {
    data: campaignData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_CAMPAIGN, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      query: searchQuery,
      archived: filterCampaign?.archived,
      sortColumn: sortCampaign?.name,
      sortOrder: sortCampaign?.order,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      updateCampaignRowState(result);
    },
  });
  useEffect(() => {
    refetch({
      variables: {
        archived: filterCampaign.archived,
      },
    }).then((result) => {
      updateCampaignRowState(result?.data);
    });
  }, [filterCampaign.archived, refetch]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setCampaignRows([...campaignRows, ...result?.data?.campaigns?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor: result?.data?.campaigns?.pageInfo?.startCursor,
          endCursor: result?.data?.campaigns?.pageInfo?.endCursor,
          hasNextPage: result?.data?.campaigns?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleClickArchive = async () => {
    const variables = {
      uuid: selectedRow?.uuid,
      isArchive: selectedRow.archivedAt ? false : true,
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await UpdateCampaignArchive({ variables });
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleExport = async () => {
    const variables = {
      pageName: "Campaign",
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateExport({ variables });
      if (result?.data?.createExport?.export?.success) {
        downloadFile(result?.data?.createExport?.export?.fileUrl);
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        clasName={classes.container}
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.fw600}
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "32px" },
              textAlign: { md: "left", sm: "left", xs: "center" },
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Campaigns
          </Typography>
        </Grid>

        <Grid
          item
          className={classes.center}
          sx={{
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "space-between",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            xs={10}
            className={classes.flexStart}
            sx={{
              width: {
                md: "fit-content",
                sm: "fit-content",
                xs: "fit-content",
              },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              flexWrap: "wrap",
              justifyContent: {
                md: "flex-start",
                sm: "flex-start",
                xs: "center",
              },
            }}
          >
            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElDateFilter(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  <CalendarMonthIcon
                    sx={{
                      color: "#9CCA3C",
                      marginRight: { md: "10px", sm: "10px", xs: "5px" },
                    }}
                  />
                  {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                  {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElDateFilter}
              open={Boolean(anchorElDateFilter)}
              onClose={(e) => {
                setAnchorElDateFilter(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  background: themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                  width: "fit-content",
                  border: "2px solid #ABFC00",
                  borderRadius: "40px",
                },
              }}
            >
              <MenuItem>
                <Grid container spacing={2} alignItems="center">
                  {/* <Grid item xs={6} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dayjs(dateRange?.startDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            startDate: date,
                          }))
                        }
                        maxDate={dateRange?.endDate ? dayjs(dateRange?.endDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <span>-</span>
                  </Grid>
                  <Grid item xs={5} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dayjs(dateRange?.endDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            endDate: date,
                          }))
                        }
                        minDate={dateRange?.startDate ? dayjs(dateRange?.startDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                  <Grid item md={2} xs={12}>
                    <DatePickerComp
                      startDate={dateRange?.startDate}
                      endDate={dateRange?.endDate}
                      displayDate={setDateRange}
                    />{" "}
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>

            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElFilter(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Filters
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>
            <Menu
              anchorEl={anchorElFilter}
              open={Boolean(anchorElFilter)}
              onClose={(e) => {
                setAnchorElFilter(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                  border: "1px solid #689900",
                  borderRadius: "10px",
                },
              }}
            >
              <Paper
                style={{
                  maxHeight: 350,
                  maxWidth: 250,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor:
                    themeMode === "dark"
                      ? "#8ED003CC #0F0F0F"
                      : "#8ED003 #E2E2E2",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterCampaign({ archived: false });
                  }}
                  selected={filterCampaign?.archived === false}
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    setAnchorElFilter(null);
                    setFilterCampaign({ archived: true });
                  }}
                  selected={filterCampaign?.archived === true}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontWeight: "600",
                    "&:hover": {
                      color: "#689900",
                    },
                    "&:focus": {
                      color: "#689900",
                    },
                  }}
                >
                  Archived
                </MenuItem>
              </Paper>
            </Menu>
            <Button
              className={classes.filterBtn}
              disableRipple
              sx={{
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              onClick={(e) => {
                setAnchorElSort(e.currentTarget);
              }}
            >
              <Grid container className={classes.noWrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    fontSize: { md: "12px", sm: "12px", xs: "13px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Sort
                  <img src={down} alt="" style={{ marginLeft: "10px" }} />
                </Typography>
              </Grid>
            </Button>

            <Menu
              anchorEl={anchorElSort}
              open={Boolean(anchorElSort)}
              onClose={(e) => {
                setAnchorElSort(null);
              }}
              sx={{
                "& .MuiPaper-root": {
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                  border: "1px solid #689900",
                  borderRadius: "10px",
                },
              }}
            >
              <Paper
                style={{
                  maxHeight: 350,
                  maxWidth: 250,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor:
                    themeMode === "dark"
                      ? "#8ED003CC #0F0F0F"
                      : "#8ED003 #E2E2E2",
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "TITLE",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "TITLE" &&
                    sortCampaign?.order === "ASCENDING"
                  }
                >
                  Title A to Z
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "TITLE",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "TITLE" &&
                    sortCampaign?.order === "DESCENDING"
                  }
                >
                  Title Z to A
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "DESCRIPTION",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "DESCRIPTION" &&
                    sortCampaign?.order === "ASCENDING"
                  }
                >
                  Description A to Z
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "DESCRIPTION",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "DESCRIPTION" &&
                    sortCampaign?.order === "DESCENDING"
                  }
                >
                  Description Z to A
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "CREATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "CREATED_AT" &&
                    sortCampaign?.order === "ASCENDING"
                  }
                >
                  Oldest Created
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "CREATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "CREATED_AT" &&
                    sortCampaign?.order === "DESCENDING"
                  }
                >
                  Recently Created
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "UPDATED_AT",
                      order: "ASCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "UPDATED_AT" &&
                    sortCampaign?.order === "ASCENDING"
                  }
                >
                  Oldest Updated
                </MenuItem>
                <MenuItem
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                  }}
                  className={classes.menuItem}
                  onClick={(e) => {
                    setAnchorElSort(null);
                    setSortCampaign({
                      name: "UPDATED_AT",
                      order: "DESCENDING",
                    });
                  }}
                  selected={
                    sortCampaign?.name === "UPDATED_AT" &&
                    sortCampaign?.order === "DESCENDING"
                  }
                >
                  Recently Updated
                </MenuItem>
              </Paper>
            </Menu>
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}
          >
            <SearchBar specific="Search Campaign" handleSearch={handleSearch} />
          </Box>
          <Box
            item
            xs={1}
            my={{ xs: 1 }}
            sx={{
              display: { md: "none", sm: "none", xs: "flex" },
              width: "100%",
              justifyContent: "center",
            }}
          >
            <SearchBar specific="Search Campaign" handleSearch={handleSearch} />
          </Box>
        </Grid>

        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            md={10}
            sm={9}
            xs={12}
            my={3}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
              alignItems: "center",
            }}
          >
            <Grid
              mr={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              className={classes.box}
              item
              container
              // md={5.8}
              // sm={5.8}
              //xs={12}
              sx={{
                width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                padding: { md: "25px", sm: "25px 15px", xs: "10px 15px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item mx={{ md: 0, sm: 0, xs: 3.5 }}>
                <Typography
                  className={classes.f12}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  Most Collection By Campaign
                </Typography>
                <Typography
                  className={classes.fs28}
                  my={1}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  ${" "}
                  {pageStats?.most_collection?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography
                  className={classes.neon}
                  sx={{
                    color: "#00FF0A",
                    color: themeMode === "dark" ? "#00FF0A" : "#9CCA3C",
                  }}
                >
                  {pageStats?.most_collection_campaign}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              ml={{ md: 1, sm: 1, xs: 0 }}
              my={{ md: 0, sm: 0, xs: 1 }}
              item
              container
              // md={5.8}
              // sm={5.8}
              // xs={12}
              className={classes.box}
              sx={{
                width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                padding: { md: "25px", sm: "25px 15px", xs: "10px 15px" },
                marginTop: { md: "0px", sm: "0px", xs: "10px" },
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid item md={8}>
                <Typography
                  className={classes.f12}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  Trending Campaign Today
                </Typography>
                <Typography
                  className={classes.fs28}
                  my={1}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  ${" "}
                  {pageStats?.trending_amount_today?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
                <Typography
                  className={classes.neon}
                  sx={{ color: themeMode === "dark" ? "#00FF0A" : "#9CCA3C" }}
                >
                  {pageStats?.trending_campaign_today}
                </Typography>
              </Grid>
              <Grid className={classes.colEnd} item md={4}>
                <Typography
                  className={classes.dullText}
                  sx={{ color: themeMode === "dark" ? "#FFFFFF80" : "#030303" }}
                >
                  Collected Today
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={2}
            sm={3}
            xs={12}
            my={3}
            sx={{
              display: "flex",
              flexDirection: { md: "column", sm: "column", xs: "row" },
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                className={classes.fs20}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                Quick Links
              </Typography>
              <Typography
                className={classes.link}
                onClick={showPopupCallback}
                sx={{ textAlign: "right" }}
              >
                3 Step to Raise
              </Typography>
            </Grid>
            <Grid>
              <Button
                disableRipple
                className={classes.clrButton}
                onClick={() => {
                  handleExport();
                }}
                sx={{
                  padding: {
                    md: "10px 30px",
                    sm: "10px 15px",
                    xs: "8px 10px",
                  },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid container className={classes.nowrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",

                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      textWrap: "nowrap",
                      fontSize: "16px",

                      width: "100%",
                    }}
                  >
                    Export List
                    <img
                      src={themeMode === "dark" ? download : downloadLight}
                      alt=""
                      style={{ marginLeft: "10px" }}
                    />
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TableContainer
            className={classes.tableContainer}
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
            }}
            id="table-container"
          >
            <Table
              className={classes.fullWhite}
              aria-label="simple table"
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
              }}
              stickyHeader
            >
              <TableHead>
                <TableRow
                  className={classes.white}
                  sx={{
                    borderBottom: `2px solid ${
                      themeMode === "dark" ? "#FFFFFF" : "#000000"
                    }`,
                  }}
                >
                  {tableCampaign?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        backgroundColor:
                          themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0", // Apply bottom left radius to the first item
                        borderBottomRightRadius:
                          index === tableCampaign.length - 1 ? "15px" : "0", // Apply bottom right radius to the last item
                        color:
                          index === tableCampaign.length - 1 &&
                          themeMode === "dark"
                            ? "#FFFF"
                            : "#030303",
                        borderRight:
                          index === tableCampaign.length - 1
                            ? "0px"
                            : undefined,
                      }}
                    >
                      <Typography
                        className={classes.title}
                        sx={{
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignRows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      align="center"
                      className={classes.white}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        No Records
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  campaignRows?.map((row, index) => (
                    <TableRow
                      key={index}
                      className={classes.row}
                      // component={Link}
                      // to={`/dashboard/campaign-overview/${row?.uuid}`}
                      sx={{
                        textDecoration: "none",
                        zIndex: "10",
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        className={classes.titleCell}
                        //  component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        md={2}
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography
                          className={classes.campaign}
                          sx={{
                            fontSize: { md: "16px", sm: "13px", xs: "12px" },
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                          }}
                        >
                          {row.title?.length > 18
                            ? `${row.title?.substring(0, 18)}...`
                            : row.title}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.rasiedCell}
                        //            component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography className={classes.colorData}>
                          $ {row?.raised?.toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell
                        //    component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography className={classes.colorData}>
                          {row?.donations?.nodes?.length}
                        </Typography>
                      </TableCell>
                      <TableCell
                        //component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography className={classes.colorData}>
                          {row?.minimumRecurringDonation || "0"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        //component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography className={classes.colorData}>
                          {row?.donations?.nodes?.length
                            ? dayjs(
                                row?.donations?.nodes[
                                  row?.donations?.nodes?.length - 1
                                ]?.updatedAt
                              )?.format("YYYY-MM-DD")
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        //component="th"
                        component={Link}
                        to={`/dashboard/campaign-overview/${row?.uuid}/${row?.title}`}
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Typography className={classes.colorData}>
                          {row?.status === "publish"
                            ? "Published"
                            : "Unpublished"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          width: "fit-content !important",
                          ...(index === tableCampaign.length - 1 && {}),
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "fit-content",
                            zIndex: "30 !important",
                          }}
                        >
                          <Button
                            className={classes.dots}
                            disableRipple
                            // sx={{ zIndex: "99 !important" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAnchorEl(e.currentTarget);
                              setSelectedRow(row);
                            }}
                          >
                            <img
                              src={
                                hoveredRows[index] && themeMode === "dark"
                                  ? dotswhite
                                  : hoveredRows[index] && themeMode === "light"
                                  ? dotsgreen
                                  : dots
                              }
                              alt=""
                              onMouseOver={() => handleHover(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                              style={{ borderRadius: "50%" }}
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => {
                              setAnchorEl(null);
                            }}
                            //PaperComponent={Paper}
                            className={classes.paperLight}
                            sx={{
                              "& .MuiPaper-root": {
                                color: themeMode === "dark" ? "#FFF" : "#111",
                                backgroundColor:
                                  themeMode === "dark" ? "#111" : "#FFF",
                              },
                            }}
                          >
                            <MenuItem
                              component={Link}
                              to={`/dashboard/campaign-overview/${selectedRow?.uuid}/${row?.title}`}
                              onClick={(e) => {
                                setAnchorEl(null);
                              }}
                            >
                              <EditIcon
                                className={classes.mr1}
                                fontSize="small"
                              />
                              <Typography ml={1}>Edit</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                handleClickArchive();
                              }}
                            >
                              <ArchiveIcon fontSize="small" />
                              <Typography ml={1}>
                                {selectedRow?.archivedAt
                                  ? "Undo-Archived"
                                  : "Archived"}
                              </Typography>
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default Campaign;
