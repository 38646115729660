import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Select, Typography, MenuItem, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setScreen } from "../../../../../store/campaignSlice";
import FormControl from "@mui/material/FormControl";

export const PreviewMenu = ({ handleClose }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const selectedScreen = useSelector((state) => state.campaign.screen);

  const [device, setDevice] = useState("Desktop");
  const handleChangeDevice = (event) => {
    setDevice(event.target.value);
  };

  const [language, setLanguage] = useState("eng-us");
  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const [country, setCountry] = useState("usa");
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  return (
    <div
      className={
        themeMode === "dark" ? "preview-settings" : "preview-settings-light"
      }
    >
      <div className="p-8">
        <div
          className="flex space-x-2 items-center cursor-pointer"
          onClick={() => {
            handleClose();
          }}
        >
          <KeyboardBackspaceIcon />
          <Typography className="preview-settings-text">
            Back to Settings
          </Typography>
        </div>
        <Typography className="preview-settings-heading py-4">
          Preview
        </Typography>
        <div className="md:flex w-full   justify-between items-center">
          <Typography className="preview-settings-text w-full">
            Device
          </Typography>
          <div className="w-full flex justify-end">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={device}
              onChange={(e) => handleChangeDevice(e)}
              size="small"
              className="w-full preview-settings-menu"
            >
              <MenuItem className="preview-settings-menu" value={"Desktop"}>
                Desktop
              </MenuItem>
              <MenuItem className="preview-settings-menu" value={"Tablet"}>
                Tablet
              </MenuItem>
              <MenuItem className="preview-settings-menu" value={"Mobile"}>
                Mobile
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className="md:flex w-full justify-between items-center py-4">
          <Typography className="preview-settings-text w-full">
            Language
          </Typography>
          <div className="w-full flex justify-end">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              onChange={(e) => handleChangeLanguage(e)}
              size="small"
              className="w-full preview-settings-menu"
            >
              <MenuItem
                className="w-full preview-settings-menu"
                value={"eng-us"}
              >
                English (USA)
              </MenuItem>
              <MenuItem
                className="w-full preview-settings-menu"
                value={"eng-uk"}
              >
                English (UK)
              </MenuItem>
            </Select>
          </div>
        </div>
        <div className="md:flex w-full  justify-between items-center">
          <Typography className="preview-settings-text w-full">
            Country
          </Typography>
          <div className="w-full">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={country}
              onChange={(e) => handleChangeCountry(e)}
              size="small"
              className="w-full preview-settings-menu"
            >
              <MenuItem className="w-full preview-settings-menu" value={"usa"}>
                USA
              </MenuItem>
              <MenuItem className="w-full preview-settings-menu" value={"uk"}>
                UK
              </MenuItem>
            </Select>
          </div>
        </div>
      </div>

      <hr />
      <div className="p-8 text-left">
        <Typography className="preview-settings-heading pb-4">
          SCREENS
        </Typography>
        <div className="flex flex-col space-y-4">
          <Button
            variant={selectedScreen == 1 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(1))}
            color="inherit"
            className="text-left"
          >
            <Typography className="preview-settings-button">
              Money-based Donation
            </Typography>
          </Button>
          <Button
            variant={selectedScreen == 2 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(2))}
            color="inherit"
          >
            <Typography className="preview-settings-button text-left">
              Pre-donation upsell{" "}
            </Typography>
          </Button>
          <Button
            variant={selectedScreen == 3 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(3))}
            color="inherit"
          >
            <Typography className="preview-settings-button">
              Payment options{" "}
            </Typography>
          </Button>
          <Button
            variant={selectedScreen == 4 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(4))}
            color="inherit"
          >
            <Typography className="preview-settings-button">
              Personal info{" "}
            </Typography>
          </Button>
          <Button
            variant={selectedScreen == 5 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(5))}
            color="inherit"
          >
            <Typography className="preview-settings-button capitalize">
              Post-donation upsell{" "}
            </Typography>
          </Button>
          <Button
            variant={selectedScreen == 6 ? "contained" : "text"}
            onClick={() => dispatch(setScreen(6))}
            color="inherit"
          >
            <Typography className="preview-settings-button ">
              Thank You (Money-based){" "}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
