export const data = [
  {
    id: 1,
    name: "Status",
    value: "Successful",
  },
  {
    id: 2,
    name: "Donor",
    value: "JayTar",
  },
  {
    id: 3,
    name: "Campaign",
    value: "Help Gaza",
  },
  {
    id: 4,
    name: "Donation Date",
    value: "11 Dec 2023",
  },
  {
    id: 5,
    name: "Frequency",
    value: "One-Time",
  },
  {
    id: 6,
    name: "Payment Method",
    value: "Credit/Debit Card",
  },
];
export const fees = [
  {
    id: 1,
    name: "Donation Amount",
    value: "Rs. 5000",
  },
  {
    id: 2,
    name: "Platform Fee",
    value: "Rs. 280",
  },
  {
    id: 3,
    name: "Payment Processing Fee",
    value: "Rs. 210",
  },
  {
    id: 4,
    name: "Payout Amount",
    value: "$ 15.96",
  },
  {
    id: 5,
    name: "Payment Processor",
    value: "Stripe",
  },
  {
    id: 6,
    name: "Fee Covered",
    value: "Covered",
  },
  {
    id: 7,
    name: "Donation Amount",
    value: "Rs. 5000",
  },
];

export const insight = [
  {
    id: 1,
    name: "IP Address",
    value: "73.72.11.19",
  },
  {
    id: 2,
    name: "IP Geolocation",
    value: "USA",
  },
  {
    id: 3,
    name: "Browser",
    value: "Safari",
  },
  {
    id: 4,
    name: "Device",
    value: "Mobile",
  },
  {
    id: 5,
    name: "Suggested Cover Fees",
    value: "Used",
  },
  {
    id: 6,
    name: "Suggested Amount",
    value: "Not Used",
  },
  {
    id: 7,
    name: "Suggested Frequency",
    value: "Used",
  },

  {
    id: 8,
    name: "Switch to Recurring",
    value: "Not Used",
  },
];
export const donor = [
  {
    id: 1,
    name: "Name",
    value: "JayTar",
  },
  {
    id: 2,
    name: "Email",
    value: "jaytar@gmail.com",
  },
  {
    id: 3,
    name: "Phone",
    value: "0312 1234567",
  },
  {
    id: 4,
    name: "Mailing Address",
    value: "87th St.,\n BridgeView, USA",
  },
];

export const comments = [];

export const emails = [
  {
    id: 1,
    sent: "Feb 12, 2021, 12:25 PM",
    subject: "Thank you for the support",
  },
];

export const status = [
  {
    id:1,
    head:"Status",
    ans:"Active"
  },
  {
    id:2,
    head:"Goal",
    ans:"$1000"
  },
  {
    id:3,
    head:"Raised",
    ans:"$700"
  },
  {
    id:4,
    head:"Donation #",
    ans:"100"
  },
]

export const graphData = [
  {
    value: 0,
    end_time: "2023-08-01T00:00:00", // August 5, 2023
  },
  {
    value: 0,
    end_time: "2023-08-05T00:00:00", // August 18, 2023
  },
  {
    value: 12,
    end_time: "2023-08-13T00:00:00", // August 10, 2023
  },
  {
    value: 12,
    end_time: "2023-08-13T00:00:00", // August 3, 2023
  },
  {
    value: 20,
    end_time: "2023-08-17T00:00:00", // August 12, 2023
  },
  {
    value: 20,
    end_time: "2023-08-17T00:00:00", // August 12, 2023
  },
  {
    value: 35,
    end_time: "2023-08-20T00:00:00", // August 12, 2023
  },
  {
    value: 44,
    end_time: "2023-08-30T00:00:00", // August 12, 2023
  },
  {
    value: 56,
    end_time: "2023-09-06T00:00:00", // August 12, 2023
  },
  {
    value: 56,
    end_time: "2023-09-08T00:00:00", // August 12, 2023
  },
  {
    value: 69,
    end_time: "2023-09-12T00:00:00", // August 12, 2023
  },
  {
    value: 88,
    end_time: "2023-09-22T00:00:00", // August 12, 2023
  },
];




//Supporter
export const p2pDonorTable = [
  {
    id: 1,
    title: "Name",
  },
  {
    id: 2,
    title: "Email",
  },
  {
    id: 3,
    title: "Role",
  },
  
];

const createDonorData = (
  name,
  email,
  role
) => {
  return {
  name,
  email,
  role
  };
};
export const donorRow = [
  createDonorData(
    "Nabeel Ahmed",
    "nabeelahmed@gmail.com",
    "Member",
  ),
  createDonorData(
    "Altaf Hussain",
    "bhai@gmail.com",
    "Member",
    
  ),
  createDonorData(
    "Babar Azam",
    "bobbyajum@gmail.com",
    "Member",
  ),
  createDonorData(
    "Syed Anab Imam",
    "syedanab@gmail.com",
    "Admin",
  ),
];
