import { gql } from "@apollo/client";

export const GENERAL_SEARCH = gql`
    query GeneralSearch($searchQuery: String){
        generalSearch(searchQuery: $searchQuery){
            uuid
            modelName
            attributes
            campaignUuid
        }
    }
`;

const pagesInfo = [
    {
      id: 1,
      title: 'Campaign',
      link: '/dashboard/campaign-settings',
      type: "specific"
    },
    {
      id: 2,
      title: 'PeerToPeerFundraise',
      link: '/dashboard/p2p',
      type: "general"
    },
    {
      id: 3,
      title: 'ThankYouPage',
      link: '/dashboard/checkout-modal',
      type: "specific"
    },
    {
      id: 4,
      title: 'PeerToPeer',
      link: '/dashboard/peer-to-peer',
      type: "specific"
    },
    {
      id: 5,
      title: 'Goal',
      link: '/dashboard/peer-to-peer',
      type: "specific"
    },
    {
      id: 6,
      title: 'EmailSetting',
      link: '/dashboard/campaign-email',
      type: "specific"
    },
    {
      id: 7,
      title: 'EmailBody',
      link: '/dashboard/campaign-email',
      type: "specific"
    },
    {
      id: 8,
      title: 'EmailLog',
      link: '/dashboard/campaign-email',
      type: "specific"
    },
    {
      id: 9,
      title: 'CampaignTemplate',
      link: '/dashboard/publish-campaign',
      type: "specific"
    },
    {
      id: 10,
      title: 'AskPage',
      link: '/dashboard/checkout-modal',
      type: "specific"
    },
    {
      id: 11,
      title: 'RecurringDonation',
      link: '/dashboard/recurring',
      type: "general"
    },
    {
      id: 12,
      title: 'Report',
      link: '/dashboard/past-reports',
      type: "general"
    },
    {
      id: 13,
      title: 'Report',
      link: '/dashboard/past-reports',
      type: "general"
    },
    {
      id: 14,
      title: 'PeerToPeerFundraise',
      link: '/dashboard/elements',
      type: "general"
    },
    {
      id: 15,
      title: 'Element',
      link: '/dashboard/elements',
      type: "general"
    },
    {
      id: 16,
      title: 'Donation',
      link: '/dashboard/donation',
      type: "specific"
    },
    {
      id: 17,
      title: 'Donor',
      link: '/dashboard/donor',
      type: "specific"
    },
];


export {
  pagesInfo,
};