import React, { useEffect, useState } from "react";
import { Grid, Input, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import "react-quill/dist/quill.snow.css";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    marginTop: theme.spacing(2),
  },
}));

const MyTextField = ({ textMsg, handleTextChange }) => {
  const [text, setText] = useState(textMsg);

  const handleFocus = () => {
    setText("");
  };

  const handleBlur = () => {
    if (text === "") {
      setText("Write message here");
    }
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  };

  useEffect(() => {
    if (textMsg) {
      setText(textMsg);
    }
  }, [textMsg]);

  return (
    <Grid>
      <Quill
        value={text}
        placeholder={text}
        onChange={(value) => {
          handleTextChange(value);
          setText(value);
        }}
        modules={modules}
        formats={formats}
        theme="snow"
        sx={{
          backgroundColor: "#0D0D0D",
          borderRadius: "10px",
          color: "black",
        }}
      />
    </Grid>
  );
};

const MySwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0.05,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#9AE400",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2e3322" : "#374714",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #949494",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#141414" : "#141414",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const donationType = [
  {
    id: 1,
    name: "One-time receipt",
    value: "one_time_receipt",
  },
  {
    id: 2,
    name: "Failed donation",
    value: "failed_donation",
  },
  {
    id: 3,
    name: "Scheduled donation",
    value: "scheduled_donation",
  },
  {
    id: 4,
    name: "Plan receipt",
    value: "plan_receipt",
  },
  {
    id: 5,
    name: "Installment receipt",
    value: "installment_receipt",
  },
  {
    id: 6,
    name: "Plan schedule",
    value: "plan_schedule",
  },
  {
    id: 7,
    name: "Tribute e-card",
    value: "tribute_e_card",
  },
  {
    id: 8,
    name: "Fundraiser started",
    value: "fundraiser_started",
  },
  {
    id: 9,
    name: "Member welcome",
    value: "member_welcome",
  },
];

const CustomColorPicker = ({ name, value, onChange }) => {
  return (
    <div>
      <Input
        type="color"
        id={name}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{ width: "7vh", boxShadow: "none", borderRadius: "50%" }}
      />
    </div>
  );
};

export { MySwitch, MyTextField, donationType, CustomColorPicker };
