import React, { useState, useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Typography, Button, Menu, MenuItem, Paper,FormControl, TextField,  } from '@mui/material'
import { useStyles } from './useStyles'
import { AuthService } from '../../../services/auth.service'
import { useNavigate } from 'react-router-dom'
import { getUserFromLocalStorage } from '../../../services/localStorageService'
import { logout } from '../../../store/authSlice'
import ThemeModeSwitch from "../../../utils/themeModeSwitch";
import{ donationHistory, campaign, recurring, messages } from './donationData';

const msg = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/msg.png'
const logo = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logo.png'
const logoLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";
const download = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/download.svg'
const dp = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png'
const campaignDP = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/campaignDp.svg'
const down = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg'
const up = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/up.svg";
const settings = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/settings.svg'
const logoutIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logout.svg";
const settingsIcon = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/settings.svg";
const visa = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/visa.svg'
const lightMsg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/msg.svg";
const ProgressBar = '../../../component/MainLayout/Dashboard/Insights/Active Campaign/progressBar'
const downWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const downBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg";
const cross = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg";
const crossBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/crossBlack.svg";


const DonorProfile = ({ }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentPath = window.location.pathname
  const [anchorEl, setAnchorEl] = useState(null)
  const [pauseAnchorEl, setPauseAnchorEl] = useState(null)
  const [msgBox, setMsgBox] = useState(null)
  const user = useSelector(state => state.auth.user)
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [mode, setMode] = useState("overview");
  const [stopDonation, setStopDonation] = useState(false);

  const handleLogin = () => {
    window.location.href = '/sign_in'
  }

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout()
      dispatch(logout())
      navigate('/')
    } catch (error) {
      console.error('Sign-out error:', error)
      navigate('/')
    }
  }

  const handlePauseMenuOpen = event => {
    setPauseAnchorEl(event.currentTarget)
  }
  const handlePauseMenuClose = () => {
    setPauseAnchorEl(null)
  }

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMsgOpen = event => {
    setMsgBox(event.currentTarget)
  }

  const handleMsgClose = () => {
    setMsgBox(null)
  }

  useEffect(() => {
    if (stopDonation) {
      document.body.classList.add("disable-scroll");
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return () => {
      document.body.classList.remove("disable-scroll");
    };
  }, [stopDonation]);
  return (
    <>
      {stopDonation ? (
        <Grid
          container
          sx={{
            padding: { md: '8% 17%', sm: '35px 10%', xs: '5px 2%' },
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '0px',
            backdropFilter: 'blur(11px)',
            height: '100%',
            zIndex: '99',
            width: '100%'
          }}
        >
          <Grid
            item
            md={10}
            sm={12}
            xs={12}
            sx={{
              transform: 'scale(0.85)',
              padding: '35px',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
              boxShadow:
                themeMode === 'dark'
                  ? '0px 0px 0px red'
                  : '0px 8px 5px 0px #00000040',
              color: themeMode === 'dark' ? '#FFFF' : '#030303',
              borderRadius: '15px',
              overflow: 'hidden',
              height: 'fit-content',

              '&::before': {
                content: "''",
                position: 'absolute',
                inset: 0,
                borderRadius: '15px',
                padding: '1px',
                background:
                  themeMode === 'dark'
                    ? 'linear-gradient(145deg, white, transparent)'
                    : 'linear-gradient(180deg, white, transparent)',
                WebkitMask:
                  'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                WebkitMaskComposite: 'xor',
                maskComposite: 'exclude'
              }
            }}
          >
           
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  textAlign: 'left',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  color: themeMode === "dark" ? "#A2F000" : "#8ED003",
                  fontWeight: '700',
                  fontSize: { md: '40px', sm: '40px', xs: '35px' },
                }}
              >
                Warning !
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
                width: '100%'
              }}
            >
              <Typography
                sx={{
                  textAlign: 'left',
                  width: '100%',
                  whiteSpace: 'nowrap',                  
                  fontWeight: '400',
                  fontSize: { md: '18px', sm: '18px', xs: '18px' },
                }}
              >
                Are you sure you want to stop this recurring donation?
              </Typography>
            </Grid>

            <Grid my={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width:"100%",
              }}
            >

              <Grid mr={4}>
                <Button disableRipple
                  onClick={() => setStopDonation(false)}
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "20px",
                    padding: "6px 25px",
                    textTransform: "capitalize",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    border: themeMode === "dark" ? "1px solid #FFF" : "1px solid #030303",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: "18px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    }
                  }}>
                  No
                </Button>
              </Grid>
              



              <Grid>
                <Button disableRipple
                  onClick={() => setStopDonation(false)}
                  sx={{
                    backgroundColor: "#C70000",
                    borderRadius: "20px",
                    padding: "6px 25px",
                    textTransform: "capitalize",
                    fontSize: "18px",
                    color: "#FFF",
                    "&:hover": {
                      backgroundColor: "#C70000",
                    }
                  }}>
                  Yes
                </Button>
              </Grid>

            </Grid>
            
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Grid
        sx={{
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
          height: '100%',
          padding: { md: '0px 180px', sm: '0px 80px', xs: '0px 20px' },
          boxShadow: '8px 8px 14px 0px #00000024',
          color: themeMode === "dark" ? "#FFFF" : "#030303",
        }}
      >
        <Grid
          container
          mb={4}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item md={4} sm={4} xs={4} my={1}>
            <Button
              disableRipple
              className={classes.logo}
              href='/'
              sx={{
                padding: { md: '25px', sm: '0px', xs: '0px' },
                transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.5)" },

              }}
            >
              {themeMode === "dark" ? (
                <img src={logo} alt="" />
              ) : (
                <img src={logoLight} alt="" />
              )}
            </Button>
          </Grid>

          <Grid item md={6} sm={6} xs={6} className={classes.flexEnd}>
            <Button
              className={classes.btn}
              disableElevation
              onClick={handleMsgOpen}
              sx={{
                transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.7)" },
                "&:hover": {
                  border: "1px solid #94D906",
                  backgroundColor:
                    themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}

            >
              {themeMode === "dark" ? (
                <img src={msg} alt="" className={classes.allFit} />
              ) : (
                <img src={lightMsg} alt="" className={classes.allFit} />
              )}
            </Button>
            <Menu
              className={classes.menu}
              msgBox={msgBox}
              open={Boolean(msgBox)}
              onClose={handleMsgClose}
              PaperComponent={Paper}
              sx={{
                position: 'absolute',
                '& .MuiPaper-root': {
                  width: { md: '20%', sm: '17%', xs: '30%' }
                }
              }}
            >
              {messages ? (
                <Grid>
                  {messages?.map((item, index) => (
                    <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                      {item.msg}
                    </Typography>
                  ))}
                </Grid>
              ) : (
                <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                  No Messages
                </Typography>
              )}
            </Menu>

            <Button
              className={classes.btn2}
              disableElevation
              onClick={handleMenuOpen}
              sx={{
                "&:hover": {
                  border: "1px solid #94D906",
                  backgroundColor:
                    themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid className={classes.row} container>
                <Grid className={classes.dpHolder} item>
                  <img src={dp} alt='' className={classes.w80} />
                  <Grid
                    className={classes.colCenter}
                    sx={{
                      display: {
                        md: 'flex',
                        sm: 'flex',
                        xs: 'none'
                      }
                    }}
                  >
                    <Grid className={classes.profile}
                      sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303", }}
                    >
                      {' '}
                      {user && user?.first_name && user?.last_name
                        ? user?.first_name + ' ' + user?.last_name
                        : ''}
                    </Grid>
                    <Grid className={classes.profileDull}>Donor</Grid>
                  </Grid>
                </Grid>
                <Grid item mx={1}>
                  {anchorEl ? (
                    <img
                      src={up}
                      alt=""
                      style={{ width: "10px", height: "10px" }}
                    />
                  ) : (
                    <img
                      src={down}
                      alt=""
                      style={{ width: "10px", height: "10px" }}
                    />
                  )}
                </Grid>
              </Grid>
            </Button>
            
            <Menu
              className={classes.menu}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperComponent={Paper}
              sx={{
                "& .MuiPaper-root": {
                  width: { md: "17%", sm: "30%", xs: "70%" },
                  backgroundImage: "none",
                  backgroundColor:
                    themeMode === "dark" ? "#0a0a0a" : "#dbd7d7",
                },
              }}
            >
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  "&:hover": {
                    backgroundColor: "#2222",
                  },
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                    borderBottom: "1px solid #FFF",
                    padding: "10px 15px",
                    justifyContent: "space-between",
                  }}
                >
                  Mode : {themeMode === "dark" ? "Dark" : "Light"}
                  <ThemeModeSwitch />
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleMenuClose}
                component={Link}
                to="/dashboard/settings"
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  "&:hover": {
                    backgroundColor: "#2222",
                  },
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                    borderBottom: "1px solid #FFF",
                    padding: "10px 15px",
                  }}
                >
                  <img
                    src={settingsIcon}
                    alt=""
                    style={{ margin: "0px 15px 0px 0px" }}
                  />
                  Settings
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  "&:hover": {
                    color: user ? "#FF0000 !important" : "#689900",
                    backgroundColor: "#2222",
                  },
                }}
                onClick={() => {
                  user ? handleLogout() : handleLogin();
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "left",
                    padding: "10px 15px",
                  }}
                >
                  <img
                    src={logoutIcon}
                    alt=""
                    style={{ margin: "0px 15px 0px 0px" }}
                  />
                  {user ? "Logout" : "Login"}
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Grid
          sx={{
            backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
            boxShadow:
              themeMode === "dark"
                ? "0px 0px red"
                : "8px 8px 14px 0px #00000024",
            borderRadius: '20px',
            width: '100%',
            height: 'fit-content',
            overflow: 'hidden',
            padding: { md: '20px 80px', sm: '20px 40px', xs: '20px' }
          }}
        >
          <Typography
            sx={{
              fontSize: { md: '40px', sm: '40px', xs: '25px' },
              fontWeight: '700',

            }}
          >
            Donor Account
          </Typography>
          <Typography
            mt={{ xs: 1 }}
            sx={{
              fontSize: '18px',
              fontWeight: '400'
            }}
          >
            Through this account, you can manage your fundraisers, manage your
            recurring plans, dedicate donations, and download donation receipts.
          </Typography>
        </Grid>

        {
          mode === "overview" ?
            (
            <>
              <Grid
                my={3}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px red"
                      : "8px 8px 14px 0px #00000024",
                  borderRadius: '20px',
                  width: '100%',
                  height: 'fit-content',
                  overflow: 'hidden',
                  padding: { md: '20px 80px', sm: '20px 40px', xs: '20px' }
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: '40px', sm: '40px', xs: '25px' },
                    fontWeight: '600'
                  }}
                >
                  P2P Goals
                </Typography>

                {campaign?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ padding: '15px 0px', width: '100%' }}
                  >
                    <Grid
                      container
                      sx={{
                        border: '1px solid #9CCA3C',
                        width: '100%',
                        borderRadius: '15px',
                        padding: '10px 15px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },

                      }}
                    >
                      <Grid
                        item
                        md={3.5}
                        sm={3.5}
                        sx={{ display: 'flex', width: '100%' }}
                      >
                        <img src={dp} alt='' />
                        <Grid
                          ml={2}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography
                            sx={{
                              whiteSpace: 'nowrap',
                              fontSize: { md: '16px', sm: '14px', xs: '12px' },
                              fontWeight: '400'
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{
                              textWrap: 'none',
                              fontSize: '12px',
                              fontWeight: '400',
                              color: themeMode === "dark" ? '#FFFFFF80' : "#030303",
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={7.5}
                        sm={7.5}
                        my={{ md: 0, sm: 0, xs: 3 }}
                        sx={{ width: { md: '65%', sm: '80%', xs: '100%' } }}
                      >
                        <ProgressBar
                          collected={item.collected}
                          remaining={item.remaining}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid
                my={3}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px red"
                      : "8px 8px 14px 0px #00000024",
                  borderRadius: '20px',
                  width: '100%',
                  height: 'fit-content',
                  overflow: 'hidden',
                  padding: { md: '20px 80px', sm: '20px 40px', xs: '20px' }
                }}
              >
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontSize: { md: '40px', sm: '40px', xs: '25px' },
                      fontWeight: '600'
                    }}
                  >
                    Recurring Donations
                  </Typography>

                </Grid>
                {recurring?.map((item, index) => (
                  <Grid container key={index}>
                    <Grid
                      item
                      mt={2}
                      md={10.5}
                      sm={10.5}
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { md: 'row', sm: 'row', xs: 'column' }
                      }}
                    >
                      <Grid
                        my={{ md: 0, sm: 0, xs: 1 }}
                        sx={{
                          display: 'flex',
                          flexDirection: { md: 'column', sm: 'column', xs: 'column' },
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              md: '16px',
                              sm: '14px',
                              xs: '15px',
                              textWrap: 'nowrap'
                            }
                          }}
                        >
                          Monthly Donation
                        </Typography>
                        <Typography
                          mt={0.5}
                          sx={{
                            color: '#9CCA3C',
                            fontWeight: '700',
                            fontSize: { md: '18px', sm: '18px', xs: '15px' },
                            textAlign: { md: 'center', sm: 'center', xs: 'right' }
                          }}
                        >
                          $13.5 USD
                        </Typography>
                      </Grid>
                      <Grid
                        my={{ md: 0, sm: 0, xs: 1 }}
                        sx={{
                          display: 'flex',
                          flexDirection: { md: 'column', sm: 'column', xs: 'column' },
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'left',
                            textWrap: 'nowrap',
                            fontSize: { md: '16px', sm: '14px', xs: '15px' }
                          }}
                        >
                          Next Installment
                        </Typography>
                        <Typography
                          mt={0.5}
                          sx={{
                            fontSize: { md: '18px', sm: '18px', xs: '15px' },
                            textAlign: { md: 'center', sm: 'center', xs: 'right' },
                            fontWeight: '600'
                          }}
                        >
                          1 January, 2024
                        </Typography>
                      </Grid>
                      <Grid
                        my={{ md: 0, sm: 0, xs: 1 }}
                        sx={{
                          display: 'flex',
                          flexDirection: { md: 'column', sm: 'column', xs: 'column' },
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { md: '16px', sm: '14px', xs: '15px' },
                            textWrap: 'nowrap'
                          }}
                        >
                          Payment Method
                        </Typography>

                        <Typography
                          mt={1}
                          sx={{
                            fontSize: { md: '18px', sm: '18px', xs: '15px' },
                            textWrap: 'nowrap',
                            textAlign: { md: 'center', sm: 'center', xs: 'right' },
                            display: "flex",
                            flexWrap: "nowrap"
                          }}
                        >
                          <img src={visa} alt='' style={{ margin: '0px 4px' }} /> . .
                          . . 4254
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={1.5}
                      sx={{
                        display: { md: 'flex', sm: 'flex', xs: 'none' },
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        onClick={()=>setMode("settings")}
                        sx={{
                          width: 'fit-content',
                          textAlign: 'right',
                          alignItem: 'center',
                          display: 'flex',
                          '&:hover': { backgroundColor: 'transparent' }
                        }}
                      >
                        <img src={settings} alt='' />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid
                my={3}
                mb={9}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px red"
                      : "8px 8px 14px 0px #00000024",
                  borderRadius: '20px',
                  width: '100%',
                  height: 'fit-content',
                  overflow: 'hidden',
                  padding: { md: '20px 80px', sm: '20px 40px', xs: '20px' }
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: '40px', sm: '40px', xs: '25px' },
                    fontWeight: '600'
                  }}
                >
                  Donation History
                </Typography>
                <Grid
                  container
                  sx={{ display: { md: 'flex', sm: 'flex', xs: 'none' } }}
                >
                  <Grid
                    item
                    mt={2}
                    md={11}
                    sm={11}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Grid
                      item
                      md={4} sm={4}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography
                        mt={0.5}
                        sx={{ fontSize: '16px', fontWeight: '400' }}
                      >
                        Date
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4} sm={4}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography
                        mt={0.5}
                        sx={{ fontSize: '16px', fontWeight: '400' }}
                      >
                        Amount
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4} sm={4}
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography
                        mt={0.5}
                        sx={{ fontSize: '16px', fontWeight: '400' }}
                      >
                        Payment Method
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {donationHistory?.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{
                      width: '100%',
                      display: { md: 'flex', sm: 'flex', xs: 'none' }
                    }}
                  >
                    <Grid
                      item
                      mt={2}
                      md={11}
                      sm={11}
                      xs={10}
                      sx={{
                        display: 'flex',
                        //justifyContent: 'space-between'
                        width: '100%'
                      }}
                    >
                      <Grid
                        item
                        md={4} sm={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          mr={{ md: 0, sm: 0, xs: 1 }}
                          sx={{
                            fontSize: { md: '18px', sm: '15px', xs: '15px' },
                            textWrap: 'nowrap',
                            fontWeight: '600'
                          }}
                        >
                          {item.date}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4} sm={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography
                          mt={0.5}
                          mx={{ md: 0, sm: 0, xs: 1 }}
                          sx={{
                            fontSize: { md: '18px', sm: '15px', xs: '15px' },
                            textWrap: 'nowrap',
                            fontWeight: '700',
                            color: '#9CCA3C'
                          }}
                        >
                          {item.amount}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4} sm={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          sx={{
                            fontSize: { md: '18px', sm: '15px', xs: '10px' },
                            textWrap: 'nowrap',
                            textAlign: { md: 'center', sm: 'center', xs: 'right' },
                            display: "flex",
                            flexWrap: "nowrap"
                          }}
                        >
                          <img
                            src={item.methodImg}
                            alt=''
                            style={{ margin: '0px 10px' }}
                          />
                          {item.method}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={1} sm={1}
                      sx={{
                        textAlign: 'right',
                        alignItem: 'center',
                        display: 'flex',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          width: '100%',
                          '&:hover': { backgroundColor: 'transparent' }
                        }}
                      >
                        <img src={download} alt='' />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
                {donationHistory?.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    my={2}
                    sx={{
                      width: '100%',
                      display: { md: 'none', sm: 'none', xs: 'flex' },
                      flexDirection: "row",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Grid
                      item
                      mt={2}
                      md={12}
                      xs={5.8}
                      sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}
                    >
                      <Grid
                        item
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          sx={{ fontSize: '12px', fontWeight: '400' }}
                        >
                          Date
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          sx={{ fontSize: '12px', fontWeight: '400' }}
                        >
                          Amount
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          sx={{ fontSize: '12px', fontWeight: '400' }}
                        >
                          Payment Method
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      mt={2}
                      md={11}
                      sm={11}
                      xs={6}
                      sx={{
                        display: 'flex',
                        flexDirection: "column",
                        width: '100%',

                      }}
                    >
                      <Grid
                        item
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          mr={1}
                          sx={{
                            fontSize: '12px',
                            textWrap: 'nowrap',
                            fontWeight: '600',
                            textAlign: "right"
                          }}
                        >
                          {item.date}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexWrap: 'wrap'
                        }}
                      >
                        <Typography
                          mt={0.5}
                          mx={{ md: 0, sm: 0, xs: 1 }}
                          sx={{
                            fontSize: '12px',
                            textWrap: 'nowrap',
                            fontWeight: '700',
                            color: '#9CCA3C',
                            textAlign: "right"
                          }}
                        >
                          {item.amount}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Typography
                          mt={0.5}
                          sx={{
                            fontSize: '12px',
                            textWrap: 'nowrap',
                            textAlign: "right",
                            display: "flex",
                            flexWrap: "nowrap"
                          }}
                        >
                          <img
                            src={item.methodImg}
                            alt=''
                            style={{ margin: '0px 10px' }}
                          />
                          {item.method}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      my={1}
                      sx={{
                        textAlign: 'right',
                        alignItem: 'center',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={{
                          width: '100%',
                          border: "1px solid #9CCA3C",
                          '&:hover': { backgroundColor: 'transparent' }
                        }}
                      >
                        <img src={download} alt='' style={{ width: "10px" }} />
                        <Typography mx={1} sx={{ textTransform: "capitalize", color: "#FFF", fontSize: "10px" }}>Download</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )
            :

          mode === "settings"?
            (
              <>
                <Grid
                  my={3}
                  //mb={9}
                  sx={{
                    backgroundColor: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2",
                    boxShadow:
                      themeMode === "dark"
                        ? "0px 0px red"
                        : "8px 8px 14px 0px #00000024",
                    borderRadius: '20px',
                    width: '100%',
                    height: 'fit-content',
                    overflow: 'hidden',
                    padding: { md: '30px 80px', sm: '20px 40px', xs: '20px' },
                  }}
                >
                    <Grid mb={4} className={classes.closeButtonHolder}>
                      <Button onClick={() => setMode("overview")} className={classes.closeButton}>
                        <img src={themeMode == "dark" ? cross : crossBlack} alt="" />
                      </Button>
                    </Grid>
                  <Grid sx={{
                    display: "flex",
                    justifyContent: "space-between",}}>
                  <Grid
                    md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '16px', sm: '14px', xs: '12px' },
                        fontWeight: '400'
                      }}
                    >
                      Monthly Donation
                    </Typography>
                  </Grid>
                  <Grid
                    ml={2}
                    md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '16px', sm: '14px', xs: '12px' },
                        fontWeight: '400',
                        color:"#8ED003"
                      }}
                    >
                      Change Donation Amount
                    </Typography>
                  </Grid>
                  </Grid>
                  <Grid
                    my={3}
                    //ml={2}
                    md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '40px', sm: '40px', xs: '36px' },
                        fontWeight: '700',
                        color: themeMode === "dark" ? "#A2F000" : "#8ED003"
                      }}
                    >
                      $13.5 USD
                    </Typography>
                  </Grid>
                  <Grid
                    my={4}
                    //ml={2}
                    md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '24px', sm: '24px', xs: '20px' },
                        fontWeight: '600',
                      }}
                    >
                      Next Installment
                    </Typography>
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '40px', sm: '40px', xs: '36px' },
                        fontWeight: '600',
                      }}
                    >
                      1 April, 2024
                    </Typography>
                  </Grid>

                  <Grid sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                    <Grid
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: { md: '24px', sm: '24px', xs: '20px' },
                        fontWeight: '600',
                      }}
                    >
                      Payment Method
                    </Typography>
                    </Grid>
                    <Grid
                      ml={2}
                      md={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                    <Typography
                      sx={{
                          whiteSpace: 'nowrap',
                          fontSize: { md: '16px', sm: '14px', xs: '12px' },
                          fontWeight: '400',
                          color: "#8ED003",
                          display:"flex",
                          alignItems:"center"
                      }}
                    >
                      Change Payment Method
                    </Typography>
                      </Grid>
                  </Grid>
                  

                  <Grid item md={7} sm={9} xs={12}>
                    <FormControl  sx={{width:"50%"}}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="outlined-basic"
                        placeholder="1234 5678 1233 2211"
                        // value={goalObj?.title || ""}
                        // onChange={(event) =>
                        //   setGoalObj({
                        //     ...goalObj,
                        //     title: event.target.value,
                        //   })
                        // }
                        className={classes.input}
                        sx={{
                          mt: 2
                          //mb: { md: 4, sm: 2, xs: 2 },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={7} sm={9} xs={12} sx={{display:"flex",justifyContent:"space-between",width:"50%"}}>
                    <FormControl sx={{width:"49%"}}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="outlined-basic"
                        placeholder="07/26"
                        // value={goalObj?.title || ""}
                        // onChange={(event) =>
                        //   setGoalObj({
                        //     ...goalObj,
                        //     title: event.target.value,
                        //   })
                        // }
                        className={classes.input}
                        sx={{
                          width: "100%",
                          mt: 2
                          //mb: { md: 4, sm: 2, xs: 2 },
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ width: "49%" }}>
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        id="outlined-basic"
                        placeholder="225"
                        // value={goalObj?.title || ""}
                        // onChange={(event) =>
                        //   setGoalObj({
                        //     ...goalObj,
                        //     title: event.target.value,
                        //   })
                        // }
                        className={classes.input}
                        sx={{
                          width: "100%",
                          mt: 2
                          //mb: { md: 4, sm: 2, xs: 2 },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid mb={20}
                  sx={{
                    display:"flex",
                    flexDirection:"row",
                    justifyContent:"flex-end"
                  }}
                >

                  <Grid mr={4}>
                    <Button disableRipple
                      onClick={handlePauseMenuOpen}
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "20px",
                        padding: "8px 15px",
                        textTransform: "capitalize",
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        border: themeMode === "dark" ? "1px solid #FFF": "1px solid #030303",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        textWrap: "nowrap",
                        fontSize: "18px",
                        "&:hover": {
                          backgroundColor: "transparent",
                        }
                      }}>
                      Pause Donation
                      <img
                        src={themeMode === "dark" ? downWhite : downBlack}
                        alt=""
                        style={{ marginLeft: "10px" }}
                      />
                    </Button>
                  </Grid>
                    <Menu
                      className={classes.menu}
                      anchorEl={pauseAnchorEl}
                      open={Boolean(pauseAnchorEl)}
                      onClose={handlePauseMenuClose}
                      PaperComponent={Paper}
                      sx={{
                        "& .MuiPaper-root": {
                          width: { md: "14%", sm: "30%", xs: "70%" },
                          backgroundImage: "none",
                          backgroundColor:
                            themeMode === "dark" ? "#0a0a0a" : "#dbd7d7",
                        },
                      }}
                    >
                      <MenuItem
                        className={classes.menuItem}
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          "&:hover": {
                            backgroundColor: "#2222",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left",
                            borderBottom: "1px solid #FFF",
                            padding: "10px 15px",
                            justifyContent: "space-between",
                          }}
                        >
                          Pause for 1 month
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={handleMenuClose}
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          "&:hover": {
                            backgroundColor: "#2222",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left",
                            padding: "10px 15px",
                          }}
                        >
                          Until I resume it
                        </Typography>
                      </MenuItem>
                    </Menu>



                <Grid>
                  <Button disableRipple
                  onClick={()=>setStopDonation(true)}
                  sx={{
                      backgroundColor:"#C70000",
                      borderRadius:"20px",
                      padding:"8px 15px",
                      textTransform:"capitalize",
                      fontSize: "18px",
                      color:"#FFF",
                      "&:hover":{
                        backgroundColor: "#C70000",
                      }
                  }}>
                    Stop Donation
                  </Button>
                </Grid>

                </Grid>
              </>
            )
        :""

      }

      </Grid>

    </>
  )
}

export default DonorProfile
