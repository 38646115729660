import { Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

const stars = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/stars.png";
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/card.png";
const checked = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/checked.png";

export const PaymentOption = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div className="flex flex-col h-full">
      <div>
        <Typography className="pre-don-text py-4 text-center">
          Payment Option{" "}
        </Typography>
        <hr />
        <Typography className="payment-option-text py-4 text-center">
          $ 63. 70 USD/ Week
        </Typography>
      </div>
      <div className="flex flex-col justify-end ">
        <div
          className="container mt-5 py-4"
          style={{ backgroundColor: "#A4B200" }}
        >
          <Typography
            className={
              themeMode === "dark"
                ? "pre-donation-btn text-white flex justify-center"
                : "pre-donation-btn text-black flex justify-center"
            }
          >
            <img src={checked} />
            <span className="mx-2"> Convert Transaction costs </span>
          </Typography>
          <div className="bottom-left">
            <img src={stars} height={50} width={50} />
          </div>
        </div>

        <div className="pt-52 px-8">
          <Button
            variant="contained"
            className="w-full py-3"
            sx={{ backgroundColor: "#A4B200", borderRadius: "10px" }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-white flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <img src={card} />
              <span className="mx-2">Credit Cards </span>
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
