import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  full: {
    width: "100%",
    height:"100%"
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  fitContent: {
    display: "flex",
    width: "fit-content",
  },
  fit: {
    width: "fit-content",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },
  overview: {
    background: "#0F0F0F",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "10px",
    alignItems: "center",
  },
  overviewBtn: {
    width: "100%",
    height: "100%",
    padding: "20px",
    borderRadius: "15px",
    border: "0.5px solid transparent",
    "&:hover": {
      background: "#191c12",
      border: "0.5px solid #fff",
    },
    "&:active": {
      background: "#191c12",
      border: "0.5px solid #fff",
    },
    "&:focus": {
      background: "#191c12",
      border: "0.5px solid #fff",
    },
  },
  flexEnd: {
    display: "flex",
    justifyContent: "space-between",
  },
  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  borderButton: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    flexWrap: "nowrap",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    
    fontWeight: "500",
    textTransform: "capitalize",
  },
  neon: {
    fontWeight: "500",
    textTransform: "capitalize",
    
  },
  neon600: {
    fontWeight: "600",
    
    textWrap: "nowrap",
  },
  fw600: {
    fontWeight: "600",
    textWrap: "wrap",
  },

  sideText: {
    fontSize: "14px",
    fontWeight: "600",
  },
  f12w500: {
    fontSize: "12px",
    fontWeight: "500",
    textWrap: "nowrap",
  },

  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  centerSpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  fw400: {
    textWrap: "nowrap",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  fw600: { fontWeight: "600" },
  colorBox: {
    border: "2px solid #9CCA3C",
    borderRadius: "15px",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "fit-content",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  colorBox2: {
    border: "1px solid #9CCA3C",
    borderRadius: "15px",
    padding: "10px 15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  data: {
    fontSize: "28px",
    fontWeight: "700",
    textWrap: "nowrap",
  },

  divider: {
    display: "flex",
    width: "100%",
    padding: "10px",
    flexWrap: "wrap",
  },

  flex: {
    display: "flex",
  },
  fs12: {
    fontSize: "12px",
    fontWeight: "500",
    textWrap: "nowrap",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignItems: "space-between",
    justifyContent: "flex-start",
    justifyContent:"space-bettween",
    textAlign: "center",
    padding: "25px",
    backdropFilter: "blur(22px)",
    position: "relative",
    //boxShadow: themeMode==="dark"?"0px 0px 0px red": "0px 8px 5px 0px #00000040",
    // backgroundColor:"#0D0D0DE5",
    //color: "#fff",
    borderRadius: "15px",
    overflow: "visible",
    height: "100%",
    "&::before": {
          content: "''",
          position: "absolute",
          inset: 0,
          borderRadius: "15px",
          padding: "1px",
          background:"linear-gradient(145deg, white, transparent)",
          WebkitMask:
            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "xor",
          maskComposite: "exclude",
        },
  },
  tag: {
    textAlign: "left",
    width: "100%",
    display: "flex",
  },
  down: {
    width: "fit-content",
    padding: "0px",
  },
  downBtn: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#A19A9A",
    textTransform: "capitalize",
  },
  gateway: {
    backgroundColor: "#0F0F0F",
    borderRadius: "15px",
    height: "fit-content",
  },
  f16w700: {
    fontSize: "16px",
    fontWeight: "700",
  },
  f14w700: {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "left",
  },
  p5: {
    padding: "5px 10px",
  },
  alignCenter: {
    display: "flex",
    alignItem: "center",
  },
  rightCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  filterBtn: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
  },
}));
