import templateDark from "../../../assets/templates/templateDark.svg"
import template from "../../../assets/templates/templateWhite.svg"
import templateBW from "../../../assets/templates/templateTimer.svg"
import templateHalf from "../../../assets/templates/templateBW.svg"
import templateYellow from "../../../assets/templates/orange.svg"
import templateOrange from "../../../assets/templates/templateOrangeDark.svg"

const setup = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/setup.svg";
const select = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/select.svg";
const embed = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/embed.svg";
const gain = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/gain.png";
const loss = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/loss.png";
const paypal = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/paypal.svg";
const stripe = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/stripe.svg";
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/card.svg";
// const template = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/template.svg"
// const templateDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateDark.svg"
// const templateBW = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateBW.svg"
// const templateHalf = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateHalf.svg"
// const templateYellow = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateYellow.svg"
// const templateOrange = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/templateOrange.svg"
const cardLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/Card.svg"
const blank = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/blank.svg";





const steps = [
  { id: 1, img: setup, text: " Set up the campaign " },
  {
    id: 2,
    img: select,
    text: " Select our pre-built donation form or make form with elements",
  },
  {
    id: 3,
    img: embed,
    text: " Share the form link or embed your element-made form into you website",
  },
];

const supporters = [
  {
    id: 1,
    name: "Shah Rukh Khan",
    location: "India",
    value: "1000",
  },
  {
    id: 2,
    name: "Imran Khan",
    location: "Pakistan",
    value: "100",
  },
  {
    id: 3,
    name: "Anonyomous",
    location: "unknown location",
    value: "50",
  },
  {
    id: 4,
    name: "Ghulam Kabir",
    location: "Pakistan",
    value: "10",
  },

  {
    id: 5,
    name: "Anonyomous",
    location: "unknown location",
    value: "170",
  },
  {
    id: 6,
    name: "Anonyomous",
    location: "unknown location",
    value: "1170",
  },
  {
    id: 7,
    name: "Anonyomous",
    location: "unknown location",
    value: "1730",
  },
];
const campaign = [
  {
    name: "Saving Animals",
    time: "30 days remaining",
    collected: "500",
    remaining: "600",
  },
  {
    name: "Saving Birds",
    time: "30 days remaining",
    collected: "90",
    remaining: "900",
  },
  {
    name: "Saving Trees",
    time: "30 days remaining",
    collected: "900",
    remaining: "1600",
  },

  {
    name: "Saving Human",
    time: "30 days remaining",
    collected: "1900",
    remaining: "2000",
  },

  {
    name: "Saving Insects",
    time: "30 days remaining",
    collected: "19",
    remaining: "800",
  },
];

const graphData = [
  {
    value: 0,
    end_time: "2023-08-01T00:00:00", // August 5, 2023
  },
  {
    value: 0,
    end_time: "2023-08-05T00:00:00", // August 18, 2023
  },
  {
    value: 12,
    end_time: "2023-08-13T00:00:00", // August 10, 2023
  },
  {
    value: 12,
    end_time: "2023-08-13T00:00:00", // August 3, 2023
  },
  {
    value: 20,
    end_time: "2023-08-17T00:00:00", // August 12, 2023
  },
  {
    value: 20,
    end_time: "2023-08-17T00:00:00", // August 12, 2023
  },
  {
    value: 35,
    end_time: "2023-08-20T00:00:00", // August 12, 2023
  },
  {
    value: 44,
    end_time: "2023-08-30T00:00:00", // August 12, 2023
  },
  {
    value: 56,
    end_time: "2023-09-06T00:00:00", // August 12, 2023
  },
  {
    value: 56,
    end_time: "2023-09-08T00:00:00", // August 12, 2023
  },
  {
    value: 69,
    end_time: "2023-09-12T00:00:00", // August 12, 2023
  },
  {
    value: 88,
    end_time: "2023-09-22T00:00:00", // August 12, 2023
  },
];

const payment = [
  {
    id: 1,
    way: "Paypal",
    img: paypal,
    value: "40%",
    light:paypal,
  },
  {
    id: 2,
    way: "Stripe",
    img: stripe,
    value: "30%",
    light:stripe,
  },
  {
    id: 3,
    way: "Credit/Debit Card",
    img: card,
    value: "30%",
    light:cardLight
  },
];

const mostUsed = [
  {
    id: 1,
    currency:"US Dollar",
    sign:"$",
  },
  { 
    id: 2,
    currency:"Euro",
    sign:"€",
  },
  {
    id: 3,
    currency:"UK Pound",
    sign:"£",
  },
];


const recurring = [
  { id: 1, name: "Amount", value: "$300" },
  { id: 2, name: "Count", value: "50" },
  { id: 3, name: "Average", value: "$50" },
  { id: 4, name: "Median", value: "$60" },
];
const one_time = [
  { id: 1, name: "Amount", value: "$420" },
  { id: 2, name: "Count", value: "68" },
  { id: 3, name: "Average", value: "$130" },
  { id: 4, name: "Median", value: "$93" },
];

const tableCampaign = [
  {
    id: 1,
    title: "Campaign Names",
  },
  {
    id: 2,
    title: "Raised",
  },
  {
    id: 3,
    title: "Donation #",
  },
  {
    id: 4,
    title: "Recurring Amount",
  },
  {
    id: 5,
    title: "Last Donation At",
  },
  {
    id: 6,
    title: "Status",
  },
  {
    id: 7,
    title: "",
  },
];
const createCampaignData = (
  ID,
  campaignName,
  raised,
  donationNo,
  recurringAmount,
  lastDonation
) => {
  return {
    ID,
    campaignName,
    raised,
    donationNo,
    recurringAmount,
    lastDonation,
  };
};
const campaignRow = [
  createCampaignData(
    "46601",
    "Save Trees, Save Earth",
    "$60",
    "7",
    "$0",
    "20/11/2023"
  ),
  createCampaignData(
    "53601",
    "Saving Animal Campaign",
    "$120",
    "10",
    "$30",
    "10/11/2023"
  ),
  createCampaignData(
    "94501",
    "IEEE Beach Cleaning",
    "$20",
    "2",
    "$0",
    "23/10/2023"
  ),
];






// Donation
const tableDonation = [
  {
    id: 1,
    title: "Date",
  },
  {
    id: 2,
    title: "Amount",
  },
  {
    id: 3,
    title: "Fee Covered",
  },
  {
    id: 4,
    title: "Supporters",
  },
  {
    id: 5,
    title: "Campaigns",
  },
];
const createDonationData = (date, amount, feeCovered, supporter, campaign) => {
  return {
    date,
    amount,
    feeCovered,
    supporter,
    campaign,
  };
};
const donationRow = [
  createDonationData(
    "Nov 02, 2023, 12:22 PM",
    "$60",
    "Covered",
    "Nabeel Ahmed",
    "Save Trees, Same Earth"
  ),
  createDonationData(
    "Nov 02, 2023, 12:22 PM",
    "$120",
    "Covered",
    "Anonymous",
    "Save Water"
  ),
  createDonationData(
    "Nov 02, 2023, 12:22 PM",
    "$20",
    "Un Covered",
    "Syed Anab",
    "Save Trees, Same Earth"
  ),

  createDonationData(
    "Nov 02, 2023, 12:22 PM",
    "$20",
    "Un Covered",
    "Anonymous",
    "Saving Animals"
  ),

  createDonationData(
    "Nov 12, 2023, 12:22 PM",
    "$20",
    "Covered",
    "Hamza",
    "Save Water"
  ),
];








// P2P
const tableP2P = [
  {
    id: 1,
    title: "Campaigns",
  },
  {
    id: 2,
    title: "Fundraiser",
  },
  {
    id: 3,
    title: "Goal",
  },
  {
    id: 4,
    title: "Raised",
  },
  {
    id: 5,
    title: "Date Created",
  },
  {
    id: 6,
    title: "7 Days Stats",
  },
  {
    id: 7,
    title: "",
  },
];
const createP2PData = (
  campaign,
  fundraiser,
  goal,
  raised,
  dateCreated,
  stats
) => {
  return {
    campaign,
    fundraiser,
    goal,
    raised,
    dateCreated,
    stats,
  };
};
const p2pRow = [
  createP2PData(
    "Save Trees, Save Earth",
    "Nabeel",
    "$100",
    "$28",
    "Oct 5, 2023",
    "0 UFV/$10"
  ),
  createP2PData(
    "Save Trees, Save Earth",
    "Taha",
    "$20",
    "$28",
    "Oct 5, 2023",
    "0 UFV/$10"
  ),
  createP2PData(
    "Saving Animal",
    "Haris",
    "$18",
    "$28",
    "Oct 5, 2023",
    "0 UFV/$10"
  ),
];






// Element
const tableElement = [
  {
    id: 1,
    title: "ID",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "Type",
  },
  {
    id: 4,
    title: "Campaigns",
  },
  {
    id: 5,
    title: "Date Created",
  },
  {
    id: 6,
    title: "",
  },
  {
    id: 7,
    title: "",
  },
  {
    id: 8,
    title: "",
  },
];
const createElementData = (
  id,
  name,
  type,
  dateCreated
) => {
  return {
  id,
  name,
  type,
  dateCreated
  };
};
const elementRows = [
  createElementData(
    "1245124",
    "Goal Meter Bar",
    "Data",
    "Oct 5, 2023",
  ),
  createElementData(
    "343583",
    "Red Donation Button",
    "Button",
    "Oct 15, 2023",
  ),
  createElementData(
    "9982442",
    "Donation Link",
    "Link",
    "Nov 1, 2023",
  ),
  createElementData(
    "657482",
    "Donation Button",
    "Button",
    "Nov 30, 2023",
  ),
  createElementData(
    "878821",
    "Image Card DD",
    "Image",
    "Jan 21, 2024",
  ),
];







//Recurring
const tableRecurring = [
  {
    id: 1,
    title: "Campaigns",
  },
  {
    id: 2,
    title: "Supporters",
  },
  {
    id: 3,
    title: "Amount",
  },
  {
    id: 4,
    title: "Installments",
  },
  {
    id: 5,
    title: "Total",
  },
  {
    id: 6,
    title: "Next Donation Occurs",
  },
];

const createRecurringData = (
  supporter,
  amount,
  installments,
  total,
  nxtDonation,
  latest
) => {
  return {
    supporter,
    amount,
    installments,
    total,
    nxtDonation,
    latest,
  };
};
const recurringRow = [
  createRecurringData(
    "Syed Anab Imam",
    "$100",
    "2 months",
    "$200",
    "18 December 2023",
    true
  ),
  createRecurringData(
    "Syed Anab Imam",
    "$200",
    "3 months",
    "$600",
    "12 December 2023",
    true
  ),
  createRecurringData(
    "Syed Anab Imam",
    "$500",
    "2 months",
    "$1000",
    "4 December 2023",
    true
  ),
  createRecurringData(
    "Syed Anab Imam",
    "$300",
    "6 months",
    "$1200",
    "1 December 2023",
    false
  ),
];



//Supporter
const tableSupporter = [
  {
    id: 1,
    title: "Name",
  },
  {
    id: 2,
    title: "Email",
  },
  {
    id: 3,
    title: "Lifetime Donations",
  },
  {
    id: 4,
    title: "First Donation At",
  },
  {
    id: 5,
    title: "Last Donation At",
  },
];

const createSupporterData = (
  name,
  email,
  lifetime,
  firstDonation,
  lastDonation
) => {
  return {
  name,
  email,
  lifetime,
  firstDonation,
  lastDonation
  };
};
const supporterRow = [
  createSupporterData(
    "Syed Anab Imam",
    "syedimad@gmail.com",
    "$200",
    "2 Aug 2023",
    "2 Nov 2023",
  ),
  createSupporterData(
    "Syed Anab Imam",
    "syedimad@gmail.com",
    "$200",
    "2 Aug 2023",
    "2 Nov 2023",
  ),
  createSupporterData(
    "Syed Anab Imam",
    "syedimad@gmail.com",
    "$200",
    "2 Aug 2023",
    "2 Nov 2023",
  ),
  createSupporterData(
    "Syed Anab Imam",
    "syedimad@gmail.com",
    "$200",
    "2 Aug 2023",
    "2 Nov 2023",
  ),
];









const tableExports = [
  
  {
    id: 1,
    title: "File Name",
  },
  {
    id: 2,
    title: "Data Type",
  },
  {
    id: 3,
    title: "File Type",
  },
  {
    id: 4,
    title: "Date",
  },
];
const createExportData = (
    fileName,
    dataType,
    fileType,
    date
) => {
  return {
    fileName,
    dataType,
    fileType,
    date
  };
};
const exportRow = [
  createExportData(
    
    "Campaign Data",
    "Campaign",
    "XLS",
    "2 Aug 2023",
  ),
  createExportData(
    
    "Donors Sheet",
    "Supporter",
    "XLS",
    "12 Aug 2023"
  ),
  createExportData(
    
    "Frequency Sheet",
    "Recurring Donation",
    "CSV",
    "20 Aug 2023"
  ),
  
  createExportData(
    
    "Our Friend",
    "P2P Fundraiser",
    "CSV",
    "22 Aug 2023"
  ),
];









const tablePastReport = [
  {
    id: 1,
    title: "ID #",
  },
  {
    id: 2,
    title: "Name",
  },
  {
    id: 3,
    title: "Campaign",
  },
  {
    id: 4,
    title: "Date",
  },
  {
    id: 5,
    title: "Download",
  },
];
const createPastReport = (id, name, campaign, date,) => {
  return {
    id,
    name,
    campaign,
    date,
  };
};
const pastReportRow = [
  createPastReport("723723", "Comparison1", "Save Trees vs Save Animal", "2 Aug 2023"),
  createPastReport("098765", "Save Gaza Report", "Save Gaza", "2 Aug 2023"),
  createPastReport(
    "345987",
    "Comparison 0",
    "Save Gaza vs Save Trees",
    "12 Aug 2023",
    "20 Aug 2023"
  ),

  createPastReport("234096", "Trees Report", "Save Trees", "22 Aug 2023"),
];

export const preBuiltTemplate_1 = `
  <h2 style="text-align: left; margin-bottom: 3px; color: black;">Donation Form</h2>
  <h3 style="text-align: left; margin-bottom: 3px; color: black;" id="campaignTitle"></h2>
  <p style="text-align: left; margin-bottom: 3px; color: black;" id="campaignDescription"></p>
  <div style="text-align: left; background: #fff; border-radius: 8px; padding: 20px; position: relative;">
      <form id="donation-form" onsubmit="return submitForm()">
          <label for="firstName" style="display: block; margin-bottom: 5px; color: black;">First Name</label>
          <input type="text" id="firstName" name="firstName" style="width: 100%; box-sizing: border-box; padding: 8px; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 4px; color: black;" required>

          <label for="lastName" style="display: block; margin-bottom: 5px; color: black;">Last Name</label>
          <input type="text" id="lastName" name="lastName" style="width: 100%; box-sizing: border-box; padding: 8px; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 4px; color: black;" required>

          <label for="email" style="display: block; margin-bottom: 5px; color: black;">Email</label>
          <input type="email" id="email" name="email" style="width: 100%; box-sizing: border-box; padding: 8px; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 4px; color: black;" required>
              
          <label for="cardHolderName" style="display: block; margin-bottom: 5px; color: black;">Card Holder Name</label>
          <input type="text" id="cardHolderName" name="cardHolderName" style="width: 100%; box-sizing: border-box; padding: 8px; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 4px; color: black;" required>
              
          <div id="card-element" style="margin-bottom: 5px;"></div>
              
          <label for="Amount" style="display: block; margin-bottom: 5px; color: black;">Amount (USD)</label>
          <input type="number" id="Amount" name="Amount" style="width: 100%; box-sizing: border-box; padding: 8px; margin-bottom: 10px; border: 1px solid #ccc; border-radius: 4px; color: black;" required>
              
              
          <div id="card-errors" role="alert" style="margin-bottom: 5px;"></div>
          <div id="success-msg" role="alert" style="margin-bottom: 5px;"></div>

          <button id="donate-button" type="submit" style="background-color: #4caf50; color: white; padding: 10px 15px; border: none; border-radius: 4px; cursor: pointer;">Donate</button>
      </form>
  </div>
`

const publishData=[
  {
    id:1,
    img:blank
  },
  {
    id:2,
    img:blank
  },
  {
    id:3,
    img:blank
  },
  {
    id:4,
    img:blank
  },
  {
    id:5,
    img:blank
  },
  {
    id:6,
    img:blank
  },

]

const defaultPublishData=[
  {
    id:0,
    name: "preBuiltTemplate_1",
    img: templateDark,
    
  },
  {
    id: 1,
    name: "preBuiltTemplate_2",
    img: templateOrange,
    
  },
  {
    id:2,
    name: "preBuiltTemplate_3",
    img: templateBW,
    
  },
  {
    id: 3,
    name: "preBuiltTemplate_4",
    img: template,
    
  },
  {
    id: 4,
    name: "preBuiltTemplate_5",
    img: templateHalf,
    
  },
  {
    id: 5,
    name: "preBuiltTemplate_6",
    img: templateYellow,

  },
  
];



export {
  supporters,
  steps,
  campaign,
  graphData,
  payment,
  mostUsed,
  recurring,
  one_time,
  tableCampaign,
  campaignRow,
  tableDonation,
  donationRow,
  tableP2P,
  p2pRow,
  tableElement,
  elementRows,
  tableRecurring,
  recurringRow,
  tableSupporter,
  supporterRow,
  tableExports,
  exportRow,
  tablePastReport,
  pastReportRow,
  publishData,
  defaultPublishData

};
