import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Button, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { adjustNegativeMonths } from "../../../../utils/globalFunctions.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import { GraphAnalysis } from '../CreateCampaign/CampaignOverview/Display/GraphAnalysis';
import PaymentGateway from "./Payment Gateway/PaymentGateway";
import MostUsedGateway from "./Used Gateway/MostUsedGateway";
import { GraphAnalysis } from "./Graph/GraphAnalysis";
import { GET_CAMPAIGN_LIST, GET_PAYMENT_DASHBOARD } from "../Insights/Query";
import { useQuery } from "@apollo/client";
import { methods } from "./methods";
import AddWithdrawalMethod from "./AddWithdrawalMethod/AddWithdrawalMethod";
import DocumentTitle from "../../../../DocumentTitle.js";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const dateIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const downgrey =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/grey down.svg";
const downgreyLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/greyDownLight.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const exclaim =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/exclaim.svg";

const Payment = ({ setWithdrawPopup, withdrawPopup }) => {
  DocumentTitle("Payments - Acceleraise");

  const themeMode = useSelector((state) => state.themeMode.mode);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [anchorElCampaign, setAnchorElCampaign] = useState(null);
  const [campaignList, setCampaignList] = useState(null);
  const [paymentDashboard, setPaymentDashboard] = useState(null);
  const [paymentGateways, setPaymentGateways] = useState({});
  const [paymentCurrencies, setPaymentCurrencies] = useState({});

  const [openWithdrawalPopup, setOpenWithdrawalPopup] = useState(null);
  const [selectedCampaignUuid, setSelectedCampaignUuid] = useState(null);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const { data: dashboard } = useQuery(GET_PAYMENT_DASHBOARD, {
    onCompleted: (result) => {
      if (result) {
        console.log("🚀 ~ Payment ~ result:", result);
        setPaymentDashboard(result?.ecoSystemDashboards);
        const formattedGateways = Object.entries(
          result?.ecoSystemDashboards?.topPaymentGateways
        )
          ?.sort((a, b) => a[1] - b[1])
          ?.map(([gateway, ranking]) => {
            return { ranking: ranking.toString(), gateway };
          });
        setPaymentGateways(result?.ecoSystemDashboards?.topPaymentGateways);
        setPaymentCurrencies(result?.ecoSystemDashboards?.mostUsedCurrencies);
      }
    },
  });

  const handleWithdrawMethodPopup = () => {
    setWithdrawPopup(true);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: { md: "40px", sm: "40px", xs: "20px" },
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "32px" },
            fontWeight: "800",
            textAlign: { md: "left", sm: "left", xs: "center" },
            color: themeMode === "dark" ? "#FFFF" : "#030303",
          }}
        >
          Payment Ecosystem
        </Typography>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          borderBottom:
            themeMode === "dark" ? "1px solid #FFFF" : "1px solid #909090",
          height: "fit-content",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Grid
          my={4}
          item
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Grid item md={6} sm={11} xs={12} sx={{ display: "flex" }}>
            <Grid
              item
              md={6}
              sm={5.7}
              xs={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: "600",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  textWrap: "wrap",
                }}
              >
                Total Raised by Campaigns
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={5.7}
              xs={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "24px", sm: "22px", xs: "22px" },
                  fontWeight: "600",
                  color: "#00F00A",
                  textWrap: "wrap",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {paymentDashboard?.totalRaisedByCampaign}
                {/* $10000 */}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            my={{ md: 0, sm: 0, xs: 1 }}
            item
            md={6}
            sm={11}
            xs={12}
            sx={{ display: "flex" }}
          >
            <Grid
              item
              md={6}
              sm={5.7}
              xs={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "16px", sm: "14px", xs: "14px" },
                  fontWeight: "600",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  textWrap: "wrap",
                }}
              >
                Total Withdrawn Amount
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={5.7}
              xs={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "24px", sm: "22px", xs: "22px" },
                  fontWeight: "600",
                  color: "#FF0000",
                  textWrap: "wrap",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {paymentDashboard?.totalWithdrawalAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          my={{ md: 4, sm: 4, xs: 2 }}
          item
          md={5}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "row" },
          }}
        >
          <Grid
            item
            mr={0.5}
            md={6}
            sm={6}
            xs={5.9}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", sm: "center" },
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => handleWithdrawMethodPopup()}
              sx={{
                background: "transparent",
                border: "2px solid #72A900",
                borderRadius: "30px",
                padding: "10px 30px",
                height: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  textTransform: "capitalize",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" },
                  fontWeight: "600",
                  textWrap: "nowrap",
                }}
              >
                Add Withdrawal Method
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            ml={0.5}
            md={6}
            sm={6}
            xs={5.9}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", sm: "center" },
              alignItems: "center",
            }}
          >
            <Button
              href="/dashboard/withdraw-donation"
              sx={{
                background:
                  "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                border: "2px solid transparent",
                borderRadius: "30px",
                padding: "10px 30px",
                height: "fit-content",
                "&:hover": {
                  background:
                    "linear-gradient(183.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#FFFF",
                  textTransform: "capitalize",
                  fontSize: { md: "14px", sm: "12px", xs: "10px" },
                  fontWeight: "600",
                  textWrap: "nowrap",
                }}
              >
                Withdraw Donation
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid my={2} item md={12} sm={12} xs={12}>
        <Button
          disableRipple
          sx={{
            padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
            color: "#FFF",
            background: "transparent",
            border: "1px solid #689900",
            borderRadius: "30px",
            textTransform: "capitalize",
            fontWeight: "600",
            flexWrap: "nowrap",
            margin: "10px 10px 10px 0px",
            width: "fit-content",
            "&:hover": {
              backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
            },
          }}
          onClick={(e) => {
            setAnchorElDateFilter(e.currentTarget);
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                fontSize: { md: "12px", sm: "12px", xs: "10px" },
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              <CalendarMonthIcon sx={{ color: "#9CCA3C", marginRight: { md: "10px", sm: "10px", xs: "5px" } }} />
              
              {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
              {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
            </Typography>
          </Grid>
        </Button>
        <Menu
          anchorEl={anchorElDateFilter}
          open={Boolean(anchorElDateFilter)}
          onClose={(e) => {
            setAnchorElDateFilter(null);
          }}
          sx={{
            "& .MuiPaper-root": {
              background: themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
              width: "fit-content",
              border: "2px solid #ABFC00",
              borderRadius: "40px",
            },
          }}
        >
          <MenuItem>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} style={{ display: "inline-block" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={dayjs(dateRange?.startDate)}
                    onChange={(date) =>
                      setDateRange((prevObj) => ({
                        ...prevObj,
                        startDate: date,
                      }))
                    }
                    maxDate={
                      dateRange?.endDate ? dayjs(dateRange?.endDate) : ""
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "inline-block", textAlign: "center" }}
              >
                <span>-</span>
              </Grid>
              <Grid item xs={5} style={{ display: "inline-block" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={dayjs(dateRange?.endDate)}
                    onChange={(date) =>
                      setDateRange((prevObj) => ({
                        ...prevObj,
                        endDate: date,
                      }))
                    }
                    minDate={
                      dateRange?.startDate ? dayjs(dateRange?.startDate) : ""
                    }
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </MenuItem>
        </Menu>

        <Button
          disableRipple
          sx={{
            padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
            color: "#FFF",
            background: "transparent",
            border: "1px solid #689900",
            borderRadius: "30px",
            textTransform: "capitalize",
            fontWeight: "600",
            flexWrap: "nowrap",
            margin: "10px 10px 10px 0px",
            width: "fit-content",
            "&:hover": {
              backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
            },
          }}
          onClick={(e) => {
            setAnchorElCampaign(e.currentTarget);
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                fontSize: { md: "12px", sm: "12px", xs: "10px" },
                display: "flex",
                alignItems: "center",
                textWrap: "nowrap",
              }}
            >
              Save Trees
              <img src={down} alt="" style={{ marginLeft: "10px" }} />
            </Typography>
          </Grid>
        </Button>
        <Menu
          anchorEl={anchorElCampaign}
          open={Boolean(anchorElCampaign)}
          onClose={(e) => {
            setAnchorElCampaign(null);
          }}
        >
          {campaignList?.map((option) => (
            <MenuItem
              key={option.uuid}
              value={option.uuid}
              onClick={() => {
                setSelectedCampaignUuid(option.uuid);
                setAnchorElCampaign(null);
              }}
              selected={option.uuid === selectedCampaignUuid}
            >
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <Grid item md={12} sm={12} xs={12} my={2} sx={{ position: "relative" }}>
        <Typography sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}>
          Active Payment Methods
        </Typography>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px 0px",
            overflowX: "auto",
            width: "100%",
          }}
        >
          {methods?.map((item, index) => (
            <Grid key={index} mr={1} mb={2} sx={{ position: "relative" }}>
              <Grid
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "10px",
                  zIndex: "5",
                }}
              >
                {!item.status && (
                  <img src={exclaim} alt="" style={{ zIndex: "10" }} />
                )}
              </Grid>

              <Grid
                my={1}
                container
                sx={{
                  backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px 0px blue"
                      : "4px 4px 14px 0px #00000024",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  zIndex: "3",
                }}
              >
                <img
                  src={themeMode === "dark" ? item.light : item.img}
                  alt={item.name}
                  style={{ opacity: !item.status ? "0.3" : "1" }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Grid item md={2.8} sm={5.8} xs={12} my={{ md: 0, sm: 1, xs: 1 }}>
          <Grid
            container
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              borderRadius: "15px",
              padding: "15px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    textWrap: "wrap",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Total Donation Raised
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#A1EE00",
                    fontSize: "40px",
                    fontWeight: "600",
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  {paymentDashboard?.totalDonationRaised}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2.8} sm={5.8} xs={12} my={{ md: 0, sm: 1, xs: 1 }}>
          <Grid
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              borderRadius: "15px",
              padding: "15px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    textWrap: "wrap",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    width: "100%",
                  }}
                >
                  Net Balance
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#A1EE00",
                    fontSize: "40px",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  {paymentDashboard?.netBalance}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2.8} sm={5.8} xs={12} my={{ md: 0, sm: 1, xs: 1 }}>
          <Grid
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              borderRadius: "15px",
              padding: "15px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Grid
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    textWrap: "wrap",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Withdrawn Amount
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#FF4D00",
                    fontSize: "40px",
                    fontWeight: "600",
                    textAlign: "right",
                    width: "100%",
                  }}
                >
                  {paymentDashboard?.totalWithdrawalAmount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={2.8} sm={5.8} xs={12} my={{ md: 0, sm: 1, xs: 1 }}>
          <Grid
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              borderRadius: "15px",
              padding: "15px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    fontWeight: "500",
                    textWrap: "wrap",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Suggested Amount Usage %
                </Typography>
              </Grid>
              <Grid
                md={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#A1EE00",
                    fontSize: "40px",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  60%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        my={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { md: "row", sm: "column", xs: "column" },
        }}
      >
        <Grid item md={3.9} sm={12} xs={12} my={1}>
          <GraphAnalysis
            revenueData={paymentDashboard?.totalWithdrawalAmount}
          />
        </Grid>
        <Grid
          item
          md={7.9}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Grid item md={5.9} sm={5.9} xs={12} my={1}>
            <PaymentGateway paymentGateways={paymentGateways} />
          </Grid>
          <Grid item md={5.9} sm={5.9} xs={12} my={1}>
            <MostUsedGateway paymentCurrencies={paymentCurrencies} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Payment;
