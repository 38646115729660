import { setContext } from '@apollo/client/link/context';


const graphqlAuthHeader = setContext((_, { headers }) => {
    // Get the JWT from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // return the headers to the context so httpLink can read them
   
    if (user && user['access-token'] && user['client'] && user['uid']) {
        return {
            headers: {
                ...headers,
                'Access-Token': user['access-token'],
                'Client': user['client'],
                'Uid': user['uid'],
            }
        };
    }
    return { headers };
});

export default graphqlAuthHeader;