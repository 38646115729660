import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@mui/material'

const StickyButtonElement = ({
    borderRadius,
    borderSize,
    buttonSize,
    labelColor,
    shadow,
    btnLabel,
    btnAlignment,
    btnPositionVert, 
    btnPositionHorz, 
    stickyBtnEnable,
    browser,
    backgroundColor, 
    iconColor, 
    borderColor 
  }) => {

  return (
    <>
      {/* <Grid
        sx={{
          padding: "0px",
          display: browser===2?"none":"flex",
          position: "absolute",
          transform: btnAlignment === 2 || btnAlignment === 3 ? "rotate(270deg)" : "",
          zIndex: 1000,
          top: btnAlignment === 1 ? '0px' //Top
            : btnAlignment === 2 && btnPositionVert === 1 ? "25%"  //Left-Right Top
              : btnAlignment === 2 && btnPositionVert === 2 ? "50%"  //Left-Right Center
                : btnAlignment === 2 && btnPositionVert === 3 ? "75%"  //Left-Right Bottom
                  : btnAlignment === 3 && btnPositionVert === 1 ? "25%"  //Left-Right Top
                    : btnAlignment === 3 && btnPositionVert === 2 ? "50%"  //Left-Right Center
                      : btnAlignment === 3 && btnPositionVert === 3 ? "75%"  //Left-Right Bottom
                        : "",
          bottom: btnAlignment === 4 ? '0px' : "", //Top

          left: btnAlignment === 1 && btnPositionHorz === 1 ? "15%" //Top-Bottom left
            : btnAlignment === 1 && btnPositionHorz === 2 ? "30%" //Top-Bottom center
              : btnAlignment === 4 && btnPositionHorz === 1 ? "15%" //Top-Bottom left
                : btnAlignment === 4 && btnPositionHorz === 2 ? "30%" //Top-Bottom center
                  : btnAlignment === 2 ? "-13%"
                    : "",
          right: btnAlignment === 1 && btnPositionHorz === 3 ? "10%" //Top-Bottom right
            : btnAlignment === 4 && btnPositionHorz === 3 ? "10%" //Top-Bottom right
              : btnAlignment === 3 ? "-13%" //Top-Bottom right
                : "",
          
        }}>
        <Button
          sx={{
            textTransform: 'capitalize',
            borderRadius: `${borderRadius}px`,
            backgroundColor: "#7CB602",
            padding: '8px 15px',
            boxShadow: shadow === true ? "2px 2px 5px #FFF" : "",
            border: `${borderSize}px solid #FFF`,
            display: stickyBtnEnable ? "flex" : "none",
            "&:hover": {
              backgroundColor: "#7CB602"
            },
            //backgroundColor:`${buttonColor}`,
            //borderColor:{borderColor},
            //borderColor: 'red',
            //borderWidth: { borderSize },
          }}
        >
          <Typography
            sx={{
              color: { labelColor },
              fontSize: `${buttonSize}px`,
              color: "#FFFF",
              display: "flex", flexDirection: "row",
            }}>
            <span style={{
              display: "flex", flexDirection: "row", width: "fit-content",
              alignItems: "center", margin: "0px 8px 0px"
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={`${buttonSize}px`} height={`${buttonSize}px`} fill="red">
                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
              </svg>
            </span>
            {btnLabel}
          </Typography>
        </Button>
      </Grid> */}
      <div style={{
        padding: '0px',
        display: browser === 2 ? 'none' : 'flex', // browser
        position: 'absolute',
        transform: btnAlignment === 2 || btnAlignment === 3 ? 'rotate(270deg)' : '', // btnAlignment
        zIndex: 1000,
        top: btnAlignment === 1 ? '0px' //Top
        : btnAlignment === 2 && btnPositionVert === 1 ? "25%"  //Left-Right Top
          : btnAlignment === 2 && btnPositionVert === 2 ? "50%"  //Left-Right Center
            : btnAlignment === 2 && btnPositionVert === 3 ? "75%"  //Left-Right Bottom
              : btnAlignment === 3 && btnPositionVert === 1 ? "25%"  //Left-Right Top
                : btnAlignment === 3 && btnPositionVert === 2 ? "50%"  //Left-Right Center
                  : btnAlignment === 3 && btnPositionVert === 3 ? "75%"  //Left-Right Bottom
                    : "",
        bottom: btnAlignment === 4 ? '0px' : "", //Top

        left: btnAlignment === 1 && btnPositionHorz === 1 ? "15%" //Top-Bottom left
          : btnAlignment === 1 && btnPositionHorz === 2 ? "30%" //Top-Bottom center
            : btnAlignment === 4 && btnPositionHorz === 1 ? "15%" //Top-Bottom left
              : btnAlignment === 4 && btnPositionHorz === 2 ? "30%" //Top-Bottom center
                : btnAlignment === 2 ? "-13%"
                  : "",
        right: btnAlignment === 1 && btnPositionHorz === 3 ? "10%" //Top-Bottom right
          : btnAlignment === 4 && btnPositionHorz === 3 ? "10%" //Top-Bottom right
            : btnAlignment === 3 ? "-13%" //Top-Bottom right
              : "",
        }}>
        <button style={{
          textTransform: 'capitalize',
          borderRadius: `${borderRadius}px`, // borderRadius
          backgroundColor: backgroundColor ? backgroundColor : '#7CB602',
          padding: '0px 15px',
          boxShadow: shadow === true ? '2px 2px 5px #FFF' : '', // shadow
          border: `${borderSize}px solid ${borderColor}`, // borderSize
          display: stickyBtnEnable ? 'flex' : 'none', // stickyBtnEnable
          '&:hover': {
            backgroundColor: backgroundColor ? backgroundColor : '#7CB602',
          },
        }} id='proceed-btn'>
          <p style={{
            fontSize: `${buttonSize}px`, // buttonSize
            color: labelColor, // labelColor
            display: 'flex',
            flexDirection: 'row',
          }}>
            <span style={{
              display: 'flex',
              flexDirection: 'row',
              width: 'fit-content',
              alignItems: 'center',
              margin: '0px 8px 0px 0px',
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={`${buttonSize}px`} height={`${buttonSize}px`} fill={iconColor}>
                <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
              </svg>
            </span>
            {btnLabel ? btnLabel : 'Donate'}
          </p>
        </button>
      </div>
    </>
  )
}
export default StickyButtonElement
