import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import NavBar from "../../../../Navbar/Navbar";
import Footer from "../../../../Footer/Footer";
import Specification from "./Specification/Specification";
import Essential from "./Essential/Essential";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import bkgLight from '../../../../../assets/FeatureLightTheme/DataExport/footerBkg.svg'
const bkg =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footer.svg";
  

const DataExport = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const light = "light";
  const dark = "dark";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {themeMode === "dark" ? (
        <NavBar
          scrollColor="#29070a"
          btnColor="#018C94"
          getStartedBorder="1px solid transparent"
          profileBkg="#2b1719"
          profileBorder="1px solid #a1020f"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      ) : (
        <NavBar
          scrollColor="#484B6A"
          btnColor="#8f94cf"
          getStartedBorder="1px solid transparent"
          profileBkg="#8b98d6"
          profileBorder="1px solid #018C94"
          logoTheme={dark}
          scrollingColor={light}
          txtColor="black"
        />
      )}

      {/* <NavBar
        scrollColor="#29070a"
        btnColor="#4D0E13"
        getStartedBorder="1px solid transparent"
        profileBkg="#2b1719"
        profileBorder="1px solid #a1020f"
      /> */}
      <Specification />

      <Essential />
{
  themeMode==="dark"?
          <Footer
            bkg={bkg}
            btnText="#FFFF"
            talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
            btnHover="#1a0101"
            btnBorder="0.5px solid #689900"
            textHover="#FFFF"
            brightness="brightness(110%)"
          />
          :
          <Footer
            bkg={bkgLight}
            btnText="#030303"
            talkBtn="#FFF"
            btnHover="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
            btnBorder="0.5px solid #689900"
            textHover="#FFFF"
            brightness="brightness(100%)"
          />
}
      
    </>
  );
};
export default DataExport;
