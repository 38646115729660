import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },
  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    
    fontWeight: "500",
    textTransform: "capitalize",
  },
  neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  fw600: {
    fontWeight: "600",
    textWrap: "wrap",
  },
  modeButton: {
    
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    
  },
  fs18: {
    fontWeight: "600",
    width: "100%",
  },
  justifyCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  quick: {
    fontSize: "20px",
    fontWeight: "600",
  },
  links: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "500",
    color: "#8ED003",
    textTransform: "capitalize",
    display: "block",
    cursor:"pointer",
  },
  rowStart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  fs16: {
    fontSize: "16px",
    marginTop: "2px",
  },
  url: {
    fontSize: "16px",
    fontWeight: "600",
  },
  input: {
    mr: 1,
    mb: 3,
    
    borderRadius: "10px",
    
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8ED003",
      },
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  select: {
    mt: 1,
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
    },
    
    borderRadius: "10px",
    width: "100%",
    
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
    
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  menuItem: {
    fontWeight: "600",
    "&:hover": {
      color: "#689900",
    },
    "&:focus": {
      color: "#689900",
    },
  },
  preview: {
    
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover":{
      border: "1px solid #689900",
    }
  },
  save: {
    color: "#FFF",
    background: "#659500",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      border: "1px solid #689900",
      backgroundColor: "#8ED003",
    },
  },
}));
