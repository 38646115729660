import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  bkg: {
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "620px",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  column: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  spaceEvenly: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  signUp: {
    color: "#FFF",
    background: "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
    borderRadius: "30px",
    flexWrap: "nowrap",
    textTransform: "capitalize",
    fontWeight: "600",
    textWrap: "nowrap",
    marginRight: "5px",
    border: "2px solid transparent",
    "&:hover": {
      background: "#081a01",
      border: "2px solid #689900",
    },
  },
  feature: {
    background: "transparent",
    border: "2px solid #689900",
    borderRadius: "30px",
    flexWrap: "nowrap",
    textTransform: "capitalize",
    fontWeight: "600",
    textWrap: "nowrap",
    backdropFilter: "blur(3px)",
    marginLeft: "5px",
  },
});
