export const infographics = [
    {
        id:0,
        text:"Per Day Collection",
        enable: true
    },
    {
        id:1,
        text:"Raised Time to Time",
        enable: true
    },
    {
        id:2,
        text:"Top Payment Gateway",
        enable: true
    },
    {
        id:3,
        text:"Goal Details",
        enable: false
    },
    {
        id:4,
        text:"Top Donation Countries",
        enable: false
    },
    {
        id:5,
        text:"Top Supporters",
        enable: true
    },
    {
        id:6,
        text:"Highest Collection",
        enable: false
    },
];
export const details = [
    {
        id:0,
        text:"Creation Date",
        enable: true
    },
    {
        id:1,
        text:"Total Raised",
        enable: true
    },
    {
        id:2,
        text:"P2P Collection",
        enable: true
    },
    {
        id:3,
        text:"Recurring Plans",
        enable: true
    },
    {
        id:4,
        text:"P2P Fundraisers",
        enable: true
    },
]

export const addInfo = [
    {
        id:0,
        text:"Email Settings",
        enable: false
    },
    {
        id:1,
        text:"P2P Settings",
        enable: false
    },
    {
        id:2,
        text:"Checkout Modal Settings",
        enable: false
    },
    {
        id:3,
        text:"Campaign Settings",
        enable:true
    },
    
]

