import { Grid, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { category } from "./data";
import { useStyles } from "./useStyles";
import { useStylesLight } from "./useStylesLight";

const ramadan = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramadan.svg";

const Stories = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  const classesLight = useStylesLight();
  const [selected, setSelected] = useState(0);
  const handleBtnSelect = (value) => {
    setSelected(value);
  };

  if (themeMode === "dark") {
    return (
      <>
        <Grid
          className={classes.bkg}
          container
          sx={{
            padding: {
              md: "130px 0px 10px",
              sm: "130px 30px 10px",
              xs: "100px 30px 10px",
            },
          }}
        >
          <Grid
            className={classes.columnCenter}
            item
            md={7.5}
            sm={10}
            xs={11}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Typography
              className={classes.upper700}
              sx={{
                fontSize: { md: "40px", sm: "36px", xs: "34px" },
                fontFamily: "Stolzl",
              }}
            >
              Take a look at our stories and ideas.
            </Typography>
            <Typography sx={{ fontFamily: "Stolzl" }}>
              Discover actionable insights and success stories to boost your
              earning potential. Gain valuable tips and techniques to expedite
              your journey toward a well-deserved salary increase.
            </Typography>
          </Grid>
          <Grid item md={6} sm={10} my={2} className={classes.overflowx}>
            {category?.map((item, index) => (
              <Button
                disableRipple
                onClick={() => handleBtnSelect(item.id)}
                item
                key={index}
                className={classes.btnList}
                sx={{
                  backgroundColor:
                    selected === item.id ? "#79B200 !important" : "#130407",
                  padding: {
                    md: "10px 18px",
                    sm: "10px 18px",
                    xs: "10px 30px",
                  },
                  mx: { md: 1, sm: 1, xs: 1 },
                }}
              >
                <Typography
                  mx={{ md: 0, sm: 0, xs: 1 }}
                  className={classes.btnText}
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "10px" },
                  }}
                >
                  {item.name}
                </Typography>
              </Button>
            ))}
          </Grid>
          <Grid
            my={3}
            item
            className={classes.jCenter}
            md={11}
            sm={10}
            xs={12}
            sx={{
              flexDirection: { md: "row", sm: "column", xs: "column" },
              width: { md: "40%", sm: "auto", xs: "auto" },
            }}
          >
            <Grid item md={12}>
              <Grid
                className={classes.ramadan}
                sx={{
                  backgroundPosition: {
                    md: "center center",
                    sm: "center center",
                    xs: "20% 10%",
                  },
                }}
              >
                <Grid
                  className={classes.captionBox}
                  item
                  md={12}
                  sx={{
                    padding: {
                      md: "20px 50px",
                      sm: "20px 50px",
                      xs: "15px 10px",
                    },
                  }}
                >
                  <Typography
                    className={classes.captionHead}
                    sx={{
                      fontSize: { md: "40px", sm: "34px", xs: "18px" },
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    Empowering ramadan generosity
                  </Typography>
                  <Typography
                    className={classes.caption2}
                    sx={{
                      fontSize: { md: "20px", sm: "17px", xs: "10px" },
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    Consideration for non-profit fundraising
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          className={classesLight.bkg}
          container
          sx={{
            padding: {
              md: "130px 0px 10px",
              sm: "130px 30px 10px",
              xs: "100px 30px 10px",
            },
          }}
        >
          <Grid
            className={classesLight.columnCenter}
            item
            md={7.5}
            sm={10}
            xs={11}
            sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
          >
            <Typography
              className={classesLight.upper700}
              sx={{
                fontSize: { md: "40px", sm: "36px", xs: "34px" },
                fontFamily: "Stolzl",
              }}
            >
              Take a look at our stories and ideas.
            </Typography>
            <Typography sx={{ fontFamily: "Stolzl", color: "black" }}>
              Discover actionable insights and success stories to boost your
              earning potential. Gain valuable tips and techniques to expedite
              your journey toward a well-deserved salary increase.
            </Typography>
          </Grid>
          <Grid item md={6} sm={10} my={2} className={classesLight.overflowx}>
            {category?.map((item, index) => (
              <Button
                disableRipple
                onClick={() => handleBtnSelect(item.id)}
                item
                key={index}
                className={classesLight.btnList}
                sx={{
                  color: "black",
                  backgroundColor:
                    selected === item.id ? "#79B200 !important" : "white",
                  padding: {
                    md: "10px 18px",
                    sm: "10px 18px",
                    xs: "10px 30px",
                  },
                  mx: { md: 1, sm: 1, xs: 1 },
                }}
              >
                <Typography
                  mx={{ md: 0, sm: 0, xs: 1 }}
                  className={classesLight.btnText}
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "10px" },
                  }}
                >
                  {item.name}
                </Typography>
              </Button>
            ))}
          </Grid>
          <Grid
            my={3}
            item
            className={classesLight.jCenter}
            md={11}
            sm={10}
            xs={12}
            sx={{
              flexDirection: { md: "row", sm: "column", xs: "column" },
              width: { md: "40%", sm: "auto", xs: "auto" },
            }}
          >
            <Grid item md={12}>
              <Grid
                className={classesLight.ramdanLight}
                sx={{
                  backgroundPosition: {
                    md: "center center",
                    sm: "center center",
                    xs: "20% 10%",
                  },
                }}
              >
                {/* <Grid
                  className={classesLight.captionBox}
                  item
                  md={12}
                  sx={{
                    padding: {
                      md: "20px 50px",
                      sm: "20px 50px",
                      xs: "15px 10px",
                    },
                  }}
                >
                  <Typography
                    className={classesLight.captionHead}
                    sx={{
                      fontSize: { md: "40px", sm: "34px", xs: "18px" },
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    Empowering ramadan generosity
                  </Typography>
                  <Typography
                    className={classesLight.caption2}
                    sx={{
                      fontSize: { md: "20px", sm: "17px", xs: "10px" },
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    Consideration for non-profit fundraising
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
};
export default Stories;
