import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  blurBkg:{
    position: "absolute",
          top: "0px",
          backdropFilter: "blur(11px)",
          height: "100%",
          zIndex: "99",
          width: "100%",
  },
  closeButtonHolder:{
    display: "flex", width: "100%", justifyContent: "flex-end" 
  },
  closeButton:{
        "&:hover":{
                transform:"scale(1.2)",
                backgroundColor:"transparent"
              }
  },
  holder:{
    textAlign: "center", height: "fit-content",fontWeight: "600",
  },
  neon:{
     color: "#94D906", fontWeight: "700" 
  },
  mapped:{
    display: "flex",
              justifyContent: "space-between",
  },
  mapItem:{
    display: "flex",
                  flexWrap: "nowrap",
                  "&:hover":{transform:"scale(1.1)"}
  },
  imgHolder:{
    
                    display: "flex",
                    alignItems: "center",
  }
})
