import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";
import { useStyles } from "./useStyles";

const supportBlur = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/support blur.png";
const support247 = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/supportCenter.png";
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/support.svg";

const Support24_7 = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Link to="/support" style={{ textDecoration: "none" }}>
      <Grid
        className={classes.bkg2}
        container
        sx={{
          padding: { md: "100px", sm: "100px 70px", xs: "100px 30px" },
          flexDirection: {
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          },
          backgroundImage: `url('${supportBlur}')`,
        }}
      >
        <Grid mt={{ md: 0, sm: 4, xs: 6 }}>
          {themeMode === "dark" ? (
            <img
              src={support247}
              alt="Support Management"
              className={classes.fullWidth}
            />
          ) : (
            <img
              src={light}
              alt="Support Management"
              className={classes.fullWidth}
            />
          )}
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            paddingLeft: { md: "70px", sm: "0px", xs: "0px" },
            textAlign: { md: "right", sm: "center", xs: "center" },
          }}
        >
          <Typography
            className={classes.title}
            sx={{
              color: themeMode === "dark" ? "#018C94" : "#FFFF",
              fontFamily: "Stolzl",
            }}
          >
            24/7 Support
          </Typography>
          <Typography sx={{ fontFamily: "Stolzl" }}>
            Provides round-the-clock assistance to ensure smooth operation and
            address any issues or inquiries that may arise, enhancing user
            satisfaction and trust.
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};
export default Support24_7;
