import { Grid, Typography, Button } from "@mui/material";
import {useStyles} from "./useStyles";
import { useSelector } from "react-redux";

const donation = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/donationBkg.svg";
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/donation.png";

const Donation = () => {
  const classes=useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid className={classes.bkg}
        sx={{background: themeMode==="dark"?"transparent":"#FFDC3D"}}
      >
        <Grid
          container
          className={classes.cover}
          sx={{
            backgroundImage: themeMode==="dark"?`url('${bkg}')`:`url('${donation}')`,
            backgroundSize: {
              md: themeMode==="dark"?"50% auto":"60% auto",
              sm: "90% auto",
              xs: "120% auto",
            },
          }}
        >
          <Grid
            item
            md={9}
            sm={10}
            xs={10}
            className={classes.column}
          >
            <Grid className={classes.blur}
              sx={{
                backdropFilter: {
                  md: "blur(21px)",
                  sm: "blur(11px)",
                  xs: "blur(11px)",
                },
                backgroundColor:themeMode==="dark"? "transparent":"#ECECEC66",
                boxShadow: themeMode==="dark"?"0px 0px 0px red": "0px 8px 5px 0px #00000040",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background:themeMode==="dark"? "linear-gradient(145deg, white, transparent)":
                    "linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
            >
              
                <Typography className={classes.fw700}
                  sx={{
                    width:"100% !important",
                    textAlign: { md: "left", sm: "left" },
                    fontSize: { md: "48px", sm: "40px", xs: "30px" },    
                    color:themeMode==="dark"? "#FFFF":"#030303",
                    fontFamily:"Stolzl"
                  }}
                >
                  DONATION MANAGEMENT
                </Typography>
              
              <Grid className={classes.flex}
                sx={{
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  fontSize: { md: "16px", sm: "16px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                }}
              >
                <Typography className={classes.padW400} variant="inherit"
                  sx={{color:themeMode==="dark"? "#FFFF":"#030303",fontFamily:"Stolzl"}}>
                  The "Donation Management" feature in Acceleraise streamlines the process of handling donations, offering users a comprehensive toolkit for tracking, managing, and optimizing fundraising efforts. This feature simplifies the administrative tasks associated with donations, enabling organizations to focus more on their missions and less on the logistical complexities of fundraising.
                </Typography>

                <Typography className={classes.padW400} variant="inherit">
                  
                </Typography>
              </Grid>
            </Grid>
            <Grid my={5} item className={classes.centerRow}>
              <Button className={classes.btn} href="/features">
                Learn More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Donation;
