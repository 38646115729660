import NavBar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Stories from "./Stories/Stories";
import Gallery from "./Gallery/Gallery";
import { useSelector } from "react-redux";
import DocumentTitle from "../../../DocumentTitle";

const bkg =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footer.svg";

const Learn = () => {
  DocumentTitle("Learn - Acceleraise");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const light = "light";
  const dark = "dark";
  return (
    <>
      {themeMode === "dark" ? (
        <NavBar
          scrollColor="#29070a"
          btnColor="#018C94"
          getStartedBorder="1px solid transparent"
          profileBkg="#2b1719"
          profileBorder="1px solid #a1020f"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      ) : (
        <NavBar
            scrollColor="#FF4B55"
          btnColor="#7CB602"
          getStartedBorder="1px solid transparent"
          profileBkg="#8b98d6"
          profileBorder="1px solid black"
          logoTheme={dark}
          scrollingColor={dark}
          txtColor="black"
        />
      )}

      <Stories />
      <Gallery />
      <Footer
        bkg={bkg}
        btnText="#FFFF"
        talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
        btnHover="#1a0101"
        btnBorder="0.5px solid #689900"
        textHover="#FFFF"
        brightness="brightness(110%)"
      />
    </>
  );
};
export default Learn;
