import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";

const mobLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/privacy/mobLight.svg"
const mobDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/privacy/mobDark.svg"
const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/privacy/heroBkgDark.svg"
const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/privacy/heroBkgLight.svg"

const Hero = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          backgroundImage: {
            md: themeMode === "dark" ? `url('${bkg}')` : `url('${light}')`,
            sm: themeMode === "dark" ? `url('${bkg}')` : `url('${light}')`,
            xs: themeMode === "dark" ? `url('${mobDark}')` : `url('${mobLight}')`,
          },
          backgroundSize: "cover",
          backgroundPosition: { md: "center", sm: "center", xs: "90% center" },
        }}
      >
        <Grid
          item
          md={7.5}
          sm={7.5}
          xs={9.5}
          className={classes.column}
          sx={{
            fontSize: { md: "16px", xs: "16px", xs: "14px" },
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              fontFamily: "Stolzl",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
            }}
          >
            Acceleraise embodies the spirit of innovative fundraising and
            strategic guidance for the nonprofit sector. Established to bridge
            the gap between missions and their achievement, Acceleraise has been
            a beacon of support since its inception.
          </Typography>
          <Grid
            my={3}
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.spaceEvenly}
            sx={{
              width: { md: "40%", sm: "80%", xs: "100%" },
            }}
          >
            <Button
              className={classes.signUp}
              href={!isAuthenticated ? "/signup" : "/dashboard"}
              sx={{
                fontSize: { md: "15px", sm: "15px", xs: "12px" },
                padding: { md: "5px 15px", sm: "5px 15px", xs: "5px 25px" },
              }}
            >
              {!isAuthenticated ? "Register with us" : "Dashboard"}
            </Button>
            <Button
              className={classes.feature}
              href="/features"
              ml={4}
              sx={{
                fontSize: { md: "15px", sm: "15px", xs: "12px" },
                padding: { md: "5px 15px", sm: "5px 15px", xs: "5px 25px" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                "&:hover": {
                  background: "#081a01",
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              Explore Features
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Hero;
