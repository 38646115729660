import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";


const Email = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Email.png";
const hearts = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/hearts.png";
const TwitterX = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/TwitterX.png";
const Facebook = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Facebook.png";
const LinkedIn = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/LinkedIn.png";
const Mail = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Mail.png";
const Instagram = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Instagram.png";
const YouTube = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/YouTube.png";
const tributeLetter = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/tributeLetter.png";


export const TributeLetter = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between w-full items-center">
          <Typography className="email-preview-title">
            Tribute E-Card preview
          </Typography>
          <Button
            style={{
              border:
                themeMode === "dark"
                  ? "2px solid rgba(255, 255, 255, 1)"
                  : "2px solid rgba(0, 0, 0, 1)",
              borderRadius: "17px",
              color: themeMode === "dark" ? "white" : "black",
            }}
            className="email-preview-title"
          >
            <img src={Email} alt="email" />
            <Typography className="email-preview-title ml-2">
              Send test email
            </Typography>
          </Button>
        </div>
        <div>
          <div
            className={
              themeMode === "dark"
                ? "mt-8 email-preview-box p-6"
                : "mt-8 email-preview-box-light p-6"
            }
          >
            {" "}
            <Typography className="email-preview-subtitle">
              {`From: Imam Connect Welfare Grant <noreply@acceleraise.com> `}
            </Typography>
            <Typography className="email-preview-subtitle mt-4">
              {`Reply-To: Imam Connect Welfare Grant <admin@imamconnect.com>`}
            </Typography>
            <Typography className="email-preview-subtitle mt-4">
              {`Subject: Thankyou  for your help!`}
            </Typography>
          </div>
        </div>

        <div className="mt-12">
          <div className="flex flex-col justify-center">
            <Typography className="mt-8 text-center email-preview-title2 mb-2">
              Thank you for your help!
            </Typography>
            <img src={tributeLetter} alt="heart" className="w-full" />
          </div>
          <div className="flex justify-center">
            <div className="lg:w-2/3">
              <Typography className="mt-8 email-preview-subtitle">
                The donation made in honor of John Smith will be put to use
                right away, helping us accomplish our mission
              </Typography>
              <Typography className="my-8 email-preview-subtitle">
                Thank you for playing a part in our work.
              </Typography>
              <Typography className="email-preview-subtitle">
                - Your friends at Imam Connect Welfare Grant.{" "}
              </Typography>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="mt-8 lg:w-1/2 ">
            <Button
              variant="contained"
              className="w-full py-3 mt-4"
              sx={{
                backgroundColor: themeMode === "dark" ? "#D9D9D9" : "#D9D9D940",
                borderRadius: "10px",
              }}
            >
              <span
                className={
                  themeMode === "dark"
                    ? "pre-donation-btn text-black flex space-x-4"
                    : "pre-donation-btn text-black flex space-x-4"
                }
              >
                <img src={TwitterX} />
                <span className="mx-2">Follow us on Twitter</span>
              </span>
            </Button>
            <Button
              variant="contained"
              className="w-full py-3 mt-4"
              sx={{
                backgroundColor: themeMode === "dark" ? "#D9D9D9" : "#D9D9D940",
                borderRadius: "10px",
              }}
            >
              <span
                className={
                  themeMode === "dark"
                    ? "pre-donation-btn text-black flex space-x-4"
                    : "pre-donation-btn text-black flex space-x-4"
                }
              >
                <img src={Instagram} width={20} height={20} />
                <span className="mx-2">Follow us on Instagram</span>
              </span>
            </Button>
            <Button
              variant="contained"
              className="w-full py-3 mt-4"
              sx={{
                backgroundColor: themeMode === "dark" ? "#D9D9D9" : "#D9D9D940",
                borderRadius: "10px",
              }}
            >
              <span
                className={
                  themeMode === "dark"
                    ? "pre-donation-btn text-black flex space-x-4"
                    : "pre-donation-btn text-black flex space-x-4"
                }
              >
                <img src={Facebook} />
                <span className="mx-2">Like us on Facebook</span>
              </span>
            </Button>

            <Button
              variant="contained"
              className="w-full py-3 mt-4"
              sx={{
                backgroundColor: themeMode === "dark" ? "#D9D9D9" : "#D9D9D940",
                borderRadius: "10px",
              }}
            >
              <span
                className={
                  themeMode === "dark"
                    ? "pre-donation-btn text-black flex space-x-4"
                    : "pre-donation-btn text-black flex space-x-4"
                }
              >
                <img src={YouTube} width={20} height={20} />
                <span className="mx-2">Subscribe us on Youtube</span>
              </span>
            </Button>
            <Button
              variant="contained"
              className="w-full py-3 mt-4"
              sx={{
                backgroundColor: themeMode === "dark" ? "#D9D9D9" : "#D9D9D940",
                borderRadius: "10px",
              }}
            >
              <span
                className={
                  themeMode === "dark"
                    ? "pre-donation-btn text-black flex space-x-4"
                    : "pre-donation-btn text-black flex space-x-4"
                }
              >
                <img src={LinkedIn} />
                <span className="mx-2">Follow us on LinkedIn</span>
              </span>
            </Button>
          </div>
        </div>

        <div className="flex justify-center border-t-2 pt-4 mt-4">
          <Typography className="email-preview-subtitle text-center mt-4">
            Imam Connect Welfare Grant is a registered CIC in the UK.{" "}
          </Typography>
        </div>
      </div>
    </div>
  );
};
