export const methods = [
    {
        id:0,
        name:"Paypal",
    },
    {
        id: 1,
        name: "Stripe",
    },
    {
        id:2,
        name:"Credit / Debit Card",
    },
    {
        id: 3,
        name: "Payoneer",
    },
    {
        id: 4,
        name: "GPay",
    },
    {
        id: 5,
        name: "Apple Pay",
    },
];

export const suggestion = [
    {
        id: 0,
        name: "1000",
        value: 1000
    },
    {
        id: 1,
        name: "500",
        value: 500,
        show: 2
    },
    {
        id: 2,
        name: "200",
        value: 200,
        
    },
    {
        id: 3,
        name: "100",
        value: 100,
        show: 4
        
    },
    {
        id: 4,
        name: "50",
        value: 50,
    },
    {
        id: 5,
        name: "20",
        value: 20,
        show: 6
    },
]

export const freq=[
    {
        id:0,
        name:"Once",
        value: "once",
        show:"1"
    },
    {
        id: 1,
        name: "Weekly",
        value: "weekly",
        show:"2"
    },
    {
        id: 2,
        name: "Monthly",
        value: "monthly",
        show:"3"
    },
    {
        id: 3,
        name: "Quarterly",
        value: "quarterly",
        show:"4"
    }
]