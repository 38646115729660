import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  Input,
} from "@mui/material";
import { useSelector } from "react-redux";
import { OverviewList } from "../overviewList";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import { ArrowRightAlt } from "@mui/icons-material";
import { useStyles } from "./useStyles";
import { publishData, defaultPublishData } from "../../data";
import { MySwitch, MyTextField, donationType } from "../MyComponents";

import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CAMPAIGN,
  PUBLISH_CAMPAIGN,
  UNPUBLISH_CAMPAIGN,
  UPDATE_SELECTED_TEMPLATE,
  UPDATE_CAMPAIGN_TEMPLATE,
  CREATE_CAMPAIGN_TEMPLATE,
} from "../Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { AlertDialog } from "../../../../../utils/alertDialog";
import DonationCheckout from "../../../../DonationCheckout/DonationCheckout";

const template =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/template.svg";
const select =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/selectElements.svg";
const customize =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/customize.svg";
const embed =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/embed.svg";
const logo =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logo.png";
const logoLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const heartDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/campaign.svg";
const heart =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/campaigns.png";
const upload =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/upload.svg";
const cross =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg";
const crossDark =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/cross.svg";

const steps = [
  {
    id: 1,
    img: select,
    text: "Select the type of element you want to create",
  },
  {
    id: 2,
    img: customize,
    text: "Enter properties and create the elements.",
  },
  {
    id: 3,
    img: embed,
    text: "Copy the HTML Embed Code & paste this into website’s code",
  },
];

const PublishCampaign = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [mode, setMode] = useState("Donation");

  const [donationForm, setDonationForm] = useState({...defaultPublishData[0]});
  const [customForm, setCustomForm] = useState(null);
  const [formSelection, setFormSelection] = useState(null);
  const [campaignObj, setCampaignObj] = useState({
    uuid: uuid,
    status: null,
    template: null,
    campaignTemplates: null,
    title: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const { data: campaign } = useQuery(GET_CAMPAIGN, {
    variables: { uuid },
    onCompleted: (result) => {
      if (result?.campaign) {
        setCampaignObj((prevObj) => ({
          ...prevObj,
          uuid: uuid,
          status: result?.campaign?.status,
          template: result?.campaign?.template,
          campaignTemplates: result?.campaign?.campaignTemplates,
          title: result?.campaign?.title,
        }));
        let selectedTemplate = result?.campaign?.campaignTemplates?.nodes?.find(
          (data) => data?.active && data?.name
        );
        const form = defaultPublishData?.find(
          (template) => template.name === selectedTemplate?.name
        );
        selectedTemplate = { ...selectedTemplate, id: form?.id };
        if (selectedTemplate) setDonationForm(selectedTemplate);
      }
    },
  });

  const SelectDonationForm = (index) => {
    const form = defaultPublishData[index];
  };
  const handleFormSelection = (index) => {
    setFormSelection(index);
  };
  const SelectCustomForm = (index) => {
    setCustomForm(index);
  };
  const [
    SavePublishCampaign,
    { loading: publishCampaignLoading, error: publishCampaignError },
  ] = useMutation(PUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request to publish campaign was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("your request to publish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.publishCampaign?.campaign?.status,
      }));
    },
  });
  const [
    SaveUnPublishCampaign,
    { loading: unPublishCampaignLoading, error: unPublishCampaignError },
  ] = useMutation(UNPUBLISH_CAMPAIGN, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to unpublish campaign was unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(success("your request to unpublish campaign was successful!"));
      setCampaignObj((prevObj) => ({
        ...prevObj,
        status: data?.unpublishCampaign?.campaign?.status,
      }));
    },
  });
  const [
    UpdateSelectedTemplate,
    { loading: updateCampaignLoading, error: updateCampaignError },
  ] = useMutation(UPDATE_SELECTED_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
    },
    onCompleted: (data) => {},
  });
  const [
    UpdateCampaignTemplate,
    {
      loading: updateCampaignTemplateLoading,
      error: updateCampaignTemplateError,
    },
  ] = useMutation(UPDATE_CAMPAIGN_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to update campaign template was unsuccessful!")
      );
    },
    onCompleted: (data) => {
      dispatch(
        success("your request to update campaign template was successful!")
      );
    },
  });
  const [
    CreateCampaignTemplate,
    {
      loading: createCampaignTemplateLoading,
      error: createCampaignTemplateError,
    },
  ] = useMutation(CREATE_CAMPAIGN_TEMPLATE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(
        errorMsg("your request to save selected template unsuccessful!")
      );
    },
    onCompleted: (data) => {
      // console.log("Mutation Completed:", data);
      dispatch(success("your request to save selected template successful!"));
    },
  });
  const DonationMode = () => {
    setMode("Donation");
  };
  const ElementMode = () => {
    setMode("Element");
  };
  const handleSave = async (form) => {
    const variables = {
      uuid: uuid,
    };
    dispatch(setSpinnerLoading(true));
    try {
      // de-active previous selected active template.
      let updatedNodes = [];
      campaignObj?.campaignTemplates?.nodes?.forEach(async (item) => {
        if (item.active && item.uuid) {
          const variables = {
            uuid: item.uuid,
            active: false,
          };
          const resultDeSelectedTemplate = await UpdateCampaignTemplate({
            variables,
          });
          const updatedItem = { ...item, active: false };
          updatedNodes.push(updatedItem);
        } else {
          updatedNodes.push(item);
        }
      });
      campaignObj.campaignTemplates = {
        ...campaignObj.campaignTemplates,
        nodes: updatedNodes,
      };
      setCampaignObj(campaignObj);

      if (campaignObj?.status === "unpublish") {
        // check if templateObj exist in campaign.
        const templateObj = campaignObj?.campaignTemplates?.nodes?.find(
          (item) => item.name === form?.name && item.uuid
        );
        if (!!templateObj) {
          // if templateObj exist in campaign then update its to active status.
          const variables = {
            uuid: templateObj.uuid,
            name: form?.name,
            template: form?.template,
            active: true,
          };
          if (variables?.uuid && variables?.name && variables?.active) {
            const resultTemplate = await UpdateCampaignTemplate({ variables });
            const templateObj = resultTemplate.data.updateCampaignTemplate.campaignTemplate;
            let templateArray = campaignObj.campaignTemplates.nodes
              ? [...campaignObj.campaignTemplates.nodes]
              : [];
            templateArray = [...templateArray, templateObj];
            campaignObj.campaignTemplates = {
              ...campaignObj.campaignTemplates,
              nodes: templateArray,
            };
            setCampaignObj(campaignObj);
          }
        } else {
          // if templateObj doesn't exist in campaign then create with active status.
          const variables = {
            campaignUuid: uuid,
            name: form?.name,
            template: form?.template,
            active: true,
          };
          if (variables?.campaignUuid && variables?.name && variables?.active) {
            const resultTemplate = await CreateCampaignTemplate({ variables });
            const templateObj = resultTemplate.data.createCampaignTemplate.campaignTemplate;
            let templateArray = campaignObj.campaignTemplates.nodes
              ? [...campaignObj.campaignTemplates.nodes]
              : [];
            templateArray = [...templateArray, templateObj];
            campaignObj.campaignTemplates = {
              ...campaignObj.campaignTemplates,
              nodes: templateArray,
            };
            setCampaignObj(campaignObj);
          }
        }
        // set state for new selected donation form.
        const selectedTemplate = { ...form, active: true };
        setDonationForm(selectedTemplate);
        // publish campaign 
        const result = await SavePublishCampaign({ variables });
        if (result?.errors?.message) {
          const sentencesArray = JSON.parse(result?.errors?.message);
          setDialogContent(
            sentencesArray ? sentencesArray : result?.errors?.message
          );
          setOpenDialog(true);
        }
      } else {
        // Unpublish campaign 
        const result = await SaveUnPublishCampaign({ variables });
      }
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };
  const copyToClipboard = () => {
    const selectedFormUrl = `${
      process.env.REACT_APP_FRONTEND_URL
    }/campaign-donation/${donationForm?.id ? donationForm?.id : 0}/${uuid}`;
    navigator.clipboard
      .writeText(selectedFormUrl)
      .then(() => {
        dispatch(success("URL copied to clipboard"));
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                display: { md: "block", sm: "block", xs: "flex" },
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>
            </Grid>

            <Grid className={classes.fullWidth}>
              <Button
                className={classes.transButton}
                href="/dashboard/campaigns"
                sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
              >
                <Grid className={classes.flexCenter}>
                  <img
                    src={themeMode === "dark" ? heart : heartDark}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    className={classes.fw500}
                    sx={{
                      fontSize: { md: "16px", sm: "16px", xs: "9px" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    Campaigns
                  </Typography>
                </Grid>
              </Button>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                className={classes.fw600}
                sx={{
                  fontSize: { md: "40px", sm: "40px", xs: "26px" },
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                {campaignObj?.title?.length > 15
                  ? `${campaignObj.title.substring(0, 15)}...`
                  : campaignObj.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Unscroll */}
        <Grid
          my={2}
          pb={2}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            flexDirection: "row",
            width: { sm: "100%", xs: "fit-content" },
            justifyContent: "space-between",
            justifyContent: { md: "space-between", sm: "flex-start" },
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
            overflowX: "auto",
          }}
        >
          {OverviewList?.map((item, index) => (
            <>
              <Button
                onClick={() => setSelectedOption(item.option)}
                key={index}
                disableElevation
                disableRipple
                component={Link}
                to={`${item.link}/${uuid}/${campaignObj?.title}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  background:
                    item.id === 5
                      ? "#689900"
                      : themeMode === "dark"
                      ? "#0F0F0F"
                      : "transparent",
                  border: "1px solid #689900",
                  borderRadius: "30px",
                  padding: {
                    md: "10px 15px 10px 5px",
                    sm: "10px 15px",
                    xs: "10px 15px",
                  },
                  width: { md: "fit-content", sm: "fit-content", xs: "100%" },
                  "&:hover": {
                    background:
                      item.id === 5
                        ? "#689900"
                        : themeMode === "dark"
                        ? "#2e3322"
                        : "#e3fca7",
                  },
                }}
              >
                <Typography
                  ml={1}
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { md: "14px", sm: "12px", xs: "12px" },
                    textWrap: "nowrap",
                    width: { md: "100%", sm: "fit-content", xs: "fit-content" },
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    color:
                      themeMode === "dark"
                        ? "#FFFF"
                        : item.id === 5
                        ? "#FFFF"
                        : "#030303",
                  }}
                >
                  {themeMode === "dark" ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : item.id === 5 ? (
                    <img
                      src={item.img}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  ) : (
                    <img
                      src={item.light}
                      alt=""
                      style={{ margin: "0px 5px 0px 0px" }}
                    />
                  )}
                  {item.text}
                </Typography>
                {/* </Grid> */}
              </Button>
              {/* {index !== OverviewList.length - 1 && (
                <Grid
                  key={`arrow-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: { md: "scale(1.3)", sm: "scale(1)" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  <ArrowRightAlt />
                </Grid>
              )} */}
            </>
          ))}
        </Grid>

        {/* Scroll */}
        <Grid
          container
          spacing={1.4}
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            width: { md: "100%", sm: "100%", xs: "105%" },
            overflowX: "auto",
            whiteSpace: "nowrap",
            flexWrap: "nowrap",
          }}
        >
          {OverviewList?.map((item, index) => (
            <Grid
              item
              key={index}
              my={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { sm: "100%", xs: "105%" },
                alignItems: "center",
              }}
            >
              <div className="flex items-center space-x-4">
                <div className="w-full">
                  {" "}
                  <Button
                    onClick={() => setSelectedOption(item.option)}
                    key={index}
                    disableElevation
                    disableRipple
                    component={Link}
                    to={`${item.link}/${uuid}/${campaignObj?.title}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      background:
                        item.id === 5
                          ? "#689900"
                          : themeMode === "dark"
                          ? "#0F0F0F"
                          : "transparent",
                      border: "1px solid #689900",
                      borderRadius: "30px",
                      padding: {
                        md: "10px 15px 10px 5px",
                        sm: "10px 15px",
                        xs: "10px 15px",
                      },
                      width: {
                        md: "fit-content",
                        sm: "fit-content",
                        xs: "100%",
                      },
                      "&:hover": {
                        background:
                          item.id === 5
                            ? "#689900"
                            : themeMode === "dark"
                            ? "#2e3322"
                            : "#e3fca7",
                      },
                    }}
                  >
                    <Typography
                      ml={1}
                      className="pr-6 pl-2"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "12px", xs: "12px" },
                        textWrap: "nowrap",
                        width: {
                          md: "100%",
                          sm: "fit-content",
                          xs: "fit-content",
                        },
                        display: "flex",
                        alignItems: "center",
                        display: "flex",
                        color:
                          themeMode === "dark"
                            ? "#FFFF"
                            : item.id === 5
                            ? "#FFFF"
                            : "#030303",
                      }}
                    >
                      {themeMode === "dark" ? (
                        <img src={item.img} alt="" />
                      ) : item.id === 5 ? (
                        <img src={item.img} alt="" />
                      ) : (
                        <img src={item.light} alt="" />
                      )}
                      <span className="pl-2">{item.text}</span>
                    </Typography>
                  </Button>
                </div>

                <div className="w-full">
                  {/* {index !== OverviewList.length - 1 && (
                    <Grid
                      key={`arrow-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        transform: { md: "scale(1.3)", sm: "scale(1)" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      <ArrowRightAlt />
                    </Grid>
                  )} */}
                </div>
              </div>{" "}
            </Grid>
          ))}
        </Grid>

        <Grid
          className={classes.fullWidth}
          sx={{
            justifyContent: { md: "left", sm: "left", xs: "center" },
            alignItems: { md: "left", sm: "left", xs: "left" },
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Button
            className={classes.modeButton}
            disableRipple
            onClick={DonationMode}
            sx={{
              background:
                mode === "Donation" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 10px",
              },
              color:
                themeMode === "light" && mode !== "Donation"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                Via Our Donation Form
              </Typography>
            </Grid>
          </Button>

          <Button
            className={classes.modeButton}
            onClick={ElementMode}
            disableRipple
            sx={{
              background:
                mode === "Element" ? "#659500 !important" : "transparent",
              padding: {
                md: "10px 30px",
                sm: "10px 15px",
                xs: "8px 15px",
              },
              color:
                themeMode === "light" && mode !== "Element"
                  ? "#030303"
                  : "#FFF",
              "&:hover": {
                backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
              },
            }}
          >
            <Grid container className={classes.flexCenter}>
              <Typography
                className={classes.fs18}
                sx={{ fontSize: { md: "18px", sm: "18px", xs: "12px" } }}
              >
                Via Embed Elements
              </Typography>
            </Grid>
          </Button>
        </Grid>

        {mode === "Donation" ? (
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            my={5}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Grid item md={9.5}>
              <Typography className={classes.fs16}>
                <b className={classes.bold}>
                  This feature is best for you if you do not have any website.
                </b>{" "}
                Select one of our pre-built donation form templates to raise
                donations. These templates will auto-fill your campaign data.
                After finalizing the template, share the form URL to donors so
                that they will fill out form on Acceleraise’s platform.
              </Typography>
            </Grid>

              <Grid my={3}>
                {formSelection === null ? (
                  <Typography
                    className={classes.f28w600}
                    sx={{
                      textAlign: { md: 'left', sm: 'left', xs: 'center' }
                    }}
                  >
                    Form Templates
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>

            <Grid
              item
              md={12}
              className={classes.rowWrap}
              sx={{
                display: formSelection === null ? "flex" : "none",
                justifyContent: "space-between",
              }}
            >
              {defaultPublishData?.map((item, index) => (
                <Grid
                  key={index}
                  item
                  md={3.8}
                  sm={5.8}
                  xs={12}
                  my={3}
                  className={classes.container}
                  sx={{
                    backgroundImage: `url('${item.img}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    width: "265px",
                    height: "181px",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    //onClick={() => SelectDonationForm(index)}
                    //onHover={() => {handleFormSelection(index);console.log("selected",index)}}
                    onMouseEnter={() => SelectDonationForm(index)}
                    //onMouseLeave={() => setPlaceholder("Select a country")}
                    disableRipple
                    className={classes.img}
                    sx={{
                      // border:
                      //   donationForm?.name === item?.name
                      //     ? '3px solid #8ED003'
                      //     : '1px solid transparent',

                      width: "100%",
                      "&:hover .animatedGrid": {
                        height: "100%",
                        backgroundColor:
                          themeMode === "dark" ? "#2e3322EE" : "#e3fca799",
                        border: "3px solid #8ED003",
                      },
                      "&:not(:hover) .animatedGrid": {
                        height: "0px",
                        backgroundColor: "transparent",
                      },
                      "&:hover .animatedButton": {
                        display: "flex",
                      },
                      "&:not(:hover) .animatedButton": {
                        display: "none",
                      },
                    }}
                  >
                    <div className={`${classes.animatedGrid} animatedGrid`}>
                      <Link
                        to={`/customize-template/${uuid}/${item?.id}`}
                      >
                        <Button
                          disableRipple
                          variant='contained'
                          color='primary'
                          href={`/customize-template/${campaignObj.uuid}/${item?.id}?title=${encodeURIComponent(campaignObj.title)}`}
                          className={`animatedButton`}
                          sx={{
                            mt: 2,
                            border:
                              themeMode === "dark"
                                ? "1px solid #FFF"
                                : "1px solid #030303",
                            borderRadius: "30px",
                            textTransform: "capitalize",
                            fontWeight: "600",
                            flexWrap: "nowrap",
                            margin: "10px 10px 10px 0px",
                            width: "fit-content",
                            backgroundColor:
                              themeMode === "dark" ? "#030303" : "#FFF",
                            color: themeMode === "dark" ? "#FFF" : "#030303",
                            "&:hover": {
                              backgroundColor:
                                themeMode === "dark" ? "#2e3322" : "#e3fca7",
                            },
                          }}
                        >
                          Customize & Publish{" "}
                        </Button>
                      </Link>

                      <LoadingButton
                        onClick={() => handleSave(item)}
                        className={`animatedButton`}
                        loading={false}
                        loadingPosition="start"
                        variant="outlined"
                        sx={{
                          color: "#FFF",
                          background: "#659500",
                          border: "1px solid #689900",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          fontWeight: "600",
                          flexWrap: "nowrap",
                          margin: "10px 10px 10px 0px",
                          width: "fit-content",
                          padding: {
                            md: "10px 30px",
                            sm: "10px 15px",
                            xs: "8px 10px",
                          },
                          "&:hover": {
                            border: "1px solid #689900",
                            backgroundColor: "#8ED003",
                            border: "1px solid #acfa07",
                            color: "#FFF",
                            backgroundColor: "#8ED003",
                          },
                        }}
                      >
                        {campaignObj?.status &&
                        campaignObj?.status === "publish"
                          ? "Unpublish"
                          : "Publish"}
                      </LoadingButton>
                    </div>
                    {/* <img
                        src={item.img}
                        alt=''
                        style={{ width: '100%',height:"200px" }}
                      /> */}
                  </Button>
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              md={12}
              sx={{
                display: formSelection === null ? "none" : "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                height: "fit-content",
                position: "relative",
                top: "50px",
              }}
            >
              <Button
                disableElevation
                disableRipple
                sx={{
                  height: "fit-content",
                  "&:hover": {
                    backgroundColor: "transparent",
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => setFormSelection(null)}
              >
                <img src={themeMode === "dark" ? cross : crossDark} alt="" />
              </Button>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: formSelection === null ? "none" : "flex",
                background: themeMode === "dark" ? "#131313" : "#ECECEC",
                border: "1px solid #A69500",
                borderRadius: "15px",
                height: "fit-content",
                padding: "30px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid mb={3} sx={{ textAlign: "center", width: "fit-content" }}>
                  {themeMode === "dark" ? (
                    <img src={logo} alt="" />
                  ) : (
                    <img src={logoLight} alt="" />
                  )}
                </Grid>
                <Grid mb={3} md={12} sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      textAlign: "left",
                      width: "100%",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Donation Form
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={defaultPublishData[formSelection]?.img}
                  alt=""
                  style={{
                    width: "644px",
                    height: "360px",
                    borderRadius: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : mode === "Element" ? (
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            my={5}
            sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
          >
            <Grid item md={9.5}>
              <Typography className={classes.fs16}>
                <b className={classes.bold}>
                  Use this feature only if you own a website.{" "}
                </b>
                Create a new or use a pre-built element and embed that into your
                website to attract donors’ traffic toward donation checkout
                form.
              </Typography>
              <Typography my={4} className={classes.italics}>
                To use elements, navigate to{" "}
                <b className={classes.neonBold}>Elements Page </b>and choose the
                element you want to embed in your website.
              </Typography>
            </Grid>

            <Grid item md={12} mt={7} sx={{ textAlign: "flex-start" }}>
              <Typography sx={{ fontSize: "32px", fontWeight: "600" }}>
                Steps to use elements:
              </Typography>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { md: "row", sm: "column", xs: "column" },
                }}
              >
                {steps?.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: { md: "column", sm: "row", xs: "column" },
                      flexWrap: "nowrap",
                    }}
                  >
                    <Grid>
                      <img src={item.img} alt="" style={{ width: "300px" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        padding: {
                          md: "0px 15%",
                          sm: "15% 5% 15% 0% ",
                          xs: "0px 15%",
                        },
                        display: "flex",
                        height: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <span sx={{ fontWeight: "700", fontSize: "24px" }}>
                          {item.id}#
                        </span>{" "}
                        {item.text}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          container
          mt={6}
          justifyContent="space-between"
          sx={{ display: mode === "Donation" ? "flex" : "none" }}
        >
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            {/* <Button
              disableRipple
              variant='contained'
              color='primary'
              className={classes.modeButton}
              href={`/customize-template/${formSelection}`}
              disabled={formSelection === null}
              sx={{
                mt: 2,
                backgroundColor: 'transparent',
                color: themeMode === 'light' ? '#030303' : '#FFF',
                '&:hover': {
                  backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7'
                }
              }}
            >
              Customize Form
            </Button> */}
            <Button
              disableRipple
              onClick={copyToClipboard}
              variant="contained"
              color="primary"
              className={classes.save}
              sx={{
                mt: 2,
                backgroundColor: "transparent",
                "&:hover": {
                  background:
                    "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
                },
              }}
            >
              Get Campaign Page Link
            </Button>
          </Grid>
          <Grid item>
            {/* <LoadingButton
              className={classes.save}
              onClick={handleSave}
              loading={false}
              loadingPosition='start'
              variant='outlined'
              sx={{
                padding: { md: '10px 30px', sm: '10px 15px', xs: '8px 10px' },
                '&:hover': {
                  border: '1px solid #acfa07',
                  color: '#FFF',
                  backgroundColor: '#8ED003'
                }
              }}
            >
              {campaignObj?.status && campaignObj?.status === 'publish'
                ? 'Unpublish'
                : 'Save & Publish'}
            </LoadingButton> */}
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Please complete all campaign settings"}
        content={dialogContent}
      />
    </>
  );
};
export default PublishCampaign;
