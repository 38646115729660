import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    imgHolder: {
    display: "flex",
    alignItems: "center",
  },
  client: {
    backgroundColor: "#160609",
    color: "#fff",
    padding: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))