import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  MenuItem,
  Modal,
} from "@mui/material";
import { useStyles } from "./useStyles";
import { currencies } from "../../../../../utils/globalFunctions";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_P2P_FUNDRAISE,
  GET_CAMPAIGN_LIST,
  UPDATE_P2P_FUNDRAISE,
} from "./Query";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const cross =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg";

export function P2PPopup({ open, setOpen, p2pData, mode, setRefetchRows }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [campaignList, setCampaignList] = useState(null);
  const [p2pObj, setP2pObj] = useState({
    campaignUuid: "",
    uuid: "",
    name: "",
    goalAmount: "",
    goalCurrency: "",
    goalDate: "",
    message: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  useEffect(() => {
    if (mode === "EDIT" && p2pData) {
      setP2pObj({
        campaignUuid: p2pData?.campaign?.uuid,
        uuid: p2pData?.uuid,
        name: p2pData?.name,
        goalAmount: p2pData?.goalAmount,
        goalCurrency: p2pData?.goalCurrency,
        goalDate: p2pData?.goalDate?.split("T")[0],
        message: p2pData?.message,
      });
    } else if (mode === "ADD") {
      setP2pObj({
        campaignUuid: "",
        uuid: "",
        name: "",
        goalAmount: "",
        goalCurrency: "",
        goalDate: "",
        message: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    }
  }, [p2pData, mode]);

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const [
    CreatePeerToPeerFundraise,
    { loading: peerLoading, error: peerError },
  ] = useMutation(CREATE_P2P_FUNDRAISE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("p2p created successfully!"));
    },
  });

  const [
    UpdatePeerToPeerFundraise,
    { loading: peerUpdateLoading, error: peerUpdateError },
  ] = useMutation(UPDATE_P2P_FUNDRAISE, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("your request was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("p2p updated successfully!"));
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const variables = {
      ...p2pObj,
      goalAmount: parseFloat(p2pObj.goalAmount),
    };
    dispatch(setSpinnerLoading(true));
    try {
      if (mode === "ADD") {
        const result = await CreatePeerToPeerFundraise({ variables });
        setP2pObj((prevObj) => ({
          ...prevObj,
          campaignUuid:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              .campaignUuid,
          name: result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
            ?.name,
          goalAmount:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.goalAmount,
          goalCurrency:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.goalCurrency,
          goalDate:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.goalDate,
          message:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.message,
          firstName:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.firstName,
          lastName:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.lastName,
          email:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise?.email,
          phoneNumber:
            result?.data?.createPeerToPeerFundraise?.peerToPeerFundraise
              ?.phoneNumber,
        }));
      }
      if (mode === "EDIT") {
        const result = await UpdatePeerToPeerFundraise({ variables });
        setP2pObj((prevObj) => ({
          ...prevObj,
          uuid: result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
            ?.uuid,
          campaignUuid:
            result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
              .campaignUuid,
          name: result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
            ?.name,
          goalAmount:
            result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
              ?.goalAmount,
          goalCurrency:
            result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
              ?.goalCurrency,
          goalDate:
            result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
              ?.goalDate,
          message:
            result?.data?.updatePeerToPeerFundraise?.peerToPeerFundraise
              ?.message,
        }));
      }
      dispatch(setSpinnerLoading(false));
      setOpen(false);
      setRefetchRows(true);
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  if (!open) return null;
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          onClick={(e) => e.stopPropagation()}
          sx={{
            padding: { md: "8% 27%", sm: "35px 15%", xs: "5px 2%" },
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(11px)",
            width: "100%",
          }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              transform: "scale(0.75)",
              padding: "35px",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              background: themeMode === "dark" ? "#0D0D0DE5" : "white",
              color: "#fff",
              borderRadius: "15px",
              overflow: "hidden",
              height: "fit-content",
              "&::before": {
                content: "''",
                position: "absolute",
                inset: 0,
                borderRadius: "15px",
                padding: "1px",
                background: "linear-gradient(145deg, white, transparent)",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "row", xs: "row" },
                justifyContent: "space-between",
                alignItems: { md: "center", sm: "center", xs: "flex-end" },
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "20px", sm: "20px", xs: "20px" },
                  fontWeight: "600",
                  textAlign: "left",
                  width: "100%",
                  whiteSpace: "nowrap",
                  color: themeMode === "dark" ? "white" : "black",
                }}
              >
                {mode === "ADD"
                  ? "Create a new Peer To Peer Fundraise"
                  : "Update Peer To Peer Fundraise"}
              </Typography>
              <Button
                disableElevation
                disableRipple
                sx={{ "&:hover": { backgroundColor: "transparent" } }}
                onClick={() => setOpen(false)}
              >
                {themeMode === "dark" ? (
                  <img src={cross} alt="" />
                ) : (
                  <CloseIcon />
                )}
              </Button>
            </Grid>
            <Grid
              item
              mt={5}
              md={12}
              sx={{
                width: "100%",
                position: "relative",
              }}
            >
              <form onSubmit={handleSubmit}>
                <FormControl sx={{ width: "100%", fontSize: "14px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.fullInput
                            : classes.fullInputLight
                        }
                        variant="outlined"
                        id="outlined-basic"
                        label="Display Name"
                        name="name"
                        value={p2pObj.name}
                        onChange={(event) =>
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            name: event.target.value,
                          }))
                        }
                        sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.select
                            : classes.selectLight
                        }
                        variant="outlined"
                        id="outlined-select-currency"
                        label="Campaign"
                        name="campaign"
                        value={p2pObj.campaignUuid}
                        onChange={(e) => {
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            campaignUuid: e.target.value,
                          }));
                        }}
                        disabled={mode === "EDIT"}
                        sx={{
                          mt: { md: 5, sm: 5, xs: 5 },
                        }}
                      >
                        {campaignList?.map((option) => (
                          <MenuItem
                            key={option.uuid}
                            value={option.uuid}
                            className={classes.menuItem}
                          >
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {mode !== "EDIT" && (
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          size="large"
                          className={
                            themeMode === "dark"
                              ? classes.fullInput
                              : classes.fullInputLight
                          }
                          variant="outlined"
                          id="outlined-basic"
                          label="First Name"
                          name="firstName"
                          value={p2pObj.firstName}
                          onChange={(event) =>
                            setP2pObj((prevObj) => ({
                              ...prevObj,
                              firstName: event.target.value,
                            }))
                          }
                          sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                        />
                      </Grid>
                    )}
                    {mode !== "EDIT" && (
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          size="large"
                          className={
                            themeMode === "dark"
                              ? classes.fullInput
                              : classes.fullInputLight
                          }
                          variant="outlined"
                          id="outlined-basic"
                          label="Last Name"
                          name="lastName"
                          value={p2pObj.lastName}
                          onChange={(event) =>
                            setP2pObj((prevObj) => ({
                              ...prevObj,
                              lastName: event.target.value,
                            }))
                          }
                          sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                        />
                      </Grid>
                    )}
                    {mode !== "EDIT" && (
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          size="large"
                          className={
                            themeMode === "dark"
                              ? classes.fullInput
                              : classes.fullInputLight
                          }
                          variant="outlined"
                          id="outlined-basic"
                          label="Email"
                          name="email"
                          value={p2pObj.email}
                          onChange={(event) =>
                            setP2pObj((prevObj) => ({
                              ...prevObj,
                              email: event.target.value,
                            }))
                          }
                          disabled={mode === "EDIT"}
                          sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.fullInput
                            : classes.fullInputLight
                        }
                        variant="outlined"
                        id="outlined-basic"
                        label="Goal Date"
                        name="goalDate"
                        type="date"
                        value={p2pObj.goalDate}
                        onChange={(event) =>
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            goalDate: event.target.value,
                          }))
                        }
                        sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.fullInput
                            : classes.fullInputLight
                        }
                        variant="outlined"
                        id="outlined-basic"
                        label="Goal Amount"
                        name="goalAmount"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={p2pObj.goalAmount}
                        onChange={(event) =>
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            goalAmount: event.target.value,
                          }))
                        }
                        sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        select
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.select
                            : classes.selectLight
                        }
                        variant="outlined"
                        id="outlined-select-currency"
                        label="Goal Currency"
                        name="goalCurrency"
                        value={p2pObj.goalCurrency}
                        onChange={(e) => {
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            goalCurrency: e.target.value,
                          }));
                        }}
                        sx={{
                          mt: { md: 5, sm: 5, xs: 5 },
                        }}
                      >
                        {currencies?.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            className={classes.menuItem}
                          >
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {mode !== "EDIT" && (
                      <Grid item xs={6}>
                        <TextField
                          required
                          fullWidth
                          size="large"
                          className={
                            themeMode === "dark"
                              ? classes.fullInput
                              : classes.fullInputLight
                          }
                          variant="outlined"
                          id="outlined-basic"
                          label="Phone Number"
                          name="phoneNumber"
                          value={p2pObj.phoneNumber}
                          onChange={(event) =>
                            setP2pObj((prevObj) => ({
                              ...prevObj,
                              phoneNumber: event.target.value,
                            }))
                          }
                          sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        required
                        fullWidth
                        size="large"
                        className={
                          themeMode === "dark"
                            ? classes.fullInput
                            : classes.fullInputLight
                        }
                        variant="outlined"
                        id="outlined-basic"
                        label="Message"
                        name="message"
                        value={p2pObj.message}
                        onChange={(event) =>
                          setP2pObj((prevObj) => ({
                            ...prevObj,
                            message: event.target.value,
                          }))
                        }
                        sx={{ mt: { md: 5, sm: 5, xs: 5 } }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      mt: 4,
                    }}
                  >
                    <Button
                      onClick={() => setOpen(false)}
                      sx={{
                        color: themeMode === "dark" ? "#FFF" : "black",
                        width: { md: "30%", sm: "30%", xs: "50%" },
                        background:
                          themeMode === "dark" ? "transarent" : "white",
                        border:
                          themeMode === "dark"
                            ? "1px solid #fff"
                            : "1px solid black",
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: "600",
                        padding: "5px 15px",
                        mr: 2,
                        "&:hover": {
                          background: "#081a01",
                          border: "2px solid #081a01",
                          color: themeMode === "dark" ? "#FFF" : "white",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      sx={{
                        color: "#FFF",
                        width: { md: "30%", sm: "30%", xs: "50%" },
                        background:
                          "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
                        borderRadius: "30px",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: "600",
                        padding: "5px 15px",
                        ml: 2,
                        "&:hover": {
                          background: "#081a01",
                        },
                      }}
                    >
                      {mode === "ADD" ? "Create" : "Update"}
                    </Button>
                  </Grid>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
