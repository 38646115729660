import React, { useState } from 'react';
import './useStyle.css'; 
import {
    Tooltip
} from '@mui/material'

const ImpactSlider = ({ impactLevel, textColor, backgroundColor, borderSize, borderRadius, borderColor, shadow, label, labelColor, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor, iconColor }) => {
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  return (
    <div className="impact-slider" >
      <div className="slider-line"></div>
      <div className="slider-buttons">
        {
            impactLevel?.map((item,index)=>(
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                    ${item.price}
                    <Tooltip
                        title={`❤ Support ${item.family} families`}
                        arrow
                        placement="right"
                        open={activeButton === item.id ? true : false}
                        sx={{
                            padding:"10px"
                        }}
                    >
                    <button key={index}
                        class="moneyBtn"
                        id="proceed-btn"
                        style={{
                          width: '16px',
                          height: '16px',
                          borderRadius: '50%',
                          backgroundColor: btnColor ? btnColor : activeButton === item.id ? '#a7f207' : '#4b6e00', 
                          border: 'none',
                          marginBottom: '10px',
                          cursor: 'pointer',
                          borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '50%', 
                          border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '0'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : 'none' 
                        }}
                        onClick={() => handleButtonClick(item.id)}
                        data-value="1"
                        value = {item.price}
                    ></button>        
                    </Tooltip>
                </div>
            ))
        }
        {/* <button
          className={`slider-button ${activeButton === 1 ? 'active' : ''}`}
          onClick={() => handleButtonClick(1)}
          data-value="1"
        ></button>
        <button
          className={`slider-button ${activeButton === 2 ? 'active' : ''}`}
          onClick={() => handleButtonClick(2)}
          data-value="2"
        ></button>
        <button
          className={`slider-button ${activeButton === 3 ? 'active' : ''}`}
          onClick={() => handleButtonClick(3)}
          data-value="3"
        ></button>
        <button
          className={`slider-button ${activeButton === 4 ? 'active' : ''}`}
          onClick={() => handleButtonClick(4)}
          data-value="4"
        ></button> */}
      </div>
    </div>
  );
};

export default ImpactSlider;



const Heart =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16px"
            height="16px"
            fill="red"
        >
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
        </svg>
    )
}