import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "../useStyles";
import { useSelector } from "react-redux";

const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/down.svg";
const bar =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/progress.svg";

export const ProgressBar = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      mx={1}
      className={classes.box}
      sx={{
        backdropFilter: {
          md: "blur(11px)",
          sm: "blur(11px)",
          xs: "blur(11px)",
        },
        boxShadow:
          themeMode === "dark"
            ? "0px 0px 0px red"
            : "0px 8px 5px 0px #00000040",
        backgroundColor: themeMode === "dark" ? "#0D0D0DE5" : "#FFF",
        color: themeMode === "dark" ? "#FFF" : "#030303",
        "&::before": {
          content: "''",
          position: "absolute",
          inset: 0,
          borderRadius: "15px",
          padding: "1px",
          background:
            themeMode === "dark"
              ? "linear-gradient(145deg, white, transparent)"
              : "linear-gradient(180deg, white, transparent)",
          WebkitMask:
            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
          WebkitMaskComposite: "xor",
          maskComposite: "exclude",
        },
      }}
    >
      {/* <Grid className={classes.tag}>
        <Button className={classes.down}>
          <Typography className={classes.downBtn} mx={1}>
            This Month{" "}
          </Typography>
          <img src={down} alt="" />
        </Button>
      </Grid> */}
      <Grid
        mb={3}
        className={classes.full}
        sx={{ color: themeMode === "dark" ? "#FFF" : "#030303" }}
      >
        <Typography className={classes.f14w700}>P2P Goal Progress </Typography>
      </Grid>

      <Grid item sx={{ color: themeMode === "dark" ? "#FFF" : "#030303" }}>
        <img src={bar} alt="" className={classes.full} />
      </Grid>
    </Grid>
  );
};
