import { Grid, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./useStyles";
import { category, jobs } from "./data";
import { ViewJob } from "./ViewJob";

const blur =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg";
const light =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/light.svg";

const Jobs = () => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [displayCount, setDisplayCount] = useState(4);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [expandedJob, setExpandedJob] = useState(null);

  const handleBtnSelect = (id) => {
    setSelectedCategory(id);
    setExpandedJob(null); // Reset expanded job when category changes
  };

  const handleToggleDetails = (index) => {
    setExpandedJob(expandedJob === index ? null : index);
  };

  const handleSeeMoreClick = () => {
    setDisplayCount(jobs.length);
  };

  const handleSeeLessClick = () => {
    setDisplayCount(4);
  };

  const filteredJobs = selectedCategory === 0 ? jobs : jobs.filter(job => job.id === selectedCategory);

  return (
    <>
      <Grid className={classes.bkg} container id="faq" sx={{ filter: themeMode === "dark" ? "" : "brightness(500%)", }}>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundImage: `url('${light}'),url('${blur}')`,
            backgroundSize: "cover,cover",
            backgroundPosition: "center center,10% 50%",
            padding: {
              md: "80px 80px",
              sm: "80px 30px ",
              xs: "80px 15px",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item md={8} sm={11.5} xs={12} my={2} className={classes.overflowx}>
            {category?.map((item, index) => (
              <Button
                disableRipple
                onClick={() => handleBtnSelect(item.id)}
                key={index}
                className={classes.btnList}
                sx={{
                  filter: themeMode === "dark" ? "" : "brightness(50%)",
                  border: themeMode==="dark"? '1px solid #5C5C5C':"1px solid #000",
                  backgroundColor:
                  themeMode==="dark" && selectedCategory === item.id ? "#79B200 !important" :
                  themeMode === "dark" && selectedCategory !== item.id ? "transparent" :
                  themeMode === "light" && selectedCategory === item.id ? "#79B200 !important" :
                   "#FFF",
                  padding: {
                    md: "10px 22px",
                    sm: "10px 18px",
                    xs: "10px 30px",
                  },
                  color: themeMode === "dark" ? '#FFFF' : "#000",
                  mx: { md: 1, sm: 1, xs: 1 },
                  '&:hover': {
                    border: '1px solid #79B200',
                    color: themeMode === "light" && selectedCategory === item.id ? "#000" : themeMode === "dark" ? '#FFFF' : "#FFF",
                  }
                }}
              >
                <Typography
                  mx={{ md: 0, sm: 0, xs: 1 }}
                  className={classes.btnText}
                  sx={{
                    fontSize: { md: "14px", sm: "14px", xs: "10px" },
                    fontWeight: themeMode==="dark"?'600':"800",
                    filter: themeMode === "dark" ? "" : "brightness(50%)",
                    
                  }}
                >
                  {item.name}
                </Typography>
              </Button>
            ))}
          </Grid>

          <Grid mt={4} sx={{ display: "flex", flexDirection: "column", width: {md:"70%",sm:"80%",xs:"90%"} }}>
            {
            filteredJobs.length === 0 ? (
              <Typography variant="h6" sx={{ textAlign: "center", marginTop: "20px" }}>
                Sorry, no jobs available.
              </Typography>
            ) : (
              filteredJobs.slice(0, displayCount).map((jobCategory, index) => (
                <Grid key={index}>
                  {
                  // jobCategory.job.length === 0 ? (
                  //   <Typography variant="h6" sx={{ textAlign: "center", marginTop: "20px" }}>
                  //     Sorry, no jobs available.
                  //   </Typography>
                  // ) : (
                    jobCategory.job.map((job, jobIndex) => (
                      <ViewJob
                        key={jobIndex}
                        category={jobCategory.name}
                        post={job.post}
                        nature={job.nature}
                        time={job.time}
                        salary={job.salary}
                        expirience={job.exp}
                        isExpanded={expandedJob === `${index}-${jobIndex}`}
                        onToggle={() => handleToggleDetails(`${index}-${jobIndex}`)}
                      />
                    //)
                    )
                  )}
                </Grid>
              ))
            )
            }
          </Grid>

          <Button onClick={() => displayCount === 4 ? handleSeeMoreClick() : handleSeeLessClick()} sx={{ mt: 4, color: "#FFF", borderRadius: "30px", padding: "8px 25px", border: "1px solid #FFF", textTransform: "capitalize" }}>
            {displayCount === 4 ? "See More" : "See Less"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Jobs;
