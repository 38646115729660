import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import mapMarker from '@iconify/icons-mdi/map-marker';



const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 37.42216,
    lng: -122.08427,
};

const LocationPin = ({ text }) => (
    <div className="pin">
        <Icon icon={mapMarker} className="pin-icon" />
        <p className="pin-text">{text}</p>
    </div>
);

const MyMap = ({size}) => {

    useEffect(() => {
        const initMap = () => {
            const mapDiv = document.getElementById('map');
            if (!mapDiv) return;

            const map = new window.google.maps.Map(mapDiv, {
                center: { lat: location.lat, lng: location.lng },
                zoom: 17,
            });

            const marker = new window.google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map,
                title: location.address,
            });

            const infowindow = new window.google.maps.InfoWindow({
                content: `<div style="text-align: center; font-size: 14px; font-family: Arial, sans-serif; color: red">
                            <div><strong>❤ ${location.address}</strong></div>
                          </div>`,
            });

            infowindow.open(map, marker);
        };

        window.initMap = initMap;

        if (window.google && window.google.maps) {
            initMap();
        }
    }, []);

    return (
        // <div className="map">
        //     <div className="google-map" style={{  height: "50vh"}}>
        //         <GoogleMapReact
        //             bootstrapURLKeys={{ key: "AIzaSyCy_pXYhi5-yl7YtUX4AGfTd3JjnRMGC_8" }}
        //             defaultCenter={location}
        //             defaultZoom={17}>
        //             <LocationPin
        //                 lat={location.lat}
        //                 lng={location.lng}
        //                 text={location.address}
        //             />
        //         </GoogleMapReact>
        //     </div>
        // </div>
        <div className="mapClass">
            <div id="map" className="google-map" style={{  height: size ? size : "50vh"}}>
                <LocationPin text={location.address} />
            </div>
        </div>
    );
};


export default MyMap
