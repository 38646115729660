import { makeStyles } from '@mui/styles'

const bullet = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/bullet.svg"
const bkg = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/planbkg.svg'
const ramadan = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Learn/ramadan.svg'

export const useStyles = makeStyles(theme => ({
  bkg: {
    backgroundImage: `url('${bkg}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  columnCenter: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  upper700: {
    textTransform: 'uppercase',
    fontWeight: '700'
  },
  color: {
    color: '#F6BE25'
  },
  jCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  textCenter: {
    textAlign: 'center'
  },
  full: {
    width: '100%'
  },
  overflowx: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto',
    scrollbarColor: '#8ED003CC #0F0F0F',
    scrollbarWidth: 'thin',
  },
  btnList: {
    
    borderRadius: '30px',
    width: '100%',
    
  },
  btnText: {
    
    
    textTransform: 'capitalize',
    textWrap: 'nowrap',
    width: 'fit-content'
  },
  ramadan: {
    backgroundImage: `url('${ramadan}')`,
    backgroundSize: 'cover',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderRadius: '30px',
    width: '100%',
    height: '70vh'
  },
  captionBox: {
    backgroundColor: '#000000CC',
    width: '100%',
    borderRadius: '0 0 30px 30px'
  },
  captionHead: {
    textTransform: 'uppercase',
    fontWeight: '700',
    width: '100%'
  },
  caption2: {
    textTransform: 'uppercase',
    fontWeight: '500',
    width: '100%'
  },
  client: {
    backgroundColor: "#160609",
    color: "#fff",
    padding: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexHolder: {
    display: "flex",
    justifyContent: "center",
    margin:"30px 0px",
  },
  gridHolder: {
    display: "grid",
    justifyItems: "center",
    padding:"10px 0px"
  },
  imgHolder: {
    display: "flex",
    alignItems: "center",
  },







  fullInput: {
    backgroundColor: '#0D0D0D',
    borderRadius: '10px',
    marginTop:"15px",
    '& .MuiOutlinedInput-input': {
      color: '#FFFFFF',
      '&::placeholder': {
        color: '#FFFFFF'
      }
    },
    '& .MuiInputLabel-root': {
      color: '#FFFFFF',
      fontSize: '12px',
      marginBottom: '15px',
      transform: 'translate(0px, -40px) scale(1.25)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0px, -40px) scale(1.25)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#828282',
      borderWidth: '0.5px',
      borderRadius: '5px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#acff00'
    },
    '&.Mui-active .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },

    outline: 'none'
  },

  
  listItemStyle : {
    listStyleType: "none",
    padding: 0,
},

listItemContentStyle : {
    position: "relative",
    paddingLeft: "30px",
    marginBottom: "10px", 
},

bulletStyle : {
    content: "''",
    backgroundImage: `url('${bullet}')`, 
    backgroundSize: "cover",
    width: "20px", 
    height: "20px", 
    position: "absolute",
    left: 0,
    top: "15%",
    transform: "translateY(-50%)",
    marginRight: "10px", 
},

  supportCard: {
    background: "#0000003B",
    //border: themeMode === "dark" ? "2px solid #FFF" :"2px solid #484B6A",
    boxShadow: '0px 0px 0px red',
    padding: '24px',
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    // flexDirection: "column",
    alignItems: "center",
    position: "relative",
    backdropFilter: 'blur(50px)',
    borderRadius: "15px",
    overflow: "visible",
    height: "fit-content",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: 'linear-gradient(180deg, #FFFFFF99, transparent)',
      WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",

    },

  }

}))
