



const tableDonations = [
    {
        id: 1,
        title: "Donor",
    },
    {
        id: 2,
        title: "Amount",
    },
    {
        id: 3,
        title: "Date",
    },
    {
        id: 4,
        title: "Location",
    },
    // {
    //     id: 5,
    //     title: "",
    // },
    
];
const createDonationData = (
    donor,amount,date,location,
    // img
) => {
    return {
        donor, amount, date, location,
        // img
    };
};
const donationRow = [
    createDonationData(
        "James Cave",
        "100.00",
        "Nov 15, 2020",
        "Dallas, Texas",
        //false
    ),
    createDonationData(
        "Lornella Dave",
        "512.40",
        "Feb 8, 2021",
        "Beaumont, Texas",
        //true
    ),
    createDonationData(
        "Hiroki Sinclair",
        "853.09",
        "Oct 23, 2021",
        "Metairie, Louisiana",
        //true
    ),
    createDonationData(
        "Jamar Gahan",
        "340.99",
        "Oct 30, 2021",
        "Houston, Texas",
        //true
    ),
    createDonationData(
        "Andrew Brown",
        "622.50",
        "Jun 25, 2022",
        "Austin, Texas",
        //false
    ),
    createDonationData(
        "Hiroki Sinclair",
        "853.09",
        "Oct 23, 2021",
        "Metairie, Louisiana",
     //   true
    ),
    createDonationData(
        "Jamar Gahan",
        "340.99",
        "Oct 30, 2021",
        "Houston, Texas",
       // true
    ),
    createDonationData(
        "Andrew Brown",
        "622.50",
        "Jun 25, 2022",
        "Austin, Texas",
        //false
    ),
];








const tableTeamPerf = [
    {
        id: 1,
        title: "#",
    },
    {
        id: 2,
        title: "Name",
    },
    {
        id: 3,
        title: "Raised",
    },
    {
        id: 4,
        title: "Donation",
    },
    {
        id: 5,
        title: "Role",
    },

];
const createTeamPerfData = (
    no, fname,lname,raised,donation,role
) => {
    return {
        no, fname,lname, raised, donation, role
    };
};
const teamPerfRow = [
    createTeamPerfData(
        "1",
        "Bridgette", 
        "Thompson",
        "6.04",
        "0",
        "Admin"
    ),
    createTeamPerfData(
        "2",
        "Terence",
        "Koelpin",
        "4.49",
        "0",
        "Member"
    ),
    createTeamPerfData(
        "3",
        "Eren",
        "Sinclair",
        "3.09",
        "1",
        "Member",
    ),
    createTeamPerfData(
        "4",
        "Mikasa",
        "Ackerman",
        "1.9",
        "0",
        "Member",
    ),
    createTeamPerfData(
        "4",
        "Armin",
        "Braun",
        "2.0",
        "1",
        "Member",
    ),
];



export {tableDonations,donationRow,tableTeamPerf,teamPerfRow}