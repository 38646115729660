import { useSelector } from "react-redux";
import { Typography, Button } from "@mui/material";
import { MoneyBasedDonation } from "./MoneyBasedDonation";
import { PreDonationUpsell } from "./Screens/PreDonationUpsell";
import { PaymentOption } from "./Screens/PaymentOption";
import { PersonalInfo } from "./Screens/PersonalInfo";
import { PostDonationUpsell } from "./Screens/PostDonationUpsell";
import { ThankYou } from "./Screens/ThankYou";

const hearts = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/CampaignManagement/hearts.png";

export const PreviewContent = () => {
  const selectedScreen = useSelector((state) => state.campaign.screen);
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div
      className={
        themeMode === "dark"
          ? "preview-settings md:py-24"
          : "preview-settings md:py-24"
      }
    >
      <div className=" ">
        <div className="md:flex justify-between md:space-x-4 space-y-4 md:space-y-0">
          <div
            style={{
              backgroundColor: themeMode === "dark" ? "black" : "white",
              borderRadius: "11px",
            }}
            className="px-8 w-full h-full"
          >
            <img
              src={hearts}
              alt="hearts"
              className="w-full"
              // width={468}
              // height={274}
            ></img>
            <Typography className="preview-settings-content-text pt-8">
              Imam Connect Welfare Grant
            </Typography>
            <Typography className="preview-settings-content-text2 pt-8">
              You Will Make a Difference{" "}
            </Typography>
            <Typography className="preview-settings-content-text3 pt-4 pb-16">
              Make the world better, kinder, brighter. Every dollar you give
              will create real change.{" "}
            </Typography>
          </div>
          <div className="w-full h-full">
            <div
              style={{
                backgroundColor: themeMode === "dark" ? "black" : "white",
                borderRadius: "11px",
              }}
              className="pb-8 h-full "
            >
              {selectedScreen == 1 && <MoneyBasedDonation />}
              {selectedScreen == 2 && <PreDonationUpsell />}
              {selectedScreen == 3 && <PaymentOption />}
              {selectedScreen == 4 && <PersonalInfo />}
              {selectedScreen == 5 && <PostDonationUpsell />}
              {selectedScreen == 6 && <ThankYou />}
            </div>
          </div>
        </div>
        <div className="my-4 w-full md:flex justify-center text-center">
          <Button variant="text">
            <Typography
              className="donate-links text-white"
             
            >
              Is my donation secure?{" "}
            </Typography>
          </Button>
          <Button variant="text">
            <Typography
              className="donate-links text-white"
               
            >
              Can I cancel my recurring donation?{" "}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
