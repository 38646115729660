import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import blur1 from '../../../../../../assets/FeatureLightTheme/DataExport/blur1.svg'
import blur2 from '../../../../../../assets/FeatureLightTheme/DataExport/blur2.svg'
import fieldsLight from '../../../../../../assets/FeatureLightTheme/DataExport/fieldsLight.svg'
import calendarLight from '../../../../../../assets/FeatureLightTheme/DataExport/calendarLight.svg'

const fields =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/fields.svg";
const bkg =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essentialBlur.svg";
const bkg2 =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/essBlur2.svg";
const calender =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/calender.svg";

const Essential = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const classes = useStyles();
  return (
    <Grid
      sx={{ backgroundColor: themeMode === "dark" ? "#130407" : "#E4E5F1" }}
    >
      <Grid
        className={classes.bkg}
        container
        sx={{
          backgroundImage: themeMode === "dark" ? `url('${bkg}')`:`url('${blur1}')`,
          padding: { md: "150px", sm: "150px 20px", xs: "70px 20px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "space-around",
        }}
      >
        <Grid
          item
          md={12}
          sm={11}
          xs={11.5}
          sx={{
            padding: { md: "40px", sm: "30px", xs: "20px" },
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "15px",
            backdropFilter: "blur(22px)",
            background: "transparent",
            boxShadow: themeMode === "dark" ? "0px 0px 0px red" : "0px 8px 5px 0px #00000040",
            position: "relative",
            color: "#fff",
            //overflow: 'hidden',
            "&::before": {
              content: "''",
              position: "absolute",
              inset: 0,
              borderRadius: "15px",
              padding: "1px",
              background: "linear-gradient(145deg, white, transparent)",
              WebkitMask:
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
            },
          }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
            }}
          >
            <Grid item md={9} sm={12} xs={12}>
              <Typography
                className={classes.title}
                sx={{
                  color:themeMode==="dark"?"#FFF":"#030303",
                  textAlign: { md: "left", sm: "center", xs: "center" },
                  fontSize: { md: "40px", sm: "35px", xs: "30px" },
                }}
              >
                Effortless Data Export for Your{" "}
                <span style={{ color: themeMode === "dark" ? "#6A9D01" :"#484B6A" }}>Essential Insights</span>
              </Typography>
              <Grid item md={11}>
                <Typography
                  mt={4}
                  sx={{
                    color: themeMode === "dark" ? "#FFF" : "#030303",
                    textAlign: { md: "left", sm: "center", xs: "center" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    fontFamily:"Stolzl"
                  }}
                >
                  Unlock the power of comprehensive data access with our
                  platform. Leverage our Data Export Management to tap into a
                  wealth of information—over 300 data points at your fingertips.
                  Effortlessly export and analyze the specifics that matter most
                  to your business or organization{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              md={5}
              mt={3}
              sx={{
                display: { md: "flex", sm: "flex", xs: "none" },
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: { md: "20%", sm: "0%", xs: "0%" },
                transform: { md: "translateY(30%) translateX(25%) scale(1.2)", sm: "translateY(0%)" },
              }}
            >
              <img src={themeMode==="dark"?fields:fieldsLight} alt="" />
            </Grid>
            <Grid
              item
              md={3.5}
              mt={3}
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: { md: "20%", sm: "0%", xs: "0%" },
                transform: { md: "translateY(30%)", sm: "translateY(0%)" },
              }}
            >
              <img src={themeMode === "dark" ? fields : fieldsLight} alt="" style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={classes.bkg}
        container
        sx={{
          backgroundImage: themeMode === "dark" ? `url('${bkg2}')` : `url('${blur2}')`,
          padding: {
            md: "150px 100px 200px 250px",
            sm: "150px 20px",
            xs: "70px 20px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "space-around",
        }}
      >
        <Grid
          item
          md={12}
          sm={11}
          xs={11.5}
          sx={{
            padding: { md: "40px", sm: "30px", xs: "20px" },
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "15px",
            backdropFilter: "blur(22px)",
            background: "transparent",
            boxShadow: themeMode === "dark" ? "0px 0px 0px red" : "3px 5px 5px  #00000040",
            position: "relative",
            color: "#fff",
            //overflow: 'hidden',
            "&::before": {
              content: "''",
              position: "absolute",
              inset: 0,
              borderRadius: "15px",
              padding: "1px",
              background: "linear-gradient(305deg, white, transparent)",
              WebkitMask:
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
            },
          }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: {
                md: "row",
                sm: "column-reverse",
                xs: "column-reverse",
              },
              justifyContent: { md: "flex-end", sm: "center", xs: "center" },
            }}
          >
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              mt={3}
              sx={{
                display: { md: "flex", sm: "flex", xs: "none" },
                alignItems: "center",
                textAlign: { md: "right", sm: "center", xs: "center" },
                position: "relative",
                top: { md: "30%", sm: "0%", xs: "0%" },
                left: { md: "-15%", sm: "10%", xs: "0%" },
                transform: {
                  md: "translateY(20%) scale(1.3)",
                  sm: "translateY(0%)",
                  xs: "translateY(0%)",
                },
              }}
            >
              <img src={themeMode==="dark"?calender:calendarLight} alt="" />
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              mt={3}
              sx={{
                display: { md: "none", sm: "none", xs: "flex" },
                alignItems: "center",
                textAlign: { md: "right", sm: "center", xs: "center" },
                position: "relative",
                top: { md: "30%", sm: "0%", xs: "0%" },
                left: { md: "-15%", sm: "10%", xs: "0%" },
                transform: {
                  md: "translateY(20%) ",
                  sm: "translateY(0%)",
                  xs: "translateY(0%)",
                },
              }}
            >
              <img src={themeMode === "dark" ? calender : calendarLight} alt="" style={{ width: "100%" }} />
            </Grid>
            <Grid item md={8.5} sm={12} xs={12}>
              <Typography
                className={classes.title}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  fontSize: { md: "40px", sm: "35px", xs: "30px" },
                  textAlign: { md: "right", sm: "center", xs: "center" },
                }}
              >
                Schedule Files for<span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}> Seamless</span>{" "}
                <span style={{ color: themeMode === "dark" ? "#6A9D01" : "#484B6A" }}> Delivery</span>
              </Typography>
              <Typography
                mt={4}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  fontFamily:"Stolzl"
                }}
              >
                Get data from Acceleraise on your schedule. Select from hourly
                to monthly intervals to automatically receive exported files
                when you need them.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Essential;
