import React from "react";
import { useSelector } from "react-redux";

const overlayStyle = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
};

const spinnerStyle = {
  border: "16px solid #000", 
  borderTop: "16px solid #00FF00", 
  borderRadius: "50%",
  width: "120px",
  height: "120px",
  animation: "spin 2s linear infinite", 
};

const keyframes = `
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Spinner() {
  const spinnerLoader = useSelector((state) => state.spinnerLoader);

  return (
    <>
      <style>
        {keyframes}
      </style>
      <div>
        {spinnerLoader.Loading && (
          <div style={overlayStyle}>
            <div style={spinnerStyle}></div>
          </div>
        )}
      </div>
      </>
  );
}

export default Spinner;