import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

const aboutBkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/aboutUs.png";
const whiteBkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/aboutBkg.svg"

const AboutUs = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const classes = useStyles();
  return (
    <Grid>
      <Grid
        container
        className={classes.bkg}
        sx={{
          padding: { md: "0px 70px", sm: "0px 70px", xs: "0px 30px" },
          backgroundImage: 
          themeMode==="dark"?
          `linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0),rgba(0, 0, 0, 1) 90%), url('${aboutBkg}')`
          :
          `url('${whiteBkg}')`
        }}
      >
        <Grid item md={12} className={classes.leftCol}>
          <Grid
            item
            md={3.5}
            sm={6}
            xs={12}
            mb={{ md: 4, sm: 4, xs: 0 }}
            mt={{ md: 0, sm: 0, xs: 4 }}
            className={classes.borderBtm}
          >
            <Typography
              variant="inherit"
              className={classes.fw700}
              sx={{
                fontSize: { md: "48px", sm: "48px", xs: "40px" },
                textAlign: { md: "left", sm: "left", xs: "center" },
                color:themeMode==="dark"? "#FFFF":"#030303",
                fontFamily:"Stolzl"
              }}
            >
              ABOUT US
            </Typography>
          </Grid>
          <Grid
            mt={3}
            className={classes.flex}
            sx={{
              flexDirection: { md: "row", sm: "row", xs: "column" },
              textAlign: { md: "left", sm: "left", xs: "justify" },
              fontSize: { md: "16px", sm: "16px", xs: "12px" },
            }}
          >
            <Typography
              variant="inherit"
              sx={{
                paddingRight: { md: "30px", sm: "30px", xs: "0px" },
                color:themeMode==="dark"? "#FFFF":"#030303",
                fontFamily:"Stolzl"
              }}
            >
              Acceleraise is at the forefront of redefining the landscape of nonprofit fundraising and strategic advisory services. Founded on the principles of innovation, collaboration, and dedication to social good, we are committed to transforming the way organizations connect with their supporters and maximize their impact.
            </Typography>
            <br />
            <Typography
              variant="inherit"
              sx={{
                paddingRight: { md: "30px", sm: "30px", xs: "0px" },
                color:themeMode==="dark"? "#FFFF":"#030303",fontFamily:"Stolzl"
              }}
            >
              Our team, comprised of seasoned experts with diverse backgrounds in philanthropy, technology, and strategic planning, harnesses a wealth of knowledge to guide nonprofits through every stage of growth. At Acceleraise, we believe in the power of partnership and the potential of every mission to drive change. Join us in our journey to empower organizations, ignite passion, and elevate causes to new heights.
            </Typography>
            <br />
            <Typography
              variant="inherit"
              sx={{ paddingRight: { md: "30px", sm: "30px", xs: "0px" } }}
            >
              
            </Typography>
            <br />
          </Grid>
          <Grid
            my={6}
            item
            md={4}
            sm={4}
            xs={12}
            className={classes.flex}
            sx={{
              justifyContent: { md: "left", sm: "left", xs: "center" },
            }}
          >
            <Button href="/company"
              disableElevation
              disableRipple
              className={classes.btn}
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "14px" },
              }}
            >
              Learn More
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AboutUs;
