import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [
    {
      id: "1",
      heading:
        "How to add the Acceleraise installation code to a WordPress website",
      text: "WordPress is a powerful and versatile open-source content management system (CMS) that is used to create websites and blogs. With a few quick steps, you can add Fundraise Up to your WordPress website.",
    },
    {
      id: "2",
      heading: "Integrating Acceleraise with Google Tag Manager",
      text: "Google Analytics and Meta Pixel let you measure your organization’s advertising ROI as well as track your Flash, video, and social networking sites and applications. Fundraise Up’s integration with Google",
    },
    {
      id: "3",
      heading: "Annual Summary Receipt FAQs",
      text: "As of November 30th 2023, your organization is able to generate annual summary receipts for recurring plans showing all the installments in that plan that fell within the tax year. Learn more about receipts",
    },
    {
      id: "4",
      heading: "How to track UTM parameters in Aceleraise",
      text: "When UTM parameters are added to URLs that launch a Checkout modal or a Campaign Page, those parameters are captured and stored in the “UTM Parameters” section of the resulting donation record. ",
    },
    {
      id: "5",
      heading: "Pricing FAQs",
      text: "How much does Fundraise Up cost? Fundraise Up charges a simple, per-transaction fee. Does Fundraise Up charge a setup fee? No. Is a contract required to use Fundraise Up? No. Is there a monthly fee? No.",
    },
  ],
};

const helpSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    searchArticles: (state, action) => {
      if (action.payload === "") {
        return initialState;
      } else {
        // state.data = state.data.filter((obj) =>
        //   Object.values(obj).some((val) => val.includes(action.payload))
        // );
        state.data = state.data.filter((obj) =>
          JSON.stringify(obj)
            .toLowerCase()
            .includes(action.payload.toString().toLowerCase())
        );
      }
    },
  },
});

export const { searchArticles } = helpSlice.actions;
export default helpSlice.reducer;
