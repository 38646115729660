import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { allClients } from "./clientsList";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";
const Client = () => {
  const classes=useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid container className={classes.client} id="client"
        sx={{backgroundColor:themeMode==="dark"? "#0A0A0A":"#DEB701",}}
      >
        <Grid item md={12} sm={11} xs={11}>
  
          <Grid className={classes.flexHolder} item md={12} sm={12}>
            <Grid
              className={classes.gridHolder}
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                gridTemplateColumns: {
                  md: "repeat(6,1fr)",
                  sm: "repeat(3,1fr)",
                  xs: "repeat(2,1fr)",
                },
              }}
            >
              {allClients?.map((item, index) => (
                <Grid key={index} className={classes.imgHolder} 
                  sx={{paddingY:{md:"0px",sm:"10px",xs:"10px"}}}>
                  <img src={item.client} alt="" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Client;
