export const donations = [
    {
        id:0,
        text:"Successful Donation"
    },
    {
        id:1,
        text:"Refunded Donation"
    },
    {
        id:2,
        text:"Failed Donation"
    },
    {
        id:3,
        text:"Disputed Donation"
    },
];
export const recurring = [
    {
        id:0,
        text:"Cancelled Plan"
    },
    {
        id:1,
        text:"Failed Plan"
    },
    {
        id:2,
        text:"Changed Amount of Plan"
    },
    {
        id:3,
        text:"Changed Payment Method"
    },
    {
        id:3,
        text:"Changed Plan Limits"
    },
]

export const report = [
    {
        id:0,
        text:"Daily Donation"
    },
    {
        id:1,
        text:"Weekly Donation"
    },
    {
        id:2,
        text:"Monthly Donation"
    },
    
]

export const p2p = [
    {
        id:0,
        text:"New P2P Fundraiser"
    },
    {
        id:1,
        text:"Updated P2P Fundraiser"
    },
    {
        id:2,
        text:"P2P Fundraiser Goal Reached"
    },
    
]