import { Grid, Typography, Button } from "@mui/material";
import {useStyles} from "./useStyles";
import { useSelector } from "react-redux";

const light = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/userBkg.svg";
const bkg =   "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Home/dashboard.jpg";

const Dashboard = () => {
  const classes=useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid className={classes.bkg}>
        <Grid container className={classes.cover}
          sx={{
            backgroundImage: themeMode==="dark"?`url('${bkg}')`:`url('${light}')`,
            backgroundSize: "cover",
            backgroundSize: {
              md: "105% auto",
              sm: "180% auto",
              xs: themeMode==="dark"?"250% 100%":"430% auto",
            },
          }}
        >
          <Grid
            item
            md={9}
            sm={10}
            xs={10}
            className={classes.column}
          >
            <Grid className={classes.blur}
              sx={{
                backdropFilter: {
                  md: "blur(11px)",
                  sm: "blur(11px)",
                  xs: "blur(11px)",
                },
                boxShadow: themeMode==="dark"?"0px 0px 0px red": "0px 8px 5px 0px #00000040",
                backgroundColor:themeMode==="dark"? "transparent":"#ECECEC66",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  inset: 0,
                  borderRadius: "15px",
                  padding: "1px",
                  background:themeMode==="dark"? "linear-gradient(145deg, white, transparent)":
                    "linear-gradient(180deg, white, transparent)",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                },
              }}
            >
              
                <Typography className={classes.fw700}
                  sx={{
                    textAlign: { md: "left", sm: "left", xs: "center" },
                    fontSize: { md: "48px", sm: "40px", xs: "30px" },
                     width:"100% !important",
                     color:themeMode==="dark"? "#FFFF":"#030303",
                     fontFamily:"Stolzl"
                  }}
                >
                  USER-FRIENDLY DASHBOARD
                </Typography>
              
              <Grid className={classes.flex}
                sx={{
                  flexDirection: { md: "row", sm: "row", xs: "column" },
                  fontSize: { md: "16px", sm: "16px", xs: "12px" },
                  textAlign: { md: "left", sm: "left", xs: "justify" },
                }}
              >
                <Typography className={classes.padW400} variant="inherit"
                  sx={{color:themeMode==="dark"? "#FFFF":"#030303",fontFamily:"Stolzl"}}
                >
                  The "User-Friendly Dashboard" feature in Acceleraise is designed to enhance the experience of its users by providing an intuitive and accessible interface. This dashboard allows users to effortlessly navigate through the platform, access important data, and manage their fundraising activities with ease. It's built to accommodate users of all technical skill levels, ensuring that every feature is straightforward to use. This focus on user-friendliness aims to streamline the process of managing and tracking fundraising efforts, making it more efficient and less time-consuming.
                </Typography>
                <Typography className={classes.padW400} variant="inherit">
                  
                </Typography>
              </Grid>
            </Grid>
            <Grid my={5} item className={classes.centerRow}>
              <Button className={classes.btn} href="/features">
                Learn More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Dashboard;
