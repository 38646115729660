import React, { useState, useEffect } from 'react'

const RecentDonations = ({limitedDonation, enable_amount, enable_location, enable_comment, enable_donation_date, show_currency, currency, enable_donate_button, title, backgroundColor, textColor, accentColor, borderSize, borderRadius, borderColor, shadow, show_animation, themeMode}) => {

    return (
        <>
            <div 
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius  ? `${borderRadius}px` : '0px', 
                    border: borderSize || borderColor ? `${borderSize ? borderSize : '0'}px solid ${borderColor ? borderColor : 'transparent'}` : 'none' , 
                }}
            >
                <div style={{ fontSize: '28px', fontWeight: '600', padding: '8px', color: textColor ? textColor : '#FFF' }}>
                    {title ? title : 'Recent Donations'}
                </div>
                {limitedDonation?.map((item, index) => (
                    <div index={index} style={{
                        width:"100%"
                    }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{textAlign:"left",fontWeight:"700"}}>
                                        <div style={{fontSize:"18px", color: textColor ? textColor : '#FFF'}}>
                                            {item.name}{" "}
                                        </div>
                                        {enable_amount && 
                                            <div style={{color: accentColor ? accentColor : '#FFF'}}>
                                                donated{" "}
                                                <span style={{ color: "#689900", fontWeight: "600", marginRight: "10px" }}>
                                                    ${item.price}
                                                </span> 
                                            </div>
                                        }    
                                    </div>
                                    {enable_comment && <div style={{textAlign:"left", color: accentColor ? accentColor : '#FFF'}}>{item.comment}</div>}
                                    {enable_location && <div style={{textAlign:"left", color: accentColor ? accentColor : '#FFF'}}>{item.country}</div>}
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default RecentDonations




export const recDonations = [
    {
        id: 0, name: "Ann Bowman", country: "Detroit, United States", price: "100,000.00", date: "Dec 25 ,2023", comment: "Thanks for all of the work you're doing in our community!"
    },
    {
        id: 1, name: "Hiroki Sinclair", country: "Toronto, Canada", price: "90,000.00", date: "Sept 15 ,2015", comment: "You've had a big impact on my life. I'm happy to give what I can."
    },
    {
        id: 2, name: "Lornella Andrews", country: "Dubai, United Arab Emirates", price: "80,000.00", date: "Jan 26 ,2019",comment:""
    },
    {
        id: 3, name: "Jamar Gahan", country: "Berlin, Germany", price: "50,000.00", date: "Dec 25 ,2023",comment:""
    },
    {
        id: 4, name: "Rashid Khan", country: "Karachi, Pakistan", price: "35,000.00", date: "Feb 05 ,2020", comment: "Making my donation to support those in need."
    },
    {
        id: 5, name: "Hanna Brown", country: "Islamabad, Pakistan", price: "35,000.00", date: "Dec 30 ,2020",comment:""
    },
    {
        id: 6, name: "Alexandra Pope", country: "London, United Kingdom", price: "35,000.00", date: "Jun 25 ,2022",comment:""
    },
]