import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { payment } from "../../data";
import { useSelector } from "react-redux";

const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const paypal = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/paypal.svg";
const stripe = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/stripe.svg";
const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/card.svg";
const cardLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/Card.svg";

const PaymentGateway = ({ paymentGateways }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const sortedEntries = Object.entries(paymentGateways).sort(
    (a, b) => a[1] - b[1]
  );

  const sortedObj = Object.fromEntries(sortedEntries);
  const resultArray = Object.entries(sortedObj).map(([gateway, ranking]) => ({
    ranking: String(ranking),
    gateway,
  }));

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
          boxShadow:
            themeMode === "dark"
              ? "0px 0px 0px blue"
              : "4px 4px 14px 0px #00000024",
          borderRadius: "15px",
          height: "fit-content",
          padding: "18px 25px",
          height: "100%",
        }}
      >
        {/* <Button disableRipple sx={{ "&:hover": { backgroundColor: "transparent" } }}
              //onClick={(e) => { setAnchorElSort(e.currentTarget); }}
            >
              <Typography mr={1} sx={{ color: themeMode==="dark"?"#A19A9A":"#8D8D8D", textTransform: "capitalize", fontSize: "12px",textWrap:"nowrap" }}>
                    By Region(UAE)
                <img src={themeMode==="dark"?downgrey:downgreyLight} alt="" style={{margin:"0px 0px 0px 10px"}}/>
              </Typography>
            </Button> */}
        <Grid
          mb={2}
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
            }}
          >
            Top Payment Gateways
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            //  overflowY: "auto",
          }}
        >
          {resultArray?.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: "5px 10px" }}>
              <Button
                disableRipple
                container
                sx={{
                  width: "100%",
                  border: "1px solid #9CCA3C",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    # {item.ranking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={9}
                  sm={9}
                  xs={9}
                  sx={{ display: "flex", alignItem: "center" }}
                >
                  {item.gateway === "" && (
                    <img
                      src={themeMode === "dark" ? paypal : paypal}
                      alt={item.gateway}
                      style={{ width: "fit-content" }}
                    />
                  )}
                  {item.gateway === "card" && (
                    <img
                      src={themeMode === "dark" ? card : cardLight}
                      alt={item.gateway}
                      style={{ width: "fit-content" }}
                    />
                  )}
                  {item.gateway === "fast_link" && (
                    <img
                      src={themeMode === "dark" ? stripe : stripe}
                      alt={item.gateway}
                      style={{ width: "fit-content" }}
                    />
                  )}
                </Grid>
              </Button>
            </Grid>
          ))}
          {/* {Object.entries(sortedObj).forEach(([key, value]) => {
            <Grid key={key} item md={12} sx={{ padding: "5px 10px" }}>
              <Button
                disableRipple
                container
                sx={{
                  width: "100%",
                  border: "1px solid #9CCA3C",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    # {value}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={9}
                  sm={9}
                  xs={9}
                  sx={{ display: "flex", alignItem: "center" }}
                >
                  <img
                    src={themeMode === "dark" ? down : down}
                    alt={"gateway"}
                    style={{ width: "fit-content" }}
                  />
                </Grid>
              </Button>
            </Grid>;
          })} */}
          {/* {payment.map((item, index) => (
            <Grid key={index} item md={12} sx={{ padding: "5px 10px" }}>
              <Button
                disableRipple
                container
                sx={{
                  width: "100%",
                  border: "1px solid #9CCA3C",
                  borderRadius: "15px",
                  padding: "10px 15px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
              >
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      textWrap: "nowrap",
                      color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                    }}
                  >
                    # {item.id}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={9}
                  sm={9}
                  xs={9}
                  sx={{ display: "flex", alignItem: "center" }}
                >
                  <img
                    src={themeMode === "dark" ? item.img : item.light}
                    alt={item.way}
                    style={{ width: "fit-content" }}
                  />
                </Grid>
              </Button>
            </Grid>
          ))} */}
        </Grid>
      </Grid>
    </>
  );
};
export default PaymentGateway;
