import { gql } from "@apollo/client";

export const GET_INSIGHTS_DATA = gql`
  query Insights(
    $startDate: String
    $endDate: String
    $campaignUuids: [String!]
  ) {
    insights(
      startDate: $startDate
      endDate: $endDate
      campaignUuids: $campaignUuids
    ) {
      donationsByDates
      newDonors
      oneTimeDonations
      recurringDonations
      todayDonations
      topDonors
      totalDonations
    }
  }
`;

export const GET_P2P = gql`
  query PeerToPeerFundraises(
    $first: Int
    $after: String
    $sortColumn: PeerToPeerFundraiseSortColumnsEnum
    $sortOrder: SortOrderEnum
    $searchQuery: String
  ) {
    peerToPeerFundraises(
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      first: $first
      after: $after
      query: $searchQuery
    ) {
      nodes {
        uuid
        name
        campaign {
          uuid
          title
          description
          donations {
            nodes {
              amount
              currency
            }
          }
        }
        goalAmount
        goalCurrency
        goalDate
        message
        updatedAt
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_CAMPAIGN_LIST = gql`
  query campaignList {
    campaigns {
      nodes {
        uuid
        title
        description
        status
      }
    }
  }
`;

export const GET_PAYMENT_DASHBOARD = gql`
  query ecoSystemDashboards {
    ecoSystemDashboards {
      totalRaisedByCampaign
      totalWithdrawalAmount
      netBalance
      totalDonationRaised
      topPaymentGateways
      mostUsedCurrencies
      totalRevenue
    }
  }
`;
