import { gql } from "@apollo/client";


export const GET_DONATION = gql`
    query Donation($uuid: ID!){
        donation(uuid: $uuid) {
            uuid
            amount
            currency
            donor {
                firstName
                lastName
                email
                phoneNumber
            }
            campaign {
                uuid
                title
                description
            }
            recurringDonation {
                frequency
            }
            feeCover
            recurring
            status
            stripeCharge
            stripeChargeId
            stripeRefund
            country
            region
            city
            browser
            device
            ipAddress
            updatedAt
            createdAt
        }
    }
`;

export const REFUND_DONATION = gql`
    mutation RefundDonation($uuid: ID!){
        refundDonation(input:{uuid: $uuid }){
            donation {
                uuid
                amount
                currency
                donor {
                    firstName
                    lastName
                    email
                    phoneNumber
                }
                campaign {
                    uuid
                    title
                    description
                }
                recurringDonation {
                    frequency
                }
                feeCover
                recurring
                status
                stripeCharge
                stripeChargeId
                stripeRefund
                updatedAt
                createdAt
            }
        }
    }
`

export const GET_EXCHANGE_RATE = gql`
    query ExchangeRate($amount: Float!, $fromCurrency: String!, $toCurrency: String!){
        exchangeRate(amount: $amount, fromCurrency: $fromCurrency, toCurrency: $toCurrency) {
            amount
            currency
        }
    }
`;