import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControlLabel,
  FormControl,
  MenuItem,
  Checkbox,
  Menu,
  Autocomplete,
  Chip,
  InputLabel,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { MySwitch } from "../CreateCampaign/MyComponents.jsx";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import DocumentTitle from "../../../../DocumentTitle.js";
import { useStyles } from "./useStyles.jsx";
import { infographics, details, addInfo } from "./data";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import { useDispatch } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_REPORT, GET_CAMPAIGN_LIST } from "./Query";
import { DatePickerComp } from "../DatePickerComp.jsx";
import { adjustNegativeMonths } from "../../../../utils/globalFunctions.js";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Controller, useForm } from "react-hook-form";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import {
  ArrowDownwardOutlined,
  ArrowDropDown,
  ArrowDropDownCircleOutlined,
} from "@mui/icons-material";

const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const up =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/up.svg";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";
const settings =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/settingsGreen.svg";

const CreateNewReport = ({ showPopupCallback }) => {
  DocumentTitle("Create Report - Acceleraise");
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [mode, setMode] = useState("Indivisual");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [anchorElFreq, setAnchorElFreq] = useState(null);
  const openFreq = Boolean(anchorElFreq);
  const handleClickFreq = (e) => {
    setAnchorElFreq(e.currentTarget);
  };
  const handleCloseFreq = () => setAnchorElFreq(null);

  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [freq, setFreq] = useState("Only Once");

  const handleChangeModified = (event) => {
    console.log("🚀 ~ handleChange ~ event:", event.target.value);
    setFreq(event.target.value);
  };
  const handleChangeWeekMonth = (event) => {
    console.log("🚀 ~ handleChange ~ event:", event);
    setFreq(event);
    handleCloseFreq();
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [campaignList, setCampaignList] = useState(null);

  const [reportObj, setReportObj] = useState({
    campaignUuid: null,
    comparisonCampaignUuid: null,
    timePeriod: "",
    email: "",
    startDeliveryDate: currentDate,
    scheduleAutoDelivery: false,
    fileType: "",
  });

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const [CreateReport, { loading: reportLoading, error: reportError }] =
    useMutation(CREATE_REPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("report request was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("report request was successful!"));
      },
    });

  const IndivisualMode = () => {
    setMode("Indivisual");
  };
  const CompareMode = () => {
    setMode("Compare");
  };
  const handleAdditionalSettings = (event) => {
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const handleGenerateReport = async () => {
    const variables = {
      CreateReportInput: {
        campaignUuid: reportObj?.campaignUuid,
        comparisonCampaignUuid: reportObj?.comparisonCampaignUuid,
        timePeriod: reportObj?.timePeriod,
        email: reportObj?.email,
        startDeliveryDate: reportObj?.startDeliveryDate,
        scheduleAutoDelivery: reportObj?.scheduleAutoDelivery,
      },
    };
    dispatch(setSpinnerLoading(true));
    try {
      const result = await CreateReport({ variables });
      dispatch(setSpinnerLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setSpinnerLoading(false));
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "40px", sm: "40px", xs: "28px" },
              fontWeight: "600",
              textAlign: { md: "left", sm: "left", xs: "center" },
              textWrap: "nowrap",
              color: themeMode === "dark" ? "#FFFF" : "#030303",
              fontFamily: "Poppins",
            }}
          >
            Generate Report
            {/* <img src={question} alt="" /> */}
          </Typography>
        </Grid>

        <Grid
          item
          md={4.5}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
              md: "space-between",
              sm: "space-between",
              xs: "flex-start",
            },
            flexDirection: { md: "row", sm: "row", xs: "row" },
            flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
          }}
        >
          <Box
            item
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: { md: "100%", sm: "100%", xs: "100%" },
              padding: { md: "0px", sm: "0px", xs: "0px 0px" },
            }}
          >
            <Button
              onClick={IndivisualMode}
              disableRipple
              sx={{
                color: "#FFF",
                color:
                  themeMode === "light" && mode !== "Indivisual"
                    ? "#0F0F0F"
                    : "#FFF",

                background:
                  mode === "Indivisual"
                    ? "linear-gradient(274.38deg, #618F00 4.85%, #8CCF01 112.34%)"
                    : "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 25px", sm: "10px 15px", xs: "8px 6px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "100%",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "fit-content",
                }}
              >
                <Grid
                  item
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    textWrap: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "14px", sm: "12px", xs: "10px" },
                      fontWeight: "400",
                      fontFamily: "Poppins",
                    }}
                  >
                    Individual Campaign
                  </Typography>
                </Grid>
              </Grid>
            </Button>

            <Button
              onClick={CompareMode}
              disableRipple
              sx={{
                color:
                  themeMode === "light" && mode !== "Compare"
                    ? "#0F0F0F"
                    : "#FFF",

                background:
                  mode === "Compare"
                    ? "linear-gradient(274.38deg, #618F00 4.85%, #8CCF01 112.34%)"
                    : "transparent",

                border: "1px solid #94DA00",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 25px", sm: "10px 15px", xs: "8px 15px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "100%",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "fit-content",
                }}
              >
                <Grid
                  item
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    textWrap: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "14px", sm: "12px", xs: "10px" },
                      fontWeight: "400",
                      fontFamily: "Poppins",
                    }}
                  >
                    Compare Campaign
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Box>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid sx={{ display: "flex" }}>
            <Grid item md={3} sm={5.5} xs={mode === "Indivisual" ? 12 : 6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "24px",
                  fontWeight: "600",
                  width: "fit-content",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontFamily: "Poppins",
                }}
              >
                Select Campaign
              </Typography>

              <Grid>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    select
                    variant="outlined"
                    placeholder={2}
                    size="small"
                    // default={2}
                    value={reportObj?.campaignUuid || ""}
                    onChange={(e) => {
                      setReportObj((prevObj) => ({
                        ...prevObj,
                        campaignUuid: e.target.value,
                      }));
                    }}
                    sx={{
                      mt: { md: 1, sm: 2, xs: 2 },
                      "& .MuiSelect-icon": {
                        fill: "#689900",
                      },
                      "& .MuiSelect-selectMenu": {
                        backgroundColor: "#160609",
                      },

                      backgroundColor:
                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                      borderRadius: "10px",
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        color: themeMode === "dark" ? "#ffff" : "#000",
                        "&::placeholder": {
                          color: themeMode === "dark" ? "#ffff" : "#000",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFFFF",
                        fontSize: "16px",
                        fontFamily: "Poppins",

                        marginBottom: "20px",
                        transform: "translate(0px, -100%) scale(0.85)",
                        "&.MuiInputLabel-shrink": {
                          transform: "translate(0px, -100%) scale(0.85)",
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                        borderWidth: "0.5px",
                        borderRadius: "5px",
                      },
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#FFF",
                        },
                        outline: "none",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            Width: "100%",
                            background: "#000",
                            background: themeMode === "dark" ? "#000" : "#ffff",
                          },
                        },
                      },
                    }}
                  >
                    {campaignList?.map((option) => (
                      <MenuItem
                        key={option.uuid}
                        value={option.uuid}
                        selected={option.uuid === reportObj?.campaignUuid}
                        sx={{
                          fontWeight: "600",
                          color: themeMode === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            themeMode === "dark" ? "#000" : "#ffff",

                          "&:hover": {
                            color: "#689900",
                          },
                          "&:focus": {
                            color: "#689900",
                          },
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>

            {mode === "Compare" ? (
              <>
                <Grid py={2} sx={{ display: "flex", alignItems: "flex-end" }}>
                  <Typography
                    mx={{ md: 3, sm: 2, xs: 1 }}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontFamily: "Poppins",
                    }}
                  >
                    vs
                  </Typography>
                </Grid>
                <Grid item md={3} sm={5.5} xs={6}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      marginTop: "24px",
                      fontWeight: "600",
                      width: "fit-content",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontFamily: "Poppins",
                    }}
                  >
                    Select Campaign
                  </Typography>

                  <Grid>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        select
                        variant="outlined"
                        placeholder={2}
                        size="small"
                        // default={2}
                        value={reportObj.comparisonCampaignUuid || ""}
                        onChange={(e) => {
                          setReportObj((prevObj) => ({
                            ...prevObj,
                            comparisonCampaignUuid: e.target.value,
                          }));
                        }}
                        sx={{
                          mt: { md: 1, sm: 2, xs: 2 },
                          "& .MuiSelect-icon": {
                            fill: "#689900",
                          },
                          "& .MuiSelect-selectMenu": {
                            backgroundColor: "#160609",
                          },
                          backgroundColor:
                            themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                          borderRadius: "10px",
                          width: "100%",
                          "& .MuiOutlinedInput-input": {
                            color: themeMode === "dark" ? "#ffff" : "#000",
                            "&::placeholder": {
                              color: themeMode === "dark" ? "#ffff" : "#000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            fontSize: "16px",
                            fontFamily: "Poppins",

                            marginBottom: "20px",
                            transform: "translate(0px, -100%) scale(0.85)",
                            "&.MuiInputLabel-shrink": {
                              transform: "translate(0px, -100%) scale(0.85)",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor:
                              themeMode === "dark" ? "#ffff" : "#0F0F0F",
                            borderWidth: "0.5px",
                            borderRadius: "5px",
                          },
                          "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#FFFF",
                            },
                            outline: "none",
                          },
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                Width: "100%",
                                background: "#000",
                                background:
                                  themeMode === "dark" ? "#000" : "#ffff",
                              },
                            },
                          },
                        }}
                      >
                        {campaignList?.map((option) => (
                          <MenuItem
                            key={option.uuid}
                            value={option.uuid}
                            selected={
                              option.uuid === reportObj?.comparisonCampaignUuid
                            }
                            sx={{
                              fontWeight: "600",
                              color: themeMode === "dark" ? "#fff" : "#000",
                              backgroundColor:
                                themeMode === "dark" ? "#000" : "#ffff",

                              "&:hover": {
                                color: "#689900",
                              },
                              "&:focus": {
                                color: "#689900",
                              },
                            }}
                          >
                            {option.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          <Grid
            my={2}
            md={12}
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid item md={6} sm={7}>
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "24px",
                  fontWeight: "600",
                  width: "fit-content",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontFamily: "Poppins",
                }}
              >
                Time Period
              </Typography>
              <Grid item md={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    width: "fit-content",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      textWrap: "nowrap",
                      width: "fit-content",
                    }}
                  >
                    <Button
                      className={classes.filterBtn}
                      disableRipple
                      sx={{
                        padding: {
                          md: "10px 30px",
                          sm: "10px 15px",
                          xs: "8px 15px",
                        },
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                      onClick={(e) => {
                        setAnchorElDateFilter(e.currentTarget);
                      }}
                    >
                      <Grid container className={classes.noWrap}>
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                            fontSize: { md: "12px", sm: "12px", xs: "10px" },
                            display: "flex",
                            alignItems: "center",
                            textWrap: "nowrap",
                            fontFamily: "Poppins",
                          }}
                        >
                          <CalendarMonthIcon
                            sx={{
                              color: "#9CCA3C",
                              marginRight: {
                                md: "10px",
                                sm: "10px",
                                xs: "5px",
                              },
                            }}
                          />
                          {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                          {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                        </Typography>
                      </Grid>
                    </Button>
                    <Menu
                      anchorEl={anchorElDateFilter}
                      open={Boolean(anchorElDateFilter)}
                      onClose={(e) => {
                        setAnchorElDateFilter(null);
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          background:
                            themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                          width: "fit-content",
                          border: "2px solid #ABFC00",
                          borderRadius: "40px",
                        },
                      }}
                    >
                      <MenuItem>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item md={2} xs={12}>
                            <DatePickerComp
                              startDate={dateRange?.startDate}
                              endDate={dateRange?.endDate}
                              displayDate={setDateRange}
                            />{" "}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "24px",
                  fontWeight: "600",
                  width: "fit-content",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontFamily: "Poppins",
                }}
              >
                File-Type
              </Typography>
              <Grid item md={12} sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    width: "fit-content",
                    marginTop: "8px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      textWrap: "nSowrap",
                      width: "fit-content",
                    }}
                  >
                    <Typography className="file-type-style">CSV</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            my={{ md: 5, sm: 5, xs: 0 }}
            px={6}
            py={4}
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px red"
                  : "0px 8px 5px 0px #00000040",
              width: "fit-content",
              borderRadius: "15px",
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Typography
              mr={{ md: 4, sm: 4, xs: 0 }}
              sx={{
                fontSize: "16px",
                textAlign: { md: "left", sm: "left", xs: "center" },
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                fontFamily: "Poppins",
              }}
            >
              Schedule Automatic Report Delivery
            </Typography>
            <Grid
              my={{ md: 0, sm: 0, xs: 2 }}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <MySwitch
                checked={reportObj?.active || ""}
                onChange={(event) =>
                  setReportObj((prevObj) => ({
                    ...prevObj,
                    active: event.target.checked,
                  }))
                }
              />

              <Typography
                ml={2}
                sx={{
                  fontSize: "16px",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontFamily: "Poppins",
                }}
              >
                Disabled
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              flexDirection: { md: "column", sm: "column", xs: "column" },
            }}
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              sx={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid
                my={{ md: 0, sm: 0, xs: 4 }}
                sx={{ width: { md: "70%", sm: "70%", xs: "100%" } }}
              >
                <Controller
                  control={control}
                  name="tags"
                  rules={{
                    required: "Veuillez choisir une réponse",
                  }}
                  render={({ field: { onChange } }) => (
                    <Autocomplete
                      defaultValue={[]}
                      multiple
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      onChange={(event, values) => {
                        onChange(values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Email to"
                          placeholder=""
                          helperText={errors.tags?.message}
                          error={!!errors.tags}
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item md={6} mt={4}>
              <Typography
                mb={1}
                sx={{
                  fontSize: "16px",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                  fontFamily: "Poppins",
                }}
              >
                Delivery Frequency
              </Typography>

              <Grid item md={12} xs={12} style={{ display: "inline-block" }}>
                <Button
                  variant="outlined"
                  onClick={handleClickFreq}
                  endIcon={<ArrowDropDown />}
                  style={{
                    color: themeMode === "dark" ? "white" : "black",
                    width: "180px",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    justifyContent: "space-between",
                    border:
                      themeMode === "dark"
                        ? "1px solid white"
                        : "1px solid black",
                  }}
                >
                  {freq}
                </Button>
                <Menu
                  anchorEl={anchorElFreq}
                  open={openFreq}
                  onClose={handleCloseFreq}
                  style={{ width: "180px" }}
                >
                  <MenuItem
                    value={"Only Once"}
                    onClick={() => handleChangeWeekMonth("Only Once")}
                  >
                    Only Once
                  </MenuItem>
                  <NestedMenuItem label="Every Week" parentMenuOpen={true}>
                    <MenuItem
                      value={"monday"}
                      onClick={() => handleChangeWeekMonth("monday")}
                    >
                      Monday
                    </MenuItem>
                    <MenuItem
                      value={"Tuesday"}
                      onClick={() => handleChangeWeekMonth("Tuesday")}
                    >
                      Tuesday
                    </MenuItem>
                    <MenuItem
                      value={"Wednesday"}
                      onClick={() => handleChangeWeekMonth("Wednesday")}
                    >
                      Wednesday
                    </MenuItem>
                    <MenuItem
                      value={"Thursday"}
                      onClick={() => handleChangeWeekMonth("Thursday")}
                    >
                      Thursday
                    </MenuItem>{" "}
                    <MenuItem
                      value={"Friday"}
                      onClick={() => handleChangeWeekMonth("Friday")}
                    >
                      Friday
                    </MenuItem>{" "}
                    <MenuItem
                      value={"Saturday"}
                      onClick={() => handleChangeWeekMonth("Saturday")}
                    >
                      Saturday
                    </MenuItem>{" "}
                    <MenuItem
                      value={"Sunday"}
                      onClick={() => handleChangeWeekMonth("Sunday")}
                    >
                      Sunday
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label="Every Month" parentMenuOpen={true}>
                    <MenuItem
                      value={"January"}
                      onClick={() => handleChangeWeekMonth("January")}
                    >
                      January
                    </MenuItem>
                    <MenuItem
                      value={"February"}
                      onClick={() => handleChangeWeekMonth("February")}
                    >
                      February
                    </MenuItem>
                    <MenuItem
                      value={"March"}
                      onClick={() => handleChangeWeekMonth("March")}
                    >
                      March
                    </MenuItem>
                    <MenuItem
                      value={"April"}
                      onClick={() => handleChangeWeekMonth("April")}
                    >
                      April
                    </MenuItem>{" "}
                    <MenuItem
                      value={"May"}
                      onClick={() => handleChangeWeekMonth("May")}
                    >
                      May
                    </MenuItem>{" "}
                    <MenuItem
                      value={"June"}
                      onClick={() => handleChangeWeekMonth("June")}
                    >
                      June
                    </MenuItem>{" "}
                    <MenuItem
                      value={"July"}
                      onClick={() => handleChangeWeekMonth("July")}
                    >
                      July
                    </MenuItem>
                    <MenuItem
                      value={"August"}
                      onClick={() => handleChangeWeekMonth("August")}
                    >
                      August
                    </MenuItem>
                    <MenuItem
                      value={"September"}
                      onClick={() => handleChangeWeekMonth("September")}
                    >
                      September
                    </MenuItem>
                    <MenuItem
                      value={"October"}
                      onClick={() => handleChangeWeekMonth("October")}
                    >
                      October
                    </MenuItem>
                    <MenuItem
                      value={"November"}
                      onClick={() => handleChangeWeekMonth("November")}
                    >
                      November
                    </MenuItem>
                    <MenuItem
                      value={"December"}
                      onClick={() => handleChangeWeekMonth("December")}
                    >
                      December
                    </MenuItem>
                  </NestedMenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          mt={4}
          md={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Button
            disableRipple
            onClick={handleAdditionalSettings}
            sx={{
              width: "fit-content",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <Typography
              sx={{
                color: "#8ED003",
                textTransform: "capitalize",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <img src={settings} alt="" style={{ marginRight: "10px" }} />
              Additional Settings
              {anchorEl ? (
                <img
                  src={up}
                  alt=""
                  style={{ width: "10px", height: "10px", marginLeft: "10px" }}
                />
              ) : (
                <img
                  src={down}
                  alt=""
                  style={{ width: "10px", height: "10px", marginLeft: "10px" }}
                />
              )}
            </Typography>
          </Button>

          <Grid
            my={3}
            item
            md={12}
            sm={12}
            sx={{
              display: anchorEl ? "flex" : "none",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "21px", sm: "18px", xs: "16px" },
                  fontWeight: "600",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                Infographics Included
              </Typography>
              <Grid mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                {infographics?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={item.enable}
                        //onChange={handleAgreeToProcessChange}
                        style={{ color: "#659500" }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: { md: "16px", xs: "16px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.text}
                      </Typography>
                    }
                    size="small"
                  />
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "21px", sm: "18px", xs: "16px" },
                  fontWeight: "600",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                Details Included
              </Typography>
              <Grid mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                {details?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={item.enable}
                        //onChange={handleAgreeToProcessChange}
                        style={{ color: "#659500" }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: { md: "16px", xs: "16px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.text}
                      </Typography>
                    }
                    size="small"
                  />
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              md={4}
              sm={4}
              xs={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "21px", sm: "18px", xs: "16px" },
                  fontWeight: "600",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                Additional Info
              </Typography>
              <Grid mt={2} sx={{ display: "flex", flexDirection: "column" }}>
                {addInfo?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={item.enable}
                        //onChange={handleAgreeToProcessChange}
                        style={{ color: "#659500" }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: { md: "16px", xs: "16px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        {item.text}
                      </Typography>
                    }
                    size="small"
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-start" my={4}>
          <Grid item md={3} sm={4} xs={6}>
            <LoadingButton
              loading={false}
              loadingPosition="start"
              startIcon={<DescriptionOutlinedIcon />}
              variant="outlined"
              sx={{
                color: themeMode === "dark" ? "#FFFF" : "#030303",
                background: "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                fontSize: { md: "14px", sm: "14px", xs: "10px" },
                textWrap: "nowrap",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  color: "#030303",
                },
              }}
              onClick={() => {
                handleGenerateReport();
              }}
            >
              Generate Report
            </LoadingButton>
          </Grid>
          {/* <Grid item md={3} sm={4} xs={6}>
            <LoadingButton
              disableRipple
              loading={false}
              loadingPosition="start"
              startIcon={<EyeIcon />}
              sx={{
                color: "#FFFF",
                background: "#659500",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                flexWrap: "nowrap",
                margin: "10px 10px 10px 0px",
                width: "fit-content",
                fontSize: { md: "14px", sm: "14px", xs: "8px" },
                textWrap: "nowrap",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
              href="/dashboard/reports"
            >
              Show Previous Reports
            </LoadingButton>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};
export default CreateNewReport;
