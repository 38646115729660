import { gql } from "@apollo/client";

export const UPDATE_USER_REQUEST = gql`
  mutation UpdateUserRequest($uuid: ID!, $nonprofitAnnualRevenue: String, $donationPlatform: String, $discoverFrom: String, $workType: String ){
    updateUserRequest(input:{uuid: $uuid, nonprofitAnnualRevenue: $nonprofitAnnualRevenue, donationPlatform: $donationPlatform, discoverFrom: $discoverFrom, workType: $workType}){
      userRequest {
        uuid
        email
        nonprofitAnnualRevenue
        donationPlatform
        discoverFrom
        workType
      }
    }
  }
`;