import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { useSelector } from "react-redux";

import bkgLight from '../../../../../../assets/FeatureLightTheme/DataExport/bkgHero.svg'
import specLight from '../../../../../../assets/FeatureLightTheme/DataExport/specLight.svg'

const spec =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/spec.svg";
  const bkg =
    "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/DataExport/bkgSpecs.svg";


// const light_export2 =
//   "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_export2.svg";
// const light_export1 =
//   "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/SubFeatures/light_export1.svg";

const Specification = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 70px 10px",
            sm: "130px 30px 10px",
            xs: "100px 30px 10px",
          },
          backgroundImage:
            themeMode === "dark" ? `url('${bkg}')` : `url('${bkgLight}')`,
        }}
      >
        <Grid
          className={classes.columnCenter}
          item
          md={8.5}
          sm={10}
          xs={12}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              fontSize: { md: "40px", sm: "36px", xs: "34px" },
            }}
          >
            <span
              className={
                themeMode === "dark" ? classes.color : classes.lightColor
              }
              sx={{ fontWeight: "700" }}
            >
              {" "}
              Data,{" "}
            </span>
            <span
              className={themeMode === "dark" ? "text-white" : "text-black"}
            >
              Tailored to Your Specifications{" "}
            </span>
          </Typography>
          <Typography
          mb={{md:2}}
            sx={{ fontFamily:"Stolzl",fontWeight:"400",fontSize:"16px"}}
            className={themeMode === "dark" ? "text-white" : "text-black"}
          >
            Customize your data exports to match your unique requirements. From
            record selection to format preferences, our Data Export Management
            feature ensures that you receive precisely the data you need.
            Download files instantly or schedule automated deliveries to your
            preferred destinations.
          </Typography>
        </Grid>
        <Grid
          my={3}
          item
          className={classes.jCenter}
          md={9.7}
          sm={10}
          xs={12}
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
            width: { md: "40%", sm: "auto", xs: "auto" },
          }}
        >
          <Grid item className={classes.textCenter} md={7}>
            <img
              src={themeMode === "dark" ? spec : specLight}
              alt=""
              className={classes.full}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Specification;
