import { Grid, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import NavBar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Answer from "./Answer/Answer";
import DocumentTitle from "../../../DocumentTitle";

const bkg =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footer.svg";
const footerlight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Auth/footerlight.svg";

const ContactUs = () =>{
    DocumentTitle("Contact Us - Acceleraise");
    const themeMode = useSelector((state) => state.themeMode.mode);
    const light = "light";
    const dark = "dark";
    return(
        <Grid
            sx={{ backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#FFF", }}
        >
            {themeMode === "dark" ? (
                <NavBar
                    scrollColor="#29070a"
                    btnColor="#4D0E13"
                    getStartedBorder="1px solid transparent"
                    profileBkg="#2b1719"
                    profileBorder="1px solid #a1020f"
                    logoTheme={light}
                    scrollingColor={light}
                    textColor="#FFF"
                />
            ) : (
                <NavBar
                    scrollColor="#8ED003"
                    btnColor="#8ED003"
                    getStartedBorder="1px solid transparent"
                    profileBkg="#84ad2d"
                    profileBorder="1px solid #ADFF00"
                    logoTheme={dark}
                    scrollingColor={dark}
                    txtColor="black"
                />
            )}

      <Answer />

      {themeMode === "dark" ? (
        <Footer
          bkg={bkg}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      ) : (
        <Footer
          bkg={footerlight}
          btnText="#FFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#1a0101"
          btnBorder="0.5px solid #689900"
          textHover="#FFFF"
          brightness="brightness(110%)"
        />
      )}
    </Grid>
  );
};
export default ContactUs;
