import axios from "axios";

const API_URL = "https://api.accceleraise.com";


const makeDonation = async (
      firstName, 
      lastName, 
      campaignUuid,
      Recurring,
      Frequency,
      Amount,
      Currency,
      Email,
      stripeCardToken,
      stripePaymentMethodId,
      paymentMethod,
      browser,
      country,
      region,
      city,
      device,
      ip_address,
      cardTitle,
      cardNumber,
      expiryMonth,
      expiryYear,
      cvcCode,
  ) => {
  try {
    const response = await axios.post(API_URL + '/api/v1/donations', {
      first_name: firstName, 
      last_name: lastName, 
      campaign_uuid: campaignUuid,
      recurring: Recurring,
      frequency: Recurring ? Frequency : null,
      amount: Amount,
      currency: Currency,
      email: Email,
      stripe_card_token: stripeCardToken,
      stripe_payment_method_id: stripePaymentMethodId,
      payment_method: paymentMethod,
      browser: browser,
      country: country,
      region: region,
      city: city,
      device: device,
      ip_address: ip_address,
      card_title: cardTitle,
      card_number: cardNumber,
      expiry_month: expiryMonth,
      expiry_year: expiryYear,
      cvc_code: cvcCode
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getCampaignDetails = async (campaignUuid) => {
  try {
    const response = await axios.get(`${API_URL}/api/v1/campaigns/${campaignUuid}`);
    return response; 
  } catch (error) {
    throw error;
  }
};

export const DonationService = {
  makeDonation,
  getCampaignDetails,
};
  