import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export const NavItems = [
  {
    id: 1,
    name: "Features",
    link: "/features",
    logo: <AutoAwesomeIcon />,
  },
  {
    id: 2,
    name: "Company",
    link: "company",
    logo: <IntegrationInstructionsIcon />,
  },
  {
    id: 3,
    name: "Learn",
    link: "/learn",
    logo: <AssessmentIcon />,
  },
  {
    id: 4,
    name: "Pricing",
    link: "/pricing",
    logo: <MonetizationOnIcon />,
  },
];
