export const category = [
    {
        id:0,
        name:"No commitment needed",
    },
    {
        id:1,
        name:"No Monthly Charges ",
    },
    {
        id:2,
        name:"No Initial Setup Cost",
    },
    

]