import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
    logo: {
        display: "flex",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "transparent",
        },
    },
    flexEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "5px",
  },
  btn: {
    borderRadius: "50px",
    "&:hover": { backgroundColor: "#2e3322" },
  },
  btn2: {
    paddingRight: " 15px",
    borderRadius: "30px",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #94D906",
      backgroundColor: "#2e3322",
    },
  },
  allFit: {
    width: "fit-content",
    height: "fit-content",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dpHolder: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
  w80: {
    width: "80%",
  },
  colCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  profile: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    color: "#FFFF",
  },
  profileDull: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    color: "#9F9F9F",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    "& .MuiPaper-root": {
      border: "1px solid #689900",
      color: "#FFF",
      backgroundColor: "#0F0F0F",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "flex-end",
      borderRadius: "15px",
    },
    "& .MuiList-root": {
      width: "100%",
    },
  },
  menuItem: {
    backgroundColor: "#0F0F0F",
    width: "100% !important",
    color: "#FFFF",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      color: "#689900",
      backgroundColor: "#2222",
    },
  },






   rotate: {
    "&:hover": {
      background: "transparent",
      transform: "rotate(315deg)",
    },
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  transButton: {
    background: "transparent",
    border: "1px solid transparent",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    textWrap: "nowrap",
  },
  fw500: {
    fontWeight: "500",
    textTransform: "capitalize",
    textWrap: "nowrap",
  },
neonButton: {
    color: "#8ED003",
    background: "transparent",
    border: "1px solid transparent",
    textTransform: "capitalize",
    fontWeight: "500",
    display: "flex",
    width: "fit-content",
    justifyContent: "flex-start",
    alignContent: "center",
    "&:hover": {
      background: "transparent",
    },
  },
}))