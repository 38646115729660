import { Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

const gift = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/gift.png";

export const PreDonationUpsell = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div className="flex flex-col h-full">
      <div>
        <Typography className="pre-don-text py-4 text-center">
          Become a Monthly Supporter{" "}
        </Typography>
        <hr />
        <Typography className="pre-don-text2 py-4 text-center px-8">
          Will you consider becoming one of our valued monthly supporter’s by
          converting your <b>1000$ </b> contribution into a monthly donation?{" "}
        </Typography>
        <Typography className="pre-don-text2   text-center px-8">
          Ongoing monthly donations allow us to better focus on our mission
        </Typography>
      </div>
      <div className="flex flex-col justify-end pt-11 px-8">
        <div className="container mt-5 pt-8">
          <Button
            variant="contained"
            className="w-full py-3"
            sx={{ backgroundColor: "#BB0C0C", borderRadius: "10px" }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-white"
                  : "pre-donation-btn text-black"
              }
            >
              Donate 50$/Month{" "}
            </span>
          </Button>
          <div className="bottom-left">
            <img src={gift} />
          </div>
        </div>

        <div className="mt-5">
          <Button
            variant="contained"
            className="w-full py-3"
            sx={{ backgroundColor: "#A4B200", borderRadius: "10px" }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-white"
                  : "pre-donation-btn text-black"
              }
            >
              Donate 25$/Month{" "}
            </span>
          </Button>
        </div>
        <div className="mt-5">
          <Button
            variant="outlined"
            className="w-full py-3"
            sx={{ border: "1px solid #A4B200", borderRadius: "10px" }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-white"
                  : "pre-donation-btn text-black"
              }
            >
              Keep my on-time $1000 gift{" "}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
