import React, { useEffect, useState, useRef } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
  TextareaAutosize,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup,
  SliderMark,
  Box,
  InputAdornment,
  Tooltip
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { setSpinnerLoading } from '../../../../../store/spinnerLoaderSlice'
import { useDispatch } from 'react-redux'
import { success, error as errorMsg } from '../../../../../store/alertSlice'
import { category, allElements, tabs, freq, counterType } from './elements'
import MainSearchBar from '../../MainSearch'
import { QuestionSearch, ElementSearch } from './ElementSearch'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_ELEMENT, GET_CAMPAIGN_LIST, UPDATE_ELEMENT } from '../Query'
import { CustomColorPicker } from '../../CreateCampaign/MyComponents'
import DonateButtonElement from './CustomElements/DonateButtonElement'
import StickyButtonElement from './CustomElements/StickyButtonElement'
import DonateForm from './CustomElements/DonateForm/DonateForm'
import { SimpleForm } from './CustomElements/SimpleForm/SimpleForm'
import ReminderElement from './CustomElements/ReminderElement'
import { useSelector } from 'react-redux'
import { useStyles } from './useStyles'
import ReactDOMServer from 'react-dom/server'
import { ImgCard } from './CustomElements/ImgCard/ImgCard'
import { Popup } from './CustomElements/Popup/Popup'
import GoalMeter from './CustomElements/Goal Meter/goalMeter'
import StatCounter from './CustomElements/StatCounter/StatCounter'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import {
  adjustNegativeMonths,
  currencies
} from '../../../../../utils/globalFunctions.js'
import { NumberInput } from './NumberInput'
import DonationLevel from './CustomElements/DonationLevel/DonationLevel'
import { GroupBtn } from './CustomElements/GroupBtn/GroupBtn'
import ColorBox from './CustomFields/ColorBox/ColorBox'
import TickBox from './CustomFields/TickBox/TickBox'
import SlidingElement from './CustomFields/SlidingElement/SlidingElement'
import MessageBar from './CustomElements/MessageBar/MessageBar'
import TopSupporter, {
  supporter
} from './CustomElements/TopSupporter/TopSupporter'
import TopCountries, {
  topCountry
} from './CustomElements/TopCountries/TopCountries'
import RecentDonations, {
  recDonations
} from './CustomElements/RecentDonations/RecentDonation'
import TopAcceleraiser, {
  topAcceleraiser
} from './CustomElements/TopAcceleraiser/TopAcceleraiser'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { ArrowRightAlt } from '@mui/icons-material'
import ImpactSlider from './CustomElements/ImpactSlider/ImpactSlider'
import SocialProof from './CustomElements/SocialProof/SocialProof'
import QRCode from './CustomElements/QR/QR'
import QRCodeImg from './CustomElements/QR/QR'
import MyMap from './CustomFields/DonorMaps/DonorMaps'
import P2PButton from './CustomElements/P2PButton/P2PButton'

const question = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/question.svg'
const cross = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg'
const crossDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/cross.svg'
const plant = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/plant.svg'
const button = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/button.svg'
const link = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/link.svg'
const donateLines = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/donateLines.svg'
const stickyLines = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/lines2.svg'
const noPhone = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Element/noPhone.svg'
const upload = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/upload.svg'
const uploadDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Overview/uploadDark.svg'

const CreateElement = ({ onClose, selectedElement, setRefetchElementRows }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const logoImgInputRef = useRef(null)
  const themeMode = useSelector(state => state.themeMode.mode)
  const [selectedValue, setSelectedValue] = useState('e')
  const [selectElement, setSelectElement] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)
  const [selectedCategoryType, setSelectedCategoryType] = useState(0)
  const [selectTabs, setSelectTabs] = useState(0)
  const [imgFile, setImgFile] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [mode, setMode] = useState(!selectedElement ? 'Show' : 'Customize')
  const [searchQuery, setSearchQuery] = useState(null)
  const [startDateField, setStartDateField] = useState(false)
  const [endDateField, setEndDateField] = useState(false)
  const [matchingField, setMatchingField] = useState(false)
  const [amount, setAmount] = useState(2237)
  const [customLevel, setCustomLevel] = useState([''])
  const [limiter, setLimiter] = useState(4)
  const [countryLimiter, setCountryLimiter] = useState(5)
  const [donationLimiter, setDonationLimiter] = useState(6)
  const [acceleraiserLimiter, setAcceleraiserLimiter] = useState(3)
  const [groupBtn, setGroupBtn] = useState([
    { id: 0, pay: 100 },
    { id: 1, pay: 75 },
    { id: 2, pay: 50 },
    { id: 3, pay: 25 },
    { id: 4, pay: 15 },
    { id: 5, pay: 10 }
  ])
  const [impactLevel, setImpactLevel] = useState([
    { id: 0, price: '3', family: '3' },
    { id: 1, price: '5', family: '5' },
    { id: 2, price: '7', family: '7' },
    { id: 3, price: '10', family: '10' }
  ])
  const [multiplierValue, setMultiplierValue] = React.useState(1)
  const [elementObj, setElementObj] = useState({
    ...selectedElement,
    uuid: selectedElement?.uuid,
    campaignUuid: selectedElement?.campaign?.uuid,
    name: selectedElement?.name,
    defaultFrequency: selectedElement?.defaultFrequency,
    elementType: selectedElement?.elementType,
    defaultAmount: selectedElement?.defaultAmount,
    amount: selectedElement?.amount,
    behaviour: selectedElement?.behaviour,
    appearance: {
      label: selectedElement?.appearance?.label,
      title: selectedElement?.appearance?.title,
      content: selectedElement?.appearance?.content
        ? selectedElement?.appearance?.content
        : '',
      size: selectedElement?.appearance?.size
        ? selectedElement?.appearance?.size
        : '25',
      height: selectedElement?.appearance?.height
        ? selectedElement?.appearance?.height
        : '',
      width: selectedElement?.appearance?.width
        ? selectedElement?.appearance?.width
        : '',
      borderRadius: selectedElement?.appearance?.borderRadius
        ? selectedElement?.appearance?.borderRadius
        : '6',
      borderSize: selectedElement?.appearance?.borderSize
        ? selectedElement?.appearance?.borderSize
        : '0',
      borderColor: selectedElement?.appearance?.borderColor
        ? selectedElement?.appearance?.borderColor
        : '#FFFF',
      iconColor: selectedElement?.appearance?.iconColor
        ? selectedElement?.appearance?.iconColor
        : '#FF0000',
      backgroundColor: selectedElement?.appearance?.backgroundColor,
      labelColor: selectedElement?.appearance?.labelColor,
      textColor: selectedElement?.appearance?.textColor,
      activeColor: selectedElement?.appearance?.activeColor
        ? selectedElement?.appearance?.activeColor
        : '#7CB602',
      enable: selectedElement?.appearance?.enable
        ? selectedElement?.appearance?.enable
        : true,
      enable_amount: selectedElement?.appearance?.enable_amount
      ? selectedElement?.appearance?.enable_amount
      : true,
      enable_location: selectedElement?.appearance?.enable_location
      ? selectedElement?.appearance?.enable_location
      : true,
      enable_emoji: selectedElement?.appearance?.enable_emoji
      ? selectedElement?.appearance?.enable_emoji
      : true,
      enable_comment: selectedElement?.appearance?.enable_comment
      ? selectedElement?.appearance?.enable_comment
      : true,
      btnColor: selectedElement?.appearance?.btnColor,
      btnSize: selectedElement?.appearance?.btnSize,
      btnBorderSize: selectedElement?.appearance?.btnBorderSize,
      btnBorderRadius: selectedElement?.appearance?.btnBorderRadius,
      btnBorderColor: selectedElement?.appearance?.btnBorderColor,
      show_close_button: selectedElement?.appearance?.show_close_button 
        ? selectedElement?.appearance?.show_close_button
        : true,
      btnAlignment: selectedElement?.appearance?.btnAlignment
        ? selectedElement?.appearance?.btnAlignment
        : 2,
      btnPositionVert: selectedElement?.appearance?.btnPositionVert
        ? selectedElement?.appearance?.btnPositionVert
        : 1,
      btnPositionHorz: selectedElement?.appearance?.btnPositionHorz
        ? selectedElement?.appearance?.btnPositionHorz
        : 1,
      scroll_offset: selectedElement?.appearance?.scroll_offset
        ? selectedElement?.appearance?.scroll_offset
        : 0,
      shadow: selectedElement?.appearance?.shadow
        ? selectedElement?.appearance?.shadow
        : false,
      showImg: selectedElement?.appearance?.showImg 
        ? selectedElement?.appearance?.showImg
        : true, 
    },
    elementHtml: selectedElement?.elementHtml,
    designation: selectedElement?.designation,
    donationUrl: selectedElement?.donationUrl,
    elementShowAtUrls: selectedElement?.elementShowAtUrls,
    elementNotShowAtUrls: selectedElement?.elementNotShowAtUrls
  })
  const [qrBase64, setQrBase64] = useState('');
  const limitedSupporters = supporter.slice(0, limiter)
  const limitedCountries = topCountry.slice(0, countryLimiter)
  const limitedDonation = recDonations.slice(0, donationLimiter)
  const limitedAcceleraiser = topAcceleraiser.slice(0, acceleraiserLimiter)

  useEffect(() => {
    if (selectedElement) {
      const selected_element = allElements?.find(
        element => element.type === selectedElement?.elementType
      )
      setSelectElement(selected_element)
    }
  }, [selectedElement])

  useEffect(() => {
    if (!imageUrl) {
      setImageUrl(plant)
    }
  }, [imageUrl])

  const [borderSize, setBorderSize] = useState('0')
  const handleBorderSizeChange = (event, newValue) => {
    setBorderSize(newValue)
  }

  const [buttonSize, setButtonSize] = useState('25')
  const handleButtonSizeChange = (event, newValue) => {
    setButtonSize(newValue)
  }

  const [borderRadius, setBorderRadius] = useState('6')
  const handleBorderRadiusChange = (event, newValue) => {
    setBorderRadius(newValue)
  }

  const [labelColor, setLabelColor] = useState('#ffffff')
  const handleLabelColorChange = color => {
    setLabelColor(color)
  }

  const [shadow, setShadow] = useState(false)
  const handleDonationButtonShadow = (event, newValue) => {
    setShadow(newValue)
  }

  const [stickyBtnEnable, setStickyBtnEnable] = useState(true)
  const handleStickyBtnEnable = (event, newValue) => {
    setStickyBtnEnable(newValue)
  }

  const [btnLabel, setBtnLabel] = useState('Donate')
  const handleDonationButtonLabel = event => {
    setBtnLabel(event.target.value)
  }

  const [btnAlignment, setBtnAlignment] = useState(2)
  const handleBtnAlignment = event => {
    setBtnAlignment(event.target.value)
  }

  const [btnPositionVert, setBtnPositionVert] = useState(1)
  const handleBtnPositionVert = event => {
    setBtnPositionVert(event.target.value)
  }

  const [btnPositionHorz, setBtnPositionHorz] = useState(1)
  const handleBtnPositionHorz = event => {
    setBtnPositionHorz(event.target.value)
  }

  const [browser, setBrowser] = useState(1)
  const handleBrowserView = event => {
    setBrowser(event.target.value)
  }

  const [reminderTxtColor, setReminderTxtColor] = useState('#FFFFFF')
  const handleReminderTxtColor = color => {
    setReminderTxtColor(color)
  }

  const [reminderBkgColor, setReminderBkgColor] = useState('#1C1C1C')
  const handleReminderBkgColor = color => {
    setReminderBkgColor(color)
  }
  const [reminderBorderSize, setReminderBorderSize] = useState(0)
  const handleReminderBorderSize = (event, newValue) => {
    setReminderBorderSize(newValue)
  }

  const [reminderBorderRadius, setReminderBorderRadius] = useState(6)
  const handleReminderBorderRadius = (event, newValue) => {
    setReminderBorderRadius(newValue)
  }

  const [reminderBorderColor, setReminderBorderColor] = useState('#FFFFFF')
  const handleReminderBorderColor = color => {
    setReminderBorderColor(color)
  }

  const [reminderShadow, setReminderShadow] = useState(true)
  const handleReminderShadow = (event, newValue) => {
    setReminderShadow(newValue)
  }

  const [reminderIconColor, setReminderIconColor] = useState('#FF435A')
  const handleReminderIconColor = color => {
    setReminderIconColor(color)
  }

  const [reminderBtnEnable, setReminderBtnEnable] = useState(true)
  const handleReminderBtnEnable = (event, newValue) => {
    setReminderBtnEnable(newValue)
  }

  const [filterElements, setFilterElements] = useState({
    showElement: null
  })

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item }
  })

  const handleClose = () => {
    onClose()
  }
  const selectCategory = (categoryId, categoryType) => {
    setSelectedCategoryId(categoryId)
    setSelectedCategoryType(categoryType)
  }
  const handleAddLevel = () => {
    if (customLevel.length < 5) {
      setCustomLevel(prevLevel => [...prevLevel, ''])
    }
  }
  const handleRemoveLevel = indexToRemove => {
    if (customLevel.length > 1) {
      setCustomLevel(prevLevel => {
        const updatedLevels = [...prevLevel]
        if (indexToRemove >= 0 && indexToRemove < updatedLevels.length) {
          updatedLevels.splice(indexToRemove, 1)
        }
        return updatedLevels
      })
    }
  }

  const handleAddGroupBtn = () => {
    if (groupBtn.length < 9) {
      setGroupBtn(prevGroupBtn => [...prevGroupBtn, { id: groupBtn.length, pay: '' }])
    }
  }
  const handleRemoveGroupBtn = indexToRemove => {
    if (groupBtn.length > 3) {
      setGroupBtn(prevGroupBtn => {
        const updatedGroupBtn = [...prevGroupBtn]
        if (indexToRemove >= 0 && indexToRemove < updatedGroupBtn.length) {
          updatedGroupBtn.splice(indexToRemove, 1)
        }
        return updatedGroupBtn
      })
    }
  }

  const handleAddImpactLevel = () => {
    if (impactLevel.length < 6) {
      setImpactLevel(prevImpactLevel => [...prevImpactLevel, {id: impactLevel.length, price: '', family: ''}])
    }
  }
  const handleRemoveImpactLevel = indexToRemove => {
    if (impactLevel.length > 4) {
      setImpactLevel(prevImpactLevel => {
        const updatedImpactLevel = [...prevImpactLevel]
        if (indexToRemove >= 0 && indexToRemove < updatedImpactLevel.length) {
          updatedImpactLevel.splice(indexToRemove, 1)
        }
        return updatedImpactLevel
      })
    }
  }

  const filteredElements = selectedCategoryType
    ? allElements.filter(element => element.category === selectedCategoryType)
    : allElements

  const handleChange = event => {
    setSelectedValue(event.target.value)
  }
  const customizeMode = () => {
    setMode('Customize')
  }
  const showMode = () => {
    setMode('Show')
  }

  const customizeElement = () => { }

  const [
    UpdateElement,
    { loading: updateElementLoading, error: updateElementError }
  ] = useMutation(UPDATE_ELEMENT, {
    onError: error => {
      console.error('Mutation Error:', error)
      dispatch(errorMsg('update element request was unsuccessful!'))
    },
    onCompleted: data => {
      dispatch(success('update element request was successful!'))
    }
  })

  const [campaignList, setCampaignList] = useState(null)
  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: result => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          campaign => campaign.status === 'publish'
        )
        setCampaignList(campaign_list)
      }
    }
  })

  const [
    CreateElement,
    { loading: createElementLoading, error: createElementError }
  ] = useMutation(CREATE_ELEMENT, {
    onError: error => {
      console.error('Mutation Error:', error)
      dispatch(errorMsg(error?.message))
    },
    onCompleted: data => {
      console.log('Mutation Completed:', data)
      dispatch(success('create element request was successful!'))
    }
  })

  const handleCreateElement = async () => {
    let elementHtml = null
    if (selectElement.id == 0) {
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <DonateButtonElement
          borderRadius={elementObj?.appearance?.borderRadius}
          borderSize={elementObj?.appearance?.borderSize}
          buttonSize={elementObj?.appearance?.size}
          labelColor={elementObj?.appearance?.labelColor}
          shadow={elementObj?.appearance?.shadow}
          btnLabel={elementObj?.appearance?.label}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          iconColor={elementObj?.appearance?.iconColor}
          borderColor={elementObj?.appearance?.borderColor}
          themeMode={themeMode}
        />
      )
    }
    else if (selectElement.id == 2) {
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <StickyButtonElement
          borderRadius={elementObj?.appearance?.borderRadius}
          borderSize={elementObj?.appearance?.borderSize}
          buttonSize={elementObj?.appearance?.size}
          labelColor={elementObj?.appearance?.labelColor}
          shadow={elementObj?.appearance?.shadow}
          btnLabel={elementObj?.appearance?.label}
          btnAlignment={elementObj?.appearance?.btnAlignment}
          btnPositionVert={elementObj?.appearance?.btnPositionVert}
          btnPositionHorz={elementObj?.appearance?.btnPositionHorz}
          stickyBtnEnable={elementObj?.appearance?.enable}
          browser={browser}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          iconColor={elementObj?.appearance?.iconColor}
          borderColor={elementObj?.appearance?.borderColor}
        />
      )
    }
    else if (selectElement.id == 3) {
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <ReminderElement
          reminderTxtColor={elementObj?.appearance?.textColor}
          reminderBkgColor={elementObj?.appearance?.backgroundColor}
          reminderBorderSize={elementObj?.appearance?.borderSize}
          reminderBorderRadius={elementObj?.appearance?.borderRadius}
          reminderBorderColor={elementObj?.appearance?.borderColor}
          reminderShadow={elementObj?.appearance?.shadow}
          reminderIconColor={elementObj?.appearance?.iconColor}
          reminderBtnEnable={elementObj?.appearance?.enable}
        />
      )
    }
    else if(selectElement.id == 4){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <DonateForm 
          title={elementObj?.appearance?.title}
          textColor={elementObj?.appearance?.textColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          iconColor={elementObj?.appearance?.iconColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          activeColor={elementObj?.appearance?.activeColor}
          label={elementObj?.appearance?.label}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
        />
      )
    }
    else if(selectElement.id == 5){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <SimpleForm 
          label={elementObj?.appearance?.label}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          shadow={elementObj?.appearance?.shadow}
        />
      )
    }
    else if (selectElement.id == 6) {
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <ImgCard imageUrl={imageUrl} 
          title={elementObj?.appearance?.title} 
          content={elementObj?.appearance?.content} 
          width={elementObj?.appearance?.width} 
          height={elementObj?.appearance?.height} 
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          shadow={elementObj?.appearance?.shadow}
          textColor={elementObj?.appearance?.textColor}
          btnColor={elementObj?.appearance?.btnColor}
          labelColor={elementObj?.appearance?.labelColor}
          label={elementObj?.appearance?.label}
        />
      )
    }
    else if(selectElement.id == 7){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <GoalMeter collected={elementObj?.amount ? Number(elementObj?.amount) : 100} remaining={elementObj?.behaviour?.goal && elementObj?.amount ? Number(elementObj?.behaviour?.goal - elementObj?.amount) : 150} themeMode={themeMode} 
          labelColor={elementObj?.appearance?.labelColor}
          progressBarColor={elementObj?.appearance?.progressBarColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          goalCurrency={elementObj?.behaviour?.goalCurrency}
        />
      )
    }
    else if(selectElement.id == 8){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <StatCounter 
          amount={elementObj?.amount } 
          goalCurrency={elementObj?.behaviour?.goalCurrency}
        />
      )
    }
    else if(selectElement.id == 9){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <ImpactSlider 
          impactLevel={impactLevel} 
          textColor={elementObj?.appearance?.textColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          label={elementObj?.appearance?.label}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          iconColor={elementObj?.appearance?.iconColor}
        />
      )
    }
    else if(selectElement.id == 10){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <DonationLevel 
          backgroundColor={elementObj?.appearance?.backgroundColor} 
          textColor={elementObj?.appearance?.textColor}
          accentTextColor={elementObj?.appearance?.accentTextColor}
          btnSize={elementObj?.appearance?.btnSize}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnRadius={elementObj?.appearance?.btnRadius}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id == 11){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <GroupBtn 
          groupBtn={groupBtn} 
          title={elementObj?.appearance?.title}
          labelColor={elementObj?.appearance?.labelColor}
          btnSize={elementObj?.appearance?.btnSize}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          shadow={elementObj?.appearance?.shadow}
        />
      )
    }
    else if(selectElement.id == 12){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <MessageBar 
          title={elementObj?.appearance?.title}
          content={elementObj?.appearance?.content}
          textColor={elementObj?.appearance?.textColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          btnPositionVert={elementObj?.appearance?.btnPositionVert}
          show_close_button={elementObj?.appearance?.show_close_button}
          shadow={elementObj?.appearance?.shadow}
          label={elementObj?.appearance?.label}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id == 13){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <TopSupporter limitedSupporters={limitedSupporters} 
          title={elementObj?.appearance?.title}
          textColor={elementObj?.appearance?.textColor}
          labelColor={elementObj?.appearance?.labelColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          enableAmount={elementObj?.appearance?.enable_amount}
          enableLocation={elementObj?.appearance?.enable_location}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id == 14){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <TopCountries limitedCountries={limitedCountries} 
          title={elementObj?.appearance?.title}
          textColor={elementObj?.appearance?.textColor}
          labelColor={elementObj?.appearance?.labelColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id == 15){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <SocialProof
          accentColor={elementObj?.appearance?.accentColor}
          textColor={elementObj?.appearance?.textColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          label={elementObj?.appearance?.label}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          enable_emoji={elementObj?.appearance?.enable_emoji}
          enable_location={elementObj?.appearance?.enable_location}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id === 16){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <img src={qrBase64} alt="QR Code" />
      )
    }
    else if(selectElement.id === 17){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <RecentDonations limitedDonation={limitedDonation}
          enable_amount={elementObj?.appearance?.enable_amount}
          enable_location={elementObj?.appearance?.enable_location}
          enable_comment={elementObj?.appearance?.enable_comment}
          enable_donation_date={elementObj?.appearance?.enable_donation_date}
          show_currency={elementObj?.behaviour?.show_currency}
          currency={elementObj?.behaviour?.currency}
          enable_donate_button={elementObj?.appearance?.enable_donate_button}
          title={elementObj?.appearance?.title}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          textColor={elementObj?.appearance?.textColor}
          accentColor={elementObj?.appearance?.accentColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          show_animation={elementObj?.appearance?.show_animation}
        /> 
      )
    }
    else if(selectElement.id === 18){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <MyMap size={elementObj?.appearance?.height} />
      )
    }
    else if(selectElement.id === 19){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <P2PButton 
          content={elementObj?.appearance?.content}
          label={elementObj?.appearance?.label}
          btnSize={elementObj?.appearance?.btnSize}
          labelColor={elementObj?.appearance?.labelColor}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          shadow={elementObj?.appearance?.shadow}
        />
      )
    }
    else if(selectElement.id === 21){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <TopAcceleraiser limitedAcceleraiser={limitedAcceleraiser} 
          title={elementObj?.appearance?.title}
          textColor={elementObj?.appearance?.textColor}
          labelColor={elementObj?.appearance?.labelColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          shadow={elementObj?.appearance?.shadow}
          themeMode={themeMode}
        />
      )
    }
    else if(selectElement.id == 22){
      elementHtml = ReactDOMServer.renderToStaticMarkup(
        <Popup 
          imageUrl={imageUrl} 
          showImg={elementObj?.appearance?.showImg}
          title={elementObj?.appearance?.title}
          content={elementObj?.appearance?.content}
          labelColor={elementObj?.appearance?.labelColor}
          backgroundColor={elementObj?.appearance?.backgroundColor}
          borderSize={elementObj?.appearance?.borderSize}
          borderRadius={elementObj?.appearance?.borderRadius}
          borderColor={elementObj?.appearance?.borderColor}
          label={elementObj?.appearance?.label}
          btnColor={elementObj?.appearance?.btnColor}
          btnBorderSize={elementObj?.appearance?.btnBorderSize}
          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
          btnBorderColor={elementObj?.appearance?.btnBorderColor}
          shadow={elementObj?.appearance?.shadow}
        />
      )
    }
    if (elementObj) {
      const variables = {
        uuid: elementObj?.uuid,
        campaignUuid: elementObj?.campaignUuid,
        name: elementObj?.name,
        defaultFrequency: elementObj?.defaultFrequency,
        elementType: elementObj?.elementType,
        defaultAmount: elementObj?.defaultAmount,
        amount: elementObj?.amount,
        appearance: elementObj?.appearance,
        elementHtml: elementHtml
      }
      dispatch(setSpinnerLoading(true))
      try {
        if (!selectedElement) {
          const result = await CreateElement({ variables })
        } else {
          const result = await UpdateElement({ variables })
        }
        dispatch(setSpinnerLoading(false))
      } catch (error) {
        console.error(error)
        dispatch(setSpinnerLoading(false))
      }
    }
    handleClose()
    setRefetchElementRows(true)
  }

  const handleImgFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      setImgFile(file)
      const reader = new FileReader()
      reader.onload = e => {
        setImageUrl(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = inputRef => {
    inputRef.current.click()
  }

  const currentDate = new Date()
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  )
  const endDate = currentDate
  const adjustedStartDate = adjustNegativeMonths(startDate)

  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate
  })

  return (
    <>
      {mode === 'Show' ? (
        <Grid
          className={classes.blur}
          container
          sx={{
            padding: { md: '0% 7%', sm: '0% 0%', xs: '5px 0%' },
          }}
        >
          <Grid className={classes.panel}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              transform: {
                md: 'scale(0.65)',
                sm: 'scale(0.85)',
                xs: 'scale(0.95)'
              },
              bottom: { md: '120px', sm: '30px', xs: '0px' },
              background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
              color: themeMode === 'dark' ? '#FFFF' : '#030303',
              boxShadow:
                themeMode === 'dark'
                  ? '0px 0px 0px red'
                  : '0px 8px 5px 0px #00000040',
              '&::before': {
                background:
                  themeMode === 'dark'
                    ? 'linear-gradient(145deg, white, transparent)'
                    : 'linear-gradient(180deg, white, transparent)',
              }
            }}
          >
            <Grid className={classes.spcBtwn100}
              mb={{ md: 3, sm: 2, xs: 2 }}
              sx={{
                flexDirection: { md: 'row', sm: 'row', xs: 'row' },
                alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
              }}
            >
              <Typography className={classes.title} 
                sx={{
                  fontSize: { md: '28px', sm: '25px', xs: '15px' },
                }}
              >
                New Element
              </Typography>
              <Button
                disableElevation
                disableRipple
                className={classes.btnHover}
                onClick={handleClose}
              >
                <img src={themeMode === 'dark' ? cross : crossDark} alt='' />
              </Button>
            </Grid>
            <Grid className={classes.topBottomBorder}
              sx={{
                padding: { md: '20px 0px', sm: '10px 0px' },
              }}
            >
              <ElementSearch />
            </Grid>
            <Grid className={`${classes.topBottomBorder} ${classes.scrollHorz}`}
              mb={3}
              item
              sx={{
                padding: { md: '20px 30px', sm: '20px 15px', xs: '10px 20px' },
              }}
            >
              {category?.map((item, index) => (
                <Button
                  my={{ md: 0, sm: 0, xs: 1 }}
                  key={index}
                  className={classes.category}
                  onClick={() => selectCategory(item.id, item.type)}
                  sx={{
                    padding: { md: '8px 20px', sm: '4px 15px', xs: '4px 0px' },
                    margin: { md: 0, sm: 0, xs: '0px 10px 0px 0px !important' },
                    backgroundColor:selectedCategoryId === index? '#8ED003CC': 'transparent',
                    '&:hover': {
                      backgroundColor:
                        selectedCategoryId === index
                          ? '#8ED003CC'
                          : themeMode === 'dark'
                            ? '#2e3322'
                            : themeMode === 'light'
                              ? '#e3fca7'
                              : ''
                    }
                  }}
                >
                  <Typography className={classes.btnText}
                    sx={{
                      color:
                        themeMode === 'dark'
                          ? '#FFFF'
                          : selectedCategoryId === index
                            ? '#FFF'
                            : '#030303',
                      fontSize: { md: '18px', sm: '15px', xs: '9px' }
                    }}
                  >
                    {item.name}
                  </Typography>
                </Button>
              ))}
            </Grid>

            <Grid className={classes.scrollVert}
              container
              sx={{
                height: { md: '90vh', sm: '60vh', xs: '60vh' },
                scrollbarColor:themeMode === 'dark' ? '#8ED003CC #0F0F0F' : '#8ED003 #E2E2E2'
              }}
            >
              {filteredElements?.map((item, index) => (
                <Button
                  className={classes.filteredButton}
                  onClick={() => {
                    customizeMode()
                    setSelectElement(item)
                    setElementObj(prevObj => ({
                      ...prevObj,
                      elementType: item.type
                    }))
                  }}
                  key={index}
                  sx={{
                    padding: { md: '20px', sm: '20px', xs: '10px 5px' },
                    flexDirection: { md: 'row', sm: 'row', xs: 'column' },
                    '&:hover': {
                      backgroundColor:themeMode === 'dark' ? '#2e3322' : '#e3fca7',
                      '& img': {
                        transform: 'scale(1.1)'
                      }
                    }
                  }}
                >
                  <Grid className={classes.leftCol}
                    item
                    md={10}
                    sm={10}
                    xs={12}
                  >
                    <Grid className={`${classes.leftCol} ${classes.w100}`}>
                      <Typography className={classes.fw600}
                        sx={{
                          textAlign: { md: 'left', sm: 'left', xs: 'center' },
                          fontSize: { md: '28px', sm: '18px', xs: '14px' },
                          color: themeMode === 'dark' ? '#DBFF00' : '#8ED003',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Grid>

                    <Grid className={classes.fullWidth}>
                      <Grid className={`${classes.leftCol} ${classes.w100} ${classes.pr15}`}
                        item md={10} sm={10}
                      >
                        <Typography className={classes.greyText}
                          sx={{
                            fontSize: { md: '18px', sm: '14px', xs: '12px' },
                            textAlign: { md: 'left', sm: 'left', xs: 'center' },
                          }}
                        >
                          {item.detail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={classes.imgBox}
                    item
                    md={2}
                    sm={2}
                    my={{ md: 0, sm: 0, xs: 1 }}
                  >
                    <img
                      src={themeMode === 'dark' ? item.img : item.light}
                      alt=''
                    />
                  </Grid>
                </Button>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : mode === 'Customize' ? (
        <>
          <Grid className={classes.blur2}
            container
            sx={{
              padding: { md: '0% 0%', sm: '0% 0%', xs: '5px 0%' },
              alignItems: { md: 'center', sm: 'center', xs: 'center' },
              height: { md: '100%', sm: '100vh', xs: '100vh' },
              overflowY: {
                md: 'hidden !important',
                sm: 'hidden !important',
                xs: 'auto !important'
              },
              flexDirection: { md: 'row', sm: 'row', xs: 'row' }
            }}
          >
            <Grid className={classes.absCenter}
              container
              sx={{
                flexDirection: { md: 'row', sm: 'row', xs: 'row' },
                height: { md: '100vh', sm: '100vh', xs: '100vh' },
                alignContent: { md: 'center', sm: 'center', xs: 'flex-start' },
                justifyContent: {md: 'center',sm: 'center',xs: 'flex-start'},
                padding: { md: '0% 7%', sm: '0% 0%', xs: '0% 0%' },
                overflowY: { md: 'hidden', sm: 'hidden', xs: 'auto' },
              }}
            >
              <Grid className={classes.panel}
                item
                md={6}
                sm={7}
                xs={12}
                sx={{
                  transform: {
                    md: 'scale(0.7)',
                    sm: 'scale(0.7)',
                    xs: 'scale(0.9)'
                  },
                  padding: {
                    md: '35px 35px 15px',
                    sm: '35px 35px 15px',
                    xs: '07% 5% '
                  },
                  background: themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
                  color: themeMode === 'dark' ? '#FFFF' : '#030303',
                  boxShadow:
                    themeMode === 'dark'
                      ? '0px 0px 0px red'
                      : '0px 8px 5px 0px #00000040',
                  
                  '&::before': {
                    background:
                      themeMode === 'dark'
                        ? 'linear-gradient(145deg, white, transparent)'
                        : 'linear-gradient(180deg, white, transparent)',
                  }
                }}
              >
                <Grid
                  mb={{ md: 3, sm: 2, xs: 2 }}
                  sx={{
                    flexDirection: { md: 'row', sm: 'row', xs: 'row' },
                    alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                    <Typography className={classes.title}
                    sx={{
                      fontSize: { md: '26px', sm: '25px', xs: '15px' },
                    }}
                  >
                    {selectElement.name}
                  </Typography>
                  <Button
                    disableElevation
                    disableRipple
                    className={classes.btnHover}
                    onClick={handleClose}
                  >
                    <img
                      src={themeMode === 'dark' ? cross : crossDark}
                      alt=''
                    />
                  </Button>
                </Grid>
                <Grid>
                  <Typography className={classes.taLeft}>
                    {selectElement.detail}
                  </Typography>
                </Grid>
                <Grid my={3} className={classes.w100}>
                  <FormControl className={classes.formControl}>
                    <Grid
                      className={classes.flexCenter}
                      mr={2}
                      item
                      md={4}
                      sm={4}
                      xs={4}
                    >
                      <Typography className={classes.taRight}
                        sx={{
                          fontSize: { md: '16px', sm: '16px', xs: '14px' },
                        }}
                      >
                        Element Name
                      </Typography>
                    </Grid>
                    <Grid className={classes.flexStart}
                      item
                      md={8}
                      sm={8}
                      xs={8}
                    >
                      <TextField
                
                        mt={1}
                        fullWidth
                        size='small'
                        className={classes.fullInput}
                        placeholder={selectElement.name || "My Element"}
                        variant='outlined'
                        id='outlined-basic'
                        name='ElementName'
                        value={elementObj?.name}
                        onChange={event => {
                          setElementObj(prevObj => ({
                            ...prevObj,
                            name: event.target.value
                          }))
                        }}
                        sx={{
                          width: '90% !important',
                          borderRadius: '10px',
                          backgroundColor:
                            themeMode === 'dark' ? '#0D0D0D' : '#ECECEC',
                          '& .MuiOutlinedInput-input': {
                            color: themeMode === 'dark' ? '#ffff' : '#000',
                            '&::placeholder': {
                              color: themeMode === 'dark' ? '#ffff' : '#000'
                            }
                          },
                          '& .MuiInputLabel-root': {
                            color: themeMode === 'dark' ? '#ffff' : '#000',
                            fontSize: '12px',
                            marginBottom: '15px',
                            transform: 'translate(0px, -40px) scale(1.25)',
                            '&.MuiInputLabel-shrink': {
                              transform: 'translate(0px, -40px) scale(1.25)'
                            }
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor:
                              themeMode === 'dark' ? '#ffff' : '#0F0F0F',
                            borderWidth: '0.5px',
                            borderRadius: '5px'
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#acff00',
                            color: themeMode === 'dark' ? '#ffff' : '#000'
                          },
                          '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'red'
                          },
                          outline: 'none'
                        }}
                      />
                    </Grid>
                  </FormControl>
                  {selectElement.enable ? (
                    <Grid sx={{ position: 'relative', top: '20px' }}>
                      <TickBox
                        text='Element is enabled'
                        value={elementObj?.appearance?.enable}
                        onChange={(event, newValue) => {
                          setElementObj(prevObj => ({
                            ...prevObj,
                            appearance: {
                              ...prevObj.appearance,
                              enable: newValue
                            }
                          }))
                        }}
                      />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid>
                    <Grid
                      mb={3}
                      item
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #929292',
                        padding: {
                          md: '10px 10px',
                          sm: '20px 0px',
                          xs: '10px 0px'
                        },
                        marginTop: '40px',
                        overflowX: 'auto !important'
                      }}
                    >
                      {selectElement?.tabs?.map((item, index) => (
                        <Button
                          disableRipple
                          disableElevation
                          my={{ md: 0, sm: 0, xs: 1 }}
                          key={index}
                          onClick={() => {
                            setSelectTabs(item.id)
                          }}
                          sx={{
                            margin: {
                              md: 0,
                              sm: 0,
                              xs: '0px 0px 0px 0px !important'
                            },
                            borderBottom:
                              selectTabs === index
                                ? '3px solid #689900'
                                : '2px solid transparent',
                            padding: {
                              md: '10px 20px',
                              sm: '4px 15px',
                              xs: '4px 0px'
                            },
                            width: '100%',
                            borderRadius: '0px',
                            '&:hover': {
                              backgroundColor: 'transparent'
                            }
                          }}
                        >
                          <Typography
                            sx={{
                              color: themeMode === 'dark' ? '#FFFF' : '#030303',
                              width: 'fit-content',
                              textWrap: 'nowrap',
                              textTransform: 'capitalize',
                              fontSize: { md: '18px', sm: '16px', xs: '12px' }
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Button>
                      ))}
                    </Grid>
                    <Grid
                      sx={{
                        borderBottom:
                          themeMode === 'dark'
                            ? '1px solid #FFF'
                            : '1px solid #929292',
                        paddingBottom: '15px'
                      }}
                    >
                      {/* Donate Button */}

                      {selectElement.id === 0 && selectTabs === 0 ? (
                        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Grid my={2}>
                            <FormControl
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%'
                              }}
                            >
                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={4}
                                mr={2}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: 'fit-content'
                                }}
                              >
                                <Typography
                                  sx={{
                                    textWrap: 'nowrap',
                                    textAlign: 'right',
                                    width: '100%',
                                    fontSize: {
                                      md: '16px',
                                      sm: '14px',
                                      xs: '12px'
                                    }
                                  }}
                                >
                                  Open Campaign
                                </Typography>
                              </Grid>
                              <Grid item md={8} sm={8} xs={8}>
                                <TextField
                                  select
                                  size='small'
                                  variant='outlined'
                                  placeholder='Select a Campaign'
                                  className={classes.select}
                                  sx={{
                                    backgroundColor:
                                      themeMode === 'dark'
                                        ? '#0D0D0D'
                                        : '#ECECEC',
                                    '& .MuiOutlinedInput-input': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000',
                                      '&::placeholder': {
                                        color:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#000'
                                      }
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor:
                                        themeMode === 'dark'
                                          ? '#ffff'
                                          : '#0F0F0F'
                                    }
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          maxHeight: 150,
                                          Width: '70%',
                                          scrollbarWidth: 'thin',
                                          scrollbarColor:
                                            themeMode === 'dark'
                                              ? '#8ED003CC #0F0F0F'
                                              : '#8ED003 #E2E2E2',
                                          '&:hover': {
                                            background: '#659500',
                                            color: '#FFF'
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={elementObj?.campaignUuid || ''}
                                  onChange={event =>
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      campaignUuid: event.target.value
                                    }))
                                  }
                                >
                                  {campaignList?.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item.uuid}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        {item.title}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                            </FormControl>
                          </Grid>

                          <Grid my={2}>
                            <FormControl
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%'
                              }}
                            >
                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={4}
                                mr={2}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Typography
                                  sx={{
                                    textWrap: 'nowrap',
                                    textAlign: 'right',
                                    width: '100%',
                                    fontSize: {
                                      md: '16px',
                                      sm: '14px',
                                      xs: '12px'
                                    }
                                  }}
                                >
                                  Frequency
                                </Typography>
                              </Grid>
                              <Grid item md={8} sm={8} xs={8}>
                                <TextField
                                  select
                                  size='small'
                                  variant='outlined'
                                  default='match_checkout_setting'
                                  className={classes.select}
                                  sx={{
                                    backgroundColor:
                                      themeMode === 'dark'
                                        ? '#0D0D0D'
                                        : '#ECECEC',
                                    '& .MuiOutlinedInput-input': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000',
                                      '&::placeholder': {
                                        color:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#000'
                                      }
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor:
                                        themeMode === 'dark'
                                          ? '#ffff'
                                          : '#0F0F0F'
                                    }
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          maxHeight: 150,
                                          Width: '70%',
                                          scrollbarWidth: 'thin',
                                          scrollbarColor:
                                            themeMode === 'dark'
                                              ? '#8ED003CC #0F0F0F'
                                              : '#8ED003 #E2E2E2',
                                          '&:hover': {
                                            background: '#659500',
                                            color: '#FFF'
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  value={elementObj?.defaultFrequency || ''}
                                  onChange={event =>
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      defaultFrequency: event.target.value
                                    }))
                                  }
                                >
                                  <MenuItem
                                    value={'match_checkout_setting'}
                                    className={classes.menuItem}
                                    sx={{
                                      color:
                                        themeMode === 'dark' ? '#FFF' : '#111'
                                    }}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Match Checkout Settings
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem
                                    value={'default_one_time'}
                                    className={classes.menuItem}
                                    sx={{
                                      color:
                                        themeMode === 'dark' ? '#FFF' : '#111',
                                      '&:hover': {
                                        background: '#659500',
                                        color: '#FFF'
                                      }
                                    }}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Default One Time
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem
                                    value={'default_monthly'}
                                    className={classes.menuItem}
                                    sx={{
                                      color:
                                        themeMode === 'dark' ? '#FFF' : '#111',
                                      '&:hover': {
                                        background: '#659500',
                                        color: '#FFF'
                                      }
                                    }}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Default Monthly
                                    </Typography>
                                  </MenuItem>
                                </TextField>
                              </Grid>
                            </FormControl>
                          </Grid>

                          <Grid my={2}>
                            <FormControl
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%'
                              }}
                            >
                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={4}
                                mr={2}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Typography
                                  sx={{
                                    textWrap: 'nowrap',
                                    textAlign: 'right',
                                    width: '100%',
                                    fontSize: {
                                      md: '16px',
                                      sm: '14px',
                                      xs: '12px'
                                    }
                                  }}
                                >
                                  Designation
                                </Typography>
                              </Grid>
                              <Grid item md={8} sm={8} xs={8}>
                                <TextField
                                  select
                                  size='small'
                                  variant='outlined'
                                  default='MatchCheckoutSettings'
                                  className={classes.select}
                                  sx={{
                                    backgroundColor:
                                      themeMode === 'dark'
                                        ? '#0D0D0D'
                                        : '#ECECEC',
                                    '& .MuiOutlinedInput-input': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000',
                                      '&::placeholder': {
                                        color:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#000'
                                      }
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor:
                                        themeMode === 'dark'
                                          ? '#ffff'
                                          : '#0F0F0F'
                                    }
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          maxHeight: 150,
                                          Width: '70%',
                                          scrollbarWidth: 'thin',
                                          scrollbarColor:
                                            themeMode === 'dark'
                                              ? '#8ED003CC #0F0F0F'
                                              : '#8ED003 #E2E2E2',
                                          '&:hover': {
                                            background: '#659500',
                                            color: '#FFF'
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  value={elementObj?.designation || ''}
                                  onChange={event =>
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      designation: event.target.value
                                    }))
                                  }
                                >
                                  <MenuItem
                                    value={'match_checkout_setting'}
                                    className={classes.menuItem}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Match Checkout Settings
                                    </Typography>
                                  </MenuItem>
                                </TextField>
                              </Grid>
                            </FormControl>
                          </Grid>

                          <Grid my={2}>
                            <FormControl
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                width: '100%'
                              }}
                            >
                              <Grid
                                item
                                md={4}
                                sm={4}
                                xs={4}
                                mr={2}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Typography
                                  sx={{
                                    textWrap: 'nowrap',
                                    textAlign: 'right',
                                    width: '100%',
                                    fontSize: {
                                      md: '16px',
                                      sm: '14px',
                                      xs: '12px'
                                    }
                                  }}
                                >
                                  Default Amount
                                </Typography>
                              </Grid>
                              <Grid item md={8} sm={8} xs={8}>
                                <TextField
                                  select
                                  size='small'
                                  variant='outlined'
                                  default='MatchCheckoutSettings'
                                  className={classes.select}
                                  sx={{
                                    backgroundColor:
                                      themeMode === 'dark'
                                        ? '#0D0D0D'
                                        : '#ECECEC',
                                    '& .MuiOutlinedInput-input': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000',
                                      '&::placeholder': {
                                        color:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#000'
                                      }
                                    },
                                    '& .MuiInputLabel-root': {
                                      color:
                                        themeMode === 'dark' ? '#ffff' : '#000'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor:
                                        themeMode === 'dark'
                                          ? '#ffff'
                                          : '#0F0F0F'
                                    }
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          maxHeight: 150,
                                          Width: '70%',
                                          scrollbarWidth: 'thin',
                                          scrollbarColor:
                                            themeMode === 'dark'
                                              ? '#8ED003CC #0F0F0F'
                                              : '#8ED003 #E2E2E2',
                                          '&:hover': {
                                            background: '#659500',
                                            color: '#FFF'
                                          }
                                        }
                                      }
                                    }
                                  }}
                                  value={elementObj?.defaultAmount || ''}
                                  onChange={event =>
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      defaultAmount: event.target.value
                                    }))
                                  }
                                >
                                  <MenuItem
                                    value={'match_checkout_setting'}
                                    className={classes.menuItem}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Match Checkout Settings
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem
                                    value={'customize'}
                                    className={classes.menuItem}
                                  >
                                    <Typography sx={{ fontSize: '14px' }}>
                                      Customize
                                    </Typography>
                                  </MenuItem>
                                </TextField>
                              </Grid>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ) : selectElement.id === 0 && selectTabs === 1 ? (
                        <Grid
                          sx={{
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            position: 'relative',
                            padding: '13px',
                            scrollbarWidth: 'thin',
                            scrollbarColor:
                              themeMode === 'dark'
                                ? '#8ED003CC #0F0F0F'
                                : '#8ED003 #E2E2E2'
                          }}
                        >
                          <FormControl
                            sx={{
                              display: 'flex',
                              flexDirection: {
                                md: 'row',
                                sm: 'row',
                                xs: 'column'
                              },
                              justifyContent: 'center',
                              width: '100%'
                            }}
                          >
                            <Grid
                              item
                              md={4}
                              sm={4}
                              xs={12}
                              mr={2}
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Typography
                                my={{ md: 0, sm: 0, xs: 1 }}
                                sx={{
                                  textWrap: 'nowrap',
                                  textAlign: {
                                    md: 'right',
                                    sm: 'right',
                                    xs: 'left'
                                  },
                                  width: '100%'
                                }}
                              >
                                Label
                              </Typography>
                            </Grid>
                            <Grid item md={8} sm={8} xs={12}>
                              <TextField
                                size='small'
                                variant='outlined'
                                default='Donate'
                                value={elementObj?.appearance?.label}
                                className={classes.select}
                                onChange={event => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      label: event.target.value
                                    }
                                  }))
                                }}
                              ></TextField>
                            </Grid>
                          </FormControl>

                          <SlidingElement
                            label='Button Size'
                            value={elementObj?.appearance?.size}
                            defaultValue={25}
                            max={80}
                            min={12}
                            onChange={(event, newValue) => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  size: newValue
                                }
                              }))
                            }}
                          />
                          <ColorBox
                            label='Label Color'
                            value={elementObj?.appearance?.labelColor}
                            onChange={color => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  labelColor: color
                                }
                              }))
                            }}
                          />
                          <ColorBox
                            label='Button Color'
                            value={
                              elementObj?.appearance?.backgroundColor ||
                              '#7CB602'
                            }
                            onChange={color => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  backgroundColor: color
                                }
                              }))
                            }}
                          />

                          <ColorBox
                            label='Icon Color'
                            value={elementObj?.appearance?.iconColor || '#FFFF'}
                            onChange={color => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  iconColor: color
                                }
                              }))
                            }}
                          />

                          <SlidingElement
                            label='Border Size'
                            value={elementObj?.appearance?.borderSize}
                            defaultValue={0}
                            max={4}
                            min={0}
                            onChange={(event, newValue) => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  borderSize: newValue
                                }
                              }))
                            }}
                          />

                          <SlidingElement
                            label='Border Radius'
                            value={elementObj?.appearance?.borderRadius}
                            defaultValue={6}
                            max={44}
                            min={0}
                            onChange={(event, newValue) => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  borderRadius: newValue
                                }
                              }))
                            }}
                          />

                          <ColorBox
                            label='Border Color'
                            value={
                              elementObj?.appearance?.borderColor || '#FFFF'
                            }
                            onChange={color => {
                              setElementObj(prevObj => ({
                                ...prevObj,
                                appearance: {
                                  ...prevObj.appearance,
                                  borderColor: color
                                }
                              }))
                            }}
                          />

                          <Grid>
                            <TickBox
                              text='Show Shadow'
                              value={elementObj?.appearance?.shadow}
                              onChange={(event, newValue) => {
                                setElementObj(prevObj => ({
                                  ...prevObj,
                                  appearance: {
                                    ...prevObj.appearance,
                                    shadow: newValue
                                  }
                                }))
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : selectElement.id === 0 && selectTabs === 2 ? (
                        <Grid my={2}>
                          <Typography sx={{ margin: '35px 0px' }}>
                            The selected campaign does not have custom fields.
                          </Typography>
                        </Grid>
                      ) : // Donate Link

                        selectElement.id === 1 && selectTabs === 0 ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              overflowY: 'auto',
                              maxHeight: '50vh',
                              position: 'relative',
                              padding: '13px',
                              scrollbarWidth: 'thin',
                              scrollbarColor:
                                themeMode === 'dark'
                                  ? '#8ED003CC #0F0F0F'
                                  : '#8ED003 #E2E2E2'
                            }}
                          >
                            <Grid my={2}>
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 'fit-content'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Open Campaign
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    placeholder='Select a Campaign'
                                    className={classes.select}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={elementObj?.campaignUuid || ''}
                                    onChange={event =>
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        campaignUuid: event.target.value,
                                        donationUrl: `https://www.accceleraise.com/campaign-donation/0/${event.target.value}`,
                                      }))
                                    }
                                  >
                                    {campaignList?.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.uuid}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          {item.title}
                                        </Typography>
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </FormControl>
                            </Grid>

                            <Grid my={2}>
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Frequency
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    default='MatchCheckoutSettings'
                                    className={classes.select}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                    value={elementObj?.defaultFrequency || ''}
                                    onChange={event =>
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        defaultFrequency: event.target.value
                                      }))
                                    }
                                  >
                                    <MenuItem
                                      value={'match_checkout_setting'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Match Checkout Settings
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={'default_one_time'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Default One Time
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={'default_monthly'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Default Monthly
                                      </Typography>
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              </FormControl>
                            </Grid>

                            <Grid my={2}>
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Designation
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    default='match_checkout_setting'
                                    value={elementObj?.designation}
                                    onChange={event => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        designation: event.target.value
                                      }))
                                    }}
                                    className={classes.select}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    <MenuItem
                                      value={'match_checkout_setting'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Match Checkout Settings
                                      </Typography>
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              </FormControl>
                            </Grid>

                            <Grid my={2}>
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Default Amount
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    default='MatchCheckoutSettings'
                                    className={classes.select}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                    value={elementObj?.defaultAmount || ''}
                                    onChange={event =>
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        defaultAmount: event.target.value
                                      }))
                                    }
                                  >
                                    <MenuItem
                                      value={'match_checkout_setting'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Match Checkout Settings
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={'customize'}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Customize
                                      </Typography>
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              </FormControl>
                            </Grid>

                            <Grid my={2}>
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    URL
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    size='small'
                                    variant='outlined'
                                    default='Select a Campaign'
                                    className={classes.select}
                                    value={elementObj?.donationUrl || `https://www.accceleraise.com/campaign-donation/0/${elementObj?.campaignUuid}`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark'
                                            ? '#ffff'
                                            : '#0F0F0F'
                                      }
                                    }}
                                  ></TextField>
                                </Grid>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ) : selectElement.id === 1 && selectTabs === 1 ? (
                          <Grid my={2}>
                            <Typography sx={{ margin: '35px 0px' }}>
                              The selected campaign does not have custom fields.
                            </Typography>
                          </Grid>
                        ) : // Sticky Button
                          selectElement.id === 2 && selectTabs === 0 ? (
                            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: 'fit-content'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Open Campaign
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      placeholder='Select a Campaign'
                                      className={classes.select}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                      value={elementObj?.campaignUuid || ''}
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          campaignUuid: event.target.value
                                        }))
                                      }
                                    >
                                      {campaignList?.map((item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.uuid}
                                          className={classes.menuItem}
                                          sx={{
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography sx={{ fontSize: '14px' }}>
                                            {item.title}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </FormControl>
                              </Grid>

                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Frequency
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      default='match_checkout_setting'
                                      className={classes.select}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                      value={elementObj?.defaultFrequency || ''}
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          defaultFrequency: event.target.value
                                        }))
                                      }
                                    >
                                      <MenuItem
                                        value={'match_checkout_setting'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Match Checkout Settings
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={'default_one_time'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Default One Time
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={'default_monthly'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Default Monthly
                                        </Typography>
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                </FormControl>
                              </Grid>

                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Designation
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      default='match_checkout_setting'
                                      value={elementObj?.designation}
                                      onChange={event => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          designation: event.target.value
                                        }))
                                      }}
                                      className={classes.select}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <MenuItem
                                        value={'match_checkout_setting'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Match Checkout Settings
                                        </Typography>
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                </FormControl>
                              </Grid>

                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Default Amount
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      default='match_checkout_setting'
                                      className={classes.select}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                      value={elementObj?.defaultAmount || ''}
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          defaultAmount: event.target.value
                                        }))
                                      }
                                    >
                                      <MenuItem
                                        value={'match_checkout_setting'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Match Checkout Settings
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={'customize'}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Customize
                                        </Typography>
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                </FormControl>
                              </Grid>
                            </Grid>
                          ) : selectElement.id === 2 && selectTabs === 1 ? (
                            <Grid
                              sx={{
                                overflowY: 'auto',
                                maxHeight: '45vh',
                                position: 'relative',
                                padding: '13px',
                                scrollbarWidth: 'thin',
                                scrollbarColor:
                                  themeMode === 'dark'
                                    ? '#8ED003CC #0F0F0F'
                                    : '#8ED003 #E2E2E2'
                              }}
                            >
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 'fit-content'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Alignment
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    className={classes.select}
                                    value={elementObj?.appearance?.btnAlignment}
                                    onChange={e => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnAlignment: e.target.value
                                        }
                                      }))
                                    }}
                                    sx={{
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    <MenuItem
                                      value={1}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Top
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={2}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Left
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={3}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Right
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={4}
                                      className={classes.menuItem}
                                      sx={{
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography sx={{ fontSize: '14px' }}>
                                        Bottom
                                      </Typography>
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              </FormControl>

                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%',
                                  my: 2
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  mr={2}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 'fit-content'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Position
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={8}>
                                  {elementObj?.appearance?.btnAlignment === 1 ||
                                    elementObj?.appearance?.btnAlignment === 4 ? (
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      defaultValue={2}
                                      className={classes.select}
                                      value={
                                        elementObj?.appearance?.btnPositionHorz
                                      }
                                      onChange={e => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            btnPositionHorz: e.target.value
                                          }
                                        }))
                                      }}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <MenuItem
                                        value={1}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Left
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={2}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Center
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={3}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Right
                                        </Typography>
                                      </MenuItem>
                                    </TextField>
                                  ) : elementObj?.appearance?.btnAlignment === 2 ||
                                    elementObj?.appearance?.btnAlignment === 3 ? (
                                    <TextField
                                      select
                                      size='small'
                                      variant='outlined'
                                      defaultValue={2}
                                      className={classes.select}
                                      value={
                                        elementObj?.appearance?.btnPositionVert
                                      }
                                      onChange={e => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            btnPositionVert: e.target.value
                                          }
                                        }))
                                      }}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <MenuItem
                                        value={1}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Top
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={2}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Center
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem
                                        value={3}
                                        className={classes.menuItem}
                                        sx={{
                                          color:
                                            themeMode === 'dark' ? '#FFF' : '#111'
                                        }}
                                      >
                                        <Typography sx={{ fontSize: '14px' }}>
                                          Bottom
                                        </Typography>
                                      </MenuItem>
                                    </TextField>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              </FormControl>

                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: 'fit-content'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Scroll offset
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    md={8}
                                    sm={8}
                                    xs={8}
                                    sx={{ display: 'flex', justifyContent: 'left' }}
                                  >
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      defaultValue={0}
                                      className={classes.fullInput}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      value={
                                        elementObj?.appearance?.scroll_offset || 0
                                      }
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            scroll_offset: event.target.value
                                          }
                                        }))
                                      }
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <Typography
                                              variant='body2'
                                              color='#8a8a8a'
                                            >
                                              px
                                            </Typography>
                                          </InputAdornment>
                                        )
                                      }}
                                    ></TextField>
                                    <Grid
                                      mx={4}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Tooltip
                                        title='This controls how far someone has to scroll before this sticky button appears'
                                        arrow
                                      >
                                        <img src={question} alt='' />
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>

                              <Grid my={2}>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    mr={2}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: 'fit-content'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textWrap: 'nowrap',
                                        textAlign: 'right',
                                        width: '100%',
                                        fontSize: {
                                          md: '16px',
                                          sm: '14px',
                                          xs: '12px'
                                        }
                                      }}
                                    >
                                      Label
                                    </Typography>
                                  </Grid>
                                  <Grid item md={8} sm={8} xs={8}>
                                    <TextField
                                      size='small'
                                      variant='outlined'
                                      default='Donate'
                                      value={elementObj?.appearance?.label}
                                      className={classes.select}
                                      onChange={e => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            label: e.target.value
                                          }
                                        }))
                                      }}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000'
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          borderColor:
                                            themeMode === 'dark'
                                              ? '#ffff'
                                              : '#0F0F0F'
                                        }
                                      }}
                                      SelectProps={{
                                        MenuProps: {
                                          PaperProps: {
                                            style: {
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              maxHeight: 150,
                                              Width: '70%',
                                              scrollbarWidth: 'thin',
                                              scrollbarColor:
                                                themeMode === 'dark'
                                                  ? '#8ED003CC #0F0F0F'
                                                  : '#8ED003 #E2E2E2',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    ></TextField>
                                  </Grid>
                                </FormControl>
                              </Grid>

                              <SlidingElement
                                label='Button Size'
                                value={elementObj?.appearance?.size}
                                defaultValue={25}
                                max={80}
                                min={12}
                                onChange={(event, newValue) => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      size: newValue
                                    }
                                  }))
                                }}
                              />

                              <ColorBox
                                label='Label Color'
                                value={elementObj?.appearance?.labelColor}
                                onChange={color => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      labelColor: color
                                    }
                                  }))
                                }}
                              />

                              <ColorBox
                                label='Button Color'
                                value={
                                  elementObj?.appearance?.backgroundColor ||
                                  '#7CB602'
                                }
                                onChange={color => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      backgroundColor: color
                                    }
                                  }))
                                }}
                              />

                              <ColorBox
                                label='Icon Color'
                                value={elementObj?.appearance?.iconColor || '#FFFF'}
                                onChange={color => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      iconColor: color
                                    }
                                  }))
                                }}
                              />

                              <SlidingElement
                                label='Border Size'
                                value={elementObj?.appearance?.borderSize}
                                defaultValue={0}
                                max={4}
                                min={0}
                                onChange={(event, newValue) => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      borderSize: newValue
                                    }
                                  }))
                                }}
                              />

                              <SlidingElement
                                label='Border Radius'
                                value={elementObj?.appearance?.borderRadius}
                                defaultValue={6}
                                max={32}
                                min={0}
                                onChange={(event, newValue) => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      borderRadius: newValue
                                    }
                                  }))
                                }}
                              />

                              <ColorBox
                                label='Border Color'
                                value={
                                  elementObj?.appearance?.borderColor || '#FFFF'
                                }
                                onChange={color => {
                                  setElementObj(prevObj => ({
                                    ...prevObj,
                                    appearance: {
                                      ...prevObj.appearance,
                                      borderColor: color
                                    }
                                  }))
                                }}
                              />

                              <Grid>
                                <TickBox
                                  text='Show Shadow'
                                  value={elementObj?.appearance?.shadow}
                                  onChange={(event, newValue) => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        shadow: newValue
                                      }
                                    }))
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : selectElement.id === 2 && selectTabs === 2 ? (
                            <Grid
                              sx={{
                                overflowY: 'auto',
                                maxHeight: '45vh',
                                position: 'relative',
                                padding: '5px',
                                scrollbarWidth: 'thin',
                                scrollbarColor:
                                  themeMode === 'dark'
                                    ? '#8ED003CC #0F0F0F'
                                    : '#8ED003 #E2E2E2'
                              }}
                            >
                              <FormControl
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  width: '100%'
                                }}
                              >
                                <Grid
                                  item
                                  md={4}
                                  sm={3}
                                  xs={3}
                                  mr={2}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Typography
                                    sx={{
                                      textWrap: 'nowrap',
                                      textAlign: 'right',
                                      width: '100%',
                                      fontSize: {
                                        md: '16px',
                                        sm: '14px',
                                        xs: '12px'
                                      }
                                    }}
                                  >
                                    Show on
                                  </Typography>
                                </Grid>
                                <Grid item md={8} sm={9} xs={9}>
                                  <TextField
                                    select
                                    size='small'
                                    variant='outlined'
                                    className={classes.select}
                                    value={browser}
                                    sx={{
                                      width: {
                                        md: '85% !important',
                                        sm: '100%',
                                        xs: '100%'
                                      },
                                      backgroundColor:
                                        themeMode === 'dark'
                                          ? '#0D0D0D'
                                          : '#ECECEC',
                                      '& .MuiOutlinedInput-input': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000',
                                        '&::placeholder': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        }
                                      },
                                      '& .MuiInputLabel-root': {
                                        color:
                                          themeMode === 'dark' ? '#ffff' : '#000'
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor:
                                          themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                      }
                                    }}
                                    SelectProps={{
                                      MenuProps: {
                                        PaperProps: {
                                          style: {
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            maxHeight: 150,
                                            Width: '70%',
                                            scrollbarWidth: 'thin',
                                            scrollbarColor:
                                              themeMode === 'dark'
                                                ? '#8ED003CC #0F0F0F'
                                                : '#8ED003 #E2E2E2',
                                            '&:hover': {
                                              background: '#659500',
                                              color: '#FFF'
                                            }
                                          }
                                        }
                                      }
                                    }}
                                    onChange={handleBrowserView}
                                  >
                                    <MenuItem
                                      value={1}
                                      className={classes.menuItem}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            md: '14px',
                                            sm: '12px',
                                            xs: '10px'
                                          }
                                        }}
                                      >
                                        Desktop and mobile browsers
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={2}
                                      className={classes.menuItem}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            md: '14px',
                                            sm: '12px',
                                            xs: '10px'
                                          }
                                        }}
                                      >
                                        Desktop browsers only
                                      </Typography>
                                    </MenuItem>
                                    <MenuItem
                                      value={3}
                                      className={classes.menuItem}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color:
                                          themeMode === 'dark' ? '#FFF' : '#111'
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: {
                                            md: '14px',
                                            sm: '12px',
                                            xs: '10px'
                                          }
                                        }}
                                      >
                                        Mobile browsers only
                                      </Typography>
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              </FormControl>
                              <Grid
                                my={4}
                                py={3}
                                sx={{
                                  borderTop:
                                    themeMode === 'dark'
                                      ? '1px solid #FFFF'
                                      : '1px solid #929292'
                                }}
                              >
                                <Typography sx={{ textAlign: 'left' }}>
                                  By default, element is displayed on every page of
                                  your website. URL control allows you to limit the
                                  pages on which element appears.
                                </Typography>
                                <Grid my={3}>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Grid
                                      mr={2}
                                      my={2}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          fontSize: '16px'
                                        }}
                                      >
                                        Only show element at these URLs
                                      </Typography>
                                      <Grid
                                        mx={4}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Tooltip
                                          title='Enter each URL on a new line. Specify up to 20 URLs where this element will be shown.'
                                          arrow
                                        >
                                          <img src={question} alt='' />
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                    <TextField
                                      //required
                                      multiline
                                      minRows={3}
                                      mt={1}
                                      fullWidth
                                      size='small'
                                      className={classes.fullInput}
                                      placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                      variant='outlined'
                                      id='outlined-basic'
                                      name='CampaignName'
                                      value={elementObj?.name}
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          name: event.target.value
                                        }))
                                      }
                                      sx={{
                                        width: '90% !important',
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000',
                                            fontSize: {
                                              md: '16px',
                                              sm: '16px',
                                              xs: '11px'
                                            }
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid my={3}>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Grid
                                      mr={2}
                                      my={2}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          fontSize: '16px'
                                        }}
                                      >
                                        Never show element at these URLs
                                      </Typography>
                                      <Grid
                                        mx={4}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Tooltip
                                          title='Enter each URL on a new line. Specify up to 20 URLs where this element will be hidden.'
                                          arrow
                                        >
                                          <img src={question} alt='' />
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                    <TextField
                                      //required
                                      multiline
                                      placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                      minRows={3}
                                      mt={1}
                                      fullWidth
                                      size='small'
                                      className={classes.fullInput}
                                      variant='outlined'
                                      id='outlined-basic'
                                      name='CampaignName'
                                      value={elementObj?.name}
                                      onChange={event =>
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          name: event.target.value
                                        }))
                                      }
                                      sx={{
                                        width: '90% !important',
                                        backgroundColor:
                                          themeMode === 'dark'
                                            ? '#0D0D0D'
                                            : '#ECECEC',
                                        '& .MuiOutlinedInput-input': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000',
                                          '&::placeholder': {
                                            color:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#000',
                                            fontSize: {
                                              md: '16px',
                                              sm: '16px',
                                              xs: '11px'
                                            }
                                          }
                                        },
                                        '& .MuiInputLabel-root': {
                                          color:
                                            themeMode === 'dark' ? '#ffff' : '#000'
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : selectElement.id === 2 && selectTabs === 3 ? (
                            <Grid my={2}>
                              <Typography my={1} sx={{ margin: '35px 0px' }}>
                                The selected campaign does not have custom fields.
                              </Typography>
                            </Grid>
                          ) : // Reminder
                            selectElement.id === 3 && selectTabs === 0 ? (
                              <Grid
                                sx={{
                                  overflowY: 'auto',
                                  maxHeight: '45vh',
                                  position: 'relative',
                                  padding: '13px',
                                  scrollbarWidth: 'thin',
                                  scrollbarColor:
                                    themeMode === 'dark'
                                      ? '#8ED003CC #0F0F0F'
                                      : '#8ED003 #E2E2E2'
                                }}
                              >
                                <ColorBox
                                  label='Text Color'
                                  value={elementObj?.appearance?.textColor || '#FFFF'}
                                  onChange={color => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        textColor: color
                                      }
                                    }))
                                  }}
                                />

                                <ColorBox
                                  label='Background Color'
                                  value={
                                    elementObj?.appearance?.backgroundColor || '#0000'
                                  }
                                  onChange={color => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        backgroundColor: color
                                      }
                                    }))
                                  }}
                                />

                                <SlidingElement
                                  label='Border Size'
                                  value={elementObj?.appearance?.borderSize}
                                  defaultValue={0}
                                  max={4}
                                  min={0}
                                  onChange={(event, newValue) => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        borderSize: newValue
                                      }
                                    }))
                                  }}
                                />

                                <SlidingElement
                                  label='Border Radius'
                                  value={elementObj?.appearance?.borderRadius}
                                  defaultValue={6}
                                  max={44}
                                  min={0}
                                  onChange={(event, newValue) => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        borderRadius: newValue
                                      }
                                    }))
                                  }}
                                />

                                <ColorBox
                                  label='Border Color'
                                  value={elementObj?.appearance?.borderColor}
                                  onChange={color => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        borderColor: color
                                      }
                                    }))
                                  }}
                                />
                                <ColorBox
                                  label='Icon'
                                  value={elementObj?.appearance?.iconColor}
                                  onChange={color => {
                                    setElementObj(prevObj => ({
                                      ...prevObj,
                                      appearance: {
                                        ...prevObj.appearance,
                                        iconColor: color
                                      }
                                    }))
                                  }}
                                />

                                <Grid>
                                  <TickBox
                                    text='Show Shadow'
                                    value={elementObj?.appearance?.shadow}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          shadow: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ) : selectElement.id === 3 && selectTabs === 1 ? (
                              <Grid
                                mt={2}
                                sx={{
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                  maxHeight: '40vh',
                                  position: 'relative',
                                  padding: '13px',
                                  scrollbarWidth: 'thin',
                                  scrollbarColor:
                                    themeMode === 'dark'
                                      ? '#8ED003CC #0F0F0F'
                                      : '#8ED003 #E2E2E2'
                                }}
                              >
                                <Typography sx={{ textAlign: 'left' }}>
                                  By default, element is displayed on every page of
                                  your website. URL control allows you to limit the
                                  pages on which element appears.
                                </Typography>
                                <Grid my={3}>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Grid
                                      mr={2}
                                      my={1}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          fontSize: {
                                            md: '16px',
                                            sm: '14px',
                                            xs: '13px'
                                          }
                                        }}
                                      >
                                        Only show element at these URLs
                                      </Typography>
                                      <Grid
                                        mx={{ md: 4, sm: 4, xs: 2 }}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Tooltip
                                          title='Enter each URL on a new line. Specify up to 20 URLs where this element will be shown.'
                                          arrow
                                        >
                                          <img src={question} alt='' />
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                    <TextareaAutosize
                                      //required
                                      multiline
                                      minRows={3}
                                      mt={1}
                                      fullWidth
                                      size='small'
                                      className={classes.fullInput}
                                      variant='outlined'
                                      id='outlined-basic'
                                      placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                      name='CampaignName'
                                      value={elementObj?.elementShowAtUrls || ''}
                                      onChange={event => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          elementShowAtUrls: event.target.value
                                        }));
                                      }}
                                      sx={{
                                        width: '90% !important',
                                        '& .MuiOutlinedInput-input': {
                                          '&::placeholder': {
                                            fontSize: {
                                              md: '16px',
                                              sm: '16px',
                                              xs: '11px'
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid my={3}>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <Grid
                                      mr={2}
                                      my={1}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          fontSize: {
                                            md: '16px',
                                            sm: '14px',
                                            xs: '13px'
                                          }
                                        }}
                                      >
                                        Never show element at these URLs
                                      </Typography>
                                      <Grid
                                        mx={{ md: 4, sm: 4, xs: 2 }}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Tooltip
                                          title='Enter each URL on a new line. Specify up to 20 URLs where this element will be hidden.'
                                          arrow
                                        >
                                          <img src={question} alt='' />
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                    <TextareaAutosize
                                      //required
                                      multiline
                                      minRows={3}
                                      mt={1}
                                      fullWidth
                                      size='small'
                                      className={classes.fullInput}
                                      placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                      variant='outlined'
                                      id='outlined-basic'
                                      name='CampaignName'
                                      value={elementObj?.elementNotShowAtUrls || ''}
                                      onChange={event => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          elementNotShowAtUrls: event.target.value
                                        }));
                                      }}
                                      sx={{
                                        width: '90% !important',
                                        '& .MuiOutlinedInput-input': {
                                          '&::placeholder': {
                                            fontSize: {
                                              md: '16px',
                                              sm: '16px',
                                              xs: '11px'
                                            }
                                          }
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            ) : //Form
                              selectElement.id === 4 && selectTabs === 0 ? (
                                // Behaviour
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_designation: event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide_designation'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide designation
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <TickBox
                                      text='Show suggested amounts'
                                      value={elementObj?.behaviour?.enable_suggested_amounts || ''}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          behaviour: {
                                            ...prevObj.behaviour,
                                            enable_suggested_amounts: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show tribute
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_tribute || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_tribute: event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide_tribute'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide tribute
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show comment
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_comment || ''}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_comment: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide_comment'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide Comment
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 4 && selectTabs === 1 ? (
                                // Form
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Your most generous donation'
                                        className={classes.select}
                                        value={elementObj?.appearance?.title}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor || '#FFFF'}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor || '#FFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Icon Color'
                                    value={elementObj?.appearance?.iconColor || '#FFFF'}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          iconColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 4 && selectTabs === 2 ? (
                                // Controls
                                <Grid>
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Active Color'
                                    value={
                                      elementObj?.appearance?.activeColor || '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          activeColor: color
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 4 && selectTabs === 3 ? (
                                // Button
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>

                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate and Support'
                                        className={classes.select}
                                        value={elementObj?.appearance?.label}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    mt={2}
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={
                                      elementObj?.appearance?.btnColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.btnBorderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 4 && selectTabs === 4 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 5 && selectTabs === 0 ? (
                                // Behaviour
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={3}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={3}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={3}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={3}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 5 && selectTabs === 1 ? (
                                // Form
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <SlidingElement
                                    label='Form Size'
                                    value={elementObj?.appearance?.size}
                                    defaultValue={40}
                                    max={80}
                                    min={40}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          size: newValue
                                        },
                                      }));
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 5 && selectTabs === 2 ? (
                                // Controls
                                <Grid>
                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={0}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Active Color'
                                    value={elementObj?.appearance?.activeColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          activeColor: color
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 5 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%',
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          '& .MuiOutlinedInput-input': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            '&::placeholder': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          },
                                          '& .MuiInputLabel-root': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000'
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:
                                              themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                          }
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate and Support'
                                        className={classes.select}
                                        value={elementObj?.appearance?.label}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.btnBorderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 5 && selectTabs === 4 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 6 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          value={elementObj?.designation}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }}
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 6 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Image
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <input
                                          type='file'
                                          ref={logoImgInputRef}
                                          onChange={handleImgFileChange}
                                          style={{ display: 'none' }}
                                        />
                                        <Button
                                          className={classes.uploadButton}
                                          variant='contained'
                                          onClick={() =>
                                            handleButtonClick(logoImgInputRef)
                                          }
                                          onMouseEnter={() => setIsHovered(true)}
                                          onMouseLeave={() => setIsHovered(false)}
                                          sx={{
                                            //background: themeMode === "dark" ? "#141414" : "#ECECEC",
                                            //backgroundImage: imageUrl ? `url('${imageUrl}')`:`url('${plant}')`,
                                            backgroundImage: `url('${imageUrl}')`,
                                            backgroundSize: 'cover,cover',
                                            backgroundPosition: 'center center,10% 50%',
                                            border:
                                              themeMode === 'dark'
                                                ? '1px solid #FFF'
                                                : '1px solid #000',
                                            color:
                                              themeMode === 'dark'
                                                ? '#FFFF'
                                                : '#030303',
                                            '&:hover': {
                                              backdropFilter: 'blur(50px)',
                                              background: '#0D0D0DE5'
                                            }
                                          }}
                                        >
                                          {!imageUrl || (imageUrl && isHovered) ? (
                                            <Grid>
                                              <Typography
                                                sx={{
                                                  textTransform: 'capitalize',
                                                  fontSize: '25px',
                                                  color: '#FFF'
                                                }}
                                              >
                                                <img
                                                  src={upload}
                                                  alt=''
                                                  style={{ marginRight: '10px' }}
                                                />
                                                Upload
                                              </Typography>
                                            </Grid>
                                          ) : (
                                            ''
                                          )}
                                        </Button>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Title
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='Donate'
                                          value={elementObj?.appearance?.title}
                                          className={classes.select}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                title: event.target.value
                                              }
                                            }))
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Content
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          default='Description of the need and/or donor impact'
                                          value={elementObj?.appearance?.content}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                content: event.target.value
                                              },
                                            }));
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid>
                                    <TickBox
                                      text='Launch Checkout with this title and content'
                                      value={true}
                                    />
                                  </Grid>

                                  <SlidingElement
                                    label='Card Width'
                                    value={elementObj?.appearance?.width}
                                    defaultValue={250}
                                    max={350}
                                    min={240}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          width: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Card Height'
                                    value={elementObj?.appearance?.height}
                                    defaultValue={300}
                                    max={400}
                                    min={280}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          height: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={4}
                                    max={20}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 6 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Button Text
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='Donate'
                                          value={elementObj?.appearance?.label}
                                          className={classes.select}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                label: event.target.value
                                              }
                                            }))
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>

                                  <ColorBox
                                    label='Button Text Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        },
                                      }));
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 6 && selectTabs === 3 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 7 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Start Date
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            startDateField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : startDateField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set Start Date'
                                          value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setStartDateField(!startDateField)
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue
                                              }
                                            }))
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: startDateField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            sx={{ width: '100%' }}
                                          >
                                            <DatePicker
                                              size='small'
                                              // label="Start Date"
                                              value={dayjs(elementObj?.behaviour?.startDate)}
                                              onChange={date =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    startDate: date
                                                  }
                                                }))
                                              }
                                              maxDate={elementObj?.behaviour?.endDate ? dayjs(elementObj?.behaviour?.endDate) : ""}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          End Date
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            endDateField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : endDateField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set End Date'
                                          //value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setEndDateField(!endDateField)
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue
                                              }
                                            }))
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: endDateField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            sx={{ width: '100%' }}
                                          >
                                            <DatePicker
                                              size='small'
                                              // label="End Date"
                                              //value={dayjs(dateRange?.startDate)}
                                              value={dayjs(elementObj?.behaviour?.endDate)}
                                              onChange={date =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    endDate: date
                                                  }
                                                }))
                                              }
                                              minDate={elementObj?.behaviour?.startDate ? dayjs(elementObj?.behaviour?.startDate) : ""}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='goalCurrency'
                                          value={elementObj?.behaviour?.goalCurrency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj?.behaviour,
                                                goalCurrency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Goal
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='$10,000'
                                          className={classes.select}
                                          value={elementObj?.behaviour?.goal}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj?.behaviour,
                                                goal: event.target.value
                                              }
                                            }))
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Add Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='$0'
                                          className={classes.select}
                                          value={elementObj?.amount}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              amount: Number(event.target.value)
                                            }));
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Matching Funds
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            matchingField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : matchingField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set Multiplier'
                                          //value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setMatchingField(!matchingField)
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue
                                              }
                                            }))
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: matchingField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <NumberInput
                                            aria-label='Demo number input'
                                            placeholder='Type a number…'
                                            value={elementObj?.behaviour?.multiplierValue}
                                            onChange={(event, newValue) =>
                                              setElementObj(prevObj => ({
                                                ...prevObj,
                                                behaviour: {
                                                  ...prevObj?.behaviour,
                                                  multiplierValue: newValue
                                                }
                                              }))
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 7 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid>
                                    <TickBox
                                      text='Show Label'
                                      value={elementObj?.appearance?.showLabel}
                                      onChange={(event, newValue) => {
                                        setElementObj((prevObj) => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            showLabel: newValue,
                                          },
                                        }));
                                      }}
                                    />
                                  </Grid>

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Progress Bar Color'
                                    value={elementObj?.appearance?.progressBarColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          progressBarColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Animation'
                                      value={elementObj?.appearance?.showAnimation}
                                      onChange={(event, newValue) => {
                                        setElementObj((prevObj) => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            showAnimation: newValue,
                                          },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 8 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Counter Type
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Amount Raised'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.behaviour?.counterType || ''}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj?.behaviour,
                                                counterType: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {counterType?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.name}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.name}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>

                                    <Grid my={2}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          width: '100%'
                                        }}
                                      >
                                        <Grid
                                          item
                                          md={4}
                                          sm={4}
                                          xs={4}
                                          mr={2}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: 'fit-content'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              textAlign: 'right',
                                              width: '100%',
                                              fontSize: {
                                                md: '16px',
                                                sm: '14px',
                                                xs: '12px'
                                              }
                                            }}
                                          >
                                            Campaigns
                                          </Typography>
                                        </Grid>
                                        <Grid item md={8} sm={8} xs={8}>
                                          <TextField
                                            select
                                            size='small'
                                            variant='outlined'
                                            placeholder='Select a Campaign'
                                            className={classes.select}
                                            sx={{
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#0D0D0D'
                                                  : '#ECECEC',
                                              '& .MuiOutlinedInput-input': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                '&::placeholder': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                }
                                              },
                                              '& .MuiInputLabel-root': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              },
                                              '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#0F0F0F'
                                              }
                                            }}
                                            SelectProps={{
                                              MenuProps: {
                                                PaperProps: {
                                                  style: {
                                                    backgroundColor:
                                                      themeMode === 'dark'
                                                        ? '#0D0D0D'
                                                        : '#ECECEC',
                                                    maxHeight: 150,
                                                    Width: '70%',
                                                    scrollbarWidth: 'thin',
                                                    scrollbarColor:
                                                      themeMode === 'dark'
                                                        ? '#8ED003CC #0F0F0F'
                                                        : '#8ED003 #E2E2E2',
                                                    '&:hover': {
                                                      background: '#659500',
                                                      color: '#FFF'
                                                    }
                                                  }
                                                }
                                              }
                                            }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            value={elementObj?.campaignUuid || ''}
                                            onChange={event =>
                                              setElementObj(prevObj => ({
                                                ...prevObj,
                                                campaignUuid: event.target.value
                                              }))
                                            }
                                          >
                                            {campaignList?.map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.uuid}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  {item.title}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='goalCurrency'
                                          value={elementObj?.behaviour?.goalCurrency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj?.behaviour,
                                                goalCurrency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Start Date
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            startDateField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : startDateField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set Start Date'
                                          value={startDateField}
                                          onChange={() => {
                                            setStartDateField(!startDateField)
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: startDateField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            sx={{ width: '100%' }}
                                          >
                                            <DatePicker
                                              size='small'
                                              // label="Start Date"
                                              value={dayjs(elementObj?.behaviour?.startDate)}
                                              onChange={date =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    startDate: date
                                                  }
                                                }))
                                              }
                                              maxDate={elementObj?.behaviour?.endDate ? dayjs(elementObj?.behaviour?.endDate) : ""}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          End Date
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            endDateField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : endDateField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set End Date'
                                          value={endDateField}
                                          onChange={() => {
                                            setEndDateField(!endDateField)
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: endDateField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            sx={{ width: '100%' }}
                                          >
                                            <DatePicker
                                              size='small'
                                              // label="End Date"
                                              onChange={date =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    endDate: date
                                                  }
                                                }))
                                              }
                                              minDate={elementObj?.behaviour?.startDate ? dayjs(elementObj?.behaviour?.startDate) : ""}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Add Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          //required
                                          mt={1}
                                          fullWidth
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='Amount'
                                          value={elementObj?.amount}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              amount: Number(event.target.value),
                                            }))
                                          }}
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            borderRadius: '10px',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              fontSize: '12px',
                                              marginBottom: '15px',
                                              transform:
                                                'translate(0px, -40px) scale(1.25)',
                                              '&.MuiInputLabel-shrink': {
                                                transform:
                                                  'translate(0px, -40px) scale(1.25)'
                                              }
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F',
                                              borderWidth: '0.5px',
                                              borderRadius: '5px'
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                              borderColor: '#acff00',
                                              color:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#000'
                                            },
                                            '&.Mui-active .MuiOutlinedInput-notchedOutline':
                                            {
                                              borderColor: 'red'
                                            },
                                            outline: 'none'
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Matching Funds
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          background:
                                            matchingField && themeMode === 'dark'
                                              ? '#0F0F0F'
                                              : matchingField && themeMode === 'light'
                                                ? '#E2E2E2'
                                                : '',
                                          padding: '10px',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start',
                                          borderRadius: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Set Multiplier'
                                          value={matchingField}
                                          onChange={() => {
                                            setMatchingField(!matchingField)
                                          }}
                                        />

                                        <Grid
                                          sx={{
                                            display: matchingField ? 'flex' : 'none',
                                            width: '100%',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          <NumberInput
                                            aria-label='Demo number input'
                                            placeholder='1'
                                            value={elementObj?.behaviour?.multiplierValue}
                                            onChange={(event, val) =>
                                              setElementObj(prevObj => ({
                                                ...prevObj,
                                                behaviour: {
                                                  ...prevObj?.behaviour,
                                                  multiplierValue: val
                                                }
                                              }))
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      ></Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        sx={{
                                          padding: '10px'
                                        }}
                                      >
                                        <TickBox
                                          text='Show counting animation'
                                          value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue,
                                              },
                                            }));
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 9 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Imapct Type
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.impact_type || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                impact_type: event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'amount_by_impact'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Amount by impact
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'impact_by_amount'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Impact by amount
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='How many families can you support?'
                                        className={classes.select}
                                        value={elementObj?.appearance?.title || 'How many families can you support?'}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%'
                                          }}
                                        >
                                          Values
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        {impactLevel?.map((item, index) => (
                                          <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            mb={2}
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#1C1C1C'
                                                  : '#E2E2E2',
                                              borderRadius: '10px',
                                              padding: '15px'
                                            }}
                                          >
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='$50'
                                              className={classes.select}
                                              value={item.price}
                                              onChange={(event) => {
                                                const updatedImpactLevel = [...impactLevel];
                                                updatedImpactLevel[index] = { ...updatedImpactLevel[index], price: event.target.value };
                                                setImpactLevel(updatedImpactLevel);
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj.behaviour,
                                                    impactLevel: updatedImpactLevel
                                                  }
                                                }))    
                                              }}
                                              sx={{
                                                width:
                                                  impactLevel?.length > 4
                                                    ? '40% !important'
                                                    : '45% !important',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position='start'>
                                                    <Typography
                                                      variant='body2'
                                                      color='#8a8a8a'
                                                      sx={{ width: 'fit-content' }}
                                                    >
                                                      $
                                                    </Typography>
                                                  </InputAdornment>
                                                )
                                              }}
                                            ></TextField>

                                            <Grid
                                              mx={{ md: 1, sm: 1, xs: 0 }}
                                              sx={{
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#FFFF'
                                                    : '#030303',
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}
                                            >
                                              <ArrowRightAlt />
                                            </Grid>

                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default={item.family}
                                              className={classes.select}
                                              value={item.family}
                                              onChange={(event) => {
                                                const updatedImpactLevel = [...impactLevel];
                                                updatedImpactLevel[index] = { ...updatedImpactLevel[index], family: event.target.value };
                                                setImpactLevel(updatedImpactLevel);
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj.behaviour,
                                                    impactLevel: updatedImpactLevel
                                                  }
                                                })) 
                                              }}
                                              sx={{
                                                width:
                                                  impactLevel?.length > 4
                                                    ? '40% !important'
                                                    : '45% !important',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position='end'>
                                                    <Typography
                                                      variant='body2'
                                                      color='#8a8a8a'
                                                    >
                                                      families
                                                    </Typography>
                                                  </InputAdornment>
                                                )
                                              }}
                                            ></TextField>
                                            <Grid
                                              sx={{
                                                display:
                                                  impactLevel?.length > 4
                                                    ? 'flex'
                                                    : 'none',
                                                alignContent: 'center'
                                              }}
                                            >
                                              <button
                                                mx={1}
                                                style={{
                                                  backgroundColor: 'transparent',
                                                  border: '0px',
                                                  padding: '0px'
                                                }}
                                                disableRipple
                                                onClick={() =>
                                                  handleRemoveImpactLevel(index)
                                                }
                                                sx={{
                                                  '&:hover': {
                                                    background: 'transparent'
                                                  }
                                                }}
                                              >
                                                <img
                                                  src={
                                                    themeMode === 'dark'
                                                      ? cross
                                                      : crossDark
                                                  }
                                                  alt=''
                                                />
                                              </button>
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    {impactLevel?.length < 6 && (
                                      <Button
                                        variant='contained'
                                        onClick={handleAddImpactLevel}
                                        sx={{
                                          background:
                                            themeMode === 'dark'
                                              ? '#141414'
                                              : '#ECECEC',
                                          border:
                                            themeMode === 'dark'
                                              ? '1px solid transparent'
                                              : '1px solid #000',
                                          color:
                                            themeMode === 'dark' ? '#FFFF' : '#030303',
                                          textTransform: 'capitalize',
                                          borderRadius: '30px',
                                          padding: '8px 15px',
                                          '&:hover': {
                                            color: '#FFF',
                                            backgroundColor: '#689900'
                                          }
                                        }}
                                      >
                                        <Typography className={classes.buttonText}>
                                          + Add impact level
                                        </Typography>
                                      </Button>
                                    )}
                                  </Grid>

                                  <Grid>
                                    <TickBox
                                      text='Allow supporter to change selected amount'
                                      value={elementObj?.appearance?.enable}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            enable: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 9 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor || '#FFFF'}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor || '#FFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={1}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={0}
                                    max={28}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 9 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>

                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate and Support'
                                        className={classes.select}
                                        value={elementObj?.appearance?.label}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    mt={2}
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={
                                      elementObj?.appearance?.btnColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.btnBorderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 9 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label Text
                                      </Typography>
                                    </Grid>

                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Support'
                                        className={classes.select}
                                        value={elementObj?.appearance?.label}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    mt={2}
                                    label='Label Text Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Label Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.borderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Icon Color'
                                    value={
                                      elementObj?.appearance?.iconColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          iconColor: color
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 9 && selectTabs === 4 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 10 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Accent
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.accent}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj?.behaviour,
                                                accent: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          <MenuItem
                                            value={'on_amount'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              On amount
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'on_level'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              On level
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  {customLevel?.map((item, index) => (
                                    <Grid
                                      key={index}
                                      my={4}
                                      sx={{
                                        backgroundColor:
                                          themeMode === 'dark' ? '#1C1C1C' : '#E2E2E2',
                                        borderRadius: '10px',
                                        padding: '15px'
                                      }}
                                    >
                                      <Grid my={2}>
                                        <Grid
                                          my={2}
                                          sx={{
                                            display:
                                              customLevel?.length > 1 ? 'flex' : 'none',
                                            justifyContent: 'flex-end'
                                          }}
                                        >
                                          <Button
                                            onClick={() => handleRemoveLevel(index)}
                                            disableRipple
                                            sx={{
                                              '&:hover': {
                                                backgroundColor: 'transparent',
                                                transform: 'scale(1.1)'
                                              }
                                            }}
                                          >
                                            <img
                                              src={
                                                themeMode === 'dark' ? cross : crossDark
                                              }
                                              alt=''
                                            />
                                          </Button>
                                        </Grid>

                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: 'fit-content'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Level
                                            </Typography>
                                          </Grid>
                                          <Grid item md={5.9} sm={5.9} xs={5.9}>
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='Supporter'
                                              className={classes.select}
                                              value={elementObj?.behaviour?.levelA}
                                              onChange={(event) => {
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    levelA: event.target.value
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                          <Grid item md={1.5} sm={1.5} xs={1.5}>
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='Supporter'
                                              className={classes.select}
                                              value={elementObj?.behaviour?.levelB}
                                              onChange={(event) => {
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    levelB: event.target.value
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: 'fit-content'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Button Label
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='Donate'
                                              className={classes.select}
                                              value={elementObj?.appearance?.label}
                                              onChange={(e) => {
                                                setElementObj((prevObj) => ({
                                                  ...prevObj,
                                                  appearance: {
                                                    ...prevObj.appearance,
                                                    label: e.target.value
                                                  },
                                                }));
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              width: 'fit-content'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Description
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              default='Supporter'
                                              multiline
                                              minRows={3}
                                              mt={1}
                                              fullWidth
                                              size='small'
                                              className={classes.fullInput}
                                              placeholder='Make a difference today'
                                              variant='outlined'
                                              id='outlined-basic'
                                              value={elementObj?.behaviour?.description}
                                              onChange={(event) => {
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    description: event.target.value
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                  <Grid
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    {customLevel?.length < 5 && (
                                      <Button
                                        variant='contained'
                                        onClick={handleAddLevel}
                                        sx={{
                                          background:
                                            themeMode === 'dark'
                                              ? '#141414'
                                              : '#ECECEC',
                                          border:
                                            themeMode === 'dark'
                                              ? '1px solid transparent'
                                              : '1px solid #000',
                                          color:
                                            themeMode === 'dark' ? '#FFFF' : '#030303',
                                          textTransform: 'capitalize',
                                          borderRadius: '30px',
                                          padding: '8px 15px',
                                          '&:hover': {
                                            color: '#FFF',
                                            backgroundColor: '#689900'
                                          }
                                        }}
                                      >
                                        <Typography className={classes.buttonText}>
                                          + Add Level
                                        </Typography>
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 10 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Accent Text Color'
                                    value={elementObj?.appearance?.accentTextColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          accentTextColor: color
                                        },
                                      }));
                                    }}
                                  />
                                  <SlidingElement
                                    label='Button Size'
                                    value={elementObj?.appearance?.btnSize}
                                    defaultValue={18}
                                    max={25}
                                    min={18}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 10 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Button Radius'
                                    value={elementObj?.appearance?.btnRadius}
                                    defaultValue={6}
                                    max={20}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={1}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        },
                                      }));
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={6}
                                    max={20}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.btnBorderColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        },
                                      }));
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 11 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.designation || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Buttons
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        {groupBtn?.map((item, index) => (
                                          <Grid
                                            item
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            mb={2}
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              backgroundColor:
                                                themeMode === 'dark'
                                                  ? '#1C1C1C'
                                                  : '#E2E2E2',
                                              borderRadius: '10px',
                                              padding: '15px'
                                            }}
                                          >
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='$50'
                                              className={classes.select}
                                              value={item.pay}
                                              onChange={(event) => {
                                                const updatedGroupBtn = [...groupBtn];
                                                updatedGroupBtn[index] = { ...updatedGroupBtn[index], pay: event.target.value };
                                                setGroupBtn(updatedGroupBtn);
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj.behaviour,
                                                    groupBtn: updatedGroupBtn
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                width:
                                                  groupBtn?.length > 3
                                                    ? '75% !important'
                                                    : '100% !important',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                            <Grid
                                              sx={{
                                                display:
                                                  groupBtn?.length > 3
                                                    ? 'flex'
                                                    : 'none',
                                                alignContent: 'center'
                                              }}
                                            >
                                              <Button
                                                disableRipple
                                                onClick={() =>
                                                  handleRemoveGroupBtn(index)
                                                }
                                                sx={{
                                                  '&:hover': {
                                                    background: 'transparent'
                                                  }
                                                }}
                                              >
                                                <img
                                                  src={
                                                    themeMode === 'dark'
                                                      ? cross
                                                      : crossDark
                                                  }
                                                  alt=''
                                                />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    {groupBtn?.length < 9 && (
                                      <Button
                                        variant='contained'
                                        onClick={handleAddGroupBtn}
                                        sx={{
                                          background:
                                            themeMode === 'dark'
                                              ? '#141414'
                                              : '#ECECEC',
                                          border:
                                            themeMode === 'dark'
                                              ? '1px solid transparent'
                                              : '1px solid #000',
                                          color:
                                            themeMode === 'dark' ? '#FFFF' : '#030303',
                                          textTransform: 'capitalize',
                                          borderRadius: '30px',
                                          padding: '8px 15px',
                                          '&:hover': {
                                            color: '#FFF',
                                            backgroundColor: '#689900'
                                          }
                                        }}
                                      >
                                        <Typography className={classes.buttonText}>
                                          + Add point
                                        </Typography>
                                      </Button>
                                    )}
                                  </Grid>

                                  <Grid my={2}>
                                    <TickBox
                                      text='Allow supporter to change selected amount'
                                      value={elementObj?.appearance?.enable_change_amount}
                                      onChange={(event, newValue) => {
                                        setMatchingField(!matchingField)
                                        setElementObj((prevObj) => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            enable_change_amount: newValue,
                                          },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 11 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Text
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='Support Us'
                                          className={classes.select}
                                          value={elementObj?.appearance?.title}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                title: event.target.value
                                              },
                                            }));
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <ColorBox
                                    label='Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={(color) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        },
                                      }));
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 11 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <SlidingElement
                                    label='Button Size'
                                    value={elementObj?.appearance?.btnSize}
                                    defaultValue={18}
                                    max={60}
                                    min={18}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        },
                                      }));
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.btnBorderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />    

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 11 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <Typography sx={{ margin: '35px 0px' }}>
                                      The selected campaign does not have custom fields.
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 12 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 12 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%',
                                          '& .MuiOutlinedInput-input': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            '&::placeholder': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          },
                                          '& .MuiInputLabel-root': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000'
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:
                                              themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                          }
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate'
                                        value={elementObj?.appearance?.title}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Content
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          default='Your contribution today can improve the lives of so many. Please join us in making a difference.'
                                          value={elementObj?.appearance?.content}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                content: event.target.value
                                              },
                                            }));
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Position
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.appearance?.btnPositionVert || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                btnPositionVert: event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'1'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Top, scrolls with page
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'0'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Bottom, scrolls with page
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'2'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Top, does not scroll
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid>
                                    <TickBox
                                      text='Show close Button'
                                      value={elementObj?.appearance?.show_close_button}
                                      onChange={(event, newValue) => {
                                        setElementObj((prevObj) => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            show_close_button: newValue,
                                          },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 12 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate'
                                        value={elementObj?.appearance?.label}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={0}
                                    max={25}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.btnBorderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 12 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '45vh',
                                    position: 'relative',
                                    padding: '5px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={3}
                                      xs={3}
                                      mr={2}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: 'right',
                                          width: '100%',
                                          fontSize: {
                                            md: '16px',
                                            sm: '14px',
                                            xs: '12px'
                                          }
                                        }}
                                      >
                                        Show on
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={9} xs={9}>
                                      <TextField
                                        select
                                        size='small'
                                        variant='outlined'
                                        className={classes.select}
                                        value={browser}
                                        sx={{
                                          width: {
                                            md: '85% !important',
                                            sm: '100%',
                                            xs: '100%'
                                          },
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          '& .MuiOutlinedInput-input': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            '&::placeholder': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          },
                                          '& .MuiInputLabel-root': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000'
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:
                                              themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                          }
                                        }}
                                        SelectProps={{
                                          MenuProps: {
                                            PaperProps: {
                                              style: {
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                maxHeight: 150,
                                                Width: '70%',
                                                scrollbarWidth: 'thin',
                                                scrollbarColor:
                                                  themeMode === 'dark'
                                                    ? '#8ED003CC #0F0F0F'
                                                    : '#8ED003 #E2E2E2',
                                                '&:hover': {
                                                  background: '#659500',
                                                  color: '#FFF'
                                                }
                                              }
                                            }
                                          }
                                        }}
                                        onChange={handleBrowserView}
                                      >
                                        <MenuItem
                                          value={1}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Desktop and mobile browsers
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          value={2}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Desktop browsers only
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          value={3}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Mobile browsers only
                                          </Typography>
                                        </MenuItem>
                                      </TextField>
                                    </Grid>
                                  </FormControl>
                                  <Grid
                                    my={4}
                                    py={3}
                                    sx={{
                                      borderTop:
                                        themeMode === 'dark'
                                          ? '1px solid #FFFF'
                                          : '1px solid #929292'
                                    }}
                                  >
                                    <Typography sx={{ textAlign: 'left' }}>
                                      By default, element is displayed on every page of
                                      your website. URL control allows you to limit the
                                      pages on which element appears.
                                    </Typography>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Only show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be shown.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Never show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be hidden.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 13 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '45vh',
                                    position: 'relative',
                                    padding: '5px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Minimum Amount
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start'
                                        }}
                                      >
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='$0'
                                          className={classes.select}
                                          value={elementObj?.appearance?.minimumAmount}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                minimumAmount: event.target.value
                                              },
                                            }));
                                          }}
                                          sx={{
                                            width: '40% !important',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Supporter's Name
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.supporters_name || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                supporters_name : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'full_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Full Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName_lastInitial'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First name, Last Initial
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Additional Details
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start'
                                        }}
                                      >
                                        <TickBox
                                          text='Donation amount'
                                          value={elementObj?.appearance?.enable_amount}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_amount: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text="Supporter's Location"
                                          value={elementObj?.appearance?.enable_location}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_location: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show Currency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_currency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_currency : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'set_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Set currency
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName_lastInitial'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Detected currency
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='Currency'
                                          value={elementObj?.behaviour?.currency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                currency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <SlidingElement
                                    label='Maximum rows'
                                    value={limiter}
                                    defaultValue={limiter}
                                    max={10}
                                    min={1}
                                    onChange={(event, newValue) => {
                                      setLimiter(newValue)
                                    }}
                                  />

                                  <TickBox
                                    text='Show donate button'
                                    value={elementObj?.appearance?.enable}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          enable: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 13 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Top Supporters'
                                        value={elementObj?.appearance?.title || 'Top Supporters'}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label=' Accent text Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <TickBox
                                    text='Show shadow'
                                    value={elementObj?.appearance?.shadow}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          shadow: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 14 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Ranking Type
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.behaviour?.ranking_type || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                ranking_type : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'top_countries'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Top Countries
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'top_reigons'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Top Reigons
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'top_cities'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Top Cities
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Minimum amount
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start'
                                        }}
                                      >
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='$0'
                                          className={classes.select}
                                          value={elementObj?.appearance?.minimumAmount}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                minimumAmount: event.target.value
                                              },
                                            }));
                                          }}
                                          sx={{
                                            width: '40% !important',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show Currency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_currency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_currency : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'set_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Set currency
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'detected_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Detected currency
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='Currency'
                                          value={elementObj?.behaviour?.currency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                currency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <SlidingElement
                                    label='Maximum rows'
                                    value={countryLimiter}
                                    defaultValue={countryLimiter}
                                    max={10}
                                    min={1}
                                    onChange={(event, newValue) => {
                                      setCountryLimiter(newValue)
                                    }}
                                  />

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <TickBox
                                      text='Show donate button'
                                      value={elementObj?.appearance?.enable}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            enable: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 14 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label=' Accent text Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <TickBox
                                    text='Show shadow'
                                    value={elementObj?.appearance?.shadow}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          shadow: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 15 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show donation to
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Supporter's Name
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.supporters_name || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                supporters_name : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'full_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Full Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName_lastInitial'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First name, Last Initial
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            display: 'flex',
                                            textWrap: 'nowrap',
                                            justifyContent: 'flex-end',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%'
                                          }}
                                        >
                                          Show
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        <TickBox
                                          text='Emoji'
                                          value={elementObj?.appearance?.enable_emoji}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_emoji: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Donation Location'
                                          value={elementObj?.appearance?.enable_location}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_location: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 15 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <ColorBox
                                    label='Accent Color'
                                    value={
                                      elementObj?.appearance?.accentColor || '#689900'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          accentColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label=' Text Color'
                                    value={elementObj?.appearance?.textColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Background Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor ||
                                      'transparent'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={1}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={50}
                                    max={80}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <TickBox
                                    text='Show shadow'
                                    value={elementObj?.appearance?.shadow}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          shadow: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 15 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate'
                                        value={elementObj?.appearance?.label}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Button Color'
                                    value={elementObj?.appearance?.btnColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={0}
                                    max={25}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj((prevObj) => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        },
                                      }));
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.btnBorderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 15 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '45vh',
                                    position: 'relative',
                                    padding: '5px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid>
                                    <Typography sx={{ textAlign: 'left' }}>
                                      By default, element is displayed on every page of
                                      your website. URL control allows you to limit the
                                      pages on which element appears.
                                    </Typography>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Only show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be shown.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Never show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be hidden.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 16 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value,
                                              donationUrl: `https://www.accceleraise.com/campaign-donation/0/${event.target.value}`,
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          value={elementObj?.designation}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }}
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          URL
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          value={elementObj?.donationUrl || ''}
                                          InputProps={{
                                            readOnly: true,
                                          }}      
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 16 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <SlidingElement
                                    label='Size'
                                    value={elementObj?.appearance?.size}
                                    defaultValue={256}
                                    max={300}
                                    min={150}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          size: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Margin'
                                    value={elementObj?.appearance?.margin}
                                    defaultValue={4}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          margin: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid mb={1}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Image in center
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <input
                                          type='file'
                                          ref={logoImgInputRef}
                                          onChange={handleImgFileChange}
                                          style={{ display: 'none' }}
                                        />
                                        <Button
                                          className={classes.uploadButton}
                                          variant='contained'
                                          onClick={() =>
                                            handleButtonClick(logoImgInputRef)
                                          }
                                          onMouseEnter={() => setIsHovered(true)}
                                          onMouseLeave={() => setIsHovered(false)}
                                          sx={{
                                            width: '50%',
                                            background:
                                              themeMode === 'dark'
                                                ? '#141414'
                                                : '#ECECEC',
                                            //backgroundImage: imageUrl ? `url('${imageUrl}')`:`url('${plant}')`,
                                            //  backgroundImage: `url('${imageUrl}')`,
                                            backgroundSize: 'cover,cover',
                                            backgroundPosition: 'center center,10% 50%',
                                            border:
                                              themeMode === 'dark'
                                                ? '1px solid #FFF'
                                                : '1px solid #000',
                                            color:
                                              themeMode === 'dark'
                                                ? '#FFFF'
                                                : '#030303',
                                            '&:hover': {
                                              backdropFilter: 'blur(50px)',
                                              background: '#0D0D0DE5'
                                            }
                                          }}
                                        >
                                          {/* {!imageUrl || (imageUrl && isHovered) ?  */}
                                          {
                                            <Grid>
                                              <Typography
                                                sx={{
                                                  textTransform: 'capitalize',
                                                  fontSize: '20px',
                                                  color: '#FFF',
                                                  textWrap: 'nowrap',
                                                  flexWrap: 'nowrap',
                                                  whiteSpace: 'nowrap',
                                                  display: 'flex',
                                                  flexDirection: 'row'
                                                }}
                                              >
                                                <img
                                                  src={upload}
                                                  alt=''
                                                  style={{ marginRight: '10px' }}
                                                />
                                                Upload
                                              </Typography>
                                            </Grid>
                                          }
                                        </Button>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <ColorBox
                                    label='Color'
                                    value={elementObj?.appearance?.color}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 16 && selectTabs === 2 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 17 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Supporter's Name
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.supporters_name || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                supporters_name : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'full_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Full Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName_lastInitial'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First name, Last Initial
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            display: 'flex',
                                            textWrap: 'nowrap',
                                            justifyContent: 'flex-end',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%'
                                          }}
                                        >
                                          Additional details
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        <TickBox
                                          text='Donation amount'
                                          value={elementObj?.appearance?.enable_amount}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_amount: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Supporter Location'
                                          value={elementObj?.appearance?.enable_location}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_location: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Donation comment'
                                          value={elementObj?.appearance?.enable_comment}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_comment: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Donation date'
                                          value={elementObj?.appearance?.enable_donation_date}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_donation_date: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <SlidingElement
                                    label='Number of rows'
                                    value={donationLimiter}
                                    defaultValue={donationLimiter}
                                    max={10}
                                    min={1}
                                    onChange={(event, newValue) => {
                                      setDonationLimiter(newValue)
                                    }}
                                  />

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Show Currency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.show_currency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                show_currency : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'set_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Set currency
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'detected_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Detected currency
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'actual_currency'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Actual currency
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='Currency'
                                          value={elementObj?.behaviour?.currency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                currency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <TickBox
                                      text='Show donate button'
                                      value={elementObj?.appearance?.enable_donate_button}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            enable_donate_button: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 17 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Recent Donations'
                                        className={classes.select}
                                        value={elementObj?.appearance?.title || 'Recent Donations'}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            }
                                          }))
                                        }}
                                        sx={{
                                          width: '90% !important',
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          borderRadius: '10px',
                                          '& .MuiOutlinedInput-input': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            '&::placeholder': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          },
                                          '& .MuiInputLabel-root': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            fontSize: '12px',
                                            marginBottom: '15px',
                                            transform:
                                              'translate(0px, -40px) scale(1.25)',
                                            '&.MuiInputLabel-shrink': {
                                              transform:
                                                'translate(0px, -40px) scale(1.25)'
                                            }
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:
                                              themeMode === 'dark'
                                                ? '#ffff'
                                                : '#0F0F0F',
                                            borderWidth: '0.5px',
                                            borderRadius: '5px'
                                          },
                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#acff00',
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000'
                                          },
                                          '&.Mui-active .MuiOutlinedInput-notchedOutline':
                                          {
                                            borderColor: 'red'
                                          },
                                          outline: 'none'
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>
                                  <ColorBox
                                    label='Background Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor || '#FFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor || '#FFFF'}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Accent Text Color'
                                    value={
                                      elementObj?.appearance?.accentColor || '#FFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          accentColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={2}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={6}
                                    max={20}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.borderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                  <Grid>
                                    <TickBox
                                      text='Show animation'
                                      value={elementObj?.appearance?.show_animation}
                                      onChange={(event, newValue) => {
                                        setElementObj((prevObj) => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            show_animation: newValue,
                                          },
                                        }));
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 18 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Supporter's Name
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.supporters_name || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                supporters_name : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'full_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Full Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName_lastInitial'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First name, Last Initial
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'firstName'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              First Name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'hide'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Hide
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            display: 'flex',
                                            textWrap: 'nowrap',
                                            justifyContent: 'flex-end',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%'
                                          }}
                                        >
                                          Show
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        <TickBox
                                          text='Donation amount'
                                          value={elementObj?.appearance?.enable_amount}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_amount: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Donation Date'
                                          value={elementObj?.appearance?.enable_date}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_date: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Donation Comment'
                                          value={elementObj?.appearance?.enable_comment}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_comment: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Emoji'
                                          value={elementObj?.appearance?.enable_emoji}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable_emoji: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 18 && selectTabs === 1 ? (
                                // <iframe
                                //   width="450"
                                //   height="250"
                                //   frameborder="0" style={{border:"0px solid #000"}}
                                //   referrerpolicy="no-referrer-when-downgrade"
                                //   src="https://www.google.com/maps/embed/v1/MAP_MODE?key=AIzaSyCy_pXYhi5-yl7YtUX4AGfTd3JjnRMGC_8&q=Space+Needle,Seattle+WA"
                                //   allowfullscreen>
                                // </iframe>
                                <Grid>
                                  <Typography>
                                    Choose the starting position and zoom level of this
                                    map.
                                  </Typography>
                                  <MyMap size={elementObj?.appearance?.height}/>
                                </Grid>
                              ) : selectElement.id === 18 && selectTabs === 2 ? (
                                <Grid>
                                  <SlidingElement
                                    label='Height'
                                    value={elementObj?.appearance?.height}
                                    defaultValue={300}
                                    max={700}
                                    min={200}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          height: newValue
                                        }
                                      }))
                                    }}
                                  />
                                </Grid>
                              ) : selectElement.id === 19 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid
                                    mb={2}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          display: 'flex',
                                          textWrap: 'nowrap',
                                          justifyContent: 'flex-end',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Actions
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8}
                                      sm={8}
                                      xs={12}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      <FormControl
                                        sx={{ width: '100%', fontSize: '14px' }}
                                      >
                                        <RadioGroup
                                          aria-labelledby='demo-controlled-radio-buttons-group'
                                          name='controlled-radio-buttons-group'
                                          value={selectedValue}
                                          onChange={handleChange}
                                        >
                                          <FormControlLabel
                                            value='newCampaign'
                                            sx={{ textAlign: 'left' }}
                                            control={
                                              <Radio
                                                {...controlProps('e')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Start a new Acceleraiser'
                                          />
                                          <FormControlLabel
                                            value='clone'
                                            control={
                                              <Radio
                                                {...controlProps('f')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Join an existing team '
                                          />
                                          <FormControlLabel
                                            value='clone'
                                            control={
                                              <Radio
                                                {...controlProps('g')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Show both options '
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  {selectedValue === 'e' || selectedValue === 'g' ? (
                                    <>
                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Frequency
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='match_checkout_setting'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.defaultFrequency || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  defaultFrequency: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                              <MenuItem
                                                value={'default_one_time'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Default One Time
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Designation
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='MatchCheckoutSettings'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.designation || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  designation: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <TickBox
                                          text='Allow Acceleraiser to change designation'
                                          value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Default amount
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='match_checkout_setting'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.defaultAmount || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  defaultAmount: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                              <MenuItem
                                                value={'customize'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Customize
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: {
                                              md: 'row',
                                              sm: 'row',
                                              xs: 'column'
                                            },
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={12}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Empowering Text
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={12}>
                                            <TextField
                                              multiline
                                              minRows={6}
                                              mt={1}
                                              fullWidth
                                              size='small'
                                              className={classes.fullInput}
                                              variant='outlined'
                                              id='outlined-basic'
                                              default='Tell the people you know best why our work matters to you! Filling in a goal, message, and photo will make your Acceleraiser feel relevant to your community.'
                                              value={elementObj?.appearance?.content}
                                              onChange={(event) => {
                                                setElementObj((prevObj) => ({
                                                  ...prevObj,
                                                  appearance: {
                                                    ...prevObj.appearance,
                                                    content: event.target.value
                                                  },
                                                }));
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: {
                                              md: 'row',
                                              sm: 'row',
                                              xs: 'column'
                                            },
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={12}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              my={{ md: 0, sm: 0, xs: 1 }}
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: {
                                                  md: 'right',
                                                  sm: 'right',
                                                  xs: 'left'
                                                },
                                                width: '100%'
                                              }}
                                            >
                                              Default Goal
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={12}>
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='$500'
                                              className={classes.select}
                                              value={elementObj?.behaviour?.goal}
                                              onChange={(event) => {
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    goal: event.target.value
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                justifyContent: 'flex-start !important',
                                                width: '60% !important',
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              ) : selectElement.id === 19 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Start a fundraise'
                                        value={elementObj?.appearance?.label}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <SlidingElement
                                    label='Button Size'
                                    value={elementObj?.appearance?.btnSize || ''}
                                    defaultValue={20}
                                    max={80}
                                    min={20}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnSize: newValue
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Button Color'
                                    value={
                                      elementObj?.appearance?.btnColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={6}
                                    max={44}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.btnBorderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 19 && selectTabs === 2 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 19 && selectTabs === 3 ? (
                                <>
                                  <QuestionSearch />
                                </>
                              ) : selectElement.id === 20 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid
                                    mb={2}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          display: 'flex',
                                          textWrap: 'nowrap',
                                          justifyContent: 'flex-end',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Actions
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      md={8}
                                      sm={8}
                                      xs={12}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      <FormControl
                                        sx={{ width: '100%', fontSize: '14px' }}
                                      >
                                        <RadioGroup
                                          aria-labelledby='demo-controlled-radio-buttons-group'
                                          name='controlled-radio-buttons-group'
                                          value={selectedValue}
                                          onChange={handleChange}
                                        >
                                          <FormControlLabel
                                            value='newCampaign'
                                            sx={{ textAlign: 'left' }}
                                            control={
                                              <Radio
                                                {...controlProps('e')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Start a new Acceleraiser'
                                          />
                                          <FormControlLabel
                                            value='clone'
                                            control={
                                              <Radio
                                                {...controlProps('f')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Join an existing team '
                                          />
                                          <FormControlLabel
                                            value='clone'
                                            control={
                                              <Radio
                                                {...controlProps('g')}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFFF'
                                                      : '#030303',
                                                  '&.Mui-checked': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#D5DA00'
                                                        : '#8ED003'
                                                  }
                                                }}
                                              />
                                            }
                                            label='Show both options '
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value,
                                              donationUrl: `https://www.accceleraise.com/campaign-donation/0/${event.target.value}`,
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  {selectedValue === 'e' || selectedValue === 'g' ? (
                                    <>
                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Frequency
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='match_checkout_setting'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.defaultFrequency || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  defaultFrequency: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                              <MenuItem
                                                value={'default_one_time'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Default One Time
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Designation
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='MatchCheckoutSettings'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.designation || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  designation: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                      <Grid
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <TickBox
                                          text='Allow Acceleraiser to change designation'
                                          value={elementObj?.appearance?.enable}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                enable: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Default amount
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={8}>
                                            <TextField
                                              select
                                              size='small'
                                              variant='outlined'
                                              default='match_checkout_setting'
                                              className={classes.select}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                              SelectProps={{
                                                MenuProps: {
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor:
                                                        themeMode === 'dark'
                                                          ? '#0D0D0D'
                                                          : '#ECECEC',
                                                      maxHeight: 150,
                                                      Width: '70%',
                                                      scrollbarWidth: 'thin',
                                                      scrollbarColor:
                                                        themeMode === 'dark'
                                                          ? '#8ED003CC #0F0F0F'
                                                          : '#8ED003 #E2E2E2',
                                                      '&:hover': {
                                                        background: '#659500',
                                                        color: '#FFF'
                                                      }
                                                    }
                                                  }
                                                }
                                              }}
                                              value={elementObj?.defaultAmount || ''}
                                              onChange={event =>
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  defaultAmount: event.target.value
                                                }))
                                              }
                                            >
                                              <MenuItem
                                                value={'match_checkout_setting'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111'
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Match Checkout Settings
                                                </Typography>
                                              </MenuItem>
                                              <MenuItem
                                                value={'customize'}
                                                className={classes.menuItem}
                                                sx={{
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#FFF'
                                                      : '#111',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }}
                                              >
                                                <Typography sx={{ fontSize: '14px' }}>
                                                  Customize
                                                </Typography>
                                              </MenuItem>
                                            </TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>

                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: {
                                              md: 'row',
                                              sm: 'row',
                                              xs: 'column'
                                            },
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={12}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: 'right',
                                                width: '100%',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '14px',
                                                  xs: '12px'
                                                }
                                              }}
                                            >
                                              Empowering Text
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={12}>
                                            <TextField
                                              multiline
                                              minRows={6}
                                              mt={1}
                                              fullWidth
                                              size='small'
                                              className={classes.fullInput}
                                              variant='outlined'
                                              id='outlined-basic'
                                              default='Tell the people you know best why our work matters to you! Filling in a goal, message, and photo will make your Acceleraiser feel relevant to your community'
                                              value={elementObj?.appearance?.content}
                                              onChange={(event) => {
                                                setElementObj((prevObj) => ({
                                                  ...prevObj,
                                                  appearance: {
                                                    ...prevObj.appearance,
                                                    content: event.target.value
                                                  },
                                                }));
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>
                                      </Grid>
                                      <Grid my={2}>
                                        <FormControl
                                          sx={{
                                            display: 'flex',
                                            flexDirection: {
                                              md: 'row',
                                              sm: 'row',
                                              xs: 'column'
                                            },
                                            justifyContent: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <Grid
                                            item
                                            md={4}
                                            sm={4}
                                            xs={12}
                                            mr={2}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Typography
                                              my={{ md: 0, sm: 0, xs: 1 }}
                                              sx={{
                                                textWrap: 'nowrap',
                                                textAlign: {
                                                  md: 'right',
                                                  sm: 'right',
                                                  xs: 'left'
                                                },
                                                width: '100%'
                                              }}
                                            >
                                              Default Goal
                                            </Typography>
                                          </Grid>
                                          <Grid item md={8} sm={8} xs={12}>
                                            <TextField
                                              size='small'
                                              variant='outlined'
                                              default='$500'
                                              className={classes.select}
                                              value={elementObj?.behaviour?.goal}
                                              onChange={(event) => {
                                                setElementObj(prevObj => ({
                                                  ...prevObj,
                                                  behaviour: {
                                                    ...prevObj?.behaviour,
                                                    goal: event.target.value
                                                  }
                                                }))
                                              }}
                                              sx={{
                                                justifyContent: 'flex-start !important',
                                                width: '60% !important',
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                '& .MuiOutlinedInput-input': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000',
                                                  '&::placeholder': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  }
                                                },
                                                '& .MuiInputLabel-root': {
                                                  color:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#000'
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                  borderColor:
                                                    themeMode === 'dark'
                                                      ? '#ffff'
                                                      : '#0F0F0F'
                                                }
                                              }}
                                            ></TextField>
                                          </Grid>
                                        </FormControl>

                                        <Grid my={3}>
                                          <FormControl
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              width: '100%'
                                            }}
                                          >
                                            <Grid
                                              item
                                              md={4}
                                              sm={4}
                                              xs={4}
                                              mr={2}
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  textWrap: 'nowrap',
                                                  textAlign: 'right',
                                                  width: '100%',
                                                  fontSize: {
                                                    md: '16px',
                                                    sm: '14px',
                                                    xs: '12px'
                                                  }
                                                }}
                                              >
                                                URL
                                              </Typography>
                                            </Grid>
                                            <Grid item md={8} sm={8} xs={8}>
                                              <TextField
                                                size='small'
                                                variant='outlined'
                                                default='Select a Campaign'
                                                value={elementObj?.donationUrl || `https://www.accceleraise.com/campaign-donation/0/${elementObj?.campaignUuid}`}
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                                className={classes.select}
                                                sx={{
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  '& .MuiOutlinedInput-input': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000',
                                                    '&::placeholder': {
                                                      color:
                                                        themeMode === 'dark'
                                                          ? '#ffff'
                                                          : '#000'
                                                    }
                                                  },
                                                  '& .MuiInputLabel-root': {
                                                    color:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#000'
                                                  },
                                                  '& .MuiOutlinedInput-notchedOutline':
                                                  {
                                                    borderColor:
                                                      themeMode === 'dark'
                                                        ? '#ffff'
                                                        : '#0F0F0F'
                                                  }
                                                }}
                                              ></TextField>
                                            </Grid>
                                          </FormControl>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              ) : selectElement.id === 20 && selectTabs === 1 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : selectElement.id === 20 && selectTabs === 2 ? (
                                <>
                                  <QuestionSearch />
                                </>
                              ) : selectElement.id === 21 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Campaigns
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Currency
                                        </Typography>
                                      </Grid>

                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          required
                                          select
                                          fullWidth
                                          size='large'
                                          className={classes.select}
                                          variant='outlined'
                                          id='outlined-select-currency'
                                          defaultValue={'USD'}
                                          name='Currency'
                                          value={elementObj?.behaviour?.currency}
                                          onChange={(event) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                currency: event.target.value
                                              }
                                            }))
                                          }}
                                        >
                                          {currencies?.map(option => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                              className={classes.menuItem}
                                              sx={{
                                                textAlign: 'left'
                                              }}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        >
                                          Minimum Amount
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start'
                                        }}
                                      >
                                        <TextField
                                          size='small'
                                          variant='outlined'
                                          default='$0'
                                          className={classes.select}
                                          value={elementObj?.appearance?.minimumAmount}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                minimumAmount: event.target.value
                                              },
                                            }));
                                          }}
                                          sx={{
                                            width: '40% !important',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Acceleraiser's name
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.behaviour?.acceleraisers_name || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                acceleraisers_name : event.target.value
                                              }
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'acceleraiser_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Acceleraiser name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'admins_full_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Admin's full name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'admins_first_name, last_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Admin's first name, last name
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'admins_first_name'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111',
                                              '&:hover': {
                                                background: '#659500',
                                                color: '#FFF'
                                              }
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Admin's first name
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <SlidingElement
                                    label='Maximum rows'
                                    value={acceleraiserLimiter}
                                    defaultValue={acceleraiserLimiter}
                                    max={10}
                                    min={1}
                                    onChange={(event, newValue) => {
                                      setAcceleraiserLimiter(newValue)
                                    }}
                                  />
                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 21 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Top Acceleraiser'
                                        className={classes.select}
                                        value={elementObj?.appearance?.title}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.textColor || '#FFFF'}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          textColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Accent Text Color'
                                    value={
                                      elementObj?.appearance?.labelColor || '#FFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Background Color'
                                    value={elementObj?.appearance?.backgroundColor || '#FFFF'}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={15}
                                    max={28}
                                    min={12}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={elementObj?.appearance?.borderColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 22 && selectTabs === 0 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          width: 'fit-content'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Open Campaign
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          placeholder='Select a Campaign'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={elementObj?.campaignUuid || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              campaignUuid: event.target.value
                                            }))
                                          }
                                        >
                                          {campaignList?.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.uuid}
                                              className={classes.menuItem}
                                              sx={{
                                                color:
                                                  themeMode === 'dark' ? '#FFF' : '#111'
                                              }}
                                            >
                                              <Typography sx={{ fontSize: '14px' }}>
                                                {item.title}
                                              </Typography>
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Frequency
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultFrequency || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultFrequency: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_one_time'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default One Time
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'default_monthly'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Default Monthly
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Designation
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='match_checkout_setting'
                                          value={elementObj?.designation}
                                          onChange={event => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              designation: event.target.value
                                            }))
                                          }}
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={4}
                                        mr={2}
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Default Amount
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={8}>
                                        <TextField
                                          select
                                          size='small'
                                          variant='outlined'
                                          default='MatchCheckoutSettings'
                                          className={classes.select}
                                          sx={{
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000'
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor:
                                                themeMode === 'dark'
                                                  ? '#ffff'
                                                  : '#0F0F0F'
                                            }
                                          }}
                                          SelectProps={{
                                            MenuProps: {
                                              PaperProps: {
                                                style: {
                                                  backgroundColor:
                                                    themeMode === 'dark'
                                                      ? '#0D0D0D'
                                                      : '#ECECEC',
                                                  maxHeight: 150,
                                                  Width: '70%',
                                                  scrollbarWidth: 'thin',
                                                  scrollbarColor:
                                                    themeMode === 'dark'
                                                      ? '#8ED003CC #0F0F0F'
                                                      : '#8ED003 #E2E2E2',
                                                  '&:hover': {
                                                    background: '#659500',
                                                    color: '#FFF'
                                                  }
                                                }
                                              }
                                            }
                                          }}
                                          value={elementObj?.defaultAmount || ''}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              defaultAmount: event.target.value
                                            }))
                                          }
                                        >
                                          <MenuItem
                                            value={'match_checkout_setting'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Match Checkout Settings
                                            </Typography>
                                          </MenuItem>
                                          <MenuItem
                                            value={'customize'}
                                            className={classes.menuItem}
                                            sx={{
                                              color:
                                                themeMode === 'dark' ? '#FFF' : '#111'
                                            }}
                                          >
                                            <Typography sx={{ fontSize: '14px' }}>
                                              Customize
                                            </Typography>
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>

                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          my={{ md: 0, sm: 0, xs: 1 }}
                                          sx={{
                                            display: 'flex',
                                            textWrap: 'nowrap',
                                            justifyContent: 'flex-end',
                                            textAlign: {
                                              md: 'right',
                                              sm: 'right',
                                              xs: 'left'
                                            },
                                            width: '100%'
                                          }}
                                        >
                                          Display Triggers
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          flexDirection: 'column'
                                        }}
                                      >
                                        <TickBox
                                          text='Page Load'
                                          caption='Allow Popup to display immediately when the webpage loads.'
                                          value={elementObj?.behaviour?.enable_on_page_load}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                enable_on_page_load: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Time Delay'
                                          caption='Allow Popup to display a few seconds after the page loads.'
                                          value={elementObj?.behaviour?.enable_on_page_load_after_seconds}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                enable_on_page_load_after_seconds: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Scroll Point'
                                          caption='Allow Popup to display when a certain percentage of the webpage has been scrolled.'
                                          value={elementObj?.behaviour?.enable_after_scroll}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                enable_after_scroll: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Inactivity'
                                          caption='Allow Popup to display a few seconds after the last cursor movement.'
                                          value={elementObj?.behaviour?.enable_after_cursor_move}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                enable_after_cursor_move: newValue
                                              }
                                            }))
                                          }}
                                        />
                                        <TickBox
                                          text='Exit-intent'
                                          caption='Allow Popup to display when the cursor has moved from the webpage to the browser toolbar (desktop only)'
                                          value={elementObj?.behaviour?.enable_after_cursor_on_toolbar}
                                          onChange={(event, newValue) => {
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              behaviour: {
                                                ...prevObj.behaviour,
                                                enable_after_cursor_on_toolbar: newValue
                                              }
                                            }))
                                          }}
                                        />
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 22 && selectTabs === 1 ? (
                                <Grid
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <Grid>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Image
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <input
                                          type='file'
                                          ref={logoImgInputRef}
                                          onChange={handleImgFileChange}
                                          style={{ display: 'none' }}
                                        />
                                        <Button
                                          className={classes.uploadButton}
                                          variant='contained'
                                          onClick={() =>
                                            handleButtonClick(logoImgInputRef)
                                          }
                                          onMouseEnter={() => setIsHovered(true)}
                                          onMouseLeave={() => setIsHovered(false)}
                                          sx={{
                                            //background: themeMode === "dark" ? "#141414" : "#ECECEC",
                                            //backgroundImage: imageUrl ? `url('${imageUrl}')`:`url('${plant}')`,
                                            backgroundImage: `url('${imageUrl}')`,
                                            backgroundSize: 'cover,cover',
                                            backgroundPosition: 'center center,10% 50%',
                                            border:
                                              themeMode === 'dark'
                                                ? '1px solid #FFF'
                                                : '1px solid #000',
                                            color:
                                              themeMode === 'dark'
                                                ? '#FFFF'
                                                : '#030303',
                                            '&:hover': {
                                              backdropFilter: 'blur(50px)',
                                              background: '#0D0D0DE5'
                                            }
                                          }}
                                        >
                                          {!imageUrl || (imageUrl && isHovered) ? (
                                            <Grid>
                                              <Typography
                                                sx={{
                                                  textTransform: 'capitalize',
                                                  fontSize: '25px',
                                                  color: '#FFF'
                                                }}
                                              >
                                                <img
                                                  src={upload}
                                                  alt=''
                                                  style={{ marginRight: '10px' }}
                                                />
                                                Upload
                                              </Typography>
                                            </Grid>
                                          ) : (
                                            ''
                                          )}
                                        </Button>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <Grid>
                                    <TickBox
                                      text='Show Image'
                                      value={elementObj?.appearance?.showImg}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            showImg: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Title
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Your most generous donation'
                                        className={classes.select}
                                        value={elementObj?.appearance?.title}
                                        onChange={(event) => {
                                          setElementObj((prevObj) => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              title: event.target.value
                                            },
                                          }));
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>
                                  <Grid my={2}>
                                    <FormControl
                                      sx={{
                                        display: 'flex',
                                        flexDirection: {
                                          md: 'row',
                                          sm: 'row',
                                          xs: 'column'
                                        },
                                        justifyContent: 'center',
                                        width: '100%'
                                      }}
                                    >
                                      <Grid
                                        item
                                        md={4}
                                        sm={4}
                                        xs={12}
                                        mr={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            textWrap: 'nowrap',
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: {
                                              md: '16px',
                                              sm: '14px',
                                              xs: '12px'
                                            }
                                          }}
                                        >
                                          Content
                                        </Typography>
                                      </Grid>
                                      <Grid item md={8} sm={8} xs={12}>
                                        <TextField
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          default='This content is usually a short paragraph explaining why the viewer should make a donation today. Given that popups interrupt site navigation until closed, make sure that it feels important!'
                                          value={elementObj?.appearance?.content}
                                          onChange={(event) => {
                                            setElementObj((prevObj) => ({
                                              ...prevObj,
                                              appearance: {
                                                ...prevObj.appearance,
                                                content: event.target.value
                                              },
                                            }));
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </FormControl>
                                  </Grid>
                                  <ColorBox
                                    label='Text Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Background Color'
                                    value={
                                      elementObj?.appearance?.backgroundColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          backgroundColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.borderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderSize: newValue
                                        }
                                      }))
                                    }}
                                  />
                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.borderRadius}
                                    defaultValue={4}
                                    max={28}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.borderColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          borderColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 22 && selectTabs === 2 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '50vh',
                                    position: 'relative',
                                    padding: '13px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: {
                                        md: 'row',
                                        sm: 'row',
                                        xs: 'column'
                                      },
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={4}
                                      xs={12}
                                      mr={2}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Typography
                                        my={{ md: 0, sm: 0, xs: 1 }}
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: {
                                            md: 'right',
                                            sm: 'right',
                                            xs: 'left'
                                          },
                                          width: '100%'
                                        }}
                                      >
                                        Label
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={8} xs={12}>
                                      <TextField
                                        size='small'
                                        variant='outlined'
                                        default='Donate'
                                        value={elementObj?.appearance?.label}
                                        className={classes.select}
                                        onChange={event => {
                                          setElementObj(prevObj => ({
                                            ...prevObj,
                                            appearance: {
                                              ...prevObj.appearance,
                                              label: event.target.value
                                            }
                                          }))
                                        }}
                                      ></TextField>
                                    </Grid>
                                  </FormControl>

                                  <ColorBox
                                    label='Label Color'
                                    value={elementObj?.appearance?.labelColor}
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          labelColor: color
                                        }
                                      }))
                                    }}
                                  />
                                  <ColorBox
                                    label='Button Color'
                                    value={
                                      elementObj?.appearance?.btnColor ||
                                      '#7CB602'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnColor: color
                                        }
                                      }))
                                    }}
                                  />


                                  <SlidingElement
                                    label='Border Size'
                                    value={elementObj?.appearance?.btnBorderSize}
                                    defaultValue={0}
                                    max={4}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderSize: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <SlidingElement
                                    label='Border Radius'
                                    value={elementObj?.appearance?.btnBorderRadius}
                                    defaultValue={6}
                                    max={44}
                                    min={0}
                                    onChange={(event, newValue) => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderRadius: newValue
                                        }
                                      }))
                                    }}
                                  />

                                  <ColorBox
                                    label='Border Color'
                                    value={
                                      elementObj?.appearance?.btnBorderColor || '#FFFF'
                                    }
                                    onChange={color => {
                                      setElementObj(prevObj => ({
                                        ...prevObj,
                                        appearance: {
                                          ...prevObj.appearance,
                                          btnBorderColor: color
                                        }
                                      }))
                                    }}
                                  />

                                  <Grid>
                                    <TickBox
                                      text='Show Shadow'
                                      value={elementObj?.appearance?.shadow}
                                      onChange={(event, newValue) => {
                                        setElementObj(prevObj => ({
                                          ...prevObj,
                                          appearance: {
                                            ...prevObj.appearance,
                                            shadow: newValue
                                          }
                                        }))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 22 && selectTabs === 3 ? (
                                <Grid
                                  sx={{
                                    overflowY: 'auto',
                                    maxHeight: '45vh',
                                    position: 'relative',
                                    padding: '5px',
                                    scrollbarWidth: 'thin',
                                    scrollbarColor:
                                      themeMode === 'dark'
                                        ? '#8ED003CC #0F0F0F'
                                        : '#8ED003 #E2E2E2'
                                  }}
                                >
                                  <FormControl
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      width: '100%'
                                    }}
                                  >
                                    <Grid
                                      item
                                      md={4}
                                      sm={3}
                                      xs={3}
                                      mr={2}
                                      sx={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <Typography
                                        sx={{
                                          textWrap: 'nowrap',
                                          textAlign: 'right',
                                          width: '100%',
                                          fontSize: {
                                            md: '16px',
                                            sm: '14px',
                                            xs: '12px'
                                          }
                                        }}
                                      >
                                        Show on
                                      </Typography>
                                    </Grid>
                                    <Grid item md={8} sm={9} xs={9}>
                                      <TextField
                                        select
                                        size='small'
                                        variant='outlined'
                                        className={classes.select}
                                        value={browser}
                                        sx={{
                                          width: {
                                            md: '85% !important',
                                            sm: '100%',
                                            xs: '100%'
                                          },
                                          backgroundColor:
                                            themeMode === 'dark'
                                              ? '#0D0D0D'
                                              : '#ECECEC',
                                          '& .MuiOutlinedInput-input': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000',
                                            '&::placeholder': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          },
                                          '& .MuiInputLabel-root': {
                                            color:
                                              themeMode === 'dark' ? '#ffff' : '#000'
                                          },
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:
                                              themeMode === 'dark' ? '#ffff' : '#0F0F0F'
                                          }
                                        }}
                                        SelectProps={{
                                          MenuProps: {
                                            PaperProps: {
                                              style: {
                                                backgroundColor:
                                                  themeMode === 'dark'
                                                    ? '#0D0D0D'
                                                    : '#ECECEC',
                                                maxHeight: 150,
                                                Width: '70%',
                                                scrollbarWidth: 'thin',
                                                scrollbarColor:
                                                  themeMode === 'dark'
                                                    ? '#8ED003CC #0F0F0F'
                                                    : '#8ED003 #E2E2E2',
                                                '&:hover': {
                                                  background: '#659500',
                                                  color: '#FFF'
                                                }
                                              }
                                            }
                                          }
                                        }}
                                        onChange={handleBrowserView}
                                      >
                                        <MenuItem
                                          value={1}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Desktop and mobile browsers
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          value={2}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Desktop browsers only
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          value={3}
                                          className={classes.menuItem}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color:
                                              themeMode === 'dark' ? '#FFF' : '#111'
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                md: '14px',
                                                sm: '12px',
                                                xs: '10px'
                                              }
                                            }}
                                          >
                                            Mobile browsers only
                                          </Typography>
                                        </MenuItem>
                                      </TextField>
                                    </Grid>
                                  </FormControl>
                                  <Grid
                                    my={4}
                                    py={3}
                                    sx={{
                                      borderTop:
                                        themeMode === 'dark'
                                          ? '1px solid #FFFF'
                                          : '1px solid #929292'
                                    }}
                                  >
                                    <Typography sx={{ textAlign: 'left' }}>
                                      By default, element is displayed on every page of
                                      your website. URL control allows you to limit the
                                      pages on which element appears.
                                    </Typography>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Only show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be shown.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                    <Grid my={3}>
                                      <FormControl
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <Grid
                                          mr={2}
                                          my={2}
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                        >
                                          <Typography
                                            sx={{
                                              textWrap: 'nowrap',
                                              fontSize: '16px'
                                            }}
                                          >
                                            Never show element at these URLs
                                          </Typography>
                                          <Grid
                                            mx={4}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <Tooltip
                                              title='Enter each URL on a new line. Specify up to 20 URLs where this element will be hidden.'
                                              arrow
                                            >
                                              <img src={question} alt='' />
                                            </Tooltip>
                                          </Grid>
                                        </Grid>
                                        <TextField
                                          //required
                                          multiline
                                          placeholder={`https://www.example.com/${'\u000A'}https://www.example.com/*/${'\u000A'}https://www.example.com/about/*`}
                                          minRows={3}
                                          mt={1}
                                          fullWidth
                                          size='small'
                                          className={classes.fullInput}
                                          variant='outlined'
                                          id='outlined-basic'
                                          name='CampaignName'
                                          value={elementObj?.name}
                                          onChange={event =>
                                            setElementObj(prevObj => ({
                                              ...prevObj,
                                              name: event.target.value
                                            }))
                                          }
                                          sx={{
                                            width: '90% !important',
                                            backgroundColor:
                                              themeMode === 'dark'
                                                ? '#0D0D0D'
                                                : '#ECECEC',
                                            '& .MuiOutlinedInput-input': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000',
                                              '&::placeholder': {
                                                color:
                                                  themeMode === 'dark'
                                                    ? '#ffff'
                                                    : '#000',
                                                fontSize: {
                                                  md: '16px',
                                                  sm: '16px',
                                                  xs: '11px'
                                                }
                                              }
                                            },
                                            '& .MuiInputLabel-root': {
                                              color:
                                                themeMode === 'dark' ? '#ffff' : '#000'
                                            }
                                          }}
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ) : selectElement.id === 22 && selectTabs === 4 ? (
                                <Grid my={2}>
                                  <Typography sx={{ margin: '35px 0px' }}>
                                    The selected campaign does not have custom fields.
                                  </Typography>
                                </Grid>
                              ) : (
                                ''
                              )}
                    </Grid>

                    <Grid
                      mt={3}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: '20px',
                          padding: '8px 15px',
                          background:
                            'radial-gradient( #8ED003 1.91%, #618F00 82.75%)',
                          '&:hover': {
                            background:
                              'radial-gradient(#618F00 95.75%,#8ED003  4.91%)'
                          }
                        }}
                        onClick={handleCreateElement}
                      >
                        <Typography
                          sx={{
                            color: '#FFF',
                            textTransform: 'capitalize',
                            fontSize: { md: '18px', sm: '16px', xs: '14px' }
                          }}
                        >
                          {!selectedElement
                            ? 'Create An Element'
                            : 'Update An Element'}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {selectElement.display ? (
                <Grid
                  item
                  md={4}
                  sm={5}
                  xs={12}
                  sx={{
                    transform: {
                      md: 'scale(0.75)',
                      sm: 'scale(0.75)',
                      xs: 'scale(0.8)'
                    },
                    padding: '0px 10px',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    background:
                      themeMode === 'dark' ? '#0D0D0DE5' : '#ECECECDD',
                    color: themeMode === 'dark' ? '#FFFF' : '#030303',
                    boxShadow:
                      themeMode === 'dark'
                        ? '0px 0px 0px red'
                        : '0px 8px 5px 0px #00000040',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    height: 'fit-content',

                    '&::before': {
                      content: "''",
                      position: 'absolute',
                      inset: 0,
                      borderRadius: '15px',
                      padding: '1px',
                      background:
                        themeMode === 'dark'
                          ? 'linear-gradient(145deg, white, transparent)'
                          : 'linear-gradient(180deg, white, transparent)',
                      WebkitMask:
                        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                      WebkitMaskComposite: 'xor',
                      maskComposite: 'exclude'
                    }
                  }}
                >
                  <Grid
                    my={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#333232',
                        width: '40%',
                        height: '9px',
                        borderRadius: '20px'
                      }}
                    ></Box>
                  </Grid>
                  <Grid
                    sx={{
                      width: '100%',
                      padding: '5px',
                      backgroundColor: themeMode==="dark"? '#0000003B':"#E3E3E3",
                      borderRadius: '10px',
                      minHeight: '90vh',
                      maxHeight: '95vh',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      position: 'relative',
                      border:themeMode==="dark"?"1px solid transparent":"1px solid grey"
                    }}
                  >
                    {selectElement.id === 0 ||
                      selectElement.id === 5 ||
                      selectElement.id === 6 ||
                      selectElement.id === 8 ||
                      selectElement.id === 16 ||
                      selectElement.id === 19 ? (
                      <img src={donateLines} alt='' />
                    ) : selectElement.id === 3 ||
                      selectElement.id === 7 ||
                      selectElement.id === 12 ||
                      selectElement.id === 15 ? (
                      <img src={stickyLines} alt='' />
                    ) : selectElement.id === 2 ? (
                      browser === 1 || browser === 3 ? (
                        <img src={stickyLines} alt='' />
                      ) : browser === 2 ? (
                        <Grid item md={9}>
                          <img src={noPhone} alt='' />
                          <Typography sx={{ color: '#8a8a8a' }}>
                            This element is hidden for mobile browsers. Please
                            check the desktop view, or change the show on option
                            selected in the visibility tab.
                          </Typography>
                        </Grid>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}

                    {selectElement.id === 0 ? (
                      <Grid sx={{ position: 'absolute' }}>
                        <DonateButtonElement
                          borderRadius={elementObj?.appearance?.borderRadius}
                          borderSize={elementObj?.appearance?.borderSize}
                          buttonSize={elementObj?.appearance?.size}
                          labelColor={elementObj?.appearance?.labelColor}
                          shadow={elementObj?.appearance?.shadow}
                          btnLabel={elementObj?.appearance?.label}
                          backgroundColor={
                            elementObj?.appearance?.backgroundColor
                          }
                          iconColor={elementObj?.appearance?.iconColor}
                          borderColor={elementObj?.appearance?.borderColor}
                        />
                      </Grid>
                    ) : selectElement.id === 2 ? (
                      <StickyButtonElement
                        borderRadius={elementObj?.appearance?.borderRadius}
                        borderSize={elementObj?.appearance?.borderSize}
                        buttonSize={elementObj?.appearance?.size}
                        labelColor={elementObj?.appearance?.labelColor}
                        shadow={elementObj?.appearance?.shadow}
                        btnLabel={elementObj?.appearance?.label}
                        btnAlignment={elementObj?.appearance?.btnAlignment}
                        btnPositionVert={
                          elementObj?.appearance?.btnPositionVert
                        }
                        btnPositionHorz={
                          elementObj?.appearance?.btnPositionHorz
                        }
                        stickyBtnEnable={elementObj?.appearance?.enable}
                        browser={browser}
                        backgroundColor={
                          elementObj?.appearance?.backgroundColor
                        }
                        iconColor={elementObj?.appearance?.iconColor}
                        borderColor={elementObj?.appearance?.borderColor}
                      />
                    ) : selectElement.id === 3 ? (
                      <ReminderElement
                        reminderTxtColor={elementObj?.appearance?.textColor}
                        reminderBkgColor={
                          elementObj?.appearance?.backgroundColor
                        }
                        reminderBorderSize={elementObj?.appearance?.borderSize}
                        reminderBorderRadius={
                          elementObj?.appearance?.borderRadius
                        }
                        reminderBorderColor={
                          elementObj?.appearance?.borderColor
                        }
                        reminderShadow={elementObj?.appearance?.shadow}
                        reminderIconColor={elementObj?.appearance?.iconColor}
                        reminderBtnEnable={elementObj?.appearance?.enable}
                      />
                    ) : selectElement.id === 4 ? (
                      <DonateForm 
                        title={elementObj?.appearance?.title}
                        textColor={elementObj?.appearance?.textColor}
                        backgroundColor={elementObj?.appearance?.backgroundColor}
                        iconColor={elementObj?.appearance?.iconColor}
                        borderSize={elementObj?.appearance?.borderSize}
                        borderRadius={elementObj?.appearance?.borderRadius}
                        borderColor={elementObj?.appearance?.borderColor}
                        shadow={elementObj?.appearance?.shadow}
                        activeColor={elementObj?.appearance?.activeColor}
                        label={elementObj?.appearance?.label}
                        labelColor={elementObj?.appearance?.labelColor}
                        btnColor={elementObj?.appearance?.btnColor}
                        btnBorderSize={elementObj?.appearance?.btnBorderSize}
                        btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                        btnBorderColor={elementObj?.appearance?.btnBorderColor}
                      />
                    ) : selectElement.id === 5 ? (
                      <Grid sx={{ position: 'absolute' }}>
                        <SimpleForm 
                          label={elementObj?.appearance?.label}
                          labelColor={elementObj?.appearance?.labelColor}
                          btnColor={elementObj?.appearance?.btnColor}
                          btnBorderSize={elementObj?.appearance?.btnBorderSize}
                          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                          btnBorderColor={elementObj?.appearance?.btnBorderColor}
                          shadow={elementObj?.appearance?.shadow}
                        />
                      </Grid>
                    ) : selectElement.id === 6 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <ImgCard imageUrl={imageUrl} 
                          title={elementObj?.appearance?.title} 
                          content={elementObj?.appearance?.content} 
                          width={elementObj?.appearance?.width} 
                          height={elementObj?.appearance?.height} 
                          backgroundColor={elementObj?.appearance?.backgroundColor}
                          borderSize={elementObj?.appearance?.borderSize}
                          borderRadius={elementObj?.appearance?.borderRadius}
                          shadow={elementObj?.appearance?.shadow}
                          textColor={elementObj?.appearance?.textColor}
                          btnColor={elementObj?.appearance?.btnColor}
                          labelColor={elementObj?.appearance?.labelColor}
                          label={elementObj?.appearance?.label}
                        />
                      </Grid>
                    ) : selectElement.id === 7 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <GoalMeter collected={elementObj?.amount ? Number(elementObj?.amount) : 100} remaining={elementObj?.behaviour?.goal && elementObj?.amount ? Number(elementObj?.behaviour?.goal - elementObj?.amount) : 150} themeMode={themeMode} 
                          labelColor={elementObj?.appearance?.labelColor}
                          progressBarColor={elementObj?.appearance?.progressBarColor}
                          backgroundColor={elementObj?.appearance?.backgroundColor}
                          borderSize={elementObj?.appearance?.borderSize}
                          borderRadius={elementObj?.appearance?.borderRadius}
                          goalCurrency={elementObj?.behaviour?.goalCurrency}
                        />
                      </Grid>
                    ) : selectElement.id === 8 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <StatCounter 
                          amount={elementObj?.amount } 
                          goalCurrency={elementObj?.behaviour?.goalCurrency}
                        />
                      </Grid>
                    ) : selectElement.id === 9 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          left: '30%'
                        }}
                      >
                        <ImpactSlider 
                          impactLevel={impactLevel} 
                          textColor={elementObj?.appearance?.textColor}
                          backgroundColor={elementObj?.appearance?.backgroundColor}
                          borderSize={elementObj?.appearance?.borderSize}
                          borderRadius={elementObj?.appearance?.borderRadius}
                          borderColor={elementObj?.appearance?.borderColor}
                          shadow={elementObj?.appearance?.shadow}
                          label={elementObj?.appearance?.label}
                          labelColor={elementObj?.appearance?.labelColor}
                          btnColor={elementObj?.appearance?.btnColor}
                          btnBorderSize={elementObj?.appearance?.btnBorderSize}
                          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                          btnBorderColor={elementObj?.appearance?.btnBorderColor}
                          iconColor={elementObj?.appearance?.iconColor}
                        />
                      </Grid>
                    ) : selectElement.id === 10 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          overflowY: 'auto'
                        }}
                      >
                        {customLevel.map((item, index) => (
                          <DonationLevel 
                            backgroundColor={elementObj?.appearance?.backgroundColor} 
                            textColor={elementObj?.appearance?.textColor}
                            accentTextColor={elementObj?.appearance?.accentTextColor}
                            btnSize={elementObj?.appearance?.btnSize}
                            borderColor={elementObj?.appearance?.borderColor}
                            shadow={elementObj?.appearance?.shadow}
                            labelColor={elementObj?.appearance?.labelColor}
                            btnColor={elementObj?.appearance?.btnColor}
                            btnRadius={elementObj?.appearance?.btnRadius}
                            btnBorderSize={elementObj?.appearance?.btnBorderSize}
                            btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                            btnBorderColor={elementObj?.appearance?.btnBorderColor}
                            themeMode={themeMode}
                          />
                        ))}
                      </Grid>
                    ) : selectElement.id === 11 ? (
                      <GroupBtn 
                        groupBtn={groupBtn} 
                        title={elementObj?.appearance?.title}
                        labelColor={elementObj?.appearance?.labelColor}
                        btnSize={elementObj?.appearance?.btnSize}
                        btnColor={elementObj?.appearance?.btnColor}
                        btnBorderSize={elementObj?.appearance?.btnBorderSize}
                        btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                        btnBorderColor={elementObj?.appearance?.btnBorderColor}
                        shadow={elementObj?.appearance?.shadow}
                      />
                    ) : selectElement.id === 12 ? (
                      <MessageBar 
                        title={elementObj?.appearance?.title}
                        content={elementObj?.appearance?.content}
                        textColor={elementObj?.appearance?.textColor}
                        backgroundColor={elementObj?.appearance?.backgroundColor}
                        btnPositionVert={elementObj?.appearance?.btnPositionVert}
                        show_close_button={elementObj?.appearance?.show_close_button}
                        shadow={elementObj?.appearance?.shadow}
                        label={elementObj?.appearance?.label}
                        labelColor={elementObj?.appearance?.labelColor}
                        btnColor={elementObj?.appearance?.btnColor}
                        btnBorderSize={elementObj?.appearance?.btnBorderSize}
                        btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                        btnBorderColor={elementObj?.appearance?.btnBorderColor}
                        themeMode={themeMode}
                      />
                    ) : selectElement.id === 13 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          width: '90%',
                          height: '90%',
                          borderRadius: '10px',
                          border:
                            themeMode === 'dark'
                              ? '1px solid #FFF'
                              : '1px solid #000'
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'auto',
                            width: '100%'
                          }}
                        >
                          <TopSupporter limitedSupporters={limitedSupporters} 
                            title={elementObj?.appearance?.title}
                            textColor={elementObj?.appearance?.textColor}
                            labelColor={elementObj?.appearance?.labelColor}
                            backgroundColor={elementObj?.appearance?.backgroundColor}
                            borderSize={elementObj?.appearance?.borderSize}
                            borderRadius={elementObj?.appearance?.borderRadius}
                            borderColor={elementObj?.appearance?.borderColor}
                            shadow={elementObj?.appearance?.shadow}
                            enableAmount={elementObj?.appearance?.enable_amount}
                            enableLocation={elementObj?.appearance?.enable_location}
                            themeMode={themeMode}
                          />
                        </Grid>
                      </Grid>
                    ) : selectElement.id === 14 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          width: '90%',
                          height: '90%',
                          borderRadius: '10px',
                          border:
                            themeMode === 'dark'
                              ? '1px solid #FFF'
                              : '1px solid #000'
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'auto',
                            width: '100%'
                          }}
                        >
                          <TopCountries limitedCountries={limitedCountries} 
                            title={elementObj?.appearance?.title}
                            textColor={elementObj?.appearance?.textColor}
                            labelColor={elementObj?.appearance?.labelColor}
                            backgroundColor={elementObj?.appearance?.backgroundColor}
                            borderSize={elementObj?.appearance?.borderSize}
                            borderRadius={elementObj?.appearance?.borderRadius}
                            borderColor={elementObj?.appearance?.borderColor}
                            shadow={elementObj?.appearance?.shadow}
                            themeMode={themeMode}
                          />
                        </Grid>
                      </Grid>
                    ) : selectElement.id === 15 ? (
                      <SocialProof
                        accentColor={elementObj?.appearance?.accentColor}
                        textColor={elementObj?.appearance?.textColor}
                        backgroundColor={elementObj?.appearance?.backgroundColor}
                        borderSize={elementObj?.appearance?.borderSize}
                        borderRadius={elementObj?.appearance?.borderRadius}
                        borderColor={elementObj?.appearance?.borderColor}
                        shadow={elementObj?.appearance?.shadow}
                        label={elementObj?.appearance?.label}
                        labelColor={elementObj?.appearance?.labelColor}
                        btnColor={elementObj?.appearance?.btnColor}
                        btnBorderSize={elementObj?.appearance?.btnBorderSize}
                        btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                        btnBorderColor={elementObj?.appearance?.btnBorderColor}
                        enable_emoji={elementObj?.appearance?.enable_emoji}
                        enable_location={elementObj?.appearance?.enable_location}
                        themeMode={themeMode}
                      />
                    ) : selectElement.id === 16 ? (
                      <Grid sx={{ position: 'absolute' }}>
                        <QRCodeImg 
                          url={elementObj?.donationUrl}
                          size={elementObj?.appearance?.size}
                          margin={elementObj?.appearance?.margin}
                          setQrBase64={setQrBase64}
                        />
                      </Grid>
                    ) : selectElement.id === 17 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          width: '90%',
                          height: '90%',
                          borderRadius: '10px',
                          border:
                            themeMode === 'dark'
                              ? '1px solid #FFF'
                              : '1px solid #000'
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'auto',
                            width: '100%'
                          }}
                        >
                          <RecentDonations limitedDonation={limitedDonation}
                            enable_amount={elementObj?.appearance?.enable_amount}
                            enable_location={elementObj?.appearance?.enable_location}
                            enable_comment={elementObj?.appearance?.enable_comment}
                            enable_donation_date={elementObj?.appearance?.enable_donation_date}
                            show_currency={elementObj?.behaviour?.show_currency}
                            currency={elementObj?.behaviour?.currency}
                            enable_donate_button={elementObj?.appearance?.enable_donate_button}
                            title={elementObj?.appearance?.title}
                            backgroundColor={elementObj?.appearance?.backgroundColor}
                            textColor={elementObj?.appearance?.textColor}
                            accentColor={elementObj?.appearance?.accentColor}
                            borderSize={elementObj?.appearance?.borderSize}
                            borderRadius={elementObj?.appearance?.borderRadius}
                            borderColor={elementObj?.appearance?.borderColor}
                            shadow={elementObj?.appearance?.shadow}
                            show_animation={elementObj?.appearance?.show_animation}
                            themeMode={themeMode}
                          /> 
                        </Grid>
                      </Grid>
                    ) : selectElement.id === 18 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <MyMap size={elementObj?.appearance?.height} />
                      </Grid>
                    ) : selectElement.id === 19 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <P2PButton 
                          content={elementObj?.appearance?.content}
                          label={elementObj?.appearance?.label}
                          btnSize={elementObj?.appearance?.btnSize}
                          labelColor={elementObj?.appearance?.labelColor}
                          btnColor={elementObj?.appearance?.btnColor}
                          btnBorderSize={elementObj?.appearance?.btnBorderSize}
                          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                          btnBorderColor={elementObj?.appearance?.btnBorderColor}
                          shadow={elementObj?.appearance?.shadow}
                        />
                      </Grid>
                    ) : selectElement.id === 21 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          width: '90%',
                          height: '90%',
                          borderRadius: '10px',
                          border:
                            themeMode === 'dark'
                              ? '1px solid #FFF'
                              : '1px solid #000'
                        }}
                      >
                        <Grid
                          sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            overflowY: 'auto',
                            width: '100%'
                          }}
                        >
                          {/* <Typography
                            sx={{
                              fontSize: '28px',
                              fontWeight: '600',
                              padding: '8px'
                            }}
                          >
                            Top Acceleraiser
                          </Typography> */}
                          <TopAcceleraiser limitedAcceleraiser={limitedAcceleraiser} 
                            title={elementObj?.appearance?.title}
                            textColor={elementObj?.appearance?.textColor}
                            labelColor={elementObj?.appearance?.labelColor}
                            backgroundColor={elementObj?.appearance?.backgroundColor}
                            borderSize={elementObj?.appearance?.borderSize}
                            borderRadius={elementObj?.appearance?.borderRadius}
                            borderColor={elementObj?.appearance?.borderColor}
                            shadow={elementObj?.appearance?.shadow}
                            themeMode={themeMode}
                          />
                        </Grid>
                      </Grid>
                    ) : selectElement.id === 22 ? (
                      <Grid
                        sx={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <Popup 
                          imageUrl={imageUrl} 
                          showImg={elementObj?.appearance?.showImg}
                          title={elementObj?.appearance?.title}
                          content={elementObj?.appearance?.content}
                          labelColor={elementObj?.appearance?.labelColor}
                          backgroundColor={elementObj?.appearance?.backgroundColor}
                          borderSize={elementObj?.appearance?.borderSize}
                          borderRadius={elementObj?.appearance?.borderRadius}
                          borderColor={elementObj?.appearance?.borderColor}
                          label={elementObj?.appearance?.label}
                          btnColor={elementObj?.appearance?.btnColor}
                          btnBorderSize={elementObj?.appearance?.btnBorderSize}
                          btnBorderRadius={elementObj?.appearance?.btnBorderRadius}
                          btnBorderColor={elementObj?.appearance?.btnBorderColor}
                          shadow={elementObj?.appearance?.shadow}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid
                    my={1}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#689900',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%'
                      }}
                    ></Box>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}
    </>
  )
}
export default CreateElement
