import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
  },
  highlighted: {
    backgroundColor: "#3f3f17",
  },
  optionsContainer: {
    position: "absolute",
    maxHeight: "300px",
    overflowY: "auto",
    backgroundColor: "#0F0F0F",
    zIndex: "999",
    borderRadius: "10px"
  },
  optionsItem: {
    paddingBottom: "10px",
    minWidth: '300px',
  },
}));

const SearchArticles = ({ placeholderText }) => {
  const classes = useStyles();
  const themeMode = useSelector(state => state.themeMode.mode)
  const [query, setQuery] = useState("");

  const handleSearch = async () => {
    try {
      // Replace the axios request with your actual search logic
      console.log(`Searching for: ${query}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid sx={{ width: { xs: "100%" } }}>
      <TextField
        className={classes.searchInput}
        style={{
          borderRadius: "50px",
          border: "1px solid #689900",
          padding: "0px 30px",
          backgroundColor: themeMode === "dark" ? "transparent" : "#FFFF" ,
          "& .MuiOutlinedInput-input": {
            color: themeMode === "dark" ? "#FFFFFF" : "#030303",
            "&::placeholder": {
              color: themeMode === "dark" ? "#FFFFFF" : "#242424",
            },
          },

        }}
        variant="standard"
        fullWidth
        placeholder={placeholderText}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton onClick={handleSearch}>
              <SearchIcon
                sx={{
                  color: "#9CCA3C",
                  borderRadius: "50px",
                  "&:hover": {
                    color: "#689900",
                  },
                }}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          style: {
            color: themeMode === "dark" ? "#FFF" : "#689900",
            "&:hover": {
              color: themeMode === "dark" ? "#FFF" : "#689900",
            },
          }
        }}sra
      />
    </Grid>
  );
};

export default SearchArticles;
