import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { Grid,MenuItem,Typography,TextField,Button } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: "50px",
    fontSize:"16px"
  },
  menuItem: {
    fontWeight: '600',
    backgroundColor:"transparent",
    '&:hover': {
      background: '#659500',
      color: '#FFF'
    },
    '&:focus': {
      color: '#659500',
      background: '#FFF',
    }
  },
}));

const ElementSearch = () => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const handleSearch = async () => {
    try {
      console.log(`Searching for: ${query}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid sx={{ width: { xs: "100%" } }}>
  <TextField
    style={{
      borderRadius: "50px",
      padding: "0px 30px",
      backgroundColor: "transparent",
    }}
    variant="standard"
    fullWidth
    placeholder="Quick Search"
    InputProps={{
      disableUnderline: true,
      startAdornment: (
        <IconButton onClick={handleSearch}>
          <SearchIcon
            sx={{
              color: "#9CCA3C",
              borderRadius: "50px",
              "&:hover": {
                color: "#689900",
              },
            }}
          />
        </IconButton>
      ),
    }}
    inputProps={{
      style: {
        color:themeMode==="dark"? "#FFFF":"#030303",
      },
    }}
    className={classes.searchInput}
  />
</Grid>

  );
};


const QuestionSearch = () => {
  const classes = useStyles();
  const [question, setQuestion] = useState([]);
  
  const themeMode = useSelector((state) => state.themeMode.mode);
  const handleQuesSearch = async () => {
    try {
      console.log(`Searching for: ${question}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid sx={{ width: { xs: "100%" } }}>
      <TextField
        select
        style={{
          borderRadius: "0px",
          padding: "10px 30px",
          backgroundColor: "transparent",
        }}
        variant="standard"
        fullWidth
        placeholder="Find or create a new question"
        sx={{
          borderTop:"1px solid #FFF",
          borderBottom: "1px solid #FFF",
          backgroundColor: "transparent",
          //   themeMode === 'dark'
          //     ? '#0D0D0D'
          //     : '#ECECEC',
          '& .MuiOutlinedInput-input': {
            color:
              themeMode === 'dark' ? '#ffff' : '#000',
            '&::placeholder': {
              color:
                themeMode === 'dark'
                  ? '#ffff'
                  : '#000'
            }
          },
          '& .MuiInputLabel-root': {
            color:
              themeMode === 'dark' ? '#ffff' : '#000'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              themeMode === 'dark'
                ? '#ffff'
                : '#0F0F0F'
          }
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                // backgroundColor:
                //   themeMode === 'dark'
                //     ? '#0D0D0D'
                //     : '#ECECEC',
                maxHeight: 150,
                Width: '20%',
                scrollbarWidth: 'thin',
                scrollbarColor:
                  themeMode === 'dark'
                    ? '#8ED003CC #0F0F0F'
                    : '#8ED003 #E2E2E2',
                '&:hover': {
                  background: '#659500',
                  color: '#FFF'
                }
              }
            }
          }
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <IconButton onClick={handleQuesSearch}>
              <SearchIcon
                sx={{
                  color: "#9CCA3C",
                  borderRadius: "50px",
                  "&:hover": {
                    color: "#689900",
                  },
                }}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          style: {
            color: themeMode === "dark" ? "#FFFF" : "#030303",
          },
        }}
        className={classes.searchInput}
      >
        <MenuItem
          value={'new_ques'}
          //className={classes.menuItem}
          sx={{
          
          }}
        >
          <Button sx={{
            backgroundColor: "transparent",
            fontSize: '14px',width:"100%",
            color:
              themeMode === 'dark' ? '#FFF' : '#111',
            '&:hover': {
              background: '#659500',
              color: '#FFF'
            },textTransform:"capitalize" }}>
            + Create a new question
          </Button>
        </MenuItem>
        {
          question?.map((item,index)=>(
            <MenuItem key={index}
              value={'new_ques'}
              className={classes.menuItem}
              sx={{
                backgroundColor: "transparent",
                color:
                  themeMode === 'dark' ? '#FFF' : '#111'
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>
                {item}
              </Typography>
            </MenuItem>
          ))
        }
      </TextField>
    </Grid>

  );
};

export {QuestionSearch, ElementSearch};
