import * as React from "react";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    Grid,
    Typography,
    Button,
    TextField,
    InputAdornment,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Menu,
    Paper
} from "@mui/material";
import { useSelector } from "react-redux";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStyles } from "../useStyles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings';
import ThemeModeSwitch from "../../../../../../utils/themeModeSwitch";
import { tableDonations,donationRow,tableTeamPerf,teamPerfRow } from "./previewData";
import { FacebookRounded, LinkedIn, MailOutline, Twitter } from "@mui/icons-material";


//import user from "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/user.svg"
//import edit from "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/editLgIcon.svg"
const editLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/editLight.svg"
const fireworks = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/fireworks.svg"
const growth = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/growth.svg"
const views = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/views.svg"
const donations = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/donations.svg"
const map = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/map.svg"
const mapDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/mapDark.svg"
const qr = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/qr.svg"
const qrLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/qrLight.svg"
const copy = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/Copy.svg"
const flag = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/flag.svg"
const comment = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/comment.svg"
const user ="https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/user.svg"
const edit ="https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/new/editLgIcon.svg"


const downWhite = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downWhite.svg";
const downBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downBlack.svg";

export default function P2PPreviewDialog({ type, mode }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = React.useState(false);
    const themeMode = useSelector((state) => state.themeMode.mode);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <React.Fragment>
            <LoadingButton
                loading={false}
                loadingPosition="start"
                startIcon={<EyeIcon />}
                variant="outlined"
                className={classes.preview}
                onClick={handleClickOpen}
                sx={{
                    padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    "&:hover": {
                        backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    },
                }}
            >
                Preview
            </LoadingButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth={true}
                sx={{background:themeMode==="dark"?"#030303":"#FFF"}}
            >
                <DialogContent sx={{ 
                    padding: { md: "20px", sm: "20px", xs: "10px" }, 
                    background:themeMode==="dark"? "#030303":"#FFF",
                    overflowX:"hidden",
                    scrollbarWidth: 'thin',
                    scrollbarColor:
                        themeMode === 'dark'
                            ? '#8ED003CC #0F0F0F'
                            : '#8ED003 #E2E2E2',
                    }}>
                    
                    <Grid container sx={{
                            display: "flex", 
                            flexDirection: {md:"row",sm:"row",xs:"column-reverse"}, 
                            padding: {md:"20px",sm:"20px",xs:"10px"}, 
                            background: themeMode === "dark" ? "#1C1C1C" : "#E2E2E2", 
                            boxShadow:
                                themeMode === 'dark'
                                    ? '0px 0px 0px red'
                                    : '0px 8px 5px 0px #00000040',
                            borderRadius:"15px"
                        }}>
                        <Grid item md={9} sm={8} xs={12} mt={{md:0,sm:0,xs:3}}>
                            <Typography sx={{fontSize:{md:"30px",sm:"30px",xs:"28px"},fontWeight:"500",textAlign:{md:"left",sm:"left",xs:"center"}}}>
                                Imam Connect Welfare Grant
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button sx={{ borderRadius: "15px", "&:hover": { backgroundColor: "transparent" } }} onClick={handleMenuOpen}>
                                <Typography sx={{ fontSize:{md: "25px",sm:"20px",xs:"15px"}, fontWeight: "300",textAlign:"right",display:"flex",alignItems:"center",justifyContent:"flex-end",textTransform:"capitalize",color:themeMode==="dark"?"#FFF":"#030303" }}>
                                    <img src={user} alt="" style={{ width: "35px", marginRight: "10px"}}/>
                                    John Doe
                                    <img src={themeMode==="dark"?downWhite:downBlack} alt="" style={{marginLeft:"10px"}}/>
                                </Typography>
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={(e) => {
                                    setAnchorEl(null);
                                }}
                                PaperComponent={Paper}
                                className={classes.paperLight}
                                sx={{
                                    "& .MuiPaper-root": {
                                        borderRadius:"15px",
                                        width: { md: "16%", sm: "30%", xs: "70%" },
                                        backgroundImage: "none",
                                        backgroundColor:
                                            themeMode === "dark" ? "#1C1C1C" : "#dbd7d7",
                                    },
                                }}
                            >

                                <MenuItem
                                    className={classes.menuItem}
                                    sx={{
                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                        "&:hover": {
                                            backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",

                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: "left",
                                            borderBottom: "1px solid #FFF",
                                            padding: "15px 15px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        Theme: {themeMode === "dark" ? "Dark" : "Light"}
                                        <ThemeModeSwitch />
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={(e) => {
                                        setAnchorEl(null);
                                    }}
                                    component={Link}
                                    to="/home"
                                    sx={{
                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                        "&:hover": {
                                            backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",
                                            color: themeMode === "dark" ? "#689900" : "#8ED003",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: "left",
                                            borderBottom: "1px solid #FFF",
                                            padding: "15px 15px",
                                        }}
                                    >
                                        <HomeIcon style={{ margin: "0px 15px 0px 0px" }} />
                                        Home
                                    </Typography>
                                </MenuItem>
                                <MenuItem
                                    className={classes.menuItem}
                                    onClick={(e) => {
                                        setAnchorEl(null);
                                    }}
                                    component={Link}
                                    to="/dashboard/settings"
                                    sx={{
                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                        "&:hover": {
                                            backgroundColor: themeMode === "dark" ? "#2222" : "#FFF",
                                            color: themeMode === "dark" ? "#689900" : "#8ED003",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: "left",
                                      //      borderBottom: "1px solid #FFF",
                                            padding: "15px 15px",
                                        }}
                                    >
                                        <SettingsIcon style={{ margin: "0px 15px 0px 0px" }} />
                                        Settings
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                        
                    <div
                        className="flex space-x-2 items-center cursor-pointer mt-8"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <KeyboardBackspaceIcon />
                        <Typography sx={{fontSize:{md:"15px",sm:"15px",xs:"12px"},fontWeight:"400"}}>
                            Back to Home
                        </Typography>
                    </div>

                    <Grid container sx={{ display: "flex", flexDirection: "container", padding: { md: "20px", sm: "20px", xs: "10px" }, }}>
                        <Grid item md={9} sm={8} my={{md:0,sm:0,xs:1}}>
                            <Typography sx={{ fontSize:{md: "30px",sm:"25px",xs:"20px"}, fontWeight: "500",width:{md:"50%",sm:"80%"},textAlign:{md:"left",sm:"left",xs:"center"} }}>
                                $3,500 USD Fundraiser for
                                Emergency relief
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12} my={{ md: 0, sm: 0, xs: 1 }}
                            sx={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                            <Button sx={{borderRadius:"30px",padding:"8px 15px",display:"flex",flexWrap:"nowrap"}}>
                                <Typography sx={{ fontSize: { md: "25px", sm: "20px", xs: "15px" } ,fontWeight: "300", textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end",textTransform:"capitalize",color:themeMode==="dark"?"#FFF":"#030303",textWrap:"nowrap" }}>
                                    <img src={themeMode==="dark" ? edit : editLight } alt="" style={{ width: "35px",marginRight:"10px" }} />
                                    Edit Fundraiser
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>


                    <Grid container sx={{ display: "flex", flexDirection: "column", borderRadius: "16px 16px 0px 0px" }}>
                        <Grid item md={12} sm={12} xs={12} sx={{ borderRadius:"16px 16px 0px 0px"}}>
                            <img src={fireworks} alt="" style={{borderRadius:"16px 16px 0px 0px"}}/>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}
                            sx={{
                                borderRadius:"0px 0px 15px 15px",
                                backgroundColor:
                                    themeMode === "dark" ? "#1C1C1C" : "#dbd7d7", 
                                boxShadow:
                                    themeMode === 'dark'
                                        ? '0px 0px 0px red'
                                        : '0px 8px 5px 0px #00000040',
                                padding:{md:"30px",sm:"30px",xs:"10px"} }}>
                            <Typography sx={{ fontSize: {md:"30px",sm:"25px",xs:"20px"}, fontWeight: "300", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>    
                                You Have Reached Your Goal! 🎉
                            </Typography>
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: { md: "30px", sm: "30px", xs: "10px" } }}>
                                <Typography sx={{ textAlign: "center", width:{md: "60%",sm:"85%",xs:"100%"}, fontWeight: "300", fontSize: {md:"20px",sm:"15px",xs:"13px"}, lineHeight:{md:"36px",sm:"30px",xs:"25px"} }}>
                                    Our thanks to you for all your help, and our deepest appreciation for empowering us to make a difference!
                                    While your goal has been reached, anyone still looking to give can contribute to your fundraiser.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container sx={{ display: "flex", flexDirection: "row", padding: "20px 0px",justifyContent:{md:"space-between",sm:"center",xs:"center"},flexWrap:"wrap"}}>
                        <Grid item md={3.5} sm={5} xs={12} my={{ md: 0, sm: 1, xs: 1 }} mx={{ md: 0, sm: 1, xs: 0 }}
                            sx={{
                                backgroundColor:
                                    themeMode === "dark" ? "#1C1C1C" : "#dbd7d7", 
                                boxShadow:
                                    themeMode === 'dark'
                                        ? '0px 0px 0px red'
                                        : '0px 8px 5px 0px #00000040',
                                padding:{md:"25px",sm:"20px",xs:"15px"},
                                display:"flex",
                                flexDirection: "row",
                                alignItems:"center",
                                borderRadius:"15px" 
                            }}>
                            <Grid mr={1} sx={{ width: "fit-content", height: "fit-content", background:"#CEFFF3",display:"flex",justifyContent:"center",alignItems:"center",padding:"15px",borderRadius:"7px"}}>
                                <img src={growth} alt=""/>
                            </Grid>
                            <Typography sx={{width:"100%",textAlign:"center", fontSize: {md:"24px",sm:"20px",xs:"15px"}, fontWeight: "300",textWrap:"nowrap" }}>
                                $3,925.40 raised
                            </Typography>
                        </Grid>

                        <Grid item md={3.5} sm={5} xs={12} my={{ md: 0, sm: 1, xs: 1 }} mx={{ md: 0, sm: 1, xs: 0 }}
                        sx={{
                            backgroundColor:
                                themeMode === "dark" ? "#1C1C1C" : "#dbd7d7",
                            boxShadow:
                                themeMode === 'dark'
                                    ? '0px 0px 0px red'
                                    : '0px 8px 5px 0px #00000040',
                            padding: { md: "25px", sm: "20px", xs: "15px" },
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: "15px"
                        }}>
                            <Grid mr={1} sx={{ width: "fit-content", height: "fit-content", background: "#C2FFAC", display: "flex", justifyContent: "center", alignItems: "center", padding: "15px", borderRadius: "7px" }}>
                                <img src={views} alt="" />
                            </Grid>
                            <Typography sx={{ width: "100%", textAlign: "center", fontSize: { md: "24px", sm: "20px", xs: "15px" }, fontWeight: "300", textWrap: "nowrap" }}>
                                12 page views
                            </Typography>
                        </Grid>

                        <Grid item md={3.5} sm={5} xs={12} my={{ md: 0, sm: 1, xs: 1 }} mx={{ md: 0, sm: 1, xs: 0 }}
                        sx={{
                            backgroundColor:
                                themeMode === "dark" ? "#1C1C1C" : "#dbd7d7",
                            boxShadow:
                                themeMode === 'dark'
                                    ? '0px 0px 0px red'
                                    : '0px 8px 5px 0px #00000040',
                            padding: { md: "25px", sm: "20px", xs: "15px" },
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: "15px"
                        }}>
                            <Grid mr={1} sx={{ width: "fit-content", height: "fit-content", background: "#E9D3FF", display: "flex", justifyContent: "center", alignItems: "center", padding: "15px", borderRadius: "7px" }}>
                                <img src={donations} alt="" />
                            </Grid>
                            <Typography sx={{ width: "100%", textAlign: "center", fontSize: { md: "24px", sm: "20px", xs: "15px" }, fontWeight: "300", textWrap: "nowrap" }}>
                                7 donations
                            </Typography>
                        </Grid>                        
                    </Grid>

                    <Grid container sx={{ display: "flex", flexDirection: "row", padding: "20px", justifyContent: "center", }}>
                        <img src={themeMode==="dark" ?mapDark:map} alt=""/>
                    </Grid>

                    <Typography my={2} sx={{fontSize:{md:"28px",sm:"24px",xs:"20px"},fontWeight:"500",textAlign:{md:"left",sm:"left",xs:"center"}}}>
                        How Far Are You From Your Goal
                    </Typography>
                        
                    <Grid container 
                        sx={{
                            backgroundColor:
                                themeMode === "dark" ? "#1C1C1C" : "#dbd7d7",
                            boxShadow:
                                themeMode === 'dark'
                                    ? '0px 0px 0px red'
                                    : '0px 8px 5px 0px #00000040', 
                            padding: {md:"25px 50px",sm:"25px 50px",xs:"15px"}, 
                            display: "flex", 
                            flexDirection: "column", 
                            alignItems: "flex-start", 
                            borderRadius: "15px" }}>
                        <Typography sx={{fontSize:{xs:"13px"}}}>
                            $3,925.40 total raised
                        </Typography>
                        <Grid item md={12} sx={{display:"flex",flexDirection:"row",width:"100%"}}>
                            <Grid sx={{
                                width:"70%",//Enter value Here
                                height:"35px",
                            }}>

                            </Grid>
                            <Grid sx={{padding:"0px"}}>
                                <img src={flag} alt=""/>
                            </Grid>

                        </Grid>

                        <Grid item   
                            sx={{
                                display:"flex",
                                flexDirection:"row",
                                padding:"0px",
                                height:"35px",
                                borderRadius:"20px",
                                width:"100%" // Total donation Goal
                            }}>

                            <Grid item sx={{width:"25%", backgroundColor:"#0E3E85",height:"100%",borderRadius:"20px 0px 0px 20px"}}></Grid>
                            <Grid item sx={{width:"20%", backgroundColor: "#3873CB", height: "100%",borderLeft:"5px solid #FFF",borderRight:"5px solid #FFF"  }}></Grid>
                            <Grid item sx={{width:"10%", backgroundColor: "#7AACF6", height: "100%",  }}></Grid>
                            <Grid item sx={{width:"7%", backgroundColor: "#939BA7", height: "100%", borderLeft:"5px solid #FFF",borderRight:"5px solid #FFF"  }}></Grid>
                            <Grid item sx={{width:"13%", backgroundColor: "#4DE8C3", height: "100%",borderRight:"5px solid #FFF" }}></Grid>
                            <Grid item sx={{width:"25%", backgroundColor: "#FFAD08", height: "100%",borderRadius:"0px 20px 20px 0px" }}></Grid>
                        </Grid>
                        <Grid mt={1} sx={{display:"flex",flexDirection:"row",width:"100%"}}>
                            <Grid sx={{display:"flex",justifyContent:"flex-end",
                                width: "80%",//Enter value Here
                            }}>
                                <Typography sx={{ fontSize: { xs: "13px" } }}>
                                    112.1 %
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography my={2} sx={{ fontSize: { md: "28px", sm: "24px", xs: "20px" }, fontWeight: "500", textAlign: { md: "left", sm: "left", xs: "center" } }}>
                        Share Your Fundraiser
                    </Typography>

                    <Grid container 
                        sx={{ 
                            backgroundColor:
                                themeMode === "dark" ? "#1C1C1C" : "#dbd7d7",
                            boxShadow:
                                themeMode === 'dark'
                                    ? '0px 0px 0px red'
                                    : '0px 8px 5px 0px #00000040', 
                            padding: { md: "25px", sm: "20px", xs: "15px" },
                            display: "flex", 
                            flexDirection: "row", 
                            alignItems: "center", 
                            borderRadius: "15px" 
                        }}>
                        <Grid item md={12} sm={12} sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"},alignItems:"center"}}>
                            <Grid item md={2} sm={2}>
                                <Typography sx={{width:"100%",textAlign:"center",fontSize:"20px",fontWeight:"400"}}>
                                    Direct Link
                                </Typography>
                            </Grid>
                            <Grid item md={8} sm={7} xs={12} my={{md:0,sm:0,xs:2}}>
                            <TextField
                                fullWidth
                                size="medium"
                                variant="outlined"
                                id="outlined-basic"
                                placeholder="https://aegjehbt.donorsupport.co/-/NXXXXXXX"
                                // value={campaignObj?.title || ""}
                                // onChange={(event) =>
                                //     setCampaignObj((prevObj) => ({
                                //         ...prevObj,
                                //         title: event.target.value,
                                //     }))
                                // }
                                //className={classes.input}
                                sx={{
                                    borderRadius: "15px",
                                    width: "100%",
                                    "& .MuiInputLabel-root": {
                                        fontSize: {md:"16px",sm:"14px",xs:"12px"},
                                        marginBottom: "20px",
                                        transform: "translate(0px, -100%) scale(0.85)",
                                        "&.MuiInputLabel-shrink": {
                                            transform: "translate(0px, -100%) scale(0.85)",
                                        },
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: "0.5px",
                                        borderRadius: "5px",
                                    },
                                    "&:focus": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "red",
                                        },
                                        outline: "none",
                                    },
                                    backgroundColor:
                                        themeMode === "dark" ? "#0D0D0D" : "#ECECEC",
                                    "& .MuiInputLabel-root": {
                                        color: themeMode === "dark" ? "#ffff" : "#000",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        color: themeMode === "dark" ? "#ffff" : "#000",
                                        "&::placeholder": {
                                            color: themeMode === "dark" ? "#ffff" : "#000",
                                        },
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                                    },
                                }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    width: {md:'13%',sm:"fit-content",xs:"fit-content"}, 
                                                    maxHeight: 'none', 
                                                    '& .MuiButton-root': {
                                                        background: '#899403',
                                                    },
                                                    '& .MuiButton-root:hover': {
                                                        background: '#9ca802',
                                                    },
                                                }}
                                            >
                                                <Button sx={{width:"fit-content"}}>
                                                    <Typography
                                                        sx={{
                                                            display: {md:'flex',sm:"none",xs:"none"},
                                                            flexDirection: 'row',
                                                            flexWrap: 'nowrap',
                                                            color: '#FFF',
                                                            textTransform: 'capitalize',
                                                            alignItems: 'center',
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <img src={copy} alt="Copy" style={{ width: '25px' }} />
                                                        Copy
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            display: { md: 'none', sm: "flex", xs: "flex" },
                                                            flexDirection: 'row',
                                                            flexWrap: 'nowrap',
                                                            color: '#FFF',
                                                            textTransform: 'capitalize',
                                                            alignItems: 'center',
                                                            width: "fit-content",
                                                            
                                                        }}
                                                    >
                                                        <img src={copy} alt="Copy" style={{ width: '30px' }} />
                                                    </Typography>

                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                            />
                            </Grid>
                            <Grid item md={2} sm={2} xs={12} sx={{display:"flex",justifyContent:{md:"flex-end",sm:"flex-end",xs:"center"},width:"100%"}}>
                                <Button sx={{borderRadius:"10px"}}>
                                    <Typography sx={{color:themeMode==="dark"?"#FFF":"#030303",textTransform:"capitalize",display:"flex",textWrap:"nowrap",alignItems:"center"}}>
                                        <img src={themeMode==="dark"?qr:qrLight} alt="" style={{marginRight:"10px"}}/>
                                        Get QR Code
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} mt={7} sx={{ display: "flex", flexDirection: "row", alignItems: "center",justifyContent:{md:"space-between",sm:"space-around"},flexWrap:"wrap" }}>
                            <Grid item md={3} sm={6} xs={12} py={{md:0,sm:1,xs:1}} sx={{display:"flex",justifyContent:"center"}}>
                                <Button sx={{ backgroundColor: "#3E4B76", borderRadius: "30px", color: "#FFF", padding: "8px 15px", "&:hover": { background: "#272f4a" }, width: { sm: "90%", xs: "100%" } }}>
                                        <Typography sx={{textTransform:"capitalize",display:"flex",alignItems:"center",fontSize:{md:"20px",sm:"18px",xs:"16px"},fontWeight:"600",textWrap:"nowrap"}}>
                                            <FacebookRounded sx={{marginRight:"10px"}} />
                                            Share on Facebook
                                        </Typography>
                                </Button>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} py={{ md: 0, sm: 1, xs: 1 }} sx={{ display: "flex", justifyContent: "center" }}>
                                <Button my={{ md: 0, sm: 1, xs: 1 }} sx={{ backgroundColor: "#7AACF6", borderRadius: "30px", color: "#FFF", padding: "8px 15px", "&:hover": { background: "#5797f7" }, width: { sm: "90%", xs: "100%" } }}>
                                    <Typography sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", fontSize: { md: "20px", sm: "18px", xs: "16px" }, fontWeight: "600", textWrap: "nowrap" }}>
                                        <Twitter sx={{ marginRight: "10px" }} />
                                        Share on Twitter
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} py={{ md: 0, sm: 1, xs: 1 }} sx={{ display: "flex", justifyContent: "center" }}>
                                <Button my={{ md: 0, sm: 1, xs: 1 }} sx={{ backgroundColor: "#2A72DD", borderRadius: "30px", color: "#FFF", padding: "8px 15px", "&:hover": { background: "#1c519e" }, width: { sm: "90%", xs: "100%" } }}>
                                    <Typography sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", fontSize: { md: "20px", sm: "18px", xs: "16px" }, fontWeight: "600", textWrap: "nowrap" }}>
                                        <LinkedIn sx={{ marginRight: "10px" }} />
                                        Share on LinkedIn
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12} py={{ md: 0, sm: 1, xs: 1 }} sx={{ display: "flex", justifyContent: "center" }}>
                                <Button my={{ md: 0, sm: 1, xs: 1 }} sx={{ backgroundColor: "transparent", borderRadius: "30px", color: themeMode === "dark" ? "#FFF" : "#030303", padding: "8px 15px", border: themeMode === "dark" ? "1px solid #FFF" : "1px solid #030303", "&:hover": { backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7', }, width: {sm:"90%",xs:"100%"} }}>
                                    <Typography sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", fontSize: { md: "20px", sm: "18px", xs: "16px" }, fontWeight: "600", textWrap: "nowrap" }}>
                                        <MailOutline sx={{ marginRight: "10px" }} />
                                        Share on Mail
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container 
                        sx={{ 
                            background:"transparent", 
                            padding: "35px 0px ", 
                            display: "flex", flexDirection: "row", 
                            alignItems: "center", borderRadius: "15px" 
                        }}>
                        <Grid item md={12} sm={12} xs={12} sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"},justifyContent:"flex-start",alignItems:"center"}}>
                            <Grid item md={2} sm={3} xs={12} sx={{display:"flex",alignItems:"center",justifyContent:{md:"",sm:"flex-start",xs:"center"}}}>
                                <Typography sx={{ fontSize: { md: "28px", sm: "24px", xs: "20px" },fontWeight:"500",width:{md:"fit-content",sm:"",xs:"100%"},textAlign:"center",display:"flex",alignItems:"center"}}>
                                    Donations
                                </Typography>
                            </Grid>
                            <Grid item md={6} sm={9} xs={12}
                                sx={{ display: "flex", flexDirection: "row", alignItems: "center",justifyContent:{md:"space-between",sm:"space-between",xs:"center"},flexWrap:"wrap" }}>
                                <Button sx={{marginY:{md:"",sm:"",xs:"10px"}, backgroundColor: "#A4B200", borderRadius: "30px", color: "#FFF", padding: "4px 45px", "&:hover": { backgroundColor: themeMode === 'dark' ? '#2e3322' : '#c6d602', }}}>
                                        <Typography sx={{textTransform:"capitalize",display:"flex",alignItems:"center",fontSize:{md:"20px",sm:"18px",xs:"15px"},fontWeight:"600",textWrap:"nowrap"}}>
                                            Recent Donations
                                        </Typography>
                                </Button>

                                <Button sx={{ backgroundColor: "transparent", borderRadius: "30px", color: themeMode==="dark"?"#FFF":"#030303", padding: "4px 45px", border: themeMode==="dark"?"1px solid #FFF":"1px solid #030303", "&:hover": { backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7', } }}>
                                    <Typography sx={{ textTransform: "capitalize", display: "flex", alignItems: "center", fontSize: { md: "20px", sm: "18px", xs: "15px" }, fontWeight: "600", textWrap: "nowrap" }}>
                                        Top Participation
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        
                        <TableContainer
                            className={classes.tableContainer}
                            id="table-container"
                            sx={{
                                borderRadius:"15px",
                                marginTop:"30px",
                                backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                                boxShadow:
                                    themeMode === "dark"
                                        ? "0px 0px 0px blue"
                                        : "4px 4px 14px 0px #00000024",
                                maxHeight:"75vh",
                            }}
                        >

                            <Table
                                className={classes.table}
                                aria-label="simple table"
                                sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F" }}
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow className={classes.white}>
                                        {tableDonations?.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    // backgroundColor: themeMode === "dark" ? "#689900" : "#9CCA3C",
                                                    fontWeight: "700",
                                                    background: themeMode === "dark" ? "#2b2b2b" :"#8ED003",
                                                     borderTopLeftRadius: index === 0 ? "15px" : "0",  
                                                     borderTopRightRadius: index === tableDonations.length - 1 ? "15px" : "0",  
                                                     borderRight: index === tableDonations.length - 1 ? "0px" : undefined,
                                                     //color: index === tableDonations.length - 1 && themeMode === "dark" ? "#FFFF" : "#030303",
                                                }}
                                            >
                                                <Typography
                                                    className={classes.tableHeadText}
                                                    sx={{
                                                        fontSize: { md: "24px", sm: "20px", xs: "16px" },
                                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {donationRow?.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align="center"
                                                sx={{ color: "#fff" }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                    }}
                                                >
                                                    No Records
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        donationRow?.map((row, index) => (
                                            <TableRow
                                                className={classes.row}
                                                key={index}
                                                sx={{
                                                    textDecoration: "none",
                                                    zIndex: "10",
                                                    "&:hover": {
                                                        backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7', 
                                                    },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        width: "28%",
                                                        ...(index === tableDonations.length - 1 && {
                                                            borderRight: "0px",
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                        <Typography
                                                            className={classes.tableTextWhite}
                                                            sx={{
                                                                color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                                fontSize: { md: "24px", sm: "14px", xs: "12px" }
                                                            }}
                                                        >
                                                        {/* {row.user.firstName}&nbsp;{row.user.lastName} */}
                                                            
                                                                {row.donor}
                                                        </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    md={2}
                                                    sx={{
                                                        width: "18%",
                                                        ...(index === tableDonations.length - 1 && {
                                                            borderRight: "0px",
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                        <Typography
                                                            className={classes.white}
                                                            sx={{
                                                                fontSize: { md: "24px", sm: "14px", xs: "12px" },
                                                                color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                            }}
                                                        >
                                                        {/* {`${getSymbolFromCurrency(row.goalCurrency)} ${row.goalAmount
                                                                }  ${row.goalCurrency}`} */}
                                                            ${row.amount}
                                                        </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        width: "20%",
                                                        ...(index === tableDonations.length - 1 && {
                                                            borderRight: "0px",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                        <Typography className={classes.neon}
                                                            sx={{
                                                                fontSize: { md: "24px", sm: "14px", xs: "12px" }
                                                            }}
                                                        >
                                                        {/* {dayjs(row.createdAt)?.format("YYYY-MM-DD")} */}
                                                                {row.date}
                                                        </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        ...(index === tableDonations.length - 1 && {
                                                            borderRight: "0px",
                                                            color: "#fff",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                        <Typography className={classes.neon}
                                                            sx={{ fontSize: { md: "24px", sm: "14px", xs: "12px" } }}
                                                        >
                                                            
                                                            {row.location}
                                                        </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                
                                                
                                                {/* <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.rasiedCell}
                                                    sx={{
                                                        ...(index === tableDonations.length - 1 && {}),
                                                    }}
                                                    onClick={(e) => {
                                                        if (!anchorEl) {
                                                            setSelectedRow(row);
                                                        }
                                                    }}
                                                >
                                                    <Grid sx={{display:"flex",justifyContent:"center"}}>
                                                        <Button
                                                            disableRipple
                                                            onClick={(e) => {
                                                                setAnchorEl(e.currentTarget);
                                                            }}
                                                            sx={{
                                                                display:row.img?"flex":"none",
                                                                "&:hover":{
                                                                backgroundColor:"transparent"
                                                            }}}
                                                        >
                                                            <img
                                                                src={comment}
                                                                alt=""
                                                                style={{width:"25px" }}
                                                            />
                                                        </Button>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={(e) => {
                                                                setAnchorEl(null);
                                                            }}
                                                            PaperComponent={Paper}
                                                            className={classes.paperLight}
                                                            sx={{
                                                                "& .MuiPaper-root": {
                                                                    color: themeMode === "dark" ? "#FFF" : "#111",
                                                                    backgroundColor:
                                                                        themeMode === "dark" ? "#111" : "#FFF",
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    setAnchorEl(null);
                                                                    setMode("EDIT");
                                                                    setOpenPopup(true);
                                                                }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                                <Typography ml={1}>Edit</Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={(e) => {
                                                                    setAnchorEl(null);
                                                                    setConfirmationDialog(true);
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    className={classes.mr1}
                                                                    fontSize="small"
                                                                />
                                                                <Typography ml={1}>Delete</Typography>
                                                            </MenuItem>
                                                        </Menu>
                                                    </Grid>
                                                </TableCell> */}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid container 
                        sx={{
                            background: "transparent",
                            padding: "35px 0px ",
                            display: "flex", flexDirection: "row",
                            alignItems: "center", borderRadius: "15px"  
                            }}>
                        <Typography sx={{ fontSize: { md: "28px", sm: "24px", xs: "20px" }, fontWeight: "500",width:"100%",textAlign:{md:"left",sm:"left",xs:"center"}}}>
                            Team Performance
                        </Typography>
                        
                        <TableContainer
                            className={classes.tableContainer}
                            id="table-container"
                            sx={{
                                borderRadius: "15px",
                                marginTop: "30px",
                                backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                                maxHeight:"85vh",
                                boxShadow:
                                    themeMode === "dark"
                                        ? "0px 0px 0px blue"
                                        : "4px 4px 14px 0px #00000024",
                                
                            }}
                        >

                            <Table
                                className={classes.table}
                                aria-label="simple table"
                                sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F", }}
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow className={classes.white}>
                                        {tableTeamPerf?.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    // backgroundColor: themeMode === "dark" ? "#689900" : "#
                                                    fontWeight: "700",
                                                    background: themeMode === "dark" ? "#2b2b2b" : "#8ED003",
                                                    borderTopLeftRadius: index === 0 ? "15px" : "0",
                                                    borderTopRightRadius: index === tableTeamPerf.length - 1 ? "15px" : "0",
                                                    //color: index === tableDonations.length - 1 && themeMode === "dark" ? "#FFFF" : "#030303",
                                                    borderRight: index === tableTeamPerf.length - 1 ? "0px" : undefined,
                                                }}
                                            >
                                                <Typography
                                                    className={classes.tableHeadText}
                                                    sx={{
                                                        fontSize: { md: "24px", sm: "20px", xs: "16px" },
                                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        textAlign: index === 0 ? "center":"",
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {teamPerfRow?.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                align="center"
                                                sx={{ color: "#fff" }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                    }}
                                                >
                                                    No Records
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        teamPerfRow?.map((row, index) => (
                                            <TableRow
                                                className={classes.row}
                                                key={index}
                                                sx={{
                                                    textDecoration: "none",
                                                    zIndex: "10",
                                                    "&:hover": {
                                                        backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7', 
                                                    },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        width: "8%",
                                                        ...(index === tableTeamPerf.length - 1 && {
                                                            borderRight: "0px",
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                    <Typography
                                                        className={classes.tableTextWhite}
                                                        sx={{
                                                            textAlign:"center",
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }}
                                                    >
                                                        {/* {row.user.firstName}&nbsp;{row.user.lastName} */}

                                                        {row.no}
                                                    </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    md={2}
                                                    sx={{
                                                        width: "100%",
                                                        display:"flex",flexDirection:"row",
                                                        ...(index === tableTeamPerf.length - 1 && {
                                                            borderRight: "0px",
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                    <Grid mr={3} sx={{ 
                                                        display: "flex", 
                                                        width: { md: "60px", sm: "50px", xs: "40px" }, 
                                                        height: { md: "60px", sm: "50px", xs: "40px" }, 
                                                        padding: "3%", 
                                                        background: themeMode === "dark" ? "#91992c" :"#899403",
                                                        borderRadius:"30px",
                                                        justifyContent:"center",
                                                        alignItems:"center"
                                                    }}>
                                                        <Typography sx={{fontSize:{md:"26px",sm:"26px",xs:"18px"},fontWeight:"700",color:"#FFF"}}>
                                                        {row?.fname?.length > 1
                                                            ? `${row.fname?.substring(0, 1)}`
                                                            : row?.fname}
                                                        {row?.lname?.length > 1
                                                            ? `${row.lname?.substring(0, 1)}`
                                                            : row?.lname}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography
                                                        className={classes.white}
                                                        sx={{
                                                            display:"flex",alignItems: "center",
                                                            fontSize: { md: "24px", sm: "14px", xs: "12px" },
                                                            color: themeMode === "dark" ? "#FFFF" : "#030303",
                                                        }}
                                                    >
                                                        {row.fname}&nbsp;{row.lname}
                                                    </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        width: "20%",
                                                        ...(index === tableTeamPerf.length - 1 && {
                                                            borderRight: "0px",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                    <Typography className={classes.neon}
                                                        sx={{ fontSize: { md: "24px", sm: "14px", xs: "12px" }, }}
                                                    >
                                                        {/* {`${getSymbolFromCurrency(row.goalCurrency)} ${row.goalAmount
                                                                }  ${row.goalCurrency}`} */}

                                                        {/* {dayjs(row.createdAt)?.format("YYYY-MM-DD")} */}
                                                        ${row.raised}
                                                    </Typography>
                                                    {/* </Link> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        width:"20%",
                                                        ...(index === tableTeamPerf.length - 1 && {
                                                            borderRight: "0px",
                                                            color: "#fff",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                    <Typography className={classes.neon} 
                                                        sx={{ fontSize: { md: "24px", sm: "14px", xs: "12px" }, }}
                                                    >

                                                        {row.donation}
                                                    </Typography>
                                                    {/* </Link> */}

                                                </TableCell>
                                                

                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{
                                                        
                                                        ...(index === tableTeamPerf.length - 1 && {
                                                            borderRight: "0px",
                                                            color: "#fff",
                                                        }),
                                                    }}
                                                >
                                                    {/* <Link
                                                        to={`/dashboard/p2p/${row.uuid}`}
                                                        style={{ textDecoration: "none", color: "inherit" }}
                                                    > */}
                                                    <Typography className={classes.neon}
                                                        sx={{ fontSize: { md: "24px", sm: "14px", xs: "12px" }, }}
                                                    >

                                                        {row.role}
                                                    </Typography>
                                                    {/* </Link> */}

                                                </TableCell>

                                               
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>

                            <Grid item md={12} sm={12} xs={12} mt={4} sx={{ display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }, alignItems: "center",padding: "25px", position: "sticky", bottom: "0", backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC", }}>
                                <Grid item md={3} sm={3}>
                                    <Typography sx={{ width: "100%", textAlign: "center", fontSize: "20px", fontWeight: "400" }}>
                                        Invite Members
                                    </Typography>
                                </Grid>
                                <Grid item md={8} sm={8} xs={12} my={{ md: 0, sm: 0, xs: 2 }}>
                                    <TextField
                                        fullWidth
                                        size="medium"
                                        variant="outlined"
                                        id="outlined-basic"
                                        placeholder="https://aegjehbt.donorsupport.co/-/NXXXXXXX"
                                        // value={campaignObj?.title || ""}
                                        // onChange={(event) =>
                                        //     setCampaignObj((prevObj) => ({
                                        //         ...prevObj,
                                        //         title: event.target.value,
                                        //     }))
                                        // }
                                        //className={classes.input}
                                        sx={{
                                            borderRadius: "15px",
                                            width: "100%",
                                            "& .MuiInputLabel-root": {
                                                fontSize: { md: "16px", sm: "14px", xs: "12px" },
                                                marginBottom: "20px",
                                                transform: "translate(0px, -100%) scale(0.85)",
                                                "&.MuiInputLabel-shrink": {
                                                    transform: "translate(0px, -100%) scale(0.85)",
                                                },
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderWidth: "0.5px",
                                                borderRadius: "5px",
                                            },
                                            "&:focus": {
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "red",
                                                },
                                                outline: "none",
                                            },
                                            backgroundColor:
                                                themeMode === "dark" ? "#0D0D0D" : "#FFF",
                                            "& .MuiInputLabel-root": {
                                                color: themeMode === "dark" ? "#ffff" : "#000",
                                            },
                                            "& .MuiOutlinedInput-input": {
                                                color: themeMode === "dark" ? "#ffff" : "#000",
                                                "&::placeholder": {
                                                    color: themeMode === "dark" ? "#ffff" : "#000",
                                                },
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: themeMode === "dark" ? "#ffff" : "#0F0F0F",
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        width: { md: '13%', sm: "fit-content", xs: "fit-content" }, 
                                                        maxHeight: 'none', 
                                                        '& .MuiButton-root': {
                                                            background: '#899403',
                                                        },
                                                        '& .MuiButton-root:hover': {
                                                            background: '#9ca802',
                                                        },
                                                    }}
                                                >
                                                    <Button>
                                                        <Typography
                                                            sx={{
                                                                display: { md: 'flex', sm: "none", xs: "none" },
                                                                flexDirection: 'row',
                                                                flexWrap: 'nowrap',
                                                                color: '#FFF',
                                                                textTransform: 'capitalize',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <img src={copy} alt="Copy" style={{ width: '25px' }} />
                                                            Copy
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                display: { md: 'none', sm: "flex", xs: "flex" },
                                                                flexDirection: 'row',
                                                                flexWrap: 'nowrap',
                                                                color: '#FFF',
                                                                textTransform: 'capitalize',
                                                                alignItems: 'center',
                                                                width: "fit-content",

                                                            }}
                                                        >
                                                            <img src={copy} alt="Copy" style={{ width: '30px' }} />
                                                        </Typography>
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item md={1} sm={1} xs={0} sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                 
                                </Grid>
                            </Grid>
                        
                        </TableContainer>
                    </Grid>

                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
