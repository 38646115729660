import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Radio,
  RadioGroup
} from '@mui/material'
import { useSelector } from 'react-redux'
import { withdrawMethod } from '../methods';

const cross = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/cross.svg';
const crossDark = 'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/crossBlack.svg'
const wire = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Payment/withdraw/wire.svg"

const AddWithdrawalMethod = ({onClose,  }) => {
    const themeMode = useSelector(state => state.themeMode.mode)

    const handleClose = () => {
        onClose()
    }
  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: '0% 20%', sm: '0% 10%', xs: '0% 1%' },
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          backdropFilter: 'blur(11px)',
          height: '100%',
          zIndex: '99',
          width: '100%'
        }}
      >
        <Grid
          item
          md={10}
          sm={12}
          xs={12}
          sx={{
            transform: {md:'scale(0.75)',sm:"scale(0.75)",xs:"scale(0.9)"},
            padding: {md:'35px',sm:"35px",xs:"35px"},
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            bottom:{md:"",sm:"55px",xs:"5px"},
            background: themeMode === 'dark' ? '#0D0D0DE5' : '#FFFFFF',
            boxShadow:
              themeMode === 'dark'
                ? '0px 0px 0px red'
                : '0px 8px 5px 0px #00000040',
            color: themeMode === 'dark' ? '#FFFF' : '#030303',
            borderRadius: '15px',
            overflow: 'hidden',
            height: 'fit-content',

            '&::before': {
              content: "''",
              position: 'absolute',
              inset: 0,
              borderRadius: '15px',
              padding: '1px',
              background:
                themeMode === 'dark'
                  ? 'linear-gradient(145deg, white, transparent)'
                  : 'linear-gradient(180deg, white, transparent)',
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude'
            }
          }}
        >
        <Grid sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column-reverse"}}}>
            <Grid item md={10.5} sm={10.5} xs={12} my={{xs:2}}
            sx={{
              display: 'flex',
              flexDirection: "column",
              justifyContent: 'space-between',
              alignItems: { md: 'center', sm: 'center', xs: 'flex-end' },
              width: '100%'
            }}
          >
            <Typography
              sx={{
                fontSize: { md: '32px', sm: '32px', xs: '20px' },
                fontWeight: '600',
                textAlign: 'left',
                width: '100%',
                whiteSpace: 'nowrap',
                fontFamily:"Raleway",
              }}
            >
              Add a withdrawal method
            </Typography>
            <Typography
              sx={{
                fontSize: { md: '16px', sm: '16px', xs: '12px' },
                fontWeight: '400',
                textAlign: 'left',
                width: '100%',
                textWrap: 'wrap',
                fontFamily:"Raleway",
              }}
            >
              Tell us how you want to get your funds. For all account types, it may take up to 3 days to activate.
            </Typography>
            
                </Grid>
                <Grid item md={1.5} sm={1.5} xs={12} sx={{display:"flex",justifyContent:"flex-end",alignItems:"flex-start"}}>
                    <Button
                      disableElevation
                      disableRipple
                      sx={{ width:"fit-content",'&:hover': { backgroundColor: 'transparent' } }}
                      onClick={handleClose}
                    >
                      <img src={themeMode === 'dark' ? cross : crossDark} alt='' />
                    </Button>
                </Grid>
            </Grid>
            <Grid my={{md:4,sm:4,xs:0}} sx={{display:"flex",flexDirection:"column",width:"100%"}}>
              {
                withdrawMethod?.map((item,index)=>(
                    <Grid key={index} item md={12} sm={12} xs={12} py={{md:2,sm:2,xs:0.5}} sx={{display:"flex",justifyContent:"center",borderBottom:themeMode==="dark"?"0.5px solid #FFF":"0.5px solid #636363"}}>
                        <Grid item md={2} sm={2} xs={2} sx={{transform:{md:"scale(1)",sm:"scale(1)",xs:"scale(0.75)"}}}>
                            <img src={item.img} alt=""/>
                        </Grid>
                        <Grid item md={8} sm={8} xs={7} mx={{xs:0.5}}>
                            <Typography sx={{textAlign:"left",fontSize:{md:"16px",sm:"16px",xs:"13px"}}}>
                                {item.name}
                            </Typography>
                            <Typography sx={{textAlign:"left",color:"#848484",fontSize:{md:"16px",sm:"16px",xs:"11px"},textWrap:"wrap"}}>
                                {item.details}
                            </Typography>
                        </Grid>
                        <Grid md={2} sm={2} xs={2} sx={{display:"flex",alignItems:"center"}}>
                            <Button 
                            href={item.link}
                            sx={{
                                borderRadius:"30px",
                                padding:{md:"8px 15px",sm:"8px 15px",xs:"3px 3px"},
                                border:"2px solid #689900",
                                color:themeMode==="dark"?"#FFF":"#000000",
                                "&:hover":{
                                    background: "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                                    color:"#FFF"
                                }
                            }}>
                                <Typography sx={{textTransform:"capitalize",fontSize: { md: '16px', sm: '16px', xs: '12px' },}}>
                                    
                                    Set up
                                </Typography>
                            </Button>
                        </Grid>   
                    </Grid>
                ))
              }

            </Grid>
            <Grid my={2} sx={{display:"flex",flexDirection:"column",width:"100%"}}>
                <Typography sx={{fontSize: { md: '28px', sm: '28px', xs: '20px' },fontWeight:"700",fontFamily:"Raleway",textAlign:"left"}}>
                    Also Available
                </Typography>
                <Grid item md={12} sm={12} xs={12} py={{md:2,sm:2,xs:0.5}} sx={{display:"flex",justifyContent:"center"}}>
                        <Grid item md={2} sm={2} xs={2} sx={{display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",transform:{md:"scale(1)",sm:"scale(1)",xs:"scale(0.75)"}}}>
                            <img src={wire} alt=""/>
                        </Grid>
                        <Grid item md={8} sm={8} xs={7} mx={{xs:0.5}}>
                            <Typography sx={{textAlign:"left",fontSize:{md:"16px",sm:"16px",xs:"13px"}}}>
                                Wire Transfer (USD)
                            </Typography>
                            <Typography sx={{textAlign:"left",color:"#848484",fontSize:{md:"16px",sm:"16px",xs:"11px"},textWrap:"wrap"}}>
                               ▪ $30 per wire transaction to any bank.
                            </Typography>
                            <Typography sx={{textAlign:"left",color:"#848484",fontSize:{md:"16px",sm:"16px",xs:"11px"},textWrap:"wrap"}}>
                               ▪ Up to 7 business days to receive funds.
                            </Typography>
                        </Grid>
                        <Grid md={2} sm={2} xs={2} sx={{display:"flex",alignItems:"center"}}>
                            <Button sx={{
                                borderRadius:"30px",
                                padding:{md:"8px 15px",sm:"8px 15px",xs:"3px 3px"},
                                border:"2px solid #689900",
                                color:themeMode==="dark"?"#FFF":"#000000",
                                "&:hover":{
                                    background: "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                                    color:"#FFF"
                                }
                            }}>
                                <Typography sx={{textTransform:"capitalize",fontSize: { md: '16px', sm: '16px', xs: '12px' },}}>
                                    Set up
                                </Typography>
                            </Button>
                        </Grid>   
                    </Grid>
            </Grid>
          
          
        </Grid>
      </Grid>
    </>
      )
}
export default AddWithdrawalMethod
