import { Grid, Typography, Button, TextField } from "@mui/material";
import { steps } from "../data";

const magnify = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/magnify.png";

const NoData = () => {
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "25px",
          justifyContent: "center",
        }}
      >
        <Grid item md={3.5}>
          <img src={magnify} alt="" />
        </Grid>
        <Grid
          item
          md={8.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            md={10}
            sx={{ textAlign: { md: "left", sm: "center", xs: "center" } }}
          >
            <Typography mb={2} sx={{ fontSize: "16px", fontWeight: "600" }}>
              You haven’t created any campaign yet.
            </Typography>
            <Typography sx={{ fontSize: "18px", fontWeight: "300" }}>
              Set up the campaign and publish the donation form to your website
              or use our own website form templates to collect donations. You
              will see insights once you share the donation form.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            width: { md: "100%", sm: "100%", xs: "100%" },
            display: "flex",
            justifyContent: "center",
            padding: { md: "50px", sm: "50px", xs: "20px 0px" },
            borderBottom: "0.5px solid #FFF",
          }}
        >
          <Button
            sx={{
              color: "#FFF",
              background:
                "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "600",
              textWrap: "noWrap",
              padding: "5px 15px",
              margin: "10px",
              width: "fit-content",
              "&:hover": {
                background: "#081a01",
                color: "#689900",
              },
            }}
          >
            Create Campaign Now
          </Button>
        </Grid>
        <Grid item md={12} mt={7} sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: "32px", fontWeight: "600" }}>
            3 Easy Steps to{" "}
            <span style={{ color: "#94D906", fontWeight: "700" }}>
              Raise Donation
            </span>
          </Typography>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", sm: "column", xs: "column" },
            }}
          >
            {steps?.map((item, index) => (
              <Grid
                container
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: { md: "column", sm: "row", xs: "column" },
                  flexWrap: "nowrap",
                }}
              >
                <Grid>
                  <img src={item.img} alt="" style={{ width: "300px" }} />
                </Grid>
                <Grid
                  item
                  sx={{
                    padding: {
                      md: "0px 15%",
                      sm: "15% 5% 15% 0% ",
                      xs: "0px 15%",
                    },
                  }}
                >
                  <Typography sx={{ fontSize: "20px" }}>
                    <span sx={{ fontWeight: "900" }}>{item.id}#</span>{" "}
                    {item.text}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default NoData;
