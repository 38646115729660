import { makeStyles } from "@mui/styles";

const plant = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/plantImg.svg";

export const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#A4B200",
      marginLeft: "30px",
      fontSize: "16px",
      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(15px, 70%) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(15px, 70%) scale(1)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderTop: "1px solid #FFF",
      //   borderRight:"1px solid #FFF",
      //   borderLeft:"1px solid #FFF",
      borderBottom: "1px solid #FFF",
    },
  },
  select: {
    width: "fit-content",
    paddingLeft: "0px",
    "& .MuiSelect-icon": {
      fill: "#689900",
    },
    "& .MuiSelect-selectMenu": {
      backgroundColor: "#160609",
      backgroundColor: "red",
    },
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      //borderColor: "#ffff",
      borderTop: "1px solid #FFF",
      borderRight: "1px solid transparent",
      borderLeft: "1px solid transparent",
      borderBottom: "1px solid #FFF",
      borderWidth: "0.5px",
      borderRadius: "5px",
      "&:hover": {
        borderTop: "1px solid #FFF",
        borderRight: "1px solid transparent",
        borderLeft: "1px solid transparent",
        borderBottom: "1px solid #FFF",
      },
    },
  },
  menuItem: {
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      color: "#689900",
      backgroundColor: "#0D0D0D",
    },
    "&:focus": {
      color: "#689900",
      backgroundColor: "#000",
    },
    "&.Mui-selected": {
      color: "#689900",
      backgroundColor: "#000",
      "&:hover": {
        color: "#689900",
        backgroundColor: "#0D0D0D",
      },
    },
  },
  input2: {
    backgroundColor: "#0D0D0D",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
      "&::placeholder": {
        color: "#FFFFFF",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
      fontSize: "16px",
      marginBottom: "20px",
      transform: "translate(0px, -100%) scale(0.85)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0px, -100%) scale(0.85)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffff",
      borderWidth: "0.5px",
      borderRadius: "5px",
    },
    "&:focus": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
      },
      outline: "none",
    },
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  p25: { display: "flex", padding: "25px" },
  box: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    background: "#0D0D0DE5",
    color: "#fff",
    borderRadius: "15px",
    overflow: "visible",
    height: "fit-content",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: "linear-gradient(145deg, white, transparent)",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  },
  flex: {
    display: "flex",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  head700: {
    textAlign: "left",
    fontWeight: "700",
  },
  bkg: {
    backgroundImage: `url('${plant}')`,
    width: "100%",
    height: "130px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    borderRadius: "10px",
  },
  f16w400: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "400",
  },
  fullWidth: {
    width: "100%",
  },
  box2: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    background: "#0D0D0DE5",
    color: "#fff",
    borderRadius: "15px",
    overflow: "visible",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: "linear-gradient(145deg, white, transparent)",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  },
  fit: {
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  fw500: {
    fontWeight: "500",
    textWrap: "nowrap",
  },
  fullSpace: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  option: {
    width: "90%",
    borderRadius: "5px",
    padding: "8px 10px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnText: {
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "10.5px",
    fontWeight: "400",
    textWrap: "nowrap",
  },
  btnHolder: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  moneyBtn: {
    width: "90%",
    marginTop: "10px",
    borderRadius: "5px",
    padding: "8px 15px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnText2: {
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: "400",
    textWrap: "nowrap",
  },
  proceed: {
    width: "100%",
    borderRadius: "10px",
    padding: "10px ",
    border: "2px solid transparent",
  },
  f16w500: {
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  f10w400l: {
    textAlign: "left",
    fontSize: "10px",
    fontWeight: "400",
  },
  f10w400r: {
    textAlign: "right",
    fontSize: "10px",
    fontWeight: "400",
  },
  flexFull: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  backBtn: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid #FFF",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& img": {
      transform: "scale(1.1)",
    },
  },
  backText: {
    textWrap: "nowrap",
    color: "#FFF",
    textTransform: "capitalize",
    width: "fit-content",
  },
  relFull: {
    position: "relative",
    width: "100%",
  },
  recur50: {
    backgroundColor: "#E70000",
    color: "#fff",
    width: "100%",
    borderRadius: "10px",
    padding: "10px ",
    border: "2px solid transparent",
    zIndex: "10",
    height: "50px",
    "&:hover": {
      border: "2px solid #4f1616",
      fontSize: "18px",
      backgroundColor: "#4f1616",
      "& img": {
        transform: "scale(1.1)",
      },
    },
  },
  red: {
    display: "flex",
    position: "absolute",
    height: "fit-content",
    width: "100%",
    justifyContent: "center",
  },
  heart: {
    position: "absolute",
    bottom: "5px",
    left: "0%",
  },
  cap500: {
    fontWeight: "500",
    textTransform: "capitalize",
  },
  green: {
    backgroundColor: "#7EBA00",
    color: "#fff",
    width: "100%",
    borderRadius: "10px",
    padding: "10px ",
    border: "2px solid transparent",
    zIndex: "10",
    "&:hover": {
      border: "2px solid #A4B200",
      backgroundColor: "transparent",
    },
  },
  don25: {
    fontWeight: "500",
    textTransform: "capitalize",
    textWrap: "nowrap",
  },
  donate: {
    backgroundColor: "transparent",
    color: "#fff",
    width: "100%",
    borderRadius: "10px",
    padding: "10px ",
    border: "2px solid #FFFFFF",
    zIndex: "10",
    "&:hover": {
      border: "2px solid #A4B200",
      backgroundColor: "transparent",
      color: "#A4B200",
    },
  },
  flexFit: {
    display: "flex",
    width: "fit-content",
  },
  consider: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid #FFF",
    "&:hover": {
      backgroundColor: "transparent",
      "& img": {
        transform: "scale(1.1)",
      },
    },
  },
  thankTitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid #FFF",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  thank700: {
    fontSize: "28px",
    fontWeight: "700",
    color: "#ADFF00",
  },
  grnBox: {
    backgroundColor: "#689900",
    width: "100%",
    zIndex: "22",
  },
  share: {
    fontSize: "18px",
    fontWeight: "400",
    textWrap: "wrap",
    padding: "20px 5px",
  },
  shareBtn: {
    backgroundColor: "transparent",
    color: "#fff",
    width: "100%",
    borderRadius: "10px",
    padding: "10px ",
    border: "2px solid #689900",
    zIndex: "10",
    "&:hover": {
      border: "2px solid #A4B200",
      backgroundColor: "transparent",
    },
  },
  inBtn: {
    display: "flex",
    width: "80%",
    justifyContent: "space-around",
  },
  close: {
    color: "#FFFF",
    textTransform: "capitalize",
    fontSize: "18px",
  },
}));
