import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";

export function ConfirmationDialog({
  open,
  setOpen,
  title,
  content,
  handleConfirmation,
}) {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            border: "1px solid #C70000",
            padding: "15px",
            borderRadius: "15px",
            backgroundColor: themeMode === "dark" ? "#0D0D0D" : "white",
            color: themeMode === "dark" ? "#fff" : "black",
          },
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              color: "#FFF",
              width: { md: "40%", sm: "30%", xs: "50%" },
              background: themeMode === "dark" ? "transarent" : "gray",
              border: "1px solid #fff",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "600",
              padding: "5px 15px",
              mr: 2,
              "&:hover": {
                background: "#081a01",
                border: "2px solid #081a01",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmation}
            sx={{
              color: "#FFF",
              width: { md: "30%", sm: "30%", xs: "50%" },
              background: "#C70000",
              borderRadius: "30px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "600",
              padding: "5px 15px",
              ml: 2,
              border: "2px solid transparent",
              "&:hover": {
                background: "#C70000",
                border: "2px solid #380101",
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
