import NavBar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import Hero from "./Hero/Hero";
import Plan from "./Plan/Plan";
import { useSelector } from "react-redux";
import DocumentTitle from "../../../DocumentTitle";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/footerbkg.svg";

const Pricing = () => {
  DocumentTitle("Pricing - Acceleraise");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const light = "light";
  const dark = "dark";
  return (
    <>
      {themeMode === "dark" ? (
        <NavBar
          scrollColor="#15091B"
          btnColor="#7CB602"
          getStartedBorder="1px solid transparent"
          profileBkg="#1f142e"
          profileBorder="1px solid #5C5C5C"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      ) : (
        <NavBar
          scrollColor="#452754"
          btnColor="#7CB602"
          getStartedBorder="1px solid transparent"
            profileBkg="#503f66"
          profileBorder="1px solid #5C5C5C"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      )}
      <Hero />
      <Plan />

      {themeMode === "dark" ? (
        <Footer
          bkg={bkg}
          btnText="#FFFFFF"
          talkBtn="linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#0000003B"
          btnBorder="0.5px solid #5C5C5C"
          textHover="#FFFFFF"
          brightness="brightness(100%)"
        />
      ) : (
        <Footer
          bkg={bkg}
          btnText="#000000"
          talkBtn="linear-gradient(75.33deg, #618F00 4.78%, #8ED003 154.24%)"
          btnHover="#0000003B"
          btnBorder="0.5px solid #5C5C5C"
          textHover="#000000"
          brightness="brightness(300%)"
        />
      )}
    </>
  );
};
export default Pricing;
