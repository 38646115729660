import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: { display: "flex", flexDirection: "row" },
  fw600: { fontWeight: "600" },
  center: {
    display: "flex",
    alignItems: "center",
    width:"100%"
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
    
  },
  filterBtn: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  noWrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
  centerNoWrap: {
    alignItems: "center",
    display: "flex",
    textWrap: "nowrap",
  },
  centerAlign: {
    textAlign: "center",
    alignItems: "center",
  },
  paper: {
    "& .MuiPaper-root": {
      color: "#FFF",
      backgroundColor: "#222",
      border: "1px solid #689900",
    },
  },
  paperLight: {
    "& .MuiPaper-root": {
      color: "#FFF",
      backgroundColor: "#111",
      border: "1px solid #FFF",
      borderRadius:"15px",
    },
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    border: "2px solid #9CCA3C",
    borderRadius: "15px",
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "100%",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  f12: {
    fontSize: "12px",
    fontWeight: "500",
  },
  fs28: {
    fontSize: "28px",
    fontWeight: "700",
    flexWrap: "nowrap",
  },
  neon: {
    fontSize: "14px",
    fontWeight: "600",
    flexWrap: "nowrap",
    color: "#00FF0A",
    whiteSpace: "nowrap",
  },
  colEnd: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  dullText: { color: "#FFFFFF80", textAlign: "right", fontSize: "10px" },
  fs20: { fontSize: "20px", fontWeight: "600" },
  link: {
    textDecoration: "underline",
    fontSize: "14px",
    fontWeight: "500",
    color: "#8ED003",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  tableContainer: {
    zIndex: "0",
    backgroundColor: "#0F0F0F",
    padding: "20px",
    borderRadius: "15px",
    maxHeight: "350px",
    overflowY: "auto",
  },
  fullWhite: {
    width: "100%",
    color: "#fff",
  },
  white: { color: "#fff" },
  title: {
    color: "#fff",
    fontWeight: "700",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  row: {
    "&:last-child td, &:last-child th": { borderBottom: 0 },
    "&:hover": {
      backgroundColor: "#2e3322",
      borderRadius: "15px",
    },
  },
  cell: {
    width: "10%",
    borderRight: "0px",
    color: "#fff",
  },
  data: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "30px",
    color: "#fff",
    textAlign: "center",
  },
  titleCell: {
    width: "30%",
    borderRight: "0px",
    color: "#FFF",
  },
  campaign: {
    color: "#fff",
    textAlign: "center",
  },
  rasiedCell: {
    width: "15%",
    borderRight: "0px",
  },
  colorData: { color: "#8ED003", textAlign: "center" },
  dots: {
    "&:hover": { backgroundColor: "transparent" },
  },
  mr1:{
    marginRight:1
  }
});
