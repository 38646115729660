import { Grid, Typography, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "./useStyles";
import data from "./data";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { supportEmail } from "../../../../store/supportSlice";
import { success } from "../../../../store/alertSlice";

const light =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/light.svg";
const blur =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/blur.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/down.svg";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  name: "",
  email: "",
  message: "",
};

const schema = yup
  .object()
  .shape({
    message: yup.string().required("You must type message"),
  })
  .required();

const MessageBox = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [displayCount, setDisplayCount] = useState(4);
  const user = useSelector((state) => state.auth.user);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, touchedFields } = formState;

  const handleSeeMoreClick = () => {
    setDisplayCount(data.length);
  };
  const handleSeeLessClick = () => {
    setDisplayCount(4);
  };

  return (
    <>
      <Grid className={classes.bkg} container id="faq">
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundImage: `url('${light}'),url('${blur}')`,
            backgroundSize: "cover,cover",
            backgroundPosition: "center center,10% 50%",
            padding: {
              md: "20px 20px",
              sm: "20px 30px ",
              xs: "20px 15px",
            },
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              //   alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
            }}
          >
            <Grid>
              <form
                // className="w-full"
                onSubmit={handleSubmit((_data) => {
                  const support_email = {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    message: _data.message,
                  };
                  console.log(
                    "🚀 ~ onSubmit={handleSubmit ~ support_email:",
                    support_email
                  );
                  dispatch(supportEmail({ support_email })).then(() =>
                    dispatch(success("Your feedback is submitted!"))
                  );
                })}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        mt={3}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Name"
                        variant="outlined"
                        id="outlined-basic"
                        // value={elementName}
                        // onChange={handleElementName}
                        sx={{
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        mt={4}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Email *"
                        variant="outlined"
                        id="outlined-basic"
                        required
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        // value={elementName}
                        // onChange={handleElementName}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        //required
                        multiline
                        minRows={5}
                        mt={3}
                        fullWidth
                        size="small"
                        className={classes.fullInput}
                        placeholder="Write message here"
                        variant="outlined"
                        id="outlined-basic"
                        name="CampaignName"
                        // value={elementName}
                        // onChange={handleElementName}
                        error={!!errors.message}
                        helperText={errors?.message?.message}
                        sx={{
                          width: {
                            md: "100% !important",
                            sm: "100%",
                            xs: "100%",
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": {
                              color: "#828282",
                              fontFamily: "Stolzl",
                              fontSize: {
                                md: "14px",
                                sm: "14px",
                                xs: "11px",
                              },
                            },
                          },
                        }}
                      />
                    )}
                    name="message"
                    control={control}
                  />
                </Grid>
                <Grid
                  mt={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    type="submit"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    sx={{
                      background:
                        "linear-gradient(90deg, #669600 0%, #89CA00 133.68%)",
                      borderRadius: "30px",
                      padding: "8px 25px",
                      width: "fit-content",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#FFFF",
                        textTransform: "capitalize",
                        textWrap: "nowrap",
                      }}
                    >
                      Send
                    </Typography>
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default MessageBox;
