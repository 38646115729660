import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: "none",
    paddingTop: "5px",
    paddingBottom:"5px",
    display: "flex",
  },
  img: {
    width: "155px",
    height: "35px",
  },

  title: {
    flexGrow: 1,
  },

  navbar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  transparent: {
    backgroundColor: "transparent",
    width: "15%",
  },
  logo: {
    "&:hover": { backgroundColor: "transparent" },
    "&:active": { backgroundColor: "transparent" },
  },
  mr10: {
    display: "flex",
    marginRight: "10%",
  },
  mt10: {
    margin: "10px 0px 0px",
  },
  navBtn: {
    fontSize: "17px",
    textTransform: "capitalize",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "600",
    textAlign: "center",
    margin: " 0px 0px",
    textDecoration: "none",
    padding: "20px",
    width: "100%",
    color: "#74aa01",
    
    "&:hover": {
      backgroundColor: "#366510",
      color: "#fff",
    },
  },
  
  itemHolder: {
    width: "100%",
    justifyContent: "flex-start",
    postition: "relative",
    left: "20%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  presentation: {
    
    display: "flex",
    alignItems: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  home: {
    marginX: "10px",
  },
  getStarted: {
    height: "100%",
    
    fontWeight: "600",
    textWrap: "nowrap",
    borderRadius: "30px",
    textTransform: "capitalize",
    margin: "0px 5px",
    
  },

  noAccount: {
    flexGrow: 1,
    backgroundColor: "transparent",
    color: "#FFF",
    textAlign: "center",
    textTransform: "capitalize",
    width: "fit-content",
    textWrap:"nowrap"
  },
  login: {
    flexGrow: 1,
    backgroundColor: "transparent",
    textAlign: "center",
    textTransform: "capitalize",
    width: "30px",
    textWrap: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
      fontSize: "16px",
    },
  },
  signup: {
    backgroundColor: "transparent",
    color: "#FFFF",
    textWrap: "nowrap",
    fontWeight: "600",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    "&:hover": {
      background: "linear-gradient(275.33deg, #618F00 4.78%, #8ED003 154.24%)",
    },
  },
  flex: { display: "flex" },
  m3: { margin: "0px 3px" },

  btn2: {
    paddingRight: " 15px",
    borderRadius: "30px",
    border: "1px solid transparent",
    
  },


  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dpHolder: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
  },
  w80: {
    width: "80%",
  },
  colCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  profile: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    
  },
  profileDull: {
    fontSize: "11px",
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
    padding: "0px 5px",
    
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    width: "100% !important",
    "& .MuiPaper-root": {
      //backgroundColor: "#0F0F0F",
      color: "#FFF",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "flex-end",
      borderRadius: "15px",
    },
    "& .MuiList-root": {
      width: "100%",
    },
  },
  menuItem: {
    backgroundColor: "transparent",
    width: "100% !important",
    textAlign: "center",
    padding: "0px 20px",
    justifyContent: "center",
    "&:hover": {
      color: "#689900",
      backgroundColor: "#2222",
    },
  },

}));
