import { Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";

const card = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/card.png";
const TwitterX = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/TwitterX.png";
const Facebook = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Facebook.png";
const LinkedIn = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/LinkedIn.png";
const Mail = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/Mail.png";

export const ThankYou = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <div className="flex flex-col h-full">
      <div>
        <Typography className="pre-don-text py-4 text-center">
          Thank You!{" "}
        </Typography>
      </div>
      <div className="flex flex-col justify-end ">
        <div
          className="container mb-5 py-4"
          style={{ backgroundColor: "#A4B200" }}
        >
          <Typography
            className={
              themeMode === "dark"
                ? "pre-donation-btn text-white flex justify-center"
                : "pre-donation-btn text-black flex justify-center"
            }
          >
            <span className="mx-2"> Thank you for your donation of $60 </span>
          </Typography>
        </div>

        <div className="px-8 pb-4">
          <div className="">
            <Typography className="thank-you-text1 text-center">
              Help Spread The Word!
            </Typography>
            <div className="flex w-full justify-center">
              <Typography className="md:w-1/2 thank-you-text2 pt-2 text-center">
                1 out of 4 people you share this with also donate{" "}
              </Typography>
            </div>
          </div>

          <Button
            variant="contained"
            className="w-full py-3 mt-4"
            sx={{
              backgroundColor: themeMode === "dark" ? "white" : "#D9D9D940",
              borderRadius: "10px",
            }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-black flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <img src={TwitterX} />
              <span className="mx-2">Share on Twitter</span>
            </span>
          </Button>
          <Button
            variant="contained"
            className="w-full py-3 mt-4"
            sx={{
              backgroundColor: themeMode === "dark" ? "white" : "#D9D9D940",
              borderRadius: "10px",
            }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-black flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <img src={Facebook} />
              <span className="mx-2">Share on Facebook</span>
            </span>
          </Button>
          <Button
            variant="contained"
            className="w-full py-3 mt-4"
            sx={{
              backgroundColor: themeMode === "dark" ? "white" : "#D9D9D940",
              borderRadius: "10px",
            }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-black flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <img src={LinkedIn} />
              <span className="mx-2">Share on LinkedIn</span>
            </span>
          </Button>
          <Button
            variant="contained"
            className="w-full py-3 mt-4"
            sx={{
              backgroundColor: themeMode === "dark" ? "white" : "#D9D9D940",
              borderRadius: "10px",
            }}
          >
            <span
              className={
                themeMode === "dark"
                  ? "pre-donation-btn text-black flex space-x-4"
                  : "pre-donation-btn text-black flex space-x-4"
              }
            >
              <img src={Mail} />
              <span className="mx-2">Share via Mail</span>
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
