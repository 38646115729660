import React, { useEffect, useState, useRef } from "react";
import {
    Typography,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { useStyles } from "../../useStyles";

const TickBox = ({text,onChange,value,caption})=>{
    const classes=useStyles();
    
    return(
        <>
        <FormControlLabel
            control={
                <Checkbox
                    className={classes.checkbox}
                    onChange={onChange}
                    checked={value}
                    style={{ color: '#689900' }}
                />
            }
            label={
                <Typography
                    sx={{
                        fontSize: {
                            md: '16px',
                            xs: '16px',
                            xs: '12px'
                        }
                    }}
                >
                    {text}
                </Typography>
            }
            size='small'
            sx={{ my: 1 }}
        />
        {
            caption?
                <Typography
                        sx={{
                            fontSize: {
                                md: '14px',
                                xs: '14px',
                                xs: '10px'
                            },
                            textAlign:"left"
                        }}
                >{caption}</Typography>
                :""
        }
    </>
    )
}
export default TickBox