import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  bkg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "center",
    
  },
  box: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
    backdropFilter: "blur(22px)",
    
    boxShadow: "10px 13px 5px 0px #00000040",
    position: "relative",
    color: "#fff",
    overflow: "hidden",
    "&::before": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "15px",
      padding: "1px",
      background: "linear-gradient(145deg, white, transparent)",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
    },
  },
  fw700: {
    fontWeight: "700",
  },
  fw400: {
    fontWeight: "400",
  },
  jCenter: {
    justifyContent: "center",
  },
  input: {
    
  },
  flexEnd: {
    display: "flex",
    padding: "10px",
    justifyContent: "flex-end",
    width: "100%",
  },
  alignRight: {
    textAlign: "right",
  },
  centerFull: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  signIn: {
    
    border: "2px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    flexWrap: "nowrap",
    fontSize: "16px",
    fontWeight: "600",
    backdropFilter: "blur(3px)",
    boxShadow: "0px 8px 5px 0px #00000040",
    padding: "5px 25px",
    
  },
  textCenter: {
    textAlign: "center",
  },
  signUp: {
    fontWeight: "600",
    textDecoration: "none",
    textTransform: "capitalize",
    fontFamily: "Stolzl",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  linkButton: {
    textDecoration: 'none',
    padding: 0,
    minWidth: 'auto',
    color: 'inherit',
    background: 'none',
    border: 'none',
    font: 'inherit',
  },
}));
