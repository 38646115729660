import { gql } from "@apollo/client";

export const GET_CAMPAIGN_LIST = gql`
    query campaignList {
        campaigns{
            nodes {
                uuid
                title
                description
                status
            }
        }
    }
`;

export const GET_ELEMENTS = gql`
    query Elements($first: Int, $after: String, $sortColumn:  ElementSortColumnsEnum, $sortOrder: SortOrderEnum, $searchQuery: String, $filterBy: String){ 
        elements(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, searchQuery: $searchQuery, filterBy: $filterBy) { 
            nodes { 
                uuid 
                name
                elementType
                campaign {
                    uuid
                    title
                    description
                }
                amount
                defaultAmount
                defaultFrequency
                appearance
                elementHtml
                archivedAt
                createdAt
                updatedAt
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
            }
        }
    }
`;

export const CREATE_ELEMENT = gql`
    mutation CreateElement($campaignUuid: ID, $name: String, $defaultFrequency: String, $elementType: String, $defaultAmount: String, $amount: Float, $appearance: JSON, $elementHtml: String){
        createElement(input:{campaignUuid: $campaignUuid, name: $name, defaultFrequency: $defaultFrequency, elementType: $elementType, defaultAmount: $defaultAmount, amount: $amount, appearance: $appearance, elementHtml: $elementHtml}){
            element {
                uuid
                name
                elementType
                amount
                campaign {
                    title
                    description
                    status
                }
                defaultAmount
                defaultFrequency
                appearance
                elementHtml
                archivedAt
                createdAt
                updatedAt
            }
        }
    }
`

export const DELETE_ELEMENT = gql`
    mutation DeleteElement($uuid: ID!){
        deleteElement(input:{uuid: $uuid}){
            success
        }
    }
`;

export const UPDATE_ELEMENT = gql`
    mutation UpdateElement($uuid: ID!, $name: String, $elementType: String, $defaultFrequency: String, $defaultAmount: String, $amount: Float, $appearance: JSON, $elementHtml: String){
        updateElement(input:{uuid: $uuid, name: $name, elementType: $elementType, defaultFrequency: $defaultFrequency, defaultAmount: $defaultAmount, amount: $amount, appearance: $appearance, elementHtml: $elementHtml}){
            element {
                uuid 
                name
                elementType
                campaign {
                    uuid
                    title
                    description
                }
                defaultAmount
                defaultFrequency
                appearance
                elementHtml
                archivedAt
                createdAt
                updatedAt
            }
        }
    }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!){
    createExport(input:{pageName: $pageName}) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;