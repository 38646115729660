import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  bkg: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "140% auto",
    backgroundPosition: "0% center",
  },
  title: {
    fontSize: "40px",
    fontWeight: "700",
    
    textTransform: "uppercase",
  },
  fullWidth: {
    width: "100%",
  },

  bkg2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "140% auto",
    backgroundPosition: "0% center",
  },
  index1: {
    position: "relative",
    zIndex: 1,
  },
  index2: {
    position: "absolute",
    zIndex: 2,
  },
  eco: {
    position: "relative",
    zIndex: 1,
    right: "0px",
    maxWidth: "100%",
  },
  link:{
    textDecoration:"none",
    color:"#FFF"
  }
}));
