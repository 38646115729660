import React, { useState, useEffect } from 'react'
import "./useStyle.css"


const DonateForm = ({
  title, textColor, backgroundColor, iconColor, borderSize, borderRadius, borderColor, shadow, activeColor, label, labelColor, btnColor, btnBorderSize, btnBorderRadius, btnBorderColor
}) => {

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const checkbox = document.getElementById('myCheckbox');
    const input = document.querySelector('.input2');

    if (checkbox && input) {
      checkbox.addEventListener('change', function() {
        input.style.display = this.checked ? 'block' : 'none';
      });
    }

    // Cleanup function to remove event listener when component unmounts
    return () => {
      if (checkbox) {
        checkbox.removeEventListener('change', () => {});
      }
    };
  }, []);

  const handleAmountButtonClick = (e) => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    
    <div class="p25" id="donation-form">
        <div class="box2" style={{ backgroundColor: backgroundColor ? backgroundColor : "#FFFFE0", borderRadius: borderRadius  ? `${borderRadius}px` : '10px', border: borderSize || borderColor ? `${borderSize ? borderSize : '2'}px solid ${borderColor ? borderColor : 'transparent'}` : '2px solid transparent'}}>
            <div class="fit">
                <h4>{title}</h4>
                <div class="fullSpace">
                    <button class="option" style={{ color: textColor ? textColor : '#000'}}>One Time</button>
                    <button class="option">
                        <span style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'fit-content',
                          alignItems: 'center',
                          margin: '0px 5px 0px',
                          color: textColor ? textColor : '#000'
                        }}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                               viewBox="0 0 24 24" 
                               width="16px"
                               height="16px"
                               fill={iconColor ? iconColor: '#FF435A'}
                          >
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C16.09 3.81 17.76 3 19.5 3 22.58 3 25 5.42 25 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                          </svg>
                        </span>
                        Monthly
                    </button>
                </div>

                <div>
                    <p style={{ color: textColor ? textColor : '#000'}}>
                        Your most generous donation
                    </p>
                </div>


                <div class="fullSpace2" id="moneyButtons">
                    <button class="moneyBtn" value = "200" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 200</button>
                    <button class="moneyBtn" value = "100" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 100</button>
                    <button class="moneyBtn" value = "50" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 50</button>
                    <button class="moneyBtn" value = "30" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 30</button>
                    <button class="moneyBtn" value = "10" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 10</button>
                    <button class="moneyBtn" value = "5" style={{ color: textColor ? textColor : '#000'}} onClick={handleAmountButtonClick}>$ 5</button>
                </div>




                <div class="alignCenter">
                    <input type="text" placeholder="Amount" class="input" list="options" id="amountInput"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    {/* <datalist id="options">
                        <option value="PKR" />
                        <option value="$" />
                        <option value="€" />
                        <option value="£" />
                    </datalist> */}
                </div>

                <div class="checkbox-container">
                    <input type="checkbox" id="myCheckbox" class="checkbox-input" />
                    <label for="myCheckbox" class="checkbox-label">Dedicate this donation</label>
                </div>

                <div class="tooltip-container">
                    <input type="text" placeholder="Name of someone special to me" class="input2" />
                    <div class="tooltip-text">Once you've donated, you'll be able to add a personal message and send a card.</div>
                </div>

                <div>
                    <p class='comment'  style={{ color: textColor ? textColor : '#000'}}>
                        Add Comment
                    </p>
                </div>                        

                <div style={{width:"100%"}}>
                    <button class="proceed" id="proceed-btn" style={{ color: labelColor ? labelColor : '#FFF', backgroundColor:  btnColor ? btnColor : '#A4B200', borderRadius: btnBorderRadius  ? `${btnBorderRadius}px` : '10px', border: btnBorderSize || btnBorderColor ? `${btnBorderSize ? btnBorderSize : '2'}px solid ${btnBorderColor ? btnBorderColor : 'transparent'}` : '2px solid transparent', width:"100%" }}>{label ? label : 'Donate and Support'}</button>
                </div>
            </div>
        </div>
        
    </div>
  )
}
export default DonateForm;
