import { gql } from "@apollo/client";

export const CREATE_REPORT = gql`
    mutation CreateReport($CreateReportInput: CreateReportInput!){
        createReport(input: $CreateReportInput) {
            report {
                uuid
                campaign {
                    uuid
                    title
                }
                comparisonCampaign {
                    uuid
                    title
                }
                email
                lastDeliveryDate
                nextDeliveryDate
                scheduleAutoDelivery
                startDeliveryDate
                timePeriod
                user {
                    email
                    firstName
                    lastName
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const GET_REPORTS = gql`
    query Reports($first: Int, $after: String, $sortColumn: ReportSortColumnsEnum, $sortOrder: SortOrderEnum, $searchQuery: String, $startDate: String, $endDate: String){ 
        reports(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, searchQuery: $searchQuery, startDate: $startDate, endDate: $endDate) 
        { 
        nodes 
        { 
            uuid
            campaign {
            uuid
            title
            status
            }
            comparisonCampaign {
            uuid
            title
            status
            }
            email
            file {
                url
            }
            lastDeliveryDate
            nextDeliveryDate
            scheduleAutoDelivery
            startDeliveryDate
            timePeriod
            createdAt
            updatedAt
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
        }
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation DeleteReport($uuid: ID!){
        deleteReport(input:{uuid: $uuid}){
            success
        }
    }
`;

export const GET_CAMPAIGN_LIST = gql`
    query campaignList {
        campaigns{
            nodes {
                uuid
                title
                status
            }
        }
    }
`;