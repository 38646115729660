import {
  Grid,
  Typography,
  Paper,
  Button,
  Link,
  Menu,
  MenuItem
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useStyles } from './useStyles'
import { AuthService } from '../../../services/auth.service'
import { logout } from '../../../store/authSlice'
import SearchArticles from './SearchArticles'
import ThemeModeSwitch from '../../../utils/themeModeSwitch'
import MainSearchBar from '../../MainLayout/Dashboard/MainSearch'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuIcon from '@mui/icons-material/Menu'
import { ArrowRightAlt } from '@mui/icons-material'
import DocumentTitle from "../../../DocumentTitle.js";

const lock = 
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/privacy/lock.svg"
const dp =
  'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png'
const verify =
  'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/verify.png'
const up =
  'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/up.svg'
const down =
  'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg'
const logoutIcon =
  'https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logout.svg'
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const sheild = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Donation/shield.svg";
const sheildBlack = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Publish/sheildBlack.svg";
const logo = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/logo.png";
const logoLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";

const PrivacyPolicy = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const themeMode = useSelector(state => state.themeMode.mode)
  const user = useSelector(state => state.auth.user)
  const [anchorEl, setAnchorEl] = useState(null)
  DocumentTitle("Privacy Policy - Acceleraise");
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleLogin = () => {
    window.location.href = '/sign_in'
  }

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout()
      dispatch(logout())
      navigate('/')
    } catch (error) {
      console.error('Sign-out error:', error)
      navigate('/')
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {/* <Grid
        className={classes.bkg}
        container
        sx={{
          padding: {
            md: "130px 0px 20px",
            sm: "130px 30px 20px",
            xs: "100px 15px 30px",
          },
        }}
      >
        <Grid
          id="help"
          className={classes.columnCenter}
          item
          md={12}
          sm={10}
          xs={11}
          sx={{ fontSize: { md: "16px", sm: "16px", xs: "14px" } }}
        >
          <Typography
            className={classes.upper700}
            sx={{
              //fontWeight:"900",
              fontSize: { md: "56px", sm: "36px", xs: "26px" },
              fontFamily: "Stolzl",
              marginBottom: "16px",
            }}
          >
            Pirvacy Policy{" "}
          </Typography>
        </Grid>
      </Grid> */}
      <Grid
        container
        sx={{
          padding: { md: '15px', sm: '15px', xs: '5px' },
          backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          minHeight: '100vh',
          height: '100%',
          overflow: 'hidden',
          paddingTop: '0px'
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            width: '100%',
            height: 'fit-content',
            flexDirection: { md: 'row', sm: 'row', xs: 'row' },
            justifyContent: { md: 'space-between', sm: 'space-evenly' },
            alignItems: { md: 'center', sm: 'center', xs: 'center' }
          }}
        >
          {/* ===================================Filter=============================================== */}
          <Grid
            item
            md={7}
            sm={8.5}
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px 0px 0px 20px'
            }}
          >
            <Grid
              sx={{
                display: {
                  md: 'flex',
                  sm: 'flex',
                  xs: 'flex'
                }
              }}
            >
              <Grid sx={{display:{ md: 'flex', sm: 'flex', xs: 'flex'},alignItems:"center" }}>
                {/* <MainSearchBar /> */}
                <Button
                  disableRipple
                  className={classes.logo}
                  href="/dashboard/insights"
                  sx={{
                    padding: { md: "15px", sm: "0px 10px 10px 0px", xs: "10px" },
                  }}
                >
                  {themeMode === "dark" ? (
                    <img src={logo} alt="" />
                  ) : (
                    <img src={logoLight} alt="" />
                  )}
                </Button>
                <Grid sx={{ display: { md: "flex", sm: "flex", xs: "none" } }}>
                  <SearchArticles placeholderText='Search' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={5}
            sm={3.5}
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '5px'
            }}
          >
            <Button
              disableElevation
              onClick={handleMenuOpen}
              sx={{
                paddingRight: ' 15px',
                borderRadius: '30px',
                border: '1px solid transparent',
                '&:hover': {
                  border: '1px solid #94D906',
                  backgroundColor: themeMode === 'dark' ? '#2e3322' : '#e3fca7'
                }
              }}
            >
              <Grid
                container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center'
                }}
              >
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: 'fit-content'
                  }}
                >
                  <img src={dp} alt='' className={classes.w80} />
                  <Grid
                    className={classes.colCenter}
                    sx={{
                      display: {
                        md: 'flex',
                        sm: 'flex',
                        xs: 'none'
                      }
                    }}
                  >
                    <Grid
                      className={classes.profile}
                      sx={{
                        color: themeMode === 'dark' ? '#FFFF' : '#0F0F0F'
                      }}
                    >
                      {' '}
                      {user && user?.first_name && user?.last_name
                        ? user?.first_name + ' ' + user?.last_name
                        : ''}
                    </Grid>
                    <Grid
                      className={classes.profileDull}
                      sx={{
                        flexWrap: 'nowrap'
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode === 'dark' ? '#9F9F9F' : '#0F0F0F',
                          textWrap: 'nowrap',
                          fontSize: '11px',
                          textTransform: 'capitalize',
                          textAlign: 'left',
                          whiteSpace: 'nowrap',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        Verified Foundation
                        <img
                          src={verify}
                          alt=''
                          style={{
                            margin: '0px 3px',
                            width: '10px',
                            height: '10px'
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item ml={1}>
                  {anchorEl ? (
                    <img
                      src={up}
                      alt=''
                      style={{ width: '10px', height: '10px' }}
                    />
                  ) : (
                    <img
                      src={down}
                      alt=''
                      style={{ width: '10px', height: '10px' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Button>
            <Menu
              className={classes.menu}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperComponent={Paper}
              sx={{
                '& .MuiPaper-root': {
                  width: { md: '17%', sm: '30%', xs: '70%' },
                  backgroundImage: 'none',
                  backgroundColor: themeMode === 'dark' ? '#0a0a0a' : '#dbd7d7'
                }
              }}
            >
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode === 'dark' ? '#FFFF' : '#030303',
                  '&:hover': {
                    backgroundColor: themeMode === 'dark' ? '#2222' : '#FFF'
                  }
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderBottom: '1px solid #FFF',
                    padding: '15px 15px',
                    justifyContent: 'space-between'
                  }}
                >
                  Theme: {themeMode === 'dark' ? 'Dark' : 'Light'}
                  <ThemeModeSwitch />
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleMenuClose}
                component={Link}
                to='/home'
                sx={{
                  color: themeMode === 'dark' ? '#FFFF' : '#030303',
                  '&:hover': {
                    backgroundColor: themeMode === 'dark' ? '#2222' : '#FFF',
                    color: themeMode === 'dark' ? '#689900' : '#8ED003'
                  }
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderBottom: '1px solid #FFF',
                    padding: '15px 15px'
                  }}
                >
                  <HomeIcon style={{ margin: '0px 15px 0px 0px' }} />
                  Home
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={handleMenuClose}
                component={Link}
                to='/dashboard/settings'
                sx={{
                  color: themeMode === 'dark' ? '#FFFF' : '#030303',
                  '&:hover': {
                    backgroundColor: themeMode === 'dark' ? '#2222' : '#FFF',
                    color: themeMode === 'dark' ? '#689900' : '#8ED003'
                  }
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    borderBottom: '1px solid #FFF',
                    padding: '15px 15px',
                    color: themeMode === 'dark' ? '#FFFF' : '#030303'
                  }}
                >
                  <SettingsIcon style={{ margin: '0px 15px 0px 0px' }} />
                  Settings
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                sx={{
                  color: themeMode === 'dark' ? '#FFFF' : '#030303',
                  '&:hover': {
                    color: user ? '#FF0000 !important' : '#689900',
                    backgroundColor: themeMode === 'dark' ? '#2222' : '#FFF'
                  }
                }}
                onClick={() => {
                  user ? handleLogout() : handleLogin()
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    padding: '15px 15px'
                  }}
                >
                  <img
                    src={logoutIcon}
                    alt=''
                    style={{ margin: '0px 15px 0px 0px' }}
                  />
                  {user ? 'Logout' : 'Login'}
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>

          <Grid
            px={1}
            my={2}
            sx={{
              width: '100%',
              display: { md: 'none', sm: 'none', xs: 'flex' }
            }}
          >
            {/* <MainSearchBar /> */}
            <SearchArticles placeholderText='Search' />
          </Grid>
        </Grid>
{/* Screen */}
        <Grid
          sx={{
            backgroundColor: themeMode === 'dark' ? '#1C1C1C' : '#E2E2E2',
            boxShadow:
              themeMode === 'dark'
                ? '0px 0px 0px red'
                : '0px 8px 5px 0px #00000040',
            borderRadius: '20px',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: { md: 'row', sm: "row", xs: "column-reverse" },
            padding: { md: '40px', sm: '40px', xs: '20px' }
          }}
        >
        
          <Grid item md={7.2} sm={8} xs={12}>
            <Grid className={classes.spaceBtwn} item>
              <Grid className={classes.column} item md={12} sm={12} xs={12}>
           
                  <Button
                    className={classes.rotate}
                    onClick={() => navigate(-1)}
                    disableRipple
                    sx={{
                      "&:hover":{
                        backgroundColor:"transparent"
                      }
                    }}
                  >
                    {themeMode === "dark" ? (
                      <img src={back} alt="" />
                    ) : (
                      <img src={backLight} alt="" />
                    )}
                  </Button>




                <Grid mt={2} sx={{
                  display: "flex",
                  width: "100%",
                }}>
                  <Button
                    href='/dashboard/campaigns'
                    sx={{
                      margin: { md: '0px', sm: '10px 0px', xs: '10px 0px' },
                      background: "transparent",
                      border: "1px solid transparent",
                      display: "flex",
                      width: "fit-content",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  >
                    <Grid sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      textWrap: "nowrap",
                    }}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: { md: '16px', sm: '16px', xs: '11px' },
                          color: themeMode === 'dark' ? '#FFFF' : '#030303',
                          display:"flex",flexDirection:"row",alignItems:"center"
                        }}
                      >
                        <img src={themeMode === 'dark' ? sheild :  sheildBlack } alt="" style={{marginRight:"10px"}}/>
                        Legal information 
                      </Typography>
                    </Grid>
                  </Button>
                  <Grid sx={{ display: "flex", alignItems: "center", color: themeMode === 'dark' ? '#FFFF' : '#030303' }}>
                    <ArrowRightAlt />
                  </Grid>
                   <Button
                    onClick={() => navigate(-1)}
                    sx={{
                      margin: { md: '0px', sm: '10px 0px', xs: '10px 0px' },
                      background: "transparent",
                      border: "1px solid transparent",
                      display: "flex",
                      width: "fit-content",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  >
                    <Grid sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      textWrap: "nowrap",
                    }}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: { md: '16px', sm: '16px', xs: '11px' },
                          color: themeMode==="dark"?"#8ED003":"#6EA006"
                        }}
                      >
                        Privacy Policy
                      </Typography>
                    </Grid>
                  </Button> 
                </Grid>
                <Grid item md={12} sm={12} xs={12} my={3}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: { md: '40px', sm: '40px', xs: '26px' },
                      fontFamily: "Poppins",
                      textAlign: { md: 'left', sm: 'left', xs: 'center' },
                      color: themeMode === 'dark' ? '#FFFF' : '#030303'
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: { md: '16px', sm: '16px', xs: '14px' },
                      fontFamily: "Poppins",
                      textAlign: { md: 'left', sm: 'left', xs: 'center' },
                      color: themeMode === 'dark' ? '#FFFF' : '#030303'
                    }}
                  >
                    Acceleraise is committed to protecting your privacy online. This Privacy Policy endeavours to describe to you our practices regarding the personal information we collect from users on our website, located at Acceleraise.com, and the services offered through the Site. If you have any questions about our Privacy Policy, our collection practices, the processing of user information, or if you would like to report a security violation to us directly, please contact us at help Acceleraise.com
                  </Typography>
                </Grid>
           
                    <Grid my={5}>
                      <Button href="/help-center" 
                        sx={{
                          backgroundColor:"#6EA006",
                          borderRadius:"30px",
                          color:"#FFF",
                          textTransform:"capitalize",
                          padding:"8px 15px"
                        }}>
                          <Typography>
                              Learn More
                          </Typography>
                      </Button>
                    </Grid>


                <Grid mt={20}>
                  <Typography sx={{ color:"#7d7d7c"}}>
                    All Rights Reserved
                  </Typography>
                </Grid>
           
              </Grid>

           
            </Grid>
          </Grid>          
          <Grid item md={4.8} sm={6} xs={12} sx={{display:"flex",alignItems:"center"}}>
            <img src={lock} alt=""/>
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}
export default PrivacyPolicy
