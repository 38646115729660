import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_DONATIONS = gql`
  query RecurringDonation($first: Int, $after: String, $sortColumn: RecurringDonationSortColumnsEnum, $sortOrder: SortOrderEnum, $filterBy: String, $searchQuery: String, $startDate: String, $endDate: String){ 
    recurringDonations(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after, filterBy: $filterBy, searchQuery: $searchQuery, startDate: $startDate, endDate: $endDate) 
    { nodes 
      { 
        uuid 
        active
        donor  {
          uuid
          firstName
          lastName
        }
        campaign {
          uuid
          title
          description
        }
        amount
        currency
        startDate
        nextDate
        endDate
        totalSpending
        updatedAt
        createdAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DONOR = gql`
  query Donor($uuid: ID!, $first: Int, $after: String, $sortColumn: DonationSortColumnsEnum, $sortOrder: SortOrderEnum,  $firstRecurring: Int, $afterRecurring: String, $sortColumnRecurring: RecurringDonationSortColumnsEnum, $sortOrderRecurring: SortOrderEnum){
    donor(uuid: $uuid) {
        uuid
        firstName
        lastName
        email
        phoneNumber
        donations(sortColumn: $sortColumn, sortOrder: $sortOrder, first: $first, after: $after) { 
          nodes 
            { 
              uuid 
              amount
              currency 
              stripeCharge
              campaign {
                uuid
                title
                description
              }
              createdAt
              updatedAt
            }
            pageInfo {
              startCursor
              endCursor
              hasNextPage
            }
        }
        recurringDonations(sortColumn: $sortColumnRecurring, sortOrder: $sortOrderRecurring, first: $firstRecurring, after: $afterRecurring) { 
          nodes { 
            uuid 
            active 
            amount
            currency
            nextDate
            frequency
            paymentCard {
              stripeCardNfo
            }
            campaign {
              uuid
              title
              description
            }
          }	
          pageInfo {
            startCursor
            endCursor
            hasNextPage
          }
        }
        totalDonations
        totalDonationAmount
        updatedAt
        createdAt
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!){
    createExport(input:{pageName: $pageName}) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;