import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";

const gain =  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/gain.png";
const loss =  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/loss.png";
const low  =  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/low.png";
const high =  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/high.png";

const AvgReport = ({ insightData }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        overflow: "none",
        width: "100%",
      }}
    >
      <Grid
        item
        container
        md={2.8}
        sm={5.8}
        xs={5.8}
        my={1}
        sx={{
          border: "2px solid #9CCA3C",
          borderRadius: "15px",
          padding: { md: "10px 25px", sm: "25px", xs: "15px" },
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: { md: "left", sm: "left", xs: "center" },
          "&:hover": {
            backgroundColor:themeMode==="dark"?"#2e3322":"#e3fca7"   
          },
        }}
      >
        <Grid item md={8} sm={8} xs={12}>
          <Typography
            sx={{
              fontFamily:"Poppins",
              fontSize: { md: "12px", sm: "12px", xs: "10px" },
              fontWeight: "500",
              textWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            Total Donation
          </Typography>
          <Typography
            my={1}
            sx={{
              fontSize: { md: "28px", sm: "28px", xs: "24px" },
              fontWeight: "700",
              whiteSpace: "nowrap",
              fontFamily:"Poppins",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            $ {insightData?.totalDonations?.total_amount?.toFixed(2)}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "500",
              flexWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            {insightData?.totalDonations?.current_month_donations - insightData?.totalDonations?.last_month_donations > 0 ?
            <><span style={{ color: "#00FF0A" }}>+{(insightData?.totalDonations?.current_month_donations - insightData?.totalDonations?.last_month_donations) || ''}</span> than last month</>
            : <><span style={{ color: "#FF0000" }}>{(insightData?.totalDonations?.current_month_donations - insightData?.totalDonations?.last_month_donations) || ''}</span> than last month</>}           
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          sx={{
            display: "flex",
            alignItems: { md: "end", sm: "end", xs: "center" },
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: { md: "0px", sm: "0px", xs: "10px" },
          }}
        >
          {insightData?.totalDonations?.current_month_donations - insightData?.totalDonations?.last_month_donations > 0 ?
            <img src={gain} alt="" style={{ width: "50%" }} />
          :
            <img src={loss} alt="" style={{ width: "50%" }} /> 
          }
          <Typography
            sx={{
              color: "#FFFFFF80",
              textAlign: { md: "right", sm: "right", xs: "center" },
              fontSize: "10px",
              color:themeMode==="dark"?"#FFFFFF80":"#0F0F0F"
            }}
          >
            Updated Yesterday
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        md={2.8}
        sm={5.8}
        xs={5.8}
        my={1}
        sx={{
          border: "2px solid #9CCA3C",
          borderRadius: "15px",
          padding: { md: "10px 25px", sm: "25px", xs: "15px" },
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: { md: "left", sm: "left", xs: "center" },
          "&:hover": {
            backgroundColor:themeMode==="dark"?"#2e3322":"#e3fca7"   
          },
        }}
      >
        <Grid item md={8} sm={8} xs={12}>
          <Typography sx={{ fontSize: "12px", fontWeight: "500",color:themeMode==="dark"?"#FFFF":"#0F0F0F" }}>
            Donation Today
          </Typography>
          <Typography
            my={1}
            sx={{
              fontSize: { md: "28px", sm: "28px", xs: "24px" },
              fontWeight: "700",
              textWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            $ {insightData?.todayDonations?.total_amount}
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "500",
              flexWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            {insightData?.todayDonations?.today_donations - insightData?.todayDonations?.yester_donations > 0 ?
                <><span style={{ color: "#00FF0A" }}>+{(insightData?.todayDonations?.today_donations - insightData?.todayDonations?.yester_donations) || ''}</span> than yesterday</>
              : <><span style={{ color: "#FF0000" }}>{(insightData?.todayDonations?.today_donations - insightData?.todayDonations?.yester_donations) || ''}</span> than yesterday</>
            }
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          sx={{
            display: "flex",
            alignItems: { md: "end", sm: "end", xs: "center" },
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: { md: "0px", sm: "0px", xs: "10px" },
          }}
        >
          {insightData?.todayDonations?.today_donations - insightData?.todayDonations?.yester_donations > 0 ?
            <img src={gain} alt="" style={{ width: "50%" }} />
          : <img src={loss} alt="" style={{ width: "50%" }} />
          }
          <Typography
            sx={{
              color: "#FFFFFF80",
              textAlign: { md: "right", sm: "right", xs: "center" },
              fontSize: "10px",
              color:themeMode==="dark"?"#FFFFFF80":"#0F0F0F"
            }}
          >
            Updated 3m ago
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        md={2.8}
        sm={5.8}
        xs={5.8}
        my={1}
        sx={{
          border: "2px solid #9CCA3C",
          borderRadius: "15px",
          padding: { md: "10px 25px", sm: "25px", xs: "15px" },
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: { md: "left", sm: "left", xs: "center" },
          "&:hover": {
            backgroundColor:themeMode==="dark"?"#2e3322":"#e3fca7"   
          },
        }}
      >
        <Grid item md={8} sm={8} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "12px", sm: "12px", xs: "10px" },
              fontWeight: "500",
              //   textWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            Avg. Campaign Clicks
          </Typography>
          <Typography
            my={1}
            sx={{
              fontSize: "28px",
              textWrap: "nowrap",
              fontWeight: "700",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            57.0
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "500",
              flexWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            <span style={{ 
              color:themeMode==="dark"?"#00FF0A":"#9CCA3C"
              }}>57.0</span> per month
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: { md: "end", sm: "end", xs: "center" },
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: { md: "0px", sm: "0px", xs: "10px" },
          }}
          item
          md={4}
          sm={4}
          xs={12}
        >
          <img src={loss} alt="" style={{ width: "50%" }} />
          <Typography
            sx={{
              color: "#FFFFFF80",
              textAlign: "right",
              fontSize: "10px",
              color:themeMode==="dark"?"#FFFFFF80":"#0F0F0F"
            }}
          >
            Updated Yesterday
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        md={2.8}
        sm={5.8}
        xs={5.8}
        my={1}
        sx={{
          border: "2px solid #9CCA3C",
          borderRadius: "15px",
          padding: { md: "10px 25px", sm: "25px", xs: "15px" },
          width: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: { md: "left", sm: "left", xs: "center" },
          "&:hover": {
            backgroundColor:themeMode==="dark"?"#2e3322":"#e3fca7"   
          },
        }}
      >
        <Grid item md={8} sm={8} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "12px", sm: "12px", xs: "10px" },
              fontWeight: "500",
              textWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            New Donors
          </Typography>
          <Typography
            my={1}
            sx={{
              fontSize: { md: "28px", sm: "28px", xs: "28px" },
              fontWeight: "700",
              textWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            {insightData?.newDonors?.current_month_donor}
          </Typography>

          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "500",
              flexWrap: "nowrap",
              color:themeMode==="dark"?"#FFFF":"#0F0F0F"
            }}
          >
            {insightData?.newDonors?.current_month_donor - insightData?.newDonors?.last_month_donor < 0 ? 
              <>
                <span style={{ color: "#00FF0A" }}>
                  <img src={low} alt="" />
                </span>{" "}
              </>
            : <>
                <span style={{ color: "#00FF0A" }}>
                  <img src={high} alt="" />
                </span>{" "}
              </>
            }
            {
              insightData?.newDonors?.last_month_donor !== 0 
                ? ((insightData?.newDonors?.current_month_donor - insightData?.newDonors?.last_month_donor) / insightData?.newDonors?.last_month_donor * 100 || 0)?.toFixed(2) + '% than last month'
                : 'No Last month donors'
            }
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          sx={{
            display: "flex",
            alignItems: { md: "end", sm: "end", xs: "center" },
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginTop: { md: "0px", sm: "0px", xs: "10px" },
          }}
        >
          {/* {insightData?.newDonors?.current_month_donor - insightData?.newDonors?.last_month_donor < 0 ? 
           <img src={loss} alt="" style={{ width: "50%" }} />
            <img src={high} alt="" style={{ width: "50%" }} />
          } */}

            
          

        </Grid>
      </Grid>
    </Grid>
  );
};
export default AvgReport;
