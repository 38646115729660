import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Typography,
    SliderMark,
    Slider,
} from '@mui/material'
import { useSelector } from "react-redux";
import { useStyles } from "../../useStyles";

const SlidingElement = ({label,value,onChange,defaultValue,max,min}) => {
    const classes = useStyles();
    return (
        <Grid
            my={2}
            sx={{
                display: 'flex',
                flexDirection: {
                    md: 'row',
                    sm: 'row',
                    xs: 'column'
                }
            }}
        >
            <Grid
                item
                md={4}
                sm={4}
                xs={12}
                mr={2}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Typography
                    my={{ md: 0, sm: 0, xs: 1 }}
                    sx={{
                        textWrap: 'nowrap',
                        textAlign: {
                            md: 'right',
                            sm: 'right',
                            xs: 'left'
                        },
                        width: '100%'
                    }}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid
                item
                md={8}
                sm={7.5}
                xs={12}
                sx={{ display: 'flex' }}
            >
                <Grid item md={10} sm={9.5} xs={10}>
                    <Slider
                        defaultValue={defaultValue}
                        max={max}
                        min={min}
                        value={value}
                        onChange={onChange}
                        
                        aria-label='Default'
                        valueLabelDisplay='auto'
                        classes={{
                            root: classes.root,
                            track: classes.track,
                            thumb: classes.thumb
                        }}
                        sx={{ width: "95%", display: "flex", justifyContent: "left" }}
                    />
                </Grid>
                <Grid
                    item
                    ml={{ md: 1, sm: 1, xs: 2 }}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Typography
                        sx={{
                            fontSize: {
                                md: '16px',
                                sm: '16px',
                                xs: '14px'
                            }
                        }}
                    >
                        {value}px
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default SlidingElement;