import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  LinearProgress,
} from "@mui/material";
import { useSelector } from 'react-redux'
import ProgressBar from "./progressBar";
import { campaign } from "../../data";
import { Link } from 'react-router-dom';

const dp = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/campaignDp.svg";

const ActiveCampaign = ({p2pRows}) => {
  const themeMode = useSelector(state => state.themeMode.mode)
  const [seeCampaign, setSeeCampaign] = useState(false);
  const handleCampaign = () => {
    setSeeCampaign(!seeCampaign);
  };

  const calculateDonationRaised = (donations) => {
    let total_donation = 0;
    if (donations && donations.length > 0) {
      donations.forEach((donation) => {
        if (donation && donation?.amount !== undefined) {
          total_donation += Number(donation.amount);
        }
      });
    }
    return total_donation ? total_donation : 0;
  };

  const calculateDaysRemaining = (goalDate) => {
    const goalDateTime = new Date(goalDate);
    const currentDate = new Date();
    
    const differenceMs = goalDateTime - currentDate;
    
    const daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
  
    return daysRemaining ? daysRemaining : 0;
  }
 
  return (
    <Grid
      container
      sx={{
        backgroundColor: themeMode === 'dark' ? '#0F0F0F' : '#ECECEC',
        boxShadow:themeMode === 'dark' ?"0px 0px 0px blue":"4px 4px 14px 0px #00000024",
        borderRadius: "15px",
        height: "fit-content",
        padding: "25px",
      }}
    >
      <Grid
        mb={2}
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "16px", sm: "14px", xs: "12px" },
            fontWeight: "700",
            color:themeMode==="dark"? "#FFFF":"#0F0F0F",
          }}
        >
          Peer To Peer
        </Typography>
        <Button
          onClick={handleCampaign}
          component={Link}
          to="/dashboard/p2p" 
          sx={{
            fontSize: { md: "12px", sm: "12px", xs: "12px" },
            fontWeight: "400",
            color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
            textTransform: "capitalize",
            borderRadius: "25px",
            border:"1px solid transparent",
            "&:hover": {
              backgroundColor: themeMode==="dark"?"#2e3322":"#e3fca7" ,
               border:themeMode==="dark"?"1px solid transparent":"1px solid #9CCA3C"    
            },
          }}
        >
          See all
        </Button>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          maxHeight: {
            md: seeCampaign ? "" : `calc(100vh - 380px)`,
            sm: seeCampaign ? "" : `calc(100vh - 255px)`,
            xs: seeCampaign ? "" : `calc(100vh - 180px)`,
          },
        }}
      >
        {p2pRows?.map((item, index) => (
          <Grid
            key={index}
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ padding: "5px 10px", width: "100%" }}
          >
            <Grid
              container
              sx={{
                border: "1px solid #9CCA3C",
                width: "100%",
                borderRadius: "15px",
                padding: "10px 15px",
                display: "flex",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: themeMode==="dark"?"#2e3322":"#e3fca7" 
                },
              }}
            >
              <Grid
                item
                md={3.5}
                sm={3.5}
                sx={{ display: "flex", width: "100%" }}
              >
                <img src={dp} alt="" />
                <Grid
                  ml={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      fontSize: { md: "16px", sm: "14px", xs: "12px" },
                      fontWeight: "400",
                      color:themeMode==="dark"? "#FFFF":"#0F0F0F",
                    }}
                  >
                    {item?.campaign?.title?.substring(0, 18)}
                  </Typography>
                  <Typography
                    sx={{
                      textWrap: "none",
                      fontSize: "12px",
                      fontWeight: "400",
                      color:themeMode==="dark"? "#FFFFFF80":"#0F0F0F",
                    }}
                  >
                    {calculateDaysRemaining(item?.goalDate) > 0 ? `${calculateDaysRemaining(item?.goalDate)} days remaining` : "0 days remaining"} 
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={7.5}
                sm={7.5}
                my={{ md: 0, sm: 0, xs: 3 }}
                sx={{ width: { md: "65%", sm: "80%", xs: "100%" } }}
              >
                <ProgressBar
                  collected={calculateDonationRaised(item?.campaign?.donations?.nodes)}
                  remaining={item?.goalAmount > calculateDonationRaised(item?.campaign?.donations?.nodes) ? item?.goalAmount - calculateDonationRaised(item?.campaign?.donations?.nodes) : 0}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default ActiveCampaign;
