import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { useSelector } from "react-redux";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStyles } from "../useStyles";
import { OneTimeReceipt } from "./OneTimeReceipt";
import { DonationFailed } from "./DonationFailed";
import { ScheduledDonations } from "./ScheduledDonations";
import { TributeLetter } from "./TributeLetter";
import { PlanReceipt } from "./PlanReceipt";
import { InstallmentReceipt } from "./InstallmentReceipt";
import { PlanSchedule } from "./PlanSchedule";
import { FundraisersStarted } from "./FundraisersStarted";
import { MemberWelcome } from "./MemberWelcome";
import { LayoutPreview } from "./LayoutPreview";

export default function EmailPreviewDialog({ type, mode }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <LoadingButton
        loading={false}
        loadingPosition="start"
        startIcon={<EyeIcon />}
        variant="outlined"
        className={classes.preview}
        onClick={handleClickOpen}
        sx={{
          padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
          color: themeMode === "dark" ? "#FFFF" : "#030303",
          "&:hover": {
            backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
          },
        }}
      >
        Preview
      </LoadingButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <div className="">
            {mode === "Customize" ? (
              <LayoutPreview />
            ) : (
              <>
                {type == 1 && <OneTimeReceipt />}
                {type == 2 && <DonationFailed />}
                {type == 3 && <ScheduledDonations />}
                {type == 4 && <PlanReceipt />}
                {type == 5 && <InstallmentReceipt />}
                {type == 6 && <PlanSchedule />}
                {type == 7 && <TributeLetter />}
                {type == 8 && <FundraisersStarted />}
                {type == 9 && <MemberWelcome />}
              </>
            )}

            {/* <div>hello</div> */}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
