import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Paper,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import { setSpinnerLoading } from "../../../../store/spinnerLoaderSlice";
import SearchBar from "../SearchBar";
import { DatePickerComp } from "../DatePickerComp.jsx";
import { useDispatch, useSelector } from "react-redux";
import { success, error as errorMsg } from "../../../../store/alertSlice";
import { ArrowRightAlt } from "@mui/icons-material";
import { tablePastReport, pastReportRow } from "../data";
import { ConfirmationDialog } from "../../../../utils/confirmationDialog";
import {
  adjustNegativeMonths,
  downloadFile,
} from "../../../../utils/globalFunctions.js";
import { GET_REPORTS, DELETE_REPORT } from "./Query";
import dayjs from "dayjs";
import { useStyles } from "./useStyles";
import { useMutation, useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const dateIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const down =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const docx =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/reports.png";
const docxLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/report.svg";
const download =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/download.svg";
const downloadLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/downloadLight.svg";
const deleteIcon =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/delete.svg";
const dots =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots.svg";
const dotswhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dotswhite.svg";
const dotsgreen =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots green.svg";
const question =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/qustion.svg";

const PastReport = ({ setSelectedOption, showPopupCallback }) => {
  const classes = useStyles();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const dispatch = useDispatch();
  const [hoveredRows, setHoveredRows] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [refetchRows, setRefetchRows] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sortReport, setSortReport] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [reportRows, setReportRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);
  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });

  const [DeleteReport, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_REPORT, {
      onError: (error) => {
        console.error("Mutation Error:", error);
        dispatch(errorMsg("the delete was unsuccessful!"));
      },
      onCompleted: (data) => {
        dispatch(success("Report deleted successfully!"));
      },
    });

  const updateReportRowState = (result) => {
    setReportRows(result?.reports?.nodes);
    const pageInfo = result?.reports?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const {
    data: reportData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_REPORTS, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      sortColumn: sortReport?.name,
      sortOrder: sortReport?.order,
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
    },
    onCompleted: (result) => {
      updateReportRowState(result);
    },
  });

  useEffect(() => {
    if (refetchRows) {
      refetch().then((result) => {
        updateReportRowState(result?.data);
      });
      setRefetchRows(false);
    }
  }, [refetchRows]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setReportRows([...reportRows, ...result?.data?.reports?.nodes]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor: result?.data?.reports?.pageInfo?.startCursor,
          endCursor: result?.data?.reports?.pageInfo?.endCursor,
          hasNextPage: result?.data?.reports?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
  };

  const handleHover = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: false,
    }));
  };

  const handleDelete = async () => {
    dispatch(setSpinnerLoading(false));
    setConfirmationDialog(false);
    if (selectedRow?.uuid) {
      const variables = {
        uuid: selectedRow?.uuid,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await DeleteReport({ variables });
        dispatch(setSpinnerLoading(false));
        setRefetchRows(true);
        setConfirmationDialog(false);
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
        setConfirmationDialog(false);
      }
    }
  };

  const handleDownload = async () => {
    if (selectedRow?.file?.url) {
      downloadFile(selectedRow?.file?.url);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid
          item
          mb={3}
          md={12}
          sm={12}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <Grid
            item
            md={9}
            sm={9.15}
            xs={12}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {/* <Grid sx={{ display: "flex", width: "fit-content" }}>
              <Button
                href="/dashboard/reports"
                disableRipple
                sx={{
                  "&:hover": {
                    background: "transparent",
                    transform: "rotate(315deg)",
                  },
                }}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>
            </Grid>

            <Grid sx={{ display: "flex", width: "100%" }}>
              <Button
                disableRipple
                href="/dashboard/reports"
                sx={{
                  background: "transparent",
                  border: "1px solid transparent",
                  display: "flex",
                  margin: { md: "0px", sm: "10px 0px", xs: "10px 5px" },
                  width: {
                    md: "fit-content",
                    sm: "fit-content",
                    xs: "fit-content",
                  },
                  justifyContent: "flex-start",
                  alignContent: "center",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  <img
                    src={themeMode === "dark" ? docx : docxLight}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      fontSize: { md: "16px", sm: "12px", xs: "12px" },
                    }}
                  >
                    Reports
                  </Typography>
                </Grid>
              </Button>

              <Grid
                mx={{ md: 1, sm: 1, xs: 0 }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                <ArrowRightAlt />
              </Grid>

              <Button
                sx={{
                  color: "#8ED003",
                  background: "transparent",
                  border: "1px solid transparent",
                  textTransform: "capitalize",
                  fontWeight: "500",
                  display: "flex",
                  fontSize: { md: "16px", sm: "12px", xs: "12px" },
                  margin: { md: "0px", sm: "10px 0px", xs: "10px 5px" },
                  width: {
                    md: "fit-content",
                    sm: "fit-content",
                    xs: "fit-content",
                  },
                  justifyContent: "flex-start",
                  alignContent: "center",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textWrap: "nowrap",
                  }}
                >
                  Show Previous Reports
                </Grid>
              </Button>
            </Grid> */}

            <Grid item md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: { md: "40px", sm: "40px", xs: "32px" },
                  fontWeight: "600",
                  textAlign: { md: "left", sm: "left", xs: "center" },
                  textWrap: "nowrap",
                  color: themeMode === "dark" ? "#FFFF" : "#030303",
                }}
              >
                Past Reports
              </Typography>
            </Grid>
            <Grid
              item
              className={classes.center}
              sx={{
                justifyContent: {
                  md: "flex-start",
                  sm: "flex-start",
                  xs: "space-between",
                },
                alignItems: {
                  md: "center",
                  sm: "center",
                  xs: "space-between",
                },
                flexDirection: { md: "row", sm: "row", xs: "row" },
                flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
              }}
            >
              <Box
                item
                xs={10}
                className={classes.flexStart}
                sx={{
                  width: { md: "fit-content", sm: "100%", xs: "100%" },
                  width: {
                    md: "fit-content",
                    sm: "100%",
                    xs: "fit-content",
                  },
                  padding: { md: "0px", sm: "0px", xs: "0px 0px" },
                  flexWrap: { md: "nowrap", sm: "nowrap", xs: "nowrap" },

                  justifyContent: {
                    md: "flex-start",
                    sm: "flex-start",
                    xs: "center",
                  },
                  flexDirection: { md: "row", sm: "row", xs: "row" },
                }}
              >
                <Button
                  className={classes.filterBtn}
                  disableRipple
                  sx={{
                    padding: {
                      md: "10px 30px",
                      sm: "10px 15px",
                      xs: "8px 15px",
                    },
                    "&:hover": {
                      backgroundColor:
                        themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    },
                  }}
                  onClick={(e) => {
                    setAnchorElDateFilter(e.currentTarget);
                  }}
                >
                  <Grid container className={classes.noWrap}>
                    <Typography
                      sx={{
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                        fontSize: { md: "12px", sm: "12px", xs: "10px" },
                        display: "flex",
                        alignItems: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      <CalendarMonthIcon
                        sx={{
                          color: "#9CCA3C",
                          marginRight: { md: "10px", sm: "10px", xs: "5px" },
                        }}
                      />
                      {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                      {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                    </Typography>
                  </Grid>
                </Button>
                <Menu
                  anchorEl={anchorElDateFilter}
                  open={Boolean(anchorElDateFilter)}
                  onClose={(e) => {
                    setAnchorElDateFilter(null);
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      background:
                        themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                      width: "fit-content",
                      border: "2px solid #ABFC00",
                      borderRadius: "40px",
                    },
                  }}
                >
                  <MenuItem>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item md={2} xs={12}>
                        <DatePickerComp
                          startDate={dateRange?.startDate}
                          endDate={dateRange?.endDate}
                          displayDate={setDateRange}
                        />{" "}
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Menu>
                <Button
                  className={classes.filterBtn}
                  disableRipple
                  sx={{
                    padding: {
                      md: "10px 30px",
                      sm: "10px 15px",
                      xs: "8px 15px",
                    },
                    "&:hover": {
                      backgroundColor:
                        themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    },
                  }}
                  onClick={(e) => {
                    setAnchorElSort(e.currentTarget);
                  }}
                >
                  <Grid container className={classes.noWrap}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: { md: "12px", sm: "12px", xs: "10px" },
                        display: "flex",
                        flexDirection: "row",
                        textWrap: "nowrap",
                        color: themeMode === "dark" ? "#ffff" : "#000",
                      }}
                    >
                      Sort by
                      <img src={down} alt="" style={{ marginLeft: "10px" }} />
                    </Typography>
                  </Grid>
                </Button>

                <Menu
                  anchorEl={anchorElSort}
                  open={Boolean(anchorElSort)}
                  onClose={(e) => {
                    setAnchorElSort(null);
                  }}
                  PaperComponent={Paper}
                  sx={{
                    "& .MuiPaper-root": {
                      color: themeMode === "dark" ? "#FFF" : "#030303",
                      backgroundColor: themeMode === "dark" ? "#222" : "#FFF",
                      border: "1px solid #689900",
                      borderRadius: "10px",
                    },
                  }}
                >
                  {" "}
                  <Paper
                    style={{
                      maxHeight: 350,
                      maxWidth: 250,
                      overflow: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor:
                        themeMode === "dark"
                          ? "#8ED003CC #0F0F0F"
                          : "#8ED003 #E2E2E2",
                    }}
                  >
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "EMAIL",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "EMAIL" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Email A to Z
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "EMAIL",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "EMAIL" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Email Z to A
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "START_DELIVERY_DATE",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "START_DELIVERY_DATE" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Oldest Start Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "START_DELIVERY_DATE",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "START_DELIVERY_DATE" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Recent Start Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "NEXT_DELIVERY_DATE",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "NEXT_DELIVERY_DATE" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Oldest Next Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "NEXT_DELIVERY_DATE",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "NEXT_DELIVERY_DATE" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Recent Next Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "LAST_DELIVERY_DATE",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "LAST_DELIVERY_DATE" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Oldest Last Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "LAST_DELIVERY_DATE",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "LAST_DELIVERY_DATE" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Recent Last Delivery
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "CREATED_AT",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "CREATED_AT" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Oldest Created
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "CREATED_AT",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "CREATED_AT" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Recently Created
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "UPDATED_AT",
                          order: "ASCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "UPDATED_AT" &&
                        sortReport?.order === "ASCENDING"
                      }
                    >
                      Oldest Updated
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: themeMode === "dark" ? "#fff" : "#000",
                      }}
                      className={classes.menuItem}
                      onClick={(e) => {
                        setAnchorElSort(null);
                        setSortReport({
                          name: "UPDATED_AT",
                          order: "DESCENDING",
                        });
                      }}
                      selected={
                        sortReport?.name === "UPDATED_AT" &&
                        sortReport?.order === "DESCENDING"
                      }
                    >
                      Recently Updated
                    </MenuItem>
                  </Paper>
                </Menu>
              </Box>
              <Box
                item
                xs={1}
                my={{ xs: 2 }}
                sx={{ width: { md: "fit-content", sm: "36%", xs: "100%" } }}
              >
                <SearchBar
                  specific="Search Report"
                  handleSearch={handleSearch}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            sm={2.85}
            xs={12}
            px={{ sm: 1 }}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              disableRipple
              href="create-report"
              // onClick={() => {
              //   handleExport();
              // }}
              sx={{
                padding: {
                  md: "10px 30px",
                  sm: "10px 15px",
                  xs: "8px 10px",
                },
                color: "#FFF",
                background:
                  "linear-gradient(273.66deg, rgba(97, 143, 0, 0.8) 4.91%, rgba(142, 208, 3, 0.8) 82.75%)",
                border: "1px solid #8ED003",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                flexWrap: "nowrap",
                margin: "10px 0px",
                width: "fit-content",
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
                },
              }}
            >
              <Grid container className={classes.nowrap}>
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textWrap: "nowrap",
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },

                    width: "100%",
                  }}
                >
                  {/* <AddIcon /> */}
                  <img
                    src={themeMode === "dark" ? docx : docxLight}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  Generate Report
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <TableContainer
            className={classes.tableContainer}
            id="table-container"
            sx={{
              zIndex: "0",
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              boxShadow:
                themeMode === "dark"
                  ? "0px 0px 0px blue"
                  : "4px 4px 14px 0px #00000024",
              padding: "20px",
              borderRadius: "15px",
            }}
          >
            <Table
              sx={{
                width: "100%",
                color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
              }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F" }}
                >
                  {tablePastReport?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        backgroundColor:
                          themeMode === "dark" ? "#689900" : "#9CCA3C",
                        borderBottomLeftRadius: index === 0 ? "15px" : "0", // Apply bottom left radius to the first item
                        borderBottomRightRadius:
                          index === tablePastReport.length - 1 ? "15px" : "0", // Apply bottom right radius to the last item
                        color:
                          index === tablePastReport.length - 1 &&
                          themeMode === "dark"
                            ? "#FFFF"
                            : "#030303",
                        borderRight:
                          index === tablePastReport.length - 1
                            ? "0px"
                            : undefined,
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          fontSize: "14px",
                          fontWeight: "700",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportRows?.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      sx={{ color: "#fff" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                        }}
                      >
                        No Records
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  reportRows?.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { borderBottom: 0 },
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        // component='th'
                        component={Link}
                        to={`/dashboard/campaign-overview/${row.campaign?.uuid}/${row.campaign?.title}`}
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tablePastReport.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }),
                        }}
                      >
                        {/* <img src={row.img} alt="" style={{width:"17px",height:"25px",marginRight:"20px"}}/> */}
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "30px",
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                            textWrap: "nowrap",
                          }}
                        >
                          {row.uuid?.slice(0, row.uuid.indexOf("-"))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        // component='th'
                        component={Link}
                        to={`/dashboard/campaign-overview/${row.campaign?.uuid}/${row.campaign?.title}`}
                        scope="row"
                        md={2}
                        sx={{
                          width: "10%",
                          ...(index === tablePastReport.length - 1 && {
                            borderRight: "0px",
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                            textWrap: "nowrap",
                          }}
                        >
                          {row.campaign?.title?.length > 18
                            ? `${row.campaign?.title?.substring(0, 18)}...`
                            : row.campaign?.title}
                        </Typography>
                      </TableCell>
                      <TableCell
                        // component='th'
                        component={Link}
                        to={`/dashboard/campaign-overview/${row.campaign?.uuid}/${row.campaign?.title}`}
                        scope="row"
                        sx={{
                          width: "10%",
                          ...(index === tablePastReport.length - 1 && {
                            borderRight: "0px",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }}
                        >
                          {row.campaign?.title?.length > 12
                            ? `${row.campaign?.title?.substring(0, 12)}...`
                            : row.campaign?.title}
                          {row.comparisonCampaign?.title
                            ? ` vs ${
                                row.comparisonCampaign?.title?.length > 12
                                  ? `${row.comparisonCampaign?.title?.substring(
                                      0,
                                      12
                                    )}...`
                                  : row.comparisonCampaign?.title
                              }`
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell
                        // component='th'
                        component={Link}
                        to={`/dashboard/campaign-overview/${row.campaign?.uuid}/${row.campaign?.title}`}
                        scope="row"
                        sx={{
                          width: "15%",
                          ...(index === tablePastReport.length - 1 && {
                            borderRight: "0px",
                          }),
                        }}
                      >
                        <Typography
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                            textWrap: "nowrap",
                          }}
                        >
                          {dayjs(row.createdAt)?.format("YYYY-MM-DD")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          width: "5%",
                          ...(index === tablePastReport.length - 1 && {
                            borderRight: "0px",
                            color: "#fff",
                          }),
                        }}
                      >
                        {/* <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                          src={themeMode === 'dark' ? download : downloadLight}
                          alt=''
                        />
                      </Grid> */}
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "fit-content",
                            zIndex: "30 !important",
                          }}
                        >
                          <Button
                            disableRipple
                            sx={{
                              // zIndex: "99 !important",
                              "&:hover": { backgroundColor: "transparent" },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setAnchorEl(e.currentTarget);
                              setSelectedRow(row);
                            }}
                          >
                            <img
                              src={
                                hoveredRows[index] && themeMode === "dark"
                                  ? dotswhite
                                  : hoveredRows[index] && themeMode === "light"
                                  ? dotsgreen
                                  : dots
                              }
                              alt=""
                              onMouseOver={() => handleHover(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                              style={{ borderRadius: "50%" }}
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => {
                              setAnchorEl(null);
                            }}
                            PaperComponent={Paper}
                            sx={{
                              "& .MuiPaper-root": {
                                color: themeMode === "dark" ? "#FFF" : "#111",
                                backgroundColor:
                                  themeMode === "dark" ? "#111" : "#FFF",
                                border: "1px solid #FFF",
                                borderRadius: "15px",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                handleDownload();
                              }}
                            >
                              <Button
                                disableRipple
                                sx={{
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                              >
                                <Typography
                                  ml={1}
                                  sx={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    textTransform: "capitalize",
                                    color:
                                      themeMode === "dark" ? "#FFF" : "#030303",
                                  }}
                                >
                                  <img
                                    src={
                                      themeMode === "dark"
                                        ? download
                                        : downloadLight
                                    }
                                    alt=""
                                    style={{ marginRight: "10px" }}
                                  />
                                  Download
                                </Typography>
                              </Button>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setConfirmationDialog(true);
                              }}
                            >
                              <Button
                                disableRipple
                                sx={{
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                              >
                                <Typography
                                  ml={1}
                                  sx={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    textTransform: "capitalize",
                                    color: "#FF0000",
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    style={{ marginRight: "15px" }}
                                  />
                                  Delete
                                </Typography>
                              </Button>
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setConfirmationDialog}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this?"}
        handleConfirmation={handleDelete}
      />
    </>
  );
};
export default PastReport;
