import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  client: {
    
    color: "#fff",
    padding: "25px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexHolder: {
    display: "flex",
    justifyContent: "center",
  },
  gridHolder: {
    display: "grid",
    justifyItems: "center",
  },
  imgHolder: {
    display: "flex",
    alignItems: "center",
  },
}));
