import { gql } from "@apollo/client";

export const GET_CAMPAIGN = gql`
  query Campaigns(
    $first: Int
    $after: String
    $archived: Boolean
    $sortColumn: CampaignSortColumnsEnum
    $sortOrder: SortOrderEnum
    $query: String
    $startDate: String
    $endDate: String
  ) {
    campaigns(
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      first: $first
      after: $after
      archived: $archived
      query: $query
      startDate: $startDate
      endDate: $endDate
    ) {
      nodes {
        uuid
        title
        description
        status
        user {
          uuid
          email
        }
        donations {
          nodes {
            uuid
            amount
            stripeChargeId
            updatedAt
          }
        }
        raised
        defaultCheckoutCurrency
        defaultFrequency
        secondFrequency
        defaultSuggestedAmounts
        secondSuggestedAmounts
        archivedAt
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const UPDATE_CAMPAIGN_ARCHIVE = gql`
  mutation ArchiveCampaign($uuid: ID!, $isArchive: Boolean!) {
    archiveCampaign(input: { uuid: $uuid, isArchive: $isArchive }) {
      campaign {
        uuid
        title
        archivedAt
      }
    }
  }
`;

export const CREATE_EXPORT = gql`
  mutation CreateExport($pageName: String!) {
    createExport(input: { pageName: $pageName }) {
      export {
        fileUrl
        message
        success
      }
    }
  }
`;

export const GET_STATS = gql`
  query GetPageStats {
    pageStats {
      campaignPage
      donationPage
      recurringPage
      supporterPage
    }
  }
`;
