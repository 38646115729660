import { Grid, Typography, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
// import _ from "@lodash";
import __ from "lodash";
import AccountHolderBankInfo from "./AccountHolderBankInfo";
import { useState } from "react";

const _ = __.runInContext();

_.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

const defaultValues = {
  swiftCode: "",
};

/**
 * Form Validation Schema
 */
const schema = z.object({
  swiftCode: z.string().nonempty("You must enter a value"),
});

const DirectToLocalBank = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [showInfo, setShowInfo] = useState(false);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: zodResolver(schema),
  });

  const { isValid, dirtyFields, errors, touchedFields } = formState;

  const data = watch();

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: { md: "40px", sm: "40px", xs: "20px" },
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "32px" },
            fontWeight: "600",
            textAlign: { md: "left", sm: "left", xs: "center" },
            color: themeMode === "dark" ? "#FFFF" : "#000000",
            fontFamily: "Poppins",
          }}
        >
          Set up withdrawals: Direct to Local Bank{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            fontWeight: "400",
            textAlign: { md: "left", sm: "left", xs: "center" },
            color: themeMode === "dark" ? "#FFFF" : "#000000",
            fontFamily: "Poppins",
            paddingY: "8px",
          }}
        >
          Send funds directly to your bank.{" "}
        </Typography>
      </Grid>
      <div
        style={{
          border: "1px solid #000000",
          borderRadius: "17px",
          padding: "32px",
          backgroundColor: themeMode === "dark" ? "#333333" : "white",
        }}
      >
        <Typography
          className="sub-heading-withdrawl"
          sx={{
            color: themeMode === "dark" ? "#FFFF" : "#000000",
            fontSize: { md: "24px", sm: "24px", xs: "18px" },
          }}
        >
          Bank Information{" "}
        </Typography>
        <Typography
          className="sub-sub-heading-withdrawl"
          sx={{
            color: themeMode === "dark" ? "#FFFF" : "#000000",
            fontSize: { md: "18px", sm: "18px", xs: "15px" },
            marginTop: "24px",
            marginBottom: "16px",
          }}
        >
          SWIFT Code{" "}
        </Typography>
        <form
          className="w-full md:w-1/2 flex justify-between items-start"
          onSubmit={handleSubmit((_data) => setShowInfo(true))}
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                error={!!errors.swiftCode}
                helperText={errors?.swiftCode?.message}
                required
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#689900",
                    },
                  },
                }}
              />
            )}
            name="swiftCode"
            control={control}
          />
          <Button
            className="mx-8 rounded-full border-[#689900] font-semibold px-8 capitalize"
            variant="outlined"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            disableRipple
              sx={{
                padding: { md: "8px 15px", sm: "18x 15px", xs: "8px 15px" },
                color: themeMode === "dark" ? "#FFFF" : "#000000",
                background: "transparent",
                border: "1px solid #689900",
                borderRadius: "30px",
                textTransform: "capitalize",
                fontWeight: "600",
                flexWrap: "nowrap",
                width: "fit-content",
                 "&:hover": {
                  backgroundColor:
                    themeMode === "dark" ? "#2e3322" : "#e3fca7",
                    border: "1px solid #689900 !important",
                },
              }}
          >
              Search
          </Button>
        </form>
        {showInfo && <AccountHolderBankInfo />}
      </div>
    </Grid>
  );
};
export default DirectToLocalBank;
