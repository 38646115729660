import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import NoData from "./NoData";
import Graph from "./Graph/Graph";
import ActiveCampaign from "./Active Campaign/ActiveCampaign";
import TopSupporters from "./Top Supporters/TopSupporters";
import AvgReport from "./AvgReport/AvgReport";
import PaymentGateway from "./Payment Gateway/PaymentGateway";
import RecDonation from "./RecDonation/RecDonation";
import OTDonation from "./OTDondation/OTDonation";
import { GET_INSIGHTS_DATA, GET_P2P, GET_CAMPAIGN_LIST } from "./Query";
import { useQuery } from "@apollo/client";
import { adjustNegativeMonths } from "../../../../utils/globalFunctions.js";
//import ThemeModeSwitch from "../../../utils/themeModeSwitch";
import { useStyles } from "./useStyles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePickerComp } from "../DatePickerComp.jsx";
import DocumentTitle from "../../../../DocumentTitle.js";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const down = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/down.svg";
const date = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/date.png";

const Insights = () => {
  DocumentTitle("Insights - Acceleraise");

  const [anyData, setAnyData] = useState(true);
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState(null);
  const [selectedCampaignUuid, setSelectedCampaignUuid] = useState(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const currentDate = new Date();
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 2,
    currentDate.getDate()
  );
  const endDate = currentDate;
  const adjustedStartDate = adjustNegativeMonths(startDate);

  const [dateRange, setDateRange] = useState({
    startDate: adjustedStartDate,
    endDate: endDate,
  });
  const [sortP2P, setSortP2P] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);
  const [p2pRows, setP2PRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [searchQuery, setSearchQuery] = useState(null);

  const { data: campaign } = useQuery(GET_CAMPAIGN_LIST, {
    onCompleted: (result) => {
      if (result.campaigns.nodes.length) {
        const campaign_list = result.campaigns.nodes?.filter(
          (campaign) => campaign.status === "publish"
        );
        setCampaignList(campaign_list);
      }
    },
  });

  const {
    data: insightData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_INSIGHTS_DATA, {
    variables: {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      campaignUuids: [selectedCampaignUuid],
    },
  });

  const updateP2PRowState = (result) => {
    setP2PRows(result?.peerToPeerFundraises?.nodes);
    const pageInfo = result?.peerToPeerFundraises?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const {
    data: p2pData,
    loadingP2P,
    fetchMoreP2P,
    refetchP2P,
  } = useQuery(GET_P2P, {
    variables: {
      first: pagination.first,
      after: pagination.after,
      searchQuery: searchQuery,
      sortColumn: sortP2P?.name,
      sortOrder: sortP2P?.order,
    },
    onCompleted: (result) => {
      updateP2PRowState(result);
    },
  });

  return (
    <>
      {anyData ? (
        <Grid
          container
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "left",
            alignItems: "flex-start",
            padding: "15px",
          }}
        >
          <Grid
            item
            className={classes.center}
            sx={{
              justifyContent: {
                md: "flex-start",
                sm: "space-between",
                xs: "space-between",
              },
              flexDirection: { md: "row", sm: "row", xs: "row" },
              flexWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
            }}
          >
            <Box
              item
              xs={10}
              className={classes.flexStart}
              sx={{
                width: { md: "fit-content", sm: "100%", xs: "100%" },
                padding: { md: "0px", sm: "0px", xs: "0px 0px" },
              }}
            >
              <Button
                className={classes.filterBtn}
                disableRipple
                sx={{
                  padding: { md: "10px 30px ", sm: "10px 15px", xs: "8px 8px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElDateFilter(e.currentTarget);
                }}
              >
                <Grid className={classes.noWrap}>
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                    }}
                  >
                    <CalendarMonthIcon sx={{ color: "#9CCA3C", marginRight: { md: "10px", sm: "10px", xs: "5px" } }} />
                    {dayjs(dateRange?.startDate)?.format("MMM D, YYYY")} –{" "}
                    {dayjs(dateRange?.endDate)?.format("MMM D, YYYY")}
                  </Typography>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElDateFilter}
                open={Boolean(anchorElDateFilter)}
                onClose={(e) => {
                  setAnchorElDateFilter(null);
                }}
                sx={{
                  "& .MuiPaper-root": {
                    background:
                      themeMode === "dark" ? "#0F0F0FEF" : "#ECECECDD",
                    width: "fit-content",
                    border: "2px solid #ABFC00",
                    borderRadius: "40px",
                  },
                }}
              >
                <MenuItem>
                  <Grid container spacing={2} alignItems="center">
                    {/* <Grid item xs={6} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dayjs(dateRange?.startDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            startDate: date,
                          }))
                        }
                        maxDate={dateRange?.endDate ? dayjs(dateRange?.endDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <span>-</span>
                  </Grid>
                  <Grid item xs={5} style={{ display: "inline-block" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dayjs(dateRange?.endDate)}
                        onChange={(date) =>
                          setDateRange((prevObj) => ({
                            ...prevObj,
                            endDate: date,
                          }))
                        }
                        minDate={dateRange?.startDate ? dayjs(dateRange?.startDate) : ""}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                    <Grid item md={2} xs={12}>
                      <DatePickerComp
                        startDate={dateRange?.startDate}
                        endDate={dateRange?.endDate}
                        displayDate={setDateRange}
                      />{" "}
                    </Grid>
                  </Grid>
                </MenuItem>
              </Menu>
              <Button
                className={classes.filterBtn}
                disableRipple
                sx={{
                  padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 15px" },
                  "&:hover": {
                    backgroundColor:
                      themeMode === "dark" ? "#2e3322" : "#e3fca7",
                  },
                }}
                onClick={(e) => {
                  setAnchorElFilter(e.currentTarget);
                }}
              >
                <Grid container className={classes.noWrap}>
                  <Typography
                    sx={{
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Campaign
                    <img
                      src={down}
                      alt=""
                      style={{ margin: "0px 0px 0px 10px" }}
                    />
                  </Typography>
                </Grid>
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                open={Boolean(anchorElFilter)}
                onClose={(e) => {
                  setAnchorElFilter(null);
                }}
              >
                <Paper style={{ maxHeight: 350, overflow: 'auto' }}>
                  <MenuItem
                    value={null}
                    onClick={() => {
                      setSelectedCampaignUuid(null);
                      setAnchorElFilter(null);
                    }}
                    selected={selectedCampaignUuid === null}
                  >
                    All Campaign
                  </MenuItem>
                  {campaignList?.map((option) => (
                    <MenuItem
                      key={option.uuid}
                      value={option.uuid}
                      onClick={() => {
                        setSelectedCampaignUuid(option.uuid);
                        setAnchorElFilter(null);
                      }}
                      selected={option.uuid === selectedCampaignUuid}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Paper>
              </Menu>
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12} my={3} sx={{}}>
            <AvgReport insightData={insightData?.insights} />
          </Grid>
          {/*======================================= Graph ==================================== */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              padding: "0px",
              width: "100%",
            }}
          >
            <Box sx={{ width: { md: "67%", sm: "100%", xs: "100%" } }}>
              <Box
                //item md={8} sm={12} xs={12}
                sx={{
                  padding: "10px",
                  width: "100%",
                }}
              >
                <Graph
                  donationsByDates={insightData?.insights?.donationsByDates}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </Box>
              <Box
                // item
                // md={8}
                // sm={12}
                // xs={12}
                sx={{
                  padding: "10px",
                  position: "relative",
                  //bottom: { md: "60%", sm: "0px", xs: "0px" },
                  width: { md: "100%", sm: "100%", xs: "100%" }, //
                }}
              >
                <ActiveCampaign p2pRows={p2pRows} />
              </Box>
            </Box>
            {/*=================================== Top Supporters================================= */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "column", sm: "row", xs: "column" },
                width: { md: "33%", sm: "100%", xs: "100%" },
              }}
            >
              <Box
                // item
                // md={4}
                // sm={6}
                // xs={12}
                sx={{
                  padding: "10px",
                  width: "100%",
                }}
              >
                <TopSupporters topDonors={insightData?.insights?.topDonors} />
              </Box>
              {/* ==================================Active Campaign============================== */}
              {/* <Grid
              item
              md={8}
              sm={12}
              xs={12}
              sx={{
                padding: "10px",
                position: "relative",
                bottom: { md: "60%", sm: "0px", xs: "0px" },
              }}
            >
              <ActiveCampaign />
            </Grid> */}
              {/*================================== Payment GateWay=============================== */}
              <Box
                item
                md={4}
                sm={6}
                sx={{
                  padding: "10px",
                  //   position: "relative",
                  width: "100%", //
                }}
              >
                <PaymentGateway />
              </Box>
            </Box>
          </Box>
          {/* =====================================RecDonation=============================== */}
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{
              padding: "10px",
              position: "relative",
              //bottom: {md:"50px",sm:"0px",xs:"0px"},
            }}
          >
            <RecDonation
              recurringDonations={insightData?.insights?.recurringDonations}
            />
          </Grid>
          {/* =================================OneTimeDonation================================= */}
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            sx={{
              padding: "10px",
              position: "relative",
              //bottom: {md:"50px",sm:"0px",xs:"0px"},
            }}
          >
            <OTDonation
              oneTimeDonations={insightData?.insights?.oneTimeDonations}
            />
          </Grid>
        </Grid>
      ) : (
        <NoData />
      )}
    </>
  );
};
export default Insights;
