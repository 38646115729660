import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  Paper,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowRightAlt } from "@mui/icons-material";
import { status, p2pDonorTable, donorRow } from "./data.jsx";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./useStyles";
import { useDispatch } from "react-redux";
import { setSpinnerLoading } from "../../../../../store/spinnerLoaderSlice";
import { success, error as errorMsg } from "../../../../../store/alertSlice";
import { useQuery, useMutation } from "@apollo/client";
import Graph from "./Graph/Graph";
import ProgressBar from "../../Insights/Active Campaign/progressBar";

import { P2PPopup } from "../P2P-Popup/P2P-Popup";
import { AddDonor } from "./AddDonor/AddDonor";
import { QRCodeModal } from "./QRCodeModal";
import { ConfirmationDialog } from "../../../../../utils/confirmationDialog";
import getSymbolFromCurrency from "currency-symbol-map";
import { GET_P2P_FUNDRAISE, DELETE_P2P_MEMBER } from "./Query";
import { defaultPublishData } from "../../data";
import dayjs from "dayjs";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";

const edit =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/edit.svg";
const pause =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/pause.svg";
const kohli =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/kohli.svg";
const back =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Dashboard/back.svg";
const backLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/back.svg";
const p2p =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/p2p.svg";
const p2pLight =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/p2p.svg";
const copy =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/copy.svg";
const qr =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/qr.svg";
const dots =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dots.svg";
const dotswhite =
  "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/buttons/dotswhite.svg";

const P2PDonor = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const [mode, setMode] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const themeMode = useSelector((state) => state.themeMode.mode);
  //const [openPopup, setOpenPopup] = useState(false)
  const [donationForm, setDonationForm] = useState(null);
  const [hoveredRows, setHoveredRows] = useState({});

  const handleHover = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setHoveredRows((prevHoveredRows) => ({
      ...prevHoveredRows,
      [index]: false,
    }));
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [openP2PEditPopup, setOpenP2PEditPopup] = useState(false);
  const [openConfirmationDialog, setConfirmationDialog] = useState(false);
  const [openQRCodeModal, setQRCodeModal] = useState(false);
  const [sortMembers, setSortMembers] = useState({
    name: "UPDATED_AT",
    order: "DESCENDING",
  });
  const [membersRows, setMembersRows] = useState([]);
  const [pagination, setPagination] = useState({
    first: 5,
    after: null,
    startCursor: null,
    endCursor: null,
    hasNextPage: null,
  });
  const [refetchRows, setRefetchRows] = useState(false);
  const [p2pobjForUpdate, setP2pobjForUpdate] = useState(null);

  const [
    DeletePeerToPeerMember,
    { loading: memberDeleteLoading, error: memberDeleteError },
  ] = useMutation(DELETE_P2P_MEMBER, {
    onError: (error) => {
      console.error("Mutation Error:", error);
      dispatch(errorMsg("the delete was unsuccessful!"));
    },
    onCompleted: (data) => {
      dispatch(success("p2p member deleted successfully!"));
    },
  });

  const updateMembersRowState = (result) => {
    setMembersRows(result?.peerToPeerFundraise?.members?.nodes);
    const pageInfo = result?.peerToPeerFundraise?.members?.pageInfo;
    if (pageInfo) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        startCursor: pageInfo?.startCursor,
        endCursor: pageInfo?.endCursor,
        hasNextPage: pageInfo?.hasNextPage,
      }));
    }
  };

  const {
    data: p2pData,
    loading,
    fetchMore,
    refetch,
  } = useQuery(GET_P2P_FUNDRAISE, {
    variables: {
      uuid: uuid,
      first: pagination.first,
      after: pagination.after,
      sortColumn: sortMembers?.name,
      sortOrder: sortMembers?.order,
    },
    onCompleted: (result) => {
      updateMembersRowState(result);
      setP2pobjForUpdate({
        campaign: result?.peerToPeerFundraise?.campaign,
        uuid: result?.peerToPeerFundraise?.uuid,
        name: result?.peerToPeerFundraise?.name,
        goalAmount: result?.peerToPeerFundraise?.goalAmount,
        goalCurrency: result?.peerToPeerFundraise?.goalCurrency,
        goalDate: result?.peerToPeerFundraise?.goalDate,
        message: result?.peerToPeerFundraise?.message,
      });
      const selectedTemplate =
        result?.peerToPeerFundraise?.campaign?.campaignTemplates?.nodes?.find(
          (data) => data?.active && data?.name
        );
      const form = defaultPublishData?.find(
        (template) => template.name === selectedTemplate.name
      );
      selectedTemplate = { ...selectedTemplate, id: form?.id };
      if (selectedTemplate) setDonationForm(selectedTemplate);
    },
  });

  useEffect(() => {
    if (refetchRows) {
      refetch().then((result) => {
        updateMembersRowState(result?.data);
      });
      setRefetchRows(false);
    }
  }, [refetchRows]);

  const handleScroll = () => {
    const container = document.getElementById("table-container");
    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      pagination?.hasNextPage
    ) {
      fetchMore({
        variables: {
          first: pagination?.first,
          after: pagination?.endCursor,
        },
      }).then((result) => {
        setMembersRows([
          ...membersRows,
          ...result?.data?.peerToPeerFundraise?.members?.nodes,
        ]);
        setPagination((prevPagination) => ({
          ...prevPagination,
          startCursor:
            result?.data?.peerToPeerFundraise?.members?.pageInfo?.startCursor,
          endCursor:
            result?.data?.peerToPeerFundraise?.members?.pageInfo?.endCursor,
          hasNextPage:
            result?.data?.peerToPeerFundraise?.members?.pageInfo?.hasNextPage,
        }));
      });
      setTimeout(() => {}, 1000);
    }
  };

  useEffect(() => {
    const container = document.getElementById("table-container");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleDeleteMember = async () => {
    if (selectedRow?.uuid) {
      const variables = {
        uuid: selectedRow?.uuid,
      };
      dispatch(setSpinnerLoading(true));
      try {
        const result = await DeletePeerToPeerMember({ variables });
        dispatch(setSpinnerLoading(false));
        setRefetchRows(true);
        setConfirmationDialog(false);
      } catch (error) {
        console.error(error);
        dispatch(setSpinnerLoading(false));
        setConfirmationDialog(false);
      }
    }
  };

  const copyToClipboard = () => {
    const selectedFormUrl = `${
      process.env.REACT_APP_FRONTEND_URL
    }/campaign-donation/${donationForm?.id ? donationForm?.id : 0}/${
      p2pData?.peerToPeerFundraise?.campaign?.uuid
    }`;
    navigator.clipboard
      .writeText(selectedFormUrl)
      .then(() => {
        dispatch(success("URL copied to clipboard"));
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  return (
    <>
      <Grid
        className={classes.container}
        container
        sx={{
          padding: { md: "40px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid
          className={classes.fullWidth}
          item
          mb={3}
          md={12}
          sm={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            alignItems: { md: "flex-end", sm: "flex-end", xs: "center" },
          }}
        >
          <Grid className={classes.column} item md={9} sm={9} xs={12}>
            <Grid
              sx={{
                flexDirection: { md: "column", sm: "column", xs: "row" },
              }}
            >
              <Button
                className={classes.rotate}
                disableRipple
                onClick={() => navigate(-1)}
              >
                <img src={themeMode === "dark" ? back : backLight} alt="" />
              </Button>

              <Grid className={classes.fullWidth}>
                <Button
                  className={classes.transButton}
                  href="/dashboard/p2p"
                  sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
                >
                  <Grid className={classes.flexCenter}>
                    <img
                      src={themeMode === "dark" ? p2p : p2pLight}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "12px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      Peer2Peer
                    </Typography>
                  </Grid>
                </Button>
                <Grid
                  className={classes.flexCenter}
                  mx={{ md: 1, sm: 1, xs: 0 }}
                  sx={{ color: themeMode === "dark" ? "#FFFF" : "#030303" }}
                >
                  <ArrowRightAlt />
                </Grid>
                <Button
                  className={classes.transButton}
                  href={`/dashboard/donor/${p2pData?.peerToPeerFundraise?.user?.uuid}`}
                  sx={{ margin: { md: "0px", sm: "10px 0px", xs: "10px 0px" } }}
                >
                  <Grid className={classes.flexCenter}>
                    <Typography
                      className={classes.fw500}
                      sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "12px" },
                        color: themeMode === "dark" ? "#FFFF" : "#030303",
                      }}
                    >
                      {p2pData?.peerToPeerFundraise?.user?.firstName
                        ? p2pData?.peerToPeerFundraise?.user?.firstName
                        : ""}
                      &nbsp;
                      {p2pData?.peerToPeerFundraise?.user?.lastName
                        ? p2pData?.peerToPeerFundraise?.user?.lastName
                        : ""}
                      &nbsp;Fundraiser
                    </Typography>
                  </Grid>
                </Button>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Grid className={classes.container}>
                <Grid className={classes.bottom}>
                  <Typography
                    className={classes.fw600w}
                    sx={{
                      fontSize: { md: "40px", sm: "20px", xs: "20px" },
                      textAlign: { md: "left", sm: "left", xs: "center" },
                      color: themeMode === "dark" ? "#FFFF" : "#030303",
                    }}
                  >
                    {p2pData?.peerToPeerFundraise?.user?.firstName
                      ? p2pData?.peerToPeerFundraise?.user?.firstName
                      : ""}
                    &nbsp;
                    {p2pData?.peerToPeerFundraise?.user?.lastName
                      ? p2pData?.peerToPeerFundraise?.user?.lastName
                      : ""}
                    &nbsp;Fundraiser
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item my={3} md={6} sm={3.7} xs={12} className={classes.rowEnd}>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.refund}
                onClick={() => setOpenP2PEditPopup(true)}
                sx={{
                  padding: { md: "8px 15px", sm: "8px 15px", xs: "8px 15px" },
                  margin: {
                    md: "10px 10px 10px 5px",
                    sm: "10px 10px 10px 5px",
                    xs: "10px 10px 10px 5px",
                    border:
                      themeMode === "dark"
                        ? "1px solid #FFFFFF"
                        : "1px solid #030303",
                  },
                }}
              >
                {themeMode === "dark" ? (
                  <img src={edit} alt="" />
                ) : (
                  <ModeEditOutlineOutlinedIcon style={{ color: "black" }} />
                )}
                <Typography
                  ml={1}
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Edit
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={
                  themeMode === "dark" ? classes.refund : classes.refundLight
                }
                //onClick={handleRefund}
                sx={{
                  padding: { md: "8px 15px", sm: "8px 15px", xs: "8px 15px" },
                  margin: {
                    md: "10px 10px 10px 5px",
                    sm: "10px 10px 10px 5px",
                    xs: "10px 10px 10px 5px",
                  },
                  border:
                    themeMode === "dark"
                      ? "1px solid #FFFFFF"
                      : "1px solid #030303",
                }}
              >
                {themeMode === "dark" ? (
                  <img src={pause} alt="" />
                ) : (
                  <PauseCircleOutlineOutlinedIcon style={{ color: "black" }} />
                )}
                <Typography
                  ml={1}
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    textTransform: "capitalize",
                    fontWeight: "600",
                    color: themeMode === "dark" ? "#FFFF" : "#030303",
                  }}
                >
                  Pause
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          my={1}
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            color: themeMode === "dark" ? "#FFFF" : "#030303",
          }}
        >
          <Grid
            item
            mb={{ md: 0, sm: 0, xs: 3 }}
            md={4.5}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item md={3.5} sm={3.5} xs={3.5}>
              <img src={kohli} alt="" style={{ width: "100%" }} />
            </Grid>
            <Grid
              item
              px={{ md: 1, sm: 2, xs: 3 }}
              pr={{ xs: 0 }}
              md={6.5}
              sm={6.5}
              xs={6.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontStyle: { md: "14px", sm: "14px", xs: "8px" },
                  fontWeight: "400",
                }}
              >
                {p2pData?.peerToPeerFundraise?.message
                  ? p2pData?.peerToPeerFundraise?.message
                  : ""}
              </Typography>
              <Typography
                sx={{
                  fontStyle: { md: "16px", sm: "16px", xs: "13px" },
                  fontWeight: "700",
                  textAlign: { md: "left", sm: "right", xs: "right" },
                  textWrap: "wrap",
                }}
              >
                ~
                {p2pData?.peerToPeerFundraise?.user?.firstName
                  ? p2pData?.peerToPeerFundraise?.user?.firstName
                  : ""}
                &nbsp;
                {p2pData?.peerToPeerFundraise?.user?.lastName
                  ? p2pData?.peerToPeerFundraise?.user?.lastName
                  : ""}
                &nbsp;Fundraiser
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            my={{ md: 0, sm: 2 }}
            md={7.5}
            sm={12}
            xs={12}
            px={{ md: 0, sm: 3, xs: 0 }}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            {
              // status.map((item, index) => (
              //   <Grid key={index} item md={3} sm={3} xs={3}>
              //     <Grid>
              //       <Typography sx={{textAlign:"center",textWrap:"nowrap",fontSize:{md:"16px",sm:"16px",xs:"13px"}}}>
              //         {item.head}
              //       </Typography>
              //     </Grid>
              //     <Grid>
              //       <Typography sx={{ fontSize: {md:"30px",sm:"30px",xs:"18px"}, fontWeight: "600", color: "#00FF0A",textAlign:"center" }}>
              //         {item.ans}
              //       </Typography>
              //     </Grid>
              //   </Grid>
              // ))
              <>
                <Grid item md={3} sm={3} xs={3}>
                  <Grid>
                    <Typography
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      }}
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", sm: "20px", xs: "15px" },
                        fontWeight: "600",
                        color: "#00FF0A",
                        textAlign: "center",
                      }}
                    >
                      {p2pData?.peerToPeerFundraise?.active
                        ? "Active"
                        : "Not-Active"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                  <Grid>
                    <Typography
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      }}
                    >
                      Goal
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", sm: "20px", xs: "15px" },
                        fontWeight: "600",
                        color: "#00FF0A",
                        textAlign: "center",
                        textWrap: "nowrap",
                      }}
                    >
                      {p2pData?.peerToPeerFundraise?.goalAmount
                        ? `${getSymbolFromCurrency(
                            p2pData?.peerToPeerFundraise?.goalCurrency
                          )}${p2pData?.peerToPeerFundraise?.goalAmount} ${
                            p2pData?.peerToPeerFundraise?.goalCurrency
                          }`
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                  <Grid>
                    <Typography
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      }}
                    >
                      Raised
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", sm: "20px", xs: "15px" },
                        fontWeight: "600",
                        color: "#00FF0A",
                        textAlign: "center",
                      }}
                    >
                      {p2pData?.peerToPeerFundraise?.campaign?.raised
                        ? `${getSymbolFromCurrency(
                            p2pData?.peerToPeerFundraise?.goalCurrency
                          )}${p2pData?.peerToPeerFundraise?.campaign?.raised} ${
                            p2pData?.peerToPeerFundraise?.goalCurrency
                          }`
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                  <Grid>
                    <Typography
                      sx={{
                        textAlign: "center",
                        textWrap: "nowrap",
                        fontSize: { md: "16px", sm: "16px", xs: "13px" },
                      }}
                    >
                      Donation #
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: { md: "20px", sm: "20px", xs: "15px" },
                        fontWeight: "600",
                        color: "#00FF0A",
                        textAlign: "center",
                      }}
                    >
                      {p2pData?.peerToPeerFundraise?.campaign?.donations?.nodes
                        ?.length
                        ? p2pData?.peerToPeerFundraise?.campaign?.donations
                            ?.nodes?.length
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </Grid>

        <Grid
          className={classes.divider}
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            flexDirection: { md: "row", sm: "row", xs: "column" },
            color: themeMode === "dark" ? "#FFFF" : "#030303",
            borderBottom:
              themeMode === "dark" ? "1px solid #FFFF" : "1px solid #030303",
          }}
        >
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Campaign</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                {p2pData?.peerToPeerFundraise?.campaign?.title?.length > 18
                  ? `${p2pData?.peerToPeerFundraise?.campaign?.title?.substring(
                      0,
                      18
                    )}...`
                  : p2pData?.peerToPeerFundraise?.campaign?.title
                  ? p2pData?.peerToPeerFundraise?.campaign?.title
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Supporters</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                {p2pData?.peerToPeerFundraise?.supporterCount
                  ? p2pData?.peerToPeerFundraise?.supporterCount
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Link Visitors</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                140
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Recurring</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                {p2pData?.peerToPeerFundraise?.recurringDonationCount
                  ? p2pData?.peerToPeerFundraise?.recurringDonationCount
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Start Date</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                {p2pData?.peerToPeerFundraise?.createdAt
                  ? dayjs(p2pData?.peerToPeerFundraise?.createdAt)?.format(
                      "YYYY-MM-DD"
                    )
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={2}
            sm={2}
            className={classes.flex}
            sx={{
              flexDirection: { md: "column", sm: "column", xs: "row" },
              justifyContent: { md: "left", sm: "left", xs: "space-between" },
            }}
          >
            <Grid item md={12} sm={12} xs={6}>
              <Typography className={classes.fs12}>Goal Date</Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={6}>
              <Typography
                className={classes.neon}
                sx={{ fontSize: { md: "18px", sm: "13px", xs: "12px" } }}
              >
                {p2pData?.peerToPeerFundraise?.goalDate
                  ? dayjs(p2pData?.peerToPeerFundraise?.goalDate)?.format(
                      "YYYY-MM-DD"
                    )
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          my={2}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
            height: "fit-content",
          }}
        >
          <Grid item md={5} sm={12} xs={12}>
            <Graph valueByDates={p2pData?.peerToPeerFundraise?.donorPerDay} />
          </Grid>

          <Grid
            item
            my={{ md: 0, sm: 2, xs: 2 }}
            md={7}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              item
              md={8}
              sm={8}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { md: "0px 15px", sm: "0px 15px", xs: "0px" },
              }}
            >
              <Grid
                mb={1}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px 0px blue"
                      : "4px 4px 14px 0px #00000024",
                  color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                  borderRadius: "15px",
                  padding: "25px",
                  border: "1px solid #FFF",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Grid sx={{ height: "fit-content" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                    Donation Goal
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                    To raise{" "}
                    {`${getSymbolFromCurrency(
                      p2pData?.peerToPeerFundraise?.goalCurrency
                    )}${p2pData?.peerToPeerFundraise?.goalAmount} ${
                      p2pData?.peerToPeerFundraise?.goalCurrency
                    }`}
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex" }} mt={2}>
                  <ProgressBar
                    collected={p2pData?.peerToPeerFundraise?.campaign?.raised}
                    remaining={
                      p2pData?.peerToPeerFundraise?.goalAmount >
                      p2pData?.peerToPeerFundraise?.campaign?.raised
                        ? p2pData?.peerToPeerFundraise?.goalAmount -
                          p2pData?.peerToPeerFundraise?.campaign?.raised
                        : 100
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                mt={1}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px 0px blue"
                      : "4px 4px 14px 0px #00000024",
                  borderRadius: "15px",
                  padding: "25px",
                  border: "1px solid #FFF",
                  color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Grid sx={{ height: "fit-content" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                    Sharing Link
                  </Typography>
                  <Typography
                    my={1}
                    sx={{
                      fontSize: { md: "12px", sm: "12px", xs: "10px" },
                      fontWeight: "400",
                    }}
                  >
                    {`${process.env.REACT_APP_FRONTEND_URL}/campaign-donation/${
                      donationForm?.id ? donationForm?.id : 0
                    }/${p2pData?.peerToPeerFundraise?.campaign?.uuid}`}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: {
                      md: "space-between",
                      sm: "space-around",
                      xs: "space-between",
                    },
                    alignItems: "flex-end",
                  }}
                  mt={2}
                >
                  <Button
                    sx={{
                      backgroundColor: "#252525",
                      border: "1px solid transparent",
                      fontSize: { md: "14px", sm: "14px", xs: "8px" },
                      "&:hover": {
                        border: "1px solid #FFFF",
                        backgroundColor: "#252525",
                      },
                    }}
                  >
                    <img src={copy} alt="" />
                    <Typography
                      ml={1}
                      sx={{
                        color: "#FFFF",
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "14px", xs: "8px" },
                        textWrap: "nowrap",
                      }}
                      onClick={copyToClipboard}
                    >
                      Copy Link
                    </Typography>
                  </Button>
                  <Typography>or</Typography>
                  <Button
                    sx={{
                      backgroundColor: "#252525",
                      border: "1px solid transparent",
                      fontSize: { md: "14px", sm: "14px", xs: "8px" },
                      "&:hover": {
                        border: "1px solid #FFFF",
                        backgroundColor: "#252525",
                      },
                    }}
                    onClick={() => setQRCodeModal(true)}
                  >
                    <img src={qr} alt="" />
                    <Typography
                      ml={1}
                      sx={{
                        color: "#FFFF",
                        textTransform: "capitalize",
                        fontSize: { md: "14px", sm: "14px", xs: "8px" },
                      }}
                    >
                      Get QR Code
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={4} sm={4} xs={12} mt={{ md: 0, sm: 0, xs: 2 }}>
              <Grid
                mb={1}
                sx={{
                  backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
                  boxShadow:
                    themeMode === "dark"
                      ? "0px 0px 0px blue"
                      : "4px 4px 14px 0px #00000024",
                  borderRadius: "15px",
                  padding: "25px",
                  border: "1px solid #FFF",
                  height: "100%",
                  color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                }}
              >
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#FFFF" : "black",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  Visit to Donate Ratio %
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "54px",
                      fontWeight: "600",
                      color: "#00FF0A",
                    }}
                  >
                    50%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          mt={1}
          sx={{
            backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
            boxShadow:
              themeMode === "dark"
                ? "0px 0px 0px blue"
                : "4px 4px 14px 0px #00000024",
            borderRadius: "15px",
            padding: "25px",
            border: "1px solid #FFF",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <TableContainer
            className={classes.tableContainer}
            id="table-container"
            sx={{
              backgroundColor: themeMode === "dark" ? "#0F0F0F" : "#ECECEC",
              color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                position: "sticky",
                top: 0,
                zIndex: 1,
                color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: { md: "18px", sm: "18px", xs: "14px" },
                    fontWeight: "700",
                  }}
                >
                  Members
                </Typography>
              </Grid>
              <Grid sx={{ position: "absolute", right: "0", top: "0" }}>
                <Button
                  onClick={() => {
                    setMode("ADD");
                    setOpenPopup(true);
                  }}
                  sx={{
                    borderRadius: "30px",
                    background:
                      "linear-gradient(274.32deg, #618F00 4.86%, #8ED003 168.46%)",

                    padding: { md: "8px 15px", sm: "8px 15px", xs: "6px 7px" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFF",
                      textTransform: "capitalize",
                      fontSize: { md: "16px", sm: "16px", xs: "10px" },
                      textWrap: "nowrap",
                    }}
                  >
                    + Add Member
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Table
              className={classes.fullWhite}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.white}>
                  {p2pDonorTable?.map((item, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: "700",
                        color: "#000",
                        border: "0px",
                        backgroundColor: "#9CCA3C",
                        ...(index === p2pDonorTable.length - 1 && {
                          borderRight: "0px",
                          color: themeMode === "dark" ? "#FFFF" : "#030303",
                          backgroundColor: "#9CCA3C",
                        }),
                      }}
                    >
                      <Typography
                        className={classes.title}
                        sx={{
                          fontSize: { md: "14px", sm: "12px", xs: "12px" },
                          color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {membersRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      align="center"
                      sx={{ color: themeMode === "dark" ? "#FFFF" : "#0F0F0F" }}
                    >
                      <Typography variant="h6">No Records</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  membersRows?.map((row, index) => (
                    <TableRow
                      key={index}
                      className={classes.row}
                      sx={{
                        "&:hover": {
                          backgroundColor:
                            themeMode === "dark" ? "#2e3322" : "#e3fca7",
                        },
                      }}
                    >
                      <TableCell
                        className={classes.titleCell}
                        component={Link}
                        to={`/dashboard/campaign-overview/${p2pData?.peerToPeerFundraise?.campaign?.uuid}`}
                        scope="row"
                        md={2}
                        sx={{
                          ...(index === membersRows?.length - 1 && {
                            borderRight: "0px",
                            color: "#FFF",
                          }),
                          color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                        }}
                      >
                        <Typography
                          className={classes.donorData}
                          sx={{
                            fontSize: { md: "16px", sm: "13px", xs: "12px" },
                            textWrap: "nowrap",
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }}
                        >
                          {row.donor.firstName}&nbsp;{row.donor.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.raisedCell}
                        component={Link}
                        to={`/dashboard/campaign-overview/${p2pData?.peerToPeerFundraise?.campaign?.uuid}`}
                        scope="row"
                        sx={{
                          ...(index === membersRows?.length - 1 && {
                            borderRight: "0px",
                            color: "#FFF",
                          }),
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          className={classes.donorData}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }}
                        >
                          {row.donor.email}
                        </Typography>
                      </TableCell>
                      <TableCell
                        component={Link}
                        to={`/dashboard/campaign-overview/${p2pData?.peerToPeerFundraise?.campaign?.uuid}`}
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          ...(index === membersRows?.length - 1 && {
                            borderRight: "0px",
                            color: "#FFF",
                          }),
                          color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          className={classes.donorData}
                          sx={{
                            color: themeMode === "dark" ? "#FFFF" : "#0F0F0F",
                          }}
                        >
                          {/* {row.role} */}
                          Member
                        </Typography>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.rasiedCell}
                        sx={{
                          width: "fit-content !important",
                          ...(index === membersRows?.length - 1 && {
                            borderRight: "0px",
                            color: "#FFF",
                          }),
                        }}
                        onClick={(e) => {
                          setSelectedRow(row);
                        }}
                      >
                        <Grid
                          sx={{
                            width: "fit-content !impportant",
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          <Button
                            className={classes.dots}
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <img
                              src={hoveredRows[index] ? dotswhite : dots}
                              alt=""
                              onMouseOver={() => handleHover(index)}
                              onMouseLeave={() => handleMouseLeave(index)}
                            />
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => {
                              setAnchorEl(null);
                            }}
                            PaperComponent={Paper}
                            className={classes.paperLight}
                          >
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setMode("EDIT");
                                setOpenPopup(true);
                              }}
                            >
                              <EditIcon
                                className={classes.mr1}
                                fontSize="small"
                              />
                              <Typography ml={1}>Edit</Typography>
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                setConfirmationDialog(true);
                              }}
                            >
                              <DeleteIcon
                                className={classes.mr1}
                                fontSize="small"
                              />
                              <Typography ml={1}>Delete</Typography>
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AddDonor
        open={openPopup}
        setOpen={setOpenPopup}
        memberData={mode === "EDIT" ? selectedRow : null}
        mode={mode}
        setRefetchRows={setRefetchRows}
        p2pUuid={uuid}
      />
      <P2PPopup
        open={openP2PEditPopup}
        setOpen={setOpenP2PEditPopup}
        p2pData={p2pobjForUpdate}
        mode={"EDIT"}
        setRefetchRows={setRefetchRows}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setConfirmationDialog}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this?"}
        handleConfirmation={handleDeleteMember}
      />
      <QRCodeModal
        open={openQRCodeModal}
        setOpen={setQRCodeModal}
        url={`${process.env.REACT_APP_FRONTEND_URL}/campaign-donation/${donationForm?.id ? donationForm?.id : 0}/${p2pData?.peerToPeerFundraise?.campaign?.uuid}`}
      />
    </>
  );
};
export default P2PDonor;
