import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    color: '#FFF'
  },
  track: {
    color: '#3d5901'
  },
  thumb: {
    color: '#7CB602'
  },
  checkbox: {
    color: '#689900'
  },
  colorBox: {
    background:
      'linear-gradient(0deg, rgba(13, 13, 13, 0.4), rgba(13, 13, 13, 0.9)) linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    width: 'fit-content',
    borderRadius: '5px',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',

  },
  menuItem: {
    fontWeight: '600',
    '&:hover': {
      background: '#659500',
      color: '#FFF'
    },
    '&:focus': {
      color: '#659500',
      background: '#FFF',
    }
  },
  fullWidth: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  inputText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    textTransform: 'capitalize'
  },
  select: {
    width: '100%',
    fontSize: '18px',
    borderRadius: '10px',
    '& .MuiSelect-icon': {
      fill: '#689900'
    },

    '& .MuiInputLabel-root': {
      fontSize: '16px',
      marginBottom: '20px',
      transform: 'translate(0px, -100%) scale(0.85)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0px, -100%) scale(0.85)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0.5px',
      borderRadius: '5px'
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8ED003'
      },
      outline: 'none'
    },
    '& .MuiSelect-selectMenu': {
      backgroundColor: '#160609'
    }
  },
  upload: {
    display: "flex",
    border: "0.25px solid #FFF",
    padding: "10px 15px",
    borderRadius: "26px",
    width: "40%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:hover": {
      filter: "grayscale(0)",
    },
  },
  spaceBtwn: {
    display: "flex",
    justifyContent: "space-between",
  },
  uploadButton: {
    borderRadius: "26px",
    width: "250px", height: "120px",
    "&:hover": {

      color: "#FFF"
    },
  },

  blur: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0px',
    backdropFilter: 'blur(20px)',
    height: 'fit-content',
    zIndex: '99',
    width: '100%'
  },
  panel: {
    padding: '35px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '15px',
    overflow: 'hidden',
    height: 'fit-content',
    '&::before': {
      content: "''",
      position: 'absolute',
      inset: 0,
      borderRadius: '15px',
      padding: '1px',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  },
  spcBtwn100: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  title: {
    fontWeight: '600',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  btnHover: {
    '&:hover': { backgroundColor: 'transparent' }
  },
  topBottomBorder: {
    borderTop: '1px solid #929292',
    borderBottom: '1px solid #929292',
    width: '100%',
  },
  scrollHorz: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'auto !important'
  },
  category: {
    border: '1px solid #689900',
    borderRadius: '20px',
    width: '100%',
  },
  btnText: {
    width: 'fit-content',
    textWrap: 'nowrap',
    textTransform: 'capitalize',
  },
  scrollVert: {
    position: 'relative',
    overflowY: 'auto !important',
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'start',
    scrollbarWidth: 'thin',
  },
  filteredButton: {
    width: '100%',
    height: 'fit-content',
    borderRadius: '15px',
    display: 'flex',
  },
  leftCol: {
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'column'
  },
  w100: {
    width: "100%"
  },
  fw600: {
    fontWeight: '600',
    textTransform: 'capitalize'
  },
  pr15: {
    paddingRight: '15px'
  },
  greyText: {
    textAlign: 'left',
    color: '#9F9F9F',
    textTransform: 'capitalize',
  },
  imgBox: {
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  blur2: {
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    backdropFilter: 'blur(20px)',
    zIndex: '99',
    width: '100%',
    display: 'flex',
  },
  absCenter: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
  },
  taLeft: {
    textAlign: "left"
  },
  taRight:{
    textWrap: 'nowrap',
    textAlign: 'right',
    width: '100%'
  },

  formControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  flexCenter: { display: 'flex', alignItems: 'center' },
  flexStart:{
    display: 'flex', justifyContent: 'flex-start'
  },
  








}))
