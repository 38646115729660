import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    color: "white !important",
  },
  input: {
    color: "#94DA00",
  },
  labelLight: {
    color: "#0F0F0F !important",
  },
  inputLight: {
    color: "#0F0F0F",
  },
  menuItem: {
    fontWeight: "600",
    "&:hover": {
      color: "#689900",
    },
    "&:focus": {
      color: "#689900",
    },
  },
  underline: {
    "&:before": {
      borderBottomColor: "#FFFFFF",
    },
    "&:hover:not($disabled):before": {
      borderBottomColor: "#6EA006",
    },
    "&:after": {
      borderBottomColor: "#94DA00",
    },
  },
  underlineLight: {
    "&:before": {
      borderBottomColor: "#0F0F0F",
    },
    "&:hover:not($disabled):before": {
      borderBottomColor: "#6EA006",
    },
    "&:after": {
      borderBottomColor: "#94DA00",
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  fw600: {
    fontWeight: "600",
    textWrap: "nowrap",
  },
  centerRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  fullStart: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px",
  },
  modeButton: {
    color: "#FFF",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#2e3322",
    },
  },
  disabled: {},
  fitNoWrap: {
    display: "flex",
    flexWrap: "nowrap",
    width: "fit-content",
  },
  fitNoWrapCenter: {
    alignItems: "center",
    display: "flex",
    textWrap: "nowrap",
    width: "fit-content",
  },
  tableContainer: {
    padding: "20px",
    paddingTop: "0px",
    maxHeight: "350px",
    overflowY: "auto",
  },
  center: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  flexStart: {
    display: "flex",
  },
  filterBtn: {
    color: "#FFF",
    background: "transparent",
    border: "1px solid #689900",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontWeight: "600",
    flexWrap: "nowrap",
    margin: "10px 10px 10px 0px",
    width: "fit-content",
  },
  noWrap: {
    display: "flex",
    flexWrap: "nowrap",
  },
}));
