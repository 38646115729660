import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PreviewMenu } from "./PreviewMenu";
import { PreviewContent } from "./PreviewContent";
import { useSelector } from "react-redux";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import LoadingButton from "@mui/lab/LoadingButton";
import { useStyles } from "../../CreateCampaign/CampaignSettings/useStyles";

export default function PreviewDialog() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <LoadingButton
        loading={false}
        loadingPosition="start"
        startIcon={<EyeIcon />}
        variant="outlined"
        className={classes.preview}
        onClick={handleClickOpen}
        sx={{
          padding: { md: "10px 30px", sm: "10px 15px", xs: "8px 10px" },
          color: themeMode === "dark" ? "#FFFF" : "#030303",
          "&:hover": {
            backgroundColor: themeMode === "dark" ? "#2e3322" : "#e3fca7",
          },
        }}
      >
        Preview
      </LoadingButton>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>
          <div className="md:flex w-full justify-between items-center md:space-x-4">
            <div className="w-full md:w-1/4">
              <PreviewMenu handleClose={handleClose} />
            </div>
            <div className="w-full md:w-3/4">
              <PreviewContent />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
