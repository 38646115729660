import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import NavBar from "../../Navbar/Navbar";
import Dynamic from "./Dynamic/Dynamic";
import Trait from "./Property/Property";
import Footer from "../../Footer/Footer";
import { useSelector } from "react-redux";
import DocumentTitle from "../../../DocumentTitle";

const bkg = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/features/footer.png";
const footer = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/Ftr/footer.svg";
const logoLight = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Navbar/logo.svg";
const logoDark = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Light/logo.svg";

const Feature = () => {
  DocumentTitle("Features - Acceleraise");
  const themeMode = useSelector((state) => state.themeMode.mode);
  const light = "light";
  const dark = "dark";
  return (
    <>
      {themeMode === "dark" ? (
        <NavBar
          scrollColor="#001617"
          btnColor="#018C94"
          getStartedBorder="1px solid transparent"
          profileBkg="#222533"
          profileBorder="1px solid #018C94"
          logoTheme={light}
          scrollingColor={light}
          textColor="#FFF"
        />
      ) : (
        <NavBar
          scrollColor="#0d7b80"
          btnColor="#018C94"
          getStartedBorder="1px solid transparent"
          profileBkg="#8b98d6"
          profileBorder="1px solid #018C94"
          logoTheme={light}
          scrollingColor={light}
          txtColor="#FFF"
        />
      )}

      <Dynamic />
      <Trait />
      {themeMode === "dark" ? (
        <Footer
          bkg={bkg}
          btnText="#000000"
          talkBtn="#018C94"
          btnHover="#01131a"
          btnBorder="0.5px solid #018C94"
          textHover="#FFFF"
          brightness="brightness(180%)"
        />
      ) : (
        <Footer
          bkg={footer}
          btnText="#000000"
          talkBtn="#018C94"
          btnHover="#01131a"
          btnBorder="0.5px solid #018C94"
          textHover="#FFFF"
          brightness="brightness(180%)"
        />
      )}
    </>
  );
};
export default Feature;
