import React, { useState, useEffect } from 'react'

const dp = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/MainLayout/dp.png"

const TopAcceleraiser = ({limitedAcceleraiser, title, textColor, labelColor, backgroundColor, borderSize, borderRadius, borderColor, shadow, themeMode}) => {

    return (
        <>
            <div 
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: borderRadius  ? `${borderRadius}px` : '0px', 
                    border: borderSize || borderColor ? `${borderSize ? borderSize : '0'}px solid ${borderColor ? borderColor : 'transparent'}` : 'none' , 
                }}
            >
                <div style={{ fontSize: '28px', fontWeight: '600', padding: '8px', color: textColor ? textColor : '#FFF' }}>
                    {title ? title : 'Top Acceleraiser'}
                </div>
                {limitedAcceleraiser?.map((item, index) => (
                    <div index={index} style={{
                        width:"100%"
                    }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
                                <div sx={{margin:"0px 15px 0px 0px"}}>
                                    <img src={item.img} alt='' />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column",padding:"0px 0px 0px 15px" }}>
                                    <div style={{textAlign:"left",fontWeight:"700"}}>
                                        <div style={{fontSize:"18px", color: textColor ? textColor : '#FFF'}}>
                                            {item.name}{" "}
                                        </div>
                                        <div style={{color: labelColor ? labelColor : '#FFF'}}>
                                            raised{" "}
                                            <span style={{ color: "#689900", fontWeight: "600", marginRight: "10px" }}>
                                                ${item.price}
                                            </span> 
                                        </div>    
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default TopAcceleraiser




export const topAcceleraiser = [
    {id: 0, name: "Ann Bowman", price: "90,000.00", img :dp},
    {
        id: 1, name: "Hiroki Sinclair", price: "90,000.00", img: dp},
    {
        id: 2, name: "Lornella Andrews", price: "80,000.00", img: dp
    },
    {
        id: 3, name: "Jamar Gahan", price: "50,000.00", img: dp
    },
    {
        id: 4, name: "Rashid Khan", price: "35,000.00", img: dp
    },
    {
        id: 5, name: "Hanna Brown", price: "35,000.00", img: dp
    },
    {
        id: 6, name: "Alexandra Pope", price: "35,000.00", img: dp
    },
]