import React from "react";
import { Chart, AxisOptions } from "react-charts";
import { Grid } from "@mui/material";
import { graphData } from "../../data";
import moment from "moment/moment";
type DataAds = { value: number, end_time: string };

const transformDataAds = (dataAds: DataAds[]): DataAds[] => {
  return dataAds.map((item: DataAds) => ({
    end_time: moment(new Date(item.end_time).toISOString()).format("MMM D"),
    value: item.value,
  }));
};

const ChartComponent = ({ donationsByDates, chartType }) => {
  const donationGraphData = Object.entries(donationsByDates)?.map(
    ([date, value]) => ({
      value,
      end_time: new Date(date)?.toISOString(),
    })
  );
  const transformedData = transformDataAds(donationGraphData);

  const series = [
    {
      label: "Donation",
      data: transformedData,
      color: "#9CCA3C",
    },
  ];

  const primaryAxis = React.useMemo(
    (): AxisOptions<DataAds> => ({
      getValue: (datum) => datum.end_time,
      type: "time",
      position: "bottom",
      // min: new Date("2023-08-01"),
      // max: new Date("2023-09-30"),
      // hardMin: new Date("2023-08-01"),
      // hardMax: new Date("2023-09-30"),
      tickInterval: [10, "day"],
      style: {
        strokeStyle: "red",
      },
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<DataAds>[] => [
      {
        getValue: (datum) => datum.value,
        // type: "linear",
        elementType: chartType ? chartType : "line",
        position: "left",
      },
    ],
    []
  );

  return (
    <Grid
      item
      md={12}
      sx={{
        width: "100%",
        height: "350px",
        marginY: "0px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
      }}
    >
      <Chart
        options={{
          
          data: series,
          primaryAxis,
          secondaryAxes,
          getSeriesStyle: () => ({
            strokeStyle: "green",
            fill: "orange",
            color: chartType === "area" ? "orange" : "green",
          }),
        }}
      />
    </Grid>
  );
};

export default ChartComponent;
